/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { ErrorResponseModelDto, ExceptionCorrectionModelDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class CheckExceptionCorrectionClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags CheckExceptionCorrection
   * @name CheckExceptionCorrectionRequestExceptionCorrection
   * @summary Creates a request for a correction to a check exception.
   * @request POST:/CheckExceptionCorrection
   */
  checkExceptionCorrectionRequestExceptionCorrection = (
    exceptionCorrectionModel: ExceptionCorrectionModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, ErrorResponseModelDto>({
      path: `/CheckExceptionCorrection`,
      method: "POST",
      body: exceptionCorrectionModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
