/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  CheckExceptionModelDataPageDto,
  ErrorHttpContentDto,
  SaveCheckExceptionAttachmentRequestModelDto,
  SearchCheckExceptionModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class CheckExceptionClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionSearchCheckExceptions
   * @summary Searches for check exceptions that meet the criteria of the request.
   * @request POST:/CheckException/Search
   */
  checkExceptionSearchCheckExceptions = (
    searchCheckExceptionModel: SearchCheckExceptionModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<CheckExceptionModelDataPageDto, ErrorHttpContentDto>({
      path: `/CheckException/Search`,
      method: "POST",
      body: searchCheckExceptionModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionDownloadPdfCheckExceptions
   * @summary Downloads a PDF for check exceptions that meet the criteria of the request.
   * @request POST:/CheckException/Download/Pdf
   */
  checkExceptionDownloadPdfCheckExceptions = (
    searchCheckExceptionModel: SearchCheckExceptionModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/CheckException/Download/Pdf`,
      method: "POST",
      body: searchCheckExceptionModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionDownloadCsvCheckExceptions
   * @summary Downloads a CSV for check exceptions that meet the criteria of the request.
   * @request POST:/CheckException/Download/Csv
   */
  checkExceptionDownloadCsvCheckExceptions = (
    searchCheckExceptionModel: SearchCheckExceptionModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/CheckException/Download/Csv`,
      method: "POST",
      body: searchCheckExceptionModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionSaveAttachment
   * @summary Attaches the uploaded file and saves the return reason comment to the specified check exception.
   * @request POST:/CheckException/{arpExceptionDetailUniqueId}/Attachment
   */
  checkExceptionSaveAttachment = (
    arpExceptionDetailUniqueId: string,
    checkExceptionAttachment: SaveCheckExceptionAttachmentRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/CheckException/${arpExceptionDetailUniqueId}/Attachment`,
      method: "POST",
      body: checkExceptionAttachment,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionGetAttachment
   * @summary Retrieves an attached file for the specified check exception.
   * @request GET:/CheckException/{arpExceptionDetailUniqueId}/Attachment
   */
  checkExceptionGetAttachment = (arpExceptionDetailUniqueId: string, params: RequestParams = {}) =>
    this.http.request<any, ErrorHttpContentDto>({
      path: `/CheckException/${arpExceptionDetailUniqueId}/Attachment`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckException
   * @name CheckExceptionDeleteAttachment
   * @summary Deletes an attached file for the specified check exception.
   * @request DELETE:/CheckException/{arpExceptionDetailUniqueId}/Attachment
   */
  checkExceptionDeleteAttachment = (arpExceptionDetailUniqueId: string, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/CheckException/${arpExceptionDetailUniqueId}/Attachment`,
      method: "DELETE",
      format: "json",
      ...params,
    });
}
