// cspell:ignore lightgray

import FieldType from '@treasury/FDL/field-type.js';
import { Record } from '@treasury/FDL/record';
import primitives from '@treasury/policy/primitives';
import { getKeys } from '@treasury/utils';
import { css, html, LitElement, nothing } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';
import { ListeningElementMixin } from './listening-element.ts';
import { closePopup, openPopup } from './omega-popup.js';
import './selection-list/filter-popup-contents.js';
import './table/omega-pagination.js';

/**
 * @typedef {import('@treasury/FDL/recordset').Recordset} Recordset
 */

const ListeningElement = ListeningElementMixin(LitElement);

class OmegaSelectionList extends ListeningElement {
    static get properties() {
        return {
            recordset: Object,
            recordTemplate: Function,
            visibleRecords: Array,
            sortFields: Array,
            sortBy: String,
            filters: Array,
            filterText: String,
            sortDirection: String,
            checkField: {
                type: String,
                reflect: true,
            },
            showCheckAll: {
                type: Boolean,
                value: false,
            },
            readOnly: {
                type: Boolean,
                value: false,
            },
        };
    }

    constructor() {
        super();
        /** @type Record[] */
        this.visibleRecords = [];
        this.sortFields = [];
        this.sortDirection = 'ascending';
        this.readOnly = false;

        /** @type Recordset */
        this.recordset = undefined;
    }

    // STUB: placeholder for show components bookmarklet
    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button--button',
        };
    }

    async firstUpdated() {
        if (!this.recordset) {
            throw new Error('Recordset required for <omega-selection-list>');
        }

        this.recordset.filter = r => {
            if (!this.filterText) {
                return true;
            }

            return getKeys(r.values).some(k => {
                const val = r.values[k];

                return typeof val === 'string' || typeof val === 'number'
                    ? val.toString().includes(this.filterText)
                    : false;
            });
        };

        this.listenTo(this.recordset, 'change', () => {
            this.visibleRecords = this.recordset.currentPage;
        });

        this.sortBy = this.sortBy ?? this.sortFields[0]?.field;
        this.sortAndFilterRecord = this.createSortAndFilterRecord();
        this.recordset.update().then(() => this.sort(this.sortBy));
    }

    createSortAndFilterRecord() {
        const fields = {
            SORT_BY: new FieldType().with
                .options(this.sortFields.map(f => ({ text: f.label, value: f.field })))
                .and.label('Sort By'),
        };
        const values = { SORT_BY: this.sortBy };
        // TODO: For now this is very narrow in scope as required for ACH Filters. We will want to expand it to support other field types that don't have options or multiple values.
        // eslint-disable-next-line no-unused-expressions
        this.filters?.forEach(filter => {
            if (filter?.schema === 'datepicker') {
                fields[filter.field] = primitives.dateRange.with
                    .schema('datepicker')
                    .and.range()
                    .and.parseDynamicRange()
                    .and.label(filter.label);
            } else {
                fields[filter.field] = new FieldType().with
                    .options(filter.options.map(f => ({ text: f, value: f })))
                    .and.multipleValues()
                    .and.label(filter.label);
            }
            values[filter.field] = this[filter.field] ?? filter.values;
        });
        return new Record(fields, values);
    }

    /**
     * @param {string} text
     */
    filter(text) {
        this.filterText = text;
        this.recordset.sortAndFilterRecords();
    }

    sort(field) {
        this.sortBy = field;
        this.recordset.sort({ field, sort: this.sortDirection });
    }

    get showCheckbox() {
        return this.recordset.fieldNames.includes(this.checkField);
    }

    /**
     * @param {string} filterText
     */
    createFilters(filterText) {
        const textFilter = this.recordset.fieldNames.map(field => ['contains', field, filterText]);
        const fieldFilters = [
            'and',
            ...Object.keys(this.sortAndFilterRecord.values)
                .filter(
                    key =>
                        key !== 'SORT_BY' &&
                        (this.sortAndFilterRecord.getField(key)?.length ||
                            (this.sortAndFilterRecord.getField(key) &&
                                this.sortAndFilterRecord.getField(key).dates?.length))
                )
                .map(key => {
                    if (
                        this.sortAndFilterRecord.primitives &&
                        this.sortAndFilterRecord.primitives[key]?.schema() === 'datepicker'
                    ) {
                        const datesArray = this.sortAndFilterRecord.getField(key)?.dates;
                        return ['inDateRange', key, datesArray[0], datesArray[1]];
                    }
                    return ['containsAny', key, this.sortAndFilterRecord.getField(key)];
                }),
        ];
        return ['and', ['or', ...textFilter], fieldFilters];
    }

    applySortAndFilter() {
        const field = this.sortAndFilterRecord.getField('SORT_BY');
        this.sortBy = field;
        this.recordset.sortColumns = [{ field, sort: this.sortDirection }];
        this.recordset.sortAndFilterRecords();

        closePopup();
    }

    cancelSortAndFilter() {
        closePopup();
    }

    selectRecord(record) {
        if (this.readOnly) {
            return;
        }

        this.dispatchEvent(
            new CustomEvent('recordSelected', {
                detail: { record },
            })
        );
    }

    checkRecord(record) {
        if (this.readOnly) {
            return;
        }

        record.setField(this.checkField, !record.getField(this.checkField));
        this.requestUpdate();
        this.dispatchCheckedEvent();
    }

    allVisibleSelected() {
        return this.visibleRecords.every(record => record.getField(this.checkField));
    }

    checkUncheckAllVisibleFiles() {
        if (this.readOnly) {
            return;
        }

        const selectBool = !this.allVisibleSelected();
        this.visibleRecords.forEach(record => {
            record.setField(this.checkField, selectBool);
        });
        this.requestUpdate();
        this.dispatchCheckedEvent();
    }

    dispatchCheckedEvent() {
        const records = this.recordset.recordsMatching(this.checkField, true);
        this.dispatchEvent(
            new CustomEvent('recordsChecked', {
                detail: { records },
            })
        );
    }

    showFilterPopup(event) {
        openPopup(
            event.target,
            (resolve, reject) => html`
                <filter-popup-contents
                    .resolve=${resolve}
                    .reject=${reject}
                    .filters=${this.filters}
                    .sortAndFilterRecord=${this.sortAndFilterRecord}
                ></filter-popup-contents>
            `,
            { directions: ['down'] }
        ).then(
            () => {
                this.applySortAndFilter();
            },
            () => {
                this.cancelSortAndFilter();
            }
        );
    }

    handleRecordKeypress(event, record) {
        if (event.key === 'Enter' || event.key === ' ') {
            this.selectRecord(record);
        }
    }

    renderRecords() {
        if (!this.recordset) {
            return nothing;
        }

        return this.visibleRecords.map(
            record =>
                html`<li
                    @keypress=${e => this.handleRecordKeypress(e, record)}
                    @click=${() => this.selectRecord(record)}
                    class="selectable-list-item"
                >
                    ${this.showCheckbox ? this.renderCheckboxInput(record) : nothing}
                    ${this.recordTemplate(record)}
                </li>`
        );
    }

    renderCheckboxInput(record) {
        return html`<omega-checkbox
            type="noBackground"
            label=""
            hideLabel
            value=${record.getField(this.checkField)}
            ?checked=${record.getField(this.checkField)}
            @click=${evt => {
                evt.stopPropagation();
            }}
            @toggle=${evt => {
                evt.stopPropagation();
                this.checkRecord(record);
            }}
            .disabled=${this.readOnly}
        ></omega-checkbox>`;
    }

    renderFilterField(filter) {
        return html`<omega-field
            field=${filter.field}
            .inline=${filter.options?.length < 5}
            .record=${this.sortAndFilterRecord}
        ></omega-field>`;
    }

    renderFilters() {
        if (!this.filters) return nothing;

        return html`
            <hr />
            ${this.filters.map(filter => this.renderFilterField(filter))}
        `;
    }

    renderSortControl() {
        return html`
            <omega-field
                field="SORT_BY"
                label="Sort By"
                .record=${this.sortAndFilterRecord}
            ></omega-field>
            ${this.renderFilters()}
        `;
    }

    renderSortDirectionControl() {
        return html`
            <omega-button
                type="icon"
                class="sort-button"
                title="Sort ${this.sortDirection}"
                @click=${() => {
                    this.sortDirection =
                        this.sortDirection === 'ascending' ? 'descending' : 'ascending';
                    this.applySortAndFilter();
                }}
            >
                <div slot="icon">
                    <i
                        class="fa ${
                            this.sortDirection === 'ascending' ? 'fa-sort-up' : 'fa-sort-down'
                        } fa-lg"
                        aria-label="Sort ${this.sortDirection}"
                    ></i>
                    <i class="fa fa-sort fa-lg"></i>
                </div>
            </omega-button>
        </omega-tooltip>`;
    }

    renderSortAndFilterDialog() {
        return html`
            <omega-button
                id="filter-popup-button"
                type="icon"
                title="Sort and Filters"
                @click=${event => this.showFilterPopup(event)}
            >
                <div slot="icon">
                    <i class="fa fa-sliders fa-lg" aria-label="Sort and Filters"></i>
                </div>
            </omega-button>
        `;
    }

    renderPagination() {
        return html` <omega-pagination compact .recordset=${this.recordset}></omega-pagination> `;
    }

    renderFooterTools() {
        return html` <div class="list-footer-tools">
            <div>
                <div class="selected-indicator">
                    <i class="fa fa-list fa-lg"></i>
                    ${this.recordset?.countRecordsMatching(this.checkField, true)} selected
                </div>
                <omega-button
                    type="link"
                    class="select-all"
                    @click=${this.checkUncheckAllVisibleFiles}
                    >${this.allVisibleSelected() ? 'Deselect All' : 'Select All'}</omega-button
                >
            </div>
            <slot name="footer-tools"></slot>
        </div>`;
    }

    render() {
        if (!this.sortAndFilterRecord) return nothing;
        return html`
            <div class="flex-container">
                <div class="search-bar">
                    <i class="fa fa-search fa-lg"></i>&nbsp;<input
                        placeholder="search accounts"
                        id="type-to-filter"
                        aria-label="Search Accounts"
                        @keyup=${e => this.filter(e.target.value)}
                    />
                </div>
                <slot name="header-tools"></slot>
                <div class="sort-direction-icon">${this.renderSortDirectionControl()}</div>
                <div class="filter-icon">${this.renderSortAndFilterDialog()}</div>
            </div>
            <ol>
                ${this.renderRecords()}
            </ol>
            <div class="footer">
                ${this.renderPagination()}
                ${this.showCheckAll && this.showCheckbox ? this.renderFooterTools() : nothing}
            </div>
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    max-height: var(--selection-list-height, 450px);
                }
                :host > ol {
                    list-style: none;
                    margin: 0px;
                    margin-left: 8px;
                    padding: 0px;
                    flex: 1;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

                :host > ol > li {
                    margin: 5px 0 5px 16px;
                    padding: 5px 0px 0px 0px;
                    border-top: var(--omega-default-border);
                    position: relative;
                }

                :host([checkField]) > ol > li {
                    padding-left: 12px;
                    margin-left: 24px;
                }

                omega-checkbox {
                    position: absolute;
                    left: -24px;
                    top: var(--omega-checkbox-top, 50%);
                    transform: translateY(-50%);
                }
                #filter-popup-button {
                    margin-left: 0;
                }
                #filter-popup-button div {
                    height: 4px;
                }
                .flex-container {
                    margin-top: 6px;
                    display: flex;
                    justify-content: space-between;
                }
                .fa-search {
                    color: gray;
                }
                input {
                    border: none;
                }
                i.fa-sliders {
                    color: var(--omega-button-icon-color);
                    cursor: pointer;
                }
                hr {
                    color: gray;
                    border: 0;
                    border-top: 1px solid lightgray;
                    height: 1px;
                    margin: 24px -16px;
                }
                #sort-and-filter {
                    min-width: 330px;
                }
                omega-button.sort-button {
                    margin: 0;
                }
                omega-button.sort-button > div {
                    top: 5px;
                    position: relative;
                    height: 4px;
                    width: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                omega-button.sort-button .fa-sort {
                    z-index: -1;
                    opacity: 0.3;
                    position: absolute;
                }
                .search-bar {
                    flex-grow: 2;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .search-bar input {
                    width: 100%;
                    font-size: 14px;
                    font-family: inherit;
                }
                .sort-direction-icon {
                    display: flex;
                    align-items: center;
                }
                omega-pagination {
                    border-top: var(--omega-default-border);
                    padding: 8px 18px;
                }
                .list-footer-tools {
                    display: flex;
                    justify-content: space-between;
                    color: var(--omega-primary);
                    padding: 0 8px;
                }
                .select-all {
                    --omega-button-padding: 0px;
                    --omega-button-font-weight: 400;
                    --omega-button-text-decoration: underline;
                    margin: 0;
                }
                .filter-icon {
                    display: flex;
                    align-items: center;
                }
                .selectable-list-item {
                    cursor: pointer;
                }
            `,
        ];
    }
}

window.customElements.define('omega-selection-list', OmegaSelectionList);
