/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AddBillPayPaymentRequestModelDto,
  AddPaymentResponseModelDto,
  BillPaySsoDto,
  GetAccountsResponseModelDto,
  GetInstitutionResponseModelDto,
  GetPayeeResponseModelDto,
  GetPaymentResponseModelDto,
  GetPaymentsResponseModelDto,
  GetUserResponseModelDto,
  UpdatePaymentRequestModelDto,
  UpdatePaymentResponseModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class BillPayClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetBillPayUrl
   * @summary Gets the bill pay URL.
   * @request GET:/BillPay/GetBillPayURL
   */
  billPayGetBillPayUrl = (params: RequestParams = {}) =>
    this.http.request<BillPaySsoDto, any>({
      path: `/BillPay/GetBillPayURL`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetInstitution
   * @summary Gets bill pay details about the calling user's institution.
   * @request GET:/BillPay/Institutions/Current
   */
  billPayGetInstitution = (params: RequestParams = {}) =>
    this.http.request<GetInstitutionResponseModelDto, any>({
      path: `/BillPay/Institutions/Current`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetPayees
   * @summary Gets the list of payees available to the current user.
   * @request GET:/BillPay/Payees
   */
  billPayGetPayees = (
    query?: {
      onlyIncludePayablePayees?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetPayeeResponseModelDto, any>({
      path: `/BillPay/Payees`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetPayee
   * @summary Gets details for a specific payee.
   * @request GET:/BillPay/Payees/{id}
   */
  billPayGetPayee = (id: string, params: RequestParams = {}) =>
    this.http.request<GetPayeeResponseModelDto, any>({
      path: `/BillPay/Payees/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetPayments
   * @summary Gets the list of past payments for the current user.
   * @request GET:/BillPay/Payments
   */
  billPayGetPayments = (
    query?: {
      /** @format date-time */
      endDate?: string | null;
      payeeId?: string | null;
      /** @format date-time */
      startDate?: string | null;
      type?: 0 | 1 | 2;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetPaymentsResponseModelDto, any>({
      path: `/BillPay/Payments`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayAddPayment
   * @summary Adds the payment.
   * @request POST:/BillPay/Payments
   */
  billPayAddPayment = (request: AddBillPayPaymentRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<AddPaymentResponseModelDto, any>({
      path: `/BillPay/Payments`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayUpdatePaymentAsnyc
   * @summary Updates the payment asnyc.
   * @request PUT:/BillPay/Payments
   */
  billPayUpdatePaymentAsnyc = (request: UpdatePaymentRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<UpdatePaymentResponseModelDto, any>({
      path: `/BillPay/Payments`,
      method: "PUT",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetPayment
   * @summary Gets the payment.
   * @request GET:/BillPay/Payments/{paymentId}
   */
  billPayGetPayment = (
    paymentId: string,
    query?: {
      includeFuturePayments?: boolean | null;
      type?: 0 | 1 | 2;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetPaymentResponseModelDto, any>({
      path: `/BillPay/Payments/${paymentId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetAccounts
   * @summary Gets the accounts.
   * @request GET:/BillPay/Accounts
   */
  billPayGetAccounts = (params: RequestParams = {}) =>
    this.http.request<GetAccountsResponseModelDto, any>({
      path: `/BillPay/Accounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BillPay
   * @name BillPayGetUser
   * @summary Gets the user asynchronous.
   * @request GET:/BillPay/Users/Current
   */
  billPayGetUser = (params: RequestParams = {}) =>
    this.http.request<GetUserResponseModelDto, any>({
      path: `/BillPay/Users/Current`,
      method: "GET",
      format: "json",
      ...params,
    });
}
