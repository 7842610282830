import { clone } from '@jack-henry/frontend-utils/functions';
import { BeneficiaryModelDto, CountryModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { address2Array } from '../utilities';
import { nullBeneficiary } from './wire-beneficiary.dto';
import { getCreditorIsoAddress } from './wires.helpers';
/**
 * Represents the client-side contract for Wire beneficiaries.
 */
export class WireBeneficiary extends DomainEntity<BeneficiaryModelDto> {
    constructor(dto = clone(nullBeneficiary)) {
        super(dto);
    }

    public createDefault(): this {
        return new WireBeneficiary(this.dto) as this;
    }

    public get id() {
        return this.dto.id || 0;
    }

    public get name() {
        return this.dto.name || '';
    }

    public set name(templateName) {
        this.dto.name = templateName;
    }

    public get accountNumber() {
        return this.dto.accountNumber || '';
    }

    public set accountNumber(accountNumber) {
        this.dto.accountNumber = accountNumber;
    }

    public get address() {
        return address2Array(Object.assign(this));
    }

    public getIsoCompliantAddress(countries: CountryModelDto[]) {
        return getCreditorIsoAddress(this.dto, countries);
    }

    public get addressLine1() {
        return this.dto.addressLine1;
    }

    public set addressLine1(addressLine1) {
        this.dto.addressLine1 = addressLine1;
    }

    public get addressLine2() {
        return this.dto.addressLine2;
    }

    public set addressLine2(addressLine2) {
        this.dto.addressLine2 = addressLine2;
    }

    public get addressLine3() {
        return this.dto.addressLine3;
    }

    public set addressLine3(addressLine3) {
        this.dto.addressLine3 = addressLine3;
    }

    public get city() {
        return this.dto.city;
    }

    public get state() {
        return this.dto.state;
    }

    public get postalCode() {
        return this.dto.postalCode;
    }

    public get country() {
        return this.dto.country;
    }

    public get bank() {
        return this.dto.bank;
    }

    public get notes() {
        return this.dto.notes || '';
    }

    public set notes(notes) {
        this.dto.notes = notes;
    }

    public get intermediaryBanks() {
        return this.dto.intermediaryBanks;
    }

    public get isInternational() {
        return this.dto.isInternational || false;
    }

    public set isInternational(isInternational) {
        this.dto.isInternational = isInternational;
    }

    public get status() {
        return this.dto.status || '';
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get audit() {
        return this.dto.audit || '';
    }

    public get permissions() {
        return this.dto.permissions || [];
    }

    public set permissions(permissions) {
        this.dto.permissions = permissions;
    }

    public toDto() {
        return this.dto;
    }
}
