/* cSpell:disable */
/** WARNING!!!!: Do NOT save this file with auto-organizing of imports turned on in VS Code.
 * The ordering of imports in this file is very specific and must be maintained.
 * In VS Code just do CMD+P and choose File: Save without Formatting
 */
import 'jquery';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/ui/widgets/sortable';
// import "../Scripts/jquery-resize/jquery.resize.js";

import 'signalr/jquery.signalR';
import 'angular/angular.js';
import 'angular-aria/angular-aria.js';
import 'angular-resource/angular-resource.js';
import '@uirouter/angularjs';

import 'angular-animate/angular-animate.js';
import 'angular-sanitize/angular-sanitize.js';
import 'angular-messages/angular-messages.js';
import 'smartmenus';
import 'angular-cookies/angular-cookies.js';
import 'angular-file-saver/dist/angular-file-saver.bundle.js';
import 'bootstrap/js/collapse';
import 'bootstrap/js/tooltip';
import 'bootstrap/js/popover';
import '../../Scripts/uiBootstrap/bootstrap.js';
import '../../Scripts/uiBootstrap/ui-bootstrap-tpls-0.14.0.js';
import '../../Scripts/toaster/toaster.js';
import '../../Scripts/custom/animations.js';
import moment from 'moment';
import 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data.js';
import '@treasury/omega/polyfills/browser.js';

import '@treasury/omega/polyfills/nodeListForEach.js';
import '@treasury/omega/polyfills/focus-traversal-api-polyfill.js';
import '@webcomponents/webcomponentsjs';
import '@webcomponents/shadycss';

import '../../Scripts/angular-ui-sortable/angular-ui-sortable.js';
import '../../Scripts/angular-vs-repeat/angular-vs-repeat.js';
import '../../Scripts/angular-local-storage/angular-local-storage.js';
import '../../Scripts/angular-gridster/angular-gridster.js';
import '../../Scripts/printThis/printThis.js';
import '../../Scripts/jquery-watch/jquery-watch.min.js';
import '../../Scripts/jquery-migrate/jquery-migrate-3.3.1.min.js';
import '../../Scripts/custom/angular-interpol.js';
import '../../Scripts/kendo/kendo.all.min.js';
window.moment = moment;
