import { GetUserProductLimitsResponseModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class UserTransferLimits extends DomainEntity<GetUserProductLimitsResponseModelDto> {
    public createDefault() {
        return new UserTransferLimits({} as GetUserProductLimitsResponseModelDto) as this;
    }

    public get dailyLimit() {
        return this.dto.productDailyLimit;
    }

    public get transferLimits() {
        return {
            initiationLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
            },
            approvalLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
            },
        };
    }

    public get userTransferAccounts() {
        return this.dto.userAccountLimits?.map(account => ({
            ...account,
            transactionLimit: {
                value: getDisplayValue(
                    account.userAccountLimit?.perTransactionLimit,
                    account.pendingUserAccountLimit?.perTransactionLimit
                ),
                edited: isEdited(
                    account.userAccountLimit?.perTransactionLimit,
                    account.pendingUserAccountLimit?.perTransactionLimit
                ),
            },
            dailyLimit: {
                value: getDisplayValue(
                    account.userAccountLimit?.dailyLimit,
                    account.pendingUserAccountLimit?.dailyLimit
                ),
                edited: isEdited(
                    account.userAccountLimit?.dailyLimit,
                    account.pendingUserAccountLimit?.dailyLimit
                ),
            },
            accountDailyLimit: account.userAccount?.account?.dailyLimit || 9999999999999.99,
        }));
    }
}
