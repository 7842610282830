/* eslint-disable no-restricted-syntax */
import { exists, Nullable } from '@treasury/utils';
import { DateFormatters } from '../../../shared/utilities';
import { IssuedItemDto, IssuedItemSavedError, IssuedItemSaveDto } from '../../types';
import { IssuedItem } from './issued-items.entity';
import { IIssuedItem } from './issued-items.mappings';

export class IssuedItemSaved extends IssuedItem {
    constructor(
        dto: Nullable<IssuedItemDto>,
        public readonly error?: IssuedItemSavedError
    ) {
        super(dto);
    }

    public readonly status = this.dto.issuedStatus || '';

    public get hasFailed() {
        return this.status === 'Failed' || exists(this.error);
    }
}

export function convertToSaveDto(item: IIssuedItem): Nullable<IssuedItemSaveDto> {
    const {
        account,
        checkAmount,
        checkNumber,
        dateIssued,
        createdDate,
        itemEntryType,
        type,
        payee,
    } = item;

    if (!account) {
        throw new Error(
            'Could not convert issued item to saveable representation. An account was not provided.'
        );
    }

    const base = {
        accountId: account.id,
        issuedAmount: checkAmount,
        checkNumber: checkNumber ?? null,
        checkDate: dateIssued ? DateFormatters.apiFormattedDate(dateIssued) : null,
        issuedItemCreatedDate: createdDate ? DateFormatters.apiFormattedDate(createdDate) : null,
        checkVoidDate: DateFormatters.apiFormattedDate(new Date()),
        entryType: itemEntryType,
        payeeName: payee,
    };

    if (type === 'Void') {
        const { sequenceNumber, batchNumber } =
            item instanceof IssuedItem ? item.toDto() : { sequenceNumber: null, batchNumber: null };

        // `sequenceNumber` and `batchNumber` must be specified
        // to perform a void that modifies an existing item
        const isModify = exists(sequenceNumber) && exists(batchNumber);

        return isModify
            ? {
                  ...base,
                  itemType: 'Void',
                  isModify,
                  sequenceNumber,
                  batchNumber,
              }
            : {
                  ...base,
                  itemType: 'Void',
                  isModify,
              };
    }

    return {
        ...base,
        itemType: 'Check',
        isModify: false,
        checkVoidDate: null,
    };
}
