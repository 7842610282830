import { getCreditorIsoAddress } from '@treasury/domain/wires';
import moment from 'moment-timezone';

FxWireWizardController.$inject = [
    '$scope',
    'wireCompaniesService',
    '$modal',
    'toaster',
    'companyAccountsService',
    'modalService',
    'entitlementsService',
    '$state',
    'holidaysService',
    'wireService',
    'beneficiariesService',
    '$filter',
    'securityService',
    '$stateParams',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'accountService',
    'dateConstants',
    'countriesService',
    'timeZones',
    'timerService',
    'dashboardService',
    'regexConstants',
    'wireIsoService',
];

export default function FxWireWizardController(
    $scope,
    wireCompaniesService,
    $modal,
    toaster,
    companyAccountsService,
    modalService,
    entitlementsService,
    $state,
    holidaysService,
    wireService,
    beneficiariesService,
    $filter,
    securityService,
    $stateParams,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    accountService,
    dateConstants,
    countriesService,
    timeZones,
    timerService,
    dashboardService,
    regexConstants,
    wireIsoService
) {
    $scope.canCreateFxWire = entitlementsService.hasEntitlement('Wire Transfer DLI, Create Wire');
    $scope.canCreateDomesticWire = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire'
    );
    $scope.hideQuoteRestrictionInfo = entitlementsService.hasEntitlement(
        'Feature.FxWire.HideQuoteRestrictionInformation'
    );
    $scope.wizardController = {};
    $scope.wire = null;
    $scope.setToInternational = setToInternational;
    $scope.showBankDialog = showBankDialog;
    $scope.addAdditionalInformation = addAdditionalInformation;
    $scope.isInternational = isInternational;
    $scope.deleteAdditionalInformation = deleteAdditionalInformation;
    $scope.setForm = setForm;
    $scope.setBankIdType = setBankIdType;
    $scope.findAccount = findAccount;
    $scope.findBeneficiaries = findBeneficiaries;
    $scope.isObject = isObject;
    $scope.selectWireCompany = selectWireCompany;
    $scope.checkEnability = checkEnability;
    $scope.beneficiaries = [];
    $scope.reset = reset;
    $scope.getPlaceHolderText = getPlaceHolderText;
    $scope.cancel = cancel;
    $scope.goToWireList = goToWireList;
    $scope.createAWire = createAWire;
    $scope.createAnotherFxWire = createAnotherFxWire;
    $scope.print = print;
    $scope.isInternationalWire = false;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.goBackToEdit = goBackToEdit;
    $scope.downloadPageId = downloadPageId.CreateWireConfirmation;
    $scope.wireConfig = {};
    $scope.success = null;
    $scope.isOneTime = isOneTime;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.getQuote = getQuote;
    $scope.confirmAndAcceptQuote = confirmAndAcceptQuote;
    $scope.confirmUsd = confirmUsd;
    $scope.showRateQuoteDetails = showRateQuoteDetails;
    $scope.showContractAcceptedMessage = showContractAcceptedMessage;
    $scope.loadCountry = loadCountry;
    $scope.getCountry = getCountry;
    $scope.onQuoteTimeout = onQuoteTimeout;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.startingBeneficiaryId = $stateParams.beneficiaryId ? $stateParams.beneficiaryId : null;
    $scope.disableReviewButton = disableReviewButton;
    $scope.pastCutOff = false;
    $scope.startTimeWithTimeZone = null;
    $scope.isBusinessDay = true;
    $scope.getWarningMessage = getWarningMessage;
    $scope.showWarningMessage = showWarningMessage;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.getCityStateAndPostalCode = getCityStateAndPostalCode;
    $scope.isBeneficiaryValidForSelectedCurrency = isBeneficiaryValidForSelectedCurrency;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    $scope.wireIsoFeatureFlag = false;
    $scope.beneficiaryAddress = [];
    $scope.beneficiaryBankAddress = [];
    $scope.setBeneficiaryAndBankAddress = setBeneficiaryAndBankAddress;
    $scope.hasWireIsoFeatureFlag = hasWireIsoFeatureFlag;

    let singleModalInstance;

    function hasWireIsoFeatureFlag() {
        return $scope.wireIsoFeatureFlag;
    }

    function getCityStateAndPostalCode(bank) {
        return beneficiariesService.getCityStateAndPostalCode(bank);
    }

    function isBeneficiaryValidForSelectedCurrency() {
        const { beneficiary } = $scope.wire;

        if (beneficiary == null) {
            // We only want to evaluate if the beneficiary is valid if one is selected
            return true;
        }

        if (
            $scope.wire.destinationCurrencyCode &&
            $scope.wire.destinationCurrencyCode !== 'USD' &&
            beneficiary.intermediaryBanks &&
            beneficiary.intermediaryBanks.length > 0
        ) {
            return beneficiary.intermediaryBanks.every(
                intermediary => intermediary.isInternational
            );
        }

        return true;
    }

    function isBeneficiaryValid() {
        return $scope.wire.beneficiary && isBeneficiaryValidForSelectedCurrency();
    }

    function openModal(options) {
        if (singleModalInstance) {
            singleModalInstance.dismiss();
        }
        singleModalInstance = $modal.open(options);
        return singleModalInstance;
    }

    function showModal(customModalDefaults, customModalOptions) {
        if (singleModalInstance) {
            singleModalInstance.dismiss();
        }
        singleModalInstance = modalService.show(customModalDefaults, customModalOptions);
        return singleModalInstance;
    }

    function loadCutoffTimesDli() {
        companyAccountsService.getCutoffTimesByProductType('WireTransferDli').then(response => {
            const timeZone = ` ${moment().tz(timeZones[response.fiTimeZone]).format('z')}`;
            $scope.isBusinessDay =
                moment(
                    moment(response.currentFiTime, moment.ISO_8601).format('L'),
                    'MM/DD/YYYY'
                ).diff(moment(response.closestBusinessDate, moment.ISO_8601)) === 0;

            const cutOff = moment.tz(
                `${moment(response.currentFiTime, moment.ISO_8601).format('L')} ${
                    response?.processingCutoff?.cutoffTime
                }`,
                'MM/DD/YYYY HH:mm:ss',
                timeZones[response.fiTimeZone]
            );
            const timeToCutoff = cutOff.diff(
                moment.tz(response.currentFiTime, moment.ISO_8601, timeZones[response.fiTimeZone])
            );
            $scope.cutOffWithTimeZone = cutOff.format('hh:mm A') + timeZone;

            const startTime = moment.tz(
                `${moment(response.currentFiTime, moment.ISO_8601).format('L')} ${
                    response?.processingCutoff?.startTime
                }`,
                'MM/DD/YYYY HH:mm:ss',
                timeZones[response.fiTimeZone]
            );
            const timeToStartTime = startTime.diff(
                moment.tz(response.currentFiTime, moment.ISO_8601, timeZones[response.fiTimeZone])
            );
            $scope.startTimeWithTimeZone = startTime.format('hh:mm A') + timeZone;

            if (!$scope.isBusinessDay) {
                $scope.disableDliToday = true;
            } else if (timeToStartTime > 0) {
                $scope.beforeStartTime = true;
                $scope.disableDliToday = true;
            } else if (timeToCutoff <= 0) {
                $scope.pastCutOff = true;
                $scope.disableDliToday = true;
            }
        });
    }

    function init() {
        if (!$scope.canCreateFxWire) {
            $state.go('dashboard');
        }

        $scope.wire = {
            wireCompany: null,
            amount: null,
            destinationCurrency: 'USD',
            isAmountInDestinationCurrency: true,
            frequency: {
                type: 'One Time',
                valueDate: '',
            },
            wireConfig: null,
        };

        getWireCompanies();
        getHolidays();
        loadCutoffTimesDli();
        window.$scope = $scope;
        loadCurrencies();
        loadFrequencyOptions();
        loadDays();
        getWireConfiguration();
        loadAccountDisplayField();
        loadCountry();
        loadDomesticWireProductStatus();

        if (!$scope.template) {
            setToInternational();
        }

        if ($scope.startingBeneficiaryId) {
            getStartingBeneficiary();
        }

        wireIsoService.getLabels().then(labels => {
            $scope.wireIsoLabels = labels;
        });
        wireIsoService.getEntitlementWireISO20022().then(ff => {
            $scope.wireIsoFeatureFlag = ff;
        });
    }

    function loadAccountDisplayField() {
        accountService.getSettings().then(settings => {
            $scope.accountDisplayField = settings.accountDisplayField;
        });
    }

    function checkEnability() {
        return !$scope.form.$valid;
    }

    function goToWireList() {
        $state.go('payables.wire.wire-list');
    }

    function createAWire() {
        $state.go('payables.wire.create-wire');
    }

    function selectWireCompany(obj) {
        $scope.wire.debitAccount = null;
        if (obj) {
            getAccounts(obj.id);
        } else {
            $scope.companyAccounts = [];
        }
    }

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function setForm(form) {
        $scope.form = form;
    }

    function loadCurrencies() {
        countriesService.getAll().then(response => {
            $scope.destinationCurrencies = currenciesFromCountries(response);
        });
    }

    function currenciesFromCountries(countries) {
        const seen = {};
        countries = countries
            .map(country => ({
                currencyLabel: `${country.currencyName} - ${country.currencyCode}`,
                currencyCode: country.currencyCode,
                intradaySpot: country.intradaySpot,
            }))
            .sort((a, b) =>
                a.currencyLabel < b.currencyLabel ? -1 : a.currencyLabel === b.currencyLabel ? 0 : 1
            );
        return countries.filter(item => {
            const k = JSON.stringify(item);
            return seen.hasOwnProperty(k) ||
                !item.currencyCode ||
                item.currencyCode === 'USD' ||
                !item.intradaySpot
                ? false
                : (seen[k] = true);
        });
    }

    function reset() {
        let $modalInstance = null;
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Reset Edits',
            headerText: 'Confirm Reset',
            bodyText: 'Are you sure you want to reset the changes you have made?',
            submit() {
                $modalInstance.close();
                clearWireDetails();
                $scope.form.$setPristine();
            },
        };
        $modalInstance = showModal({}, modalOptions);
    }

    function clearWireDetails() {
        $scope.wire.wireCompany = null;
        $scope.wire.debitAccount = null;
        $scope.wire.beneficiary = null;
        $scope.wire.purpose = null;
        $scope.wire.additionalInformation = [{ value: '' }];
        $scope.wire.intermediaryBanks = [];
    }

    function getPlaceHolderText(index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function setBankIdType(intermediaryBank, type) {
        if (intermediaryBank !== null) {
            const index = $scope.wire.intermediaryBanks.indexOf(intermediaryBank);
            $scope.wire.intermediaryBanks[index].bankIdType = type;
        } else {
            $scope.wire.bank.bankIdType = type;
        }
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function showBankDialog(index) {
        const modalInstance = openModal({
            template: require('../../beneficiaries/views/bankDialogView.html'),
            size: 'md',
            controller: 'BankDialogController',
            backdrop: 'static',
            resolve: {
                beneficiary() {
                    return $scope.beneficiary;
                },
            },
        });
        modalInstance.result.then(response => {
            if (index !== undefined) {
                $scope.wire.intermediaryBanks[index] = response;
            } else {
                $scope.wire.bank = response;
            }
        });
    }

    function isInternational() {
        return $scope.wire && $scope.wire.isInternational;
    }

    function isOneTime() {
        return (
            $scope.wire &&
            $scope.wire.frequency &&
            $scope.wire.frequency.type &&
            $scope.wire.frequency.type.replace(/\s/g, '').toLowerCase() === 'onetime'
        );
    }

    function addAdditionalInformation() {
        if ($scope.wire.additionalInformation.length < 4) {
            $scope.wire.additionalInformation.push({ value: '' });
        }
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            $scope.disableDliOnNonProcessingDay = holidaysService.compareDates(
                new Date($scope.wire.frequency.valueDate),
                $scope.holidayDates
            );
        });
    }

    function getWarningMessage() {
        if ($scope.isBusinessDay && !$scope.disableDliOnNonProcessingDay) {
            if ($scope.pastCutOff) {
                return `The FX Wire service is not available because the current time is past the ${$scope.cutOffWithTimeZone} cut-off time.`;
            }
            if ($scope.beforeStartTime) {
                return `The FX Wire service is not available because the current time is before the ${$scope.startTimeWithTimeZone} start time.`;
            }
        }
        return 'The FX Wire service is not available because today is a non-processing day or a holiday.';
    }

    function showWarningMessage() {
        return $scope.disableDliToday || $scope.disableDliOnNonProcessingDay;
    }

    function deleteAdditionalInformation(index) {
        $scope.wire.additionalInformation.splice(index, 1);
    }

    function cancel() {
        if (!$scope.form.$dirty && checkEnability()) {
            if ($scope.isInternationalWire === true) {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateInternationalWire
                );
            } else {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateDomesticWire
                );
            }
            $state.go('payables.wire.wire-list');
        } else {
            confirmCancel();
        }
    }

    function confirmCancel() {
        let $modalInstance = null;
        const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Cancel Edits',
            headerText: 'Confirm Cancellation',
            bodyText: 'Are you sure you want to cancel the changes you have made?',
            submit() {
                $modalInstance.close();
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateInternationalWire
                );
                if ($scope.template) {
                    $state.go('payables.wire.wire-template-list');
                } else {
                    $state.go('payables.wire.wire-list');
                }
            },
        };
        $modalInstance = showModal({}, modalOptions);
    }

    function setToInternational() {
        $scope.wire = {
            additionalInformation: [{ value: '' }],
            isInternational: true,
            isAmountInDestinationCurrency: false,
            intermediaryBanks: [],
            amount: null,
            destinationCurrency: null,
            frequency: {
                type: 'One Time',
                valueDate: moment(new Date()).format('MM/DD/YYYY'),
            },
        };
        if (!$scope.internationalBenes) {
            getBeneficiaries($scope.wire.isInternational);
        } else {
            $scope.beneficiaries = $scope.internationalBenes;
        }

        if ($scope.wireCompanies && $scope.wireCompanies.length === 1) {
            $scope.wire.wireCompany = $scope.wireCompanies[0];
        }
        $scope.isInternationalWire = true;
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.AccessCreateInternationalWire
        );
    }

    function getBeneficiaries() {
        const filter = {
            status: 'Ready',
            beneficiaryType: 'International',
            requireEntitlement: false,
        };

        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            $scope.internationalBenesAll = response;
            filterBeneficiaries();
            $scope.beneficiaries = $scope.internationalBenes;
        });
    }

    function setBeneficiaryAndBankAddress() {
        $scope.beneficiaryAddress = getCreditorIsoAddress(
            $scope.wire.beneficiary,
            $scope.countries
        );
        $scope.beneficiaryBankAddress = getCreditorIsoAddress(
            $scope.wire.beneficiary.bank,
            $scope.countries
        );
    }

    function getWireCompanies() {
        wireCompaniesService.getAll().then(response => {
            $scope.wireCompaniesAll = response;
            filterWireCompanies();
            if ($scope.wireCompanies.length === 1) {
                $scope.wire.wireCompany = $scope.wireCompanies[0];
                getAccounts($scope.wire.wireCompany.id);
            }
        });
    }

    function filterWireCompanies() {
        $scope.wireCompanies = $scope.wireCompaniesAll.filter(company => company.isDliWireCompany);
    }

    function filterBeneficiaries() {
        $scope.internationalBenes = $scope.internationalBenesAll.filter(
            bene => bene.isForeignCurrency
        );
    }

    function loadFrequencyOptions() {
        $scope.wireFrequencies = dateConstants.paymentFrequencies;
    }

    function loadDays() {
        $scope.weekDays = dateConstants.weekDays;
    }

    function getAccounts(id, callback) {
        wireCompaniesService.getDebitAccounts(id, 'createDli').then(response => {
            $scope.companyAccounts = response;
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    function getWireConfiguration() {
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
            $scope.wire.wireConfig = response;
            setUsdLabel();
        });
    }

    function setUsdLabel() {
        if (
            $scope.canCreateFxWire &&
            $scope.wireConfig.fiDliProductEnabled &&
            $scope.wireConfig.IsDliCompanyProductEnabled &&
            $scope.wireConfig.IsCompanyDliUsdEnabled
        ) {
            $scope.usdLabel = ' USD';
        }
    }

    function findAccount(id) {
        getAccounts(id, () => {
            const modalInstance = openModal({
                template: require('../../wires/views/accountModalView.html'),
                size: 'md',
                controller: 'AccountsController',
                backdrop: 'static',
                resolve: {
                    wireId() {
                        return id;
                    },
                    companyAccounts() {
                        return $scope.companyAccounts;
                    },
                },
            });
            modalInstance.result.then(selectedAccount => {
                $scope.wire.debitAccount = angular.copy(selectedAccount);
            });
        });
    }

    $scope.isValidLookups = function () {
        let result = true;
        if (
            $scope.wire.debitAccount === null ||
            $scope.wire.debitAccount === undefined ||
            typeof $scope.wire.debitAccount !== 'object'
        ) {
            result = false;
        }
        if (
            $scope.wire.beneficiary === null ||
            $scope.wire.beneficiary === undefined ||
            typeof $scope.wire.beneficiary !== 'object'
        ) {
            result = false;
        }
        return result;
    };

    function print() {
        window.print();
    }

    function createAnotherFxWire() {
        $state.go($state.current, {}, { reload: true });
    }

    function findBeneficiaries(type) {
        const modalInstance = openModal({
            template: require('../../wires/views/beneficiaryModalView.html'),
            size: 'lg',
            controller: 'BeneficiarySelectionController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                beneficiaries() {
                    return $scope.beneficiaries;
                },
                enableFreeFormEntry() {
                    return false;
                },
            },
        });
        modalInstance.result.then(selectedBeneficiary => {
            $scope.wire.beneficiary = angular.copy(selectedBeneficiary);
            $scope.setBeneficiaryAndBankAddress();
        });
    }

    $scope.$watch(
        () => $scope.wire.beneficiary,
        data => {
            if (data && !data.status) {
                angular.forEach($scope.beneficiaries, bene => {
                    if (data.accountNumber === bene.accountNumber) {
                        data = bene;
                        $scope.wire.beneficiary = bene;
                        $scope.setBeneficiaryAndBankAddress();
                    }
                });
            }
        },
        true
    );

    function goBackToEdit() {
        $scope.errorMessage = '';
        $scope.errorDetails = [];
        $scope.wizardController.goToPreviousStep();
    }

    function getQuote() {
        wireService.getRateQuote($scope.wire).then(
            response => {
                $scope.wire.rateQuote = response;
            },
            error => {
                // TODO How should we handle an error?
            }
        );
    }

    function confirmUsd() {
        $scope.wire.isDliWire = true;
        $scope.wire.destinationCurrency = 'USD';
        securityService
            .verifyUser('CreateFxWire_ByName', $scope.wire, () =>
                wireService.acceptRateQuote($scope.wire)
            )
            .then(response => {
                if (response.errorSummary) {
                    $scope.errorMessage = '';
                    $scope.errorMessage = response.errorSummary.summaryMessage;
                    if (response.errorSummary.details) {
                        $scope.errorDetails = [];
                        response.errorSummary.details.forEach(detail => {
                            $scope.errorDetails.push(detail);
                        });
                    }
                } else {
                    $scope.errorMessage = '';
                    $scope.errorDetails = [];
                    $scope.success = true;
                    $scope.isConfirmation = true;
                    $scope.wizardController.goToNextStep();
                    $scope.wire = angular.copy(response);
                }
            });
    }

    function confirmAndAcceptQuote() {
        const modalInstance = openModal({
            template: require('../views/rateQuoteAcceptModal.html'),
            size: 'md',
            controller: 'RateQuoteAcceptModalController',
            backdrop: 'static',
            resolve: {
                wire() {
                    return $scope.wire;
                },
                cutOff() {
                    return $scope.cutOffWithTimeZone;
                },
            },
        });
        modalInstance.result.then(response => {
            if (response.errorSummary) {
                $scope.errorMessage = '';
                $scope.errorMessage = response.errorSummary.summaryMessage;
                if (response.errorSummary.details) {
                    $scope.errorDetails = [];
                    angular.forEach(response.errorSummary.details, detail => {
                        $scope.errorDetails.push(detail);
                    });
                }
            } else {
                $scope.errorMessage = '';
                $scope.errorDetails = [];
                $scope.success = true;
                $scope.isConfirmation = true;
                $scope.wizardController.goToNextStep();
                $scope.wire = angular.copy(response);
                timerService.stop('rateQuote');
            }
        });
    }

    function showRateQuoteDetails() {
        return $scope.wire.rateQuote && $scope.wire.destinationCurrency !== 'USD';
    }

    function disableReviewButton() {
        return (
            !$scope.isValidLookups() ||
            $scope.disableDliToday ||
            !($scope.wire.rateQuote || $scope.wire.destinationCurrencyCode === 'USD') ||
            $scope.disableDliOnNonProcessingDay ||
            !isBeneficiaryValid()
        );
    }

    function showContractAcceptedMessage() {
        return (
            $scope.isConfirmation &&
            $scope.wire.numberOfApprovalsNeeded > 0 &&
            $scope.wire.rateQuote &&
            $scope.wire.destinationCurrency !== 'USD'
        );
    }

    function launchTimeoutModal() {
        const timeoutModal = openModal({
            template: require('../views/rateQuoteExpiredModal.html'),
            size: 'md',
            controller: 'RateQuoteExpiredModalController',
            backdrop: 'static',
        });
        timeoutModal.result.then(() => {
            $scope.goBackToEdit();
        });
    }

    function onQuoteTimeout() {
        $scope.wire.rateQuote = null;
        launchTimeoutModal();
    }

    function getStartingBeneficiary() {
        beneficiariesService.get($scope.startingBeneficiaryId).then(response => {
            $scope.wire.beneficiary = response;
            $scope.setBeneficiaryAndBankAddress();
        });
    }

    function useThreeLineAddress() {
        return (
            !!$scope.wire &&
            !!$scope.wire.beneficiary &&
            beneficiariesService.canUseThreeLineAddress($scope.wire.beneficiary)
        );
    }

    function loadCountry() {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    }

    function getCountry(countryCode) {
        let result = null;
        angular.forEach($scope.countries, country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    function loadDomesticWireProductStatus() {
        dashboardService.getProductStatus().then(response => {
            const wireProduct = $filter('filter')(response, {
                type: 'WireTransferProductFeature',
            })[0];
            $scope.isDomesticWireProductActive = wireProduct && wireProduct.status === 'Active';
        });
    }

    init();
}
