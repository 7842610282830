import { Injectable } from '@jack-henry/frontend-utils/di';
import { getSearchParams } from '../functions';
import { Timer } from '../types';

/**
 * Wrapper for access to the window object.
 * Provides an injectable abstraction for properties and methods available on global state.
 */
@Injectable()
export class WindowService extends EventTarget {
    public readonly Date = Date;

    public get document() {
        return this.window.document;
    }

    public get location() {
        return this.window.location;
    }

    public get history() {
        return this.window.history;
    }

    public get console() {
        return this.window.console;
    }

    public get searchParams() {
        return getSearchParams();
    }

    public get sessionStorage() {
        return this.window.sessionStorage;
    }

    public get localStorage() {
        return this.window.sessionStorage;
    }

    /**
     * Exists as a getter to avoid holding a direct reference to the window object.
     *
     * This prevents circular reference infinite recursion when attempting
     * to clone objects with a direct or transitive reference to this service.
     */
    private get window() {
        return window;
    }

    public print() {
        this.window.print();
    }

    public addEventListener(...args: Parameters<Window['addEventListener']>) {
        this.window.addEventListener(...args);

        return {
            unsubscribe: () => this.window.removeEventListener(...args),
        };
    }

    public removeEventListener(...args: Parameters<Window['removeEventListener']>): void {
        this.window.removeEventListener(...args);
    }

    public createTimer(duration: number, handler?: () => void): Timer {
        const t = new Timer(duration, undefined, false);

        if (typeof handler === 'function') {
            t.then(handler);
        }

        t.start();
        return t;
    }
}
