/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { AchImportLayoutModelDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchImportLayoutClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags AchImportLayout
   * @name AchImportLayoutGet
   * @summary Gets an AchImportLayoutModel.
   * @request GET:/achImportLayout
   */
  achImportLayoutGet = (params: RequestParams = {}) =>
    this.http.request<AchImportLayoutModelDto, any>({
      path: `/achImportLayout`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchImportLayout
   * @name AchImportLayoutPut
   * @summary Saves an AchImportLayoutModel.
   * @request PUT:/achImportLayout
   */
  achImportLayoutPut = (achImportLayout: AchImportLayoutModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchImportLayoutModelDto, any>({
      path: `/achImportLayout`,
      method: "PUT",
      body: achImportLayout,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
