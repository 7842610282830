import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record, Recordset, RecordsetEvent } from '@treasury/FDL';
import { FetchResult, RecordsetColumn } from '@treasury/FDL/recordset';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import {
    AchRecipientsService,
    FetchRecipientsForMasterListParams,
    getAccountTypeIdFromAccountTypeDescription,
    getTransactionTypeIdFromTransactionType,
} from '@treasury/domain/channel/services/ach/ach-recipients-service';
import { DownloadEvent } from '@treasury/domain/channel/services/download/';
import {
    AchBank,
    ImportedAchRecipient,
    MasterListRecipient,
    PaymentRecipient,
    UpdateMasterListRecipientObject,
} from '@treasury/domain/channel/types/ach';
import { TmApiError } from '@treasury/domain/shared';
import { corePagingParameters } from '@treasury/domain/shared/utilities';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/layouts/omega-report';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { OmegaToastService } from '@treasury/omega/services/omega-toast';
import {
    LocalFilterEvent,
    OmegaReportDownloadFormat,
    OmegaReportLink,
} from '@treasury/omega/types';
import { exists, printNode } from '@treasury/utils';
import { LitElement, css, html, nothing, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../components/blocking-loader.js';
import { AchDomesticClient } from '../../ach-domestic/clients/ach-domestic-client';
import '../../components/ach-template-action-dialog.js';
import { listIcon } from '../../components/list-icon';
import { convertSelectedToPaymentRecipients } from '../data/ach-recipients-data';
import { createAchRecipientsFilters } from '../data/ach-recipients-filter-definitions';
import { getAchRecipientsColumns, recipientFields } from '../data/ach-recipients-table-data';
import { achSelectedRecipientsFields } from '../data/ach-selected-recipients-dialog-data';
import { AchRecipientsAddendaDialog } from '../dialogs';
import '../dialogs/ach-recipient-details-dialog';
import { AchRecipientFileUploadDialog } from '../dialogs/ach-recipient-file-upload-dialog';
import '../dialogs/ach-recipients-addenda-dialog';
import {
    renderAchSelectedRecipientsDialog,
    renderDownloadDialog,
    renderReportInfo,
} from '../shared/templates';
import '../shared/templates/ach-import-recipients-dialog';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));

type RecipientsForMasterListParams = Omit<
    FetchRecipientsForMasterListParams,
    'pagingParameters' | 'institution' | 'searchText'
> & {
    sort: RecordsetColumn<MasterListRecipient>[];
};

@customElement('ach-recipients-container')
export class AchRecipientsContainer extends AlertingListeningElement {
    public readonly title = 'ACH Recipients';

    private readonly reportInfoElement = renderReportInfo(this.title);

    private readonly downloadOptions: OmegaReportDownloadFormat[] = ['CSV', 'PDF'];

    private readonly toolbarOptions = ['download', 'print'];

    @InjectProperty()
    private declare readonly achRecipientsService: AchRecipientsService;

    @InjectProperty()
    private declare readonly toastService: OmegaToastService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    private filters: any = [];

    @state()
    private localFilters: any = [];

    @state()
    achDomesticClient: AchDomesticClient = new AchDomesticClient();

    @state()
    recordset!: Recordset<MasterListRecipient>;

    @state()
    private isLoading = false;

    @state()
    private isDownloading = false;

    @state()
    actions: any;

    @state()
    reportActions: any;

    @state()
    selectedRecord!: Record<MasterListRecipient>;

    @state()
    createRecipient = false;

    @state()
    private columns?: Array<any>;

    @property({
        type: String,
    })
    public institution!: string;

    @state()
    private selectedRecipient = {} as Record<MasterListRecipient>;

    @state()
    private showRecipientDetailDialog = false;

    @state()
    private showImportRecipientsDialog = false;

    @state()
    private uploadedRecipientsFromFile: PaymentRecipient[] = [];

    @property({ type: Object })
    public alert: OmegaAlertConfig = {
        visible: false,
        message: '' as any | typeof html as any,
        code: '',
        time: '',
        title: '',
        type: 'success',
        posture: 'polite' as any,
        actions: html as any,
    };

    @state()
    private selectedRecipients: Array<Record<MasterListRecipient>> = [];

    @state()
    private selectedRecipientsList!: Recordset<MasterListRecipient>;

    @state()
    openAchSelectedRecipientsDialog = false;

    addListeners = () => {
        this.listenTo(this.recordset, RecordsetEvent.PageChanged, () => {
            this.createRecipient = false;
            this.recordset.allRecords.forEach(currentRecord => {
                const currentRecordId = currentRecord.getField('id');
                this.selectedRecipients.forEach(selectedRecipient => {
                    if (currentRecordId === selectedRecipient.getField('id')) {
                        currentRecord.setField('selected', true);
                    }
                });
            });
            this.removeDuplicatesFromSelected();
        });

        this.listenTo(this.recordset, RecordsetEvent.Updated, (detail: CustomEvent) => {
            const fieldFromEvent = detail.detail.field;
            const recordFromEvent = detail.detail.record;

            if (recordFromEvent && fieldFromEvent === 'selected') {
                const recordFromEventIsSelected = recordFromEvent.getField('selected');
                if (recordFromEventIsSelected) this.selectedRecipients.push(recordFromEvent);

                if (!recordFromEventIsSelected)
                    this.selectedRecipients = this.selectedRecipients.filter(
                        selectedRecipient =>
                            selectedRecipient.getField('id') !== recordFromEvent.getField('id')
                    );

                this.setRecipientsListReportAction();
            }
        });
    };

    setRecipientsListReportAction = () => {
        this.removeDuplicatesFromSelected();

        const selectedRecipientsCount = this.selectedRecipients.length;

        this.reportActions.pop(); // Only works if recipientsListReportAction is last item

        this.reportActions = [
            ...this.reportActions,
            this.selectedRecipientsAction(selectedRecipientsCount),
        ];
    };

    selectedRecipientsAction = (selectedRecipientsCount: number) => ({
        type: 'link',
        label: html`${listIcon}&nbsp;&nbsp;${selectedRecipientsCount} Selected`,
        action: () => {
            this.openAchSelectedRecipientsDialog = true;
        },
        isDisabled: () => false,
        visibleWhen: () => selectedRecipientsCount > 0,
    });

    private get reportLinks() {
        const links: OmegaReportLink[] = [
            {
                title: 'ACH Recipient Activity',
                url: `/${this.institution}/recipients-activity`,
            },
        ];

        return links;
    }

    public async firstUpdated() {
        const canApprove = await EntitlementsService.instance.hasEntitlement('Approve Recipient');
        const canEdit = await EntitlementsService.instance.hasEntitlement('Edit Recipient');
        const canDelete = await EntitlementsService.instance.hasEntitlement('Delete Recipient');
        const canCreate = await EntitlementsService.instance.hasEntitlement('Create Recipient');
        this.columns = getAchRecipientsColumns(canApprove, canEdit, canDelete);
        this.recordset = new Recordset(
            recipientFields(),
            args => this.hydrateRecordset(args) as Promise<FetchResult<MasterListRecipient>>,
            0,
            true
        );
        this.filters = createAchRecipientsFilters();
        this.actions = {
            selectRecipient: (record: Record<MasterListRecipient>) => {
                this.selectedRecipient = record;
                this.showRecipientDetailDialog = true;
            },
            openAddendaDialog: (record: Record<MasterListRecipient>) => {
                this.selectedRecord = record;
                AchRecipientsAddendaDialog.open(this);
            },
            delete: async (record: Record<MasterListRecipient>) => {
                this.isLoading = true;
                await this.achRecipientsService.deleteRecipients([record.values.id]);
                this.toastService.render([
                    { message: 'Recipient deleted successfully', type: 'success' },
                ]);
                this.isLoading = false;
                await this.recordset.requestUpdate();
            },
            edit: (selectedRecord: Record<MasterListRecipient>) => {
                this.recordset.allRecords.forEach((record: Record<MasterListRecipient>) => {
                    record.setField('editable', false);
                });
                selectedRecord.setField('editable', true);
                const routingNumber = selectedRecord.getField('routingNumber');
                return selectedRecord
                    .fieldTypeForField('routingNumber')
                    .options(selectedRecord)
                    .then((options: AchBank[]) => {
                        const routingNumberMatch = options.find(
                            (option: AchBank) => Number(option.bankId) === Number(routingNumber)
                        );

                        selectedRecord.setField(
                            'routingNumber',
                            routingNumberMatch?.bankId.toString()
                        );
                        return options;
                    })
                    .then(() => selectedRecord.setField('editable', true));
            },
            cancel: (selectedRecord: Record<MasterListRecipient>) => {
                if (
                    this.createRecipient &&
                    selectedRecord.getField('achMasterRecipientStatusTypeDescription') !== 'Ready'
                ) {
                    this.recordset.deleteRecord(selectedRecord);
                    this.createRecipient = false;
                }
                selectedRecord.reset();
            },
            save: async (selectedRecord: Record<MasterListRecipient>) => {
                try {
                    const { values: recordValues } = selectedRecord;
                    if (this.createRecipient) {
                        const createRecipientObj = {
                            addenda: recordValues.addendaRaw,
                            accountNumber: recordValues.accountNumber,
                            accountTypeId: getAccountTypeIdFromAccountTypeDescription(
                                selectedRecord.values.accountTypeDescription
                            ),
                            defaultAmount: recordValues.defaultAmount,
                            recipientIdNumber: recordValues.recipientIdNumber,
                            recipientName: recordValues.recipientName,
                            routingNumber: recordValues.routingNumber,
                            transactionTypeId: getTransactionTypeIdFromTransactionType(
                                selectedRecord.values.transactionType
                            ),
                        };
                        this.isLoading = true;
                        await this.achRecipientsService.createRecipientsForMasterList([
                            createRecipientObj,
                        ]);
                        this.toastService.render([
                            {
                                message: 'Recipient saved successfully!',
                                type: 'success',
                            },
                        ]);
                        this.isLoading = false;
                        await this.recordset.requestHardUpdate();
                        this.recordset.allRecords.forEach((record: Record<MasterListRecipient>) => {
                            record.setField('editable', false);
                        });

                        this.createRecipient = false;
                    } else {
                        const updateRecipientObj: UpdateMasterListRecipientObject = {
                            addenda: recordValues.addendaRaw,
                            accountNumber: recordValues.accountNumber,
                            accountTypeId: getAccountTypeIdFromAccountTypeDescription(
                                selectedRecord.values.accountTypeDescription
                            ),
                            defaultAmount: recordValues.defaultAmount,
                            recipientIdNumber: recordValues.recipientIdNumber,
                            recipientName: recordValues.recipientName,
                            routingNumber: recordValues.routingNumber,
                            transactionTypeId: getTransactionTypeIdFromTransactionType(
                                selectedRecord.values.transactionType
                            ),
                            transactionType: recordValues.transactionType,
                            accountTypeDescription: recordValues.accountTypeDescription,
                            id: recordValues.id,
                        };
                        this.isLoading = true;
                        await this.achRecipientsService.updateRecipientsForMasterList([
                            updateRecipientObj,
                        ]);
                        this.toastService.render([
                            {
                                message: 'Recipient updated successfully!',
                                type: 'success',
                            },
                        ]);
                        this.isLoading = false;
                        await this.recordset.requestHardUpdate();
                        this.recordset.allRecords.forEach((record: Record<MasterListRecipient>) => {
                            record.setField('editable', false);
                        });
                        this.createRecipient = false;
                    }
                } catch (error) {
                    this.createRecipient = false;
                    this.isLoading = false;

                    if (error instanceof TmApiError) {
                        const { message, errorCode: code, timestamp: time } = error;
                        this.alert = {
                            ...this.alert,
                            visible: true,
                            type: 'error',
                            actions: '',
                            title: 'Error',
                            posture: 'assertive',
                            message,
                            code,
                            time,
                        };
                    } else {
                        this.alert = {
                            ...this.alert,
                            visible: true,
                            type: 'error',
                            title: 'Error',
                            posture: 'assertive',
                            message: 'Save failed. Please try again.',
                        };
                    }
                }
            },
            approve: async (record: Record<MasterListRecipient>) => {
                this.isLoading = true;
                await this.achRecipientsService.approveRecipients([record.values.id]);
                this.isLoading = false;
                await this.recordset.requestHardUpdate();
            },
            reject: async (record: Record<MasterListRecipient>) => {
                this.isLoading = true;
                await this.achRecipientsService.rejectRecipients([record.values.id]);
                this.isLoading = false;
                await this.recordset.requestHardUpdate();
            },
        };
        this.reportActions = [
            {
                type: 'primary',
                label: 'Create Payment From Selected',
                action: () => {
                    const paymentRecipients: Array<PaymentRecipient> =
                        convertSelectedToPaymentRecipients(this.selectedRecipients);
                    this.navService.navigate('payables.ach.payments.create-dark', {
                        paymentRecipients,
                    });
                },
                isDisabled: () => {
                    const anyPendingApproval = this.selectedRecipients.some(
                        (r: Record<MasterListRecipient>) =>
                            r.values.achMasterRecipientStatusTypeId === 1
                    );
                    const anyRejected = this.selectedRecipients.some(
                        (r: Record<MasterListRecipient>) =>
                            r.values.achMasterRecipientStatusTypeId === 3
                    );

                    if (this.selectedRecipients.length < 1) return true;
                    return anyPendingApproval || anyRejected;
                },
            },
            {
                type: 'approve',
                label: 'Approve Selected',
                action: async () => {
                    const selectedRecords = this.recordset.recordsMatching('selected', true);
                    const selectedRecordsIds = selectedRecords.map(
                        (r: Record<MasterListRecipient>) => r.values.id
                    );
                    this.isLoading = true;
                    await this.achRecipientsService.approveRecipients(selectedRecordsIds);
                    this.isLoading = false;
                    await this.recordset.requestHardUpdate();
                },
                isDisabled: () => {
                    const noneSelected = this.recordset?.noRecordsMatch('selected', true);
                    const selectedRecords = this.recordset.recordsMatching('selected', true);
                    const canApproveRecipient = (recipient: MasterListRecipient) =>
                        recipient.canApproveReject && canApprove;

                    if (noneSelected || !canApprove) return true;

                    return !selectedRecords.every((r: Record<MasterListRecipient>) =>
                        canApproveRecipient(r.values)
                    );
                },
            },
            {
                type: 'reject',
                label: 'Reject Selected',
                action: async () => {
                    const selectedRecords = this.recordset.recordsMatching('selected', true);
                    const selectedRecordsIds = selectedRecords.map(
                        (r: Record<MasterListRecipient>) => r.values.id
                    );
                    this.isLoading = true;
                    await this.achRecipientsService.rejectRecipients(selectedRecordsIds);
                    this.isLoading = false;
                    await this.recordset.requestHardUpdate();
                },
                isDisabled: () => {
                    const noneSelected = this.recordset?.noRecordsMatch('selected', true);
                    const selectedRecords = this.recordset.recordsMatching('selected', true);
                    const canApproveRecipient = (recipient: MasterListRecipient) =>
                        recipient.canApproveReject && canApprove;

                    if (noneSelected || !canApprove) return true;

                    return !selectedRecords.every((r: Record<MasterListRecipient>) =>
                        canApproveRecipient(r.values)
                    );
                },
            },
            {
                type: 'reject',
                label: 'Delete Selected',
                action: async () => {
                    const selectedRecords = this.recordset.recordsMatching('selected', true);
                    const selectedRecordsIds = selectedRecords.map(
                        (selectedRecord: Record<MasterListRecipient>) => selectedRecord.values.id
                    );
                    this.isLoading = true;
                    await this.achRecipientsService.deleteRecipients(selectedRecordsIds);
                    this.toastService.render([
                        { message: 'Recipient deleted successfully', type: 'success' },
                    ]);
                    this.isLoading = false;
                    await this.recordset.requestHardUpdate();
                },
                isDisabled: () => this.recordset?.noRecordsMatch('selected', true),
                visibleWhen: () => canDelete,
            },
            {
                type: 'link',
                label: 'Import Recipients from File',
                action: () => AchRecipientFileUploadDialog.open(this),
                isDisabled: () => false,
                visibleWhen: () => canCreate,
                alignment: 'Right',
            },
            {
                type: 'link',
                label: 'Add Recipient',
                action: () => {
                    this.recordset.insertDefaultRecord();
                    const newRecord =
                        this.recordset.sortedFilteredRecords[
                            this.recordset.sortedFilteredRecords.length - 1
                        ];

                    newRecord.setField('editable', true);
                    newRecord.setField('addendaRaw', '');
                    this.createRecipient = true;
                },
                isDisabled: () => this.createRecipient,
                visibleWhen: () => canCreate,
                alignment: 'Right',
            },
            {
                ...this.selectedRecipientsAction(0),
            },
        ];
        this.addListeners();
    }

    private hydrateRecordset(params: RecipientsForMasterListParams) {
        const { sort, startIndex, pageSize } = params;

        return this.achRecipientsService.fetchRecipientsForMasterList({
            ...params,
            pagingParameters: this.createPagingParams(sort, startIndex, pageSize),
            institution: this.institution,
            searchText: this.localFilters.searchText,
        });
    }

    private createPagingParams(
        cols: RecordsetColumn<MasterListRecipient>[],
        startIndex: number,
        pageSize: number
    ) {
        return corePagingParameters('RecipientName', cols, startIndex, pageSize);
    }

    private removeDuplicatesFromSelected() {
        this.selectedRecipients.forEach(selectedRecipient => {
            const selectedRecipientId = selectedRecipient.getField('id');
            this.selectedRecipients = this.selectedRecipients.filter(
                recipient => recipient.getField('id') !== selectedRecipientId
            );
            this.selectedRecipients.push(selectedRecipient);
        });
    }

    private async handleDownload({ detail }: DownloadEvent<any>) {
        this.isDownloading = true;
        try {
            const { type, filter } = detail;
            await AchRecipientsService.downloadAchRecipients({
                type,
                page: 'ACHMasterRecipientsList',
                params: filter,
                startIndex: this.recordset.pageIndex,
                pageSize: this.recordset.pageSize,
            });
        } finally {
            this.isDownloading = false;
        }
    }

    async handlePrint() {
        const printRecordset = new Recordset(recipientFields(), () => this.recordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        const printableDiv = document.createElement('div');
        const printArray = this.columns;
        if (printArray && printArray[0].type === 'checkbox') {
            printArray?.shift();
        }
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${printArray}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.title, printableDiv);
    }

    private onFilter(event: LocalFilterEvent<any>) {
        const { detail } = event;
        this.localFilters = detail;
        this.recordset.requestHardUpdate();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    recipientEqualityCheck(existing: any, original: any) {
        const keys1 = Object.keys(existing);
        for (const key of keys1) {
            if (existing[key] !== original[key] && key !== 'bank') {
                return false;
            }
        }
        return true;
    }

    async uploadFileRecipients(event: CustomEvent) {
        try {
            this.isLoading = true;
            const [file] = event.detail.files;
            const { format } = event.detail;
            const recipients = await this.achDomesticClient.uploadRecipients(file, format);
            this.uploadedRecipientsFromFile = recipients.map((recipient: PaymentRecipient) => ({
                ...recipient,
                accountType:
                    recipient.accountType === 'GL' ? 'General Ledger' : recipient.accountType,
            }));
            this.showImportRecipientsDialog = true;

            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            if (error instanceof TmApiError) {
                const { errorCode: code, timestamp: time } = error;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    actions: '',
                    title: 'Error',
                    posture: 'assertive',
                    message: error.message,
                    code,
                    time,
                };
            } else {
                console.error(error);
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    title: 'Error',
                    posture: 'assertive',
                    message: 'Unable to complete upload. Please try again.',
                };
            }
        }
    }

    closeAchSelectedRecipientsDialog() {
        this.openAchSelectedRecipientsDialog = false;

        const recordsDeselectedFromList = this.selectedRecipientsList.recordsMatching(
            'selected',
            false
        );

        recordsDeselectedFromList.forEach(recipientFromList => {
            this.recordset.allRecords.forEach(masterRecord => {
                const masterRecordId = masterRecord.getField('id');
                const recipientFromListId = recipientFromList.getField('id');
                if (masterRecordId === recipientFromListId) {
                    masterRecord.setField('selected', false);
                }
            });
        });

        this.selectedRecipients = this.selectedRecipients.filter(selectedRecipient =>
            selectedRecipient.getField('selected')
        );

        this.setRecipientsListReportAction();
    }

    handleRenderAchSelectedRecipientsDialog() {
        const selectedRecipientsForList: Array<MasterListRecipient> = this.selectedRecipients.map(
            record => record.values
        );

        this.selectedRecipientsList = new Recordset(achSelectedRecipientsFields(), () => []);

        this.selectedRecipientsList.setData(selectedRecipientsForList);

        return renderAchSelectedRecipientsDialog({
            selectedRecipientsRecordset: this.selectedRecipientsList,
            openDialogStatus: this.openAchSelectedRecipientsDialog,
            closeDialog: () => this.closeAchSelectedRecipientsDialog(),
        });
    }

    private renderFilterBar() {
        const { onFilter } = this;
        if (!exists(onFilter)) {
            return nothing;
        }

        return html`<omega-filter-bar
            .recordset=${this.recordset}
            @change=${(e: CustomEvent) => this.onFilter(e)}
        ></omega-filter-bar>`;
    }

    private renderRecipientDetailDialog() {
        if (!Object.keys(this.selectedRecipient).length) {
            return nothing;
        }

        return html`<ach-recipient-details-dialog
            .recipient=${this.selectedRecipient}
            .open=${this.showRecipientDetailDialog}
            @close=${() => {
                this.selectedRecipient = {} as Record<MasterListRecipient>;
            }}
        ></ach-recipient-details-dialog>`;
    }

    renderBlockingLoader() {
        if (!this.isLoading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    renderImportRecipientsDialog() {
        if (!this.showImportRecipientsDialog) return nothing;

        // eslint-disable-next-line no-return-assign
        const setSelectedRecord = (record: Record<MasterListRecipient>) =>
            (this.selectedRecord = record);

        return html`<ach-import-recipients-dialog
            .open=${this.showImportRecipientsDialog}
            .importedRecipients=${this.uploadedRecipientsFromFile}
            @save=${async (e: CustomEvent) => {
                const recordValues = e.detail;
                const mappedRecipients = recordValues.map((values: ImportedAchRecipient) => ({
                    addenda: values.addendaRaw,
                    accountNumber: values.accountNumber,
                    accountTypeId: getAccountTypeIdFromAccountTypeDescription(values.accountType),
                    defaultAmount: values.amount,
                    recipientIdNumber: values.idNumber,
                    recipientName: values.name,
                    routingNumber: values.routingNumber,
                    transactionTypeId: getTransactionTypeIdFromTransactionType(
                        values.transactionType
                    ),
                }));

                this.isLoading = true;
                await this.achRecipientsService.createRecipientsForMasterList(mappedRecipients);
                this.toastService.render([
                    { message: 'Recipients saved successfully!', type: 'success' },
                ]);
                this.isLoading = false;
                await this.recordset.requestHardUpdate();
            }}
            @close=${() => {
                this.showImportRecipientsDialog = false;
            }}
            .setSelectedRecord=${setSelectedRecord}
        ></ach-import-recipients-dialog>`;
    }

    renderDialogs() {
        return html`
            ${this.renderImportRecipientsDialog()} ${this.renderRecipientDetailDialog()}
            ${renderDownloadDialog(this.isDownloading, () => {
                this.isDownloading = false;
            })}
            <ach-recipients-addenda-dialog
                .record=${this.selectedRecord}
            ></ach-recipients-addenda-dialog>

            <ach-recipient-file-upload-dialog
                @upload=${this.uploadFileRecipients}
            ></ach-recipient-file-upload-dialog>

            ${this.handleRenderAchSelectedRecipientsDialog()}
        `;
    }

    public render() {
        if (this.filters.length < 1) {
            return nothing;
        }

        return html` ${this.renderAlert()} ${this.renderBlockingLoader()} ${this.renderDialogs()}
            <div class="report-container">
                <omega-report
                    flyout
                    autostart
                    .reportInformation=${this.reportInfoElement}
                    .title=${this.title}
                    .columns=${this.columns}
                    .recordset=${this.recordset}
                    .localFilters=${this.localFilters}
                    .options=${this.toolbarOptions}
                    .printFunction=${() => this.handlePrint()}
                    .downloadOptions=${this.downloadOptions}
                    .downloadFunction=${this.handleDownload}
                    @reportDownload=${(event: DownloadEvent<any>) => this.handleDownload(event)}
                    .reportLinks=${this.reportLinks}
                    .filters=${this.filters}
                    .actions=${this.actions}
                    .reportActions=${this.reportActions}
                >
                    <div id="top-of-table" slot="above-table">${this.renderFilterBar()}</div>
                </omega-report>
            </div>`;
    }

    static styles = css`
        .report-container {
            height: 100%;
            display: block;
        }

        .padded {
            padding: 10px;
        }

        .row {
            display: flex;
            flex-direction: row;
        }

        .row > * {
            flex: auto;
        }

        .full-width {
            width: 100%;
        }

        .right {
            text-align: right;
        }

        .text-center {
            text-align: center;
        }

        .text-right {
            text-align: right;
        }

        .dialog-body {
            padding: 20px;
        }

        #top-of-table {
            margin: 0px 15px;
        }
    `;
}
