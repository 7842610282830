import { GetUserAchLimitsModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class AchLimits extends DomainEntity<GetUserAchLimitsModelDto> {
    public createDefault() {
        return new AchLimits({} as GetUserAchLimitsModelDto) as this;
    }

    public get dailyLimit() {
        return this.dto.productDailyLimit;
    }

    public get achLimits() {
        return {
            initiationLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
            },
            approvalLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
            },
        };
    }

    public get userAchCompanies() {
        return this.dto.userAchCompanies?.map(company => ({
            companyName: company.achCompany?.companyName,
            companyId: company.achCompany?.companyId,
            id: company.achCompany?.id,
            transactionLimit: {
                value: getDisplayValue(
                    company.userAchCompanyLimit?.perTransactionLimit,
                    company.pendingUserAchCompanyLimit?.perTransactionLimit
                ),
                edited: isEdited(
                    company.userAchCompanyLimit?.perTransactionLimit,
                    company.pendingUserAchCompanyLimit?.perTransactionLimit
                ),
            },
            dailyLimit: {
                value: getDisplayValue(
                    company.userAchCompanyLimit?.dailyLimit,
                    company.pendingUserAchCompanyLimit?.dailyLimit
                ),
                edited: isEdited(
                    company.userAchCompanyLimit?.dailyLimit,
                    company.pendingUserAchCompanyLimit?.dailyLimit
                ),
            },
            companyDailyLimit: company.achCompany?.totalDailyLimit || 9999999999999.99,
        }));
    }
}
