import { TmHttpClient } from '@treasury/core/http';
import removeUnusedKeys from '../../../shared/utilities/remove-unused-keys.js';
import {
    mapRecurringAchFiltersToRequest,
    mapResponseToRecurringAchFields,
} from '../../mappings/ach/ach-recurring-payments.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import { RecurringAchRequests } from '../../requests/ach/recurring-ach-requests.js';
import SecCodesRequests from '../../requests/codes/sec-codes-requests.js';
import DownloadRequests from '../../requests/download/download.js';

export default class AchRecurringServices {
    // eslint-disable-next-line no-unused-vars
    static async fetchRecurringAchActivity({ parameters, startIndex, pageSize, page, sort }) {
        const body = mapRecurringAchFiltersToRequest(parameters);
        const fetchedData = await RecurringAchRequests.fetchRecurringAchActivity(body);
        const mappedData = mapResponseToRecurringAchFields(fetchedData);
        return {
            data: mappedData,
            totalCount: mappedData.length,
        };
    }

    static async fetchRecurringAchPaymentsAndTotals({ parameters }, type = 'Recurring') {
        if (!Object.keys(parameters).length) {
            return {
                data: [],
                totalCount: 0,
                totals: [],
            };
        }
        const body = mapRecurringAchFiltersToRequest(parameters);
        const http = await TmHttpClient.getInstance();
        const fetchedData = await http.request(`ach/payments/search?type=${type}`, {
            method: 'POST',
            body,
        });
        const mappedData = mapResponseToRecurringAchFields(fetchedData.results);
        return {
            data: mappedData,
            totalCount: mappedData.length,
            totals: fetchedData.totals,
        };
    }

    static async downloadRecurringAchPayments(type, page, params) {
        const filters = removeUnusedKeys(mapRecurringAchFiltersToRequest(params));
        const downloadFormats = ['PDF'];
        return DownloadRequests.download(
            null,
            type,
            page,
            { ...filters, page: 'RecurringACHPaymentList' },
            downloadFormats
        );
    }

    static async getSecCodes() {
        const data = await SecCodesRequests.getSecCodes();
        if (!data.includes('All')) {
            data.unshift('All');
        }
        return data;
    }

    static async getCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }
}
