export const nullUserTimeAccess = {
    timeRestricted: false,
    timeRestrictedPending: false,
    userId: 0,
    userTimeAccessDays: [],
    userTimeAccessDaysPending: [],
    success: false,
    // eslint-disable-next-line no-restricted-syntax
    errorMessage: null,
};
