import { PositivePayExceptionRequests } from '@treasury/domain/channel/requests/positive-pay/positive-pay-exception-requests.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import {
    EXCEPTION_REASONS,
    RETURN_REASON_OPTION,
} from '@treasury/domain/channel/types/arp/constants.js';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import {
    amountRange,
    dateRange,
    dateRangePickerNoDefault,
    number,
    string,
} from '@treasury/policy/primitives';
import { html, LitElement, nothing } from 'lit';
import '../../components/blocking-loader.js';
import { thisWeek } from '../../wires/data/filter-definitions.ts';
import checkExceptionFields from './data/check-exception-fields.js';
import { setColumns } from './data/table-columns.js';
import { createCheckExceptionDetail } from './parts/check-exception-detail.js';

class CheckExceptionDecisionActivity extends LitElement {
    static get properties() {
        return {
            institution: String,
            loading: Boolean,
            loadingFilters: Boolean,
            filters: Array,
            exceptions: Object,
            recordset: Object,
            localFilters: Array,
            arpExceptionAccounts: Array,
            loadingImages: Boolean,
        };
    }

    constructor() {
        super();
        this.loadingFilters = true;
        this.rowsPerPage = 25;
    }

    async firstUpdated() {
        await this.getArpExceptionAccounts();
        this.options = await PositivePayExceptionRequests.getPositivePayCompanyConfiguration();
        this.hasReturnReason =
            this.hasOption(RETURN_REASON_OPTION) &&
            EntitlementsService.instance.hasEntitlement('Feature.PositivePay.ReturnReason');
        this.hasAttachmentEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.CheckException.Attachments'
        );
        this.fields = checkExceptionFields([], false, this.hasReturnReason);
        this.columns = setColumns(false, this.hasReturnReason, true);
        this.filters = [
            {
                field: 'decisionTaken',
                fieldType: string.with
                    .options([
                        { text: 'All', value: '' },
                        { text: 'Pay', value: 'Pay' },
                        { text: 'Return', value: 'Return' },
                    ])
                    .thatIs.inline()
                    .thatHas.label('Decision Status')
                    .as.tag('omega-select'),

                value: '',
            },
            {
                field: 'account',
                fieldType: string.with
                    .options({
                        data: this.arpExceptionAccounts,
                        text: record => record.accountDisplayLabel,
                        value: record => record.accountUniqueId,
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatHas.label('Account')
                    .as.tag('omega-select'),
                value: this.arpExceptionAccounts.map(account => account.accountUniqueId),
            },
            {
                label: 'Check Number',
                field: 'checkNumber',
                fieldType: number.with
                    .defaultValue('')
                    .and.label('Check Number')
                    .as.tag('omega-input'),
            },
            {
                field: 'paidAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .and.label('Paid Amount')
                    .as.tag('omega-rage'),
                value: ['specific', 0],
            },
            {
                field: 'issuedAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .and.label('Issued Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'postedDate',
                fieldType: dateRange.and.label('Posted Date').as.tag('omega-datepicker'),
                value: thisWeek,
            },
            {
                field: 'issuedDate',
                fieldType: dateRangePickerNoDefault.and
                    .label('Issued Date')
                    .as.tag('omega-datepicker'),
            },
            {
                field: 'issuedPayee',
                fieldType: string.with.label('Issued Payee').as.tag('omega-input'),
            },
            {
                field: 'exceptionReasons',
                fieldType: string.with
                    .label('Exception Reason')
                    .with.options({
                        data: EXCEPTION_REASONS.map(reason => ({ text: reason, value: reason })),
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatHas.defaultValue(EXCEPTION_REASONS.map(reason => reason))
                    .as.tag('omega-select'),
            },
        ];
        this.recordset = new Recordset(this.fields, CheckExceptionsServices.decisionActivity);
    }

    async getArpExceptionAccounts() {
        try {
            this.arpExceptionAccounts =
                await PositivePayExceptionRequests.getArpExceptionAccounts();
        } catch (e) {
            const message = e instanceof Error ? e.message : 'Could not get ARP accounts.';
            this.alert = { ...this.alert, message, visible: true, type: 'error' };
        } finally {
            this.loadingFilters = false;
        }
    }

    async handleDownload(e) {
        this.downloading = true;
        try {
            await PositivePayExceptionRequests.downloadArpExceptionsList(e.detail, 'History');
        } catch (error) {
            const message =
                error instanceof Error ? error.message : 'Could not download. Please try again.';
            this.alert = { ...this.alert, message, visible: true, type: 'error' };
        } finally {
            this.downloading = false;
        }
    }

    hasOption(optionName) {
        const foundOption = this.options.find(option => option.name === optionName);
        return foundOption && foundOption.value === '1';
    }

    renderBlockingLoader() {
        return this.loadingImages ? html`<blocking-loader></blocking-loader>` : nothing;
    }

    render() {
        if (!this.recordset || this.loadingFilters) return nothing;
        return html`
            ${this.renderBlockingLoader()}
            <omega-report
                title="Check Exceptions - Decision Activity"
                .filters=${this.filters}
                .fields=${this.fields}
                .columns=${this.columns}
                .localFilters=${this.localFilters}
                .reportLinks=${[
                    {
                        title: 'Check Exceptions',
                        url: `${window.location.origin}/${this.institution}/payables/arp/check-exceptions`,
                    },
                    {
                        title: 'Issued Items Activity',
                        url: `${window.location.origin}/${this.institution}/payables/arp/issued-items-activity`,
                    },
                ]}
                .options=${['save', 'print', 'download']}
                .recordset=${this.recordset}
                .detailFunction=${createCheckExceptionDetail(
                    false,
                    true,
                    this.hasAttachmentEntitlement
                )}
                .downloadOptions=${['CSV', 'PDF']}
                .rowsPerPage=${this.rowsPerPage}
                @reportDownload=${this.handleDownload}
                @loadingCheckImage=${e => {
                    this.loadingImages = e.detail;
                }}
                autostart
                flyout
            >
                <div style="margin-left:16px;" slot="above-table">
                    <omega-filter-bar
                        .recordset=${this.recordset}
                        @change=${({ detail }) => {
                            this.localFilters = detail;
                        }}
                    ></omega-filter-bar>
                </div>
            </omega-report>
        `;
    }
}

window.customElements.define('check-exception-decision-activity', CheckExceptionDecisionActivity);
export default CheckExceptionDecisionActivity;
