/* eslint-disable no-restricted-syntax */
import { Frequency, FrequencyType } from './frequency.dto';

export const DefaultFrequency: Frequency = {
    type: undefined,
    valueDate: null,
    initiatedDate: null,
    repeatOn: null,
    startOn: null,
    endOn: null,
    nextPaymentDate: null,
    noEndDate: null,
    repeatOnDay1: null,
    repeatOnDay2: null,
    repeatOnLastBusinessDay: null,
    summary: null,
    updatedBy: null,
    updatedDate: null,
};

export interface FrequencyConstructorInput {
    // same as PaymentFrequency, but fields are optional in constructor input
    // effectiveDate?: string;
    type?: FrequencyType | null | undefined;
    valueDate?: string | null;
    repeatOn?: string | null;
    startOn?: string | null;
    endOn?: string | null;
    nextPaymentDate?: string | null;
    noEndDate?: boolean | null;
    repeatOnDay1?: number | null;
    repeatOnDay2?: number | null;
    repeatOnLastBusinessDay?: boolean | null;
    summary?: string | null;
    id?: number | null;
    updatedBy?: string | null;
    updatedDate?: string | null;
    initiatedDate?: string | null;
}

export function FrequencyDataConstructor(frequencyBase: FrequencyConstructorInput): Frequency {
    return {
        valueDate: frequencyBase.valueDate ?? null,
        initiatedDate: frequencyBase.initiatedDate ?? DefaultFrequency.initiatedDate,
        type: frequencyBase.type ?? DefaultFrequency.type,
        repeatOn: frequencyBase.repeatOn ?? DefaultFrequency.repeatOn,
        startOn: frequencyBase.startOn ?? DefaultFrequency.startOn,
        endOn: frequencyBase.endOn ?? DefaultFrequency.endOn,
        nextPaymentDate: frequencyBase.nextPaymentDate ?? DefaultFrequency.nextPaymentDate,
        noEndDate: frequencyBase.noEndDate ?? DefaultFrequency.noEndDate,
        repeatOnDay1: frequencyBase.repeatOnDay1 ?? DefaultFrequency.repeatOnDay1,
        repeatOnDay2: frequencyBase.repeatOnDay2 ?? DefaultFrequency.repeatOnDay2,
        repeatOnLastBusinessDay:
            frequencyBase.repeatOnLastBusinessDay ?? DefaultFrequency.repeatOnLastBusinessDay,
        summary: frequencyBase.summary ?? DefaultFrequency.summary,
        id: frequencyBase.id ?? DefaultFrequency.id,
        updatedBy: frequencyBase.updatedBy ?? DefaultFrequency.updatedBy,
        updatedDate: frequencyBase.updatedDate ?? DefaultFrequency.updatedDate,
    };
}
