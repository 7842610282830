import { getCreditorIsoAddress } from '@treasury/domain/wires';

/**
 * @typedef { ReturnType<import('../../../../services').WireService> } WireService
 * @typedef { ReturnType<import('../../../../../../domain/wires').WireIsoService> } WireIsoService
 * @typedef { import('@treasury/api/channel').WireModelDto } WireDto
 */

/**
 * @typedef { object } LocalScope
 * @property { WireDto | null } wire
 */

/**
 * @typedef { LocalScope & ng.IScope } AugmentedScope
 */

/**
 *
 * @param { WireService } wireService
 * @param { WireIsoService } wireIsoService
 * @param { AugmentedScope } $scope
 */
export default function CreateWireController(
    $scope,
    wireCompaniesService,
    $modal,
    toaster,
    companyAccountsService,
    utilityService,
    modalService,
    entitlementsService,
    $state,
    holidaysService,
    wireService,
    wireIsoService,
    beneficiariesService,
    securityService,
    $stateParams,
    $timeout,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    accountService,
    dateConstants,
    countriesService,
    regexConstants
) {
    $scope.startingBeneficiaryId = $stateParams.beneficiaryId ? $stateParams.beneficiaryId : null;
    $scope.canCreateDomesticWire = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire'
    );
    $scope.canCreateInternationalWire = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire'
    );
    $scope.canCreateFxWire = entitlementsService.hasEntitlement('Wire Transfer DLI, Create Wire');
    $scope.canCreateDomesticTemplate = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire Template'
    );
    $scope.canCreateInternationalTemplate = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire Template'
    );
    $scope.canCreateDomesticBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Beneficiary'
    );
    $scope.canCreateInternationalBeneficiary = entitlementsService.hasEntitlement(
        'Wire, International, Create Beneficiary'
    );
    $scope.canCreateForeignBeneficiary = entitlementsService.hasEntitlement(
        'Wire Transfer DLI, Create Beneficiary'
    );
    $scope.wirePaymentActivityButtonLabel = 'View Wire Activity';
    $scope.wizardController = {};
    $scope.wire = null;
    $scope.setToDomestic = setToDomestic;
    $scope.setToInternational = setToInternational;
    $scope.setToMultiple = setToMultiple;
    $scope.hasType = hasType;
    $scope.showBankDialog = showBankDialog;
    $scope.showWireTypeSelectionOption = false;
    $scope.addAdditionalInformation = addAdditionalInformation;
    $scope.isInternational = isInternational;
    $scope.deleteAdditionalInformation = deleteAdditionalInformation;
    $scope.setForm = setForm;
    $scope.setBankIdType = setBankIdType;
    $scope.checkRepeatOnDays = checkRepeatOnDays;
    $scope.findAccount = findAccount;
    $scope.findBeneficiaries = findBeneficiaries;
    $scope.isObject = isObject;
    $scope.selectWireCompany = selectWireCompany;
    $scope.checkEnability = checkEnability;
    $scope.beneficiaries = [];
    $scope.reset = reset;
    $scope.resetValues = resetValues;
    $scope.getPlaceHolderText = getPlaceHolderText;
    $scope.cancel = cancel;
    $scope.hasBeneficiaryPermission = hasBeneficiaryPermission;
    $scope.save = save;
    $scope.resetDate = resetDate;
    $scope.goToWireList = goToWireList;
    $scope.goToWireTemplateList = goToWireTemplateList;
    $scope.createAnotherWire = createAnotherWire;
    $scope.print = print;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.getMaximumDate = getMaximumDate;
    $scope.getNextYear = getNextYear;
    $scope.isInternationalWire = false;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.goBackToEdit = goBackToEdit;
    $scope.downloadPageId = downloadPageId.CreateWireConfirmation;
    $scope.wireConfig = {};
    $scope.saveAsTemplate = saveAsTemplate;
    $scope.canCreateTemplate = canCreateTemplate;
    $scope.success = null;
    $scope.isOneTime = isOneTime;
    $scope.selection = { type: 'Domestic' };
    $scope.previousSelection = { type: 'Domestic' };
    $scope.confirmToggle = confirmToggle;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.isType = isType;
    $scope.getQuote = getQuote;
    $scope.isDli = isDli;
    $scope.enterBeneficiary = false;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.onEnterFreeFormBeneficiary = onEnterFreeFormBeneficiary;
    $scope.onSelectBeneficiary = onSelectBeneficiary;
    $scope.review = review;
    $scope.disableReview = disableReview;
    $scope.beneficiaryReferenceRegex = regexConstants.BeneficiaryReferenceRegex;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.isSaving = false;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    $scope.wireIsoFf = false;
    $scope.beneficiaryAddress = [];
    $scope.beneficiaryBankAddress = [];
    $scope.setBeneficiaryAddress = setBeneficiaryAddress;
    $scope.countries = [];
    $scope.hasWireIsoFf = hasWireIsoFf;

    function hasWireIsoFf() {
        return $scope.wireIsoFf;
    }

    function disableReview() {
        return (
            $scope.form.$invalid ||
            !$scope.isValidLookups() ||
            ($scope.isInternational() && $scope.internationalDate !== $scope.currentDate) ||
            ($scope.isDli() && $scope.disableDliToday) ||
            ($scope.enterFreeFormBeneficiary && !$scope.wire.isFreeFormBeneficiaryValid)
        );
    }

    function onEnterFreeFormBeneficiary() {
        $scope.wire.frequency.type = 'One Time';
        $scope.wire.beneficiary = { name: null, enterFreeFormBeneficiary: true };
        if (!$scope.wire.freeFormBeneficiary) {
            $scope.wire.freeFormBeneficiary = {
                name: null,
                isFreeForm: true,
                intermediaryBanks: [],
                isInternational: $scope.wire.isInternational,
                isForeignCurrency: false,
            };
            if ($scope.wire.isInternational) {
                $scope.wire.freeFormBeneficiary.bank = {
                    bankIdType: 'Swift Code',
                    isInternational: true,
                };
            } else {
                $scope.wire.freeFormBeneficiary.country = 'US';
                $scope.wire.freeFormBeneficiary.bank = { bankIdType: undefined };
            }
        }
        $scope.enterFreeFormBeneficiary = true;
        $scope.form.beneficiary.$setUntouched();
        $scope.form.beneficiary.$setPristine();
    }

    function onSelectBeneficiary(beneficiary) {
        $scope.enterFreeFormBeneficiary = false;
        setBeneficiaryAddress(beneficiary);
    }

    function useThreeLineAddress() {
        return (
            !!$scope.wire &&
            !!$scope.wire.beneficiary &&
            beneficiariesService.canUseThreeLineAddress($scope.wire.beneficiary)
        );
    }

    function isDli() {
        return (
            isType('ForeignCurrency') ||
            (isType('International') && $scope.wireConfig.fiDliUsdEnabled)
        );
    }

    function confirmToggle() {
        if ($scope.form && $scope.form.$dirty) {
            let $modalInstance = null;
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit() {
                    $modalInstance.close();
                },
            };
            $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(
                () => {
                    toggle();
                },
                () => {
                    $scope.selection.type = $scope.previousSelection.type;
                }
            );
        } else {
            toggle();
        }
    }

    function review() {
        if (
            (!$scope.wire.beneficiary || !$scope.wire.beneficiary.id) &&
            !!$scope.wire.freeFormBeneficiary
        ) {
            $scope.$broadcast('changeStep', { message: 'review' });
            $scope.wire.beneficiary = $scope.wire.freeFormBeneficiary;
            setBeneficiaryAddress($scope.wire.beneficiary);
        }
        $scope.wizardController.goToNextStep();
    }

    function toggle() {
        $scope.previousSelection.type = $scope.selection.type;
        $scope.enterFreeFormBeneficiary = false;
        switch ($scope.selection.type) {
            case 'Domestic':
                setToDomestic();
                break;
            case 'International':
                setToInternational();
                break;
            case 'ForeignCurrency':
                setToInternational();
                break;
            case 'Multiple':
                setToMultiple();
                break;
        }
        $scope.form.$setPristine(true);
        filterWireCompanies();
    }

    function isType(type) {
        return $scope.selection.type === type;
    }

    init();

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('WireTransfer').then(response => {
            $scope.processingCutoff = response;
            $scope.internationalDate = formatDate(response.currentFiTime);
            const cutoffTime = response?.processingCutoff?.cutoffTime;
            const timeout = cutoffTime
                ? moment(`${moment(response.currentFiTime).format('L')} ${cutoffTime}`).diff(
                      response.currentFiTime
                  )
                : 0;
            $timeout(() => {
                $scope.disableToday = true;

                if ($scope.wire && $scope.wire.frequency) {
                    if (
                        moment($scope.wire.frequency.valueDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.valueDate = formatDate(response);
                                $scope.currentDate = formatDate(response);
                                $scope.endOnDate = formatDate(response);
                            });
                    }
                    if (
                        moment($scope.wire.frequency.startOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.startOn = formatDate(response);
                            });
                    }
                    if (
                        moment($scope.wire.frequency.endOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.endOn = formatDate(response);
                            });
                    }
                }

                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create wire for today.');
                }
            }, timeout);
        });
    }

    function loadCutoffTimesDli() {
        companyAccountsService.getCutoffTimesByProductType('WireTransferDLI').then(response => {
            $scope.processingCutoff = response;
            const timeToStart = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.startTime
                }`
            ).diff(response.currentFiTime);
            const timeToCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $scope.disableDliToday = !(timeToStart <= 0 && timeToCutoff > 0);
            if (timeToStart > 0) {
                toaster.alert(
                    'Start Time Pending',
                    'International wires may not be submitted before the processing start time.'
                );
                $timeout(() => {
                    toaster.alert('Start Time Passed', 'You may submit an International Wire.');
                    $scope.disableDliToday = false;
                }, timeToStart);
            } else {
                $timeout(() => {
                    if (!isType('Domestic')) {
                        $scope.selection.type = 'Domestic';
                        toggle();
                    }
                    $scope.disableDliToday = true;
                    toaster.alert(
                        'Cutoff Time Passed',
                        'International wires may not be submitted after the processing cutoff time.'
                    );
                }, timeToCutoff);
            }
        });
    }

    function loadTemplate() {
        const templates = wireService.getSelectedTemplates();
        if (templates !== null && templates !== undefined && templates.length > 0) {
            $scope.template = templates[0];
            wireService.setSelectedTemplates([]);

            $scope.startingBeneficiaryId = $scope.template.beneficiary.id;
            getStartingBeneficiaryForTemplate();
        }
    }

    function getStartingBeneficiaryForTemplate() {
        getStartingBeneficiary(() => {
            $scope.wire.additionalInformation =
                $scope.template.notes === null
                    ? [{ value: '' }]
                    : $scope.template.notes.map(note => ({ value: note.trim() }));
            $scope.wire.wireCompany = $scope.template.wireCompany;
            $scope.wire.isInternational = $scope.template.isInternational;
            $scope.wire.purpose = $scope.template.purpose;
            $scope.wire.referenceBeneficiary = $scope.template.referenceBeneficiary;
            $scope.wire.wireTemplate = { id: $scope.template.id };
            $scope.wire.debitAccount = $scope.template.debitAccount;
        });
    }

    function canCreateTemplate(wire) {
        if (wire.isInternational) {
            return $scope.canCreateInternationalTemplate;
        }
        return $scope.canCreateDomesticTemplate;
    }

    function init() {
        if (!$scope.canCreateDomesticWire && !$scope.canCreateInternationalWire) {
            $state.go('dashboard');
        }

        if (entitlementsService.hasEntitlement('Feature.Wire.SFTP.DataTransmissions')) {
            $scope.wirePaymentActivityButtonLabel = 'View Wire Payment Activity';
        }

        $scope.wire = {
            wireCompany: null,
            amount: null,
            destinationCurrency: 'USD',
            frequency: {
                type: 'One Time',
                valueDate: '',
            },
        };

        wireIsoService.getLabels().then(result => {
            $scope.wireIsoLabels = result;
        });
        wireIsoService.getEntitlementWireISO20022().then(ff => {
            $scope.wireIsoFf = ff;
        });

        loadTemplate();
        if (!$scope.template) {
            getWireCompanies();
            if ($scope.canCreateDomesticWire) {
                setToDomestic();
            } else {
                $scope.selection.type = 'International';
                setToInternational();
            }
        }
        getHolidays();
        loadCutoffTimes();
        $scope.disableDliToday = true;

        loadCountriesAndCurrencies();
        loadFrequencyOptions();
        loadDays();
        getWireConfiguration();
        loadAccountDisplayField();

        if (!$scope.template) {
            // Use permissions to determine where to start.
            const wireType = wireService.getCreateWireType();

            if (
                wireType === 'D' ||
                ($scope.canCreateDomesticWire &&
                    !$scope.canCreateInternationalWire &&
                    $scope.startingBeneficiaryId)
            ) {
                setToDomestic();
            } else if (
                wireType === 'I' ||
                ($scope.canCreateInternationalWire &&
                    !$scope.canCreateDomesticWire &&
                    $scope.startingBeneficiaryId)
            ) {
                setToInternational();
            } else if ($scope.startingBeneficiaryId) {
                getStartingBeneficiary();
            }
        }
    }

    function loadAccountDisplayField() {
        accountService.getSettings().then(settings => {
            $scope.accountDisplayField = settings.accountDisplayField;
        });
    }

    function getStartingBeneficiary(callback) {
        beneficiariesService.get($scope.startingBeneficiaryId).then(response => {
            $scope.beneficiaryInformation = response;
            if (response.isInternational) {
                setToInternational();
            } else {
                setToDomestic();
            }
            $timeout(() => {
                $scope.wire.beneficiary = $scope.beneficiaryInformation;
                $scope.setBeneficiaryAddress($scope.beneficiaryInformation);
                if (typeof callback === 'function') {
                    callback();
                }
            }, 20);
        });
    }

    function setWireTypeSelectionVisibilityOption() {
        if (
            $scope.canCreateDomesticWire &&
            $scope.canCreateInternationalWire &&
            $scope.wireConfig.companyWireProductEnabled &&
            $scope.wireConfig.companyInternationalUsdEnabled
        ) {
            $scope.showWireTypeSelectionOption = true;
        }
    }

    function checkRepeatOnDays() {
        if (!$scope.wire.frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.wire.frequency.repeatOnDay1 < $scope.wire.frequency.repeatOnDay2 ||
                $scope.wire.frequency.repeatOnDay2 === '';
            const isValid2 =
                $scope.wire.frequency.repeatOnDay2 > $scope.wire.frequency.repeatOnDay1 ||
                $scope.wire.frequency.repeatOnDay1 === '';

            if (
                $scope.wire.frequency.repeatOnDay1 !== undefined &&
                $scope.wire.frequency.repeatOnDay2 !== undefined &&
                $scope.wire.frequency.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    }

    function checkEnability() {
        return !$scope.form.$valid;
    }

    function getMaximumDate(startDate, frequencyType) {
        let date = moment(new Date(startDate));

        if (frequencyType === 'Every Six Months') {
            date = date.add(6, 'months');
        } else if (frequencyType === 'Monthly') {
            date = date.add(1, 'month');
        } else if (frequencyType === 'Quarterly') {
            date = date.add(3, 'months');
        } else if (frequencyType === 'Yearly') {
            date = date.add(1, 'year');
        }

        return date.format('MM/DD/YYYY');
    }

    function goToWireList() {
        $state.go('payables.wire.wire-list');
    }

    function goToWireTemplateList() {
        $state.go('payables.wire.wire-template-list');
    }

    function selectWireCompany(obj) {
        $scope.wire.debitAccount = null;
        if (obj) {
            getAccounts(obj.id);
        } else {
            $scope.companyAccounts = [];
        }
    }

    $scope.onChange = function () {
        if (!isNaN(Date.parse($scope.wire.frequency.startOn))) {
            const dt = new Date($scope.wire.frequency.startOn);

            if ($scope.wire.frequency.type === 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type === 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.wire.frequency.type === 'Twice a Month' ||
                $scope.wire.frequency.type === 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type === 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type === 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type === 'Yearly') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function setForm(form) {
        $scope.form = form;
    }

    function loadCountriesAndCurrencies() {
        countriesService.getAll().then(response => {
            $scope.countries = response;
            $scope.destinationCurrencies = currenciesFromCountries(response);
        });
    }

    function currenciesFromCountries(countryData) {
        const seen = {};
        const countries = countryData
            .map(country => ({
                currencyLabel: `${country.currencyName} - ${country.currencyCode}`,
                currencyCode: country.currencyCode,
                intradaySpot: country.intradaySpot,
            }))
            .sort((a, b) => {
                if (a.currencyLabel < b.currencyLabel) return -1;
                if (a.currencyLabel === b.currencyLabel) return 0;
                return 1;
            });
        return countries.filter(item => {
            const k = JSON.stringify(item);
            // Disabling ESLint rule because it's not worth the risk / effort to disentangle this legacy code
            // eslint-disable-next-line no-return-assign,no-prototype-builtins
            return seen.hasOwnProperty(k) ||
                !item.currencyCode ||
                item.currencyCode === 'USD' ||
                !item.intradaySpot
                ? false
                : (seen[k] = true);
        });
    }

    function reset() {
        let $modalInstance = null;
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Reset Edits',
            headerText: 'Confirm Reset',
            bodyText: 'Are you sure you want to reset the changes you have made?',
            submit() {
                $modalInstance.close();
                if ($scope.template) {
                    if (isInternational()) {
                        $scope.wire.amount = null;
                    } else {
                        $scope.wire.amount = null;
                        $scope.wire.frequency = {
                            type: 'One Time',
                            valueDate: $scope.currentDate,
                        };
                    }
                } else {
                    $scope.enterFreeFormBeneficiary = false;
                    if (isInternational()) {
                        setToInternational();
                    } else {
                        setToDomestic();
                    }
                }
                $scope.form.$setPristine();
            },
        };
        $modalInstance = modalService.showModal({}, modalOptions);
    }

    $scope.$watch('wire.frequency', newValue => {
        if (newValue !== null && newValue !== undefined) {
            if (
                newValue.repeatOnDay1 !== undefined &&
                newValue.repeatOnDay1 !== null &&
                newValue.repeatOnDay2 !== undefined &&
                newValue.repeatOnDay2 !== null &&
                newValue.repeatOnDay1 < newValue.repeatOnDay2
            ) {
                $scope.wire.frequency.isValid = true;
            }
        }
    });

    function hasBeneficiaryPermission() {
        return (
            $scope.canCreateDomesticBeneficiary ||
            $scope.canCreateInternationalBeneficiary ||
            $scope.canCreateForeignBeneficiary
        );
    }

    function getPlaceHolderText(index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function setBankIdType(intermediaryBank, type) {
        if (intermediaryBank !== null) {
            const index = $scope.wire.intermediaryBanks.indexOf(intermediaryBank);
            $scope.wire.intermediaryBanks[index].bankIdType = type;
        } else {
            $scope.wire.bank.bankIdType = type;
        }
    }

    function resetValues(type) {
        if (type === 'Twice a Month') {
            $scope.wire.frequency.repeatOnDay2 = null;
            $scope.form.repeatOnDay1.$setValidity('doesNotMatch', true);
            $scope.form.repeatOnDay2.$setValidity('doesNotMatch', true);
        }
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function showBankDialog(index) {
        const modalInstance = $modal.open({
            template: require('../../beneficiaries/views/bankDialogView.html'),
            size: 'md',
            controller: 'BankDialogController',
            backdrop: 'static',
            resolve: {
                beneficiary() {
                    return $scope.beneficiary;
                },
            },
        });
        modalInstance.result.then(response => {
            if (index !== undefined) {
                $scope.wire.intermediaryBanks[index] = response;
            } else {
                $scope.wire.bank = response;
            }
        });
    }

    function saveAsTemplate() {
        const modalInstance = $modal.open({
            template: require('../views/wireToTemplateDialogView.html'),
            size: 'sm',
            controller: 'WireToTemplateDialogController',
            backdrop: 'static',
        });
        modalInstance.result.then(templateName => {
            const template = {
                name: templateName,
                beneficiary: $scope.wire.beneficiary,
                debitAccount: $scope.wire.debitAccount,
                wireCompany: $scope.wire.wireCompany,
                purpose: $scope.wire.purpose,
                notes: $scope.wire.additionalInformation.map(info => info.value),
                isInternational: $scope.wire.isInternational,
            };
            securityService
                .verifyUser('Create Wire Template', template, () =>
                    wireService.createWireTemplate(template)
                )
                .then(response => {
                    if (response.errorSummary) {
                        $scope.errorMessage = '';
                        $scope.errorMessage = response.errorSummary.summaryMessage;
                        if (response.errorSummary.details) {
                            $scope.errorDetails = [];
                            angular.forEach(response.errorSummary.details, detail => {
                                $scope.errorDetails.push(detail);
                            });
                        }
                    } else {
                        $scope.templateNameFromDialog = response.name;
                        $scope.templateStatusFromDialog = response.status;
                        toaster.successText(
                            `Template '${response.name}' has been created and is ${response.status}.`
                        );
                        $state.go('payables.wire.wire-template-list');
                    }
                });
        });
    }

    function isInternational() {
        return $scope.wire && $scope.wire.isInternational;
    }

    function isOneTime() {
        return (
            $scope.wire &&
            $scope.wire.frequency &&
            $scope.wire.frequency.type &&
            $scope.wire.frequency.type.replace(/\s/g, '').toLowerCase() === 'onetime'
        );
    }

    function resetDate() {
        $scope.wire.frequency.endOn = null;
    }

    function addAdditionalInformation() {
        if ($scope.wire.additionalInformation.length < 4) {
            $scope.wire.additionalInformation.push({ value: '' });
        }
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(nextBusinessDay => {
                    $scope.wire.frequency.valueDate = formatDate(nextBusinessDay);
                    $scope.currentDate = formatDate(nextBusinessDay);
                    $scope.endOnDate = formatDate(nextBusinessDay);
                });
        });
    }

    function deleteAdditionalInformation(index) {
        $scope.wire.additionalInformation.splice(index, 1);
    }

    function setToDomestic() {
        $scope.wire = {
            additionalInformation: [{ value: '' }],
            frequency: {
                type: 'One Time',
                valueDate: $scope.wire.frequency.valueDate,
            },
            isInternational: false,
            amount: null,
            destinationCurrency: 'USD',
        };
        if (!$scope.template && !$scope.domesticBenes) {
            getBeneficiaries(false);
        } else {
            $scope.beneficiaries = $scope.domesticBenes;
        }
        if ($scope.wireCompanies && $scope.wireCompanies.length === 1) {
            $scope.wire.wireCompany = $scope.wireCompanies[0];
        }
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.AccessCreateDomesticWire
        );
    }

    function cancel() {
        if (!$scope.form.$dirty && checkEnability()) {
            if ($scope.isInternationalWire === true) {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateInternationalWire
                );
            } else {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateDomesticWire
                );
            }
            if ($scope.template) {
                $state.go('payables.wire.wire-template-list');
            } else {
                $state.go('payables.wire.wire-list');
            }
        } else {
            confirmCancel();
        }
    }

    $scope.isConfirmButtonDisabled = function () {
        return $scope.isSaving;
    };

    /**
     * @param { WireDto } wire
     */
    function save(wire) {
        $scope.isSaving = true;
        if (wire.isInternational) {
            $scope.wire.frequency = {
                type: 'One Time',
                valueDate: $scope.currentDate,
            };
        }

        const actionType =
            wire.frequency.type === 'One Time'
                ? 'Create One time WIRE Payment'
                : 'Create Recurring WIRE Payment';
        securityService
            .verifyUser(actionType, wire, () => wireService.create(wire))
            .then(response => {
                proceedToConfirmation(response, wire.isInternational);
            })
            .finally(() => {
                $scope.isSaving = false;
            });
    }

    function proceedToConfirmation(response, isIntl) {
        if (response.errorSummary) {
            $scope.errorMessage = '';
            $scope.errorMessage = response.errorSummary.summaryMessage;
            if (response.errorSummary.details) {
                $scope.errorDetails = [];
                angular.forEach(response.errorSummary.details, detail => {
                    $scope.errorDetails.push(detail);
                });
            }
            if (response.id > 0) {
                $scope.wire = response;
                $scope.success = false;
                $scope.wizardController.goToNextStep();
            }
        } else {
            $scope.errorMessage = '';
            $scope.errorDetails = [];
            response.isInternational = isIntl;
            $scope.wire = response;
            $scope.success = true;
            $scope.isConfirmation = true;
            $scope.wizardController.goToNextStep();
        }
    }

    function confirmCancel() {
        let $modalInstance = null;
        const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Cancel Edits',
            headerText: 'Confirm Cancellation',
            bodyText: 'Are you sure you want to cancel the changes you have made?',
            submit() {
                $modalInstance.close();
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateWire
                );
                if ($scope.template) {
                    $state.go('payables.wire.wire-template-list');
                } else {
                    $state.go('payables.wire.wire-list');
                }
            },
        };
        $modalInstance = modalService.showModal({}, modalOptions);
    }

    function setToInternational() {
        $scope.wire = {
            additionalInformation: [{ value: '' }],
            isInternational: true,
            intermediaryBanks: [],
            amount: null,
            destinationCurrency: isType('ForeignCurrency') ? null : 'USD',
            frequency: {
                type: 'One Time',
                valueDate: '',
            },
        };
        if (!$scope.template) {
            if (!$scope.internationalBenes) {
                getBeneficiaries(true);
            } else {
                filterBeneficiaries();
                $scope.beneficiaries = $scope.internationalBenes;
            }
        }

        if ($scope.wireCompanies && $scope.wireCompanies.length === 1) {
            $scope.wire.wireCompany = $scope.wireCompanies[0];
        }
        $scope.isInternationalWire = true;
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.AccessCreateInternationalWire
        );
        $scope.selection.type = 'International';
    }

    function setToMultiple() {
        $scope.wireIsoEntitlement
            ? $state.go('payables.wire.creditor-list', { multipleWire: true })
            : $state.go('payables.wire.beneficiary-list', { multipleWire: true });
    }

    function getBeneficiaries(isIntl) {
        const filter = {
            status: 'Ready',
            beneficiaryType: isIntl ? 'International' : 'Domestic',
            requireEntitlement: false,
        };

        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            if (isIntl) {
                $scope.internationalBenesAll = response;
                filterBeneficiaries();
                $scope.beneficiaries = $scope.internationalBenes;
            } else {
                $scope.beneficiaries = response;
                $scope.domesticBenes = angular.copy(response);
            }
        });
    }

    function hasType() {
        return ($scope.wire && $scope.wire.isInternational !== undefined) || $scope.template;
    }

    function getWireCompanies() {
        wireCompaniesService.getAll().then(response => {
            $scope.wireCompaniesAll = response;
            filterWireCompanies();
            if ($scope.wireCompanies.length === 1) {
                $scope.wire.wireCompany = $scope.wireCompanies[0];
                getAccounts($scope.wire.wireCompany.id);
            }
        });
    }

    function filterWireCompanies() {
        let getDliCompanies = false;
        switch ($scope.selection.type) {
            case 'International':
                getDliCompanies = $scope.wireConfig.fiDliUsdEnabled;
                break;
            case 'ForeignCurrency':
                getDliCompanies = true;
                break;
        }
        $scope.wireCompanies = $scope.wireCompaniesAll.reduce((acc, val) => {
            if (val.isDliWireCompany === getDliCompanies) {
                acc.push(val);
            }
            return acc;
        }, []);
    }

    function filterBeneficiaries() {
        const getForeignBeneficiaries = $scope.selection.type === 'ForeignCurrency';
        $scope.internationalBenes = $scope.internationalBenesAll.reduce((acc, val) => {
            if (!!val.isForeignCurrency === getForeignBeneficiaries) {
                acc.push(val);
            }
            return acc;
        }, []);
    }

    function getFrequencySummary(wire) {
        return wireService.summarizeFrequency(wire.frequency);
    }

    function loadFrequencyOptions() {
        $scope.wireFrequencies = dateConstants.paymentFrequencies;
    }

    function loadDays() {
        $scope.weekDays = dateConstants.weekDays;
    }

    function getAccounts(id, callback) {
        let create;
        if ($scope.isInternationalWire === true) {
            create = isDli() ? 'createDli' : 'createIntl';
        } else {
            create = 'createDomestic';
        }
        wireCompaniesService.getDebitAccounts(id, create).then(response => {
            $scope.companyAccounts = response;
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    function getWireConfiguration() {
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
            if ($scope.wireConfig.maximumFutureDays) {
                $scope.futureDatedLimit = formatDate(
                    moment().add({ days: $scope.wireConfig.maximumFutureDays })
                );
            }
            setUsdLabel();
            setWireTypeSelectionVisibilityOption();
        });
    }

    function setUsdLabel() {
        if ($scope.wireConfig.fiDliProductEnabled) {
            $scope.usdLabel = ' USD';
        }
    }

    function findAccount(id) {
        getAccounts(id, () => {
            const modalInstance = $modal.open({
                template: require('../views/accountModalView.html'),
                size: 'md',
                controller: 'AccountsController',
                backdrop: 'static',
                resolve: {
                    wireId() {
                        return id;
                    },
                    companyAccounts() {
                        return $scope.companyAccounts;
                    },
                },
            });
            modalInstance.result.then(selectedAccount => {
                $scope.wire.debitAccount = angular.copy(selectedAccount);
            });
        });
    }

    $scope.isValidLookups = function () {
        let result = true;
        if (
            $scope.wire.debitAccount === null ||
            $scope.wire.debitAccount === undefined ||
            typeof $scope.wire.debitAccount !== 'object'
        ) {
            result = false;
        }
        return result;
    };

    function print() {
        window.print();
    }

    $scope.$watch('wire.frequency.type', (newValue, oldValue) => {
        if (
            $scope.wire.frequency &&
            wireService.frequencyTypeToFriendly(newValue) !==
                wireService.frequencyTypeToFriendly(oldValue)
        ) {
            $scope.wire.frequency.type = newValue;
            $scope.wire.frequency.startOn = null;
            $scope.wire.frequency.endOn = null;
            $scope.wire.frequency.valueDate = null;
            $scope.wire.frequency.noEndDate = false;
            $scope.wire.frequency.repeatOnDay1 = '';
            $scope.wire.frequency.repeatOnDay2 = '';
            $scope.wire.frequency.repeatOnLastBusinessDay = false;
        }
    });

    function createAnotherWire() {
        $state.go($state.current, {}, { reload: true });
    }

    function getQuote() {
        const modalInstance = $modal.open({
            template: require('../views/readyForQuote.html'),
            size: 'md',
            controller: 'ReadyForQuoteController',
            backdrop: 'static',
            resolve: {
                wire() {
                    return $scope.wire;
                },
            },
        });
        modalInstance.result.then(result => {
            if (result.rateQuote.accepted) {
                $scope.wire.rateQuote = angular.copy(result.rateQuote);
                proceedToConfirmation(result, true);
            }
        });
    }

    function findBeneficiaries(type) {
        const modalInstance = $modal.open({
            template: require('../views/beneficiaryModalView.html'),
            size: 'lg',
            controller: 'BeneficiarySelectionController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                beneficiaries() {
                    return $scope.beneficiaries;
                },
                enableFreeFormEntry() {
                    return !$scope.template;
                },
            },
        });
        modalInstance.result.then(selectedBeneficiary => {
            if (selectedBeneficiary.enterFreeFormBeneficiary) {
                $scope.onEnterFreeFormBeneficiary();
            } else {
                $scope.enterFreeFormBeneficiary = false;
                $scope.wire.beneficiary = angular.copy(selectedBeneficiary);
                $scope.setBeneficiaryAddress(selectedBeneficiary);
            }
        });
    }

    function setBeneficiaryAddress(beneficiary) {
        $scope.beneficiaryAddress = getCreditorIsoAddress(beneficiary, $scope.countries);
        $scope.beneficiaryBankAddress = getCreditorIsoAddress(beneficiary.bank, $scope.countries);
    }

    function getNextYear() {
        if ($scope.wire.frequency.startOn !== '') {
            return moment($scope.wire.frequency.startOn).add(1, 'y').format('MM/DD/YYYY');
        }
        return moment().add(1, 'y').format('MM/DD/YYYY');
    }

    $scope.$watch(
        () => $scope.wire.beneficiary,
        data => {
            if (data && !data.status && !$scope.enterFreeFormBeneficiary) {
                angular.forEach($scope.beneficiaries, bene => {
                    if (data.accountNumber === bene.accountNumber && data.name === bene.name) {
                        data = bene;
                        $scope.wire.beneficiary = bene;
                    }
                });
            }
        },
        true
    );

    function goBackToEdit() {
        $scope.errorMessage = '';
        if ($scope.enterFreeFormBeneficiary) {
            $scope.wire.beneficiary = null;
        }
        $scope.errorDetails = [];
        $scope.wizardController.goToPreviousStep();
    }
}

CreateWireController.$inject = [
    '$scope',
    'wireCompaniesService',
    '$modal',
    'toaster',
    'companyAccountsService',
    'utilityService',
    'modalService',
    'entitlementsService',
    '$state',
    'holidaysService',
    'wireService',
    'wireIsoService',
    'beneficiariesService',
    'securityService',
    '$stateParams',
    '$timeout',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'accountService',
    'dateConstants',
    'countriesService',
    'regexConstants',
];
