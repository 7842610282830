/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ErrorHttpContentDto, LogonInputModelDto, LogonResponseModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AuthenticationClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLogon
     * @summary Logs onto the system.
     * @request POST:/authentication/logon
     */
    authenticationLogon = (input: LogonInputModelDto | null, params: RequestParams = {}) =>
        this.http.request<LogonResponseModelDto, ErrorHttpContentDto>({
            path: `/authentication/logon`,
            method: 'POST',
            body: input,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
