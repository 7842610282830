/* eslint-disable import/no-extraneous-dependencies */
import { v4 as uuid } from 'uuid';

function formatDateForAPI(value) {
    if (!value) return null;
    const date = new Date(value);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T00:00:00`;
}

export const mapPaymentRecipients = (payment, banks) => {
    const { recipients } = payment;
    return recipients.map(r => ({
        ...r,
        bank: banks.find(b => b.bankId === r.routingNumber),
        addenda: r.addenda[0]?.value ? r.addenda[0].value : '',
        fromMasterList: r.achMasterRecipientId !== null,
    }));
};

export const mapPaymentHeaderAndRecipientsRecordset = (
    paymentHeaderRecord,
    recipientsRecordset
) => ({
    ...paymentHeaderRecord,
    frequency: {
        ...paymentHeaderRecord.frequency,
        effectiveDate: paymentHeaderRecord.frequency.valueDate,
    },
    recipients: recipientsRecordset.map(recipient => ({
        ...recipient,
        prenote: !!recipient.prenote,
        hold: !!recipient.hold,
        id: 0,
        reversal: false,
        routingNumber: recipient.bank.bankId,
        isRoutingNumberValid: true,
        isRoutingNumberOnUs: false,
        transactionType: 'CR',
        addenda: [
            {
                ...recipient.addenda[0],
            },
        ],
    })),
    restricted: !!paymentHeaderRecord.restricted,
    isTemplate: false,
    applyUpdatesToBatch: false,
    status: '',
    numberOfApprovalsNeeded: 0,
    completedApprovals: [],
    securityMessage: {
        actionType: 'Create One time ACH Payment',
        status: null,
        methodUsed: null,
        hasAlternate: false,
        errorCode: null,
        message: null,
        oneTimePassword: null,
    },
    duplicatePreventionId: uuid(),
});

const achPaymentFrequencyTypes = {
    OneTime: 'One Time',
    Weekly: 'Weekly',
    EveryTwoWeeks: 'Every Two Weeks',
    TwiceAMonth: 'Twice a Month',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    EverySixMonths: 'Every Six Months',
    Yearly: 'Yearly',
};

export const mapPaymentPostRequestDto = (header, recipients) => ({
    ...header,
    audit: header.audit || '',
    offsetAccount: header.offsetAccount === '' ? null : header.offsetAccount,
    achCompanyName: header.achCompany.companyName,
    achCompanyId: header.achCompanyId,
    duplicatePreventionId: uuid(),
    fileArchiveId: header.nachaFileUpload && header.fileArchiveId ? header.fileArchiveId : null,
    frequency: {
        ...header.frequency,
        type: achPaymentFrequencyTypes[header.frequency.type],
        startOn: formatDateForAPI(header.frequency.startOn),
        endOn: formatDateForAPI(header.frequency.endOn),
        effectiveDate: formatDateForAPI(header.frequency.valueDate),
    },
    securityMessage: {
        actionType: 'Initiate Payment From Batch',
    },
    recipients: recipients.map(r => ({
        ...r,
        routingNumber: r.bank.bankId,
        addenda:
            Array.isArray(r.addenda) && !r.addenda[0]?.value
                ? r.addenda
                : r.addenda
                  ? [{ value: r.addenda }]
                  : null,
        addendaBody: r.addenda?.length ? r.addenda : null,
        idNumber: r.idNumber === '' ? null : r.idNumber,
    })),
});

export const mapRecipientResponse = (response, banks) =>
    response.map(recipient => {
        if (recipient.routingNumber.length === 8) {
            // business rule: adding prefix zero for 8 character routing numbers mishandled my spreadsheets
            recipient.routingNumber = `0${recipient.routingNumber}`;
        }
        const bank = banks.find(b => b.bankId === recipient.routingNumber);
        recipient.addenda = recipient.addenda.length ? recipient.addenda : null;
        return {
            ...recipient,
            bank,
        };
    });
