/* eslint-disable sonarjs/no-nested-template-literals */
import {
    repeatDay1BeforeRepeatDay2,
    validRepeatOnEnd,
    validRepeatOnStart,
} from '@treasury/policy/lib/validators/frequency.validator';
import { css, html, LitElement } from 'lit';
import { formStyles } from '../../form-styles.js';

import '../../../omega-select.js';

const days = new Array(31).fill(0).map((_, i) => i + 1);

export class OmegaFrequencyRepeatOnDays extends LitElement {
    static get properties() {
        return {
            showSecondDay: { type: Boolean, reflect: true },
            frequency: Object,
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
        };
    }

    repeatDayOneChange(e) {
        this.frequency = { ...this.frequency, repeatOnDay1: e.target.value };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    repeatDayTwoChange(e) {
        this.frequency = { ...this.frequency, repeatOnDay2: e.target.value };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    repeatOnLastBusinessDayChange() {
        const repeatOnLastBusinessDay = !this.frequency?.repeatOnLastBusinessDay;
        const repeatOnDay1 =
            !this.showSecondDay && repeatOnLastBusinessDay ? null : this.frequency?.repeatOnDay1;
        const repeatOnDay2 =
            !this.showSecondDay || (this.showSecondDay && repeatOnLastBusinessDay)
                ? null
                : this.frequency?.repeatOnDay2;
        this.frequency = {
            ...this.frequency,
            repeatOnLastBusinessDay,
            repeatOnDay1,
            repeatOnDay2,
        };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    get validRepeatOn1() {
        return validRepeatOnStart(this.frequency);
    }

    get validRepeatOn2() {
        return validRepeatOnEnd(this.frequency);
    }

    get validRepeatBothDays() {
        return repeatDay1BeforeRepeatDay2(this.frequency);
    }

    renderReadOnly() {
        const parseRepeatDay = day => day ?? '(no date selected)';
        return html`<div class="italic">
            Repeats on day${this.showSecondDay ? html`<span>s</span>` : null}:
            ${this.showSecondDay
                ? `${parseRepeatDay(this.frequency?.repeatOnDay1)} and
                ${
                    this.frequency?.repeatOnLastBusinessDay
                        ? 'last business day'
                        : parseRepeatDay(this.frequency?.repeatOnDay2)
                }`
                : `${
                      this.frequency?.repeatOnLastBusinessDay
                          ? 'last business day'
                          : (this.frequency?.repeatOnDay1 ?? '(no date selected)')
                  }`}
        </div>`;
    }

    render() {
        if (this.readOnly) return this.renderReadOnly();
        const dayOptions = days.map(d => ({ text: d.toString(), value: d.toString() }));
        return html`
            <div class="row">
                <label
                    >Repeat on Day${this.showSecondDay ? html`<span>s</span>` : null}:
                    ${!this.readOnly ? html`<span class="required"> *</span>` : null}</label
                >
                <div class="day-picker control">
                    <omega-select
                        class="repeatOnDay1"
                        .items=${dayOptions}
                        @change=${this.repeatDayOneChange}
                        ?disabled=${(this.frequency?.repeatOnLastBusinessDay &&
                            !this.showSecondDay) ||
                        this.disabled}
                        .value=${this.frequency?.repeatOnDay1}
                        .valid=${this.validRepeatOn1 && this.validRepeatBothDays}
                    ></omega-select>
                    ${this.showSecondDay
                        ? html`<div class="pad">and</div>
                              <omega-select
                                  class="repeatOnDay2"
                                  .items=${dayOptions}
                                  @change=${this.repeatDayTwoChange}
                                  ?disabled=${(this.showSecondDay &&
                                      this.frequency?.repeatOnLastBusinessDay) ||
                                  this.disabled}
                                  .value=${this.frequency?.repeatOnDay2}
                                  .valid=${this.validRepeatOn2 && this.validRepeatBothDays}
                              ></omega-select>`
                        : null}
                    <label>
                        <input
                            type="checkbox"
                            ?checked=${this.frequency?.repeatOnLastBusinessDay}
                            @change=${this.repeatOnLastBusinessDayChange}
                            ?disabled=${this.disabled}
                        />Last Business Day</label
                    >
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            formStyles,
            css`
                :host {
                    display: block;
                }
                .day-picker {
                    display: flex;
                    align-items: center;
                }
                .day-picker omega-select {
                    min-width: 100px;
                }
                .pad {
                    padding: 0 5px;
                }
                label {
                    white-space: nowrap;
                }
            `,
        ];
    }
}

window.customElements.define('omega-frequency-repeat-on-days', OmegaFrequencyRepeatOnDays);
export default OmegaFrequencyRepeatOnDays;
