import { IssuedItemStatus, itemStatuses } from '@treasury/domain/channel/types/arp';
import { OmegaListItem } from '@treasury/omega/view-models';
import { camelCaseToTitle } from '@treasury/utils';

export const itemStatusOptions: OmegaListItem<IssuedItemStatus>[] = itemStatuses
    .map(status => ({
        value: status,
        text: camelCaseToTitle(status),
    }))
    .filter(status => status.value !== 'Submitted' && status.value !== 'Failed');
