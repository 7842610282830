import {
    DynamicComponentExitReason,
    DynamicComponentHandle,
    DynamicComponentOptions,
    DynamicComponentResult,
} from '@jack-henry/frontend-utils/lit/dynamic-component-registry';
import { Action, FlexDirection, ScreenUnit } from '@treasury/utils';
import type { OmegaDialog } from '../../components/omega-dialog';

// alias under original name for existing consumers
export { DynamicComponentExitReason as OmegaDialogExitReason } from '@jack-henry/frontend-utils/lit/dynamic-component-registry';

/**
 * @deprecated Use `DynamicComponentResult`
 *
 * Convenience alias for existing consumers.
 */
export type OmegaDialogResult<T> = DynamicComponentResult<T>;

type OmegaButtonType = 'primary' | 'secondary' | 'approve' | 'reject' | 'link' | 'icon';

export enum DialogButtonPosture {
    Primary,
    Secondary,
    Constructive,
    Destructive,
}

export const postureToTypeMap: Record<DialogButtonPosture, OmegaButtonType> = {
    [DialogButtonPosture.Primary]: 'primary',
    [DialogButtonPosture.Secondary]: 'secondary',
    [DialogButtonPosture.Constructive]: 'approve',
    [DialogButtonPosture.Destructive]: 'reject',
};

export interface DialogButton {
    label: string;
    posture: DialogButtonPosture;
    onClick?: Action;
}

export interface OmegaDialogOptionsAll extends DynamicComponentOptions {
    flexDirection: FlexDirection;
    headerIcon?: string;
    height: number;
    heightUnits: ScreenUnit;
    width: number;
    widthUnits: ScreenUnit;
    buttons: { [key in DynamicComponentExitReason]: DialogButton | null };
    preventClose: boolean;
    renderButtons: boolean;
    logoSource?: string;
}

type OmegaDialogButtonsOptional = {
    [key in DynamicComponentExitReason]?: Partial<DialogButton> | null;
};

export type OmegaDialogOptions = Partial<Omit<OmegaDialogOptionsAll, 'buttons'>> & {
    buttons?: OmegaDialogButtonsOptional;
};

export interface OmegaDialogHandle<ContentElem extends Element = Element, T = unknown>
    extends DynamicComponentHandle<OmegaDialog, T> {
    content: ContentElem;
    /**
     * @deprecated - Use `dispose()`
     *
     * Alias for `dispose()` to support backwards compatibility.
     */
    close: DynamicComponentHandle<OmegaDialog, T>['dispose'];
    /**
     * @deprecated - Use `disposed`
     *
     * Alias for `disposed` to support backwards compatibility.
     */
    closed: DynamicComponentHandle<OmegaDialog, T>['disposed'];
}
