/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { TermsAndConditionsModelDto } from "../";
import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class TermsAndConditionsClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags TermsAndConditions
   * @name TermsAndConditionsGet
   * @summary Gets the specified blank if user accepted.
   * @request GET:/termsAndConditions
   */
  termsAndConditionsGet = (
    query: {
      /** if set to true [blank if user accepted]. */
      blankIfUserAccepted: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TermsAndConditionsModelDto, any>({
      path: `/termsAndConditions`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TermsAndConditions
   * @name TermsAndConditionsAccept
   * @summary Accepts this instance.
   * @request GET:/termsAndConditions/accept
   */
  termsAndConditionsAccept = (params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/termsAndConditions/accept`,
      method: "GET",
      format: "json",
      ...params,
    });
}
