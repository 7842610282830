import {
    CompanyAccountModelDto,
    UserModelDto,
    WireFileDto,
    WireFileModelDto,
    WireFileSearchCriteriaModelDto,
    WiresClient,
} from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';

import { Injectable } from '@jack-henry/frontend-utils/di';
import { Nullable } from '@treasury/utils';
import { FiDate } from '../../../dates';
import { PaginatedResponse } from '../../../shared/types';
import { mapToWireTemplate } from '../../mappings/wires';
import {
    WireFileActivity,
    mapToWireFileActivity,
} from '../../mappings/wires/wire-file-activity.entity';
import { WireCompanyRequests } from '../../requests/wires/wire-companies';
import { WireTemplateQueryDto } from '../../types';
import { DownloadFormat, DownloadService } from '../download';

@Injectable()
export class WiresService {
    constructor(
        private readonly http: TmHttpClient,
        private readonly wiresClient: WiresClient
    ) {}

    public getAllWireCompanies(nonDli: boolean) {
        return WireCompanyRequests.getAllWireCompanies(nonDli);
    }

    public getAllFileUploadUsers() {
        return WireCompanyRequests.getAllFileUploadUsers() as unknown as UserModelDto[];
    }

    public async getFiles(query: WireFileSearchCriteriaModelDto) {
        const response = await this.wiresClient.wiresSearchFile(query);
        const { items = [], pageNumber, pageSize, totalCount } = response.data;
        const fileActivity = items.map(mapToWireFileActivity);
        const paginatedResponse: PaginatedResponse<Nullable<WireFileModelDto>, WireFileActivity> = {
            data: fileActivity,
            pageNumber,
            pageSize,
            totalCount,
        };

        return paginatedResponse;
    }

    public async searchTemplates(query: WireTemplateQueryDto) {
        const dtos = (await WireCompanyRequests.searchTemplates(query)) || [];
        const wireTemplate = dtos.map(mapToWireTemplate);
        return wireTemplate.map(template => {
            template.status = template.isPendingEdit ? 'Pending Edits' : template.status;
            return template;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public fetchWireTemplateApprovers(parameters: Record<string, any>) {
        const body = {
            productId: parameters.templateId,
            createdBy: parameters.createdBy,
            approvalEntity: 'wireTemplate',
        };
        return this.http.request(`users/getApprovers`, {
            method: 'POST',
            body,
        });
    }

    public async fetchTransferAccounts() {
        return (await WireCompanyRequests.fetchTransferAccounts()) as CompanyAccountModelDto[];
    }

    public fetchWireConfiguration() {
        return this.http.request('/wireCompanies/wireConfiguration', {
            method: 'GET',
        });
    }

    public downloadWiresReport(outputType: DownloadFormat, filterModel: WireTemplateQueryDto) {
        const pageId = 'WireTemplateList';
        const fileSuffix = new FiDate(new Date()).toIsoDate();

        DownloadService.download<WireTemplateQueryDto>(
            fileSuffix,
            outputType,
            pageId,
            {
                ...filterModel,
                page: pageId,
            },
            ['PDF']
        );
    }

    public approveTemplate(id: string) {
        return WireCompanyRequests.approveTemplate(id);
    }

    public rejectTemplate(id: string) {
        return WireCompanyRequests.rejectTemplate(id);
    }

    public removeTemplate(id: string) {
        return WireCompanyRequests.removeTemplate(id);
    }

    public removeFile(id: string) {
        return WireCompanyRequests.removeFile(id);
    }

    public getWireFileContents(id: string): Promise<WireFileDto> {
        return WireCompanyRequests.getWireFileContents(id);
    }
}
