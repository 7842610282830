import { clone } from '@jack-henry/frontend-utils/functions';
import { WireCompanyModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

const nullWireCompany: WireCompanyModelDto = {
    id: 0,
    name: '',
    isDliWireCompany: false,
    updatedBy: undefined,
    updatedDate: '',
};

export class WireCompany extends DomainEntity<WireCompanyModelDto> {
    constructor(dto = clone(nullWireCompany)) {
        super(dto);
    }

    public createDefault(): this {
        return new WireCompany(clone(nullWireCompany)) as this;
    }

    public get name() {
        return this.dto.name;
    }

    public get id() {
        return this.dto.id;
    }

    public get isDliWireCompany() {
        return this.dto.isDliWireCompany;
    }
}
