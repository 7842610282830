/* eslint-disable no-restricted-syntax */
export const nullUser = {
    id: 0,
    userId: undefined,
    userName: undefined,
    isLocked: false,
    isMfaLocked: false,
    isAdmin: false,
    isSuperUser: false,
    status: undefined,
    publishState: undefined,
    isActive: false,
    isEditor: false,
    isDeleted: false,
    changedUserInformation: false,
    changedAccountAccess: false,
    hasAch: false,
    changedAchEntitlements: false,
    changedAchUserLimits: false,
    hasStandardPosPay: false,
    hasSsoPosPay: false,
    changedStandardPosPayEntitlements: false,
    changedSsoPosPayUserSettings: false,
    hasInformationReporting: false,
    changedInformationReportingEntitlements: false,
    hasInternalTransfers: false,
    changedInternalTransfersEntitlements: false,
    changedInternalTransfersUserLimits: false,
    hasWireTransfers: false,
    hasWireTransfersDomestic: false,
    hasWireTransfersInternational: false,
    changedWireTransfersEntitlements: false,
    changedWireTransfersUserLimits: false,
    hasWireTransfersDli: false,
    changedWireTransfersDliEntitlements: false,
    changedWireTransfersDliUserLimits: false,
    hasStopPayment: false,
    changedStopPaymentEntitlements: false,
    changedIpRestrictions: false,
    changedTimeAccess: false,
    hasIntegratedServices: false,
    changedIntegratedServices: false,
    hasReceivables: false,
    changedReceivables: false,
    hasAccountReconciliationReporting: false,
    changedAccountReconciliationReporting: false,
    name: '',
    loginId: '',
    department: '',
    email: '',
    phone: '',
    extension: '',
    mobile: '',
    mobileExtension: '',
    phoneCarrier: {
        phoneCarrierId: 0,
        phoneCarrierName: '',
        active: false,
        id: 0,
        updatedBy: undefined,
        updatedDate: '',
    },
    fax: '',
    accountNicknames: false,
    accountMasking: false,
    verificationMethod: undefined,
    isMfaBlocked: false,
    isAccountMaskingConfigurable: false,
    isMethodOfVerificationConfigurable: false,
    copyUserId: 0,
    isEnrolled: false,
    success: false,
    productPermissions: [
        {
            permission: '',
            productId: undefined,
            productName: undefined,
            id: 0,
            updatedBy: undefined,
            updatedDate: '',
        },
    ],
    securityMessage: {
        actionType: '',
        status: undefined,
        methodUsed: undefined,
        hasAlternate: false,
        errorCode: undefined,
        message: undefined,
        sendMessage: undefined,
        challengeMethodTypeId: 0,
        oneTimePassword: undefined,
        success: false,
    },
    pendingUser: {
        userId: 0,
        name: '',
        loginId: '',
        department: null,
        email: '',
        phone: '',
        extension: null,
        mobile: null,
        mobileExtension: null,
        phoneCarrier: null,
        fax: null,
        accountNicknames: null,
        accountMasking: null,
        verificationMethod: null,
        isMfaBlocked: false,
        isSuperUser: true,
        isAccountMaskingConfigurable: false,
        isMethodOfVerificationConfigurable: false,
        isAdmin: false,
        copyUserId: 0,
        isEnrolled: false,
        isActive: false,
        productPermissions: null,
        securityMessage: null,
        success: false,
        errorMessage: null,
    },
    pendingState: '',
    completedApprovals: 0,
};
