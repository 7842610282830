// cspell:disable
const vanityUrls = {
    'treasuryvanity.dev.jackhenry.com': 'tm554',
    'treasuryvanity.stg.jackhenry.com': 'tm554',
    'treasuryvanity.jackhenry.com': 'tm554',
    'business.abbank.com': 'abbank',
    'businessbanking.oakstarbank.com': 'oakstarbank',
    'commercial.alpinebank.com': 'alpinebank',
    'commercial.fidelitybankonline.com': 'fidelitybank',
    'corporateo2.oldsecond.com': 'oldsecond',
    'emerald.stonebank.com': 'stonebank',
    'mytreasury.citizensebank.com': 'citizensebank',
    'syncbusiness.bibank.com': 'bibank',
    'tm.bankonmainstreet.com': 'bankonmainstreet',
    'tm.busey.com': 'busey',
    'tm.chelseastate.bank': 'chelseastatebank',
    'tm.firstoklahomabank.com': 'firstoklahomabank',
    'tm.linkbank.com': 'linkbank',
    'tm.luminate.bank': 'luminatebank',
    'tm.northstarathome.com': 'northstarathome',
    'tm.peapackprivate.com': 'pgbank',
    'tm.pgbank.com': 'pgbank',
    'tm.wmcb.com': 'wmcb',
    'tmbs.fabt.bank': 'fabt',
    'tmonline.univest.net': 'souderton',
    'tps.altabank.com': 'insighttreasury',
    'connect.insighttreasury.com': 'insighttreasury',
    'secure1.capecodfive.com': 'capecodfive',
    'treas.web5.com': 'web5',
    'treasury.alpinebank.com': 'alpinebank',
    'treasury.bankbsf.com': 'bankbsf',
    'treasury.bankfirstfs.com': 'bankfirstfs',
    'treasury.btcbank.bank': 'btcbank',
    'treasury.chainbridgebank.com': 'chainbridge',
    'treasury.choiceone.bank': 'choiceone',
    'treasury.choiceone.com': 'choiceone',
    'treasury.commerceonebank.com': 'cob',
    'treasury.craft.bank': 'craftbank',
    'treasury.cwbk.com': 'cwbk',
    'treasury.fcbanking.com': 'fcbanking',
    'treasury.greatsouthernbank.com': 'greatsouthernbank',
    'treasury.hawthornbank.com': 'hawthornbank',
    'treasury.idahofirstbank.com': 'idahofirstbank',
    'treasury.jbt.bank': 'jbt',
    'treasury.lbtexas.com': 'lbtexas',
    'treasury.mcb.com': 'mcb',
    'treasury.nbofi.com': 'nbofi',
    'treasury.orrstown.com': 'orrstown',
    'treasury.outdoorbank.com': 'outdoorbank',
    'treasury.securitybankkc.com': 'securitybankkc',
    'treasury.securityfederalbank.com': 'securityfederalbank',
    'treasury.smb-t.com': 'smbt',
    'treasury.sonatabank.com': 'sonatabank',
    'treasury.southwest.bank': 'southwestbank',
    'treasury.tandem.bank': 'tandembank',
    'treasury.trustarbank.com': 'trustarbank',
    'treasury.ubmich.com': 'unionbank',
    'treasury.wilsonbank.com': 'wilsonbank',
    'treasurymanagement.bankofcastile.com': 'bankofcastile',
    'treasurymanagement.evergreenbankgroup.com': 'evergreenbankgroup',
    'treasurymanagement.fieldpointprivate.com': 'fieldpointprivate',
    'treasurymanagement.mahopacbank.com': 'mahopacbank',
    'treasurymanagement.pegasusbankdallas.com': 'pegasus',
    'treasurymanagement.simmonsbank.com': 'simmonsbank',
    'treasurymanagement.tompkinsbank.com': 'tompkinsbank',
    'treasurymanagement.vistbank.com': 'vistbank',
    'treasurysuite.tworivers.bank': 'tworivers',
    'treasury.citizenslc.com': 'citizenslc',
    'treasury.oxfordbank.bank': 'oxford',
    'treasury.regent.bank': 'regentbank',
};
const vanityUrlToIdMap = new Map(Object.entries(vanityUrls));

module.exports = { vanityUrlToIdMap };
