import { clone } from '@jack-henry/frontend-utils/functions';
import { CreditorModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { address2Array } from '../utilities';
import { nullCreditor } from './wire-creditor.dto';
/**
 * Represents the client-side contract for Wire beneficiaries.
 */
export class WireCreditor extends DomainEntity<CreditorModelDto> {
    constructor(dto = clone(nullCreditor)) {
        super(dto);
        this.dto = dto;
    }

    public createDefault(): this {
        return new WireCreditor(this.dto) as this;
    }

    public get id() {
        return this.dto.id || 0;
    }

    public get name() {
        return this.dto.name || '';
    }

    public set name(templateName) {
        this.dto.name = templateName;
    }

    public get accountNumber() {
        return this.dto.accountNumber || '';
    }

    public set accountNumber(accountNumber) {
        this.dto.accountNumber = accountNumber;
    }

    public get address() {
        return address2Array(Object.assign(this));
    }

    public get addressLine1() {
        return this.dto.addressLine1;
    }

    public set addressLine1(addressLine1) {
        this.dto.addressLine1 = addressLine1;
    }

    public get addressLine2() {
        return this.dto.addressLine2;
    }

    public set addressLine2(addressLine2) {
        this.dto.addressLine2 = addressLine2;
    }

    public get buildingNumber() {
        return this.dto.buildingNumber;
    }

    public set buildingNumber(buildingNumber) {
        this.dto.buildingNumber = buildingNumber;
    }

    public get streetName() {
        return this.dto.streetName;
    }

    public set streetName(streetName) {
        this.dto.streetName = streetName;
    }

    public get addressLine3() {
        return this.dto.addressLine3;
    }

    public set addressLine3(addressLine3) {
        this.dto.addressLine3 = addressLine3;
    }

    public get city() {
        return this.dto.city;
    }

    public set city(city) {
        this.dto.city = city;
    }

    public get state() {
        return this.dto.state;
    }

    public set state(state) {
        this.dto.state = state;
    }

    public get postalCode() {
        return this.dto.postalCode;
    }

    public set postalCode(postalCode) {
        this.dto.postalCode = postalCode;
    }

    public get country() {
        return this.dto.country;
    }

    public set country(country) {
        this.dto.country = country;
    }

    public get department() {
        return this.dto.department;
    }

    public set department(department) {
        this.dto.department = department;
    }

    public get subDepartment() {
        return this.dto.subDepartment;
    }

    public set subDepartment(subDepartment) {
        this.dto.subDepartment = subDepartment;
    }

    public get postBox() {
        return this.dto.postBox;
    }

    public set postBox(postBox) {
        this.dto.postBox = postBox;
    }

    public get buildingName() {
        return this.dto.buildingName;
    }

    public set buildingName(buildingName) {
        this.dto.buildingName = buildingName;
    }

    public get buildingFloor() {
        return this.dto.buildingFloor;
    }

    public set buildingFloor(buildingFloor) {
        this.dto.buildingFloor = buildingFloor;
    }

    public get buildingRoom() {
        return this.dto.buildingRoom;
    }

    public set buildingRoom(buildingRoom) {
        this.dto.buildingRoom = buildingRoom;
    }

    public get townLocationName() {
        return this.dto.townLocationName;
    }

    public set townLocationName(townLocationName) {
        this.dto.townLocationName = townLocationName;
    }

    public get districtName() {
        return this.dto.districtName;
    }

    public set districtName(districtName) {
        this.dto.districtName = districtName;
    }

    public get isIsoCompliant() {
        return this.dto.isIsoCompliant || false;
    }

    public get bank() {
        return this.dto.bank;
    }

    public get notes() {
        return this.dto.notes || '';
    }

    public set notes(notes) {
        this.dto.notes = notes;
    }

    public get intermediaryBanks() {
        return this.dto.intermediaryBanks;
    }

    public resetIntermediaryBanks() {
        return (this.dto.intermediaryBanks = []);
    }

    public get isInternational() {
        return this.dto.isInternational || false;
    }

    public set isInternational(isInternational) {
        this.dto.isInternational = isInternational;
    }

    public get status() {
        return this.dto.status || '';
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get audit() {
        return this.dto.audit || '';
    }

    public get permissions() {
        return this.dto.permissions || [];
    }

    public set permissions(permissions) {
        this.dto.permissions = permissions;
    }

    public get updatedDate() {
        return this.dto.updatedDate;
    }

    public get numberOfApprovalsNeeded() {
        return this.dto.numberOfApprovalsNeeded;
    }

    public get isTemplateBeneficiary() {
        return this.dto.isTemplateBeneficiary;
    }

    public get isUserApprovalLimitLess() {
        return this.dto.isUserApprovalLimitLess;
    }

    public get isFreeForm() {
        return this.dto.isFreeForm;
    }

    public set isFreeForm(isFreeForm) {
        this.dto.isFreeForm = isFreeForm;
    }

    public get isForeignCurrency() {
        return this.dto.isForeignCurrency;
    }

    public set isForeignCurrency(isForeignCurrency) {
        this.dto.isForeignCurrency = isForeignCurrency;
    }

    public toDto() {
        return this.dto;
    }
}
