import { WireIsoService } from '@treasury/domain/wires';
import { ListeningElementMixin } from '@treasury/omega/components';
import usdFormat from '@treasury/policy/lib/formatters/usd.formatter';
import { css, html, LitElement } from 'lit';
import { mix } from 'mixwith';

export class WireFileSummaryHeader extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            wireFile: Object,
            files: Array,
            title: String,
            records: Object,
            wireIsoService: Object,
            isoLabels: Object,
        };
    }

    constructor() {
        super();
        this.title = 'File Upload Summary';
        this.wireIsoService = new WireIsoService();
        this.isoLabels = WireIsoService.legacyLabels;
    }

    async firstUpdated() {
        this.isoLabels = await this.wireIsoService.getLabels();
    }

    // review updated usage
    updated() {
        if (this.records) {
            ['updated', 'page-changed'].forEach(event =>
                this.listenTo(this.records, event, () => {
                    this.requestUpdate();
                })
            );
        }
    }

    getFileSummary() {
        const file = this.files[0] ?? null;
        const onlyUnique = (value, index, self) => self.indexOf(value) === index;
        const sum = (total, num) => total + num;
        const wires = this.records
            ? this.records.getData().filter(wire => wire.selected)
            : this.wireFile?.wires;
        const totalBeneficiaries = Array.isArray(wires)
            ? wires.map(w => w.beneficiaryName).filter(onlyUnique).length
            : 0;
        const totalWires = Array.isArray(wires) ? wires.length : 0;
        const totalDebitAmount = Array.isArray(wires)
            ? wires.map(w => w.wireAmount || w.amount).reduce(sum, 0)
            : 0;

        return {
            name: file.name,
            size: `${file.size / 1000} KB`,
            totalBeneficiaries,
            totalWires,
            totalDebitAmount,
        };
    }

    printAll() {
        this.dispatchEvent(
            new CustomEvent('printAll', {
                composed: true,
                bubbles: true,
                detail: { records: this.records },
            })
        );
    }

    render() {
        if (!this.files || this.files.length < 1 || !this.wireFile)
            return html`<em>No Wire File Found</em>`;
        const summary = this.getFileSummary();
        return html`<div>
            <div class="title-container">
                <h2>${this.title}</h2>
                <omega-button type="icon" icon="print" @click=${this.printAll}>Print</omega-button>
            </div>
            <div class="header-bar">File Name: ${summary.name}</div>
            <div class="columns">
                <!-- col 1 -->
                <div>
                    <div class="row">
                        <div class="label">File Size:</div>
                        <div>${summary.size}</div>
                    </div>
                </div>
                <!-- col 2 -->
                <div>
                    <div class="row">
                        <div class="label">Total Wires:</div>
                        <div>${summary.totalWires}</div>
                    </div>
                    <div class="row">
                        <div class="label">Total ${this.isoLabels.creditors}:</div>
                        <div>${summary.totalBeneficiaries}</div>
                    </div>
                </div>
                <!-- col 3 -->
                <div>
                    <div class="row">
                        <div class="label">Total Debit Amount:</div>
                        <div>${usdFormat(summary.totalDebitAmount)}</div>
                    </div>
                </div>
            </div>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            h2 {
                font-size: 22px;
                font-weight: 500;
            }
            .title-container {
                padding: 0 0 0 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .header-bar {
                padding: 12px 10px;
                border-width: 0px;
                border-style: solid;
                font-size: var(--omega-label);
                background-color: rgb(230, 230, 230);
                box-shadow: rgb(204, 204, 204) 0px -1px 0px inset;
                color: rgb(70, 70, 70);
                font-weight: 400;
            }
            .columns {
                display: flex;
                padding: 16px;
                line-height: 1.5;
            }
            .columns > div {
                flex: 1 0 0px;
                padding: 0 16px;
                position: relative;
            }
            .columns > div:after {
                content: '';
                position: absolute;
                top: 12px;
                bottom: 12px;
                right: 0px;
                border-right: 1px solid var(--omega-divider-color);
            }
            .columns > div:last-child:after {
                display: none;
            }
            .row {
                display: flex;
                padding: 8px 0;
                align-items: center;
            }
            .row.no-align {
                align-items: unset;
            }
            .row > * {
                flex: 1 0 0px;
            }
            .row > label,
            .row > .label {
                max-width: 228px;
                color: var(--omega-text-secondary);
            }
        `;
    }
}
window.customElements.define('wire-file-summary-header', WireFileSummaryHeader);
export default WireFileSummaryHeader;
