export enum ProductFeatureType {
    AccountAccess = 'accountaccess',
    IpRestrictions = 'ipRestrictions',
    TimeAccess = 'usertimeaccess',
    AccountRecon = 'AccountRecon',
    AchEntitlements = 'achproductentitlements',
    AchUserLimits = 'achlimits',
    ArpExceptions = 'ArpExceptions',
    AchExceptions = 'AchExceptions',
    AchFilters = 'AchFilters',
    PositivePay = 'PositivePay',
    InformationReporting = 'InformationReporting',
    StopPayment = 'StopPayment',
    TransferUserLimits = 'InternalTransferLimits',
    TransferEntitlements = 'InternalTransfer',
    WireInternationalEntitlements = 'WireTransferInternational',
    WireDomesticEntitlements = 'WireTransferDomestic',
    WireUserLimits = 'WireTransfer',
    FxWireEntitlements = 'WireTransferDLI',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    FxWireUserLimits = 'WireTransferDLI',
    ReceivablesEntitlements = 'receivablessettings',
    IntegratedServicesEntitlements = 'IntegratedServices',
}

/** During the refactoring of the Users code over time, I'm using this as a source of truth for
 * which Product Features are ready to use the refactored code paths */
export const standardizedFeatures = [
    ProductFeatureType.InformationReporting,
    // ProductFeatureType.WireInternationalEntitlements,
    // ProductFeatureType.WireDomesticEntitlements,
    ProductFeatureType.StopPayment,
    // ProductFeatureType.ArpExceptions,
    // ProductFeatureType.AchExceptions,
    // ProductFeatureType.AchFilters,
    ProductFeatureType.TransferEntitlements,
] as const;

export type StandardizedFeature = (typeof standardizedFeatures)[number];

export const isStandardizedFeature = (f: ProductFeatureType | ''): f is StandardizedFeature =>
    f ? (standardizedFeatures as Readonly<ProductFeatureType[]>).includes(f) : false;
