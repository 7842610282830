/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class ManifestClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Manifest
   * @name ManifestGetByFinancialInstitutionId
   * @summary Get the manifest using the provided FI ID in the query params.
   * @request GET:/manifest/{fiid}
   */
  manifestGetByFinancialInstitutionId = (fiid: string, params: RequestParams = {}) =>
    this.http.request<string, any>({
      path: `/manifest/${fiid}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
