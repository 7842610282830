/**
 * BackOffice Type Overrides
 *
 * This file is for overriding/correcting the swagger-generated types. When overriding a type:
 * 1. Import the incorrect type without "Dto" so that the original name can be used for export
 * 2. Add a jsdoc-style comment with the name of the original type (just for file readability)
 * 3. Create new overridden type and export it using the original name from data-contracts
 * 4. Explicitly re-export the new type from index.ts in this folder (necessary to avoid ambiguity)
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    CompaniesClient as GeneratedCompaniesClient,
    CompanyuserClient as GeneratedCompanyuserClient,
} from './generated/clients';
import {
    AccountModelDto,
    FiProductFeatureModelDto,
    InformationReportingProductFeatureModelDto as GeneratedInformationReportingProductFeatureModelDto,
    ProductFeatureModelDto,
    RemoteDepositProductModelDto,
    SearchCompanyUserModelDto,
} from './generated/data-contracts';
import { ContentType, RequestParams } from './generated/http-client';

/**
 * ProductFeatureModelDto
 */

export interface FromAccountPermissionDto {
    id: number;
    fromAccountType: string;
    isAllowed: boolean;
}
export interface AccountPermissionDto {
    id: number;
    toAccountType: string;
    fromAccountTypes: FromAccountPermissionDto[];
}
export interface TransferProductFeatureModelDto extends ProductFeatureModelDto {
    accounts?: AccountModelDto[];
    accountPermissions: AccountPermissionDto[];
    allowLoanPaymentPastDue?: boolean;
    approval?: 'None' | number;
    dailyLimit?: number;
    templateApproval?: 'None' | number;
}

export interface FiTransferProductFeatureModelDto extends FiProductFeatureModelDto {
    accounts?: AccountModelDto[];
    accountPermissions: AccountPermissionDto[];
    allowLoanPaymentPastDue?: boolean;
    approval?: 'None' | number;
    dailyLimit?: number;
    templateApproval?: 'None' | number;
}

export interface InformationReportingProductFeatureModelDto
    extends GeneratedInformationReportingProductFeatureModelDto {
    enableIntuitFormattedExports: boolean;
}

export interface StopPaymentProductFeatureModelDto extends ProductFeatureModelDto {
    accounts?: AccountModelDto[];
    approvalCount: number;
}

// TODO: Create DTOs for each product type: ACH, Wires, etc.

/**
 * Overrides the generated CompanyUserSearch method to use the correct type
 *
 * @tags CompanyUser
 * @name CompanyUserSearch
 * @summary Searches for users.
 * @request POST:/companyuser/{companyId}/users/search
 */
@Injectable()
export class CompanyuserClient extends GeneratedCompanyuserClient {
    companyUserSearch = (
        searchUserModel: SearchCompanyUserModelDto | null,
        params: RequestParams = {}
    ) =>
        // @ts-expect-error
        this.http.request<UserSummaryModelDto, any>({
            path: `/companyuser/${searchUserModel!.companyId}/users/search`,
            method: 'POST',
            body: searchUserModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}

@Injectable()
export class CompaniesClient extends GeneratedCompaniesClient {
    // @ts-expect-error
    companiesEditRemoteDepositProduct = (
        companyId: number,
        product: RemoteDepositProductModelDto | null,
        params: RequestParams = {}
    ) =>
        // @ts-expect-error
        this.http.request<RemoteDepositProductModelDto, any>({
            path: `/Companies/${companyId}/EditRemoteDepositProduct`,
            method: 'PUT',
            body: product,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
