/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AgileticsSamlResponseDto,
  CompanyLoginRequestDto,
  CompanyLoginResponseDto,
  ErrorHttpContentDto,
  SsoViaUisRequestDto,
  SsoViaUisResponseDto,
  UisLoginUrlRequestDto,
  UisLoginUrlResponseDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AuthenticationClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Authentication
   * @name AuthenticationUisLoginUrl
   * @summary Gets a UisLoginUrlResponse.
   * @request POST:/Authentication/UisLoginUrl
   */
  authenticationUisLoginUrl = (uisLoginUrlRequest: UisLoginUrlRequestDto | null, params: RequestParams = {}) =>
    this.http.request<UisLoginUrlResponseDto, ErrorHttpContentDto>({
      path: `/Authentication/UisLoginUrl`,
      method: "POST",
      body: uisLoginUrlRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name AuthenticationSsoViaUis
   * @summary Gets a SsoViaUisResponse.
   * @request POST:/Authentication/SsoViaUis
   */
  authenticationSsoViaUis = (ssoViaUisRequest: SsoViaUisRequestDto | null, params: RequestParams = {}) =>
    this.http.request<SsoViaUisResponseDto, ErrorHttpContentDto>({
      path: `/Authentication/SsoViaUis`,
      method: "POST",
      body: ssoViaUisRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name AuthenticationUisMobileRedirect
   * @summary Takes in fiId, code, and state.
   * @request GET:/Authentication/UisMobileRedirect/{fiId}
   */
  authenticationUisMobileRedirect = (
    fiId: string,
    query: {
      code: string | null;
      state: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/Authentication/UisMobileRedirect/${fiId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name AuthenticationCompanyLogin
   * @summary Gets a CompanyLoginResponse.
   * @request POST:/Authentication/CompanyLogin
   */
  authenticationCompanyLogin = (companyLoginRequest: CompanyLoginRequestDto | null, params: RequestParams = {}) =>
    this.http.request<CompanyLoginResponseDto, ErrorHttpContentDto>({
      path: `/Authentication/CompanyLogin`,
      method: "POST",
      body: companyLoginRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name AuthenticationAgiletics
   * @summary Gets a AgileticsSamlResponse.
   * @request POST:/Authentication/Agiletics
   */
  authenticationAgiletics = (params: RequestParams = {}) =>
    this.http.request<AgileticsSamlResponseDto, ErrorHttpContentDto>({
      path: `/Authentication/Agiletics`,
      method: "POST",
      format: "json",
      ...params,
    });
}
