import { AchBank, AchBankDto, AchCompany, SecCode } from '.';
import { Frequency } from '../frequency';
import { OffsetAccount } from './offsetAccount';

export interface PaymentHeaderInputData {
    accountNumber?: string;
    accountType?: string;
    achCompany: AchCompany;
    achCompanyId: number | string;
    addenda?: string;
    addendaId?: string;
    amount?: number;
    audit: string;
    bank?: AchBankDto;
    creditAmount: number;
    debitAmount: number;
    discretionaryData: string;
    entryDescription: string;
    errors?: boolean;
    fileArchiveId?: number | null;
    frequency: Frequency;
    hold?: boolean;
    id: number;
    idNumber?: string;
    nachaFileUpload?: boolean;
    name: string;
    offsetAccount: OffsetAccount | string;
    parentId: number;
    prenote?: boolean;
    restricted: boolean;
    reversalAchPaymentId: number;
    secCode: SecCode | string;
    status?: string | null;
    step: number;
    transactionId?: string;
    transactionType?: string;
    achPaymentDraftId?: number;
    hasAccess?: boolean;
}

export class PaymentHeader {
    constructor(dto: PaymentHeaderInputData) {
        Object.assign(this, dto);
    }

    public accountNumber?: string;

    public accountType?: string;

    public achCompany!: AchCompany;

    public achCompanyId!: number | string;

    public addenda?: string;

    public addendaId?: string;

    public amount?: number;

    public audit!: string;

    public bank?: AchBank | AchBankDto;

    public creditAmount!: number;

    public debitAmount!: number;

    public discretionaryData!: string;

    public editable?: boolean;

    public entryDescription!: string;

    public errors?: boolean;

    public frequency!: Frequency;

    public hold?: boolean;

    public id!: number;

    public idNumber?: string | null;

    public nachaFileUpload? = false;

    public name!: string;

    public offsetAccount!: OffsetAccount | string;

    public parentId!: number;

    public prenote?: boolean;

    public restricted!: boolean;

    public reversalAchPaymentId!: number;

    public secCode!: SecCode | string;

    public status?: string | null;

    public step: number = 0;

    public transactionId?: string;

    public transactionType?: string;

    public testAchCompanyName?: string;

    private get isEditableStatus() {
        if (!this.status) {
            return false;
        }
        const editablePayments = [
            'Approval Rejected',
            'Failed',
            'Uninitiated',
            'Expired',
            'Scheduled',
        ];

        return editablePayments.indexOf(this.status) !== -1;
    }

    private get existing() {
        return !!this.id && this.id !== 0;
    }

    private get isChildPayment() {
        return !!this.parentId && this.parentId !== 0;
    }

    private get isReversal() {
        return !!this.reversalAchPaymentId && this.reversalAchPaymentId !== 0;
    }

    public get isEditable() {
        return this.existing && this.isEditableStatus && !this.isChildPayment && !this.isReversal;
    }
}
