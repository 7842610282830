/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AccountBalanceRequestModelDto,
  GetOfxUserAccountsRequestModelDto,
  GetTransfersRequestDto,
  OfxAccountsModelDto,
  OfxAuthenticationCodeDto,
  OfxContextDto,
  OfxProductDto,
  OfxTransactionsModelDto,
  OfxTransferRequestDto,
  OfxTransfersModelDto,
  PasswordRulesDto,
  TransactionDetailRequestDto,
  TransferAccountRequestDto,
  TransferResultDto,
  UserIdentityDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class OfxClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Ofx
   * @name OfxValidateCredentials
   * @summary Validates the credentials.
   * @request POST:/ofx/validateCredentials
   */
  ofxValidateCredentials = (userIdentity: UserIdentityDto, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/ofx/validateCredentials`,
      method: "POST",
      body: userIdentity,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetCompanyIdByIntuitId
   * @summary Gets the company identifier by intuit identifier.
   * @request GET:/ofx/getCompanyIdByIntuitId
   */
  ofxGetCompanyIdByIntuitId = (
    query: {
      /** The fi identifier. */
      fiId: string | null;
      /** The ofx authentication code. */
      ofxAuthenticationCode: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OfxAuthenticationCodeDto, any>({
      path: `/ofx/getCompanyIdByIntuitId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxContext
   * @summary Gets the ofx context.
   * @request POST:/ofx/getOfxContext
   */
  ofxGetOfxContext = (userIdentity: UserIdentityDto, params: RequestParams = {}) =>
    this.http.request<OfxContextDto, any>({
      path: `/ofx/getOfxContext`,
      method: "POST",
      body: userIdentity,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetPasswordRules
   * @summary Gets the password rules.
   * @request GET:/ofx/getPasswordRules
   */
  ofxGetPasswordRules = (
    query: {
      /** The fi identifier. */
      fiId: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PasswordRulesDto, any>({
      path: `/ofx/getPasswordRules`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetCompanyUserPasswordRules
   * @summary Gets the company user password rules.
   * @request GET:/ofx/getCompanyUserPasswordRules
   */
  ofxGetCompanyUserPasswordRules = (
    query: {
      /** The fi identifier. */
      fiId: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PasswordRulesDto, any>({
      path: `/ofx/getCompanyUserPasswordRules`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxAccountsWithBalance
   * @summary Gets the ofx accounts with balance.
   * @request POST:/ofx/getOfxAccountsWithBalance
   */
  ofxGetOfxAccountsWithBalance = (accountBalanceRequest: AccountBalanceRequestModelDto, params: RequestParams = {}) =>
    this.http.request<OfxAccountsModelDto, any>({
      path: `/ofx/getOfxAccountsWithBalance`,
      method: "POST",
      body: accountBalanceRequest,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxAccountsWithoutBalance
   * @summary Gets the ofx accounts without balance.
   * @request POST:/ofx/getOfxAccountsWithoutBalance
   */
  ofxGetOfxAccountsWithoutBalance = (
    accountBalanceRequest: AccountBalanceRequestModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<OfxAccountsModelDto, any>({
      path: `/ofx/getOfxAccountsWithoutBalance`,
      method: "POST",
      body: accountBalanceRequest,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxTransferToAccount
   * @summary Gets the ofx transfer to account.
   * @request POST:/ofx/getOfxTransferToAccount
   */
  ofxGetOfxTransferToAccount = (transferAccountRequest: TransferAccountRequestDto | null, params: RequestParams = {}) =>
    this.http.request<OfxAccountsModelDto, any>({
      path: `/ofx/getOfxTransferToAccount`,
      method: "POST",
      body: transferAccountRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxTransactionDetail
   * @summary Gets the ofx transaction detail.
   * @request POST:/ofx/getOfxTransactionDetail
   */
  ofxGetOfxTransactionDetail = (request: TransactionDetailRequestDto | null, params: RequestParams = {}) =>
    this.http.request<OfxTransactionsModelDto, any>({
      path: `/ofx/getOfxTransactionDetail`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxProducts
   * @summary Gets the ofx products.
   * @request POST:/ofx/getOfxProducts
   */
  ofxGetOfxProducts = (accountBalanceRequest: AccountBalanceRequestModelDto, params: RequestParams = {}) =>
    this.http.request<OfxProductDto, any>({
      path: `/ofx/getOfxProducts`,
      method: "POST",
      body: accountBalanceRequest,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxSearchOfxAccountBalance
   * @summary Searches the ofx account balance.
   * @request POST:/ofx/searchOfxAccountBalance
   */
  ofxSearchOfxAccountBalance = (accountBalanceRequest: AccountBalanceRequestModelDto, params: RequestParams = {}) =>
    this.http.request<OfxAccountsModelDto, any>({
      path: `/ofx/searchOfxAccountBalance`,
      method: "POST",
      body: accountBalanceRequest,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetOfxUserAccounts
   * @summary Gets the ofx user accounts.
   * @request POST:/ofx/getOfxUserAccounts
   */
  ofxGetOfxUserAccounts = (
    ofxUserAccountRequest: GetOfxUserAccountsRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<OfxAccountsModelDto, any>({
      path: `/ofx/getOfxUserAccounts`,
      method: "POST",
      body: ofxUserAccountRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxAddOfxTransfer
   * @summary Adds the ofx transfer.
   * @request POST:/ofx/addOfxTransfer
   */
  ofxAddOfxTransfer = (request: OfxTransferRequestDto, params: RequestParams = {}) =>
    this.http.request<TransferResultDto, any>({
      path: `/ofx/addOfxTransfer`,
      method: "POST",
      body: request,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxCancelOfxTransfer
   * @summary Cancels the ofx transfer.
   * @request POST:/ofx/cancelOfxTransfer
   */
  ofxCancelOfxTransfer = (request: OfxTransferRequestDto, params: RequestParams = {}) =>
    this.http.request<TransferResultDto, any>({
      path: `/ofx/cancelOfxTransfer`,
      method: "POST",
      body: request,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxEditOfxTransfer
   * @summary Edits the ofx transfer.
   * @request POST:/ofx/editOfxTransfer
   */
  ofxEditOfxTransfer = (request: OfxTransferRequestDto, params: RequestParams = {}) =>
    this.http.request<TransferResultDto, any>({
      path: `/ofx/editOfxTransfer`,
      method: "POST",
      body: request,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ofx
   * @name OfxGetAllTransfers
   * @summary Gets all transfers.
   * @request POST:/ofx/getAllTransfers
   */
  ofxGetAllTransfers = (request: GetTransfersRequestDto | null, params: RequestParams = {}) =>
    this.http.request<OfxTransfersModelDto, any>({
      path: `/ofx/getAllTransfers`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
