import { DiContainer, Injectable } from '@jack-henry/frontend-utils/di';
import { CurrencyModelDto } from '@treasury/api/channel';
import { currenciesList } from './currencies-list';

function createCurrencyModel(currencyCode: string, name: string): CurrencyModelDto {
    return { id: 0, updatedDate: '', currencyCode, name };
}

@Injectable()
export class CurrenciesService {
    public static async getInstance() {
        const container = await DiContainer.getInstance();
        return container.get(CurrenciesService);
    }

    public getAll() {
        return Promise.resolve(
            currenciesList.map(([currencyCode, name]) => createCurrencyModel(currencyCode, name))
        );
    }
}
