import { registerConfigBlock } from './app.config.js';
import { appConstantsModule } from './app.constants';
import { statesModule } from './app.routes';
import { registerRunBlock } from './app.run';
import { constantsModule } from './constants';
import { channelDiModule } from './constants/di.constants';
import { candoModule } from './directives/cando/cando.module.js';
import { directivesComponentsModule } from './directives/components/module.js';
import { directivesModule } from './directives/module.js';
import { filtersModule } from './filters';
import { servicesCommonModule } from './services/common/module.js';
import { servicesModule } from './services/module';
import { sharedModule } from './shared/module';

let channelModule: ng.IModule;

/**
 * Get the master channel module (and create it if hasn't been already).
 */
export function getChannelModule() {
    if (channelModule) {
        return channelModule;
    }

    channelModule = angular.module('channel', [
        'ui.router',
        'ui.bootstrap',
        'ngResource',
        'LocalStorageModule',
        'toaster',
        'ngAnimate',
        'kendo.directives',
        'channelAnimations',
        'interpol',
        'ngSanitize',
        'ngCookies',
        'ngFileSaver',
        'vs-repeat',
        'gridster',
        'ui.sortable',
        'ngMessages',
        'ngAria',
        servicesCommonModule.name,
        servicesModule.name,
        sharedModule.name,
        directivesModule.name,
        candoModule.name,
        directivesComponentsModule.name,
        constantsModule.name,
        channelDiModule.name,
        statesModule.name,
        appConstantsModule.name,
        filtersModule.name,
    ]);

    registerConfigBlock(channelModule);
    registerRunBlock(channelModule);

    return channelModule;
}
