export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            require: 'ngModel',
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                id: '@',
                name: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                transaction: '=?',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                disabled: '=',
                form: '=',
                enableFreeFormEntry: '=?',
                onEnterFreeFormBeneficiary: '&',
                onSelectBeneficiary: '&',
                minLength: '@',
                isoCreditorLabel: '@',
            },
            link(scope, element, attrs, modelCtrl) {
                const input = element.find('input');
                input.focus(() => {
                    input.trigger('input');
                    input.trigger('change');
                });
            },
            template: require('./typeAheadNameTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = [];

                    $scope.query = `item as item.${$scope.columnName} for item in internalSelectionList`;

                    $scope.$watch(
                        'transaction',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = $scope.selectionList.map(item => ({
                                    name: item.name,
                                    accountNumber: item.accountNumber,
                                }));
                            }
                        },
                        true
                    );

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = $scope.selectionList.map(item => ({
                                    name: item.name,
                                    accountNumber: item.accountNumber,
                                }));
                            }
                        },
                        true
                    );

                    $scope.$watch('ngModel', newValue => {
                        if (
                            newValue !== null &&
                            newValue !== undefined &&
                            typeof newValue !== 'object'
                        ) {
                            $scope.ngModel = newValue;

                            angular.forEach($scope.internalSelectionList, item => {
                                if (item.name === newValue) {
                                    $scope.ngModel = item;
                                }
                            });
                        }
                    });

                    $scope.filterBeneficiaries = function () {
                        let beneficaries = [];
                        $scope.instance = $scope.form.beneficiary.$viewValue;
                        $scope.model = $scope.form.beneficiary.$modelValue;
                        if ($scope.instance.length >= $scope.minLength) {
                            beneficaries = $scope.internalSelectionList.filter(
                                beneficiary =>
                                    beneficiary.name
                                        .toLowerCase()
                                        .indexOf($scope.instance.toLowerCase()) !== -1 ||
                                    beneficiary.name
                                        .toUpperCase()
                                        .indexOf($scope.instance.toUpperCase()) !== -1 ||
                                    beneficiary.accountNumber
                                        .toLowerCase()
                                        .indexOf($scope.instance.toLowerCase()) !== -1 ||
                                    beneficiary.accountNumber
                                        .toUpperCase()
                                        .indexOf($scope.instance.toUpperCase()) !== -1
                            );
                        }
                        if (
                            $scope.enableFreeFormEntry &&
                            (!$scope.model || !$scope.model.enterFreeFormBeneficiary)
                        ) {
                            beneficaries.unshift({
                                name: `Enter ${$scope.isoCreditorLabel || 'Beneficiary'}`,
                                enterFreeFormBeneficiary: true,
                            });
                        }
                        return beneficaries;
                    };

                    $scope.onSelect = function ($item, $model, $label) {
                        if (
                            !!$item.enterFreeFormBeneficiary &&
                            !!$scope.onEnterFreeFormBeneficiary
                        ) {
                            $scope.onEnterFreeFormBeneficiary();
                        } else if ($scope.onSelectBeneficiary) {
                            $scope.onSelectBeneficiary({ beneficiary: $item });
                        }
                    };
                },
            ],
        };
    },
];
