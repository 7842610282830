import { FiDate } from '@treasury/domain/dates';

CreatePaymentMultipleTaxTemplatesController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$uibModal',
    '$location',
    '$anchorScroll',
    'toaster',
    'modalService',
    'entitlementsService',
    'taxesService',
    'achPaymentTypes',
    'usersService',
    'statesService',
    'companyAccountsService',
    'achPaymentsService',
    'holidaysService',
    'securityService',
    'utilityService',
    'downloadPageId',
    'taxPaymentAccountTypes',
    'scopeService',
    'achCompaniesService',
    'timeZones',
];

export default function CreatePaymentMultipleTaxTemplatesController(
    $scope,
    $state,
    $timeout,
    $uibModal,
    $location,
    $anchorScroll,
    toaster,
    modalService,
    entitlementsService,
    taxesService,
    achPaymentTypes,
    usersService,
    statesService,
    companyAccountsService,
    achPaymentsService,
    holidaysService,
    securityService,
    utilityService,
    downloadPageId,
    taxPaymentAccountTypes,
    scopeService,
    achCompaniesService,
    timeZones
) {
    const maximumNumberOfAmounts = 3;
    const firstStateAmountTypeId = 1;
    const taxTemplateListState = 'payables.ach.payments.tax-templates';

    $scope.form = {};
    $scope.globalFrequency = {
        show: false,
        summary: '',
    };
    $scope.isReviewing = false;
    $scope.canRestrictBatch = entitlementsService.hasEntitlement('Restricted Batch');
    $scope.hasFullEditEntitlement = entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
    $scope.hasPartialEditEntitlement = entitlementsService.hasEntitlement(
        'ACH, Payment, Partial Edit'
    );
    $scope.isAchPrefundingEntitled = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowUnbalancedPayments'
    );
    $scope.canFullEdit = canFullEdit;
    $scope.totals = {
        credit: { amount: 0, transactions: 0 },
        debit: { amount: 0, transactions: 0 },
    };
    $scope.findAccountForBalancedCompany = findAccountForBalancedCompany;
    $scope.findFederalTaxCodes = findFederalTaxCodes;
    $scope.findStateTaxCodes = findStateTaxCodes;
    $scope.achCompanyUndefined = achCompanyUndefined;
    $scope.stateTaxCodeChanged = stateTaxCodeChanged;
    $scope.federalTaxCodeChanged = federalTaxCodeChanged;
    $scope.federalAmountTypeChanged = federalAmountTypeChanged;
    $scope.federalAmountTypesLoaded = federalAmountTypesLoaded;
    $scope.federalTaxCodesLoaded = federalTaxCodesLoaded;
    $scope.stateTaxCodesLoaded = stateTaxCodesLoaded;
    $scope.onSelectBank = onSelectBank;
    $scope.isObject = isObject;
    $scope.addFederalAmountType = addFederalAmountType;
    $scope.deleteFederalAmountType = deleteFederalAmountType;
    $scope.addStateAmountType = addStateAmountType;
    $scope.deleteStateAmountType = deleteStateAmountType;
    $scope.isFederalTaxCodeInvalid = isFederalTaxCodeInvalid;
    $scope.federalTaxCodeNotFromPredefinedList = federalTaxCodeNotFromPredefinedList;
    $scope.isStateTaxCodeInvalid = isStateTaxCodeInvalid;
    $scope.stateTaxCodeNotFromPredefinedList = stateTaxCodeNotFromPredefinedList;
    $scope.isStateInvalid = isStateInvalid;
    $scope.isReviewOrConfirmation = isReviewOrConfirmation;
    $scope.isStateTaxPayment = isStateTaxPayment;
    $scope.isFederalTaxPayment = isFederalTaxPayment;

    $scope.validateBatch = validateBatch;
    $scope.applyGlobalFrequency = applyGlobalFrequency;
    $scope.getPayFromAccountName = getPayFromAccountName;
    $scope.getStateAmountTypeDescription = getStateAmountTypeDescription;
    $scope.getFederalAmountTypeDescription = getFederalAmountTypeDescription;
    $scope.getRecipientAccountTypeDescription = getRecipientAccountTypeDescription;
    $scope.getStateLongName = getStateLongName;
    $scope.getTaxCodeDescription = getTaxCodeDescription;
    $scope.getBatchTotal = getBatchTotal;
    $scope.getGrandTotal = getGrandTotal;

    $scope.review = review;
    $scope.goBack = goBack;
    $scope.createPayment = createPayment;
    $scope.cancel = cancel;
    $scope.goToPaymentsList = goToPaymentsList;
    $scope.setBatchFrequencies = setBatchFrequencies;
    $scope.editBatch = editBatch;
    $scope.shouldShowOffsetWarnings = shouldShowOffsetWarnings;
    $scope.downloadPageId = downloadPageId.MultipleAchTaxPaymentsConfirmation;
    $scope.filterObject = {};
    $scope.stateTaxCodes = {};
    $scope.federalTaxAmountTypes = {};

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function getTaxCodeDescription(batch) {
        let taxCode;

        if (batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            if ($scope.federalTaxCodes) {
                taxCode = $scope.federalTaxCodes.find(
                    federalTaxCode => federalTaxCode.taxCode === batch.taxCode
                );
            }
        } else if (batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            if ($scope.stateTaxCodes && $scope.stateTaxCodes[batch.stateId]) {
                taxCode = $scope.stateTaxCodes[batch.stateId].find(
                    stateTaxCode => stateTaxCode.taxCode === batch.taxCode
                );
            }
        }

        if (taxCode) {
            return taxCode.description;
        }

        return '';
    }

    function statesLoaded() {
        return listHasAny($scope.states);
    }

    function getStateLongName(id) {
        if (!statesLoaded()) return '';

        const selected = $scope.states.find(item => item.id === id);

        return selected ? selected.value : '';
    }

    function isBalancedAchCompany(batch) {
        const payment = batch;

        return (
            payment &&
            payment.achCompany &&
            payment.achCompany.companyName &&
            payment.achCompany.batchBalanceRequirements === 'Balanced'
        );
    }

    function getOffsetAccountName(batch) {
        const payment = batch;

        if ($scope.isAchPrefundingEntitled && batch.achCompany.prefundingDays > 0) {
            return batch.achCompany.offsetAccountNumber;
        }

        if (!payment || !payment.offsetAccount) {
            return batch.payFromAccountName || '';
        }

        const { offsetAccount } = payment;

        return offsetAccount.showAccountNickname ? offsetAccount.nickName : offsetAccount.number;
    }

    function getBalancedAccountName(batch) {
        const payment = batch;

        if (!payment || !payment.balancedAccount) {
            return batch.payFromAccountName || '';
        }

        return payment.balancedAccount.number;
    }

    function getPayFromAccountName(batch) {
        if (isBalancedAchCompany(batch)) {
            return getBalancedAccountName(batch);
        }

        return getOffsetAccountName(batch);
    }

    function isStateTaxPayment(batch) {
        return batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment;
    }

    function isFederalTaxPayment(batch) {
        return batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment;
    }

    function isReviewOrConfirmation() {
        return $scope.isReviewing || $scope.isConfirmation;
    }

    function canFullEdit() {
        return $scope.hasFullEditEntitlement;
    }

    function stateTaxCodesLoaded(batch) {
        return (
            $scope.stateTaxCodes &&
            $scope.stateTaxCodes[batch.stateId] &&
            $scope.stateTaxCodes[batch.stateId].length
        );
    }

    function federalTaxCodesLoaded() {
        return listHasAny($scope.federalTaxCodes);
    }

    function isFederalTaxCodeInvalid(batch, form) {
        return (
            form.typeAheadFederalTaxCodeId &&
            form.typeAheadFederalTaxCodeId.$dirty &&
            !isValidTaxCode(batch.selectedFederalTaxCode)
        );
    }

    function federalTaxCodeNotFromPredefinedList(batch, form) {
        return (
            form.typeAheadFederalTaxCodeId &&
            form.typeAheadFederalTaxCodeId.$dirty &&
            !isObject(batch.selectedFederalTaxCode)
        );
    }

    function isStateTaxCodeInvalid(batch, form) {
        return (
            form.typeAheadStateTaxCodeId &&
            form.typeAheadStateTaxCodeId.$dirty &&
            !isValidTaxCode(batch.selectedStateTaxCode)
        );
    }

    function stateTaxCodeNotFromPredefinedList(batch, form) {
        return (
            form.typeAheadStateTaxCodeId &&
            form.typeAheadStateTaxCodeId.$dirty &&
            !isObject(batch.selectedStateTaxCode)
        );
    }

    function isStateInvalid(batch, form) {
        return form.typeAheadRecipientStateId.$dirty && !isObject(batch.selectedState);
    }

    function isValidTaxCode(value) {
        return taxesService.isValidTaxCode(value);
    }

    function findFederalTaxCodes(batch) {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.federalTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedFederalTaxCode => {
            batch.selectedFederalTaxCode = angular.copy(selectedFederalTaxCode);
        });
    }

    function findStateTaxCodes(batch) {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.stateTaxCodes[batch.stateId];
                },
            },
        });
        modalInstance.result.then(selectedStateTaxCode => {
            batch.selectedStateTaxCode = angular.copy(selectedStateTaxCode);
        });
    }

    function findAccountForBalancedCompany(batch, form) {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/userAccountsModalView.html'),
            size: 'md',
            controller: 'UserAccountsController',
            backdrop: 'static',
            resolve: {
                companyAccounts() {
                    return $scope.userAccounts;
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            batch.balancedAccount = angular.copy(selectedAccount);
            batch.payFromAccountId = selectedAccount.id;
            form.$setDirty();
        });
    }

    function achCompanyUndefined(batch) {
        return !batch.achCompanyName;
    }

    function federalAmountTypeChanged(index, batch) {
        if (federalAmountTypesLoaded(batch)) {
            batch.achFederalTaxPaymentAmounts[index].achFederalAmountTypeId =
                taxesService.getFederalAmountType(
                    $scope.federalTaxAmountTypes[batch.selectedFederalTaxCode.id],
                    batch.achFederalTaxPaymentAmounts[index].amountType
                ).achFederalAmountTypeId;
        }
    }

    function federalAmountTypesLoaded(batch) {
        if (!batch.selectedFederalTaxCode || !batch.selectedFederalTaxCode.id) {
            return false;
        }

        const taxCodeId = batch.selectedFederalTaxCode.id;
        return (
            $scope.federalTaxAmountTypes &&
            $scope.federalTaxAmountTypes[taxCodeId] &&
            $scope.federalTaxAmountTypes[taxCodeId].length
        );
    }

    function getStateAmountTypeDescription(achStateAmountTypeId) {
        return taxesService.getStateAmountTypeDescription(
            $scope.stateTaxAmountTypes,
            achStateAmountTypeId
        );
    }

    function getFederalAmountTypeDescription(index, batch) {
        const { amountType } = batch.achFederalTaxPaymentAmounts[index];
        const taxCodeId = batch.selectedFederalTaxCode.id;

        if (!federalAmountTypesLoaded(batch)) return '';

        return taxesService.getFederalAmountTypeDescription(
            $scope.federalTaxAmountTypes[taxCodeId],
            amountType
        );
    }

    function getRecipientAccountTypeDescription(recipientAccountTypeId) {
        return taxesService.getRecipientAccountTypeDescription(
            $scope.recipientAccountTypes,
            recipientAccountTypeId
        );
    }

    function onSelectBank(bank, batch) {
        if (bank) {
            batch.recipientRoutingNumber = bank.bankId;
        }
    }

    function addStateAmountType(batch) {
        if (batch.achStateTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        batch.achStateTaxPaymentAmounts.push({
            achStateAmountTypeId: firstStateAmountTypeId,
            amount: 0,
        });
    }

    function deleteStateAmountType(index, batch) {
        batch.achStateTaxPaymentAmounts.splice(index, 1);
    }

    function addFederalAmountType(batch) {
        if (batch.achFederalTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        const taxCodeId = batch.selectedFederalTaxCode.id;
        batch.achFederalTaxPaymentAmounts.push({
            achFederalAmountTypeId:
                $scope.federalTaxAmountTypes[taxCodeId][0].achFederalAmountTypeId,
            amountType: $scope.federalTaxAmountTypes[taxCodeId][0].amountType,
            amount: 0,
        });
    }

    function deleteFederalAmountType(index, batch) {
        batch.achFederalTaxPaymentAmounts.splice(index, 1);
    }

    function isObject(value) {
        return angular.isObject(value);
    }

    function federalTaxCodeChanged(batch) {
        if (!federalTaxCodesLoaded()) return;

        const federalTaxCode = batch.selectedFederalTaxCode;

        if (federalTaxCode && federalTaxCode.taxCode) {
            if (isSelectedTaxCode(federalTaxCode, batch)) {
                return;
            }

            batch.selectedFederalTaxCode = federalTaxCode;
            batch.taxCode = federalTaxCode.taxCode;

            getFederalTaxAmountTypes(batch);
            return;
        }

        if (taxesService.isPatternMatchForTaxCode(federalTaxCode)) {
            const selectedTaxCode = findTaxCodeObject(federalTaxCode);

            if (selectedTaxCode) {
                batch.selectedFederalTaxCode = selectedTaxCode;
                batch.taxCode = federalTaxCode.taxCode;

                getFederalTaxAmountTypes(batch);
                return;
            }

            batch.taxCode = federalTaxCode;
            batch.selectedFederalTaxCode = federalTaxCode;
        } else {
            batch.taxCode = '';
        }

        if ($scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes[federalTaxCode.id])
            $scope.federalTaxAmountTypes[federalTaxCode.id].length = 0;
    }

    function isSelectedTaxCode(federalTaxCode, batch) {
        return (
            batch.selectedFederalTaxCode &&
            batch.selectedFederalTaxCode.taxCode === federalTaxCode.taxCode &&
            batch.taxCode === federalTaxCode.taxCode
        );
    }

    function stateTaxCodeChanged(batch) {
        if (!batch) return;

        const stateTaxCode = batch.selectedStateTaxCode || batch.taxCode;

        if (stateTaxCode && stateTaxCode.taxCode) {
            batch.selectedStateTaxCode = stateTaxCode;
            batch.taxCode = stateTaxCode.taxCode;
            return;
        }

        if (taxesService.isPatternMatchForTaxCode(stateTaxCode)) {
            batch.taxCode = stateTaxCode;
            batch.selectedStateTaxCode = stateTaxCode;
        } else {
            batch.taxCode = '';
            batch.selectedStateTaxCode = '';
        }
    }

    function getStateTaxCodes(batch) {
        if (!batch) return;

        const { stateId } = batch;

        if ($scope.stateTaxCodes[stateId] && $scope.stateTaxCodes[stateId].length) return;

        return taxesService.getStateTaxCodes(stateId).then(response => {
            $scope.stateTaxCodes[stateId] = response;

            const taxCode = $scope.stateTaxCodes[stateId].find(
                item => item.taxCode === batch.taxCode
            );

            if (taxCode) {
                batch.selectedStateTaxCode = taxCode;
                stateTaxCodeChanged(batch);
            }
        });
    }

    function getFederalTaxCodes() {
        taxesService.getFederalTaxCodes().then(response => {
            $scope.federalTaxCodes = response;
        });
    }

    function getFederalTaxAmountTypes(batch) {
        if (!batch.selectedFederalTaxCode) return;

        const { selectedFederalTaxCode } = batch;

        if (
            $scope.federalTaxAmountTypes[selectedFederalTaxCode.id] &&
            $scope.federalTaxAmountTypes[selectedFederalTaxCode.id].length
        ) {
            initFederalTaxPaymentAmounts(batch);
            return;
        }

        taxesService.getFederalTaxAmountTypes(selectedFederalTaxCode.id).then(response => {
            $scope.federalTaxAmountTypes[selectedFederalTaxCode.id] = response;
            initFederalTaxPaymentAmounts(batch);
        });
    }

    function initFederalTaxPaymentAmounts(batch) {
        const { selectedFederalTaxCode } = batch;
        const taxCodeId = batch.selectedFederalTaxCode.id;
        if (
            $scope.federalTaxAmountTypes &&
            $scope.federalTaxAmountTypes[taxCodeId] &&
            $scope.federalTaxAmountTypes[taxCodeId].length
        )
            batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId:
                        $scope.federalTaxAmountTypes[taxCodeId][0].achFederalAmountTypeId,
                    amountType: $scope.federalTaxAmountTypes[taxCodeId][0].amountType,
                    amount: 0,
                },
            ];
        else
            batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId: null,
                    amountType: selectedFederalTaxCode.taxCode,
                    amount: 0,
                },
            ];
    }

    function loadUserAccounts() {
        usersService.getAccountsAccessibleToUser().then(response => {
            const accounts = response || [];
            $scope.userAccounts = accounts.filter(
                item =>
                    item.type === taxPaymentAccountTypes.Savings ||
                    item.type === taxPaymentAccountTypes.Checking
            );
        });
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    function getStateTaxAmountTypes() {
        taxesService.getStateTaxAmountTypes().then(response => {
            $scope.stateTaxAmountTypes = response;
        });
    }

    function getRecipientAccountTypes() {
        taxesService.getRecipientAccountTypes().then(response => {
            $scope.recipientAccountTypes = response;
        });
    }

    function formatDate(date) {
        return new FiDate(new Date(date)).toString();
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('SameDayAch').then(response => {
            $scope.processingCutoff = response;
            const cutOffDiff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            const timeout = response.processingCutoff.allowSameDay === false ? 0 : cutOffDiff;
            $scope.cutoffPassed = cutOffDiff < 0;
            $scope.disallowSameDayAch = response.processingCutoff.allowSameDay === false;
            loadHolidays().then(() => {
                checkTimeout(timeout);
            });
        });
    }

    function checkTimeout(timeout) {
        $timeout(() => {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disallowSameDayAch)
                .then(response => {
                    for (let i = 0; i < $scope.payment.achBatchSummaries.length; i++) {
                        if (
                            moment(
                                $scope.payment.achBatchSummaries[i].frequency.effectiveDate
                            ).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            $scope.payment.achBatchSummaries[i].frequency.effectiveDate =
                                formatDate(response);
                        }
                    }
                    if (
                        moment($scope.payment.frequency.effectiveDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        $scope.payment.frequency.effectiveDate = formatDate(response);
                    }
                });

            if (timeout > 0) {
                toaster.alert('Cutoff Time Passed', 'Cannot create ACH for today.');
            }
        }, timeout);
    }

    function editBatch(batch) {
        $scope.setUploadedBatch(null);
        $state.go('payables.ach.batch-tax-detail', {
            id: batch.id,
            type: 'edit',
            payment: true,
        });
    }

    function loadHolidays(timeout) {
        return holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            checkTimeout(timeout);

            utilityService
                .getAchNextEffectiveDate(
                    timeZones[$scope.processingCutoff.fiTimeZone],
                    $scope.holidayDates,
                    $scope.disallowSameDayAch,
                    $scope.cutoffPassed
                )
                .then(nextAchEffectiveDate => {
                    $scope.payment.frequency.effectiveDate = formatDate(nextAchEffectiveDate);
                    $scope.nextAchBusinessDate = formatDate(nextAchEffectiveDate);
                });
        });
    }

    function setBatchFrequencies() {
        if ($scope.globalFrequency.show) {
            angular.forEach($scope.payment.achBatchSummaries, batch => {
                batch.frequency = angular.extend({}, $scope.payment.frequency);
            });
            validateAllBatches();
        }
    }

    function review() {
        $scope.isReviewing = true;
        $scope.isConfirmation = false;
        $timeout(() => {
            scrollTo('overflowed-panel');
        }, 250);
        $scope.wizardStep = 3;
    }

    function scrollTo(id) {
        $location.hash(id);
        $anchorScroll();
    }

    function goBack() {
        if ($scope.isReviewing) {
            $scope.isReviewing = false;
        }
        $scope.wizardStep = 2;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    $modalInstance.close();
                    goToTemplatesList();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            goToTemplatesList();
        }
    }

    function goToPaymentsList() {
        $state.go('payables.ach.payment-list');
    }

    function goToTemplatesList() {
        $state.go(taxTemplateListState);
    }

    function createPayment() {
        let hasErrors = false;

        securityService
            .verifyUser('Initiate Payment From Batch', $scope.payment, () =>
                achPaymentsService.createTaxPaymentsFromTemplates($scope.payment)
            )
            .then(response => {
                $scope.isReviewing = false;
                $scope.isConfirmation = true;
                $scope.filterObject.AchPaymentIds = [];
                $timeout(() => {
                    $('#overflowed-panel')[0].scrollTop = 0;
                }, 250);
                response.payment.achBatchSummaries.forEach(payment => {
                    $scope.filterObject.AchPaymentIds.push(payment.id);

                    payment.errors = [];
                    if (payment.errorSummary) {
                        hasErrors = true;
                        payment.errors = payment.errorSummary.summaryMessageList;
                    }

                    payment.isValid = payment.errors.length === 0;
                });
                $scope.numberOfApprovalsNeeded =
                    response.payment.achBatchSummaries[0].numberOfApprovalsNeeded;

                $scope.payment = response.payment;
                $scope.payment.achBatchSummaries.forEach(payment => {
                    setSelectedObjects(payment);
                });

                $scope.wizardStep = 4;

                if (!hasErrors) {
                    toaster.save('ACH Tax Payments');
                }
            });
    }

    function validateFrequency(frequency) {
        const { type } = frequency;

        const errors = [];

        if (type === 'One Time') {
            if (
                !frequency.effectiveDate ||
                frequency.effectiveDate === '' ||
                frequency.effectiveDate === '0001-01-01T00:00:00'
            ) {
                errors.push('Please enter a valid Effective Date.');
            }
        }

        return errors;
    }

    function validateAllBatches() {
        // if the global frequencies are used, no need to validate these over and over.
        // just validate it once and push the results to each batch.
        let globalFrequencyErrors = [];
        if ($scope.globalFrequency.show) {
            globalFrequencyErrors = validateFrequency($scope.payment.frequency);
        }

        $scope.payment.achBatchSummaries.forEach(batch => {
            let errors = [];
            if ($scope.globalFrequency.show) {
                errors = errors.concat(globalFrequencyErrors);
            } else {
                errors = errors.concat(validateFrequency(batch.frequency));
            }

            batch.errors = errors;
            batch.isValid = batch.errors.length === 0;
        });
    }

    function validateBatch(batch) {
        const errors = validateFrequency(batch.frequency);
        batch.errors = errors;
        batch.isValid = batch.errors.length === 0;
    }

    function getTotalAmount(taxPaymentAmounts) {
        if (!listHasAny(taxPaymentAmounts)) return 0;

        const amountsList = taxPaymentAmounts.map(item => item.amount);

        return amountsList.reduce((total, amount) => total + amount);
    }

    function getBatchTotal(batch) {
        if (batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            return getTotalAmount(batch.achFederalTaxPaymentAmounts);
        }
        if (batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            return getTotalAmount(batch.achStateTaxPaymentAmounts);
        }
        return 0;
    }

    function getGrandTotal() {
        if (!listHasAny($scope.payment.achBatchSummaries)) return 0;

        const amountsList = $scope.payment.achBatchSummaries.map(batch => getBatchTotal(batch));

        return amountsList.reduce((total, amount) => total + amount);
    }

    function applyGlobalFrequency() {
        if ($scope.payment.frequency) {
            setBatchFrequencies();

            $timeout(() => {
                $scope.$apply();
            });
        }
    }

    function setState(batch) {
        whenDefined('states', () => {
            batch.selectedState = $scope.states.find(state => batch.stateId === state.id);

            if (batch.selectedState) {
                getStateTaxCodes(batch);
            }
        });
    }

    function setStateAmounts(batch) {
        whenDefined('stateTaxAmountTypes', () => {
            if (!(batch.achStateTaxPaymentAmounts && batch.achStateTaxPaymentAmounts.length)) {
                batch.achStateTaxPaymentAmounts = [];
                batch.achStateTaxPaymentAmounts.push({
                    achStateAmountTypeId: firstStateAmountTypeId,
                    amount: 0,
                });
            }
        });
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function setFederalTaxCode(batch) {
        whenDefined('federalTaxCodes', () => {
            if (!batch.taxCode) {
                return;
            }

            const taxCode = $scope.federalTaxCodes.find(item => item.taxCode === batch.taxCode);

            if (taxCode) {
                batch.selectedFederalTaxCode = taxCode;

                if (!$scope.federalTaxAmountTypes) {
                    $scope.federalTaxAmountTypes = {};
                }

                if (
                    $scope.federalTaxAmountTypes[taxCode.id] &&
                    $scope.federalTaxAmountTypes[taxCode.id].length
                ) {
                    setFederalTaxPaymentAmounts(batch);
                    return;
                }

                taxesService.getFederalTaxAmountTypes(taxCode.id).then(response => {
                    $scope.federalTaxAmountTypes[taxCode.id] = response;
                    setFederalTaxPaymentAmounts(batch);
                });
            }
        });
    }

    function setFederalTaxPaymentAmounts(batch) {
        if (!listHasAny(batch.achFederalTaxPaymentAmounts)) {
            initFederalTaxPaymentAmounts(batch);
        }
    }

    function listHasAny(list) {
        return list && list.length;
    }

    function setSelectedObjects(batch) {
        if (batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            setFederalTaxCode(batch);
        } else if (batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            setState(batch);
            setStateAmounts(batch);
        }
    }

    async function populateAchCompanies(batchSummaries) {
        const achCompanies = await achCompaniesService.getAll();

        angular.forEach(batchSummaries, summary => {
            const achCompany = achCompanies.find(company => company.id === summary.achCompany.id);
            summary.achCompany.prefundingDays = achCompany.prefundingDays;
            summary.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
        });
    }

    async function getOffsetAccounts(batchSummaries) {
        await populateAchCompanies(batchSummaries);

        const offsetBatches = batchSummaries.filter(batch => hasOffsetCompany(batch));

        const distinctCompanyIds = getDistinctOffsetCompanyIds(offsetBatches);
        distinctCompanyIds.forEach(achCompanyId => {
            achCompaniesService.getOffsetAccounts(achCompanyId).then(response => {
                const filteredBatches = offsetBatches.filter(
                    batch => batch.achCompany.id === achCompanyId
                );

                filteredBatches.forEach(batch => {
                    batch.offsetAccounts = response;
                });
            });
        });
    }

    function hasOffsetCompany(batch) {
        return (
            batch &&
            batch.achCompany &&
            batch.achCompany.id &&
            batch.achCompany.batchBalanceRequirements !== 'Balanced'
        );
    }

    function getDistinctOffsetCompanyIds(batchSummaries) {
        const offsetCompanyIds = batchSummaries.map(batch => batch.achCompany.id);

        const distinctCompanyIds = [];

        offsetCompanyIds.forEach(offsetCompanyId => {
            if (!distinctCompanyIds.find(id => id === offsetCompanyId)) {
                distinctCompanyIds.push(offsetCompanyId);
            }
        });

        return distinctCompanyIds;
    }

    function shouldShowOffsetWarnings(batch) {
        if (isBalancedAchCompany(batch)) return false;
        return $scope.isAchPrefundingEntitled && batch.achCompany?.prefundingDays <= 0;
    }

    (function () {
        // init
        $scope.payment.frequency = {
            effectiveDate: new FiDate(new Date()).toString(),
            type: 'One Time',
        };

        loadCutoffTimes();
        loadUserAccounts();
        getStates();
        getStateTaxAmountTypes();
        getRecipientAccountTypes();
        getFederalTaxCodes();

        $scope.totals.credit.amount = $scope.payment.totalCreditAmount;
        $scope.totals.credit.transactions = $scope.payment.totalCreditTransactions;

        $scope.payment.achBatchSummaries.forEach(batch => {
            setSelectedObjects(batch);

            // set frequency type if not already set
            if (!batch.frequency) {
                batch.frequency = {};
                batch.frequency.type = $scope.payment.frequency.type;
                batch.frequency.effectiveDate = $scope.payment.frequency.effectiveDate;
                batch.frequency.startOn = null;
                batch.frequency.endOn = null;
            }
        });

        getOffsetAccounts($scope.payment.achBatchSummaries);

        validateAllBatches();
    })();
}
