import { InjectionToken } from '@jack-henry/frontend-utils/di';
import { JhRequestDefaultBody } from '@jack-henry/frontend-utils/http';
import { HttpResponse } from '@treasury/utils';
import { SecurityMessage, WithSecurityMessage } from '../../channel/mappings/security';

export type IdentityDialogOtpRequest = (securityMessage: SecurityMessage) => Promise<Response>;

export interface IdentityDialogOptions {
    forLogin: boolean;
    withoutUserInteraction: boolean;
    otpRequestFn: IdentityDialogOtpRequest;
    disableClose: boolean;
}

export interface IdentityDialogResult<T = object> {
    reason: 'confirm' | 'cancel';
    message: SecurityMessage | WithSecurityMessage<T>;
    response?: Response;
}

export interface AuthStatus {
    isLocked: boolean;
    needsAuthentication: boolean;
    successful: boolean;
}

export type SecurityResponse<T extends object = JhRequestDefaultBody> = T & {
    securityMessage?: SecurityMessage;
};

export type AuthSecurityResponse<T extends object = JhRequestDefaultBody> =
    WithSecurityMessage<T> & {
        _auth: AuthStatus;
    };

export type AuthFetchResponse<T extends object> = Promise<
    T | AuthSecurityResponse<T> | HttpResponse
>;

export interface IdentityDialogFn<T = object> {
    (
        message: SecurityMessage,
        options?: Partial<IdentityDialogOptions>
    ): Promise<IdentityDialogResult<T>>;
}

/**
 * Token to be used to inject a function that opens an identity dialog.
 *
 * Does not itself provide the implementation.
 */
export const OpenIdentityDialogToken = new InjectionToken('OpenIdentityDialog');

/**
 * Sentinel value used for breaking out of the MFA request cycle.
 */
export const EXIT_FETCH = Symbol('EXIT_FETCH');
