import convertStringToPascalCase from './convert-string-to-pascal-case.js';

/**
 * @typedef { Object } ParsedCol
 * @property { boolean } Descending
 * @property { string} Name
 */

/**
 * We can choose to keep the exported functions instead, went with the class for utility consistency but that may be unnecessary
 */
export class PagingFormatters {
    /**
     * @returns { Array<ParsedCol>}
     */
    static parseSortColumns(sortCols) {
        return (sortCols || []).map(col => ({
            Descending: col.sort === 'descending',
            Name: convertStringToPascalCase(col.field),
        }));
    }

    /**
     * @returns { ParsedCol}
     */
    static parseLatestSortColumn(sortCols) {
        return PagingFormatters.parseSortColumns(sortCols).pop();
    }

    static getPageNumber(start, count) {
        return Math.ceil((start + 1) / count) || 1;
    }
}

export default PagingFormatters;
