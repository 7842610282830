/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { FilterBaseModelDto, SortRequestDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class InformationReportsClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsGetAll
   * @summary Returns the standard reports.
   * @request GET:/InformationReports/{type}
   */
  informationReportsGetAll = (type: string, params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto[], any>({
      path: `/InformationReports/${type}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsGetAll2
   * @summary Returns all reports.
   * @request GET:/InformationReports
   */
  informationReportsGetAll2 = (params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto[], any>({
      path: `/InformationReports`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsPost
   * @summary Add the passed Report filter.
   * @request POST:/InformationReports
   */
  informationReportsPost = (filter: FilterBaseModelDto | null, params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto, any>({
      path: `/InformationReports`,
      method: "POST",
      body: filter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsGet
   * @summary Returns the filter detail for the specified report filter id.
   * @request GET:/InformationReports/{id}
   */
  informationReportsGet = (id: number, params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto, any>({
      path: `/InformationReports/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsPut
   * @summary Updates the passed Filter model for the specified Id.
   * @request PUT:/InformationReports/{id}
   */
  informationReportsPut = (id: number, filter: FilterBaseModelDto | null, params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto, any>({
      path: `/InformationReports/${id}`,
      method: "PUT",
      body: filter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsDelete
   * @summary Deletes the filter for the specified Id.
   * @request DELETE:/InformationReports/{id}
   */
  informationReportsDelete = (id: number, params: RequestParams = {}) =>
    this.http.request<File | null, any>({
      path: `/InformationReports/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsSequence
   * @summary Updates the sequence for the specified Filter Id, and re-sequences the My reports.
   * @request POST:/InformationReports/Sequence
   */
  informationReportsSequence = (reportIds: SortRequestDto | null, params: RequestParams = {}) =>
    this.http.request<FilterBaseModelDto[], any>({
      path: `/InformationReports/Sequence`,
      method: "POST",
      body: reportIds,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InformationReports
   * @name InformationReportsFavorite
   * @summary Set a report as a favorite.
   * @request GET:/InformationReports/{id}/favorite
   */
  informationReportsFavorite = (
    id: number,
    query: {
      /**
       * Indicate favorite true/false.
       *
       */
      value: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FilterBaseModelDto, any>({
      path: `/InformationReports/${id}/favorite`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
