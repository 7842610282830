import { DiContainer } from '@jack-henry/frontend-utils/di';
import { exists } from '@jack-henry/frontend-utils/functions';
import { JhHttpClientCached } from '@jack-henry/frontend-utils/http';
import { FetchFn } from '@treasury/utils';
import { ConfigurationService } from '../config';
import { AUTH_TOKEN, getAuthToken, updateAuthToken } from './request-validation.js';

/**
 * HTTP Client that wraps a `fetch()` implementation
 * to include Treasury Management-specific headers and behavior.
 */
export class TmHttpClient extends JhHttpClientCached {
    constructor(fetchFn: FetchFn, configService: ConfigurationService) {
        super(fetchFn, {
            baseUrl: configService.apiRoot,
            authHeaderName: AUTH_TOKEN,
            getAuthToken,
            setAuthToken: updateAuthToken,
            createRequestHeaders: () => createHeaders(exists(this.authToken)),
        });
    }

    /**
     * Convenience method for locations that don't have access to DI.
     */
    public static async getInstance() {
        return (await DiContainer.getInstance()).get(TmHttpClient);
    }
}

/**
 * Create the set of headers that should be included on every request to a TM API.
 */
function createHeaders(hasToken: boolean) {
    const autoHeaders: HeadersInit = {
        'x-tm-client-web': 'true',
    };

    if (hasToken) {
        autoHeaders['Jha-Treasury-ClientInfo'] = 'Web';
    }

    return autoHeaders;
}
