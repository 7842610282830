import { clone } from '@jack-henry/frontend-utils/functions';
import { WireFileModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';
import { DomainEntity } from '../../../shared';
import { nullWireFileActivity } from '../../types/wires';

/**
 * Represents the client-side contract for Wires templates.
 */
export class WireFileActivity extends DomainEntity<Nullable<WireFileModelDto>> {
    constructor(protected dto = clone(nullWireFileActivity)) {
        super(dto);
    }

    public get fileName() {
        return this.dto.fileName || '';
    }

    public set fileName(fileName) {
        this.dto.fileName = fileName;
    }

    public get fileSize() {
        return this.dto.fileSize || 0;
    }

    public set fileSize(fileSize) {
        this.dto.fileSize = fileSize;
    }

    public get fileFormat() {
        return this.dto.fileFormat || '';
    }

    public set fileFormat(fileFormat) {
        this.dto.fileFormat = fileFormat;
    }

    public get isConfirmed() {
        return Boolean(this.dto.isConfirmed);
    }

    public set isConfirmed(isConfirmed) {
        this.dto.isConfirmed = isConfirmed;
    }

    public get isExpired() {
        return Boolean(this.dto.isExpired);
    }

    public set isExpired(isExpired) {
        this.dto.isExpired = isExpired;
    }

    public get isValid() {
        return Boolean(this.dto.isValid);
    }

    public set isValid(isValid) {
        this.dto.isValid = isValid;
    }

    public get uploadedBy() {
        return this.dto.uploadedBy || '';
    }

    public set uploadedBy(uploadedBy) {
        this.dto.uploadedBy = uploadedBy;
    }

    public get uploadDate() {
        return this.dto.uploadDate || '';
    }

    public set uploadDate(uploadDate) {
        this.dto.uploadDate = uploadDate;
    }

    public get processedBy() {
        return this.dto.processedBy || '';
    }

    public set processedBy(processedBy) {
        this.dto.processedBy = processedBy;
    }

    public get processedDate() {
        return this.dto.processedDate || '';
    }

    public set processedDate(processedDate) {
        this.dto.processedDate = processedDate;
    }

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(amount) {
        this.dto.amount = amount;
    }

    public get totalWireCount() {
        return this.dto.totalWireCount || 0;
    }

    public set totalWireCount(totalWireCount) {
        this.dto.totalWireCount = totalWireCount;
    }

    public get approvedCount() {
        return this.dto.approvedCount || 0;
    }

    public set approvedCount(approvedCount) {
        this.dto.approvedCount = approvedCount;
    }

    public get pendingCount() {
        return this.dto.pendingCount || 0;
    }

    public set pendingCount(pendingCount) {
        this.dto.pendingCount = pendingCount;
    }

    public get rejectedCount() {
        return this.dto.rejectedCount || 0;
    }

    public set rejectedCount(rejectedCount) {
        this.dto.rejectedCount = rejectedCount;
    }

    public get expiredCount() {
        return this.dto.expiredCount || 0;
    }

    public set expiredCount(expiredCount) {
        this.dto.expiredCount = expiredCount;
    }

    public get wireFileId() {
        return this.dto.wireFileId || 0;
    }

    public set wireFileId(wireFileId) {
        this.dto.wireFileId = wireFileId;
    }

    public get wireFileUniqueId() {
        return this.dto.wireFileUniqueId;
    }

    public set wireFileUniqueId(wireFileUniqueId) {
        this.dto.wireFileUniqueId = wireFileUniqueId;
    }

    public createDefault() {
        return new WireFileActivity() as this;
    }
}

export function mapToWireFileActivity(dto: WireFileModelDto): WireFileActivity {
    return new WireFileActivity(dto);
}
