/* eslint-disable import/extensions */
import { JhHttpResponseType } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';

/**
 * Calls associated with the ach child support payments:
 *  * create child support payment
 *  * create child support payment template
 *  * update child support payment template
 *  * delete child support payment template
 *  * get child support payment templates
 */
class AchChildSupportRequests {
    static async uploadChildSupportFile(formData) {
        return (await TmHttpClient.getInstance()).request(`achPayments/uploadChildSupport`, {
            method: 'POST',
            body: formData,
            disableContentType: true,
        });
    }

    static async getChildSupportPayment(paymentId) {
        return (await TmHttpClient.getInstance()).request(
            `AchPayments/GetChildSupportPayment?paymentId=${paymentId}`,
            {
                method: 'GET',
            }
        );
    }

    static async createPayment(body) {
        return (await TmHttpClient.getInstance()).request(`achPayments/childSupportPayment`, {
            method: 'POST',
            body,
        });
    }

    static async editPayment(body) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/ChildSupportPayment/${body.id}`,
            {
                method: 'PUT',
                body,
            }
        );
    }

    static async fetchPaymentTemplates(body) {
        return (await TmHttpClient.getInstance()).request(`ach/templates/childsupport/search`, {
            method: 'POST',
            body,
        });
    }

    static async fetchPaymentTemplate(guid) {
        return (await TmHttpClient.getInstance()).request(`ach/template/childsupport/${guid}`, {
            method: 'GET',
        });
    }

    static async createTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`ach/template/childsupport`, {
            method: 'POST',
            body: { ...body },
        });
    }

    static async updateTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`ach/template/childsupport`, {
            method: 'PUT',
            body,
        });
    }

    static async deleteTemplate(guid) {
        return (await TmHttpClient.getInstance()).request(`ach/template/childsupport/${guid}`, {
            method: 'DELETE',
        });
    }

    static async downloadChildSupportTemplate(guid, outputType) {
        return (await TmHttpClient.getInstance()).request(`ach/template/childsupport/download`, {
            method: 'POST',
            responseType: JhHttpResponseType.ArrayBuffer,
            body: {
                filterModel: guid,
                outputType,
            },
        });
    }

    static async downloadChildSupportTemplates(filterModel, outputType) {
        return (await TmHttpClient.getInstance()).request(
            `ach/templates/childsupport/search/download`,
            {
                method: 'POST',
                responseType: JhHttpResponseType.ArrayBuffer,
                body: {
                    filterModel,
                    outputType,
                },
            }
        );
    }
}

export default AchChildSupportRequests;
