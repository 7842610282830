/* eslint-disable no-restricted-syntax */
import { WireCompanyModelDto, WireFileModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils/types';

export type WireFileActivityEntryType = 'Domestic' | 'International';

export type WireFileActivityStatus = 'Pending Approval' | 'Approval Rejected' | 'Ready';

export const nullWireFileActivity: Nullable<WireFileModelDto> = {
    id: 0,
    updatedDate: null,
    fileName: null,
    fileSize: 0,
    fileFormat: null,
    isConfirmed: false,
    isDuplicated: false,
    isExpired: false,
    isValid: true,
    uploadedBy: null,
    uploadDate: null,
    processedBy: null,
    processedDate: null,
    amount: 0,
    totalWireCount: 0,
    approvedCount: 0,
    pendingCount: 0,
    rejectedCount: 0,
    expiredCount: 0,
    wireFileId: 0,
    wireFileUniqueId: null,
    batchCount: 0,
};

interface WireFileActivityQueryDtoBase {
    fileName: string;
    isDownloadReport: boolean;
    uploadDateStart: string;
    uploadDateEnd: string;
    uploadDateType: string;
    wireCompanies: WireCompanyModelDto[];
    uploadedByIds: number[];
    wireAmount: number;
    wireAmountMax: number;
    wireAmountMin: number;
    wireAmountType: string;
}

/**
 * Search parameters the API expects when querying for file activity.
 */
export type WireFileActivityQueryDto = Nullable<WireFileActivityQueryDtoBase>;
