import { getKeys } from '.';

export function getSearchParams() {
    const search = window.location.search || '';
    const queryString = search.split('?')[1] || '';
    return new URLSearchParams(queryString);
}

export function buildQuery(params: Record<string, unknown>) {
    return getKeys(params)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k] + '')}`)
        .join('&');
}
