import { WireIsoService } from '@treasury/domain/wires';
import { ListeningElementMixin } from '@treasury/omega/components';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import {
    mapFileErrorsToRecordSet,
    mapWiresToRecordSet,
    mapWireToRecord,
} from '../../helpers/recordsetMapping.js';
import '../../tables/error-table.js';
import '../../tables/tableHeaders/wire-file-summary-header.js';
import '../../tables/wire-table.js';
import { workflowStyles } from '../workflow.styles.js';

export class WireUploadSummary extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            wireFile: Object,
            wireConfiguration: Object,
            accountConfiguration: Object,
            holidays: Array,
            records: Object,
            fileErrors: Object,
            wireIsoService: Object,
            wireIsoLabels: Object,
        };
    }

    constructor() {
        super();
        this.wireIsoService = new WireIsoService();
        this.wireIsoLabels = WireIsoService.legacyLabels;
    }

    updated(changeProps) {
        if (changeProps.has('wireFile')) this.getErrors();
    }

    async firstUpdated() {
        if (this.wireFile) this.getErrors();
        this.wireIsoLabels = await this.wireIsoService.getLabels();
    }

    back() {
        this.dispatchEvent(new CustomEvent('back'));
    }

    review() {
        const wireTable = this.shadowRoot.querySelector('wire-table');
        this.dispatchEvent(
            new CustomEvent('review', {
                detail: { value: wireTable.records },
            })
        );
    }

    async getErrors() {
        if (this.wireFile && this.wireFile.fileErrors) {
            if (this.wireFile.fileErrors.length && this.wireFile.fileErrors[0].lineNumber < 0) {
                const message = this.wireFile.fileErrors[0].errorText;
                this.dispatchEvent(
                    new CustomEvent('processFileError', {
                        detail: { value: message },
                        bubbles: true,
                        composed: true,
                    })
                );
                this.fileErrors = null;
            } else {
                this.fileErrors = mapFileErrorsToRecordSet(this.wireFile.fileErrors);
                await this.fileErrors.requestUpdate();
            }
        } else {
            this.fileErrors = null;
        }
    }

    getRecords() {
        if (this.records) {
            this.records.setData(this.wireFile.wires.map(mapWireToRecord));
        } else {
            this.records = mapWiresToRecordSet(
                this.wireFile.wires,
                this.wireConfiguration,
                this.holidays,
                this.wireIsoLabels
            );
            this.listenTo(this.records, 'updated', () => {
                this.dispatchEvent(
                    new CustomEvent('updateRecords', {
                        bubbles: true,
                        composed: true,
                        detail: { records: this.records },
                    })
                );
            });
        }
        this.dispatchEvent(
            new CustomEvent('updateRecords', {
                bubbles: true,
                composed: true,
                detail: { records: this.records },
            })
        );
        return this.records;
    }

    renderReview() {
        if (this.wireFile && !this.wireFile.fileErrors) {
            return html`<omega-button
                ?loading=${!this.records}
                type="primary"
                @click=${this.review}
            >
                Review
            </omega-button>`;
        }
        return nothing;
    }

    renderProcessAlert(message) {
        // TODO: Dispatch an event that causes the alert dialog on the parent to render the message:
        this.dispatchEvent(new CustomEvent('someEvent', { detail: { value: message } }));
        return html`<omega-button-bar alignment="left" position="bottom"
            ><omega-button @click=${this.back}>Back</omega-button></omega-button-bar
        > `;
    }

    render() {
        if (!this.wireFile) return html`<em>No WireFile Found</em>`;
        if (this.wireFile?.fileErrors && this.fileErrors)
            return html`<error-table .errors=${this.fileErrors}></error-table>

                <omega-button-bar alignment="left" position="bottom"
                    ><omega-button @click=${this.back}>Back</omega-button></omega-button-bar
                > `;
        if (this.wireFile?.wires)
            return html`<slot></slot
                ><wire-table
                    .records=${this.getRecords()}
                    .accountConfiguration=${this.accountConfiguration}
                ></wire-table>

                <omega-button-bar alignment="left" position="bottom">
                    <omega-button @click=${this.back}>Back</omega-button>
                    ${this.renderReview()}
                </omega-button-bar> `;
        return html`
            <omega-button-bar alignment="left" position="bottom"
                ><omega-button @click=${this.back}>Back</omega-button></omega-button-bar
            >
        `;
    }

    static get styles() {
        return [
            workflowStyles,
            css`
                :host {
                    display: block;
                    height: 100%;
                    position: relative;
                }
            `,
        ];
    }
}

window.customElements.define('wire-upload-summary', WireUploadSummary);
export default WireUploadSummary;
