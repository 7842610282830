/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { createUniqueId } from '@jack-henry/frontend-utils/functions';
import {
    InternationalAchCountryDto,
    InternationalAchPaymentRecipientDto,
    SecurityMessageModelDto,
} from '@treasury/api/channel';
import { FiDate } from '../../../dates';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';
import {
    InternationalAchNachaResponse,
    InternationalAchTemplateCreateBody,
    InternationalAchTemplateFiltersDto,
    InternationalAchTemplateGetRequest,
    InternationalAchTemplateHeader,
    InternationalAchTemplateOptions,
    InternationalAchTemplateRecipientAddenda,
    InternationalAchTemplateRecipients,
    InternationalAchTemplateRequestRecipients,
} from '../../types/ach/international';

const throwError = (field: string) => {
    throw new Error(`Error processing field ${field}`);
};

export const bankIdTypeOptions = [
    { id: '01', text: '01 National Clearing', value: '01 National Clearing' },
    { id: '02', text: '02 BIC Code', value: '02 BIC Code' },
    { id: '03', text: '03 IBAN', value: '03 IBAN' },
];

export const internationalTransactionTypes = () => [
    { code: 'ANN', name: 'Annuity' },
    { code: 'BUS', name: 'Business/Commercial' },
    { code: 'DEP', name: 'Deposit' },
    { code: 'LOA', name: 'Loan' },
    { code: 'MIS', name: 'Miscellaneous' },
    { code: 'MOR', name: 'Mortgage' },
    { code: 'PEN', name: 'Pension' },
    { code: 'REM', name: 'Remittance' },
    { code: 'RLS', name: 'Rent/Lease' },
    { code: 'SAL', name: 'Salary/Payroll' },
    { code: 'TAX', name: 'Tax' },
];

export const mapDestinationCountry = (
    destinationCountry: { receivingBankCountry: any; countryCode: any; countryName: any },
    europeanCountries: any
) => {
    if (typeof destinationCountry !== 'string') {
        const { receivingBankCountry, countryCode, countryName } = destinationCountry;
        const receivingCountry = receivingBankCountry.toLowerCase().trim();
        if (receivingCountry === 'europe') {
            return europeanCountries;
        }
        return [{ countryName, countryCode }];
    }
    // Note: don't know the instance where we only get the country as a string back...
    return [destinationCountry];
};

/**
 *
 * @param {object} frequency
 * @param {boolean} isOneTimePayment
 * @returns Summary string
 */
function buildFrequencySummary({ startOn, noEndDate, endOn }: any, isOneTimePayment: boolean) {
    if (isOneTimePayment) {
        return 'One Time.';
    }

    const parseDate = (date: string | number | FiDate | Date | undefined) =>
        date ? new FiDate(date) : '(no date selected)';

    const start = `Starts on ${parseDate(startOn)}`;
    const end = noEndDate ? 'with no end date' : `and ends on ${parseDate(endOn)}`;

    return `${start} ${end}`;
}

const frequencyTypes = {
    twiceamonth: 'Twice a Month',
    onetime: 'One Time',
    weekly: 'Weekly',
    everytwoweeks: 'Every Two Weeks',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    everysixmonths: 'Every Six Months',
    yearly: 'Yearly',
};

type FrequencyKeys = keyof typeof frequencyTypes;

function mapFrequencyType(frequencyType: string) {
    return frequencyTypes[frequencyType.toLowerCase() as FrequencyKeys];
}

/**
 *
 * @param {object} paymentHeaderRecord
 * @returns paymentHeaderRecord.frequency with updated type, effectiveDate, and summary values
 */
function buildFrequency({ frequency }: { frequency?: any }) {
    // Test against One Time and OneTime
    const isOneTimePayment = /One\s?Time/.test(frequency.type);
    const type = isOneTimePayment ? 'One Time' : mapFrequencyType(frequency.type);

    return {
        ...frequency,
        startOn: frequency.startOn ? new FiDate(frequency.startOn).toString() : null,
        effectiveDate: frequency.valueDate,
        summary: buildFrequencySummary(frequency, isOneTimePayment),
        type: type ?? 'One Time',
    };
}

function buildAddenda(response: any) {
    const addenda: any[] = [];
    if (response.optionalAddendaLine1 !== null) {
        addenda[0] = response.optionalAddendaLine1;
    }
    if (response.optionalAddendaLine2 !== null) {
        addenda[1] = response.optionalAddendaLine2;
    }
    return addenda;
}

const mapReceivingBankCountry = (country: { countryCode: string }) => {
    if (typeof country === 'object') {
        return country.countryCode;
    }
    return country;
};

export function buildRecipient(recipient: InternationalAchTemplateRecipients) {
    const [optionalAddendaLine1, optionalAddendaLine2] = recipient.addenda || [null, null];
    return {
        ...recipient,
        IatTransactionTypeId: 1,
        BankIdTypeId: 1,
        ReceivingBankCountryId: 1,
        receivingBankCountry: mapReceivingBankCountry(recipient.receivingBankCountry),
        routingNumber: recipient.receivingBankNumber,
        iatTransactionType: recipient.iatTransactionType.code,
        transactionType: recipient.transactionType.toLowerCase() === 'credit' ? 'CR' : 'DR',
        prenote: !!recipient.prenote,
        hold: !!recipient.hold,
        id: 0,
        reversal: false,
        isRoutingNumberValid: true,
        isRoutingNumberOnUs: false,

        destination: null,
        optionalAddendaLine1,
        optionalAddendaLine2,
    };
}

export const buildTemplateRecipientForApi = (
    recipient: InternationalAchTemplateRecipients,
    options: InternationalAchTemplateOptions,
    header: InternationalAchTemplateHeader
) => {
    const bankIdTypeId =
        options.values.bankIdTypes.find(
            type =>
                type.name === recipient.receivingBankType ||
                type.description === recipient.receivingBankType
        )?.id || throwError('bank type');
    const transactionType = recipient.transactionType.toLowerCase() === 'debit' ? 1 : 2;
    const formatAddenda = (
        line: string,
        id: string
    ): InternationalAchTemplateRecipientAddenda | undefined => {
        if (line) {
            return recipient.recipientId
                ? {
                      line,
                      recipientId: recipient.recipientId,
                      id,
                  }
                : {
                      line,
                  };
        }
        return undefined;
    };
    const addenda1 = formatAddenda(recipient.addenda[0], '1');
    const addenda2 = formatAddenda(recipient.addenda[1], '2');
    const addenda: [
        InternationalAchTemplateRecipientAddenda?,
        InternationalAchTemplateRecipientAddenda?,
    ] = [];
    if (addenda1) {
        addenda.push(addenda1);
        if (addenda2) {
            addenda.push(addenda2);
        }
    }

    return {
        accountTypeName: '',
        name: recipient.name,
        idNumber: recipient.idNumber,
        routingNumber: recipient.receivingBankNumber,
        accountNumber: recipient.accountNumber,
        accountType: recipient.accountType,
        transactionType,
        transactionTypeDescription: '',
        amount: header.setToZero ? 0 : recipient.amount,
        isPrenote: !!recipient.prenote,
        holdTransaction: !!recipient.hold,
        id: '',
        streetAddress: recipient.streetAddress,
        state: recipient.state,
        city: recipient.city,
        country: recipient.country,
        zipCode: recipient.zipCode,
        iatTransactionTypeId: recipient.iatTransactionType.id,
        bankName: recipient.receivingBankName,
        bankIdTypeId,
        receivingBankCountryId: recipient.receivingBankCountry.id,
        addenda,
    };
};

const getCountries = async (achServiceGetCountries: () => any) => {
    try {
        const countries = await achServiceGetCountries();
        return countries.errorCode ? [] : countries;
    } catch {
        return [];
    }
};

/**
 * @returns the appropriate countryCode object
 */
const parseCountryCode = async <T = unknown>(
    valueToParse: string | T,
    achServiceGetCountries: any
) => {
    return typeof valueToParse === 'string'
        ? (await getCountries(achServiceGetCountries)).find(
              (c: InternationalAchCountryDto) => c.countryCode === valueToParse
          )
        : valueToParse;
};

/**
 * @returns the appropriate iatTransactionType object
 */
const parseTransactionType = <T = unknown>(valueToParse?: string | T) => {
    if (!valueToParse) {
        return {
            code: '',
            name: '',
        };
    }

    return typeof valueToParse === 'string'
        ? internationalTransactionTypes().find(({ code }) => code === valueToParse) || {
              code: '',
              name: '',
          }
        : valueToParse;
};

export const parsePaymentResponseRecipients = async (recipients: any[]) =>
    Promise.all(
        recipients.map(async (recipient: any) => {
            let { receivingBankType, iatTransactionType } = recipient;

            if (typeof receivingBankType === 'string') {
                const bankType =
                    bankIdTypeOptions.find(({ id }) => id.includes(receivingBankType)) ||
                    throwError('bank type');

                receivingBankType = bankType?.value;
            }

            if (typeof iatTransactionType === 'string') {
                iatTransactionType = await parseTransactionType(iatTransactionType);
            }

            return {
                ...recipient,
                receivingBankType,
                iatTransactionType,
                addenda: buildAddenda(recipient),
            };
        })
    );

/**
 * @param {Object} response the response value from the service
 * @returns the payment object formatted to align with needed UI values
 */
export const parsePaymentResponse = async (
    response: { payment: any },
    achServiceGetCountries: any
) => {
    const {
        payment: { destinationCountryCode, recipients },
    } = response;

    return {
        payment: {
            ...response.payment,
            destinationCountryCode: await parseCountryCode(
                destinationCountryCode,
                achServiceGetCountries
            ),
            recipients: await parsePaymentResponseRecipients(recipients),
        },
    };
};

const parseNachaFileResponseRecipient = (recipient: InternationalAchPaymentRecipientDto) => {
    const {
        streetAddress,
        name,
        city,
        state,
        zipCode,
        country,
        idNumber,
        accountType,
        accountNumber,
        transactionType,
        iatTransactionType,
        amount,
        prenote,
        hold,
        receivingBankType,
        receivingBankName,
        receivingBankNumber,
        receivingBankCountry,
        optionalAddendaLine1,
        optionalAddendaLine2,
    } = recipient;

    return {
        streetAddress,
        name,
        city,
        state,
        zipCode,
        country,
        idNumber,
        accountType,
        accountNumber,
        transactionType,
        amount,
        iatTransactionType: parseTransactionType(iatTransactionType),
        paymentType: accountType,
        addenda: [optionalAddendaLine1, optionalAddendaLine2].filter(l => l),
        prenote,
        hold,
        receivingBankType,
        receivingBankName,
        receivingBankNumber,
        receivingBankCountry,
        nachaUpload: true,
    };
};

export const parseNachaFileResponseRecipients = (recipients: any[]) =>
    recipients.map(parseNachaFileResponseRecipient);

export const parseNachaFileResponse = (response: any) => response;

export const parseNachaPaymentHeaderResponse = (response: InternationalAchNachaResponse) => ({
    ...response,
    achCompany: {
        ...response.achCompany,
        discretionaryData: response.discretionaryData,
        entryDescription: response.entryDescription,
        secCode: response.secCode,
        achCompanyId: response.achCompanyId,
    },
    nachaUpload: true,
});

interface Payment {
    type: string;
    duplicatePreventionId: string;
    achCompanyName?: string;
    destinationCountryCode?: string;
    offsetAccount?: any;
    restricted?: boolean;
    frequency?: any;
    fileArchiveId?: string;
    recipients: any[];
}
export const mapPaymentHeaderAndRecipients = (
    paymentHeaderRecord: {
        achCompany?: any;
        destinationCountryCode?: any;
        offsetAccount?: any;
        restricted?: any;
        frequency?: any;
        fileArchiveId?: any;
    },
    recipientsRecordset: InternationalAchTemplateRecipients[]
) => {
    const Payment: Payment = {
        ...paymentHeaderRecord,
        achCompanyName: paymentHeaderRecord.achCompany.companyName,
        destinationCountryCode: paymentHeaderRecord.destinationCountryCode.countryCode
            ? paymentHeaderRecord.destinationCountryCode.countryCode
            : paymentHeaderRecord.destinationCountryCode,
        frequency: buildFrequency(paymentHeaderRecord),
        fileArchiveId:
            paymentHeaderRecord.fileArchiveId === 0 ? null : paymentHeaderRecord.fileArchiveId,
        recipients: recipientsRecordset.map(buildRecipient),
        offsetAccount: {
            id: paymentHeaderRecord.offsetAccount.id,
            key: null,
            securityMessage: null,
            updatedBy: null,
            updatedDate: '0001-01-01T00:00:00',
            value: null,
        },
        type: 'AchInternationalPayment',
        restricted: !!paymentHeaderRecord.restricted,
        duplicatePreventionId: createUniqueId(),
    };

    const securityMessage: SecurityMessageModelDto = {
        actionType: 'Initiate Payment From Batch',
        status: undefined,
        methodUsed: undefined,
        hasAlternate: false,
        errorCode: undefined,
        message: undefined,
        oneTimePassword: undefined,
        challengeMethodTypeId: undefined as unknown as number,
    };

    if (paymentHeaderRecord.achCompany.offsetAccountNumber) {
        delete Payment.offsetAccount;
    }

    return {
        Payment,
        securityMessage,
    };
};

export const mapWorkflowDataForTemplateApi = (
    paymentHeaderRecord: InternationalAchTemplateHeader,
    recipientsRecordset: InternationalAchTemplateRecipients[],
    options: InternationalAchTemplateOptions,
    action: string
): InternationalAchTemplateCreateBody => {
    const { currencies, foreignExchangeIndicators, foreignExchangeReferenceIndicators, states } =
        options.values;
    const state =
        states.find(obj => obj.code === paymentHeaderRecord.state)?.id || throwError('state');
    const foreignExchangeIndicatorId =
        foreignExchangeIndicators.find(
            obj => obj.name === paymentHeaderRecord.foreignExchangeIndicator
        )?.id || throwError('foreign exchange indicator');
    const foreignExchangeReferenceIndicatorId = paymentHeaderRecord.foreignExchangeReferenceNumber
        ? foreignExchangeReferenceIndicators.find(
              obj => obj.name === paymentHeaderRecord.foreignExchangeReferenceNumber
          )?.id || throwError('foreign exchange reference indicator')
        : 3;
    const originatorCurrencyCodeId =
        currencies.find(obj => obj.name === paymentHeaderRecord.originatorCurrencyCode)?.id ||
        throwError('origin currency code id');
    const destinationCurrencyCodeId =
        currencies.find(obj => obj.name === paymentHeaderRecord.destinationCurrencyCode)?.id ||
        throwError('destination currency code');
    const actionType =
        action === 'create'
            ? 'Create Ach International Template'
            : 'Edit Ach International Template';
    return {
        id: '',
        stateId: state,
        foreignExchangeIndicatorId,
        foreignExchangeReference: paymentHeaderRecord.foreignExchangeReference,
        foreignExchangeReferenceIndicatorId,
        originatorCurrencyCodeId,
        destinationCountryCodeId: paymentHeaderRecord.destinationCountryCode.id,
        destinationCurrencyCodeId,
        achCompanyId: paymentHeaderRecord.achCompany.id,
        achCompanyUniqueId: paymentHeaderRecord.achCompany.companyGuidUniqueId,
        companyIdentity: paymentHeaderRecord.achCompany.companyId,
        city: paymentHeaderRecord.city,
        country: paymentHeaderRecord.country,
        discretionaryData: paymentHeaderRecord.discretionaryData,
        entryDescription: paymentHeaderRecord.entryDescription,
        name: paymentHeaderRecord.templateName ?? paymentHeaderRecord.name,
        restricted: false,
        secCode: paymentHeaderRecord.secCode,
        streetAddress: paymentHeaderRecord.streetAddress,
        zipCode: paymentHeaderRecord.zipCode,
        recipients: recipientsRecordset.map(recipient =>
            buildTemplateRecipientForApi(recipient, options, paymentHeaderRecord)
        ),
        securityMessage: {
            actionType,
            status: null,
            methodUsed: null,
            hasAlternate: false,
            errorCode: null,
            message: null,
            oneTimePassword: null,
        },
    };
};

export const mapTemplateFilters = (filterValues: InternationalAchTemplateFiltersDto) => {
    const { status, achCompanyList, batchName, updatedDate, creditAmount, debitAmount } =
        filterValues.parameters;
    // delete filterValues?.parameters;
    const achCompanyIdList = Array.isArray(achCompanyList)
        ? achCompanyList.map(company => company.id)
        : achCompanyList;

    return {
        // ...filterValues,//no pagination at this time
        status,
        achCompanyIdList,
        templateName: batchName.length > 0 ? batchName : null,
        lastUpdatedDateType: DateFormatters.getDateType(updatedDate) === 'specificDate' ? 1 : 2,
        lastUpdated:
            DateFormatters.parseDate(updatedDate).value.start ??
            DateFormatters.parseDate(updatedDate).value,
        lastUpdatedEnd: DateFormatters.parseDate(updatedDate).value.end ?? null,
        creditAmountType: creditAmount[0] === 'range' ? 2 : 1,
        creditAmount:
            creditAmount[1] === 0 || !creditAmount[1] ? null : parseFloat(creditAmount[1]),
        creditAmountMax: creditAmount[2] === 0 ? null : parseFloat(creditAmount[2]),
        debitAmountType: debitAmount[0] === 'range' ? 2 : 1,
        debitAmount: debitAmount[1] === 0 || !debitAmount[1] ? null : parseFloat(debitAmount[1]),
        debitAmountMax: debitAmount[2] === 0 ? null : parseFloat(debitAmount[2]),
    };
};

const buildRecipientForTemplateWorkflow = (
    recipient: InternationalAchTemplateRequestRecipients,
    options: InternationalAchTemplateOptions
) => {
    const optionsData = options.values;
    const { countries, bankIdTypes } = optionsData;
    const bankData =
        bankIdTypes.find(type => type.id === recipient.bankIdTypeId) || throwError('bank type');
    const transactionTypeData =
        optionsData.internationalTransactionTypes.find(
            type => type.id === recipient.iatTransactionTypeId
        ) || throwError('transaction type');
    const receivingBankCountry =
        countries.find(country => country.id === recipient.receivingBankCountryId)?.abbreviation ||
        throwError('receiving bank country');
    const addenda = recipient.addenda.map(item => item?.line);
    const recipientId = recipient.addenda[0]?.recipientId;
    return {
        ...recipient,
        accountType: recipient.accountTypeName,
        receivingBankName: recipient.bankName,
        receivingBankType: bankData.description,
        receivingBankNumber: recipient.routingNumber,
        receivingBankCountry,
        iatTransactionType: {
            code: transactionTypeData.name,
            name: transactionTypeData.description,
        },
        transactionType: recipient.transactionTypeDescription,
        idNumber: recipient.idNumber,
        prenote: recipient.isPrenote,
        hold: recipient.holdTransaction,
        addenda,
        recipientId,
    };
};

export const mapTemplateRequestDataForWorkflow = (
    values: InternationalAchTemplateGetRequest,
    options: InternationalAchTemplateOptions
) => {
    const optionsData = options.values;
    const {
        countries,
        currencies,
        foreignExchangeIndicators,
        foreignExchangeReferenceIndicators,
        // internationalTransactionTypes is already declared in this file, cannot destructure,
        states,
    } = optionsData;

    const recipients = values.recipients.map(recipient =>
        buildRecipientForTemplateWorkflow(recipient, options)
    );
    const destinationCountryCode =
        countries.find(country => country.id === values.destinationCountryCodeId)?.abbreviation ||
        throwError('destination country code');
    const destinationCurrencyCode =
        currencies.find(currency => currency.id === values.destinationCurrencyCodeId)?.name ||
        throwError('destination currency code');
    const foreignExchangeReferenceNumber =
        foreignExchangeReferenceIndicators.find(
            indicator => indicator.id === values.foreignExchangeReferenceIndicatorId
        )?.description || throwError('foreign exchange reference indicator');
    const foreignExchangeIndicator =
        foreignExchangeIndicators.find(
            indicator => indicator.id === values.foreignExchangeIndicatorId
        )?.name || throwError('foreign exchange indicator');
    const originatorCurrencyCode =
        currencies.find(currency => currency.id === values.originatorCurrencyCodeId)?.name ||
        throwError('originator currency code');
    const state = states.find(listItem => listItem.id === values.stateId)?.code;

    return {
        ...values,
        recipients,
        achCompany: values.achCompanyName,
        destinationCountryCode,
        destinationCurrencyCode,
        foreignExchangeReferenceNumber,
        foreignExchangeIndicator,
        originatorCurrencyCode,
        state,
        status: values.statusDescription,
        nachaUpload: false,
    };
};
