import { createUniqueId, exists } from '@jack-henry/frontend-utils/functions';
import { CountryModelDto, FrequencyModelDto } from '@treasury/api/channel';
import { format } from 'date-fns';

export const formatDay = (day = 0) => {
    switch (day) {
        case 1:
        case 21:
        case 31:
            return `${day}st`;
        case 2:
        case 22:
            return `${day}nd`;
        case 3:
        case 23:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

export const getStartAndEnd = (frequency: FrequencyModelDto) => {
    if (frequency.endOn) {
        return `from ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')} to ${format(
            new Date(frequency.endOn),
            'MM/dd/yyyy'
        )}`;
    }
    return `starting on ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')}`;
};

export const getDaysOfMonth = (frequency: FrequencyModelDto, twoDays = false) => {
    const day1 = frequency.repeatOnDay1;
    const day2 = frequency.repeatOnDay2;

    if (twoDays) {
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    if (frequency.repeatOnLastBusinessDay) {
        return 'the last business day';
    }
    return `the ${formatDay(day1)}`;
};

export const getDayForAnnual = (date: string) => {
    const tempDate = new Date(date);
    return format(tempDate, 'MM/dd');
};

export const getDayForADate = (date: string) => formatDay(Number(format(new Date(date), 'd')));

interface Location {
    streetName?: string;
    buildingNumber?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    state?: string;
    country?: string;
    countryCode?: string;
    postalCode?: string;
    postalCodeExtension?: string;
    department?: string;
    subDepartment?: string;
    postBox?: string;
    buildingName?: string;
    buildingFloor?: string;
    buildingRoom?: string;
    townLocationName?: string;
    districtName?: string;
}

export const getCreditorIsoAddress = function (
    address: Location,
    countries?: CountryModelDto[]
): string[] {
    const lines = [];
    // Line 1 – Building Number, Street Name
    if (exists(address.buildingNumber) || exists(address.streetName)) {
        const line1 = [address.buildingNumber, address.streetName].filter(exists).join(' ');
        lines.push(line1);
    } else if (
        exists(address.addressLine1) ||
        exists(address.addressLine2) ||
        exists(address.addressLine3)
    ) {
        const line1 = [address.addressLine1, address.addressLine2, address.addressLine3]
            .filter(exists)
            .join(' ');
        lines.push(line1);
    }
    // Line 2 – City/Town Name, State/Country Sub division, Post Code
    if (exists(address.city) || exists(address.state) || exists(address.postalCode)) {
        let line2 = [address.city, address.state].filter(exists).join(', ');
        if (exists(address.postalCode)) {
            line2 = [line2, address.postalCode].filter(exists).join(' ');
        }
        if (exists(line2) && exists(address.postalCodeExtension)) {
            line2 = [line2, address.postalCodeExtension].filter(exists).join('-');
        }
        lines.push(line2);
    }
    // Line 3 – Country Code
    if (exists(address.country)) {
        if (exists(countries)) {
            lines.push(getCountryNameFromCode(address.country, countries));
        } else {
            lines.push(address.countryCode);
        }
    }
    // Lines 4-11 – Department, Sub Department, Post Box, Building Name, Floor, Room, Town Location Name, District Name
    optionalLocationFieldIds.forEach(field => {
        if (address[field as keyof Location]) {
            lines.push(address[field as keyof Location]);
        }
    });

    return lines.filter(exists);
};

export const getCountryNameFromCode = function (countryCode: string, countries: CountryModelDto[]) {
    return countries.find(country => country.countryCode === countryCode)?.countryName;
};

// eslint-disable-next-line no-useless-escape
export const acceptedAdditionalInfoChars = /^[a-zA-Z0-9!#&%*=^_`{}|~";@\[\]\ ]+$/;

export const additionalLocationInfoFields = [
    {
        name: 'Department',
        id: 'department',
        index: 0,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Sub department',
        id: 'subDepartment',
        index: 1,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Post box',
        id: 'postBox',
        index: 2,
        charLimit: 16,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Building Name',
        id: 'buildingName',
        index: 3,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Floor',
        id: 'buildingFloor',
        index: 4,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Room',
        id: 'buildingRoom',
        index: 5,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Town location name',
        id: 'townLocationName',
        index: 6,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'District name',
        id: 'districtName',
        index: 7,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
];

export const getEditableAdditionalLocation = (creditor: object) => {
    return additionalLocationInfoFields
        .filter(field => exists(creditor[field.id as keyof typeof creditor]))
        .map(field => ({
            type: field,
            value: creditor[field.id as keyof typeof creditor],
            uid: createUniqueId(),
        }));
};

export const getBlankAdditionalLocationInfo = (): {
    value: string;
    type: {
        name: string;
        id: string;
        charLimit: number;
        regex: RegExp;
    };
    uid: string;
} => ({
    value: '',
    type: {
        name: 'Select type',
        id: 'blank',
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    uid: createUniqueId(),
});

export const optionalLocationFieldIds = additionalLocationInfoFields.map(field => field.id);
