import { clone } from '@jack-henry/frontend-utils/functions';
import { WireConfigurationDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { nullWireConfiguration } from './wire-configuration.dto';

export class WireConfiguration extends DomainEntity<WireConfigurationDto> {
    constructor(dto = clone(nullWireConfiguration)) {
        super(dto);
    }

    public createDefault(): this {
        return new WireConfiguration(clone(nullWireConfiguration)) as this;
    }

    public get maximumFutureDays() {
        return this.dto.maximumFutureDays;
    }

    public get fiDliUsdEnabled() {
        return this.dto.fiDliUsdEnabled;
    }
}
