/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  ChangePasswordViewModelDto,
  CredentialDto,
  ErrorHttpContentDto,
  GuidKeyCollectionDto,
  LogOffModelDto,
  LoginCompletionStatusModelDto,
  OkResultDto,
  SecurityMessageModelDto,
  SecurityQuestionModelDto,
  UserInformationModelDto,
  UserModelDto,
  UserPasswordModelDto,
  UserSettingsModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AccountClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Account
   * @name AccountGet
   * @summary Gets user information.
   * @request GET:/account
   */
  accountGet = (params: RequestParams = {}) =>
    this.http.request<UserInformationModelDto, ErrorHttpContentDto>({
      path: `/account`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountPost
   * @summary Posts user information.
   * @request POST:/account
   */
  accountPost = (userInformationModel: UserInformationModelDto | null, params: RequestParams = {}) =>
    this.http.request<SecurityMessageModelDto, ErrorHttpContentDto>({
      path: `/account`,
      method: "POST",
      body: userInformationModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags Account
 * @name AccountLogin
 * @summary Validate the user login credentials and check MFA
Returns the credentials for MFA.
 * @request POST:/account/login
 */
  accountLogin = (user: UserModelDto, params: RequestParams = {}) =>
    this.http.request<CredentialDto, ErrorHttpContentDto>({
      path: `/account/login`,
      method: "POST",
      body: user,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountLogOut
   * @summary Log out the user.
   * @request POST:/account/logout
   */
  accountLogOut = (logOffModel: LogOffModelDto | null, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/account/logout`,
      method: "POST",
      body: logOffModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountLogOutWithUrl
   * @summary Log out with the URL.
   * @request POST:/account/logoutWithUrl
   */
  accountLogOutWithUrl = (logoffRequest: LogOffModelDto | null, params: RequestParams = {}) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/account/logoutWithUrl`,
      method: "POST",
      body: logoffRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountRefreshToken
   * @summary Refresh the the token.
   * @request GET:/account/refreshtoken
   */
  accountRefreshToken = (params: RequestParams = {}) =>
    this.http.request<OkResultDto, ErrorHttpContentDto>({
      path: `/account/refreshtoken`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountTimeOut
   * @summary Returns the time out value.
   * @request GET:/account/timeout
   */
  accountTimeOut = (params: RequestParams = {}) =>
    this.http.request<number, ErrorHttpContentDto>({
      path: `/account/timeout`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountSecurityQuestions
   * @summary Save Security Question and Answers.
   * @request POST:/account/securityquestions
   */
  accountSecurityQuestions = (securityQuestions: SecurityQuestionModelDto[], params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/account/securityquestions`,
      method: "POST",
      body: securityQuestions,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountVerifySecurityQuestions
   * @summary Validate MFA Challenge Questions Answers.
   * @request PUT:/account/verifysecurityquestions
   */
  accountVerifySecurityQuestions = (securityQuestions: SecurityQuestionModelDto[], params: RequestParams = {}) =>
    this.http.request<CredentialDto, ErrorHttpContentDto>({
      path: `/account/verifysecurityquestions`,
      method: "PUT",
      body: securityQuestions,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountVerifyUserForPasswordReset
   * @summary Validate the passed User ID and Login ID for the intent of password reset.
   * @request PUT:/account/VerifyUserForPasswordReset
   */
  accountVerifyUserForPasswordReset = (user: UserModelDto, params: RequestParams = {}) =>
    this.http.request<CredentialDto, ErrorHttpContentDto>({
      path: `/account/VerifyUserForPasswordReset`,
      method: "PUT",
      body: user,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountVerifyUser
   * @summary Validate the passed User ID and Login ID.
   * @request PUT:/account/verifyuser
   */
  accountVerifyUser = (user: UserModelDto, params: RequestParams = {}) =>
    this.http.request<CredentialDto, ErrorHttpContentDto>({
      path: `/account/verifyuser`,
      method: "PUT",
      body: user,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountChangePassword
   * @summary Change User Password.
   * @request POST:/account/changePassword
   */
  accountChangePassword = (userPasswordModel: UserPasswordModelDto | null, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/account/changePassword`,
      method: "POST",
      body: userPasswordModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountUpdatePassword
   * @summary Update User Password.
   * @request PUT:/account/updatepassword
   */
  accountUpdatePassword = (user: UserModelDto | null, params: RequestParams = {}) =>
    this.http.request<ChangePasswordViewModelDto, ErrorHttpContentDto>({
      path: `/account/updatepassword`,
      method: "PUT",
      body: user,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountPasswordRequirements
   * @summary Get Password Strength Requirements.
   * @request GET:/account/passwordrequirements
   */
  accountPasswordRequirements = (
    query: {
      /** string fiId value. */
      fiId: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GuidKeyCollectionDto, ErrorHttpContentDto>({
      path: `/account/passwordrequirements`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags Account
 * @name AccountForgotPassword
 * @summary Validates User for Forgot Password Functionality.
If the provided information is correct it will send an email,
otherwise returns false.
 * @request POST:/account/forgotpassword
 */
  accountForgotPassword = (userModel: UserModelDto, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/account/forgotpassword`,
      method: "POST",
      body: userModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountCheckLoginCompletion
   * @summary Checks login completion.
   * @request GET:/account/checkLoginCompletion
   */
  accountCheckLoginCompletion = (params: RequestParams = {}) =>
    this.http.request<LoginCompletionStatusModelDto, ErrorHttpContentDto>({
      path: `/account/checkLoginCompletion`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountSettings
   * @summary Gets user settings.
   * @request GET:/account/settings
   */
  accountSettings = (params: RequestParams = {}) =>
    this.http.request<UserSettingsModelDto, ErrorHttpContentDto>({
      path: `/account/settings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AccountGetAccountUniqueId
   * @summary Retrieves the account unique id for the account id.
   * @request GET:/account/accountUniqueId/{accountId}
   */
  accountGetAccountUniqueId = (accountId: number, params: RequestParams = {}) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/account/accountUniqueId/${accountId}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
