import { Injectable } from '@jack-henry/frontend-utils/di';
import TermsAndConditionsRequests from '../../requests/account/terms-and-conditions.js';

@Injectable()
export default class TermsAndConditionsService {
    public static getTermsAndConditions() {
        return TermsAndConditionsRequests.get(false);
    }

    public static checkTermsAndConditions() {
        return TermsAndConditionsRequests.get(true);
    }

    public static acceptTermsAndConditions() {
        return TermsAndConditionsRequests.accept();
    }

    public getTermsAndConditions() {
        return TermsAndConditionsRequests.get(false);
    }
}
