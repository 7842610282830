import {
    Config,
    disableAchPaymentValueDates,
    getDefaultValueDate,
    PaymentEntitlements,
    PaymentHeader,
    WorkflowActions,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach';
import { Frequency, FrequencyType } from '@treasury/domain/channel/types/frequency';
import { FieldType, Record } from '@treasury/FDL';
import {
    FrequencyForSelect,
    getFrequencyTypesForSelect,
} from '@treasury/omega/components/omega-frequency/types';
import {
    achCompany,
    achCompanyId,
    achCompanyName,
    achCompanySecCode,
    achDiscretionaryData,
    achEntryDescription,
    achOffsetAccount,
    achPaymentAudit,
    achPaymentFrequency,
    achPaymentName,
    achPaymentStatus,
    achRestrictPayment,
    achTransactionId,
    isAllowUnbalancedPayments,
    requirePrefundingOffsetAccount,
} from '@treasury/policy/ach';
import FieldTypes from '@treasury/policy/primitives';
import { exists } from '@treasury/utils';
import { html } from 'lit';
import { AchDomesticClient } from '../clients/ach-domestic-client';

export const getPaymentFieldValue = (payment: any, field: string) => {
    if (!payment) return '';
    return payment[field] ?? '';
};

export const fullCompanyMatch = (match: number) => match === 5;

export const isExistingPayment = (record: Record<any>) => !!record.getField('id');

export const notStepOne = (record: Record<any>) => record.getField('step') !== 0;
export const notStepTwo = (record: Record<any>) => record.getField('step') !== 1;

export interface Field {
    field: string;
    fieldType: FieldType<any>;
    value?: any;
    label?: string;
}

export interface PaymentHeaderConfiguration {
    fields: Array<Field>;
    source: Record<PaymentHeader>;
}

const sharedTemplateAndPaymentFields = (
    config: Config,
    payment: PaymentHeader,
    client: AchDomesticClient,
    fdlClient: any
): Array<Field> => {
    const {
        entitlements,
        companyMatchType,
        type,
        action,
        holidays,
        sameDayAchSettings,
        allowSameDayPayments,
        cutoffTimes,
        offsetAccount,
    } = config;
    // TODO: move all of these checks outside this fields function
    const isPayment = () => type === WorkflowTypes.Payment;

    const hasPartialPaymentEditEntitlement = () =>
        entitlements &&
        entitlements.edit.partial &&
        !entitlements.edit.full &&
        isPayment() &&
        action !== WorkflowActions.Create;

    const hasFullEditEntitlement = () => entitlements.edit.full;

    const hasPartialTemplateEditEntitlement = () =>
        entitlements.edit.partial &&
        !entitlements.edit.full &&
        !isPayment() &&
        action !== WorkflowActions.Create;
    const hasNoPaymentEditEntitlement = () =>
        !entitlements.edit.partial &&
        !entitlements.edit.full &&
        isPayment() &&
        action !== WorkflowActions.Create;
    const hasNoTemplateEditEntitlement = () =>
        !entitlements.edit.partial &&
        !entitlements.edit.full &&
        !isPayment() &&
        action !== WorkflowActions.Create;

    const isCreatePaymentFlow = () => type === 'payment' && action === 'create';

    /**
     * Note: includes only works here because we're using guid for templates. We need to
     * take a look at either combining identity and companyId to remove this check
     * or extend the initiate checks to know if we're initiating from a payment
     * or a template
     * @returns {boolean}
     */

    const isInitiatePaymentFromTemplateFlow = () =>
        type === WorkflowTypes.Payment &&
        action === WorkflowActions.InitiateFromTemplate &&
        payment.id.toString().includes('-');

    const isEditPaymentFlow = () =>
        type === WorkflowTypes.Payment && action === WorkflowActions.Edit;

    const isEditTemplateFlow = () =>
        type === WorkflowTypes.Template && action === WorkflowActions.Edit;

    const isViewBatchDetail = () => action === WorkflowActions.ViewBatchDetail;

    const isViewMode = () => action === WorkflowActions.View || isViewBatchDetail();

    const isTemplateDetailView = () =>
        action === WorkflowActions.View && type === WorkflowTypes.Template;

    const isPaymentDetailView = () =>
        action === WorkflowActions.View && type === WorkflowTypes.Payment;

    // Match criteria comes from the BO produce feature configuration for ACH
    const doesMatchCriteriaContainCompanyName = () => companyMatchType >= 1;

    const doesMatchCriteriaContainCompanyID = () => companyMatchType >= 2;

    const doesMatchCriteriaContainSECCode = () => companyMatchType >= 3;

    const doesMatchCriteriaContainEntryDescription = () => companyMatchType >= 4;

    const doesMatchCriteriaContainDiscretionaryData = () => companyMatchType >= 5;

    const hasRestrictPaymentEntitlement = (entitlements: PaymentEntitlements) =>
        entitlements.restrictPayment;

    const nachaFileUpload = (record: Record<PaymentHeader>): boolean =>
        !!record.getField('nachaFileUpload');

    const step = (record: Record<PaymentHeader>): number => record.getField('step');

    const lastStep = (record: Record<PaymentHeader>) => {
        if (nachaFileUpload(record)) {
            return step(record) === 3;
        }
        return step(record) === 2;
    };

    const OffsetAccountVisibleSteps = (nachaFileUpload = false) => {
        let visibleSteps = [];
        if (!nachaFileUpload) {
            visibleSteps.push(1);
        } else {
            visibleSteps.push(3);
        }
        if (isPayment()) {
            visibleSteps.push(2);
        }
        if (isEditPaymentFlow() || (isViewMode() && !isViewBatchDetail())) {
            visibleSteps = [0, 1, 2];
        }
        return visibleSteps;
    };

    const OffsetAccountReadOnly = (nachaFileUpload = false) => {
        let readOnlySteps = [];
        if (isEditPaymentFlow()) {
            readOnlySteps.push(0);
        }
        if (nachaFileUpload) {
            readOnlySteps.push(3);
        } else {
            readOnlySteps.push(2);
        }

        if (isViewMode()) {
            readOnlySteps = [0, 1, 2];
        }
        return readOnlySteps;
    };

    if (!config.offsetAccount) {
        config = {
            ...config,
            offsetAccount: {
                requiredSteps: isEditTemplateFlow() ? [] : 1,
                readOnlySteps: OffsetAccountReadOnly(),
                visibleSteps: OffsetAccountVisibleSteps(),
                nachaFileSteps: {
                    requiredSteps: 2,
                    readOnlySteps: OffsetAccountReadOnly(true),
                    visibleSteps: OffsetAccountVisibleSteps(true),
                },
            },
        };
    }

    const valueDate = getDefaultValueDate(
        allowSameDayPayments,
        cutoffTimes,
        holidays,
        sameDayAchSettings
    );

    const getFrequencyFieldValue = (payment: any) => {
        const frequencyValue = getPaymentFieldValue(payment, 'frequency');
        const defaultFrequency = {
            valueDate: null,
            repeatOn: null,
            startOn: null,
            endOn: null,
            nextPaymentDate: null,
            noEndDate: true,
            repeatOnDay1: null,
            repeatOnDay2: null,
            repeatOnLastBusinessDay: null,
            type: frequencyValue.type ?? FrequencyType.OneTime,
            summary: '',
        };
        if (isEditPaymentFlow() && frequencyValue) {
            return defaultFrequency;
        }
        return frequencyValue;
    };

    return [
        {
            field: 'status',
            // we always set the step to 3 on the detail view, so we shouldn't force it to be visible when there's an id
            fieldType: achPaymentStatus.thatIs
                .visibleWhen<PaymentHeader>(
                    record =>
                        (lastStep(record) || isEditPaymentFlow() || isViewMode()) &&
                        !isViewBatchDetail()
                )
                .and.readOnlyWhen<PaymentHeader>(
                    record => exists(record.getField('id')) || notStepOne(record)
                )

                .with.label('Status')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'status'),
        },
        {
            field: 'name',
            fieldType: achPaymentName.thatIs
                .required()
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        !nachaFileUpload(record) && notStepOne(record)
                )
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>): boolean =>
                        nachaFileUpload(record) && notStepTwo(record)
                )
                .and.readOnlyWhen(() => isEditPaymentFlow() && hasPartialPaymentEditEntitlement())
                .and.readOnlyWhen(() => isEditTemplateFlow() && hasPartialTemplateEditEntitlement())
                .and.readOnlyWhen(() => isEditTemplateFlow() && hasNoTemplateEditEntitlement())
                .and.readOnlyWhen(() => isViewMode())

                .thatHas.label('Payment Name')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'name'),
        },
        {
            field: 'achCompany',
            fieldType: achCompany(fdlClient)
                .thatIs.required()
                .thatHas.options({
                    fetch: () => client.getAchCompanies(),
                    text: 'companyName',
                    value: record => record,
                })
                .and.readOnlyWhen((record: Record<PaymentHeader>) => notStepOne(record))
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        isExistingPayment(record) && doesMatchCriteriaContainCompanyName()
                )
                .and.readOnlyWhen(() => isViewMode())

                .thatHas.hashFunction((item: any) => item?.id)
                .thatHas.label('ACH Company Name')
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'achCompany'),
        },
        {
            field: 'achCompanyName',
            fieldType: achCompanyName.thatIs.visibleWhen(() => false).and.readOnly(),
            value: getPaymentFieldValue(payment, 'achCompanyName'),
        },
        {
            field: 'achCompanyId',
            fieldType: achCompanyId.thatIs
                .required()
                .and.readOnlyWhen(
                    () =>
                        isTemplateDetailView() ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainCompanyID()
                )
                .and.readOnlyWhen(notStepOne)
                .and.readOnlyWhen(
                    () => hasNoPaymentEditEntitlement() || hasNoTemplateEditEntitlement()
                )
                .and.readOnlyWhen(() => isViewMode()),
            value: getPaymentFieldValue(payment, 'achCompanyId'),
        },
        {
            field: 'companyIdentity',
            fieldType: achCompanyId.thatIs
                .visibleWhen(
                    () =>
                        isEditTemplateFlow() ||
                        isTemplateDetailView() ||
                        isInitiatePaymentFromTemplateFlow()
                )
                .thatIs.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        isTemplateDetailView() ||
                        notStepOne(record) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainCompanyID()
                )
                .and.readOnlyWhen(() => isViewMode())

                .with.label('ACH Company ID'),
            value: getPaymentFieldValue(payment, 'companyIdentity'),
        },
        {
            field: 'secCode',
            fieldType: achCompanySecCode(secCode => secCode.code !== 'IAT')
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        // eslint-disable-next-line @treasury/max-boolean-operators
                        doesMatchCriteriaContainSECCode() ||
                        isTemplateDetailView() ||
                        notStepOne(record) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        !record.getField('achCompany')
                )
                .and.readOnlyWhen(() => isViewMode())

                .thatHas.label('SEC Code')
                .thatIs.required()
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'secCode'),
        },
        {
            field: 'entryDescription',
            fieldType: achEntryDescription.thatIs
                .required()
                .and.readOnlyWhen(notStepOne)
                .and.readOnlyWhen((record: Record<PaymentHeader>) => {
                    const hasPermissions =
                        hasFullEditEntitlement() ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainEntryDescription();
                    return !hasPermissions || !record.getField('achCompany');
                })
                .and.readOnlyWhen(() => isEditPaymentFlow() && hasPartialPaymentEditEntitlement())
                .and.readOnlyWhen(() => isViewMode())
                .thatHas.label('Entry Description')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'entryDescription'),
        },
        {
            field: 'discretionaryData',
            fieldType: achDiscretionaryData.thatIs
                .readOnlyWhen(notStepOne)
                .and.readOnlyWhen((record: Record<PaymentHeader>) => {
                    const hasPermissions =
                        hasFullEditEntitlement() ||
                        fullCompanyMatch(companyMatchType) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainDiscretionaryData();
                    return !hasPermissions || !record.getField('achCompany');
                })
                .and.readOnlyWhen(() => isEditPaymentFlow() && hasPartialPaymentEditEntitlement())
                .and.readOnlyWhen(() => isViewMode())
                .thatHas.label('Discretionary Data')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'discretionaryData'),
        },
        {
            field: 'restricted',
            fieldType: achRestrictPayment.thatHas
                .label('Restrict Payment')
                .thatIs.visibleWhen(
                    () =>
                        hasRestrictPaymentEntitlement(entitlements as PaymentEntitlements) &&
                        !isEditPaymentFlow() &&
                        !isViewMode()
                )
                .and.readOnlyWhen((record: Record<PaymentHeader>) => {
                    if (nachaFileUpload(record)) {
                        return record.getField('step') >= 2;
                    }
                    return step(record) !== 0;
                })
                .as.tag('omega-checkbox')
                .with.hideLabel()
                .and.template((value, record) => {
                    if (value === 'No') {
                        return 'Not Restricted Payment';
                    }
                    return 'Restricted Payment';
                }),
            value: getPaymentFieldValue(payment, 'restricted'),
        },
        {
            field: 'offsetAccount',
            fieldType: achOffsetAccount(config as Config, fdlClient)
                .thatHas.template((value, record) => {
                    const editFlowNoPaymentOffsetAccount =
                        isEditPaymentFlow() && record.getField('offsetAccount') === '';
                    if (
                        !requirePrefundingOffsetAccount(
                            entitlements as PaymentEntitlements,
                            record
                        ) ||
                        isAllowUnbalancedPayments(entitlements as PaymentEntitlements, record) ||
                        editFlowNoPaymentOffsetAccount
                    ) {
                        return record.getField('achCompany').offsetAccountNumber;
                    }
                    // accountDisplayLabel exists when fetching offsetAccounts from the endpoint. This value disappears
                    // and is replaced by 'value' when loading a child support payment by Id from `getChildSupportPayment`...
                    return (
                        record.getField('offsetAccount').accountDisplayLabel ??
                        record.getField('offsetAccount').value
                    );
                })
                .with.label('Offset Account')
                .thatHas.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'offsetAccount'),
        },
        {
            field: 'debitAmount',
            fieldType: FieldTypes.money.thatIs
                .visibleWhen(
                    (record: Record<PaymentHeader>) =>
                        notStepOne(record) ||
                        isEditPaymentFlow() ||
                        config.action === WorkflowActions.ViewBatchDetail
                )
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        notStepOne(record) ||
                        isEditPaymentFlow() ||
                        config.action === WorkflowActions.ViewBatchDetail
                )

                .thatHas.label('Debit'),
            value: payment ? getPaymentFieldValue(payment, 'debitAmount') : 0,
        },
        {
            field: 'creditAmount',
            fieldType: FieldTypes.money.thatIs
                .visibleWhen(
                    (record: Record<PaymentHeader>) =>
                        notStepOne(record) ||
                        isEditPaymentFlow() ||
                        config.action === WorkflowActions.ViewBatchDetail
                )
                .and.readOnlyWhen(
                    (record: Record<PaymentHeader>) =>
                        notStepOne(record) ||
                        isEditPaymentFlow() ||
                        config.action === WorkflowActions.ViewBatchDetail
                )

                .thatHas.label('Credit'),
            value: payment ? getPaymentFieldValue(payment, 'creditAmount') : 0,
        },
        {
            field: 'frequency',
            label: '',
            fieldType: achPaymentFrequency.with
                .options((record: Record<PaymentHeader>): FrequencyForSelect[] => {
                    // Users in edit flow can not change one time payments to be recurring or vice-versa
                    if (isEditPaymentFlow()) {
                        const frequency = record.getField('frequency') as Frequency;
                        const typePossibleSpace = frequency.type as string;
                        const noSpacesType = typePossibleSpace.replace(' ', '');

                        if (noSpacesType === FrequencyType.OneTime) {
                            return getFrequencyTypesForSelect(['OneTime']);
                        }

                        return getFrequencyTypesForSelect([
                            'Weekly',
                            'EveryTwoWeeks',
                            'TwiceAMonth',
                            'Monthly',
                            'Quarterly',
                            'EverySixMonths',
                            'Yearly',
                        ]);
                    }
                    return getFrequencyTypesForSelect([
                        'OneTime',
                        'Weekly',
                        'EveryTwoWeeks',
                        'TwiceAMonth',
                        'Monthly',
                        'Quarterly',
                        'EverySixMonths',
                        'Yearly',
                    ]);
                })
                .and.selectionDisabledFunctions(
                    disableAchPaymentValueDates(allowSameDayPayments, holidays, cutoffTimes)
                )
                .and.defaultValue({
                    valueDate,
                    repeatOn: null,
                    startOn: null,
                    endOn: null,
                    nextPaymentDate: null,
                    noEndDate: true,
                    repeatOnDay1: null,
                    repeatOnDay2: null,
                    repeatOnLastBusinessDay: null,
                    type: FrequencyType.OneTime,
                    summary: '',
                })
                .and.disabledWhen((record: Record<PaymentHeader>) => {
                    if (!record.getField('achCompany')) return true;
                    // if we've uploaded a file
                    const nacha = nachaFileUpload(record);
                    if (nacha) {
                        return step(record) === 2 || step(record) === 3;
                    }
                    return step(record) !== 0;
                })
                .and.readOnlyWhen(() => isViewMode())
                .thatIs.visibleWhen(isPayment)
                .as.tag('omega-frequency')
                .with.frequencyAnnotation((record: any) => {
                    const isReadOnly = record.fieldTypeForField('frequency').readonly(record);

                    if (isReadOnly || !nachaFileUpload(record)) return null;

                    return html`<div class="annotation">
                        <i class="fa fa-calendar"></i>
                        <span>Please validate the Effective Date for accuracy</span>
                    </div>`;
                })
                .thatIs.required()
                .with.label('Effective Date / Frequency')
                .and.hideLabel(),
            value: getFrequencyFieldValue(payment),
        },
        {
            field: 'audit',
            fieldType: achPaymentAudit.thatIs
                .visibleWhen(
                    (record: Record<PaymentHeader>) =>
                        (lastStep(record) || isEditPaymentFlow() || isViewMode()) &&
                        !isViewBatchDetail()
                )
                .and.readOnlyWhen(() => true)
                .thatHas.label('Audit'),
            value: getPaymentFieldValue(payment, 'audit'),
        },
        {
            field: 'transactionId',
            fieldType: achTransactionId.thatIs
                .visibleWhen((record: Record<PaymentHeader>) => lastStep(record))
                .thatIs.visibleWhen(isPayment)
                .thatHas.label('Transaction ID'),
            value: getPaymentFieldValue(payment, 'transactionId'),
        },
        {
            field: 'id',
            fieldType: FieldTypes.number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'id'),
        },
        /* meta */
        {
            field: 'step',
            fieldType: FieldTypes.number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'step'),
        },
        {
            field: 'action',
            fieldType: FieldTypes.string.thatIs.visibleWhen(() => false),
            value: action,
        },
        {
            field: 'type',
            fieldType: FieldTypes.string.thatIs.visibleWhen(() => false),
            value: type,
        },
        {
            field: 'statusDescription',
            fieldType: FieldTypes.string.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'statusDescription'),
        },
        {
            field: 'achCompanyUniqueId',
            fieldType: FieldTypes.string.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'achCompanyUniqueId'),
        },
        {
            field: 'nachaFileUpload',
            fieldType: FieldTypes.boolean.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'nachaFileUpload'),
        },
        {
            field: 'fileArchiveId',
            fieldType: FieldTypes.number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'fileArchiveId'),
        },
    ];
};

export const getPaymentHeaderRecord = (
    config: Config,
    payment: PaymentHeader,
    client = new AchDomesticClient(),
    fdlClient = null
): PaymentHeaderConfiguration => {
    const fields = sharedTemplateAndPaymentFields(config, payment, client, fdlClient);
    const fieldTypes = fields.reduce(
        (acc, curr) => {
            acc[curr.field] = curr.fieldType;
            return acc;
        },
        {} as { [key: string]: FieldType<any> }
    );
    const values = fields.reduce(
        (acc, curr) => {
            acc[curr.field] = curr.value || curr.fieldType.defaultValue();
            return acc;
        },
        {} as { [key: string]: FieldType<any> }
    ) as unknown as PaymentHeader;
    const source = new Record<PaymentHeader>(fieldTypes, {
        ...(values as PaymentHeader),
        step: 0,
    } as PaymentHeader);
    return {
        fields,
        source,
    };
};
