/**
 * TODO: Obviously this code needs some work to meet eslint requirements
 */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable sonarjs/cognitive-complexity */
import { clone } from '@jack-henry/frontend-utils/functions';

const MARGIN_ADJUSTMENT = 0.5;
const MAX_NESTED_ELEMENTS = 2;

/**
 * When getting the bounding client rectangle from the element
 * we want to add the elements margin (with a small adjustment)
 * to the bounds of the rectangle. 

 * This provides the visible bounding box.
 */
function getBoundingRectangle(element) {
    const rectangle = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);
    const bound = {};

    const marginTop = Number.parseFloat(style.marginTop) + MARGIN_ADJUSTMENT;
    const marginBottom = Number.parseFloat(style.marginBottom) + MARGIN_ADJUSTMENT;
    const marginLeft = Number.parseFloat(style.marginLeft) + MARGIN_ADJUSTMENT;
    const marginRight = Number.parseFloat(style.marginRight) + MARGIN_ADJUSTMENT;

    bound.top = rectangle.top - marginTop;
    bound.bottom = rectangle.bottom + marginBottom;
    bound.left = rectangle.left - marginLeft;
    bound.right = rectangle.right + marginRight;

    bound.x = rectangle.x - marginTop;
    bound.y = rectangle.y - marginLeft;

    bound.width = rectangle.width + marginTop + marginBottom;
    bound.height = rectangle.height + marginLeft + marginRight;

    return bound;
}

export default class DragAndDropModel {
    constructor(element, container, config, view) {
        this.window = window;
        this.document = document.body;
        this.element = element;
        this.container = container;
        this.view = view;
        this.tiles = container.querySelectorAll('.omega_tile_draggable');
        this.clickTile = null;
        this.dragTile = null;
        this.hoverTile = null;
        this.sortedTiles = [];
        this.click = {};
        this.dragging = false;
        this.listeners = {
            change: [],
        };
        this.container.dataset.active = config.active;
        this.container.dataset.isSortable = true;

        this.tiles.forEach((tile, idx) => this.setOrderOrRange(tile, idx));

        this.element.addEventListener('mousedown', this.onPress.bind(this), true);
        this.element.addEventListener('touchstart', this.onPress.bind(this), true);
        this.element.addEventListener('mouseup', this.onRelease.bind(this), true);
        this.element.addEventListener('touchend', this.onRelease.bind(this), true);
        this.element.addEventListener('mousemove', this.onMove.bind(this), true);
        this.element.addEventListener('touchmove', this.onMove.bind(this), true);
        this.tiles.forEach((tile, idx) => {
            this.setOrderOrRange(tile, idx);
            this.addTileListeners(tile);
        });
    }

    on(event, fn) {
        this.listeners[event].push(fn);
    }

    notifyListeners(event) {
        this.listeners[event].forEach(fn => fn());
    }

    addTileListeners(tile) {
        const removeTileButton = tile.querySelector('.tile_remove_button');
        removeTileButton.addEventListener('click', () => this.removeTile(tile));

        if (tile.dataset.tileType !== 'fixed position') return;
        const columnWidth = tile.querySelector('.tile_position_count_input');
        const addToWidth = tile.querySelector('.add_button');
        const subtractFromWidth = tile.querySelector('.subtract_button');

        columnWidth.addEventListener('keyup', () =>
            this.keyupInput(subtractFromWidth, columnWidth, tile)
        );
        columnWidth.addEventListener('change', () =>
            this.changeInput(subtractFromWidth, columnWidth, tile)
        );
        addToWidth.addEventListener('click', () =>
            this.increase(subtractFromWidth, columnWidth, tile)
        );
        subtractFromWidth.addEventListener('click', () =>
            this.decrease(subtractFromWidth, columnWidth, tile)
        );
    }

    changeInput(button, input, tile) {
        if (Number(input.value) <= 0) {
            tile.dataset.width = 1;
            input.setAttribute('value', tile.dataset.width);
            input.value = 1;
            button.setAttribute('disabled', true);
        }
        this.sortTiles();
    }

    keyupInput(button, input, tile) {
        tile.dataset.width = input.value;
        input.setAttribute('value', tile.dataset.width);
        if (Number(tile.dataset.width) <= 1) {
            button.setAttribute('disabled', true);
        } else {
            button.removeAttribute('disabled');
        }
        this.sortTiles();
    }

    increase(button, input, tile) {
        tile.dataset.width = (Number(tile.dataset.width) + 1).toString();
        input.setAttribute('value', tile.dataset.width);
        input.value = tile.dataset.width;
        button.removeAttribute('disabled');
        this.sortTiles();
    }

    decrease(button, input, tile) {
        tile.dataset.width = (Number(tile.dataset.width) - 1).toString();
        if (+tile.dataset.width <= 1) {
            button.setAttribute('disabled', true);
        }
        input.setAttribute('value', tile.dataset.width);
        input.value = tile.dataset.width;
        this.sortTiles();
    }

    getPosition(e) {
        const scrollX =
            Math.max(0, this.window.pageXOffset || this.element.scrollLeft || 0) -
            (this.document.clientLeft || 0);
        const scrollY =
            Math.max(0, this.window.pageYOffset || this.element.scrollTop || 0) -
            (this.document.clientTop || 0);
        const x = e ? Math.max(0, e.pageX || e.clientX || 0) - scrollX : 0;
        const y = e ? Math.max(0, e.pageY || e.clientY || 0) - scrollY : 0;

        return { x, y };
    }

    isValidContainer(tile, container) {
        if (tile.dataset.optional === 'true' && tile.dataset.type !== 'filler') return true;
        if (container.dataset.active === 'true' && tile.dataset.type === 'filler') return true;
        if (container.dataset.active === 'true' && tile.dataset.optional === 'false') return true;
        return false;
    }

    isOnTop(tile, x, y) {
        const box = getBoundingRectangle(tile);
        const isx = x > box.left && x < box.left + box.width;
        const isy = y > box.top && y < box.top + box.height;
        return isx && isy;
    }

    tileClass(tile, task, cls) {
        const list = tile.className.split(/\s+/);
        const index = list.indexOf(cls);

        if (task === 'add' && index == -1) {
            list.push(cls);
            tile.className = list.join(' ');
        } else if (task === 'remove' && index != -1) {
            list.splice(index, 1);
            tile.className = list.join(' ');
        }
    }

    shiftTiles(tile1, tile2) {
        if (tile1.parentElement !== tile2.parentElement) return;

        tile1.before(tile2);
    }

    moveTile(tile, x, y) {
        tile.style['-webkit-transform'] = `translateX( ${x}px ) translateY( ${y}px )`;
        tile.style['-moz-transform'] = `translateX( ${x}px ) translateY( ${y}px )`;
        tile.style['-ms-transform'] = `translateX( ${x}px ) translateY( ${y}px )`;
        tile.style.transform = `translateX( ${x}px ) translateY( ${y}px )`;
    }

    makeDragTile(tile) {
        this.trashDragTile();
        this.containers = this.element.querySelectorAll('[data-is-sortable]');

        this.clickTile = tile;
        this.tileClass(this.clickTile, 'add', 'active');

        this.dragTile = document.createElement(tile.tagName);
        this.dragTile.classList.add('omega_tile_draggable', 'dragging');
        this.dragTile.dataset.type = tile.dataset.type;
        this.dragTile.innerHTML = tile.innerHTML;
        this.dragTile.style.position = 'absolute';
        this.dragTile.style['z-index'] = '999';
        this.dragTile.style.left = `${tile.offsetLeft || 0}px`;
        this.dragTile.style.top = `${tile.offsetTop || 0}px`;
        this.dragTile.style.width = `${tile.offsetWidth || 0}px`;

        this.container.appendChild(this.dragTile);
    }

    makeFillerTile() {
        const optionalContainer = Array.from(this.containers).pop();
        const optionalContainerHasFiller =
            Array.from(optionalContainer.querySelectorAll('.omega_tile_draggable')).filter(
                tile => tile.dataset.type === 'filler'
            ).length > 0;
        if (optionalContainerHasFiller) return;
        const fillerTile = this.view.buildTile(
            {
                id: 100,
                name: 'Filler Tile',
                optional: 'true',
                order: null,
                required: 'false',
                type: 'filler',
                width: 1,
            },
            this.view.tileType
        );
        this.view.buildRemoveTileButton(fillerTile);
        this.addTileListeners(fillerTile);
        optionalContainer.appendChild(fillerTile);
    }

    trashFillerTile(tile) {
        tile.remove();
        this.sortTiles();
    }

    trashDragTile() {
        if (this.dragTile && this.clickTile) {
            if (this.dragTile.dataset.type === 'filler') {
                this.trashFillerTile(this.dragTile);
                this.makeFillerTile();
            }
            this.tileClass(this.clickTile, 'remove', 'active');
            this.clickTile = null;

            this.dragTile.remove();
            this.dragTile = null;
        }
        this.sortTiles();
    }

    removeTile(tile) {
        if (tile.dataset.type === 'filler') {
            tile.remove();
        } else {
            Array.from(this.element.querySelectorAll("[active-container='false']"))
                .pop()
                .prepend(tile);
        }
        this.sortTiles();
    }

    findTile(target, breakCount = MAX_NESTED_ELEMENTS) {
        if (!target) return;

        if (target.nodeName === 'INPUT' || target.nodeName === 'BUTTON') return;

        if (breakCount < 0 || !target) return;

        if (target.parentNode === this.container) return target;

        return this.findTile(target.parentNode, breakCount - 1);
    }

    onPress(e) {
        if (!e.target) return;
        const tile = this.findTile(e.target);
        if (tile) {
            e.preventDefault();
            this.dragging = true;
            this.click = this.getPosition(e);
            this.makeDragTile(tile);
            this.onMove(e);
        }
    }

    onRelease() {
        if (!this.clickTile) return;
        this.dragging = false;
        this.trashDragTile();
    }

    sortTiles() {
        this.sortedTiles = Array.from(this.element.querySelectorAll('.omega_tile_draggable'))
            .filter(node => node.dataset)
            .map((item, idx) => {
                item.dataset.active = item.parentElement.dataset.active;
                this.setOrderOrRange(item, idx);
                return clone(item.dataset);
            });
        this.notifyListeners('change');
    }

    setOrderOrRange(tile, idx) {
        tile.dataset.order = idx + 1;
        this.view.setTileRemoveState(tile);
        if (tile.dataset.tileType === 'delimited') {
            tile.querySelector('.tile_order').innerHTML = tile.dataset.order;
        }
        if (tile.dataset.tileType === 'fixed position') {
            const tiles = this.element.querySelectorAll('.omega_tile_draggable');
            let rangeStart = 0;
            let rangeEnd = 0;
            tiles.forEach((tile, idx) => {
                const rangeEl = tile.querySelector('.tile_order');
                const width = tiles[idx - 1]?.dataset?.width ?? 1;
                rangeStart = Number(width) + rangeStart;
                rangeEnd = rangeStart + Number(tile.dataset.width) - 1;
                rangeEl.innerHTML = `${rangeStart} - ${rangeEnd}`;
                tile.dataset.beginPosition = rangeStart;
                tile.dataset.endPosition = rangeEnd;
            });
        }
    }

    onMove(e) {
        if (this.dragTile && this.dragging) {
            e.preventDefault();
            const point = this.getPosition(e);
            let { container } = this;

            this.moveTile(this.dragTile, point.x - this.click.x, point.y - this.click.y);

            for (let i = 0; i < this.containers.length; i++) {
                const listsContainer = this.containers[i];

                if (this.isOnTop(listsContainer, point.x, point.y)) {
                    container = listsContainer;
                }
            }

            if (
                this.isOnTop(container, point.x, point.y) &&
                this.isValidContainer(this.clickTile, container)
            ) {
                container.appendChild(this.clickTile);
            }

            for (let i = 0; i < container.children.length; i++) {
                const subTile = container.children[i];

                if (
                    this.isOnTop(subTile, point.x, point.y) &&
                    this.isValidContainer(this.clickTile, container)
                ) {
                    this.shiftTiles(subTile, this.clickTile);
                    break;
                }
            }
        }
    }
}
