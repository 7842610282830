import { AgileticsSamlResponseDto } from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';

interface AgileticsWebEscrowSsoResponseBody {
    samlResponse: string;
    relayState: string;
}

type AgileticsResponse = Omit<AgileticsSamlResponseDto, 'body'> & {
    // we need to manually define body shape since generated type is too generic
    body?: AgileticsWebEscrowSsoResponseBody;
};
export default class AgileticsWebEscrowSsoRequest {
    public static async getAgileticsWebEscrowSsoUrl() {
        return (await TmHttpClient.getInstance()).request<AgileticsResponse>(
            `Authentication/Agiletics`,
            {
                method: 'POST',
            }
        );
    }

    public static async login(
        url: string,
        body: AgileticsWebEscrowSsoResponseBody
    ): Promise<string> {
        const params = new URLSearchParams({
            SAMLResponse: body.samlResponse,
            RelayState: body.relayState,
        });

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
            credentials: 'include',
        });

        return new URL(url).origin + body.relayState;
    }
}
