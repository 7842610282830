import { DiContainer } from '@jack-henry/frontend-utils/di';
import { ApiErrorDto } from '@treasury/api/shared';
import { NavigationService } from '@treasury/core/navigation';
import { ModalService } from '@treasury/core/types';
import { exists } from '@treasury/utils';
import { AuthRequestInterceptor } from './auth-request-interceptor';
import { AuthResponseInterceptor } from './auth-response-interceptor';

export function AuthInterceptorServiceFactory(
    $q: ng.IQService,
    $injector: ng.auto.IInjectorService
): ng.IHttpInterceptor {
    return {
        request: AuthRequestInterceptor,
        response: async response => AuthResponseInterceptor(response),
        responseError: async response => {
            const data = await AuthResponseInterceptor(response);
            if (data.status >= 400) {
                const navService = (await DiContainer.getInstance()).get(NavigationService);
                const modalService = $injector.get<ModalService>('modalService');

                return handleResponseError(
                    data as ng.IHttpResponse<ApiErrorDto>,
                    $q,
                    navService,
                    modalService
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ) as ng.IPromise<ng.IHttpResponse<any>>;
            }
            return data;
        },
    };
}

function handleResponseError<T extends ApiErrorDto>(
    response: ng.IHttpResponse<T>,
    $q: ng.IQService,
    navService: NavigationService,
    modalService: ModalService
) {
    const { status, data } = response;

    if (status === 401) {
        if (
            exists(data?.error) &&
            response.data.error === 'Authorization has been denied for this request'
        ) {
            return $q.reject(response);
        }

        navService.navigate('login').then(() => {
            // TODO: remove this once the deep link login issue has been resolved;
            // this is a temporary work around to clear in-memory state from a prior user session
            window.location.reload();
        });
    } else if (status === 400 || status === 409 || status === 500) {
        const { config } = response;
        const suppressErrorMessage =
            'suppressErrorMessage' in config && !!config.suppressErrorMessage;
        const errorMessage = typeof data === 'string' ? data : undefined;

        let error: ApiErrorDto = data;
        if (config.responseType === 'arraybuffer') {
            error = JSON.parse(new TextDecoder().decode(data as unknown as ArrayBuffer));
        }

        if (!suppressErrorMessage) {
            if (errorMessage) {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText: errorMessage,
                    }
                );
            } else {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText: error.message || '',
                        actionCode: error.code ? error.code.toString() : '',
                        time: error.time || '',
                    }
                );
            }
        }
    }

    return $q.reject(response);
}

AuthInterceptorServiceFactory.$inject = ['$q', '$injector'];
