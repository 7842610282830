import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import {
    AccountDto,
    IssuedItemEntryType,
    IssuedItemLegacyDto,
} from '@treasury/domain/channel/types/arp';
import { FieldType } from '@treasury/FDL';
import '@treasury/omega/layouts/omega-report/omega-report';
import { OmegaReportFilter, ReportFilterFactory } from '@treasury/omega/types';
import { OmegaListItem } from '@treasury/omega/view-models';
import FieldTypes from '@treasury/policy/primitives';
import { isIntegerString } from '@treasury/utils';
import { AccountListItem, createAccountListItem, itemStatusOptions } from '../models';

export const entryTypes: IssuedItemEntryType[] = ['Upload', 'Manual', 'Other'];

export const itemTypes: IssuedItemLegacyDto['type'][] = ['Check', 'Void'];

const entryTypesVm: OmegaListItem<IssuedItemEntryType>[] = entryTypes.map(type => ({
    text: type,
    value: type,
}));

const itemTypesVm: OmegaListItem<string>[] = itemTypes.map(type => ({
    text: type,
    value: type.toLocaleLowerCase(),
}));

const filterFactory = new ReportFilterFactory<IIssuedItem>();

const dateMultiFilter = (hasIssuedItemsClearedDate = false) =>
    filterFactory.createMulti(
        hasIssuedItemsClearedDate
            ? ['dateIssued', 'createdDate', 'voidedDate', 'clearedDate']
            : ['dateIssued', 'createdDate', 'voidedDate'],
        FieldTypes.multiDate.with
            .range()
            .and.placeholder('Select Date')
            .with.label('Date Type')
            .as.tag('omega-multi-date') as unknown as FieldType<Date | undefined>,
        hasIssuedItemsClearedDate
            ? [
                  {
                      text: 'Issued Date',
                      value: 'dateIssued',
                  },
                  {
                      text: 'Created Date',
                      value: 'createdDate',
                  },
                  {
                      text: 'Void Date',
                      value: 'voidedDate',
                  },
                  {
                      text: 'Cleared Date',
                      value: 'clearedDate',
                  },
              ]
            : [
                  {
                      text: 'Issued Date',
                      value: 'dateIssued',
                  },
                  {
                      text: 'Created Date',
                      value: 'createdDate',
                  },
                  {
                      text: 'Void Date',
                      value: 'voidedDate',
                  },
              ],
        undefined
    );

export async function createFilters(
    accounts: AccountDto[],
    hasIssuedItemsClearedDate = false
): Promise<OmegaReportFilter<IIssuedItem, any>[]> {
    return [
        {
            field: 'itemEntryType',
            fieldType: FieldTypes.string.with
                .label('Item Entry Type')
                .with.options({
                    data: entryTypesVm,
                })
                .with.placeholder('Select an entry type')
                .with.multipleValues()
                .as.tag('omega-select'),
            value: entryTypesVm.map(type => type.value),
        },
        {
            field: 'type',
            fieldType: FieldTypes.string.with
                .label('Item Type')
                .with.options({
                    data: itemTypesVm,
                })
                .with.placeholder('Select an item type')
                .with.multipleValues()
                .as.tag('omega-select'),
            value: itemTypesVm.map(type => type.value),
        },
        {
            field: 'itemStatus',
            fieldType: FieldTypes.string.with
                .label('Item Status')
                .with.options({
                    data: itemStatusOptions,
                })
                .with.placeholder('Select a status')
                .with.multipleValues()
                .as.tag('omega-select'),
            value: itemStatusOptions.map(item => item.value),
        },
        dateMultiFilter(hasIssuedItemsClearedDate),
        {
            field: 'accountName',
            fieldType: new FieldType<AccountListItem>().with
                .defaultValue(null)
                .and.options({
                    data: accounts.map(a => createAccountListItem(a)),
                })
                .with.placeholder('Select an account')
                .with.label('Accounts')
                .with.multipleValues()
                .thatHas.hashFunction<AccountListItem['value']>(v => v.accountUniqueId),
            value: accounts,
        },
        {
            field: 'checkAmount',
            fieldType: FieldTypes.amountRange.with.label('Amount').as.tag('omega-range'),
            value: ['specific', null],
        },
        {
            field: 'checkNumber',
            fieldType: (FieldTypes.string as FieldType<string>).with
                .label('Check Number')
                .as.tag('omega-input')
                .and.validator({
                    name: 'checkNumberFilterValidator',
                    validate: v => v === '' || isIntegerString(v),
                })
                .and.inputMask(/[0-9]/),
            value: '',
        },
        {
            field: 'payee',
            fieldType: FieldTypes.string.with.label('Payee').as.tag('omega-input'),
            value: '',
        },
    ];
}
