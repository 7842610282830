/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { CompanyAccountModelDto, LookupListModelDto, LookupModelDto, StpModelDto, StpSearchModelDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class StpClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Stp
   * @name StpGetAll
   * @summary Gets all accounts eligible for the stop payment feature.
   * @request GET:/stp
   */
  stpGetAll = (params: RequestParams = {}) =>
    this.http.request<StpModelDto[], any>({
      path: `/stp`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpGetAll2
   * @summary Gets all accounts eligible for the stop payment feature.
   * @request GET:/stp/getAll
   */
  stpGetAll2 = (params: RequestParams = {}) =>
    this.http.request<StpModelDto[], any>({
      path: `/stp/getAll`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpCreate
   * @summary Creates the specified model.
   * @request POST:/stp/create
   */
  stpCreate = (model: StpModelDto | null, params: RequestParams = {}) =>
    this.http.request<StpModelDto, any>({
      path: `/stp/create`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpSearch
   * @summary Searches the specified model.
   * @request POST:/stp/search
   */
  stpSearch = (model: StpSearchModelDto | null, params: RequestParams = {}) =>
    this.http.request<StpSearchModelDto[], any>({
      path: `/stp/search`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpGetAccounts
   * @summary Gets the accounts.
   * @request GET:/stp/getAccounts
   */
  stpGetAccounts = (params: RequestParams = {}) =>
    this.http.request<CompanyAccountModelDto[], any>({
      path: `/stp/getAccounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpGetFilterAccounts
   * @summary Gets the filter accounts.
   * @request GET:/stp/getFilterAccounts
   */
  stpGetFilterAccounts = (params: RequestParams = {}) =>
    this.http.request<StpModelDto, any>({
      path: `/stp/getFilterAccounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpApprove
   * @summary Approves the specified identifier.
   * @request POST:/stp/{id}/approve
   */
  stpApprove = (id: number, model: LookupModelDto, params: RequestParams = {}) =>
    this.http.request<StpModelDto, any>({
      path: `/stp/${id}/approve`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpReject
   * @summary Rejects the specified identifier.
   * @request POST:/stp/{id}/reject
   */
  stpReject = (id: number, model: LookupModelDto, params: RequestParams = {}) =>
    this.http.request<StpModelDto, any>({
      path: `/stp/${id}/reject`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpCancel
   * @summary Cancels the specified identifier.
   * @request POST:/stp/{id}/cancel
   */
  stpCancel = (id: number, model: LookupModelDto, params: RequestParams = {}) =>
    this.http.request<StpModelDto, any>({
      path: `/stp/${id}/cancel`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpBatchApprove
   * @summary Batches the approve.
   * @request POST:/stp/batchApprove
   */
  stpBatchApprove = (batch: LookupListModelDto, params: RequestParams = {}) =>
    this.http.request<StpModelDto[], any>({
      path: `/stp/batchApprove`,
      method: "POST",
      body: batch,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stp
   * @name StpBatchReject
   * @summary Batches the reject.
   * @request POST:/stp/batchReject
   */
  stpBatchReject = (batch: LookupListModelDto, params: RequestParams = {}) =>
    this.http.request<StpModelDto[], any>({
      path: `/stp/batchReject`,
      method: "POST",
      body: batch,
      format: "json",
      ...params,
    });
}
