import { JhHttpClientCached } from '@jack-henry/frontend-utils/http';
import { removeAuthToken, TmHttpClient } from '@treasury/core/http';
import { SessionStorageService } from '@treasury/utils';
import { StatusCode } from '../../../src/login/data/login-phases.js';

/**
 *
 * @param { ng.IScope } $scope
 * @param { ng.IRootScopeService } $rootScope
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
// eslint-disable-next-line @treasury/filename-match-export
export default function LoginController(
    $scope,
    $rootScope,
    $state,
    $modalStack,
    accountService,
    pagePushService,
    TmDi
) {
    $scope.loginData = {
        institution: document.documentElement.dataset.institution,
        companyId: '',
        loginId: '',
        password: '',
        useRefreshTokens: false,
    };

    $scope.message = '';

    init();

    async function init() {
        $modalStack.dismissAll();
        $('.dropdown.open').removeClass('open');
        if ($rootScope.closeEverything) {
            $rootScope.closeEverything();
        }
        removeAuthToken();
        const http = await TmDi.getAsync(TmHttpClient);
        if (http instanceof JhHttpClientCached) {
            http.clearCache();
        }

        await clearSessionStorage();
    }

    function getStorageService() {
        return TmDi.getAsync(SessionStorageService);
    }

    async function clearSessionStorage() {
        const storageService = await getStorageService();
        storageService.clear();
    }

    $scope.navigateToForgotPassword = function () {
        $state.go('forgot-password');
    };

    $scope.login = async () => {
        $scope.message = '';
        $scope.loginData.userName = $scope.loginData.loginId;
        if ($scope.loginData.companyId && $scope.loginData.loginId && $scope.loginData.password) {
            try {
                const response = await accountService.validateUser($scope.loginData);

                // https://stackoverflow.com/a/43874591/4322226
                $(':password').val('');
                delete $scope.loginData.password;
                $scope.loginData.credential = response;
                pagePushService.setUser($scope.loginData);
                $scope.message = accountService.checkForError($scope.loginData.credential);
                if (response.statusCode === StatusCode.Invalid) throw new Error(response.message);
                accountService.setAuth({
                    statusCode: response.statusCode,
                    daysUntilPasswordExpires: response.daysUntilPasswordExpires,
                });

                await accountService.goToNextStep();
            } catch (response) {
                const message =
                    response instanceof Error
                        ? response.message
                        : 'An error occurred during login. Please try again.';

                $scope.message = message;
                $scope.$apply();
            }
        } else {
            $scope.message = 'Required fields are missing. Please try again.';
        }
    };
}

LoginController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$modalStack',
    'accountService',
    'pagePushService',
    'TmDi',
];
