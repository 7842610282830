/**
 * TODO: Add test to cover these functions
 */

import { AlarmClock } from '@treasury/alarm-clock';
import { CutoffTimes, Holiday } from '..';
import { SameDayAchSettings } from './sameDayAchSettings';

interface DateModel {
    day: number;
    month: number;
    dayOfMonth: number;
    year: number;
    isWeekend: () => boolean;
}

export const checkAfterCutoff = (
    cutoffTimes: CutoffTimes,
    productType: 'Same Day ACH' | 'ACH' | 'Transfer'
) => {
    const isProductProcessingCutoffEnabledForFi = cutoffTimes.processingCutoffs.some(
        productCutoffs => productCutoffs.productType === productType
    );
    const productCutoff = cutoffTimes.processingCutoffs.find(
        productCutoffs => productCutoffs.productType === productType
    );
    const fiTime = cutoffTimes.currentFiTime.split('T').pop();

    if (isProductProcessingCutoffEnabledForFi) {
        if (!fiTime || !productCutoff) {
            return false;
        }
        return fiTime > productCutoff.cutoffTime;
    }
    return false;
};

export const checkHolidays = (checkDate: Date, holidays: Array<Holiday>) => {
    const isHoliday = () =>
        holidays.some(
            h =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (new Date(h.date) as any).getYear() === (checkDate as any).getYear() &&
                new Date(h.date).getMonth() === checkDate.getMonth() &&
                new Date(h.date).getDate() === checkDate.getDate()
        );
    return isHoliday();
};

export const getNextBusinessDay = (holidays: Array<Holiday>, fromDate: Date = new Date()) => {
    const date = fromDate;
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1); // tomorrow
    if (date.getDay() === 0) {
        date.setDate(date.getDate() + 1);
    } else if (date.getDay() === 6) {
        date.setDate(date.getDate() + 2);
    }
    if (checkHolidays(date, holidays)) {
        getNextBusinessDay(holidays, date);
    }
    return date.toISOString();
};

const getDayAfterNextBusinessDay = (holidays: Array<Holiday>) => {
    const date = new Date(getNextBusinessDay(holidays));
    return getNextBusinessDay(holidays, date);
};

export const getDefaultValueDate = (
    allowSameDayPayments: boolean,
    cutoffTimes: CutoffTimes,
    holidays: Array<Holiday>,
    sameDayAchSettings?: SameDayAchSettings
) => {
    const cutoffTimeProductType = allowSameDayPayments ? 'Same Day ACH' : 'ACH';
    const today = new Date();
    let isAfterCutoff = false;
    today.setHours(0, 0, 0, 0);

    const cutoffs = { ...cutoffTimes };

    if (sameDayAchSettings?.processingCutoff) {
        const { processingCutoffs } = cutoffs;
        processingCutoffs.push(sameDayAchSettings.processingCutoff);
    }

    if (
        allowSameDayPayments &&
        sameDayAchSettings &&
        sameDayAchSettings.processingCutoff?.cutOffDateTime
    ) {
        isAfterCutoff =
            sameDayAchSettings.currentFiTime > sameDayAchSettings?.processingCutoff?.cutOffDateTime;
    } else {
        isAfterCutoff = checkAfterCutoff(cutoffs, cutoffTimeProductType);
    }

    const holiday = checkHolidays(today, holidays);

    if (holiday) {
        if (allowSameDayPayments) {
            return getNextBusinessDay(holidays);
        }
        return getDayAfterNextBusinessDay(holidays);
    }
    if (isAfterCutoff) {
        if (allowSameDayPayments) {
            return getNextBusinessDay(holidays);
        }
        return getDayAfterNextBusinessDay(holidays);
    }
    if (!allowSameDayPayments) {
        return getNextBusinessDay(holidays);
    }
    //This return should be changed from `$TODAY` to `today.toISOString()` whenever Omega is no longer used
    return '$TODAY';
};

export const isTodayValidProcessingDate = (
    allowSameDayPayments: boolean,
    cutoffTimes: CutoffTimes,
    sameDayAchSettings?: SameDayAchSettings
) => {
    const cutoffTimeProductType = allowSameDayPayments ? 'Same Day ACH' : 'ACH';
    if (!allowSameDayPayments) {
        return false;
    }
    if (sameDayAchSettings?.processingCutoff) {
        cutoffTimes.processingCutoffs.push(sameDayAchSettings.processingCutoff);
    }
    return !(allowSameDayPayments && checkAfterCutoff(cutoffTimes, cutoffTimeProductType));
};

export const isValidDate = (
    dateModel: DateModel | Date,
    allowSameDayPayments: boolean,
    holidays: Array<Holiday>,
    cutoffTimes: CutoffTimes
) => {
    const checkDate =
        dateModel instanceof Date
            ? dateModel
            : new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
    const cutoffTimeProductType = allowSameDayPayments ? 'Same Day ACH' : 'ACH';
    const today = new Date();
    const nextBusinessDay = new Date(getNextBusinessDay(holidays, new Date()));
    checkDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    nextBusinessDay.setHours(0, 0, 0, 0);
    const checkDateIsToday = checkDate.toString() === today.toString();
    const checkDateIsNextBusinessDay = checkDate.toString() === nextBusinessDay.toString();

    /**
     * if same day payments are turned off, and we're after the cutoff, the expected behavior
     * is to disable the next business day in the date picker
     */
    if (
        !allowSameDayPayments &&
        checkAfterCutoff(cutoffTimes, cutoffTimeProductType) &&
        checkDateIsNextBusinessDay
    ) {
        return true;
    }
    if (!(dateModel instanceof Date) && dateModel.isWeekend()) {
        return true;
    }
    if (checkHolidays(checkDate, holidays)) {
        return true;
    }
    if (checkDate < today) {
        return true;
    }
    return checkDateIsToday && !isTodayValidProcessingDate(allowSameDayPayments, cutoffTimes);
};

export const isIncomingDateValid = (
    incomingDate: Date | null,
    allowSameDayPayments: boolean,
    holidays: Array<Holiday>,
    sameDayAchSettings: SameDayAchSettings
) => {
    if (!incomingDate) {
        return false;
    }
    let isAfterCutoff = false;

    if (sameDayAchSettings?.processingCutoff?.cutOffDateTime) {
        isAfterCutoff =
            sameDayAchSettings.currentFiTime > sameDayAchSettings.processingCutoff.cutOffDateTime;
    } else {
        isAfterCutoff = AlarmClock.getInstance().isAfter('achCutoff');
    }

    const isHoliday = (() => {
        incomingDate.setHours(0, 0, 0, 0);
        return checkHolidays(incomingDate, holidays);
    })();

    if (isHoliday) {
        return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    incomingDate.setHours(0, 0, 0, 0);

    if (incomingDate < today) {
        return false;
    }

    if (today === incomingDate && allowSameDayPayments && isAfterCutoff) {
        return false;
    }

    return true;
};

export const disableAchPaymentValueDates = (
    allowSameDayPayments: boolean,
    holidays: Array<Holiday>,
    cutoffTimes: CutoffTimes
) => ({
    isDisabledStartDate: () => true,
    isDisabledEndDate: () => true,
    isStartDateInvalid: (dateModel: DateModel | Date) =>
        isValidDate(dateModel, allowSameDayPayments, holidays, cutoffTimes),
    isEndDateInvalid: (dateModel: DateModel | Date) =>
        isValidDate(dateModel, allowSameDayPayments, holidays, cutoffTimes),
    dateDisabledFunction: (dateModel: DateModel | Date) =>
        isValidDate(dateModel, allowSameDayPayments, holidays, cutoffTimes),
});
