/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { CreditorModelDto, SearchCreditorsRequestDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class CreditorsClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Creditors
   * @name CreditorsSearch
   * @summary Searches for Creditors.
   * @request POST:/creditors/search
   */
  creditorsSearch = (searchCreditorsRequest: SearchCreditorsRequestDto | null, params: RequestParams = {}) =>
    this.http.request<CreditorModelDto[], any>({
      path: `/creditors/search`,
      method: "POST",
      body: searchCreditorsRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Creditors
   * @name CreditorsPost
   * @summary Creates a Creditor.
   * @request POST:/creditors
   */
  creditorsPost = (creditor: CreditorModelDto, params: RequestParams = {}) =>
    this.http.request<CreditorModelDto, any>({
      path: `/creditors`,
      method: "POST",
      body: creditor,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Creditors
   * @name CreditorsPut
   * @summary Updates a Creditor.
   * @request PUT:/creditors
   */
  creditorsPut = (creditor: CreditorModelDto, params: RequestParams = {}) =>
    this.http.request<CreditorModelDto, any>({
      path: `/creditors`,
      method: "PUT",
      body: creditor,
      format: "json",
      ...params,
    });
}
