import { AUTH_TOKEN, isApiRequest, TmHttpClient } from '@treasury/core/http';

export async function AuthResponseInterceptor<T>(response: ng.IHttpResponse<T>) {
    const authHeader = response.headers(AUTH_TOKEN);
    const apiRequest = await isApiRequest(response.config.url);

    if (apiRequest && authHeader) {
        const http = await TmHttpClient.getInstance();
        http.authToken = authHeader;
    }

    return response;
}
