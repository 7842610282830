import { formatDistance } from 'date-fns/formatDistance';

export const dateDistance = (date: Date, relativeDate: Date) =>
    formatDistance(date, relativeDate) as string;

export function isValidDate(d: Date) {
    return d.toString() !== 'Invalid Date';
}

export function tryParseDate(str: string) {
    const maybeValidDate = new Date(str);
    if (!isValidDate(maybeValidDate)) {
        return undefined;
    }

    return maybeValidDate;
}

export function getDateRange(startDate: Date, endDate: Date) {
    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString());
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}
