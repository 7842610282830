import { AchTemplateService } from '@treasury/domain/channel/services/ach';
import AchConfigurationServices from '@treasury/domain/channel/services/ach/ach-configuration-services.js';
import { AchDomesticServices } from '@treasury/domain/channel/services/ach/ach-domestic-service';
import { AchRecipientsService } from '@treasury/domain/channel/services/ach/ach-recipients-service';
import { DownloadService } from '@treasury/domain/channel/services/download';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';
import {
    AchBatchDetail,
    AchBatchSummary,
    BatchDetails,
    NachaUploadBatchData,
    PaymentHeader,
    PaymentRecipient,
} from '@treasury/domain/channel/types/ach';

export class AchDomesticClient {
    async achBanks() {
        return AchDomesticServices.getAchBanks();
    }

    async onUsAchBanks() {
        return AchDomesticServices.getOnUsAchBanks();
    }

    getAchCompanies() {
        return AchDomesticServices.getAchCompanies();
    }

    async achConfiguration() {
        const configuration: any = await AchConfigurationServices.getConfiguration();
        const entitlements = await EntitlementsService.getEntitlements();
        return {
            ...configuration.achSettings,
            sameDayAchSettings: configuration.sameDayAchSettings,
            companyMatchType: configuration.companyMatchType,
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            allowSameDayPayments: configuration.isSameDayAchEnabled,
            entitlements,
        };
    }

    async createPayment(header: PaymentHeader, recipientsArray: Array<PaymentRecipient>) {
        return AchDomesticServices.createPayment(header, recipientsArray);
    }

    async createBatch(summary: AchBatchSummary, batchDetails: Array<BatchDetails>) {
        return AchDomesticServices.createBatch(summary, batchDetails);
    }

    async createTemplate(...args: any) {
        return AchTemplateService.createTemplate(...args);
    }

    async isTemplateNameValid(name: string) {
        return AchTemplateService.isTemplateNameValid(name);
    }

    async download(...args: any) {
        const [confirmationId, outputType, pageId, filterModel, downloadFormats] = args;
        return DownloadService.download(
            confirmationId,
            outputType,
            pageId,
            filterModel,
            downloadFormats
        );
    }

    async editPayment(header: PaymentHeader, recipientsArray: Array<PaymentRecipient>) {
        return AchDomesticServices.editPayment(header, recipientsArray);
    }

    async editTemplate() {
        return AchDomesticServices.editTemplate();
    }

    async getPayment(paymentId: number) {
        return AchDomesticServices.getAchPayment(paymentId);
    }

    async getPaymentDraft(draftId: number, batchName: string): Promise<[AchBatchDetail]> {
        return AchDomesticServices.getPaymentDraft(draftId, batchName);
    }

    async getPaymentDrafts(
        data: Array<{ draftId: number; batchName: string }>
    ): Promise<AchBatchDetail[]> {
        return AchDomesticServices.getPaymentDrafts(data);
    }

    async print(...args: any) {
        return UserActivityService.print(...args);
    }

    async uploadRecipients(file: File, format: string) {
        return AchRecipientsService.uploadRecipients(file, format);
    }

    async saveMasterRecipient(recipients: Array<PaymentRecipient>) {
        return AchRecipientsService.saveMasterRecipient(recipients);
    }

    async uploadNachaFile(nachaData: File): Promise<NachaUploadBatchData> {
        return AchDomesticServices.uploadNachaFile(nachaData);
    }

    async initiateFromTemplate(list: Array<{ key: string; value: string }>) {
        return AchDomesticServices.initiateFromTemplate(list);
    }
}
