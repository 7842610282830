CreatePaymentMultipleBatchesController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$modal',
    'toaster',
    'modalService',
    'achCompaniesService',
    'achSettingsService',
    'companyAccountsService',
    'achPaymentsService',
    'entitlementsService',
    'holidaysService',
    'securityService',
    'utilityService',
    'downloadPageId',
    'dateConstants',
    'timeZones',
];

export default function CreatePaymentMultipleBatchesController(
    $scope,
    $state,
    $timeout,
    $modal,
    toaster,
    modalService,
    achCompaniesService,
    achSettingsService,
    companyAccountsService,
    achPaymentsService,
    entitlementsService,
    holidaysService,
    securityService,
    utilityService,
    downloadPageId,
    dateConstants,
    timeZones
) {
    $scope.form = {};
    $scope.globalFrequency = {
        show: false,
        summary: '',
    };
    $scope.isReviewing = false;
    $scope.loadRecipients = loadRecipients;
    $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
    $scope.achFull = entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
    $scope.achPartial = entitlementsService.hasEntitlement('ACH, Payment, Partial Edit');
    $scope.isAchPrefundingEntitled = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowUnbalancedPayments'
    );
    $scope.totals = {
        credit: { amount: 0, transactions: 0 },
        debit: { amount: 0, transactions: 0 },
    };
    $scope.frequencyOptions = [
        'One Time',
        'Weekly',
        'Every Two Weeks',
        'Twice a Month',
        'Monthly',
        'Quarterly',
        'Every Six Months',
        'Annually',
    ];
    $scope.nextAchBusinessDate = moment().format('MM/DD/YYYY');
    $scope.endOnDate = moment().format('MM/DD/YYYY');
    $scope.numbers = dateConstants.daysInMonth;
    $scope.weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    $scope.dpOptions = {
        disableDates(date) {
            if (
                $scope.disallowSameDayAch &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };
    $scope.offsetAccounts = [];
    $scope.isFromUpload = $state.params.upload;

    $scope.editBatch = editBatch;
    $scope.dpOnChange = dpOnChange;
    $scope.getEndOnDate = getEndOnDate;
    $scope.setBatchFrequencies = setBatchFrequencies;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.checkRepeatOnDays = checkRepeatOnDays;
    $scope.review = review;
    $scope.goBack = goBack;
    $scope.createPayment = createPayment;
    $scope.cancel = cancel;
    $scope.goToPaymentsList = goToPaymentsList;
    $scope.resetValues = resetValues;
    $scope.resetBatchValues = resetBatchValues;
    $scope.processingCutoff = {};
    $scope.disallowSameDayAch = false;
    $scope.batchOffsetAccounts = [];
    $scope.downloadPageId = downloadPageId.MultipleAchPaymentConfirmation;
    $scope.filterObject = {};
    $scope.wizardStep = 2;
    $scope.validateAllBatches = validateAllBatches;
    $scope.validateBatch = validateBatch;
    $scope.validateFrequency = validateFrequency;
    $scope.applyGlobalFrequency = applyGlobalFrequency;
    $scope.cutoffPassed = false;
    $scope.showField = showField;
    $scope.setPendingApprovalPaymentMessage = setPendingApprovalPaymentMessage;
    $scope.setProcessingPaymentMessage = setProcessingPaymentMessage;
    $scope.setScheduledPaymentMessage = setScheduledPaymentMessage;
    $scope.shouldShowOffsetWarnings = shouldShowOffsetWarnings;
    $scope.getOffsetAccountLabel = getOffsetAccountLabel;
    $scope.shouldShowOffsetReadOnly = shouldShowOffsetReadOnly;
    $scope.shouldShowOffsetLookup = shouldShowOffsetLookup;

    function setPendingApprovalPaymentMessage() {
        return (
            $scope.isConfirmation &&
            $scope.numberOfApprovalsNeeded > 0 &&
            $scope.paymentStatus === 'Pending Approval'
        );
    }
    function setProcessingPaymentMessage() {
        return (
            $scope.isConfirmation &&
            $scope.numberOfApprovalsNeeded === 0 &&
            $scope.paymentStatus === 'Initiated'
        );
    }

    function setScheduledPaymentMessage() {
        return (
            $scope.isConfirmation &&
            $scope.numberOfApprovalsNeeded === 0 &&
            $scope.paymentStatus === 'Scheduled'
        );
    }

    function getAchSettings() {
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function showField(globalFrequency, field, batch) {
        if ($scope.isReviewing || $scope.isConfirmation) {
            if (globalFrequency == null) {
                switch (field) {
                    case 'frequencyLabel':
                        return batch.frequency.type !== 'One Time';
                    case 'effectiveDateLabel':
                        return true;
                    case 'offsetAccountLabel':
                        return batch.achCompany.batchBalanceRequirements !== 'Balanced';
                }
            }
        } else if (!$scope.isReviewing && !$scope.isConfirmation) {
            if (globalFrequency) {
                switch (field) {
                    case 'frequency':
                        return $scope.payment.frequency.type !== 'One Time';
                    case 'effectiveDate':
                        return batch.frequency.type === 'One Time';
                    case 'offsetAccount':
                        return batch.achCompany.batchBalanceRequirements !== 'Balanced';
                }
            } else {
                switch (field) {
                    case 'frequencyOptions':
                        return true;
                    case 'frequencyOneTime':
                        return batch.frequency.type === 'One Time';
                    case 'frequencyRepeatOn':
                        return (
                            batch.frequency.type === 'Weekly' ||
                            batch.frequency.type === 'Every Two Weeks'
                        );
                    case 'frequencyTwicePerMonth':
                        return batch.frequency.type === 'Twice a Month';
                    case 'frequencyMonthly':
                        return batch.frequency.type === 'Monthly';
                    case 'datesForBatch':
                        return batch.frequency.type !== 'One Time';
                    case 'frequencyLabelQuarterly':
                        return batch.frequency.type === 'Quarterly';
                    case 'frequencyLabelSixMonths':
                        return batch.frequency.type === 'Every Six Months';
                    case 'frequencyLabelAnnually':
                        return batch.frequency.type === 'Annually';
                    case 'offsetAccount':
                        return batch.achCompany.batchBalanceRequirements !== 'Balanced';
                }
            }
        }
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('SameDayAch').then(response => {
            $scope.processingCutoff = response;
            const cutOffDiff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            const timeout = response.processingCutoff.allowSameDay === false ? 0 : cutOffDiff;
            $scope.cutoffPassed = cutOffDiff < 0;
            $scope.disallowSameDayAch = response.processingCutoff.allowSameDay === false;
            loadHolidays().then(() => {
                checkTimeout(timeout);
            });
        });
    }

    function checkTimeout(timeout) {
        $timeout(() => {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disallowSameDayAch)
                .then(response => {
                    for (let i = 0; i < $scope.payment.achBatchSummaries.length; i++) {
                        if (
                            moment(
                                $scope.payment.achBatchSummaries[i].frequency.effectiveDate
                            ).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            $scope.payment.achBatchSummaries[i].frequency.effectiveDate =
                                formatDate(response);
                        }
                        if (
                            moment($scope.payment.achBatchSummaries[i].frequency.startOn).format(
                                'L'
                            ) === moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            $scope.payment.achBatchSummaries[i].frequency.startOn =
                                formatDate(response);
                        }
                        if (
                            moment($scope.payment.achBatchSummaries[i].frequency.endOn).format(
                                'L'
                            ) === moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            $scope.payment.achBatchSummaries[i].frequency.endOn =
                                formatDate(response);
                        }
                    }
                    if (
                        moment($scope.payment.frequency.effectiveDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        $scope.payment.frequency.effectiveDate = formatDate(response);
                    }
                    if (
                        moment($scope.payment.frequency.startOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        $scope.payment.frequency.startOn = formatDate(response);
                    }
                    if (
                        moment($scope.payment.frequency.endOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        $scope.payment.frequency.endOn = formatDate(response);
                    }
                });

            if (timeout > 0) {
                toaster.alert('Cutoff Time Passed', 'Cannot create ACH for today.');
            }
        }, timeout);
    }

    function loadRecipients(batch) {
        $modal.open({
            template: require('../views/paymentRecipientsModal.html'),
            size: 'lg',
            controller: 'PaymentRecipientsModalController',
            backdrop: 'static',
            resolve: {
                batch() {
                    return batch;
                },
            },
        });
    }

    function editBatch(batch) {
        $scope.setUploadedBatch(null);
        $state.go('payables.ach.batch-detail', {
            id: batch.id,
            type: 'edit',
            payment: true,
        });
    }

    function resetValues(type) {
        if (type === 'Monthly') {
            $scope.payment.frequency.repeatOnDay1 = null;
        }
    }

    function resetBatchValues(batch) {
        if (batch.frequency.type === 'Monthly') {
            batch.frequency.repeatOnDay1 = null;
        }

        validateBatch(batch);
    }

    function dpOnChange(e) {
        const dpScope = e.sender.$angular_scope;

        $scope.endOnDate = getEndOnDate(
            dpScope.batch.frequency.startOn,
            dpScope.batch.frequency.type
        );
    }

    function getEndOnDate(date, frequencyType) {
        let endOnDate = moment().format('MM/DD/YYYY');

        if (!isNaN(Date.parse(date))) {
            const dt = new Date(date);

            if (frequencyType === 'Weekly') {
                endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if (frequencyType === 'Every Two Weeks') {
                endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (frequencyType === 'Twice a Month' || frequencyType === 'Monthly') {
                endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if (frequencyType === 'Quarterly') {
                endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if (frequencyType === 'Every Six Months') {
                endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if (frequencyType === 'Annually') {
                endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }

        return endOnDate;
    }

    function loadHolidays(timeout) {
        return holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            checkTimeout(timeout);

            utilityService
                .getAchNextEffectiveDate(
                    timeZones[$scope.processingCutoff.fiTimeZone],
                    $scope.holidayDates,
                    $scope.disallowSameDayAch,
                    $scope.cutoffPassed
                )
                .then(nextAchEffectiveDate => {
                    $scope.payment.frequency.effectiveDate = formatDate(nextAchEffectiveDate);
                    $scope.nextAchBusinessDate = formatDate(nextAchEffectiveDate);
                });
        });
    }

    function setBatchFrequencies() {
        if ($scope.globalFrequency.show) {
            angular.forEach($scope.payment.achBatchSummaries, batch => {
                batch.frequency = angular.extend({}, $scope.payment.frequency);
            });
            validateAllBatches();
        }
    }

    function getFrequencySummary(frequency) {
        const errors = validateFrequency(frequency);

        if (errors.length === 0) {
            return achPaymentsService.summarizeFrequency(frequency);
        }
        return '';
    }

    function getAchSettingsAllowUnbalancedPayments() {
        return $scope.achSettings?.allowUnbalancedPayments ?? true;
    }

    async function populateAchCompanies(batchSummaries) {
        const achCompanies = await achCompaniesService.getAll();

        angular.forEach(batchSummaries, summary => {
            const achCompany = achCompanies.find(company => company.id === summary.achCompany.id);
            summary.achCompany.prefundingDays = achCompany.prefundingDays;
            summary.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
            summary.achCompany.allowUnbalancedPayments = achCompany.allowUnbalancedPayments;
        });
    }

    async function getOffsetAccounts(batchSummaries) {
        const achCompanyIds = batchSummaries.map(summary => summary.achCompany.id);

        const uniqueAchCompanyIds = achCompanyIds.filter(
            (value, index, self) => self.indexOf(value) === index
        );

        await populateAchCompanies(batchSummaries);

        angular.forEach(uniqueAchCompanyIds, id => {
            achCompaniesService.getOffsetAccounts(id).then(response => {
                angular.forEach(batchSummaries, summary => {
                    if (summary.achCompany.id === id) {
                        summary.offsetAccounts = response;
                        if (summary.offsetAccount) {
                            if (summary.offsetAccount.id === 0) {
                                summary.offsetAccount = null;
                            } else {
                                for (let i = 0; i < summary.offsetAccounts.length; i++) {
                                    if (summary.offsetAccounts[i].id === summary.offsetAccount.id) {
                                        summary.offsetAccount = summary.offsetAccounts[i];
                                        break;
                                    }
                                }
                            }
                        }
                    }
                });
            });
        });
    }

    function checkRepeatOnDays(form, batch) {
        if (!$scope.payment.frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.payment.frequency.repeatOnDay1 < $scope.payment.frequency.repeatOnDay2 ||
                $scope.payment.frequency.repeatOnDay2 === '';

            const isValid2 =
                $scope.payment.frequency.repeatOnDay2 > $scope.payment.frequency.repeatOnDay1 ||
                $scope.payment.frequency.repeatOnDay1 === '';

            if (
                $scope.payment.frequency.repeatOnDay1 &&
                $scope.payment.frequency.repeatOnDay2 &&
                $scope.payment.frequency.repeatOnDay2 !== ''
            ) {
                form.repeatOnMonthDay1.$setValidity('doesNotMatch', isValid1);
                form.repeatOnMonthDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
        if (batch) {
            validateBatch(batch);
        }
    }

    function review() {
        $scope.isReviewing = true;
        $scope.isConfirmation = false;
        $timeout(() => {
            $('#overflowed-panel')[0].scrollTop = 0;
        }, 250);
        $scope.wizardStep = 3;
    }

    function goBack() {
        if ($scope.isReviewing) {
            $scope.isReviewing = false;
        }
        $scope.wizardStep = 2;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    $modalInstance.close();
                    $state.go('payables.ach.payment-list');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            goToPaymentsList();
        }
    }

    function goToPaymentsList() {
        $state.go('payables.ach.payment-list');
    }

    function createPayment() {
        let hasErrors = false;
        securityService
            .verifyUser('Initiate Payment From Batch', $scope.payment, () =>
                achPaymentsService.create($scope.payment)
            )
            .then(response => {
                $scope.isReviewing = false;
                $scope.isConfirmation = true;
                $scope.filterObject.AchPaymentIds = [];
                $timeout(() => {
                    $('#overflowed-panel')[0].scrollTop = 0;
                }, 250);
                angular.forEach(response.payment.achBatchSummaries, payment => {
                    $scope.filterObject.AchPaymentIds.push(payment.id);
                    angular.forEach($scope.payment.achBatchSummaries, batch => {
                        if (
                            payment.batchUniqueId === batch.batchUniqueId &&
                            payment.name === batch.name &&
                            payment.creditAmount === batch.creditAmount &&
                            payment.debitAmount === batch.debitAmount
                        ) {
                            payment.recipients = batch.recipients;
                            payment.frequency = batch.frequency;
                            payment.offsetAccount = batch.offsetAccount;
                        }
                    });

                    if (!payment.isValid && payment.errorSummary) {
                        hasErrors = true;
                        payment.errors = payment.errorSummary.summaryMessageList;
                        $scope.hasBatchErrors = payment.errorSummary.summaryMessageList.length > 0;
                    }
                });
                $scope.numberOfApprovalsNeeded =
                    response.payment.achBatchSummaries[0].numberOfApprovalsNeeded;
                $scope.payment = response.payment;
                $scope.paymentStatus = response.payment.achBatchSummaries[0].status;
                $scope.wizardStep = 4;

                if (!hasErrors) {
                    toaster.save('ACH Payments');
                }
            });
    }

    function validateOffsetAccount(batch) {
        const errors = [];
        if (batch.achCompany.batchBalanceRequirements !== 'Balanced') {
            let prefundingCheck = true;
            if (
                $scope.isAchPrefundingEntitled &&
                (batch.achCompany.prefundingDays > 0 || batch.achCompany.allowUnbalancedPayments)
            ) {
                prefundingCheck = false;
            }
            if (prefundingCheck && (!batch.offsetAccount || batch.offsetAccount.value === '')) {
                errors.push('Please select an offset account.');
            }
        }

        return errors;
    }
    function validateFrequency(frequency) {
        const { type } = frequency;

        const errors = [];

        if (type === 'One Time') {
            if (
                !frequency.effectiveDate ||
                frequency.effectiveDate === '' ||
                frequency.effectiveDate === '0001-01-01T00:00:00'
            ) {
                errors.push('Please enter a valid Effective Date.');
            }
        } else {
            if (
                !frequency.startOn ||
                frequency.startOn === '' ||
                frequency.startOn === '0001-01-01T00:00:00'
            ) {
                errors.push('Please enter a valid Start On date.');
            }

            if (
                !frequency.noEndDate &&
                (!frequency.endOn ||
                    frequency.endOn === '' ||
                    frequency.endOn === '0001-01-01T00:00:00')
            ) {
                errors.push('Please enter a valid End On date.');
            }

            if (type === 'Weekly' || type === 'Every Two Weeks') {
                if (!frequency.repeatOn || frequency.repeatOn === '') {
                    errors.push('Please enter a valid Repeat On day.');
                }
            } else if (type === 'Twice a Month') {
                if (!frequency.repeatOnDay1 || frequency.repeatOnDay1 === '') {
                    errors.push('Please enter a valid first Repeat On day.');
                }
                if (
                    !frequency.repeatOnLastBusinessDay &&
                    (!frequency.repeatOnDay2 || frequency.repeatOnDay2 === '')
                ) {
                    errors.push('Please enter a valid second Repeat On day.');
                }
            } else if (type === 'Monthly') {
                if (
                    !frequency.repeatOnLastBusinessDay &&
                    (!frequency.repeatOnDay1 || frequency.repeatOnDay1 === '')
                ) {
                    errors.push('Please enter a valid Monthly Repeat On day.');
                }
            }
        }

        return errors;
    }

    function validateAllBatches() {
        // if the global frequencies are used, no need to validate these over and over.
        // just validate it once and push the results to each batch.
        let globalFrequencyErrors = [];
        if ($scope.globalFrequency.show) {
            globalFrequencyErrors = validateFrequency($scope.payment.frequency);
        }

        angular.forEach($scope.payment.achBatchSummaries, batch => {
            let errors = [];
            if ($scope.globalFrequency.show) {
                errors = errors.concat(globalFrequencyErrors);
            } else {
                errors = errors.concat(validateFrequency(batch.frequency));
            }

            const offsetErrors = validateOffsetAccount(batch);

            errors = errors.concat(offsetErrors);
            batch.errors = errors;
            batch.isValid = batch.errors.length === 0;
        });
    }

    function validateBatch(batch) {
        let errors = validateFrequency(batch.frequency);
        const offsetErrors = validateOffsetAccount(batch);

        errors = errors.concat(offsetErrors);

        batch.errors = errors;
        batch.isValid = batch.errors.length === 0;
    }

    function applyGlobalFrequency() {
        if ($scope.payment.frequency) {
            // set the frequency of each batch to what is set in the global frequency object
            setBatchFrequencies();
            $scope.endOnDate = getEndOnDate(
                $scope.payment.frequency.startOn,
                $scope.payment.frequency.type
            );

            $timeout(() => {
                $scope.$apply();
            });
        }
    }

    function shouldShowOffsetWarnings(batch) {
        if (!$scope.isAchPrefundingEntitled) return true;
        return batch.achCompany.prefundingDays <= 0;
    }

    function getOffsetAccountLabel(batch) {
        if (
            ($scope.isAchPrefundingEntitled &&
                batch.achCompany.prefundingDays > 0 &&
                !isDebitTransaction(batch)) ||
            (batch.achCompany.allowUnbalancedPayments && getAchSettingsAllowUnbalancedPayments())
        ) {
            return batch.achCompany.offsetAccountNumber;
        }
        return batch.offsetAccount.accountDisplayLabel;
    }

    function isDebitTransaction(batch) {
        return batch.recipients.some(recipient => recipient.transactionType === 'DR');
    }

    function shouldShowOffsetReadOnly(batch) {
        if (!$scope.isAchPrefundingEntitled) return false;
        return (
            (batch.achCompany.prefundingDays > 0 && !isDebitTransaction(batch)) ||
            (batch.achCompany.allowUnbalancedPayments && getAchSettingsAllowUnbalancedPayments())
        );
    }

    function shouldShowOffsetLookup(batch) {
        if (!$scope.isAchPrefundingEntitled) return true;
        return (
            (batch.achCompany.prefundingDays <= 0 || isDebitTransaction(batch)) &&
            (!batch.achCompany.allowUnbalancedPayments || !getAchSettingsAllowUnbalancedPayments())
        );
    }

    (async function () {
        // init
        $scope.payment.frequency = {
            effectiveDate: moment(new Date()).format('MM/DD/YYYY'),
            type: 'One Time',
        };
        loadCutoffTimes();
        getAchSettings();
        await getOffsetAccounts($scope.payment.achBatchSummaries);

        $scope.totals.credit.amount = $scope.payment.totalCreditAmount;
        $scope.totals.debit.amount = $scope.payment.totalDebitAmount;
        $scope.totals.credit.transactions = $scope.payment.totalCreditTransactions;
        $scope.totals.debit.transactions = $scope.payment.totalDebitTransactions;

        angular.forEach($scope.payment.achBatchSummaries, batch => {
            // set frequency type if not already set
            if (!batch.frequency) {
                batch.frequency = {};
                batch.frequency.type = $scope.payment.frequency.type;
                batch.frequency.effectiveDate = $scope.payment.frequency.effectiveDate;
                batch.frequency.startOn = null;
                batch.frequency.endOn = null;
            }
        });

        validateAllBatches();
    })();
}
