import { JhHttpResponseType } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';

const baseEndpoint = `activity/`;

// eslint-disable-next-line @treasury/filename-match-export
export default class UserActivityRequests {
    static async print(activityId, additionalInformation) {
        return (await TmHttpClient.getInstance()).request('print/useractivityaudit', {
            method: 'POST',
            body: { printId: activityId, AdditionalAuditInformation: additionalInformation },
        });
    }

    static async navigationAudit(toState, fromState) {
        return (await TmHttpClient.getInstance()).request(`${baseEndpoint}navigationAudit`, {
            method: 'POST',
            body: { toState, fromState },
        });
    }

    static async userInteractionAudit(resourceType, auditCode) {
        return (await TmHttpClient.getInstance()).request(
            `${baseEndpoint}userInteractionAudit?resourceType=${resourceType}&auditCode=${auditCode}`,
            {
                method: 'GET',
                responseType: JhHttpResponseType.Text,
            }
        );
    }

    static async saveUserActivity(userActivityType, activityMessage, relatedId, activityDate) {
        return (await TmHttpClient.getInstance()).request(`users/saveuseractivity`, {
            method: 'POST',
            body: { userActivityType, activityMessage, relatedId, activityDate },
        });
    }
}
