/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AchChildSupportTemplateListDownloadModelDto,
  AchInternationalTemplateListDownloadModelDto,
  AchPaymentFilterModelDto,
  AchPaymentSearchResponseDto,
  AchTemplateApprovalModelDto,
  AchTemplateDownloadModelDto,
  AchTemplateRejectModelDto,
  ApproveAchTemplateResponseDto,
  CreateAchChildSupportTemplateRequestDto,
  CreateAchInternationalTemplateRequestDto,
  CreateAchTemplateResponseDto,
  DeleteAchTemplateResponseDto,
  ErrorHttpContentDto,
  GetAchChildSupportTemplateResponseDto,
  GetAchInternationalTemplateResponseDto,
  GetAchNotificationOfChangeDownloadRequestDto,
  GetAchNotificationOfChangeResponseDto,
  GetAchReturnDownloadRequestDto,
  GetAchReturnResponseDto,
  GetAchTemplateApproversResponseDto,
  RejectAchTemplateResponseDto,
  SearchAchChildSupportTemplateRequestDto,
  SearchAchInternationalTemplateRequestDto,
  SearchAchInternationalTemplateResponseDto,
  SearchAchNotificationOfChangeDownloadRequestDto,
  SearchAchNotificationOfChangeRequestDto,
  SearchAchNotificationOfChangeResponseDto,
  SearchAchReturnDownloadRequestDto,
  SearchAchReturnRequestDto,
  SearchAchReturnResponseDto,
  SearchAchTemplateListResponseDto,
  UpdateAchChildSupportTemplateRequestDto,
  UpdateAchInternationalTemplateRequestDto,
  UpdateAchTemplateResponseDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * @description Request example: ```json { "EffectiveDateFilterOption": 2, "EffectiveDateStart": "2022-01-01", "EffectiveDateEnd": "2022-01-28", "ReceivedDateFilterOption": 2, "ReceivedDateStart": "2022-01-01", "ReceivedDateEnd": "2022-01-28", "AchCompanies": [ "76b28ab2-c521-4303-9517-85bbfa83a95c", "c3c645a5-e3c1-4e76-84b7-f4df4645fd69" ], "RecipientName": "Thor", "PagingParameters": { "PageSize": 25, "PageNumber": 1, "OrderBys": [ { "Name": "RecipientName", "Descending": true } ] } } ``` Response example: ```json { "pageSize": 1, "maxPageSize": 1, "pageNumber": 1, "totalCount": 1, "recordCountInCurrentPage": 1, "notificationOfChanges": [ { "achNocTransactionUniqueId": "c3c645a5-e3c1-4e76-84b7-f4df4645fd69", "effectiveDate": "2022-01-01T00:00:00", "receivedDate": "2022-01-01T00:00:00", "achCompanyName": "Mock Company", "companyEntryDescription": "Mock Company Desc", "secCode": { "original": "PPD", "updated": "CCD" }, "recipientName": { "original": "Mock Smith", "updated": "John Mock" }, "recipientFinancialInstitution": { "original": "12345", "updated": "54321" }, "accountNumber": { "original": "12345", "updated": "54321" }, "accountType": { "original": "Checking", "updated": "Savings" }, "identificationNumber": { "original": "12345", "updated": "54321" }, "traceNumber": { "original": "12345", "updated": "54321" }, "changeCode": "C01 - Mock Change Code", } ], "successLevel": 1, "responseDetailCollection": [] } ```
   *
   * @tags AchNotificationOfChange
   * @name AchNotificationOfChangeSearchAchNotificationOfChange
   * @summary Retrieves a collection of ACH notification of change transaction items based on the search criteria.
   * @request POST:/ach/noc/search
   */
  achNotificationOfChangeSearchAchNotificationOfChange = (
    request: SearchAchNotificationOfChangeRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<SearchAchNotificationOfChangeResponseDto, ErrorHttpContentDto>({
      path: `/ach/noc/search`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Request example: No json body. Response example: ```json { "achNocTransactionUniqueId": "c3c645a5-e3c1-4e76-84b7-f4df4645fd69", "effectiveDate": "2022-01-01T00:00:00", "receivedDate": "2022-01-01T00:00:00", "achCompanyName": "Mock Company", "companyEntryDescription": "Mock Company Desc", "secCode": { "original": "PPD", "updated": "CCD" }, "recipientName": { "original": "Mock Smith", "updated": "John Mock" }, "recipientFinancialInstitution": { "original": "12345", "updated": "54321" }, "accountNumber": { "original": "12345", "updated": "54321" }, "accountType": { "original": "Checking", "updated": "Savings" }, "identificationNumber": { "original": "12345", "updated": "54321" }, "traceNumber": { "original": "12345", "updated": "54321" }, "changeCode": "C01 - Mock Change Code", "successLevel": 1, "responseDetailCollection": [] } ```
   *
   * @tags AchNotificationOfChange
   * @name AchNotificationOfChangeGetAchNotificationOfChange
   * @summary Retrieves an ACH notification of change transaction detail.
   * @request GET:/ach/noc/{achNocTransactionUniqueId}/detail
   */
  achNotificationOfChangeGetAchNotificationOfChange = (achNocTransactionUniqueId: string, params: RequestParams = {}) =>
    this.http.request<GetAchNotificationOfChangeResponseDto, ErrorHttpContentDto>({
      path: `/ach/noc/${achNocTransactionUniqueId}/detail`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Example request: { "OutPutType": 1 // 1 - pdf, 2 - csv, 7 - nacha }
   *
   * @tags AchNotificationOfChange
   * @name AchNotificationOfChangeGetAchDownload
   * @summary Retrieves a file for a single ACH notification of change transaction.
   * @request POST:/ach/noc/{nocTransactionUniqueId}/download
   */
  achNotificationOfChangeGetAchDownload = (
    nocTransactionUniqueId: string,
    request: GetAchNotificationOfChangeDownloadRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/noc/${nocTransactionUniqueId}/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Example request: { "InformationType": "summary", // summary, detail, nacha "OutPutType": 1, // 1 - pdf, 2 - csv, 7 - nacha "filter": { "effectiveDateFilterOption": 2, "effectiveDateStart": "2022-03-01", "effectiveDateEnd": "2022-03-04", "receivedDateFilterOption": 2, // 0 - undefined, 1 - specific date, 2 - range "receivedDateStart": "2022-03-01", "receivedDateEnd": "2022-03-04", "achCompanies": [ "{{$guid}}" ], "recipientName": "Michael" }, "pagingParameters": { "orderBys": [ { "name": "ReceivedDate", "descending": true } ] } }
   *
   * @tags AchNotificationOfChange
   * @name AchNotificationOfChangeSearchAchNotificationOfChangeDownload
   * @summary Retrieves a collection of ACH notification of change transaction items based on the search request.
   * @request POST:/ach/noc/search/download
   */
  achNotificationOfChangeSearchAchNotificationOfChangeDownload = (
    request: SearchAchNotificationOfChangeDownloadRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/noc/search/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Request example: ```json { "EffectiveDateFilterOption": 2, "EffectiveDateStart": "2022-01-01", "EffectiveDateEnd": "2022-01-27", "ReceivedDateFilterOption": 2, "ReceivedDateStart": "2022-01-01", "ReceivedDateEnd": "2022-01-27", "AchCompanies": [ "ed2bb1f6-63fe-457f-b35f-4a9f135325d7", "8ef733a1-f18a-4580-a9bf-af8f3e756b66" ], "RecipientName": "Thor", "PagingParameters": { "PageSize": 25, "PageNumber": 1, "OrderBys": [ { "Name": "RecipientName", "Descending": true } ] } } ``` Response example: ```json { "pageSize": 100, "maxPageSize": 100, "pageNumber": 1, "totalCount": 1, "recordCountInCurrentPage": 1, "returns": [ { "achCompanyId": "1234567", "achReturnTransactionUniqueId": "76b28ab2-c521-4303-9517-85bbfa83a95c", "originalEffectiveDate": "2022-01-01T00:00:00", "receivedDate": "2022-01-01T00:00:00", "achCompanyName": "MATTHEW 2", "secCode": "PPD", "AchCompanyDiscretionaryData": "PAYROLL", "AchCompanyEntryDescription": "Weekly", "recipientName": "Mock Smith", "recipientFinancialInstitution": "01100053", "accountNumber": "83795621", "accountType": "Checking", "amount": 3.67, "identificationNumber": "021306547000001", "traceNumber": "123456789", "returnReason": "R02 - Description", "dateOfDeath": "2022-01-01T00:00:00", } ], "successLevel": 1, "responseDetailCollection": [] } ```
   *
   * @tags AchReturns
   * @name AchReturnsSearchAchReturns
   * @summary Retrieves a collection of ACH return transaction items based on the search criteria.
   * @request POST:/ach/return/search
   */
  achReturnsSearchAchReturns = (request: SearchAchReturnRequestDto | null, params: RequestParams = {}) =>
    this.http.request<SearchAchReturnResponseDto, ErrorHttpContentDto>({
      path: `/ach/return/search`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Request example: No json body. Response example: ```json { "achCompanyId": "1234567", "achReturnTransactionUniqueId": "76b28ab2-c521-4303-9517-85bbfa83a95c", "originalEffectiveDate": "2022-01-01T00:00:00", "receivedDate": "2022-01-01T00:00:00", "achCompanyName": "MATTHEW 2", "secCode": "PPD", "AchCompanyDiscretionaryData": "PAYROLL", "AchCompanyEntryDescription": "Weekly", "recipientName": "Mock Smith", "recipientFinancialInstitution": "01100053", "accountNumber": "83795621", "accountType": "Checking", "amount": 3.67, "identificationNumber": "021306547000001", "traceNumber": "123456789", "returnReason": "R02 - Description", "dateOfDeath": "2022-01-01T00:00:00", "successLevel": 1, "responseDetailCollection": [] } ```
   *
   * @tags AchReturns
   * @name AchReturnsAchReturnDetail
   * @summary Retrieves an ACH return detail.
   * @request GET:/ach/return/{achReturnTransactionUniqueId}/detail
   */
  achReturnsAchReturnDetail = (achReturnTransactionUniqueId: string, params: RequestParams = {}) =>
    this.http.request<GetAchReturnResponseDto, ErrorHttpContentDto>({
      path: `/ach/return/${achReturnTransactionUniqueId}/detail`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Example request: { "OutPutType": 7 // 1 - pdf, 2 - csv, 7 - nacha }
   *
   * @tags AchReturns
   * @name AchReturnsGetAchReturnDownload
   * @summary Retrieves a file for a single ACH return based on the parameters in the request.
   * @request POST:/ach/return/{achReturnTransactionUniqueId}/download
   */
  achReturnsGetAchReturnDownload = (
    achReturnTransactionUniqueId: string,
    request: GetAchReturnDownloadRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/return/${achReturnTransactionUniqueId}/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Example request: { "InformationType": "summary", // summary, detail, nacha "OutPutType": 1, // 1 - pdf, 2 - csv, 7 - nacha "filter": { "effectiveDateFilterOption": 2, "effectiveDateStart": "2022-03-01", "effectiveDateEnd": "2022-03-04", "receivedDateFilterOption": 2, // 0 - undefined, 1 - specific date, 2 - range "receivedDateStart": "2022-03-01", "receivedDateEnd": "2022-03-04", "achCompanies": [ "{{$guid}}" ], "recipientName": "Michael" }, "pagingParameters": { "orderBys": [ { "name": "ReceivedDate", "descending": true } ] } }
   *
   * @tags AchReturns
   * @name AchReturnsSearchAchReturnDownload
   * @summary Returns the ach return download file.
   * @request POST:/ach/return/search/download
   */
  achReturnsSearchAchReturnDownload = (request: SearchAchReturnDownloadRequestDto | null, params: RequestParams = {}) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/return/search/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateGetChildSupport
   * @summary Get an ACH child support template with GUID id.
   * @request GET:/ach/template/childsupport/{id}
   */
  achTemplateGetChildSupport = (id: string, params: RequestParams = {}) =>
    this.http.request<GetAchChildSupportTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/childsupport/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDeleteChildSupport
   * @summary Delete an ACH child support template with GUID id.
   * @request DELETE:/ach/template/childsupport/{id}
   */
  achTemplateDeleteChildSupport = (id: string, params: RequestParams = {}) =>
    this.http.request<DeleteAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/childsupport/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateCreateChildSupport
   * @summary Create an ACH child support template.
   * @request POST:/ach/template/childsupport
   */
  achTemplateCreateChildSupport = (
    request: CreateAchChildSupportTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<CreateAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/childsupport`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateUpdateChildSupport
   * @summary Update an ACH child support template.
   * @request PUT:/ach/template/childsupport
   */
  achTemplateUpdateChildSupport = (
    request: UpdateAchChildSupportTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/childsupport`,
      method: "PUT",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateSearchChildSupport
   * @summary Search for ACH child support templates.
   * @request POST:/ach/templates/childsupport/search
   */
  achTemplateSearchChildSupport = (
    request: SearchAchChildSupportTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<SearchAchTemplateListResponseDto, ErrorHttpContentDto>({
      path: `/ach/templates/childsupport/search`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDownloadChildSupportTemplateDetails
   * @summary Retrieves a single ACH child support template report.
   * @request POST:/ach/template/childsupport/download
   */
  achTemplateDownloadChildSupportTemplateDetails = (request: AchTemplateDownloadModelDto, params: RequestParams = {}) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/template/childsupport/download`,
      method: "POST",
      body: request,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDownloadChildSupportTemplateListing
   * @summary Download the ACH child support template list.
   * @request POST:/ach/templates/childsupport/search/download
   */
  achTemplateDownloadChildSupportTemplateListing = (
    request: AchChildSupportTemplateListDownloadModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<SearchAchTemplateListResponseDto, ErrorHttpContentDto>({
      path: `/ach/templates/childsupport/search/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateGetInternational
   * @summary Get an international ACH template with GUID id.
   * @request GET:/ach/template/international/{id}
   */
  achTemplateGetInternational = (id: string, params: RequestParams = {}) =>
    this.http.request<GetAchInternationalTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDeleteInternational
   * @summary Delete an international ACH template with GUID id.
   * @request DELETE:/ach/template/international/{id}
   */
  achTemplateDeleteInternational = (id: string, params: RequestParams = {}) =>
    this.http.request<DeleteAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateCreateInternational
   * @summary Create an international ACH template.
   * @request POST:/ach/template/international
   */
  achTemplateCreateInternational = (
    request: CreateAchInternationalTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<CreateAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateUpdateInternational
   * @summary Update an international ACH template.
   * @request PUT:/ach/template/international
   */
  achTemplateUpdateInternational = (
    request: UpdateAchInternationalTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international`,
      method: "PUT",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateSearchInternational
   * @summary Search for international ACH templates.
   * @request POST:/ach/templates/international/search
   */
  achTemplateSearchInternational = (
    request: SearchAchInternationalTemplateRequestDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<SearchAchInternationalTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/templates/international/search`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDownloadInternationalTemplateDetails
   * @summary Retrieves a single international ACH template report.
   * @request POST:/ach/template/international/download
   */
  achTemplateDownloadInternationalTemplateDetails = (
    request: AchTemplateDownloadModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/template/international/download`,
      method: "POST",
      body: request,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateDownloadInternationalTemplateListing
   * @summary Download the international ACH template list.
   * @request POST:/ach/templates/international/search/download
   */
  achTemplateDownloadInternationalTemplateListing = (
    request: AchInternationalTemplateListDownloadModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<File, ErrorHttpContentDto>({
      path: `/ach/templates/international/search/download`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateApproveInternational
   * @summary Approve an international ACH template with GUID id.
   * @request PUT:/ach/template/international/approve
   */
  achTemplateApproveInternational = (
    achTemplateApprovalModel: AchTemplateApprovalModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ApproveAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international/approve`,
      method: "PUT",
      body: achTemplateApprovalModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchTemplate
   * @name AchTemplateRejectInternational
   * @summary Reject an international ACH template with GUID id.
   * @request PUT:/ach/template/international/reject
   */
  achTemplateRejectInternational = (
    achTemplateRejectModel: AchTemplateRejectModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<RejectAchTemplateResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international/reject`,
      method: "PUT",
      body: achTemplateRejectModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags AchTemplate
 * @name AchTemplateGetInternationalTemplateApprovers
 * @summary Gets a collection of user(s) who approved an international ACH template and a collection of user(s) who are
available to approve the international ACH template.
 * @request GET:/ach/template/international/getapprovers/{id}
 */
  achTemplateGetInternationalTemplateApprovers = (id: string, params: RequestParams = {}) =>
    this.http.request<GetAchTemplateApproversResponseDto, ErrorHttpContentDto>({
      path: `/ach/template/international/getapprovers/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchPayments
   * @name AchPaymentsSearchAchPayments
   * @summary Provides an endpoint to search for ACH Payments. This endpoint returns the totals grouped by status.
   * @request POST:/ach/payments/search
   */
  achPaymentsSearchAchPayments = (
    query: {
      /**
       * Type: OneTime, Recurring.
       *
       */
      type: string | null;
    },
    achPaymentFilter: AchPaymentFilterModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<AchPaymentSearchResponseDto, ErrorHttpContentDto>({
      path: `/ach/payments/search`,
      method: "POST",
      query: query,
      body: achPaymentFilter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
