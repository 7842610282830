import { clone } from '@jack-henry/frontend-utils/functions';
import { IntermediaryBankModelDto } from '@treasury/api/channel';
import { nullIntermediaryBankModel } from '../channel/types';
import { DomainEntity } from '../shared';

export class IntermediaryBank extends DomainEntity<IntermediaryBankModelDto> {
    constructor(dto = clone(nullIntermediaryBankModel)) {
        super(dto);
    }

    public createDefault(): this {
        return new IntermediaryBank(this.dto) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get updatedDate() {
        return this.dto.updatedDate;
    }

    public get addressId() {
        return this.dto.addressId;
    }

    public get accountNumber() {
        return this.dto.accountNumber;
    }

    public set accountNumber(accountNumber) {
        this.dto.accountNumber = accountNumber;
    }

    public get isDeleted() {
        return this.dto.isDeleted;
    }

    public get isInternational() {
        return this.dto.isInternational;
    }

    public get onUs() {
        return this.dto.onUs;
    }

    public get bankId() {
        return this.dto.bankId;
    }

    public set bankId(bankId) {
        this.dto.bankId = bankId;
    }

    public get name() {
        return this.dto.name || '';
    }

    public set name(name) {
        this.dto.name = name;
    }

    public get city() {
        return this.dto.city;
    }

    public set city(city) {
        this.dto.city = city;
    }

    public get state() {
        return this.dto.state;
    }

    public set state(state) {
        this.dto.state = state;
    }

    public get country() {
        return this.dto.country;
    }

    public set country(country) {
        this.dto.country = country;
    }

    public get notes() {
        return this.dto.notes;
    }

    public set notes(notes) {
        this.dto.notes = notes;
    }
}
