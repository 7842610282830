import { format } from 'date-fns';

export function filterGrid(filterText, keysToFilter) {
    const searchLower = filterText.trim().toLowerCase();
    return item => {
        for (const key of keysToFilter) {
            if (typeof item[key] === 'string' || typeof item[key] === 'number') {
                const formattedValue = formatValueBasedOnType(item[key]);
                if (formattedValue.indexOf(searchLower) !== -1) {
                    return true;
                }
            }
        }
        return false;
    };
}

function isDate(value) {
    if (!value.includes('-') && !value.includes('/')) {
        return false;
    }
    const potentialDate = new Date(value);
    return potentialDate instanceof Date && !isNaN(potentialDate);
}

function formatString(value) {
    if (isDate(value)) {
        return format(new Date(value), 'MM/dd/yyyy');
    }
    return value.toLowerCase();
}

function formatValueBasedOnType(value) {
    if (typeof value === 'number') {
        return parseFloat(value).toFixed(2);
    }
    if (typeof value === 'string') {
        return formatString(value);
    }
    return value.toString().toLowerCase();
}
