import { clone } from '@jack-henry/frontend-utils/functions';
import { CompanyAccountModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { nullWireAccount } from './wire-account.dto';

export class WireAccount extends DomainEntity<CompanyAccountModelDto> {
    constructor(dto = clone(nullWireAccount)) {
        super(dto);
    }

    public createDefault(): this {
        return new WireAccount(clone(nullWireAccount)) as this;
    }

    public get name() {
        return this.dto.name || '';
    }

    public get number() {
        return this.dto.number || '';
    }

    public get id() {
        return this.dto.id;
    }

    public get accountDisplayLabel() {
        return this.dto.accountDisplayLabel || '';
    }

    public get availableBalance() {
        return this.dto.availableBalance || '';
    }
}
