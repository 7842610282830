import { css, html, LitElement } from 'lit';
import './icons/omegaIconChevronDownOutline.js';

/**
 * Built with the thought that we could extend to an accordion group - as of now it appears as though we only need
 * stand-alone accordions.
 */
class OmegaAccordion extends LitElement {
    static get properties() {
        return {
            expanded: { type: Boolean, reflect: true },
            tabs: Array,
            panels: Array,
        };
    }

    constructor() {
        super();
        this.expanded = true;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-accordion--accordion',
        };
    }

    firstUpdated() {
        this.tabs = this.shadowRoot.querySelectorAll('dt');
        this.panels = this.shadowRoot.querySelectorAll('dd');
    }

    getPanel() {
        return this.shadowRoot.querySelector('#accordion-panel');
    }

    select(tab) {
        this.deselectAll();
        tab.setAttribute('aria-selected', 'true');
        tab.setAttribute('tabindex', '0');
        tab.focus();
    }

    deselectAll() {
        this.tabs.forEach(tab => {
            tab.setAttribute('aria-selected', 'false');
            tab.setAttribute('tabindex', '-1');
        });
    }

    show(tab) {
        this.getPanel(tab).setAttribute('aria-hidden', 'false');
        tab.setAttribute('aria-expanded', 'true');
        this.expanded = true;
    }

    hide(tab) {
        this.getPanel(tab).setAttribute('aria-hidden', 'true');
        tab.setAttribute('aria-expanded', 'false');
        this.expanded = false;
    }

    hideAll() {
        this.tabs.forEach(tab => {
            this.hide(tab);
        }, this);
    }

    getNextTab(tab) {
        const next = tab.nextSibling;
        if (next === null) {
            return this.tabs[0];
        }
        if (next.tagName === 'DT') {
            return next;
        }
        return this.getNextTab(next);
    }

    getPrevTab(tab) {
        const next = tab.previousSibling;
        if (next === null) {
            return this.tabs[this.tabs.length - 1];
        }
        if (next.tagName === 'DT') {
            return next;
        }
        return this.getPrevTab(next);
    }

    onTabClick(e) {
        const showTab = e.target.getAttribute('aria-expanded') !== 'true';
        this.hideAll();
        if (showTab) {
            this.select(e.target);
            this.show(e.target);
        }
    }

    onTabKeydown(e) {
        const showTab = e.target.getAttribute('aria-expanded') !== 'true';
        switch (e.which) {
            case 13: // ENTER
                e.preventDefault();
                e.stopPropagation();
                this.hideAll();
                if (showTab) {
                    this.show(e.target);
                }
                break;
            case 37: // LEFT
            case 38: // UP
                e.preventDefault();
                e.stopPropagation();
                this.select(this.getPrevTab(e.target));
                break;
            case 39: // RIGHT
            case 40: // DOWN
                e.preventDefault();
                e.stopPropagation();
                this.select(this.getNextTab(e.target));
                break;
            case 35: // END
                e.preventDefault();
                e.stopPropagation();
                this.select(this.tabs[this.tabs.length - 1]);
                break;
            case 36: // HOME
                e.preventDefault();
                e.stopPropagation();
                this.select(this.tabs[0]);
                break;
            default:
                break;
        }
    }

    onPanelKeydown(e) {
        if (e.ctrlKey || e.metaKey) {
            switch (e.which) {
                case 38: // UP
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.currentTarget) {
                        this.select(this.getPrevTab(e.currentTarget));
                    }
                    break;
                case 40: // PAGE DOWN
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.currentTarget) {
                        this.select(this.getNextTab(e.currentTarget));
                    }
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return html`<section>
            <dl class="accordion" role="tablist">
                <dt
                    id="accordion-tab"
                    role="tab"
                    aria-controls="accordion-tab"
                    aria-expanded=${this.expanded ? 'true' : 'false'}
                    aria-selected="false"
                    tabindex="0"
                    @click=${this.onTabClick}
                    @keydown=${e => this.onTabKeydown(e)}
                >
                    <slot name="header"></slot>
                    <omega-icon-chevron-down-outline></omega-icon-chevron-down-outline>
                </dt>
                <dd
                    id="accordion-panel"
                    aria-hidden=${this.expanded ? 'false' : 'true'}
                    aria-labelledby="accordion-tab"
                    role="tabpanel"
                    @keydown=${e => this.onPanelKeydown(e)}
                >
                    <slot name="content"></slot>
                </dd>
            </dl>
        </section>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .accordion dt {
                font-weight: 500;
                margin: 0;
                border: 1px solid rgb(221, 221, 221);
            }

            .accordion dd {
                margin: 0;
                border: 1px solid rgb(221, 221, 221);
                border-top: none;
            }

            .accordion[role='tablist'] dt {
                position: relative;
                font-size: 14px;
                color: rgb(84, 84, 84);
                background-color: rgb(234, 234, 234);
                padding: 8px 15px;
                user-select: none;
                cursor: pointer;
                transition:
                    background-color 0.25s,
                    color 0.25s;
            }

            .accordion[role='tablist'] dt omega-icon-chevron-down-outline {
                width: 26px;
                height: 26px;
                position: absolute;
                right: 10px;
                top: 4px;
                fill: rgb(130, 130, 130);
                pointer-events: none;
                transform: rotate(0deg);
                transition:
                    transform 0.35s,
                    color 0.25s;
                transform-origin: center;
            }

            .accordion[role='tablist'] dt[aria-expanded='true'] omega-icon-chevron-down-outline {
                transform: rotate(-180deg);
            }

            .accordion[role='tablist'] dd {
                max-height: 600px;
                padding: 12px 15px 13px;
                color: rgb(62, 63, 66);
                font-size: 13px;
                opacity: 1;
                transition:
                    visibility 0.1s,
                    max-height 0.35s 0.1s,
                    padding 0.35s 0.1s,
                    opacity 0.2s 0.1s;
            }

            .accordion[role='tablist'] dd:first-child {
                margin-top: 0;
            }

            .accordion[role='tablist'] dd:last-child {
                margin-bottom: 0;
            }

            .accordion[role='tablist'] dd[aria-hidden='true'] {
                visibility: hidden;
                max-height: 0;
                opacity: 0;
                transition:
                    visibility 0.1s 0.35s,
                    max-height 0.35s,
                    padding 1s,
                    opacity 0.05s;
            }
        `;
    }
}

customElements.define('omega-accordion', OmegaAccordion);
export default OmegaAccordion;
