/* eslint-disable no-restricted-syntax */
import {
    FrequencyModelDto,
    LookupModelDto,
    StatusModel2Dto,
    WireModelDto,
    WireSearchCriteriaModelDto,
} from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';
import { format } from 'date-fns';
import { nullBeneficiary, nullDebitAccount } from '../channel/types';

export enum WireType {
    Domestic = 'domestic',
    International = 'international',
}

export const defaultFrequency: FrequencyModelDto = {
    type: 'OneTime',
    valueDate: format(new Date(), 'MM/dd/yyyy'),
    id: 0,
    updatedDate: '',
};

export const nullFrequency: FrequencyModelDto = {
    repeatOn: '',
    startOn: '',
    endOn: '',
    valueDate: '',
    nextPaymentDate: '',
    noEndDate: false,
    repeatOnDay1: 0,
    repeatOnDay2: 0,
    repeatOnLastBusinessDay: false,
    type: '',
    summary: '',
    id: 0,
    updatedBy: '',
    updatedDate: '',
};

export const nullLookupModelDto: LookupModelDto = { id: 0, updatedDate: '' };

export const nullWire: Nullable<WireModelDto> = {
    transactionId: null,
    wireCompany: null,
    debitAccount: nullDebitAccount,
    amount: 0,
    destinationCurrency: '',
    beneficiary: nullBeneficiary,
    frequency: defaultFrequency,
    parentFrequency: nullFrequency,
    additionalInformation: [nullLookupModelDto],
    isInternational: false,
    isDliWire: false,
    status: '',
    audit: '',
    numberOfApprovalsNeeded: 0,
    rateQuote: null,
    isUserApprovalLimitLess: false,
    permissions: [
        {
            permission: '',
            id: 0,
            updatedBy: '',
            updatedDate: '',
        },
    ],
    errorSummary: null,
    isEdited: false,
    message: null,
    messageType: null,
    successMessage: null,
    createdDate: '',
    purpose: '',
    confirmationNumber: null,
    completedApprovals: [],
    availableApprovers: [],
    wireTemplate: null,
    createdBy: '',
    completedApprovalCount: 0,
    referenceBeneficiary: '',
    wireFileId: null,
    fileName: null,
    duplicatePreventionId: '',
    securityMessage: null,
    id: 0,
    updatedBy: null,
    updatedDate: '',
};

// TODO: This type can be removed once the Swagger docs are updated to correctly mark the updatedDate as not required
export type WireSearchCriteria = Omit<WireSearchCriteriaModelDto, 'updatedDate'>;

// TODO: This type can be removed once the Swagger docs are updated to correctly mark the name as required and updatedDate as not required
export type StatusModel = Required<Omit<StatusModel2Dto, 'updatedDate' | 'updatedBy'>>;
