/* eslint-disable lines-between-class-members */

import { Injectable, InjectByToken } from '@jack-henry/frontend-utils/di';
import { exists } from '@jack-henry/frontend-utils/functions';
import {
    JhHttpResponseType,
    JhRequestConfig,
    JhRequestConfigArrayBuffer,
    JhRequestConfigBlob,
    JhRequestConfigRaw,
    JhRequestConfigText,
} from '@jack-henry/frontend-utils/http';
import {
    ApiErrorDto,
    ErrorResponseDto,
    isApiErrorDto,
    isErrorResponse,
} from '@treasury/api/shared';
import { ConfigurationService } from '@treasury/core/config';
import { TmHttpClient } from '@treasury/core/http';
import { type FetchFn, FetchFnToken } from '@treasury/utils';
import {
    ChallengeType,
    ErrorType,
    SecurityMessage,
    WithSecurityMessage,
} from '../../channel/mappings/security';
import { ResponseError, TmApiError, TmDetailedError } from '../types';
import {
    coerceSecurityMessage,
    extractSecurityPayload,
    getSecurityMessage,
    isLocked,
    isLockedOrSuccessful,
    isSuccessful,
    mapResponse,
} from './tm-http-client-mfa.helpers';
import {
    type IdentityDialogFn,
    EXIT_FETCH,
    IdentityDialogOtpRequest,
    OpenIdentityDialogToken,
} from './tm-http-client-mfa.types';

/**
 * A Treasury Management HTTP client implementing an MFA challenge flow.
 */
@Injectable()
export class TmHttpClientMfa extends TmHttpClient {
    constructor(
        @InjectByToken(FetchFnToken) fetchFn: FetchFn,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @InjectByToken(OpenIdentityDialogToken) private openIdentityDialog: IdentityDialogFn<any>,
        configService: ConfigurationService
    ) {
        super(fetchFn, configService);
    }

    public async request(url: string, config: JhRequestConfigText): Promise<string>;
    public async request(url: string, config: JhRequestConfigArrayBuffer): Promise<ArrayBuffer>;
    public async request(url: string, config: JhRequestConfigRaw): Promise<Response>;
    public async request(url: string, config: JhRequestConfigBlob): Promise<Blob>;
    public async request<T extends object>(url: string, config?: JhRequestConfig): Promise<T>;
    public async request<T extends object>(url: string, config?: JhRequestConfig) {
        const normalizedConfig = this.normalizeRequestConfig(config);
        const { responseType } = normalizedConfig;
        const apiResponse = await super.request(url, {
            ...normalizedConfig,
            // always use raw so that the response object can be examined for a
            // security message as JSON irrespective of the de-serialization method specified
            responseType: JhHttpResponseType.Raw,
        });
        const securityPayload = await extractSecurityPayload<T>(apiResponse);

        // just a regular request with no MFA implications; return it without additional processing
        if (!securityPayload) {
            return this.transformResponse(apiResponse, responseType);
        }

        const securityMessage = coerceSecurityMessage(securityPayload);
        const response = mapResponse(securityPayload, getSecurityMessage(normalizedConfig));
        const { isLocked, needsAuthentication } = response._auth;

        // if locked, throw an error so the user knows there is a problem
        if (isLocked) {
            throw new Error(
                securityMessage.message ?? 'Authentication locked. Please contact an administrator.'
            );
        }

        if (!needsAuthentication) {
            return this.transformResponse(apiResponse, responseType);
        }

        normalizedConfig.body = {
            ...normalizedConfig.body,
            securityMessage: response.securityMessage,
        };

        const securityResponse = await this.initiateSecurityRequest<T>(
            url,
            normalizedConfig,
            securityMessage
        );

        return exists(securityResponse)
            ? this.transformResponse(securityResponse, responseType)
            : this.transformResponse(apiResponse, responseType);
    }

    /**
     * This form of verification requires responding to a challenge via user interaction within the UI
     * by entering some known form of data (e.g., a one-time code sent to them).
     *
     * @param otpRequestFn Original request to be invoked at a later time
     * (either as a re-verification or with a user-provided code).
     */
    public async verificationWithUxAction<T extends object>(
        securityMessage: SecurityMessage,
        otpRequestFn?: IdentityDialogOtpRequest
    ) {
        const { message, response, reason } = await (
            this.openIdentityDialog as unknown as IdentityDialogFn<T>
        )(securityMessage, {
            otpRequestFn,
        });

        // user cancelled authentication; abort request and validation
        if (reason === 'cancel') {
            return EXIT_FETCH;
        }

        return {
            message,
            response,
        };
    }

    /**
     * This form of verification automatically sends a request to the server
     * which then waits for the user to respond before the response is sent.
     *
     * An identity dialog is opened with a loading indicator to let the user know
     * that the server is waiting for a response.
     *
     * Example: Text/call verification
     */
    public async verificationWithoutUxAction<T extends object>(
        securityMessage: SecurityMessage,
        requestFn: IdentityDialogOtpRequest
    ) {
        const { message, response } = await (
            this.openIdentityDialog as unknown as IdentityDialogFn<T>
        )(securityMessage, {
            withoutUserInteraction: true,
            otpRequestFn: requestFn,
        });

        return {
            message,
            response,
        };
    }

    protected async isBadResponse(resp: Response) {
        if (await super.isBadResponse(resp)) {
            return true;
        }

        // layer on additional domain logic into bad response evaluation;
        // the API may communicate bad responses on HTTP 200 responses
        if (resp.ok) {
            try {
                const json: object | boolean = await resp.clone().json();
                if (typeof json === 'boolean') {
                    return json === false;
                }

                if ('success' in json) {
                    return json.success === false;
                }

                // check specifically for "error" instead of not "success",
                // since this property can be overloaded to be an enum value
                // depending on the endpoint (e.g., security message)
                if (
                    'status' in json &&
                    typeof json.status === 'string' &&
                    json.status.toLowerCase() === 'error'
                ) {
                    return true;
                }

                if (isApiErrorDto(json)) {
                    return true;
                }
            } catch {
                return false;
            }
        }

        return false;
    }

    /**
     *
     * Normalize known API error flavors into a consistent client side `TmApiError` shape.
     * Otherwise, attempt to extract the `message` property from the shape and create
     * a `TmApiError` with only that data.
     *
     * @returns A `TmApiError` instance if provided a recognized response.
     * If not, let the default implementation handle it.
     */
    protected async transformBadResponse(resp: Response) {
        try {
            const json: ErrorResponseDto | ApiErrorDto | boolean | { message?: string } = await resp
                .clone()
                .json();

            // some endpoints return only a string payload on HTTP-400 responses
            if (!resp.ok && typeof json === 'string') {
                throw new Error(json);
            }

            if (typeof json === 'boolean') {
                return new TmApiError({
                    message: 'An unknown error occurred.',
                    responseCode: 0,
                });
            }

            if (isErrorResponse(json)) {
                return new ResponseError(json);
            }

            if (isApiErrorDto(json)) {
                return json.errorSummary
                    ? new TmDetailedError(json, json.errorSummary)
                    : new TmApiError(json);
            }

            if (typeof json.message === 'string' && json.message.length > 0) {
                return new TmApiError({
                    message: json.message.toString(),
                    responseCode: 1,
                });
            }

            return super.transformBadResponse(resp);
        } catch {
            // JSON parsing failed
            return super.transformBadResponse(resp);
        }
    }

    private async initiateSecurityRequest<T extends object>(
        url: string,
        config: JhRequestConfig,
        securityMessage: SecurityMessage
    ) {
        if (isLockedOrSuccessful(securityMessage)) {
            return undefined;
        }

        const dialogResult = await this.performSecurityRequest<T>(url, config, securityMessage);

        if (dialogResult === EXIT_FETCH) {
            throw new Error('Could not perform request. User identity could not be verified.');
        }

        if (exists(dialogResult)) {
            const { message } = dialogResult;
            const errMessage = coerceSecurityMessage(message).message;

            if (isSuccessful(message)) {
                return dialogResult.response;
            } else if (isLocked(message)) {
                throw new Error(
                    errMessage ??
                        'Request failed. Unable to verify identity due to multiple failed attempts.'
                );
            }
        }

        // code flow should never get here;
        // the identity dialog should only close if the user cancelled, was validated or locked
        throw new Error(
            'Security request cycle failed. User could not be verified and was not locked.'
        );
    }

    private async performSecurityRequest<T extends object>(
        url: string,
        config: JhRequestConfig,
        securityMessage: SecurityMessage
    ) {
        const { challengeMethodTypeId } = securityMessage;
        const otpRequestFn = this.prepareRequest(url, config, securityMessage);
        switch (challengeMethodTypeId) {
            case ChallengeType.OutOfBandOTP:
            case ChallengeType.SecureToken: {
                return this.verificationWithUxAction(securityMessage, otpRequestFn);
            }
            case ChallengeType.OutOfBand: {
                return this.performOobChallenge<T>(securityMessage, otpRequestFn);
            }
            default:
                throw new Error(`Invalid security challenge type '${challengeMethodTypeId}'`);
        }
    }

    /**
     * Prepare a request to be used at a later time,
     * capturing a particular URL and other metadata.
     */
    private prepareRequest(
        url: string,
        config: JhRequestConfig,
        securityMessage: SecurityMessage
    ): IdentityDialogOtpRequest {
        return (newSecurityMessage: SecurityMessage) => {
            if (url === 'security/checkSecurity') {
                config.body = {
                    ...securityMessage,
                    ...newSecurityMessage,
                };
            } else {
                (config.body as WithSecurityMessage).securityMessage = {
                    ...securityMessage,
                    ...newSecurityMessage,
                };
            }

            return super.request(url, {
                ...config,
                responseType: JhHttpResponseType.Raw,
            });
        };
    }

    private async performOobChallenge<T extends object>(
        securityMessage: SecurityMessage,
        requestFn: IdentityDialogOtpRequest
    ) {
        // Verify user using call/text if error code is verify
        if (securityMessage.errorCode !== ErrorType.Verify) {
            return undefined;
        }

        return this.verificationWithoutUxAction<T>(securityMessage, requestFn);
    }
}
