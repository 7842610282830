import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import '../icons/omegaIconArrowUp.js';

/**
 * @typedef {import('@treasury/FDL/recordset').Recordset} Recordset
 */

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export class OmegaPagination extends LitElement {
    static get properties() {
        return {
            recordset: Object,
            compact: {
                type: Boolean,
                value: false,
            },
            itemLabel: Object,
            rowsPerPageOptions: Array,
            indicateFiltering: Boolean,
        };
    }

    // adding the constructor mostly to add JSDoc type to the recordset field
    constructor() {
        super();
        /** @type Recordset */
        this.recordset = undefined;
        this.subs = [];
        this.itemLabel = {
            singular: 'item',
            plural: 'items',
        };
        this.rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS;
        this.indicateFiltering = true;
    }

    firstUpdated() {
        if (!this.recordset) {
            throw new Error('Recordset required for <omega-pagination>');
        }

        this.subs.push(this.recordset.onChange(() => this.requestUpdate()));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.subs.forEach(sub => sub.unsubscribe());
    }

    get rowsPerPage() {
        return this.recordset.pageSize;
    }

    set rowsPerPage(rowsPerPage) {
        const oldValue = this.recordset.pageSize;
        this.recordset.pageSize = +rowsPerPage;
        this.goToFirstPage();
        this.requestUpdate('rowsPerPage', oldValue);
    }

    get pageNumber() {
        return this.recordset.pageNumber;
    }

    set pageNumber(n) {
        const oldValue = this.recordset.pageNumber;
        this.recordset.pageNumber = n;
        this.requestUpdate('pageNumber', oldValue);
    }

    get pageCount() {
        return Math.ceil(this.recordset.filteredCount / this.recordset.pageSize);
    }

    isBackButtonDisabled() {
        return this.recordset.pageNumber === 1;
    }

    isNextButtonDisabled() {
        if (this.recordset.currentPage.length === 0) return true;

        return this.recordset.pageNumber === this.pageCount;
    }

    isLastButtonDisabled() {
        if (this.recordset.currentPage.length === 0) return true;
        return this.isNextButtonDisabled();
    }

    isFirstButtonDisabled() {
        return this.isBackButtonDisabled();
    }

    goToFirstPage() {
        this.pageNumber = 1;
    }

    goToLastPage() {
        this.pageNumber = this.pageCount;
    }

    goToNextPage() {
        this.pageNumber += 1;
    }

    goToPreviousPage() {
        this.pageNumber -= 1;
    }

    isCurrentPage(n) {
        return n === this.recordset.pageNumber;
    }

    isPaginationBarAvailable() {
        return this.recordset.totalCount >= Math.min(...this.rowsPerPageOptions);
    }

    pageButtons() {
        const buttons = [this.recordset.pageNumber];
        let remainingButtons = 4;
        let steps = 0;
        let keepGoing = true;
        while (remainingButtons > 0 && keepGoing) {
            steps += 1;
            keepGoing = false;
            const before = this.recordset.pageNumber - steps;
            const after = this.recordset.pageNumber + steps;
            if (before >= 1) {
                buttons.unshift(before);
                remainingButtons -= 1;
                keepGoing = true;
            }
            if (after <= this.pageCount) {
                buttons.push(after);
                remainingButtons -= 1;
                keepGoing = true;
            }
        }
        return buttons;
    }

    message() {
        if (!this.recordset) {
            return nothing;
        }

        if (this.itemLabel.plural === undefined) {
            this.itemLabel.plural = `${this.itemLabel.singular}s`;
        }

        const { totalCount, filteredCount, filter } = this.recordset;
        const isFiltered = filter.length > 0;
        const loadingOrViewing = this.recordset.isLoading ? 'Loading' : 'Viewing';

        if (this.recordset.totalCount === 0 && !this.recordset.isLoading) {
            return html`<strong>No ${this.itemLabel.plural} found</strong>`;
        }
        if (this.recordset.totalCount === 1) {
            return html`${loadingOrViewing} <strong>1</strong> of
                <strong>1 ${this.itemLabel.singular}</strong>`;
        }

        if (isFiltered && filteredCount === 0) {
            return html`<strong>No ${this.itemLabel.plural} found</strong>`;
        }
        if (isFiltered && filteredCount === 1) {
            return html`${loadingOrViewing} <strong>1</strong> of
                <strong>1 filtered ${this.itemLabel.singular}</strong>`;
        }

        const start = this.recordset.currentPage.length
            ? this.recordset.firstRecordNumberOnPage
            : 0;
        const end = this.recordset.lastRecordNumberOnPage;

        const includedRecords = isFiltered
            ? html`<strong
                  >${filteredCount.toLocaleString('en-US')}${this.indicateFiltering
                      ? ' filtered'
                      : ''}
                  ${this.itemLabel.plural}</strong
              >`
            : html`<strong>${totalCount.toLocaleString('en-US')} ${this.itemLabel.plural}</strong>`;
        const viewRange =
            this.pageCount === 1
                ? ''
                : html`<strong>${start}</strong> to <strong>${end}</strong> of`;
        return html`${loadingOrViewing} ${viewRange} ${includedRecords}`;
    }

    selectedMessage() {
        if (!this.showSelected) {
            return '';
        }

        throw new Error('Selected records not implemented');
    }

    render() {
        if (this.compact) {
            return html`
                <div class="pagination-message">${this.message()} ${this.selectedMessage()}</div>
                <div class="compact-pagination-controls">
                    <button class="previous-button" @click="${this.goToPreviousPage}">
                        <omega-icon-arrow-up></omega-icon-arrow-up>
                    </button>
                    <div class="input-container">
                        <!-- TODO: future enhancement: should be able to type in a page number -->
                        <!-- <input type="number" value=$ {this.pageNumber}> -->
                        <span>${this.pageNumber}</span>
                    </div>
                    <button class="next-button" @click="${this.goToNextPage}">
                        <omega-icon-arrow-up></omega-icon-arrow-up>
                    </button>
                </div>
            `;
        }

        function jumpToPageButton(label, clickFn, isDisabledFn) {
            return html`<li
                class=${classMap({
                    'omega-disabled': isDisabledFn(),
                })}
            >
                <a
                    aria-disabled=${isDisabledFn()}
                    @click=${clickFn}
                    @keydown=${e => {
                        if (e.key === 'Enter') {
                            clickFn();
                        }
                    }}
                    >${label}<span class="visually-hidden"> page</span></a
                >
            </li>`;
        }

        const pageNumberButtons = this.pageButtons().map(
            pageNumber =>
                html`<li>
                    <a
                        @click=${() => {
                            this.pageNumber = pageNumber;
                        }}
                        @keydown=${e => {
                            if (e.key === 'Enter') {
                                clickFn();
                            }
                        }}
                        class="page-number"
                        aria-current=${this.isCurrentPage(pageNumber) ? 'page' : 'false'}
                        ><span class="visually-hidden">page </span>${pageNumber}</a
                    >
                </li>`
        );

        const pageButtons = [
            ...[
                jumpToPageButton(
                    'First',
                    this.goToFirstPage.bind(this),
                    this.isFirstButtonDisabled.bind(this)
                ),
                jumpToPageButton(
                    'Previous',
                    this.goToPreviousPage.bind(this),
                    this.isBackButtonDisabled.bind(this)
                ),
            ],
            pageNumberButtons,
            [
                jumpToPageButton(
                    'Next',
                    this.goToNextPage.bind(this),
                    this.isNextButtonDisabled.bind(this)
                ),
                jumpToPageButton(
                    'Last',
                    this.goToLastPage.bind(this),
                    this.isLastButtonDisabled.bind(this)
                ),
            ],
        ];

        const rowsPerPageDropdown = html`<select
            aria-label="Rows per page"
            @change=${event => {
                this.rowsPerPage = event.target.value;
            }}
            @blur=${e => {
                e.stopPropagation();
            }}
        >
            ${this.rowsPerPageOptions.map(
                option =>
                    html`<option value=${option} ?selected=${this.rowsPerPage === option}>
                        ${option}
                    </option>`
            )}
        </select>`;

        const paginationBar = this.isPaginationBarAvailable()
            ? html` <div class="pagination-controls ">
                  <nav class="pagination-nav" aria-label="Pages">
                      <ol>
                          ${pageButtons}
                      </ol>
                  </nav>
                  ${rowsPerPageDropdown}
              </div>`
            : nothing;

        return html`
            <div class="table-footer omega-no-print">
                <div class="pagination-message">${this.message()} ${this.selectedMessage()}</div>
                ${paginationBar}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                z-index: 1; /* Prevents .search-button in omega-select.js from bleeding over this control. */
            }

            .table-footer {
                display: flex;
                width: auto;
                align-items: center;
                justify-content: space-between;
                min-height: 32px;
                padding: 10px 15px;
                background-color: #f0f0f0;
            }

            .pagination-message {
                font-size: 12px;
            }

            .pagination-nav {
                display: flex;
                padding: 0;
                margin: 0;
            }

            .pagination-controls {
                display: flex;
            }

            .pagination-nav ol {
                display: inline-block;
                padding: 0;
                margin: 0;
            }

            .pagination-nav li {
                display: inline-block;
                margin: 0;
                list-style: none;
            }

            .pagination-nav a {
                display: block;
                min-width: 20px;
                padding: 0 10px;
                border: 1px solid #ddd;
                border-right-width: 0;
                background-color: white;
                color: var(--omega-primary);
                cursor: pointer;
                line-height: 30px;
                text-align: center;
                text-decoration: none;
            }

            .pagination-nav a:hover {
                border-color: #ddd;
                background-color: #eee;
                color: #22527b;
            }

            .pagination-nav li {
                font-weight: bold;
            }

            .pagination-nav .omega-disabled {
                font-weight: normal;
                opacity: 0.5;
            }

            .pagination-nav [aria-disabled='true'] {
                color: inherit;
                cursor: default;
                pointer-events: none;
            }

            .pagination-nav [aria-current='page'] {
                background-color: var(--omega-primary);
                color: #fff;
                cursor: default;
                pointer-events: none;
            }

            .pagination-nav li:first-child a {
                border-bottom-left-radius: 3px;
                border-top-left-radius: 5px;
            }

            .pagination-nav li:last-child a {
                border-right-width: 1px;
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
            }

            .table-footer select {
                height: 30px;
                margin: 0 10px;
            }

            .visually-hidden {
                position: absolute !important;
                overflow: hidden;
                width: 1px;
                height: 1px;
                clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
                clip: rect(1px, 1px, 1px, 1px);
            }

            :host([compact]) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .compact-pagination-controls {
                display: flex;
                margin-left: 8px;
            }

            .compact-pagination-controls span,
            .compact-pagination-controls input {
                border: none;
                font-family: inherit;
                font-size: 14px;
                width: 32px;
                text-align: center;
            }

            /* hides "stepper" on number input */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }

            .input-container {
                border-top: var(--omega-default-border);
                border-bottom: var(--omega-default-border);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .next-button,
            .previous-button {
                background: #fff;
                height: 36px;
                width: 36px;
                border: var(--omega-default-border);
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            omega-icon-arrow-up {
                fill: var(--omega-text-secondary);
                width: 10px;
                height: 10px;
            }

            .next-button {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            .next-button omega-icon-arrow-up {
                transform: rotate(90deg);
            }

            .previous-button {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .previous-button omega-icon-arrow-up {
                transform: rotate(-90deg);
            }

            @media print {
                .omega-no-print {
                    display: none;
                }
            }
        `;
    }
}

window.customElements.define('omega-pagination', OmegaPagination);
