import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

export const mapRecurringAchFiltersToRequest = params => ({
    ...params,
    creditAmountType: params.creditAmount
        ? params.creditAmount[0] === 'range'
            ? 'Range'
            : 'Specific Amount'
        : null,
    creditAmount: AmountRangeFormatters.getSpecificAmount(params.creditAmount),
    creditAmountMin: AmountRangeFormatters.getRangeStart(params.creditAmount),
    creditAmountMax: AmountRangeFormatters.getRangeEnd(params.creditAmount),
    debitAmountType: params.debitAmount[0] === 'range' ? 'Range' : 'Specific Amount',
    debitAmount: AmountRangeFormatters.getSpecificAmount(params.debitAmount),
    debitAmountMin: AmountRangeFormatters.getRangeStart(params.debitAmount),
    debitAmountMax: AmountRangeFormatters.getRangeEnd(params.debitAmount),
    initiatedDate:
        DateFormatters.parseDate(params.createdDate).value.start ??
        DateFormatters.parseDate(params.createdDate).value,
    frequencies: params.frequencies === 'all' ? [] : params.frequencies,
    initiatedDateEnd: DateFormatters.parseDate(params.createdDate).value.end ?? null,
    initiatedDateStart: DateFormatters.parseDate(params.createdDate).value.start ?? null,
    initiatedDateType:
        DateFormatters.getDateType(params.createdDate) === 'dateRange' ? 'Range' : 'Specific Date',
    nextPaymentDate:
        DateFormatters.parseDate(params.nextPaymentDate).value.start ??
        DateFormatters.parseDate(params.nextPaymentDate).value,
    nextPaymentDateEnd: DateFormatters.parseDate(params.nextPaymentDate).value.end ?? null,
    nextPaymentDateStart: DateFormatters.parseDate(params.nextPaymentDate).value.start ?? null,
    nextPaymentDateType:
        DateFormatters.getDateType(params.nextPaymentDate) === 'dateRange'
            ? 'Range'
            : 'Specific Date',
    // TODO: pagingParameters
});

export const mapResponseToRecurringAchFields = response =>
    response.map(payment => ({
        ...payment,
        frequencyType: payment.frequency.type,
        nextPaymentDate: payment.frequency.nextPaymentDate,
    }));
