import { Injectable, InjectProperty } from '@jack-henry/frontend-utils/di';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

@Injectable()
export class WireIsoService {
    @InjectProperty({ async: true, token: FeatureFlagService })
    private declare featureFlagService: Promise<FeatureFlagService>;

    public async getShowWireCreditorSpeedbumpFF() {
        return (await this.featureFlagService).isEnabled(Feature.WireCreditorIsoSpeedBump);
    }

    public async getEntitlementWireISO20022() {
        return (await this.featureFlagService).isEnabled(Feature.WireIsoFeatureFlag);
    }

    /**
     * For angular code, call this in the controller. Returns the correct set of labels based on feature flag settings
     *
     */
    public async getLabels() {
        return (await this.getEntitlementWireISO20022()) ? this.iso20022Labels : this.legacyLabels;
    }

    private async getEntitlementWireBeneficiaryIso20022() {
        return (await this.featureFlagService).isEnabled(Feature.WireIsoBeneficiaryFeatureFlag);
    }

    public get legacyLabels() {
        return legacyWireLabels;
    }

    public get iso20022Labels() {
        return iso20022WireLabels;
    }

    public static get legacyLabels() {
        return legacyWireLabels;
    }
}

export type WireIsoLabels = typeof iso20022WireLabels | typeof legacyWireLabels;

const iso20022WireLabels = {
    creditor: 'Creditor',
    creditors: 'Creditors',
    creditorLowercase: 'creditor',
    agent: 'Agent',
    agentLowercase: 'agent',
    agents: 'Agents',
    debtor: 'Debtor',
    instructingAgent: 'Instructing Agent',
    creditorAgent: 'Creditor Agent',
    instructed: 'Instructed Agent',
    intermediaryAgent: 'Intermediary Agent',
    internationalIntermediaryAgent: 'Intermediary Agent',
    intermediaryAgentInfo: 'Intermediary Agent Information',
    instructedAgentInfo: 'Instructed Agent Information',
    agentId: 'Agent ID',
    agentIdLowercase: 'agent ID',
    endToEndId: 'End to End ID',
    endToEndIdLowercase: 'end to end ID',
    address1: 'Building Name and Street Name',
    address2and3:
        'Department, Sub Department, Building Name,Floor, Room, Town Location Name, District Name, Post Box',
    townName: 'Town Name',
    cityTownName: 'City/Town Name',
    agentCityTownName: 'Agent City/Town Name',
    stateSubDivision: 'State/Sub Division',
    stateCountrySubDivision: 'State/Country Sub Division',
    agentStateCountrySubDivision: 'Agent State/Country Sub Division',
    countrySubDivision: 'Country Sub Division',
    postCode: 'Post Code',
    postalCode: 'Post Code',
    remittanceInformation: 'Remittance Information',
    instructedAgent: 'Instructed Agent',
};

const legacyWireLabels = {
    creditor: 'Beneficiary',
    creditors: 'Beneficiaries',
    creditorLowercase: 'beneficiary',
    agent: 'Bank',
    agentLowercase: 'bank',
    agents: 'Banks',
    debtor: 'Originator',
    instructingAgent: 'Sending Bank',
    creditorAgent: 'Beneficiary Bank',
    instructed: 'Intermediary',
    intermediaryAgent: 'Receiving Bank',
    internationalIntermediaryAgent: 'International Intermediary Bank',
    intermediaryAgentInfo: 'Intermediary Bank Information',
    instructedAgentInfo: 'Intermediary Bank Information',
    agentId: 'Routing Number',
    agentIdLowercase: 'routing number',
    endToEndId: 'Reference Beneficiary',
    endToEndIdLowercase: 'reference beneficiary',
    address1: 'Address 1',
    address2and3: 'Address 2/3',
    townName: 'City',
    cityTownName: 'City',
    agentCityTownName: 'City',
    countrySubDivision: 'State',
    stateCountrySubDivision: 'State',
    agentStateCountrySubDivision: 'State',
    stateSubDivision: 'State',
    postCode: 'Zip Code',
    postalCode: 'Postal Code',
    remittanceInformation: 'Additional Information',
    instructedAgent: 'Domestic Intermediary Bank',
};
