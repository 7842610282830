/* eslint-disable no-restricted-syntax */
import { clone, exists } from '@jack-henry/frontend-utils/functions';
import { Nullable } from '@treasury/utils';
import { DomainEntity } from '../../../shared/types';
import { AccountDto, IssuedItemDto, nullAccount, nullIssuedItem } from '../../types/arp';
import { IIssuedItem, IssuedItemPendingStatus } from './index';
import { compareIssuedItems, copyIssuedItem, IssuedItemUiState } from './issued-items.mappings';

export class IssuedItem extends DomainEntity<Nullable<IssuedItemDto>> implements IIssuedItem {
    constructor(dto = clone(nullIssuedItem)) {
        super(dto);
        this._account = createAccountDto(dto);
    }

    public pendingStatus = IssuedItemPendingStatus.None;

    public readonly voidedDate = this.dto.checkVoidDate
        ? new Date(this.dto.checkVoidDate)
        : undefined;

    public readonly postDate = this.dto.postDate ? new Date(this.dto.postDate) : undefined;

    /**
     * The date this check was either voided or closed, if any.
     */
    public readonly dateFinalized = (dto => {
        if (this.voidedDate) {
            return this.voidedDate;
        }

        if (dto.postDate) {
            return new Date(dto.postDate);
        }

        return undefined;
    })(this.dto);

    public readonly auditItems = exists(this.dto.accountReconItemAudits)
        ? this.dto.accountReconItemAudits.map(mapAuditItem)
        : [];

    private _dateIssued = this.dto.checkDate ? new Date(this.dto.checkDate) : undefined;

    private _createdDate = this.dto.issuedItemCreatedDate
        ? new Date(this.dto.issuedItemCreatedDate)
        : undefined;

    private _account?: AccountDto;

    public get itemEntryType() {
        return this.dto.entryType || 'Other';
    }

    public set itemEntryType(entryType) {
        this.dto.entryType = entryType || null;
    }

    public get createdDate() {
        return this._createdDate;
    }

    public set createdDate(date) {
        this._createdDate = date;
    }

    public get clearedDate() {
        return this.dto.clearedDate ? new Date(this.dto.clearedDate) : undefined;
    }

    public get voidEligible() {
        const { pendingStatus, itemStatus } = this;
        const hasEligiblePendingStatus =
            pendingStatus === IssuedItemPendingStatus.None ||
            pendingStatus === IssuedItemPendingStatus.SelectedFromMatches;

        return itemStatus !== 'Void' && itemStatus !== 'Cleared' && hasEligiblePendingStatus;
    }

    public get errorState() {
        const { itemStatus, pendingStatus } = this;

        if (
            pendingStatus === IssuedItemPendingStatus.TooManyMatches ||
            pendingStatus === IssuedItemPendingStatus.HasPossibleMatches
        ) {
            return IssuedItemUiState.Error;
        }

        // pending statuses requiring user attention are considered invalid;
        // others statuses may be void ineligible, but will simply be removed at save time

        if (
            pendingStatus === IssuedItemPendingStatus.HasOnlyFinalizedMatches ||
            itemStatus === 'Void' ||
            itemStatus === 'Cleared'
        ) {
            return IssuedItemUiState.Warning;
        }

        return IssuedItemUiState.None;
    }

    public get payee() {
        return this.dto.payeeName || '';
    }

    public set payee(payeeName) {
        this.dto.payeeName = payeeName;
    }

    public get checkNumber() {
        return this.dto.checkNumber || undefined;
    }

    public set checkNumber(number) {
        this.dto.checkNumber = number ?? null;
    }

    public get checkAmount() {
        return this.dto.issuedAmount || 0;
    }

    public set checkAmount(amount) {
        this.dto.issuedAmount = amount;
    }

    public get itemStatus() {
        return this.dto.issuedStatus || '';
    }

    public set itemStatus(status) {
        this.dto.issuedStatus = status === '' ? null : status;
    }

    public get account() {
        return this._account;
    }

    public set account(account) {
        this._account = account ?? nullAccount;

        const { id, type, accountDisplayLabel } = this._account;

        this.dto.accountNumber = accountDisplayLabel;
        this.dto.accountId = id;
        this.dto.accountType = type;
    }

    public get accountName() {
        if (!this._account) {
            return '';
        }

        const { accountDisplayLabel, number } = this._account;
        return accountDisplayLabel || number || '';
    }

    public get type() {
        return this.dto.itemType || undefined;
    }

    public set type(itemType) {
        this.dto.itemType = itemType || null;
    }

    public get dateIssued() {
        return this._dateIssued;
    }

    public set dateIssued(date) {
        this._dateIssued = date;
        this.dto.checkDate = date ? date.toString() : null;
    }

    public copyFrom(other: IIssuedItem) {
        // use the DTO if available, since it contains additional properties like
        // sequence number and batch number
        if (other instanceof IssuedItem) {
            this.dto = other.dto;
        }

        // invoke this function regardless to ensure setter logic is called
        copyIssuedItem(other, this);
    }

    public compare(other: IIssuedItem): boolean {
        if (other instanceof IssuedItem && other.dto === this.dto) {
            return true;
        }

        return compareIssuedItems(this, other);
    }

    public createDefault() {
        return new IssuedItem() as this;
    }
}

function createAccountDto(dto: Nullable<IssuedItemDto>) {
    const { accountId, accountType, accountNumber } = dto;

    if (accountId === null || accountType === null || accountNumber === null) {
        return undefined;
    }

    const account = clone(nullAccount);

    account.accountDisplayLabel = account.number = accountNumber || '';
    account.type = accountType || 'Checking';
    account.id = accountId || -1;

    return account;
}

function mapAuditItem(
    audit: IssuedItemDto['accountReconItemAudits'][0]
): IIssuedItem['auditItems'][0] {
    const { createdDate, createdBy, accountReconItemActionType } = audit;

    return {
        createdFiDateTime: createdDate,
        createdByUserName: createdBy,
        action: accountReconItemActionType,
    };
}
