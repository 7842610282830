import '@treasury/omega/components/omega-frequency/omega-frequency';
import usdFormat from '@treasury/policy/lib/formatters/usd.formatter';
import { html, render } from 'lit';

export const printWire = (wire, config, labels) => {
    const parseCurrency = currency => usdFormat(currency);
    const div = document.createElement('div');
    const contents = html`${labels.creditor}:${wire.beneficiaryName}<br />
        Account Number: ${wire.accountNumber} <br />
        ${labels.agent} ID:${wire.bank.bankId}<br />
        Amount: ${parseCurrency(wire.wireAmount)}<br />
        Wire Company Name: ${wire.wireCompanyName}<br />
        Debit Account:
        ${config.accountNicknames ? wire.debitAccountName : wire.debitAccountNumber}<br />
        Additional Information:
        ${wire.additionalInformation
            ? wire.additionalInformation.filter(ai => ai && ai.length > 0).join(',')
            : 'n/a'}<br />
        Purpose: ${wire.purpose ?? 'n/a'}<br />
        ${labels.endToEndId}: ${wire.referenceBeneficiary ?? 'n/a'}<br />
        <omega-frequency readonly .frequency=${wire.frequency}></omega-frequency>`;
    render(contents, div);

    return div;
};
const print = (title, contentNode) => {
    const frame1 = document.createElement('iframe');
    frame1.name = 'frame1';
    frame1.style.position = 'absolute';
    frame1.style.top = '-1000000px';
    document.body.appendChild(frame1);
    const frameDoc = frame1.contentDocument;
    frameDoc.title = title;
    frameDoc.body.append(contentNode);

    setTimeout(() => {
        window.frames.frame1.focus();
        window.frames.frame1.print();
        document.body.removeChild(frame1);
    }, 500);
    return false;
};

export const printSingleWire = (wire, config, labels) => {
    print('Print Wire', printWire(wire, config, labels));
};

export const printWires = (wires, config, labels) => {
    const elements = wires.map(r => printWire(r, config, labels));
    const content = document.createElement('div');
    Array.forEach(elements, el => {
        content.append(el);
        content.append(document.createElement('hr'));
    });
    print('Print Wires', content);
};

export default { printSingleWire, printWires };
