import { returnActivityDatePicker, returnActivityCompanyIds } from '@treasury/policy/ach';

import AchCompanyRequests from '@treasury/domain/channel/requests/ach/ach-company-requests';

export const reportFilters = [
    {
        field: 'receivedDate',
        fieldType: returnActivityDatePicker.with
            .label('Date Return Received')
            .with.options(['today', 'specific', 'range', 'week-to-date', 'month-to-date'])
            .as.tag('omega-datepicker'),
    },
    {
        field: 'achCompanyIds',
        fieldType: returnActivityCompanyIds.with
            .label('ACH Company')
            .with.options({
                fetch: AchCompanyRequests.getAchCompanies,
                text: record =>
                    `${record.companyName} - ${record.companyId} - ${record.secCodes[0]?.code}`,
                value: record => record.id,
                hideSelectAll: true,
            })
            .with.filtering()
            .and.multipleValues(1, 11)
            .thatHas.placeholder('Select ACH Company')
            .as.tag('omega-select'),
        value: [],
    },
];
