const IsoCreditorSpeedbumpKey = 'Channel.DoOnce.ShowWireIsoCreditorSpeedBump';

export function showCreditorIsoModalOnce(wireIsoService, modalService) {
    return wireIsoService.getShowWireCreditorSpeedbumpFF().then(showWireCreditorSpeedbumpFF => {
        if (
            showWireCreditorSpeedbumpFF &&
            !window.sessionStorage.getItem(IsoCreditorSpeedbumpKey)
        ) {
            let options = {
                headerText: 'Verify Creditor (Beneficiary) Information',
                closeButtonText: '',
                actionButtonText: 'Ok',
                bodyText:
                    'Address formatting changes occurred on July 14, 2025. Please verify the creditor information is correct prior to initiating a wire.',
                submit(result) {
                    $modalInstance.close(result);
                    window.sessionStorage.setItem(IsoCreditorSpeedbumpKey, true);
                },
            };
            let $modalInstance = modalService.showModal({}, options);
        }
    });
}
