import { clone } from '@jack-henry/frontend-utils/functions';
import convertStringToPascalCase from './convert-string-to-pascal-case.js';

export function mapDownloadOptionsFromApi(reportOptions, apiValue, presentationValue) {
    const data = clone(reportOptions);
    if (data.includes(apiValue) && !data.includes(presentationValue)) {
        const filteredData = data.filter(option => option !== apiValue);
        filteredData.push(presentationValue);
        return filteredData;
    }
    return data;
}

function updateTitleBasedOnDownloadPage(title, dateType) {
    const lower = title.toLowerCase().trim();
    if (lower === 'daybalancereport' || lower === 'daytransactionsreport') {
        const adjective = dateType.substring(0, dateType.indexOf(' '));
        return adjective + title;
    }
    return title;
}

export function createFileDownloadName(title, fileExtension, dateType) {
    const pascalCaseTitle = convertStringToPascalCase(title);
    const updateDownloadBasedOnTitle = updateTitleBasedOnDownloadPage(pascalCaseTitle, dateType);
    return `${updateDownloadBasedOnTitle}.${fileExtension}`;
}
