/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AchBatchFilterModelDto,
  AchBatchModelDto,
  AchBatchSummaryModelDto,
  AchEditableBatchModelDto,
  AchFederalTaxTemplateModelDto,
  AchPaymentModelDto,
  AchStateTaxTemplateModelDto,
  DeleteAchBatchModelDto,
  LookupModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchBatchesClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPost
   * @summary Creates an ACH Batch.
   * @request POST:/achBatches/batch
   */
  achBatchesPost = (achBatch: AchBatchModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/batch`,
      method: "POST",
      body: achBatch,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPostStateTaxTemplate
   * @summary Creates a State Tax Template.
   * @request POST:/achBatches/stateTaxTemplate
   */
  achBatchesPostStateTaxTemplate = (achTemplate: AchStateTaxTemplateModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/stateTaxTemplate`,
      method: "POST",
      body: achTemplate,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPostFederalTaxTemplate
   * @summary Creates a Federal Tax Template.
   * @request POST:/achBatches/federalTaxTemplate
   */
  achBatchesPostFederalTaxTemplate = (achTemplate: AchFederalTaxTemplateModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/federalTaxTemplate`,
      method: "POST",
      body: achTemplate,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesGet
   * @summary Gets a specific ACH Batch for editing.
   * @request GET:/achBatches/{id}
   */
  achBatchesGet = (id: number, params: RequestParams = {}) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPut
   * @summary Updates an ACH Batch.
   * @request PUT:/achBatches/{id}
   */
  achBatchesPut = (id: number, achBatch: AchBatchModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/${id}`,
      method: "PUT",
      body: achBatch,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesClone
   * @summary Gets a cloned ach batch.
   * @request GET:/achBatches/Clone
   */
  achBatchesClone = (
    query: {
      /**
       * ID of the cloned ACH batch to retrieve.
       *
       * @format int32
       */
      cloneId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/Clone`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesGetAll
   * @summary Gets a default list of ACH Batch Summaries.
   * @request GET:/achBatches
   */
  achBatchesGetAll = (params: RequestParams = {}) =>
    this.http.request<AchBatchSummaryModelDto[], any>({
      path: `/achBatches`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesValidateTemplateName
   * @summary Validate a Wire Template Name.
   * @request GET:/achBatches/validateTemplateName
   */
  achBatchesValidateTemplateName = (
    query: {
      /**
       * Wire Template Name
       *
       */
      templateName: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/achBatches/validateTemplateName`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPostAll
   * @summary Gets a filtered list of ACH Batch Summaries.
   * @request POST:/achBatches/search
   */
  achBatchesPostAll = (achBatchFilter: AchBatchFilterModelDto, params: RequestParams = {}) =>
    this.http.request<AchBatchSummaryModelDto[], any>({
      path: `/achBatches/search`,
      method: "POST",
      body: achBatchFilter,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesSearchTaxTemplates
   * @summary Gets a filtered list of ACH Batch Summaries.
   * @request POST:/achBatches/searchTaxTemplates
   */
  achBatchesSearchTaxTemplates = (achBatchFilter: AchBatchFilterModelDto, params: RequestParams = {}) =>
    this.http.request<AchBatchSummaryModelDto[], any>({
      path: `/achBatches/searchTaxTemplates`,
      method: "POST",
      body: achBatchFilter,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesUpdateFederalTaxTemplate
   * @summary Updates a Federal Tax Template.
   * @request PUT:/achBatches/federalTaxTemplate/{id}
   */
  achBatchesUpdateFederalTaxTemplate = (
    id: number,
    achTemplate: AchFederalTaxTemplateModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/federalTaxTemplate/${id}`,
      method: "PUT",
      body: achTemplate,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesUpdateStateTaxTemplate
   * @summary Updates a State Tax Template.
   * @request PUT:/achBatches/stateTaxTemplate/{id}
   */
  achBatchesUpdateStateTaxTemplate = (
    id: number,
    achTemplate: AchStateTaxTemplateModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<AchEditableBatchModelDto, any>({
      path: `/achBatches/stateTaxTemplate/${id}`,
      method: "PUT",
      body: achTemplate,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesApprove
   * @summary Approve an ACH Batch.
   * @request POST:/achBatches/{id}/approve
   */
  achBatchesApprove = (id: number, comment: LookupModelDto, params: RequestParams = {}) =>
    this.http.request<LookupModelDto, any>({
      path: `/achBatches/${id}/approve`,
      method: "POST",
      body: comment,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesReject
   * @summary Reject an ACH Batch.
   * @request POST:/achBatches/{id}/reject
   */
  achBatchesReject = (id: number, comment: LookupModelDto, params: RequestParams = {}) =>
    this.http.request<LookupModelDto, any>({
      path: `/achBatches/${id}/reject`,
      method: "POST",
      body: comment,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesDelete
   * @summary Deletes an ACH Batch from the Batch List
   * @request DELETE:/achBatches/delete
   */
  achBatchesDelete = (model: DeleteAchBatchModelDto | null, params: RequestParams = {}) =>
    this.http.request<DeleteAchBatchModelDto, any>({
      path: `/achBatches/delete`,
      method: "DELETE",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesPost2
   * @summary Create an ACH payment from one or more batches.
   * @request POST:/achBatches/initiate
   */
  achBatchesPost2 = (achBatchIds: LookupModelDto[], params: RequestParams = {}) =>
    this.http.request<AchPaymentModelDto, any>({
      path: `/achBatches/initiate`,
      method: "POST",
      body: achBatchIds,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchBatches
   * @name AchBatchesInitiateTaxPayments
   * @summary Create an ACH Tax Payment from one or more batches.
   * @request POST:/achBatches/initiateTax
   */
  achBatchesInitiateTaxPayments = (achBatchIds: LookupModelDto[], params: RequestParams = {}) =>
    this.http.request<AchPaymentModelDto, any>({
      path: `/achBatches/initiateTax`,
      method: "POST",
      body: achBatchIds,
      format: "json",
      ...params,
    });
}
