import { TmHttpClient } from '@treasury/core/http';
import { WirePaymentDto } from '../../types/wire/wire-payment-dto.types';

export class WirePaymentsService {
    constructor(private readonly http: TmHttpClient) {}

    public async fetchWireActivity(body: object) {
        const response = await this.http.request<WirePaymentDto[]>('wires/Search?type=One+Time', {
            method: 'POST',
            body,
        });

        return {
            data: response,
            totalCount: response.length,
        };
    }
}
