import { DiContainer, Injectable } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { FiDate } from '../../../dates';
import corePagingParameters from '../../../shared/utilities/core-paging-parameters';
import { mapRecipientResponse } from '../../mappings/ach/ach-domestic-payments';
import { AchCompanyRequests } from '../../requests/ach';
import { AchRecipientsRequests } from '../../requests/ach/ach-recipients-requests';
import DownloadRequests from '../../requests/download/download.js';
import {
    CreateMasterListRecipientsParams,
    MasterListRecipient,
    MasterListRecipientDto,
    MasterListRecipientsRaw,
    PaymentRecipient,
    PaymentRecipientForSelect,
    RecipientsDownloadParams,
    TransactionType,
    UpdateMasterListRecipientObject,
} from '../../types/ach';
import { RecipientsFilterParams } from '../../types/ach/api';
import { RecipientAuditDto } from '../../types/ach/api/recipientAudit.dto';

export interface FetchRecipientsForMasterListParams {
    pagingParameters: ReturnType<typeof corePagingParameters>;
    startIndex: number;
    pageSize: number;
    institution: string;
    parameters: RecipientsFilterParams | void;
    searchText: string;
}

interface RecipientParams {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sort: any[];
    startIndex: number;
    pageSize: number;
    parameters: {
        searchText: string;
        achCompanyId: number;
    };
}

const transactionTypes: TransactionType = {
    1: {
        abbreviation: 'CR',
        name: 'Credit',
    },
    2: {
        abbreviation: 'DR',
        name: 'Debit',
    },
};

enum AchMasterRecipientStatusTypeId {
    All,
    PendingApproval,
    Ready,
    Rejected,
}

export const getTransactionTypeName = (transactionTypeId: number) =>
    transactionTypes[transactionTypeId].name;

const getTransactionTypeAbbreviation = (transactionTypeId: number) =>
    transactionTypes[transactionTypeId].abbreviation;

const formatFetchedRecipients = (
    fetchedRecipients: Array<MasterListRecipientDto>
): Array<PaymentRecipientForSelect> =>
    fetchedRecipients.map((item: MasterListRecipientDto) => {
        const {
            accountNumber,
            accountTypeDescription,
            recipientName,
            recipientIdNumber,
            routingNumber,
            achMasterRecipientStatusTypeDescription,
            defaultAmount,
            transactionTypeId,
            id,
        } = item;

        const _amount = defaultAmount ?? 0;
        const _status = achMasterRecipientStatusTypeDescription ?? '';

        return {
            id,
            name: recipientName,
            recipientIdNumber,
            accountNumber,
            accountType: accountTypeDescription,
            transactionType: getTransactionTypeName(transactionTypeId),
            amount: _amount,
            addenda: item.addenda,
            status: _status,
            routingNumber,
            step: 0,
            fromMasterList: true,
            selected: false,
        };
    });

export const getTransactionTypeIdFromTransactionType = (transactionType: string) =>
    transactionType === 'CR' ? 1 : 2;

export const getAccountTypeIdFromAccountTypeDescription = (
    accountTypeDescription: string
): number => {
    switch (accountTypeDescription) {
        case 'Checking':
            return 1;
        case 'Savings':
            return 2;
        case 'Loan':
            return 3;
        case 'General Ledger':
            return 7;
        default:
            return 1;
    }
};

const formatMasterRecipientsRawToMasterListRecipients = (
    recipientsRaw: MasterListRecipientsRaw
): Array<MasterListRecipient> => {
    const {
        pagedRecipients: { items },
    } = recipientsRaw;

    return items.map(item => ({
        ...item,
        transactionType: getTransactionTypeAbbreviation(item.transactionTypeId),
        addenda: 'Addenda',
        addendaRaw: item.addenda,
        actions: 'Actions',
        editable: false,
    }));
};

@Injectable()
export class AchRecipientsService {
    public static async uploadRecipients(file: File, format: string) {
        const response = await AchRecipientsRequests.uploadRecipients(file, format);
        const banks = await AchCompanyRequests.getAchBanks();
        return mapRecipientResponse(response, banks);
    }

    public static async downloadAchRecipients({
        type,
        page,
        params,
        startIndex,
        pageSize,
    }: RecipientsDownloadParams) {
        const fileSuffix = `${type.split(' ')[0]}-${new FiDate(new Date()).toIsoDate()}`;
        const { OrderBys, pageNumber } = corePagingParameters(
            'RecipientName',
            [],
            startIndex,
            pageSize
        );
        const buildOrderBys = [
            {
                name: OrderBys[0].Name,
                descending: false,
            },
        ];
        const filters = {
            achMasterRecipientStatusTypeId: params.status,
            amountFilter: {
                minimumAmount: params.amount[1],
                maximumAmount: params.amount[2],
            },
            page,
            pagingParameters: {
                pageSize,
                pageNumber,
                orderBys: buildOrderBys,
            },
            recipientName: params.recipientName,
            searchText: '',
        };
        const downloadFormats = ['CSV', 'PDF'];
        return DownloadRequests.download(fileSuffix, type, page, filters, downloadFormats);
    }

    public static async fetchValidRecipientsForPayment(args: RecipientParams) {
        const {
            sort,
            startIndex,
            pageSize,
            parameters: { searchText, achCompanyId },
        } = args;

        const params = {
            pagingParameters: corePagingParameters('RecipientName', sort, startIndex, pageSize),
            searchText,
            achCompanyId,
            achMasterRecipientStatusTypeId: AchMasterRecipientStatusTypeId.Ready,
        };
        params.pagingParameters.OrderBys = params.pagingParameters.OrderBys.map(orderBy => ({
            ...orderBy,
            Name: orderBy.Name === 'Name' ? 'RecipientName' : orderBy.Name,
        }));

        const fetchedRecipients = await AchRecipientsRequests.fetchRecipients(params);
        const paymentRecipients: Array<MasterListRecipientDto> =
            fetchedRecipients?.pagedRecipients?.items;
        const formattedFetchedRecipients = formatFetchedRecipients(paymentRecipients);

        return {
            data: formattedFetchedRecipients,
            totalCount: fetchedRecipients.pagedRecipients.totalCount || 0,
        };
    }

    public static async saveMasterRecipient(recipients: Array<PaymentRecipient>) {
        type SaveableRecipient = {
            accountNumber?: string;
            /** @format int32 */
            accountTypeId: number;
            addenda: string | null;
            /** @format decimal */
            defaultAmount: number;
            /** @format int32 */
            recipientIdNumber?: string;
            recipientName?: string;
            routingNumber?: string;
            /** @format int32 */
            transactionTypeId: number;
        };
        const convertPaymentRecipient = (recipient: PaymentRecipient): SaveableRecipient => {
            const accountTypes = {
                Checking: 1,
                Savings: 2,
                Loans: 3,
                GL: 7,
            };

            const transactionTypeCodes = {
                CR: 1,
                DR: 2,
            };

            // eslint-disable-next-line no-restricted-syntax
            const addenda = Array.isArray(recipient.addenda) ? recipient.addenda : null;
            const accountType = recipient.accountType as keyof typeof accountTypes;
            const bankRoutingNumber = recipient.bank?.bankId as string;
            const amount = recipient.amount ?? 0;
            return {
                accountNumber: recipient.accountNumber,
                accountTypeId: accountTypes[accountType],
                addenda,
                defaultAmount: amount,
                recipientIdNumber: recipient.idNumber ?? undefined,
                recipientName: recipient.name,
                routingNumber: bankRoutingNumber,
                transactionTypeId:
                    transactionTypeCodes[
                        recipient.transactionType as keyof typeof transactionTypeCodes
                    ],
            };
        };

        return AchRecipientsRequests.createOrUpdateRecipients(
            recipients.map(convertPaymentRecipient)
        );
    }

    public async approveRecipients(recipientIds: number[]) {
        const response = await AchRecipientsRequests.approveRecipients(recipientIds);
        const di = await DiContainer.getInstance();
        const analytics = await di.getAsync(AnalyticsService);

        analytics.track(AnalyticsEvent.AchRecipientsApproved, {
            recipientIds: recipientIds.map(id => id.toString()),
        });

        return response;
    }

    public async rejectRecipients(recipientIds: number[]) {
        return AchRecipientsRequests.rejectRecipients(recipientIds);
    }

    public async fetchRecipientsForMasterList(
        masterListParams: FetchRecipientsForMasterListParams
    ) {
        const { pagingParameters, institution, parameters, searchText } = masterListParams;
        const amountType = parameters?.amount[0];
        const minAmount = parameters?.amount[1];
        const maxAmount = parameters?.amount[2];

        const recipientFilter = {
            recipientName: parameters?.recipientName ? parameters.recipientName : '',
            statusTypeId: parameters?.status.length === 1 ? parameters?.status : '0',
        };

        const amountFilter = () => {
            const amountFilterObjNoType = {
                minimumAmount: minAmount,
                maximumAmount: maxAmount === 0 ? minAmount : maxAmount,
            };

            const amountFilterObj = {
                ...amountFilterObjNoType,
                amountType,
            };

            return amountType?.length === 0
                ? amountFilterObjNoType
                : (() => {
                      if (amountType === 'specific') {
                          return {
                              ...amountFilterObj,
                              minimumAmount: 0,
                              maximumAmount: 0,
                              amount: minAmount,
                          };
                      }

                      return {
                          ...amountFilterObj,
                          amount: 0,
                      };
                  })();
        };

        const params = {
            pagingParameters,
            recipientName: recipientFilter.recipientName,
            achMasterRecipientStatusTypeId: recipientFilter.statusTypeId,
            amountFilter: amountFilter(),
            searchText,
            achCompanyId: institution,
        };

        const constructOrderBysNameForAPI = (
            orderBy: (typeof params.pagingParameters.OrderBys)[0]
        ) => {
            if (orderBy.Name === 'AccountTypeDescription') {
                return 'AccountType.Name';
            }
            if (orderBy.Name === 'AchMasterRecipientStatusTypeDescription') {
                return 'AchMasterRecipientStatusType.Description';
            }
            return orderBy.Name;
        };

        params.pagingParameters.OrderBys = params.pagingParameters.OrderBys.map(orderBy => ({
            ...orderBy,
            Name: constructOrderBysNameForAPI(orderBy),
        }));

        const fetchedRecipients: MasterListRecipientsRaw =
            await AchRecipientsRequests.fetchMasterListRecipients(params);

        const formattedFetchedRecipients: Array<MasterListRecipient> =
            formatMasterRecipientsRawToMasterListRecipients(fetchedRecipients);

        return {
            data: formattedFetchedRecipients,
            totalCount: fetchedRecipients?.pagedRecipients?.totalCount,
        };
    }

    public async createRecipientsForMasterList(
        createRecipientsParams: CreateMasterListRecipientsParams
    ) {
        return AchRecipientsRequests.createOrUpdateRecipients(createRecipientsParams);
    }

    public async updateRecipientsForMasterList(
        updateRecipientsParams: Array<UpdateMasterListRecipientObject>
    ) {
        const reqArr = updateRecipientsParams.map((recipient: UpdateMasterListRecipientObject) => {
            const routingNumberString =
                typeof recipient?.routingNumber === 'string'
                    ? recipient?.routingNumber
                    : recipient?.routingNumber?.bankId;

            return {
                ...recipient,
                routingNumber: routingNumberString || '',
                transactionTypeId: getTransactionTypeIdFromTransactionType(
                    recipient.transactionType
                ),
                accountTypeId: getAccountTypeIdFromAccountTypeDescription(
                    recipient.accountTypeDescription
                ),
            };
        });
        return AchRecipientsRequests.createOrUpdateRecipients(reqArr);
    }

    public async deleteRecipients(recipientIds: number[]) {
        return AchRecipientsRequests.deleteRecipients(recipientIds);
    }

    public async getAchRecipientAudit(id: number) {
        const response = (await AchRecipientsRequests.getAchRecipientAudit(
            id
        )) as unknown as RecipientAuditDto;
        return response.audits;
    }
}
