RecurringWireListController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    'searchFilterService',
    'entitlementsService',
    'companyAccountsService',
    'currenciesService',
    'wireService',
    'toaster',
    'downloadPageId',
    'securityService',
    '$filter',
    'wireCompaniesService',
    '$q',
    'wireIsoService',
];

export default function RecurringWireListController(
    $scope,
    $state,
    $uibModal,
    searchFilterService,
    entitlementsService,
    companyAccountsService,
    currenciesService,
    wireService,
    toaster,
    downloadPageId,
    securityService,
    $filter,
    wireCompaniesService,
    $q,
    wireIsoService
) {
    $scope.downloadPageId = downloadPageId.RecurringWireList;
    $scope.companyAccounts = [];
    $scope.wireCompanies = [];
    $scope.currencies = null;
    $scope.dateOptions = null;
    $scope.filterObject = {};
    $scope.selectionList = [];
    $scope.statusOptions = null;
    $scope.wireAmountOptions = null;
    $scope.wires = null;
    $scope.createWire = createWire;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.frequencyTypeToFriendly = frequencyTypeToFriendly;
    $scope.goToRecurringWireDetail = goToRecurringWireDetail;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.hasPermissionToCreate = hasPermissionToCreate;
    $scope.isWireSftpEnabled = isWireSftpEnabled;
    $scope.print = print;
    $scope.cancel = cancel;
    $scope.resetSearch = resetSearch;
    $scope.search = search;
    $scope.disableDownload = true;

    $scope.filteredWires = {};
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.goToWireList = goToWireList;
    $scope.goToWireFileActivity = goToWireFileActivity;
    $scope.resetNextPaymentDates = resetNextPaymentDates;
    $scope.resetCreatedDates = resetCreatedDates;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });

    function setApprovalAttributes() {
        $scope.wires.map(wire => {
            wire.approvalAttributes = {
                approvalEntity: 'wire',
                approvalCount: wire.completedApprovalCount,
                amount: wire.amount,
                createdBy: wire.createdBy,
                updatedBy: wire.updatedBy,
                productId: wire.id,
            };
            return wire;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredWires = $filter('filter')($scope.wires, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.wires ||
            $scope.wires.length <= 0
        ) {
            return true;
        }

        const searchText = $scope.searchObj.text.toLowerCase();

        return (
            (!!row.transactionId && row.transactionId.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.debitAccount &&
                !!row.debitAccount.number &&
                row.debitAccount.number.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.beneficiary &&
                !!row.beneficiary.name &&
                row.beneficiary.name.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.wireCompany &&
                !!row.wireCompany.name &&
                row.wireCompany.name.indexOf(searchText) !== -1) ||
            (!!row.destinationCurrency &&
                row.destinationCurrency.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.createdDate &&
                $filter('date')(row.createdDate, 'MM/dd/yyyy').indexOf(searchText) !== -1) ||
            (!!row.frequency &&
                !!row.frequency.nextPaymentDate &&
                $filter('date')(row.frequency.nextPaymentDate, 'MM/dd/yyyy').indexOf(searchText) !==
                    -1) ||
            (!!row.frequency &&
                !!row.frequency.endOn &&
                $filter('date')(row.frequency.endOn, 'MM/dd/yyyy').indexOf(searchText) !== -1) ||
            (!!row.amount && $filter('currency')(row.amount, '$').indexOf(searchText) !== -1) ||
            (!!row.status && row.status.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.frequency.type &&
                $scope
                    .frequencyTypeToFriendly(row.frequency.type)
                    .toLowerCase()
                    .indexOf(searchText) !== -1)
        );
    }

    function disableFilter() {
        return $scope.wires ? $scope.wires.length <= 0 : true;
    }

    function goToWireList() {
        $state.go('payables.wire.wire-list');
    }

    function goToWireFileActivity() {
        $state.go('payables.wire.wire-file-activity');
    }

    init();

    function init() {
        $scope.dateOptions = ['Specific Date', 'Date Range'];
        $scope.wireAmountOptions = ['Specific Amount', 'Amount Range'];
        $scope.frequencies = [
            { key: 'Weekly', value: 'Weekly', isChecked: true },
            { key: 'EveryTwoWeeks', value: 'Every Two Weeks', isChecked: true },
            { key: 'TwiceAMonth', value: 'Twice a Month', isChecked: true },
            { key: 'Monthly', value: 'Monthly', isChecked: true },
            { key: 'Quarterly', value: 'Quarterly', isChecked: true },
            { key: 'EverySixMonths', value: 'Every Six Months', isChecked: true },
            { key: 'Yearly', value: 'Yearly', isChecked: true },
        ];

        $scope.statusOptions = [
            { name: 'Pending Approval' },
            { name: 'Approval Rejected' },
            { name: 'Cancelled' },
            { name: 'Scheduled' },
            { name: 'Completed' },
            { name: 'Failed' },
        ];
        $scope.currentDate = moment().format('MM/DD/YYYY');

        resetSearch();
        searchFilterService.restoreFilter($scope.filterObject);

        $q.all([loadWireCompanies(), loadCompanyAccounts(), loadCurrencies()]).then(() => {
            search();
        });
    }

    function resetSearch() {
        $scope.filterObject = {
            wireAmountType: $scope.wireAmountOptions[0],
            nextPaymentDateType: $scope.dateOptions[0],
            createdDateType: $scope.dateOptions[0],
        };
        angular.forEach($scope.statusOptions, statusOption => {
            statusOption.isChecked = true;
        });
        angular.forEach($scope.companyAccounts, companyAccount => {
            companyAccount.isChecked = true;
        });
        angular.forEach($scope.wireCompanies, wireCompany => {
            wireCompany.isChecked = true;
        });
        angular.forEach($scope.frequencies, frequency => {
            frequency.isChecked = true;
        });
    }

    function search() {
        searchFilterService.saveFilter($scope.filterObject, ['payables/wire/wire-details']);
        wireService.search($scope.filterObject, 'Recurring').then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.searchObj.text = null;
            $scope.wires = response;
            if ($scope.wires.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);
        });
    }

    function goToRecurringWireDetail(wire) {
        $state.go('payables.wire.wire-details', { id: wire.id, type: 'recurringWireList' });
    }

    function createWire() {
        $state.go('payables.wire.create-wire');
    }

    function getFrequencySummary(wire) {
        return wireService.summarizeFrequency(wire.frequency);
    }

    function frequencyTypeToFriendly(frequencyType) {
        return wireService.frequencyTypeToFriendly(frequencyType);
    }

    function hasPermissionToCreate() {
        const domestic = entitlementsService.hasEntitlement('Wire, Domestic, Create Wire');
        const international = entitlementsService.hasEntitlement(
            'Wire, International, Create Wire'
        );

        return domestic || international;
    }

    function isWireSftpEnabled() {
        return entitlementsService.hasEntitlement('Feature.Wire.SFTP.DataTransmissions');
    }

    function print() {
        $('#wireTable').printThis();
    }

    function hasAccessPermissions(wire, permissionType) {
        let result = false;
        angular.forEach(wire.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function cancel(wire) {
        const modalInstance = $uibModal.open({
            template: require('../views/removeWireDialogView.html'),
            size: 'lg',
            controller: 'RemoveWireController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'recurringWireList';
                },
            },
        });

        modalInstance.result.then(comments => {
            const cancelWireModel = {
                wireId: wire.id,
                comments,
            };
            securityService
                .verifyUser('Delete Wire Payment', cancelWireModel, response =>
                    wireService.cancel(cancelWireModel)
                )
                .finally(() => window.location.reload());
        });
    }

    //
    // Private
    function loadWireCompanies() {
        return wireCompaniesService.getAll().then(response => {
            let wireCompanies = response;

            wireCompanies.forEach(wireCompany => {
                wireCompany.isChecked = true;

                if (wireCompany.isDliWireCompany) {
                    wireCompany.name += ' - International';
                }
            });

            wireCompanies = $filter('orderBy')(wireCompanies, 'name');

            $scope.wireCompanies = wireCompanies;
            $scope.filterObject.wireCompanies = wireCompanies;
        });
    }

    function loadCompanyAccounts() {
        return companyAccountsService.getCompanyAccounts('WireTransfer').then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.companyAccounts = response;
            $scope.filterObject.debitAccounts = response;

            angular.forEach($scope.companyAccounts, companyAccount => {
                companyAccount.isChecked = true;
            });
        });
    }

    function loadCurrencies() {
        return currenciesService.getAll().then(response => {
            $scope.currencies = response;
        });
    }

    function resetNextPaymentDates() {
        $scope.filterObject.nextPaymentDateStart = null;
        $scope.filterObject.nextPaymentDateEnd = null;
        $scope.filterObject.nextPaymentDate = null;
    }

    function resetCreatedDates() {
        $scope.filterObject.createdDateStart = null;
        $scope.filterObject.createdDateEnd = null;
        $scope.filterObject.createdDate = null;
    }
}
