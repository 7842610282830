import { exists } from './object.helpers';

export const phoneFormatter = (value?: string) => {
    if (!exists(value)) {
        return '';
    }

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    // return the value with no formatting if its less than four digits
    // to avoid weird behavior that occurs if you format the area code too early
    if (phoneNumberLength < 4) {
        return phoneNumber;
    }

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};
