/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AdditionalSettingsModelDto,
  ApprovalListModelDto,
  ApprovalListRequestModelDto,
  BaseResponse2Dto,
  ChallengesSupportedResponseModelDto,
  CompanyAccountModelDto,
  CurrentUserTimeAccessDto,
  DeleteUserModelDto,
  ElectronicDocumentSsoDto,
  ElectronicDocumentsModelDto,
  ErrorResponseModelDto,
  GetUserAccountAccessModelDto,
  GetUserAchEntitlementsModelDto,
  GetUserAchLimitsModelDto,
  GetUserProductEntitlementsModelDto,
  GetUserProductLimitsResponseModelDto,
  IpRestrictionModelDto,
  LookupModelDto,
  ReceivablesUserSettingsModelDto,
  SaveReceivablesUserSettingsModelDto,
  SaveSsoPosPayUserSettingsModelDto,
  SaveUisUserEnrollmentDetailRequestModelDto,
  SaveUserAccountAccessModelDto,
  SaveUserAchLimitsRequestModelDto,
  SaveUserAchProductEntitlementsModelDto,
  SaveUserActivityRequestDto,
  SaveUserIpAccessRequestModelDto,
  SaveUserProductEntitlementsModelDto,
  SaveUserProductLimitsRequestModelDto,
  SaveUserTimeAccessRequestModelDto,
  SearchUserModelDto,
  SecurityBaseDto,
  SsoPosPayUserSettingsModelDto,
  SsoViaUisResponseDto,
  UisEnrollmentLinkRequestModelDto,
  UisEnrollmentLinkResponseModelDto,
  UserInformationModelDto,
  UserInformationResponseModelDto,
  UserModelDto,
  UserProductFeatureSummaryModelDto,
  UserSummaryModelDto,
  UserTimeAccess2Dto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class UsersClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
 * No description
 *
 * @tags Users
 * @name UsersForgotPassword
 * @summary Validates User for Forgot Password Functionality.
If the provided information is correct it will send an email,
otherwise returns false
 * @request POST:/users/forgotpassword
 */
  usersForgotPassword = (userModel: UserModelDto | null, params: RequestParams = {}) =>
    this.http.request<File | null, any>({
      path: `/users/forgotpassword`,
      method: "POST",
      body: userModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSearch
   * @summary Searches for users.
   * @request POST:/users/search
   */
  usersSearch = (searchUserModel: SearchUserModelDto, params: RequestParams = {}) =>
    this.http.request<UserSummaryModelDto[], any>({
      path: `/users/search`,
      method: "POST",
      body: searchUserModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSetActive
   * @summary Sets as user as active or inactive
   * @request PUT:/users/{id}/setactive
   */
  usersSetActive = (
    id: number,
    query: {
      isActive: boolean;
      /** @default "" */
      comments?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<UserSummaryModelDto, any>({
      path: `/users/${id}/setactive`,
      method: "PUT",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersUnlock
   * @summary Unlocks a user
   * @request PUT:/users/{id}/unlock
   */
  usersUnlock = (id: number, params: RequestParams = {}) =>
    this.http.request<UserSummaryModelDto, any>({
      path: `/users/${id}/unlock`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetHeader
   * @summary Gets the header information when editing a user
   * @request GET:/users/{id}/header
   */
  usersGetHeader = (id: number, params: RequestParams = {}) =>
    this.http.request<UserSummaryModelDto, any>({
      path: `/users/${id}/header`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersResetPassword
   * @summary Resets the password.
   * @request POST:/users/{id}/ResetPassword
   */
  usersResetPassword = (id: number, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/ResetPassword`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersManualResetPassword
   * @summary Manuals the reset password.
   * @request POST:/users/{id}/ManualResetPassword
   */
  usersManualResetPassword = (id: number, password: LookupModelDto | null, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/ManualResetPassword`,
      method: "POST",
      body: password,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetProductFeatureSummary
   * @summary Gets the product feature summary when editing a user
   * @request GET:/users/{id}/productfeaturesummary
   */
  usersGetProductFeatureSummary = (id: number, params: RequestParams = {}) =>
    this.http.request<UserProductFeatureSummaryModelDto, any>({
      path: `/users/${id}/productfeaturesummary`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSubmit
   * @summary Submits new user information
   * @request PUT:/users/{id}/submit
   */
  usersSubmit = (id: number, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/submit`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersDiscard
   * @summary Discards user information that is pending submission
   * @request PUT:/users/{id}/discard
   */
  usersDiscard = (
    id: number,
    query?: {
      /** @default "" */
      comments?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/discard`,
      method: "PUT",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersApprove
   * @summary Approves user information that has been submitted
   * @request PUT:/users/{id}/approve
   */
  usersApprove = (
    id: number,
    query?: {
      /** @default "" */
      comments?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/approve`,
      method: "PUT",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersReject
   * @summary Rejects user information that has been submitted
   * @request PUT:/users/{id}/reject
   */
  usersReject = (
    id: number,
    query?: {
      /** @default "" */
      comments?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/reject`,
      method: "PUT",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersDelete
   * @summary Soft Delete the selected User.
   * @request DELETE:/users
   */
  usersDelete = (deleteUserModel: DeleteUserModelDto | null, params: RequestParams = {}) =>
    this.http.request<SecurityBaseDto, any>({
      path: `/users`,
      method: "DELETE",
      body: deleteUserModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserInformation
   * @summary Gets a user's User Information
   * @request GET:/users/{id}/userinformation
   */
  usersGetUserInformation = (id: number, params: RequestParams = {}) =>
    this.http.request<UserInformationResponseModelDto, any>({
      path: `/users/${id}/userinformation`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserInformation
   * @summary Updates a user with User Information
   * @request POST:/users/{id}/userinformation
   */
  usersSaveUserInformation = (
    id: number,
    userInformation: UserInformationModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<UserInformationModelDto, any>({
      path: `/users/${id}/userinformation`,
      method: "POST",
      body: userInformation,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserTimeAccess
   * @summary Gets a User's Time Access
   * @request GET:/users/{id}/usertimeaccess
   */
  usersGetUserTimeAccess = (id: number, params: RequestParams = {}) =>
    this.http.request<UserTimeAccess2Dto, any>({
      path: `/users/${id}/usertimeaccess`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserTimeAccess
   * @summary Updates a User's Time Access
   * @request POST:/users/{id}/usertimeaccess
   */
  usersSaveUserTimeAccess = (
    id: number,
    userTimeAccess: SaveUserTimeAccessRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/usertimeaccess`,
      method: "POST",
      body: userTimeAccess,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserAccountAccess
   * @summary Gets a user's Account Access
   * @request GET:/users/{id}/accountaccess
   */
  usersGetUserAccountAccess = (id: number, params: RequestParams = {}) =>
    this.http.request<GetUserAccountAccessModelDto, any>({
      path: `/users/${id}/accountaccess`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserAccountAccess
   * @summary Updates a user's Account Access
   * @request PUT:/users/{id}/accountaccess
   */
  usersSaveUserAccountAccess = (
    id: number,
    accountAccess: SaveUserAccountAccessModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/accountaccess`,
      method: "PUT",
      body: accountAccess,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserProductLimits
   * @summary Gets a User's Product Limits Config
   * @request GET:/users/{id}/productlimits
   */
  usersGetUserProductLimits = (
    id: number,
    query: {
      productFeatureType: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetUserProductLimitsResponseModelDto, any>({
      path: `/users/${id}/productlimits`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserProductLimits
   * @summary Updates a User's Product Limits
   * @request PUT:/users/{id}/productlimits
   */
  usersSaveUserProductLimits = (
    id: number,
    productLimits: SaveUserProductLimitsRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/productlimits`,
      method: "PUT",
      body: productLimits,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserProductEntitlements
   * @summary Gets a user's Product Entitlements Config
   * @request GET:/users/{id}/productentitlements
   */
  usersGetUserProductEntitlements = (
    id: number,
    query: {
      productFeatureType: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetUserProductEntitlementsModelDto, any>({
      path: `/users/${id}/productentitlements`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserProductEntitlements
   * @summary Updates a user's Product Entitlements
   * @request PUT:/users/{id}/productentitlements
   */
  usersSaveUserProductEntitlements = (
    id: number,
    saveUserProductEntitlementsModel: SaveUserProductEntitlementsModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/productentitlements`,
      method: "PUT",
      body: saveUserProductEntitlementsModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserAchEntitlements
   * @summary Gets a user's Ach Entitlements
   * @request GET:/users/{id}/achproductentitlements
   */
  usersGetUserAchEntitlements = (id: number, params: RequestParams = {}) =>
    this.http.request<GetUserAchEntitlementsModelDto, any>({
      path: `/users/${id}/achproductentitlements`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserAchProductEntitlements
   * @summary Saves the user ach product entitlements.
   * @request PUT:/users/{id}/achproductentitlements
   */
  usersSaveUserAchProductEntitlements = (
    id: number,
    saveUserProductEntitlementsModel: SaveUserAchProductEntitlementsModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/achproductentitlements`,
      method: "PUT",
      body: saveUserProductEntitlementsModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserAchLimits
   * @summary Gets a user's Ach limits
   * @request GET:/users/{id}/achlimits
   */
  usersGetUserAchLimits = (id: number, params: RequestParams = {}) =>
    this.http.request<GetUserAchLimitsModelDto, any>({
      path: `/users/${id}/achlimits`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserAchLimits
   * @summary Saves the user ach limits.
   * @request PUT:/users/{id}/achlimits
   */
  usersSaveUserAchLimits = (
    id: number,
    achLimits: SaveUserAchLimitsRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/achlimits`,
      method: "PUT",
      body: achLimits,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetIpRestrictions
   * @summary Gets the ip restrictions.
   * @request GET:/users/{id}/iprestrictions
   */
  usersGetIpRestrictions = (id: number, params: RequestParams = {}) =>
    this.http.request<IpRestrictionModelDto, any>({
      path: `/users/${id}/iprestrictions`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersPutIpRestrictions
   * @summary Puts the ip restrictions.
   * @request PUT:/users/{id}/iprestrictions
   */
  usersPutIpRestrictions = (
    id: number,
    query: {
      /** if set to true [restrict]. */
      restrict: boolean;
    },
    model: SaveUserIpAccessRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/iprestrictions`,
      method: "PUT",
      query: query,
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetElectronicDocumentSso
   * @summary Gets the electronic document sso.
   * @request GET:/users/getElectronicDocumentSso
   */
  usersGetElectronicDocumentSso = (params: RequestParams = {}) =>
    this.http.request<ElectronicDocumentSsoDto, any>({
      path: `/users/getElectronicDocumentSso`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetApprovers
   * @summary Gets the approvers.
   * @request POST:/users/getApprovers
   */
  usersGetApprovers = (request: ApprovalListRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<ApprovalListModelDto, any>({
      path: `/users/getApprovers`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetSsoPosPayUserSettings
   * @summary Gets the sso position pay user settings.
   * @request GET:/users/{id}/ssopospayusersettings
   */
  usersGetSsoPosPayUserSettings = (id: number, params: RequestParams = {}) =>
    this.http.request<SsoPosPayUserSettingsModelDto, any>({
      path: `/users/${id}/ssopospayusersettings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersPutSsoPosPayUserSettings
   * @summary Puts the sso position pay user settings.
   * @request PUT:/users/{id}/ssopospayusersettings
   */
  usersPutSsoPosPayUserSettings = (
    id: number,
    model: SaveSsoPosPayUserSettingsModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/ssopospayusersettings`,
      method: "PUT",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUserReceivablesSettings
   * @summary Gets the user receivables settings.
   * @request GET:/users/{id}/receivablessettings
   */
  usersGetUserReceivablesSettings = (id: number, params: RequestParams = {}) =>
    this.http.request<ReceivablesUserSettingsModelDto, any>({
      path: `/users/${id}/receivablessettings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersPutUserReceivablesSettings
   * @summary Puts the user receivables settings.
   * @request PUT:/users/{id}/receivablessettings
   */
  usersPutUserReceivablesSettings = (
    id: number,
    model: SaveReceivablesUserSettingsModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ChallengesSupportedResponseModelDto, any>({
      path: `/users/${id}/receivablessettings`,
      method: "PUT",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetAccountsAccessibleToUser
   * @summary Gets the accounts accessible to user.
   * @request GET:/users/accessibleaccounts
   */
  usersGetAccountsAccessibleToUser = (params: RequestParams = {}) =>
    this.http.request<CompanyAccountModelDto[], any>({
      path: `/users/accessibleaccounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetElectronicDocumentsAdditionalSettingsAndAccounts
   * @summary Gets the electronic documents additional settings and accounts.
   * @request GET:/users/electronicdocuments/{electronicDocumentTypeId}/additionalsettingsandaccounts
   */
  usersGetElectronicDocumentsAdditionalSettingsAndAccounts = (
    electronicDocumentTypeId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<ElectronicDocumentsModelDto, any>({
      path: `/users/electronicdocuments/${electronicDocumentTypeId}/additionalsettingsandaccounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetElectronicDocumentsAdditionalSettingsAndAccounts2
   * @summary Gets the electronic documents additional settings and accounts.
   * @request GET:/users/wausaustatements/{electronicDocumentTypeId}/additionalsettingsandaccounts
   */
  usersGetElectronicDocumentsAdditionalSettingsAndAccounts2 = (
    electronicDocumentTypeId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<ElectronicDocumentsModelDto, any>({
      path: `/users/wausaustatements/${electronicDocumentTypeId}/additionalsettingsandaccounts`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetAdditionalRemoteDepositSettings
   * @summary Gets the additional remote deposit settings.
   * @request GET:/users/getAdditionalRemoteDepositSettings
   */
  usersGetAdditionalRemoteDepositSettings = (params: RequestParams = {}) =>
    this.http.request<AdditionalSettingsModelDto, any>({
      path: `/users/getAdditionalRemoteDepositSettings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description This will return the current users time access details which include if the user has a time access restriction and, if they do, the details of that restriction.
   *
   * @tags Users
   * @name UsersGetCurrentUserTimeAccess
   * @summary Gets the current users time access details.
   * @request GET:/users/usertimeaccess
   */
  usersGetCurrentUserTimeAccess = (params: RequestParams = {}) =>
    this.http.request<CurrentUserTimeAccessDto, ErrorResponseModelDto>({
      path: `/users/usertimeaccess`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUserActivity
   * @summary Saves user activity
   * @request POST:/users/saveuseractivity
   */
  usersSaveUserActivity = (saveUserActivityRequest: SaveUserActivityRequestDto | null, params: RequestParams = {}) =>
    this.http.request<BaseResponse2Dto, any>({
      path: `/users/saveuseractivity`,
      method: "POST",
      body: saveUserActivityRequest,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGenerateEnrollmentEmail
   * @summary Generates an enrollment email and sends it to the user's registered email.
   * @request POST:/users/{id}/generateenrollmentemail
   */
  usersGenerateEnrollmentEmail = (id: number, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/users/${id}/generateenrollmentemail`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersGetUisEnrollmentLink
   * @summary Generates an Uis enrollment link for a user.
   * @request POST:/users/getUisEnrollmentLink
   */
  usersGetUisEnrollmentLink = (
    uisEnrollmentLinkRequestModel: UisEnrollmentLinkRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<UisEnrollmentLinkResponseModelDto, any>({
      path: `/users/getUisEnrollmentLink`,
      method: "POST",
      body: uisEnrollmentLinkRequestModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUisEnrollmentLink
   * @summary Saves Uis enrollment details in treasury database after user created in UIS.
   * @request POST:/users/saveUisUserEnrollmentDetail
   */
  usersSaveUisEnrollmentLink = (
    saveUisUserEnrollmentDetailRequestModel: SaveUisUserEnrollmentDetailRequestModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/users/saveUisUserEnrollmentDetail`,
      method: "POST",
      body: saveUisUserEnrollmentDetailRequestModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSaveUisEnrollmentLinkAndLogin
   * @summary Saves Uis enrollment details in treasury database after user created in UIS and create the token.
   * @request POST:/users/saveUisUserEnrollmentDetailAndLogin
   */
  usersSaveUisEnrollmentLinkAndLogin = (
    saveUisUserEnrollmentDetailRequestModel: SaveUisUserEnrollmentDetailRequestModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<SsoViaUisResponseDto, any>({
      path: `/users/saveUisUserEnrollmentDetailAndLogin`,
      method: "POST",
      body: saveUisUserEnrollmentDetailRequestModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersSearchUsers
   * @summary Searches for users.
   * @request POST:/users/searchUsers
   */
  usersSearchUsers = (searchUserModel: SearchUserModelDto, params: RequestParams = {}) =>
    this.http.request<UserSummaryModelDto[], any>({
      path: `/users/searchUsers`,
      method: "POST",
      body: searchUserModel,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name UsersResetPasswordLink
   * @summary Sends a request password link for the end user. (UIS enabled)
   * @request GET:/users/{userId}/resetpasswordlink
   */
  usersResetPasswordLink = (userId: string, params: RequestParams = {}) =>
    this.http.request<UserSummaryModelDto[], any>({
      path: `/users/${userId}/resetpasswordlink`,
      method: "GET",
      format: "json",
      ...params,
    });
}
