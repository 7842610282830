/* eslint-disable no-use-before-define */
import { JhHttpResponseType, JhRequestConfig } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';
import { BrowserFetch, Constructor, HttpVerb } from '@treasury/utils';
import { HttpClient as BoHttpClient } from '../bo/generated/http-client';
import { HttpClient as ChannelHttpClient } from '../channel/generated/http-client';

type FetchParams = Parameters<BrowserFetch>;
type CombinedCtorParams =
    | ConstructorParameters<typeof ChannelHttpClient>
    | ConstructorParameters<typeof BoHttpClient>;

/**
 *
 * @param tmClient `TmHttpClient` instance.
 * @param HttpClientCtor Constructor for the `HttpClient` included with the `swagger-typescript-api` package.
 * @returns
 */
export function createSwaggerClient<HttpClient extends ChannelHttpClient | BoHttpClient>(
    tmClient: TmHttpClient,
    HttpClientCtor: Constructor<HttpClient, CombinedCtorParams>
) {
    return new HttpClientCtor({
        baseUrl: '',
        customFetch: createTmFetchAdapter(tmClient),
    });
}

/**
 * Creates an adapter function that transforms `fetch()` parameters
 * into `TmHttpClient` ones and performs a request.
 */
function createTmFetchAdapter(tmHttpClient: TmHttpClient) {
    return function customFetch(...params: FetchParams) {
        const [input, init] = params;

        let url = '';
        if (typeof input === 'string') {
            url = input;
        } else if (input instanceof Request) {
            url = input.url;
        } else {
            url = url.toString();
        }

        // HACK: Remove leading slash so our fetch client can generate the full path dynamically
        if (url.charAt(0) === '/') url = url.substring(1);

        const { method, body, maxAgeInSeconds, ttl, disableContentType, withoutBase } = (init ||
            {}) as FetchParams[1] & JhRequestConfig;
        const config: JhRequestConfig = {
            body: typeof body === 'string' ? JSON.parse(body) : (body as object),
            method: (method ?? 'GET') as HttpVerb,
            responseType: JhHttpResponseType.Raw,
            maxAgeInSeconds,
            ttl,
            disableContentType,
            withoutBase,
        };

        return tmHttpClient.request<Response>(url, config);
    };
}
