/* eslint-disable no-use-before-define */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@jack-henry/frontend-utils/di';
import { delay } from '@jack-henry/frontend-utils/functions';
import {
    DynamicComponentExitReason,
    DynamicComponentHandle,
    DynamicComponentRegistry,
    DynamicComponentResult,
} from '@jack-henry/frontend-utils/lit/dynamic-component-registry';
import { exists, getKeys, noOp } from '@treasury/utils';
import { html, nothing, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import '../../components/omega-dialog';
import { OmegaDialog } from '../../components/omega-dialog';
import '../../components/omega-dialog-branded';
import {
    DialogButton,
    DialogButtonPosture,
    OmegaDialogHandle,
    OmegaDialogOptions,
    OmegaDialogOptionsAll,
    postureToTypeMap,
} from './omega-dialog.service.types';

const defaultOptions: OmegaDialogOptionsAll = Object.freeze({
    buttons: {
        [DynamicComponentExitReason.Confirm]: {
            label: 'OK',
            onClick: noOp,
            posture: DialogButtonPosture.Primary,
        },
        [DynamicComponentExitReason.Cancel]: {
            label: 'Cancel',
            onClick: noOp,
            posture: DialogButtonPosture.Secondary,
        },
        [DynamicComponentExitReason.ForceClose]: null,
    },
    host: window.document.body,
    renderButtons: true,
    preventClose: false,
    flexDirection: 'row',
    height: 100,
    heightUnits: '%',
    width: 100,
    widthUnits: '%',
});

/**
 * Service for rendering arbitrary content into an `<omega-dialog>`.
 */
@Injectable()
export class OmegaDialogService {
    /**
     * Tracks state about any currently open dialogs.
     *
     * At present, the service only supports modal dialogs (i.e., one at a time).
     * The registry supports multiple dialogs, but an appropriate UX has not been decided.
     */
    private readonly dialogs = new DynamicComponentRegistry<OmegaDialog>();

    public open<Elem extends HTMLElement = HTMLElement, T = unknown>(
        content: string | TemplateResult,
        title: string,
        options: OmegaDialogOptions = {}
    ): OmegaDialogHandle<Elem, T> {
        const allOptions = normalizeOptions(options);
        const template = this.renderDialog<T>(content, title, allOptions, result => {
            this.closeDialog<T>(handle, result);
        });
        const handle = this.dialogs.create<T>(template, allOptions);
        const { element, listenFor } = handle;
        const contentElement = this.getDialogContentElement<Elem>(element);

        // listen for close events raised by the element inside
        // the dialog since it may not own its buttons
        listenFor<DynamicComponentResult<T>>('close', ({ detail }) => {
            this.closeDialog(handle, detail);
        });

        return {
            ...handle,
            close: result => {
                this.closeDialog<T>(handle, result);
            },
            closed: handle.disposed,
            content: contentElement,
        };
    }

    public closeAll() {
        this.dialogs.destroyAll();
    }

    private closeDialog<T>(
        handle: DynamicComponentHandle<OmegaDialog, T>,
        result?: DynamicComponentResult<T>
    ) {
        const { element } = handle;
        if (element.open) {
            element.open = false;
        }

        // allow time for animation
        const delayPromise = delay(500);
        handle.dispose(result, delayPromise);
    }

    private getDialogContentElement<Elem extends Element>(dialogElement: OmegaDialog) {
        const contentContainer = dialogElement.querySelector('.dialog-content');

        if (!contentContainer) {
            throw new Error('Could not get dialog content element.');
        }

        if (!contentContainer.firstElementChild) {
            throw new Error(
                'Could not get dialog content element. Did you pass a template containing text with no parent element?'
            );
        }

        return contentContainer.firstElementChild as Elem;
    }

    private renderButtons<T>(
        buttons: OmegaDialogOptionsAll['buttons'],
        options: OmegaDialogOptionsAll,
        closeDialog: (result: DynamicComponentResult<T>) => void
    ) {
        const { preventClose, renderButtons } = options;

        if (!renderButtons) {
            return nothing;
        }

        const buttonElements: TemplateResult[] = [];
        const confirmBtn = buttons[DynamicComponentExitReason.Confirm];
        const cancelBtn = buttons[DynamicComponentExitReason.Cancel];

        if (exists(confirmBtn)) {
            buttonElements.push(
                html` <omega-button
                    slot="actions"
                    type=${postureToTypeMap[confirmBtn.posture]}
                    @click=${() =>
                        closeDialog({
                            reason: DynamicComponentExitReason.Confirm,
                        })}
                    >${confirmBtn.label}</omega-button
                >`
            );
        }

        if (exists(cancelBtn) && !preventClose) {
            buttonElements.push(
                html` <omega-button
                    slot="actions"
                    type=${postureToTypeMap[cancelBtn.posture]}
                    @click=${() =>
                        closeDialog({
                            reason: DynamicComponentExitReason.Cancel,
                        })}
                    >${cancelBtn.label}</omega-button
                >`
            );
        }

        return buttonElements;
    }

    private renderDialog<T>(
        content: string | TemplateResult,
        title: string,
        options: OmegaDialogOptionsAll,
        closeDialog: (result: DynamicComponentResult<T>) => void
    ) {
        const {
            buttons,
            preventClose,
            logoSource,
            height,
            heightUnits,
            width,
            widthUnits,
            headerIcon,
        } = options;

        const contentTemplate = typeof content === 'string' ? html`<p>${content}</p>` : content;

        if (logoSource) {
            return html` <omega-dialog-branded
                .hideCloseButton=${preventClose}
                @close=${(e: CustomEvent<DynamicComponentResult<T>>) => closeDialog(e.detail)}
                dialogTitle="${title}"
                logoSource=${logoSource}
                open
            >
                <div
                    class="dialog-content"
                    slot="content"
                    style=${`padding: 20px; min-width: 80%; max-width: 100%; width: ${width}${widthUnits}
                    ; height: ${height}${heightUnits}; min-height: 80%;`}
                >
                    ${contentTemplate}
                </div>
                ${this.renderButtons(buttons, options, closeDialog)}
            </omega-dialog-branded>`;
        }

        return html` <omega-dialog
            .hideCloseButton=${preventClose}
            @close=${(e: CustomEvent<DynamicComponentResult<T>>) => closeDialog(e.detail)}
            dialogTitle="${title}"
            headerIcon=${ifDefined(headerIcon)}
            open
        >
            <div
                class="dialog-content"
                slot="content"
                style=${`padding: 20px; min-width: 80%;  max-width: 100%; width: ${width}${widthUnits}; height: ${height}${heightUnits}; min-height: 80%;`}
            >
                ${contentTemplate}
            </div>
            ${this.renderButtons(buttons, options, closeDialog)}
        </omega-dialog>`;
    }
}

function normalizeOptions(options: OmegaDialogOptions): OmegaDialogOptionsAll {
    const mergedButtons = getKeys(defaultOptions.buttons).reduce(
        (buttons, key) => {
            const defaultButton = defaultOptions.buttons?.[key];
            const button = options.buttons?.[key];
            buttons[key] =
                // support explicitly removing buttons with null value
                button === null ? null : ({ ...defaultButton, ...button } as DialogButton);
            return buttons;
        },
        {} as OmegaDialogOptionsAll['buttons']
    );

    if (options.height && !options.heightUnits) {
        options.heightUnits = 'px';
    }

    if (options.width && !options.widthUnits) {
        options.widthUnits = 'px';
    }

    return {
        ...defaultOptions,
        ...options,
        ...{
            // merge nested properties this way until a deep merge helper exists
            buttons: mergedButtons,
        },
    };
}
