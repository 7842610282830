import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

const dateFilterOptions = {
    specificDate: 1,
    dateRange: 2,
    noDate: 0,
};

export const mapReturnActivityResponse = items =>
    items.map(item => ({
        ...item,
        fullReturnReason: `${item.returnReasonCode} - ${item.returnReasonDescription}`,
    }));

export const mapReturnActivitySearchFilter = (parameters, achCompanyTotal = null) => ({
    EffectiveDateFilterOption:
        DateFormatters.parseDate(parameters.effectiveDate) === null
            ? dateFilterOptions.noDate
            : dateFilterOptions[DateFormatters.getDateType(parameters.effectiveDate)],
    EffectiveDateStart:
        DateFormatters.parseDate(parameters.effectiveDate) === null
            ? ''
            : (DateFormatters.parseDate(parameters.effectiveDate).value.start ??
              DateFormatters.parseDate(parameters.effectiveDate).value),
    EffectiveDateEnd:
        DateFormatters.parseDate(parameters.effectiveDate) === null
            ? ''
            : (DateFormatters.parseDate(parameters.effectiveDate).value.end ??
              DateFormatters.parseDate(parameters.effectiveDate).value),
    ReceivedDateFilterOption:
        DateFormatters.parseDate(parameters.receivedDate) === null
            ? dateFilterOptions.noDate
            : dateFilterOptions[DateFormatters.getDateType(parameters.receivedDate)],
    ReceivedDateStart:
        DateFormatters.parseDate(parameters.receivedDate) === null
            ? ''
            : (DateFormatters.parseDate(parameters.receivedDate).value.start ??
              DateFormatters.parseDate(parameters.receivedDate).value),
    ReceivedDateEnd:
        DateFormatters.parseDate(parameters.receivedDate) === null
            ? ''
            : (DateFormatters.parseDate(parameters.receivedDate).value.end ??
              DateFormatters.parseDate(parameters.receivedDate).value),
    AchCompanies:
        parameters.achCompanyIds.length === achCompanyTotal ||
        parameters.achCompanyIds === 'all' ||
        typeof parameters.achCompanyIds === 'undefined'
            ? []
            : parameters.achCompanyIds.map(achCompany => achCompany.companyGuidUniqueId),
    RecipientName: parameters.recipientName,
});
