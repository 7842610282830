import { CompanyOptionModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export class CompanyOptionModel extends DomainEntity<CompanyOptionModelDto> {
    constructor(dto: CompanyOptionModelDto) {
        super(dto);
        this.name = dto.name;
        this.value = dto.value;
    }

    public name?: string;

    public value?: string;

    public createDefault(): this {
        return new CompanyOptionModel({
            name: '',
            value: '',
        }) as this;
    }
}
