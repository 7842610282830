import { Injectable } from '@jack-henry/frontend-utils/di';
import { HolidaysClient } from '@treasury/api/channel';
import { Holiday } from './holiday.entity';

@Injectable()
export class HolidaysService {
    constructor(private readonly holidaysClient: HolidaysClient) {}

    public async getHolidays() {
        const dtos = (
            await this.holidaysClient.holidaysGet({
                maxAgeInSeconds: 3600,
            })
        ).data;
        return dtos.map(d => new Holiday(d));
    }
}
