// TODO: update file to use api client and proper types
import { exists } from '@jack-henry/frontend-utils/functions';
import { ExceptionCorrectionModelDto } from '@treasury/api/channel';
import { CheckException } from '../../../arp';
import { downloadBlob, toBase64 } from '../../../utilities/file-handling.js';
import CheckExceptionMappings from '../../mappings/positive-pay/check-exceptions/check-exception-mappings.js';
import { PositivePayExceptionRequests } from '../../requests/positive-pay/positive-pay-exception-requests.js';

export interface CheckExceptionHoldover {
    // this type is a holdover to avoid ts errors in the legacy saveCheckExceptions method
    decisionChoice: string;
    decisionTaken: string;
    returnReason: string;
    comment: string;
    initialDecision: string;
    arpExceptionDetailUniqueId: string;
}

interface CheckExceptionSearchParameters {
    account?: string[];
    checkNumber?: string;
    issuedAmount?: string;
    paidAmount?: string;
    issuedDate?: string;
    issuedPayee?: string | null;
    exceptionReasons?: string;
    decisionTaken?: string;
}

export default class CheckExceptionsServices {
    /**
     * @deprecated
     * use submitCheckExceptionDecisions instead
     */
    public static async saveCheckExceptions(records: CheckExceptionHoldover[]) {
        // TODO: This should be cleaned up to only post the required fields.
        const exceptions = records.map(record => {
            const exception = {
                ...record,
                pay: record.decisionChoice === 'Pay',
                existingDecision: record.decisionChoice === record.decisionTaken,
                returnReasonUniqueId: record.returnReason,
                returnReasonComment: record.comment,
            };
            // @ts-expect-error: deleting this prop is okay for API payload
            delete exception.returnReason;
            // @ts-expect-error: deleting this prop is okay for API payload
            delete exception.initialDecision;
            // @ts-expect-error: deleting this prop is okay for API payload
            delete exception.decisionChoice;
            // @ts-expect-error: deleting this prop is okay for API payload
            delete exception.pendingDecision;
            return exception;
        });
        return PositivePayExceptionRequests.saveCheckExceptions(exceptions);
    }

    public static async submitCheckExceptionDecisions(checkExceptionViewModels: CheckException[]) {
        // submitCheckExceptionDecisions cleaner version of legacy code above
        return PositivePayExceptionRequests.saveCheckExceptions(
            checkExceptionViewModels.map(exception => exception.getDtoForSubmit())
        );
    }

    public static async saveAttachmentAndComment(
        checkException: CheckException,
        newComment: string,
        fileToUpload: File | undefined
    ) {
        const body: {
            Attachment?: File;
            FileName?: string;
            Comment?: string;
        } = {};
        if (fileToUpload) {
            body.Attachment = await toBase64(fileToUpload);
            body.FileName = fileToUpload.name;
        }
        if (typeof newComment !== 'undefined') {
            body.Comment = newComment;
        }

        if (Object.keys(body).length !== 0) {
            return PositivePayExceptionRequests.saveAttachmentAndComment(body, checkException.guid);
        }
        return false;
    }

    public static async searchCheckExceptions(searchData: {
        parameters: CheckExceptionSearchParameters;
    }) {
        let body = {};
        const { parameters } = searchData;
        if (Object.keys(parameters).length) {
            body = CheckExceptionMappings.mapSearchParams(parameters);
            body = {
                ...body,
                type: 'OpenItems',
            };
        }
        const searchCheckExceptionsResponse =
            await PositivePayExceptionRequests.searchCheckExceptions(body);

        if (!searchCheckExceptionsResponse.items) {
            return { data: [], totalCount: 0 };
        }

        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        const mapped = searchCheckExceptionsResponse.items.map(exception => {
            const lastDecisionDateTime = new Date(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (exception as Record<string, any>).lastDecisionDate as string
            );
            const lastDecisionDate = new Date(
                lastDecisionDateTime.getFullYear(),
                lastDecisionDateTime.getMonth(),
                lastDecisionDateTime.getDate()
            );

            return {
                ...exception,
                decisionChoice: exception.decisionStatus,
                initialDecision: exception.decisionStatus,
                pendingDecision:
                    lastDecisionDate.getTime() !== today.getTime() && exception.protected !== 'Y'
                        ? 'true'
                        : 'false',
                returnReason: exception.returnReasonUniqueId,
                // Notes of the business logic we know so far:
                // If the last decision is the FI, it hasn't been worked
                // If the audit action is Pay or Return and from a real user, it's been worked
                // If the return reason is set, it's been worked
                // all have to occur during the same day
                userDecisionedToday: exception.audits?.reduce((prev, audit) => {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    const sinceYesterday = new Date(audit.timestamp) >= yesterday;
                    const decisionNotFromFI =
                        exception.lastDecisionTakenBy !== 'Financial Institution';
                    const isDecision =
                        audit.action === 'Return' ||
                        audit.action === 'Pay' ||
                        (exists(exception.returnReasonId) && exception.decisionStatus === 'Return');

                    return prev || (decisionNotFromFI && sinceYesterday && isDecision);
                }, false),
            };
        });

        return {
            data: mapped,
            totalCount: searchCheckExceptionsResponse?.items.length,
            toDecideCount: mapped.filter(
                item => item.protected !== 'Y' && !item.userDecisionedToday
            ).length,
        };
    }

    public static async decisionActivity(searchData: {
        parameters: CheckExceptionSearchParameters;
    }) {
        let body = {};
        const { parameters } = searchData;
        if (Object.keys(parameters).length) {
            body = CheckExceptionMappings.mapSearchParams(parameters);
            body = {
                ...body,
                decisionStatus: parameters.decisionTaken,
                type: 'History',
            };
        }

        const searchCheckExceptionsResponse =
            await PositivePayExceptionRequests.searchCheckExceptions(body);

        if (!searchCheckExceptionsResponse.items) {
            return { data: [], totalCount: 0 };
        }

        return {
            data: searchCheckExceptionsResponse.items,
            totalCount: searchCheckExceptionsResponse?.items.length,
        };
    }

    public static async fetchCheckImages(checkImageNumber: string) {
        return PositivePayExceptionRequests.fetchCheckImages(checkImageNumber);
    }

    public static async saveCorrectionRequest(
        file: File,
        correctionRequest: ExceptionCorrectionModelDto
    ) {
        const body = { ...correctionRequest };
        if (file) {
            const encodedFile = await toBase64(file);
            body.attachment = encodedFile;
            body.attachmentFileName = file.name || '';
        }
        return PositivePayExceptionRequests.saveCorrectionRequest(body);
    }

    public static async getCheckExceptionAttachment(arpExceptionDetailUniqueId: string) {
        const response = await PositivePayExceptionRequests.getCheckExceptionAttachment(
            arpExceptionDetailUniqueId
        );
        const filename = response.headers.get('x-filename');
        const contentType = response.headers.get('content-type');
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType ?? '' });

        return downloadBlob(blob, filename);
    }

    public static async getCheckExceptionReturnReasons() {
        return PositivePayExceptionRequests.getReturnReasons();
    }
}
