import { Injectable } from '@jack-henry/frontend-utils/di';
import { createUniqueId } from '@jack-henry/frontend-utils/functions';
import { WireListModelDto, WireModelDto, WiresClient } from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';
import { WireResponse } from '../../mappings/wires/wireResponse.js';
import { WireUploadRequests } from '../../requests/wires/wire-upload.js';

@Injectable()
export class WireUploadService {
    constructor(
        private readonly wiresClient: WiresClient,
        private readonly http: TmHttpClient
    ) {}

    public async createBulkWires(
        wires: WireModelDto[],
        onValidationError: (response: WireResponse) => void,
        onSuccess: (response: WireResponse) => void
    ) {
        wires.forEach(wire => {
            wire.duplicatePreventionId = wire.duplicatePreventionId ?? createUniqueId();
        });

        const payload: WireListModelDto = {
            id: 0,
            updatedDate: '',
            wires,
            securityMessage: {
                actionType: 'Create Wire',
                hasAlternate: false,
                challengeMethodTypeId: 0,
            },
        };
        const result = await this.wiresClient.wiresInitiateBulkWires(payload);
        const response = new WireResponse(result.data);
        if (response.isLocked) {
            return;
        }

        if (!response.validationPhaseComplete && response.hasErrors) {
            // If there are wire errors from the validation phase, send the users back to the summary screen
            onValidationError(response);
        } else if (response.isSuccessful) {
            onSuccess(response);
        }
    }

    public async parseWireFile(file: File, companyId: string, formatId: string) {
        const fd = new FormData();

        fd.append('wireData', file, file.name);
        fd.append('wireCompanyId', companyId);
        fd.append('formatId', formatId);

        return this.http.request('wires/parseWireFile', {
            method: 'POST',
            body: fd,
            disableContentType: true,
        });
    }

    public async processWireFile(wireFileId: string) {
        const result = await this.wiresClient.wiresProcessWireFile(wireFileId);
        return result.data;
    }

    public async getFileFormats() {
        return WireUploadRequests.getFileFormats();
    }

    public async getCompanies() {
        return WireUploadRequests.getCompanies();
    }

    public async getWireConfiguration() {
        return WireUploadRequests.getWireConfiguration();
    }

    public async getAccountConfiguration() {
        return WireUploadRequests.getAccountConfiguration();
    }
}

export default WireUploadService;
