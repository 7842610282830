// For use with legacy BackOffice
export const legacyBoUserStorageKey = 'bo-user';

// For use with the newer Authentication Service
export const tmUserStorageKey = 'tm-user';

/**
 * The shape of data stored in session storage at login in BackOffice
 */
export interface UserLoginData {
    fiId: string;
    loginId: string;
    password: string;
    useRefreshTokens: boolean;
    riskReceiptId?: string;
    credential: {
        biometricsInfo: {
            authenticationId?: string;
            key?: string;
        };
        data: unknown[];
        message: string;
        numberOfSecurityQuestions: number;
        riskReceiptId?: undefined;
        rsaStatus: 'Allow' | 'Deny';
        secureTokenStatus: 'Allow' | 'Deny';
        userCredentialStatus: 'Allow' | 'Deny';
        id: number;
        updatedBy?: string;
        updatedDate?: string;
    };
}

/**
 * A subset of user data that Channel and BackOffice have in common.
 */
export interface TmUser {
    loginId: string;
    companyId?: string;
    isAdmin: boolean;
}

// Not all call sites will have access to the `isAdmin` property so it will be set by the service.
export type SetUserPayload = Omit<TmUser, 'isAdmin'>;

/**
 * Status emitted at the end of an authentication flow.
 * Useful for consumer apps to determine where to route the user to.
 */
export enum AuthFlowEvent {
    TermsAndConditionsAccept,
    Done,
}
