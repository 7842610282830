import { IpRestrictionAddressModelDto, IpRestrictionModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';

export class UserIpRestrictions extends DomainEntity<IpRestrictionModelDto> {
    public createDefault() {
        return new UserIpRestrictions({} as IpRestrictionModelDto) as this;
    }

    public get ipRestrictions() {
        return this.dto.ipRestrictions?.map((ipRestriction, index) => ({
            ...ipRestriction,
            edited: Object.entries(ipRestriction).some(([key, value]) => {
                const { ipRestrictionsPending } = this.dto;
                const pendingRestriction = ipRestrictionsPending
                    ? ipRestrictionsPending[index]
                    : undefined;
                return pendingRestriction
                    ? pendingRestriction[key as keyof IpRestrictionAddressModelDto] !== value
                    : false;
            }),
        }));
    }

    public get restricted() {
        return this.dto.restricted;
    }
}
