/* eslint-disable no-restricted-syntax */
import { createUniqueId } from '@jack-henry/frontend-utils/functions';
import { Holiday } from '../../types';
import {
    AchBatchDetail,
    AchBatchDetailDto,
    AchBatchDownloadRequestDto,
    AchBatchRecipientSummary,
    AchBatchRequestDto,
    AchBatchSummary,
    AchPaymentRequestDto,
    AchRecipientDto,
    BatchAddendaDetail,
    BatchDetails,
    BatchRecipient,
    Config,
    getDefaultValueDate,
    isIncomingDateValid,
    NachaUploadBatchData,
    NachaUploadData,
    NachaUploadPaymentDto,
    OffsetAccount,
    SameDayAchSettings,
    SecCode,
} from '../../types/ach';
import { Frequency, FrequencyDataConstructor, FrequencyType } from '../../types/frequency';

export interface BatchData {
    batchSummary: AchBatchSummary;
    batchDetails: Array<BatchDetails>;
}

const parseAddendaFromApi = (val: Array<BatchAddendaDetail | string> | string | undefined) =>
    Array.isArray(val)
        ? val
              .map(addendaObjOrStr =>
                  typeof addendaObjOrStr === 'string' ? addendaObjOrStr : addendaObjOrStr.value
              )
              .join('\n')
        : (val ?? '');

/**
 * parses out individual batch payment details from nacha upload data for use in ach-domestic-batch
 */

const convertNachaPaymentToBatchDetails = (
    payments: Array<NachaUploadData>,
    effectiveDate: string,
    summary: NachaUploadPaymentDto,
    allowSameDayPayments: boolean,
    holidays: Array<Holiday>,
    sameDayAchSettings: SameDayAchSettings
): Array<BatchDetails> => {
    const { achBatchSummaries } = summary;

    return payments.map((paymentData: NachaUploadData): BatchDetails => {
        const {
            name,
            achCompany,
            debitAmount,
            creditAmount,
            achPaymentDraftId,
            hasAccess,
            offsetAccount,
            discretionaryData,
            entryDescription,
            fileArchiveId,
        } = paymentData.payment;

        const paymentFrequencyFromAPI: Frequency | undefined = achBatchSummaries?.find(
            indSummary => indSummary.achPaymentDraftId === achPaymentDraftId
        )?.frequency as Frequency;

        const paymentEffectiveDateFromAPI = paymentFrequencyFromAPI?.effectiveDate;
        const incomingEffectiveDate = new Date(paymentEffectiveDateFromAPI || '');

        const isIncomingEffectiveDateValid = isIncomingDateValid(
            incomingEffectiveDate,
            allowSameDayPayments,
            holidays,
            sameDayAchSettings
        );

        const validEffectiveDate = isIncomingEffectiveDateValid
            ? (paymentEffectiveDateFromAPI as string)
            : effectiveDate;

        const secCode: SecCode =
            typeof paymentData.payment.secCode === 'object'
                ? paymentData.payment.secCode
                : { description: '', code: paymentData.payment.secCode };
        const offset = typeof offsetAccount === 'string' ? offsetAccount : offsetAccount.value;

        return {
            selected: true,
            name: name ?? '',
            achCompany,
            secCode: secCode ?? '',
            debitAmount,
            creditAmount,
            effectiveDate: validEffectiveDate,
            achPaymentDraftId,
            fileArchiveId: fileArchiveId ?? 0,
            discretionaryData,
            entryDescription,
            step: 1,
            hasAccess: hasAccess ?? false,
            unauthorized: !hasAccess,
            unselected: false,
            oneEffectiveDate: false,
            offsetAccount: (offset as OffsetAccount) ?? undefined,
            errorSummary: paymentData.errorSummary ?? undefined,
            hasErrors: !!paymentData.errorSummary,
        };
    });
};

/**
 * parses batch summary from nacha upload and uses helper function to obtain batch details
 */
export const getBatchDataFromPayments = (
    nachaPaymentData: NachaUploadBatchData,
    config: Config
): BatchData => {
    const { summary, payments } = nachaPaymentData;

    // getValueDate
    const date = getDefaultValueDate(
        config.allowSameDayPayments,
        config.cutoffTimes,
        config.holidays,
        config.sameDayAchSettings
    );
    const jsDate = date === '$TODAY' ? new Date() : new Date(date);
    const effectiveDate = `${jsDate.getMonth()}/${jsDate.getDate()}/${jsDate.getFullYear()}`;
    const {
        totalBalancedBatches,
        totalBalancedDebitAmount,
        totalBalancedCreditAmount,
        totalUnBalancedBatches,
        totalUnBalancedDebitAmount,
        totalUnBalancedCreditAmount,
        fileSize,
        fileName,
        fileArchiveId,
    } = summary;
    const batchSummary: AchBatchSummary = {
        id: 0,
        step: 1,
        name: fileName ?? '',
        totalBalancedBatches: totalBalancedBatches ?? 0,
        totalBalancedDebitAmount: totalBalancedDebitAmount ?? 0,
        totalBalancedCreditAmount: totalBalancedCreditAmount ?? 0,
        totalUnbalancedBatches: totalUnBalancedBatches ?? 0,
        totalUnbalancedDebitAmount: totalUnBalancedDebitAmount ?? 0,
        totalUnbalancedCreditAmount: totalUnBalancedCreditAmount ?? 0,
        fileSize: fileSize ?? '',
        batchCount: (totalBalancedBatches ?? 0) + (totalUnBalancedBatches ?? 0),
        oneEffectiveDate: false,
        effectiveDate,
        fileArchiveId,
        selectedCount: (totalBalancedBatches ?? 0) + (totalUnBalancedBatches ?? 0),
    };
    const batchDetails: Array<BatchDetails> = convertNachaPaymentToBatchDetails(
        payments,
        effectiveDate,
        summary,
        config.allowSameDayPayments,
        config.holidays,
        config.sameDayAchSettings
    );

    return {
        batchSummary,
        batchDetails,
    };
};

/**
 * parses out recipients from draft request for use in recipients table
 */
const mapBatchRecipients = (r: AchRecipientDto): BatchRecipient => ({
    ...r,
    step: 1,
    fromMasterList: !!r.achMasterRecipientId,
    addenda: parseAddendaFromApi(r.addenda),
    effectiveDate: r.effectiveDate ?? '',
});

/**
 * obtains batch details from ach payment draft request
 */
export const getBatchDetailFromRequest = (batchRequest: AchBatchRequestDto): AchBatchDetail[] => {
    const { achBatchSummaries } = batchRequest;

    if (achBatchSummaries) {
        return achBatchSummaries.map((achBatchSummary): AchBatchDetail => {
            const {
                achCompany,
                achPaymentDraftId,
                audit,
                creditAmount,
                debitAmount,
                discretionaryData,
                entryDescription,
                id,
                name,
                recipients,
                restricted,
                secCode,
                frequency,
            } = achBatchSummary;
            const batch: AchBatchDetail = {
                achCompany,
                achPaymentDraftId,
                audit: audit ?? '',
                offsetAccount: achBatchSummary.offsetAccount ?? {
                    accountDisplayLabel: '',
                    value: '',
                    number: 0,
                    name: '',
                },
                recipients: recipients.map(mapBatchRecipients),
                achCompanyId: parseInt(achCompany.companyId),
                parentId: 0,
                reversalAchPaymentId: 0,
                step: 1,
                frequency: FrequencyDataConstructor({ valueDate: frequency.effectiveDate }),
                transactionId: '',
                creditAmount,
                debitAmount,
                discretionaryData,
                entryDescription,
                id,
                name,
                restricted,
                secCode,
            };

            return batch;
        });
    }
    return [];
};

/**
 * parses batch recipients data from ach payment draft request
 */
export const getBatchRecipientSummaryFromDraft = (
    batches: AchBatchDetail[],
    paymentId: number
): Array<AchBatchRecipientSummary> => {
    const result: Array<AchBatchRecipientSummary> = [];

    batches.forEach(batch => {
        batch.recipients.forEach(value => {
            const addenda = parseAddendaFromApi(value.addenda ?? '');
            const secCode: SecCode =
                typeof batch.secCode === 'object'
                    ? batch.secCode
                    : { description: '', code: batch.secCode };
            const {
                id,
                name,
                accountNumber,
                accountType,
                routingNumber,
                transactionType,
                amount,
                prenote,
                effectiveDate,
            } = value;
            result.push({
                id,
                batch: batch.name ?? '',
                name,
                secCode: secCode ?? { description: '', code: '' },
                accountNumber: accountNumber ?? '',
                accountType: accountType ?? '',
                routingNumber: routingNumber ?? '',
                transactionType: transactionType ?? '',
                amount: amount ?? 0,
                prenote: prenote ?? false,
                fileArchiveId: batch.fileArchiveId ?? 0,
                addenda,
                achPaymentDraftId: paymentId,
                effectiveDate: effectiveDate ?? '',
            });
        });
    });

    return result;
};

/**
 * formats ach summary data required for posting the batch payments
 */
const getAchSummariesFromBatchDetails = (details: BatchDetails[]): AchBatchDetailDto[] =>
    details.map((value, index) => {
        const {
            achCompany,
            achPaymentDraftId,
            creditAmount,
            debitAmount,
            hasAccess,
            name,
            secCode,
            offsetAccount,
            errorSummary,
            discretionaryData,
            entryDescription,
            fileArchiveId,
        } = value;

        return {
            achCompany,
            achCompanyId: achCompany?.companyId,
            achCompanyName: achCompany?.companyName,
            achPaymentDraftId,
            achPaymentTypeId: 1,
            audit: null,
            availableApprovers: null,
            batchUniqueId: index + 1,
            completedApprovalCount: null,
            completedApprovals: null,
            createdBy: 0,
            creditAmount,
            debitAmount,
            discretionaryData,
            entryDescription,
            errorMessage: null,
            errorSummary: errorSummary ?? null,
            fileArchiveId,
            frequency: {
                effectiveDate: value.effectiveDate.toString(),
                endOn: null,
                id: 0,
                initiatedDate: null,
                nextPaymentDate: '0001-01-01T00:00:00',
                noEndDate: false,
                repeatOn: '',
                repeatOnDay1: 0,
                repeatOnDay2: 0,
                repeatOnLastBusinessDay: null,
                startOn: null,
                summary: 'One Time.',
                type: 'One Time',
                updatedBy: null,
                updatedDate: '0001-01-01T00:00:00',
            },
            hasAccess,
            id: 0,
            isEdited: false,
            isSelected: true,
            isValid: true,
            lastUpdated: null,
            name,
            numberOfApprovalsNeeded: 0,
            numberOfRecipients: 0,
            offsetAccount: offsetAccount || null,
            offsetAccounts: [],
            paymentProcessingBasedOn1Record: null,
            permissions: [],
            recipients: [],
            restricted: false,
            secCode,
            securityMessage: null,
            status: null,
            successMessage: null,
            transactionId: null,
            updatedBy: null,
            updatedByUserId: 0,
            updatedDate: '0001-01-01T00:00:00',
        };
    });

/**
 * formats the post request body for posting a batch of ach payments
 */
export const mapBatchPostRequest = (
    batchSummary: AchBatchSummary,
    batchDetails: BatchDetails[]
): AchPaymentRequestDto => {
    const achBatchSummaries = getAchSummariesFromBatchDetails(batchDetails);
    const {
        totalBalancedBatches,
        totalBalancedCreditAmount,
        totalBalancedDebitAmount,
        totalUnbalancedBatches,
        totalUnbalancedCreditAmount,
        totalUnbalancedDebitAmount,
    } = batchSummary;

    const totalCreditTransactions = achBatchSummaries.reduce(
        (acc, curr) => (curr.creditAmount > 0 ? acc + 1 : acc),
        0
    );
    const totalDebitTransactions = achBatchSummaries.reduce(
        (acc, curr) => (curr.debitAmount > 0 ? acc + 1 : acc),
        0
    );

    return {
        achBatchSummaries,
        type: 'AchPaymentWithBatches',
        achCompany: null,
        achCompanyId: null,
        achCompanyName: null,
        achPaymentDraftId: 0,
        achPaymentIds: null,
        achPaymentTypeDescription: null,
        achPaymentTypeId: 1,
        achPaymentTypeName: null,
        applyUpdatesToBatch: false,
        audit: null,
        availableApprovers: null,
        batchId: 0,
        canReverseFull: false,
        canReverseTransactions: false,
        completedApprovalCount: null,
        completedApprovals: null,
        copiedFromTransactionId: null,
        createdDate: '0001-01-01T00:00:00',
        creditAmount: 0,
        debitAmount: 0,
        destinationCountryCode: null,
        destinationCurrencyCode: null,
        discretionaryData: null,
        duplicatePreventionId: createUniqueId(),
        entryDescription: null,
        errorMessage: null,
        errorSummary: null,
        failureReason: null,
        fileArchiveId: null,
        fileName: [batchSummary.name],
        fileSize: batchSummary.fileSize,
        foreignExchangeIndicator: null,
        foreignExchangeReference: null,
        foreignExchangeReferenceNumber: null,
        frequency: {
            type: FrequencyType.OneTime,
            effectiveDate: batchDetails[0].effectiveDate.toString(),
        },
        id: 1,
        isEdited: false,
        message: null,
        messageType: null,
        name: null,
        numberOfApprovalsNeeded: 0,
        offsetAccount: null,
        originatorCurrencyCode: null,
        parentFrequency: null,
        parentFrequencyType: null,
        parentId: 0,
        paymentProcessingBasedOn1Record: null,
        permissions: [],
        recipientIdsforReversal: null,
        recipients: [],
        restricted: false,
        reversalAchPaymentId: null,
        secCode: null,
        status: null,
        successMessage: null,
        templateName: null,
        totalBalancedBatches,
        totalBalancedCreditAmount,
        totalBalancedDebitAmount,
        totalCreditAmount: totalBalancedCreditAmount + totalUnbalancedCreditAmount,
        totalCreditTransactions,
        totalDebitAmount: totalBalancedDebitAmount + totalUnbalancedDebitAmount,
        totalDebitTransactions,
        totalUnBalancedBatches: totalUnbalancedBatches,
        totalUnBalancedCreditAmount: totalUnbalancedCreditAmount,
        totalUnBalancedDebitAmount: totalUnbalancedDebitAmount,
        transactionId: null,
        updatedBy: null,
        updatedDate: '0001-01-01T00:00:00',
        uploadedBy: null,
        userPermissions: [],
    };
};

export const mapBatchDownloadRequest = (
    batchSummary: AchBatchSummary,
    batchDetails: Array<BatchDetails>
): AchBatchDownloadRequestDto => {
    const batchPostRequest = mapBatchPostRequest(batchSummary, batchDetails);
    if (batchPostRequest.fileName && Array.isArray(batchPostRequest.fileName)) {
        batchPostRequest.fileName = batchPostRequest.fileName.join('');
    }

    return {
        ...batchPostRequest,
        type: 'AchPaymentWithBatches',
        page: 'AchPaymentUploadConfirmation',
    };
};
