import { html } from 'lit';
import primitives from '@treasury/policy/primitives';
import userHasEntitlement from '@treasury/domain/shared/utilities/user-has-entitlement.js';
import paymentCanBeCancelled from '@treasury/domain/shared/utilities/payment-can-be-cancelled.js';
import '../../../components/channel-status-badge.js';
import RecurringAchPaymentsClient from '../clients/recurring-ach-payments-client.js';

const client = new RecurringAchPaymentsClient();

const canCancelRecurringPayment = (record, entitlements) => {
    const status = record.getField('status');
    return paymentCanBeCancelled(status) && userHasEntitlement(entitlements, 'Delete Ach Payment');
};

export const recurringAchPaymentsFields = {
    transactionId: primitives.string.thatIs.readOnly(),
    name: primitives.string.thatIs.readOnly(),
    achCompanyName: primitives.string.thatIs.readOnly(),
    secCode: primitives.string.thatIs.readOnly(),
    createdDate: primitives.date.thatIs.readOnly(),
    debitAmount: primitives.money.thatIs.readOnly(),
    creditAmount: primitives.money.thatIs.readOnly(),
    frequencyType: primitives.string.thatIs.readOnly(),
    nextPaymentDate: primitives.date.thatIs.readOnly(),
    status: primitives.string.with
        .template(
            (status, record) =>
                html`<channel-status-badge
                    .fetchApproversFunction=${async () =>
                        client.fetchApprovers({
                            productId: record.getField('id'),
                            approvalEntity: 'achPayment',
                            updatedBy: record.getField('updatedBy'),
                        })}
                    .status=${status}
                    .record=${record}
                ></channel-status-badge>`
        )
        .thatIs.readOnly(),
    id: primitives.number.thatIs.readOnly(),
    permissions: primitives.list.with.defaultValue([]).thatIs.readOnly(),
};

export const recurringAchPaymentsReportColumns = entitlements => [
    {
        label: 'Transaction ID',
        field: 'transactionId',
        type: 'command',
        action: 'clickTransactionId',
    },
    {
        label: 'Batch Name',
        field: 'name',
    },
    {
        label: 'ACH Company Name',
        field: 'achCompanyName',
    },
    {
        label: 'SEC Code',
        field: 'secCode',
    },
    {
        label: 'Created Date',
        field: 'createdDate',
    },
    {
        label: 'Debit Amount',
        field: 'debitAmount',
    },
    {
        label: 'Credit Amount',
        field: 'creditAmount',
    },
    {
        label: 'Frequency',
        field: 'frequencyType',
    },
    {
        label: 'Next Payment Date',
        field: 'nextPaymentDate',
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        type: 'actions',
        // actions: 'Cancel', // TODO: For now, show Cancel for all records. Circle back and conditionally show based on payment status.
        actions: [
            {
                label: 'Cancel',
                action: 'Cancel',
                visibleWhen: record => canCancelRecurringPayment(record, entitlements),
            },
        ],
        label: 'Actions',
    },
];
