import { clone, createUniqueId, deepEquals } from '@jack-henry/frontend-utils/functions';

export * from './address';
export * from './bank.dto';
export * from './beneficiary.dto';
export * from './frequency.types';
export * from './paginated-response';
export * from './rate-quote';
export * from './tm-api-error';
export * from './wire.dto';
export * from './wireCompany.dto';

/**
 * All classes that are based on and must later be transformed back
 * into DTOs should inherit from this class.
 */
export abstract class Serializable<Dto> {
    constructor(protected dto: Dto) {
        this.dto = clone(dto);
    }

    public toDto() {
        return this.dto;
    }
}

/**
 * The base class of all Treasury Management business objects.
 */
export abstract class DomainEntity<Dto> extends Serializable<Dto> {
    constructor(protected dto: Dto) {
        super(dto);
        this.initialDto = clone(dto);
    }

    public readonly initialDto: Dto;

    public readonly entityId = createUniqueId();

    public clone(): this {
        const Ctor = this.constructor as new (dto: Dto) => this;
        return new Ctor(clone(this.dto));
    }

    /**
     * Compare two `DomainEntity` objects for equality.
     *
     * @returns `true` if the other `DomainEntity` is equivalent to this one.
     */
    public compare(other: this) {
        return deepEquals(this.dto, other.dto);
    }

    /**
     * Reset the current dto value to the dto value the entity was constructed with.
     */
    public reset() {
        this.dto = clone(this.initialDto);
    }

    /**
     * Create a default instance of the domain object.
     */
    public abstract createDefault(): this;
}
