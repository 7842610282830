/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { ErrorResponseModelDto, InstitutionCheckExceptionReturnReasonDto } from "../";
import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class InstitutionCheckExceptionReturnReasonClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags InstitutionCheckExceptionReturnReason
   * @name InstitutionCheckExceptionReturnReasonGetCheckExceptionReturnReasons
   * @summary Retrieves all of the check exceeption return reasons defined for the current users institution.
   * @request GET:/InstitutionCheckExceptionReturnReason
   */
  institutionCheckExceptionReturnReasonGetCheckExceptionReturnReasons = (params: RequestParams = {}) =>
    this.http.request<InstitutionCheckExceptionReturnReasonDto[], ErrorResponseModelDto>({
      path: `/InstitutionCheckExceptionReturnReason`,
      method: "GET",
      format: "json",
      ...params,
    });
}
