/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AchRecipientFilterModelDto,
  AchRecipientModelDto,
  AchRecipientRecordModelDto,
  SearchAchRecipientResponseModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchRecipientsClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags AchRecipients
   * @name AchRecipientsPostAll
   * @summary Posts the specified format.
   * @request POST:/achRecipients
   */
  achRecipientsPostAll = (
    query: {
      /** The format. */
      format: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AchRecipientModelDto[], any>({
      path: `/achRecipients`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchRecipients
   * @name AchRecipientsGet
   * @summary Gets this instance.
   * @request GET:/achRecipients
   */
  achRecipientsGet = (params: RequestParams = {}) =>
    this.http.request<AchRecipientRecordModelDto[], any>({
      path: `/achRecipients`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchRecipients
   * @name AchRecipientsPost
   * @summary Posts the specified ach recipient filter.
   * @request POST:/achRecipients/Search
   */
  achRecipientsPost = (achRecipientFilter: AchRecipientFilterModelDto | null, params: RequestParams = {}) =>
    this.http.request<SearchAchRecipientResponseModelDto, any>({
      path: `/achRecipients/Search`,
      method: "POST",
      body: achRecipientFilter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
