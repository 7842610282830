import { WireConfigurationDto } from '@treasury/api/channel';

export const nullWireConfiguration: WireConfigurationDto = {
    fiDliProductEnabled: false,
    fiDliUsdEnabled: false,
    dliCompanyProductEnabled: false,
    dliCompanyInternationalUsdEnabled: false,
    requirePurposeDli: false,
    fiWireProductEnabled: false,
    fiInternationalUsdEnabled: false,
    companyWireProductEnabled: false,
    companyInternationalUsdEnabled: false,
    maximumFutureDays: 0,
    requirePurpose: false,
    useExternalFxWireProcessing: false,
    useExternalFxQuoteRatesheets: false,
};
