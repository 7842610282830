/* eslint-disable no-restricted-syntax */
import { SearchCheckExceptionModelDto } from '@treasury/api/channel';
import { DateFormatters } from '../../shared';
import AmountRangeFormatters from '../../shared/utilities/amount-range-formatters';

export const formatDateRange = (date?: string) => {
    if (!date) {
        return {
            startDate: null,
            endDate: null,
        };
    }
    const parsedDate = DateFormatters.parseDate(date);
    if (!parsedDate?.value) {
        return null;
    }
    if (typeof parsedDate.value === 'object') {
        return {
            startDate: parsedDate.value.start,
            endDate: parsedDate.value.end,
        };
    }
    return {
        startDate: parsedDate.value,
        endDate: parsedDate.value,
    };
};

export const formatAmountRange = (amount?: string) => {
    let startAmount = null;
    let endAmount = null;
    if (amount) {
        const rangeType = AmountRangeFormatters.getRangeType(amount);
        if (rangeType === 'AmountRange') {
            const amountRange = AmountRangeFormatters.getAmountStartAndEnd(amount);
            startAmount = amountRange.start;
            endAmount = amountRange.end;
        }
        if (rangeType === 'SpecificAmount') {
            const specificAmount = AmountRangeFormatters.getSpecificAmount(amount);
            startAmount = specificAmount;
            endAmount = specificAmount;
        }
        if (startAmount === null && endAmount === null) {
            return null;
        }
        return {
            startAmount,
            endAmount,
        };
    }
    return null;
};

export const mapSearchParams = (params?: { account?: string[] }): SearchCheckExceptionModelDto => {
    return {
        accountUniqueIds: params?.account,
        isPortrait: false,
        type: 'OpenItems',
    };
};
