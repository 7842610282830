import { Nullable } from '@treasury/utils/types';
import { AccountDto, isNullAccount } from './accounts.dto';

export const itemStatuses = Object.freeze([
    'Issued',
    'Cleared',
    'Returned',
    'Void',
    'Deleted',
    'StopPay',
    'StaleDate',
    'Dormant',
    'Failed',
    'Submitted',
] as const);

export type IssuedItemStatus = (typeof itemStatuses)[number];

export interface AuditItemDto {
    createdFiDateTime: string;
    createdByUserName: string;
    action: string;
}

export type IssuedItemEntryType = 'Manual' | 'Upload' | 'Other';
export type IssuedItemType = 'Check' | 'Void';

/**
 * @deprecated
 *
 * This is the legacy shape coming from `/arp` endpoints
 * and should be phased out in favor of `IssuedItemDto` and the end `/issuedItems`
 * endpoints.
 */
export interface IssuedItemLegacyDto {
    createdDate: string;
    dateIssued: string;
    voidDate: string;
    postDate?: string;
    clearedDate?: string;
    checkAmount: number;
    checkNumber: string;
    itemStatus: IssuedItemStatus;
    payee: string;
    type: IssuedItemType;
    account: AccountDto;
    isUploaded: boolean;
    auditItems: AuditItemDto[];
    entryType: IssuedItemEntryType;
}

/**
 *  @deprecated Use `IssuedItemSavedDto`.
 * The shape of the response when attempting to save a list of `IssuedItemDto` objects.
 */
export interface IssuedItemSavedLegacyDto extends IssuedItemLegacyDto {
    status: 'Submitted' | 'Failed';
    index: number;
    voidDate: string;
    errorSummary: {
        type: number;
        summaryMessage: string;
        summaryMessageList: string[];
        details: string;
        securityMessage: string;
        id: number;
        updatedBy: string;
        updatedDate: string;
    };
}

interface IssuedItemsQueryDtoBase {
    /**
     * Does this really need to be the full object or can it just be IDs?
     */
    accounts: AccountDto[];
    amountStart: string | number;
    amountEnd: string | number;
    itemType: Lowercase<IssuedItemLegacyDto['type']> | 'both';
    entryTypes: IssuedItemEntryType[];
    issuedDateStart: string;
    issuedDateEnd: string;
    createdDateStart: string;
    createdDateEnd: string;
    voidDateStart: string;
    voidDateEnd: string;
    clearedDateStart: string;
    clearedDateEnd: string;
    statusNames: IssuedItemLegacyDto['itemStatus'][];
    payee: string;
    checkNumber: string;
}

/**
 * Search parameters the API expects when querying for issued items.
 */
export type IssuedItemsQueryDto = Nullable<IssuedItemsQueryDtoBase>;

export interface UploadIssuedItemsError {
    lineNumber: number;
    errorText: string;
}

export interface IssuedItemsParsedResponse {
    fileErrors: UploadIssuedItemsError[];
    issuedItems: Nullable<IssuedItemSavedLegacyDto>[];
}

export function hasNullAccount(dto: Nullable<IssuedItemLegacyDto>) {
    const { account } = dto;
    if (!account) {
        return true;
    }

    return isNullAccount(account);
}
