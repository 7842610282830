import { ReceivablesUserSettingsModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class UserReceivablesSettings extends DomainEntity<ReceivablesUserSettingsModelDto> {
    constructor(dto: ReceivablesUserSettingsModelDto) {
        super(dto);
    }

    public createDefault(): this {
        return new UserReceivablesSettings({} as ReceivablesUserSettingsModelDto) as this;
    }

    public get ssoAccessKey() {
        return {
            value: getDisplayValue(this.dto.ssoAccessKey, this.dto.pendingSsoAccessKey),
            edited: isEdited(this.dto.ssoAccessKey, this.dto.pendingSsoAccessKey),
        };
    }

    public get ssoLoginId() {
        return {
            value: getDisplayValue(this.dto.ssoLoginId, this.dto.pendingSsoLoginId),
            edited: isEdited(this.dto.ssoLoginId, this.dto.pendingSsoLoginId),
        };
    }

    public get products() {
        return this.dto.userProductClaims?.map(claim => ({
            name: claim.claimName,
            hasProduct: getDisplayValue(claim.hasUserClaim, claim.hasPendingUserClaim),
            edited: isEdited(claim.hasUserClaim, claim.hasPendingUserClaim),
            description: claim.claimDescription,
        }));
    }
}
