import { DomainEntity } from '../shared';
import { getDisplayValue } from './helpers/get-display-value';
import { isEdited } from './helpers/is-edited';
import { nullUser } from './null-user';
import { UserDetail } from './types/user-detail.type';

export class User extends DomainEntity<UserDetail> {
    constructor(user: UserDetail) {
        super(user);
    }

    public static create(partial: Partial<UserDetail>) {
        return new User({ ...nullUser, ...partial } as unknown as UserDetail) as User;
    }

    public createDefault() {
        return new User(nullUser as unknown as UserDetail) as this;
    }

    public get userId() {
        return {
            value: getDisplayValue(this.dto.userId, this.dto.pendingUser.userId.toString()),
            edited: isEdited(this.dto.userId, this.dto.pendingUser.userId.toString()),
        };
    }

    public get name() {
        return {
            value: getDisplayValue(this.dto.name, this.dto.pendingUser.name),
            edited: isEdited(this.dto.name, this.dto.pendingUser.name),
        };
    }

    public get userName() {
        return {
            value: this.dto.userName,
            edited: false,
        };
    }

    public get status() {
        return {
            value: this.dto.status,
            edited: false,
        };
    }

    public get isLocked() {
        return {
            value: this.dto.isLocked,
            edited: false,
        };
    }

    public get isMfaLocked() {
        return {
            value: this.dto.isMfaLocked,
            edited: false,
        };
    }

    public get isAdmin() {
        return {
            value: getDisplayValue(this.dto.isAdmin, this.dto.pendingUser.isAdmin),
            edited: isEdited(this.dto.isAdmin, this.dto.pendingUser.isAdmin),
        };
    }

    public get isSuperUser() {
        return {
            value: getDisplayValue(this.dto.isSuperUser, this.dto.pendingUser.isSuperUser),
            edited: isEdited(this.dto.isSuperUser, this.dto.pendingUser.isSuperUser),
        };
    }

    public get email() {
        return {
            value: getDisplayValue(this.dto.email, this.dto.pendingUser.email),
            edited: isEdited(this.dto.email, this.dto.pendingUser.email),
        };
    }

    public get phone() {
        return {
            value: getDisplayValue(this.dto.phone, this.dto.pendingUser.phone) as string,
            edited: isEdited(this.dto.phone, this.dto.pendingUser.phone),
        };
    }

    public get extension() {
        return {
            value: getDisplayValue(this.dto.extension, this.dto.pendingUser.extension),
            edited: isEdited(this.dto.extension, this.dto.pendingUser.extension),
        };
    }

    public get mobile() {
        return {
            value: getDisplayValue(this.dto.mobile, this.dto.pendingUser.mobile),
            edited: isEdited(this.dto.mobile, this.dto.pendingUser.mobile),
        };
    }

    public get mobileExtension() {
        return {
            value: getDisplayValue(this.dto.mobileExtension, this.dto.pendingUser.mobileExtension),
            edited: isEdited(this.dto.mobileExtension, this.dto.pendingUser.mobileExtension),
        };
    }

    public get phoneCarrier() {
        return {
            value: this.dto.phoneCarrier,
            edited: false,
        };
    }

    public get department() {
        return {
            value: getDisplayValue(this.dto.department, this.dto.pendingUser.department),
            edited: isEdited(this.dto.department, this.dto.pendingUser.department),
        };
    }

    public get fax() {
        return {
            value: getDisplayValue(this.dto.fax, this.dto.pendingUser.fax),
            edited: isEdited(this.dto.fax, this.dto.pendingUser.fax),
        };
    }

    public get pendingState() {
        return this.dto.pendingState;
    }

    public get completedApprovals() {
        return this.dto.completedApprovals;
    }

    public get loginId() {
        return {
            value: getDisplayValue(this.dto.loginId, this.dto.pendingUser.loginId),
            edited: isEdited(this.dto.loginId, this.dto.pendingUser.loginId),
        };
    }

    public get isEnrolled() {
        return this.dto.isEnrolled;
    }

    public get userProductFeatures() {
        return this.dto.userProductFeatures;
    }

    public get isEditor() {
        return this.dto.isEditor;
    }

    public get editedByUserName() {
        return this.dto.editedByUser.name;
    }
}
