/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  CancelTransferModelDto,
  ErrorResponseModelDto,
  InternalTransferAddTransactionModelDto,
  InternalTransferBatchModelDto,
  InternalTransferTemplateDetailModelDto,
  InternalTransferTemplateFilterModelDto,
  InternalTransferTemplateModelDto,
  InternalTransferTransactionFilterModelDto,
  InternalTransferTransactionModelDto,
  RecurringInternalTransferFilterModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class InternalTransfersClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGet
   * @summary Retrieves the Internal Transfer Transaction for the specified Id.
   * @request GET:/InternalTransfers
   */
  internalTransfersGet = (
    query: {
      /**
       * Internal Transfer Transaction Id
       *
       * @format int32
       */
      id: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/InternalTransfers`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersPost
   * @summary Saves the Internal Transfer Transaction.
   * @request POST:/InternalTransfers
   */
  internalTransfersPost = (
    query: {
      /**
       * Transfer type.
       *
       */
      type: string | null;
    },
    internalTransfer: InternalTransferAddTransactionModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/InternalTransfers`,
      method: "POST",
      query: query,
      body: internalTransfer,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersFilterTransfers
   * @summary Returns the Internal Transfer Transactions for the specified filter criteria.
   * @request POST:/InternalTransfers/FilterTransfers
   */
  internalTransfersFilterTransfers = (
    internalTransferTransactionFilter: InternalTransferTransactionFilterModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto[], ErrorResponseModelDto>({
      path: `/InternalTransfers/FilterTransfers`,
      method: "POST",
      body: internalTransferTransactionFilter,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGetLoanPayments
   * @summary Returns loan payments based on a search filter.
   * @request POST:/internalTransfers/getLoanPayments
   */
  internalTransfersGetLoanPayments = (
    internalTransferTransactionFilter: InternalTransferTransactionFilterModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto[], ErrorResponseModelDto>({
      path: `/internalTransfers/getLoanPayments`,
      method: "POST",
      body: internalTransferTransactionFilter,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGetRecurringTransfers
   * @summary Returns the Recurring Internal Transfers
   * @request POST:/internalTransfers/getRecurringTransfers
   */
  internalTransfersGetRecurringTransfers = (
    recurringInternalTransferFilter: RecurringInternalTransferFilterModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto[], ErrorResponseModelDto>({
      path: `/internalTransfers/getRecurringTransfers`,
      method: "POST",
      body: recurringInternalTransferFilter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersPut
   * @summary Saves the Internal Transfer Transaction.
   * @request PUT:/InternalTransfers/{id}
   */
  internalTransfersPut = (
    id: number,
    internalTransfer: InternalTransferTransactionModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/InternalTransfers/${id}`,
      method: "PUT",
      body: internalTransfer,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersApprove
   * @summary Approve the Internal Transfer Transaction for the specified Id.
   * @request POST:/internalTransfers/{id}/approve
   */
  internalTransfersApprove = (id: number, model: InternalTransferTransactionModelDto, params: RequestParams = {}) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/${id}/approve`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersReject
   * @summary Reject the Internal Transfer Transaction for the specified Id.
   * @request POST:/internalTransfers/{id}/reject
   */
  internalTransfersReject = (id: number, model: InternalTransferTransactionModelDto, params: RequestParams = {}) =>
    this.http.request<InternalTransferTransactionModelDto[], ErrorResponseModelDto>({
      path: `/internalTransfers/${id}/reject`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersCancel
   * @summary Cancel the Internal Transfer Transaction for the specified Id.
   * @request POST:/internalTransfers/cancel
   */
  internalTransfersCancel = (cancelTransferModel: CancelTransferModelDto | null, params: RequestParams = {}) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/cancel`,
      method: "POST",
      body: cancelTransferModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersBatchApprove
   * @summary Approve a batch of Internal Transfers
   * @request POST:/internalTransfers/batchApprove
   */
  internalTransfersBatchApprove = (model: InternalTransferBatchModelDto, params: RequestParams = {}) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/batchApprove`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersBatchReject
   * @summary Reject a batch of Internal Transfers
   * @request POST:/internalTransfers/batchReject
   */
  internalTransfersBatchReject = (model: InternalTransferBatchModelDto, params: RequestParams = {}) =>
    this.http.request<InternalTransferTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/batchReject`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGetTransferTemplates
   * @summary Returns transfer templates based on search filter.
   * @request POST:/internalTransfers/getTransferTemplates
   */
  internalTransfersGetTransferTemplates = (
    transfertemplateFilterModel: InternalTransferTemplateFilterModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTransactionModelDto[], ErrorResponseModelDto>({
      path: `/internalTransfers/getTransferTemplates`,
      method: "POST",
      body: transfertemplateFilterModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGetTransferTemplate
   * @summary Returns transfer template details.
   * @request GET:/internalTransfers/getTemplateById/{id}
   */
  internalTransfersGetTransferTemplate = (id: number, params: RequestParams = {}) =>
    this.http.request<InternalTransferTemplateDetailModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/getTemplateById/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersGetTransferTemplateDetails
   * @summary Returns transfer template details by ID and type.
   * @request GET:/internalTransfers/getTransferTemplateDetails/{id}
   */
  internalTransfersGetTransferTemplateDetails = (
    id: number,
    query: {
      /**
       * Transfer template type.
       *
       */
      type: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferAddTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/getTransferTemplateDetails/${id}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersApproveRejectTemplateGet
   * @summary Approve or reject a transfer template.
   * @request GET:/internalTransfers/{id}/approverejecttemplate
   */
  internalTransfersApproveRejectTemplateGet = (
    id: number,
    query: {
      /**
       * Transfer template type.
       *
       */
      type: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTemplateModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/${id}/approverejecttemplate`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersApproveRejectTemplatePost
   * @summary Approve or reject a transfer template.
   * @request POST:/internalTransfers/{id}/approverejecttemplate
   */
  internalTransfersApproveRejectTemplatePost = (
    id: number,
    query: {
      /**
       * Transfer template type.
       *
       */
      type: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferTemplateModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/${id}/approverejecttemplate`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersDeleteTemplate
   * @summary Delete a transfer template.
   * @request POST:/internalTransfers/{id}/deletetemplate
   */
  internalTransfersDeleteTemplate = (id: number, params: RequestParams = {}) =>
    this.http.request<InternalTransferTemplateModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/${id}/deletetemplate`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersSaveTransferTemplate
   * @summary Saves the Internal Transfer Transaction.
   * @request POST:/internalTransfers/saveTransferTemplate
   */
  internalTransfersSaveTransferTemplate = (
    query: {
      /**
       * Internal transfer type.
       *
       */
      type: string | null;
    },
    transferTemplate: InternalTransferAddTransactionModelDto,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferAddTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/saveTransferTemplate`,
      method: "POST",
      query: query,
      body: transferTemplate,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersUpdateTransferTemplate
   * @summary Update a transfer template.
   * @request PUT:/internalTransfers/updateTransferTemplate
   */
  internalTransfersUpdateTransferTemplate = (
    transferTemplate: InternalTransferAddTransactionModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<InternalTransferAddTransactionModelDto, ErrorResponseModelDto>({
      path: `/internalTransfers/updateTransferTemplate`,
      method: "PUT",
      body: transferTemplate,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InternalTransfers
   * @name InternalTransfersValidateTemplateName
   * @summary Validate a transfer template name.
   * @request GET:/internalTransfers/validateTemplateName
   */
  internalTransfersValidateTemplateName = (
    query: {
      /**
       * Template name to validate.
       *
       */
      templateName: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, ErrorResponseModelDto>({
      path: `/internalTransfers/validateTemplateName`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
