import FieldType from '@treasury/FDL/field-type.js';
import '@treasury/omega/components/omega-tooltip.js';
import {
    additionalInformation,
    bankId,
    bankName,
    beneficiaryName,
    debitAccount,
    debitAccountNumber,
    effectiveDate,
    errorText,
    intermediaryBanks,
    lineNumber,
    purpose,
    selected,
    wireAmount,
    wireCompanyName,
} from '@treasury/policy/wires';
import { html } from 'lit';

import { FiDate } from '@treasury/domain/dates';
import { Recordset } from '@treasury/FDL';
import { accountNumber } from '@treasury/policy/account';
import { frequency } from '@treasury/policy/primitives';

export const mapErrorSummaryToMessage = errorSummary => {
    if (!errorSummary) return;
    // eslint-disable-next-line consistent-return
    return html`<p>${errorSummary.summaryMessage}</p>
        <ul>
            ${errorSummary.details.map(error => html`<li>${error.message}</li>`)}
        </ul>
        <div><em>(Open Details to edit and see more info.)</em></div>`;
};

export const mapWireToRecord = w => ({
    beneficiaryName: w.beneficiary.name,
    intermediaryBanks: w.beneficiary.intermediaryBanks,
    referenceBeneficiary: w.referenceBeneficiary,
    debitAccount: w.debitAccount.name,
    debitAccountNumber: w.debitAccount.number,
    wireCompanyName: w.wireCompany?.name ?? '',
    effectiveDate: w.frequency.valueDate,
    wireAmount: w.amount,
    accountNumber: w.beneficiary.accountNumber,
    bankId: w.beneficiary.bank.bankId,
    bankName: w.beneficiary.bank.name,
    bank: w.beneficiary.bank,
    purpose: w.purpose,
    additionalInformation: w.additionalInformation.map(ai => ai.value),
    frequency: w.frequency,
    selected: true,
    errorSummary: w.errorSummary,
    data: w,
});

export const mapWiresToRecordSet = (wires, wireConfiguration, holidays, isoLabels) => {
    const checkHolidays = checkDate =>
        holidays.some(
            h =>
                new Date(h.date).getYear() === checkDate.getYear() &&
                new Date(h.date).getMonth() === checkDate.getMonth() &&
                new Date(h.date).getDate() === checkDate.getDate()
        );

    const disabledFunctions = {
        isDisabledStartDate: () => true,
        isDisabledEndDate: () => true,
        isStartDateInvalid: dateModel => {
            const today = new Date();
            const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
            checkDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            if (wireConfiguration.maximumFutureDays) {
                let maxFutureDate = new Date();
                maxFutureDate = new Date(
                    maxFutureDate.setDate(
                        maxFutureDate.getDate() + wireConfiguration.maximumFutureDays
                    )
                );
                maxFutureDate.setHours(0, 0, 0, 0);
                if (checkDate > maxFutureDate) return true;
            }
            if (dateModel.isWeekend()) return true;
            if (checkHolidays(checkDate)) return true;
            return checkDate < today;
        },
        isEndDateInvalid: dateModel => {
            const today = new Date();
            const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
            checkDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            if (dateModel.isWeekend()) return true;
            if (checkHolidays(checkDate)) return true;
            return checkDate < today;
        },
    };

    const referenceBeneficiary = new FieldType().with
        .label(isoLabels.endToEndId)
        .with.tag('omega-input');

    return new Recordset(
        {
            error: new FieldType().with
                .template((value, record) => {
                    const errorSummary = record.getField('errorSummary');
                    return html`${value}
                    ${errorSummary
                        ? html` <style>
                                  :host {
                                      --omega-secondary-lighten-100: var(--omega-error);
                                  }
                                  omega-tooltip {
                                      cursor: pointer;
                                  }
                              </style>
                              <omega-tooltip direction="right" light icon="warning">
                                  <div slot="content">
                                      ${mapErrorSummaryToMessage(errorSummary)}
                                  </div>
                              </omega-tooltip>`
                        : null}`;
                })
                .and.minColumnWidth(50)
                .and.maxColumnWidth(100)
                .and.targetColumnWidth(50)
                .and.sortable(false),
            beneficiaryName,
            intermediaryBanks,
            referenceBeneficiary,
            debitAccount,
            debitAccountNumber,
            wireCompanyName,
            effectiveDate: effectiveDate.with.maxColumnWidth(125).and.template((value, record) => {
                const date = record.getField('frequency').startOn
                    ? record.getField('frequency').startOn
                    : record.getField('frequency').valueDate;
                const formatter = dateValue => {
                    if (!dateValue) return '';
                    if (dateValue.toString().indexOf('$') > -1)
                        return new Date().toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        });
                    return new FiDate(new Date(dateValue)).toString();
                };

                return formatter(date);
            }),
            wireAmount: wireAmount.with.reducer('sum'),
            accountNumber,
            bankId,
            bankName,
            bank: new FieldType(),
            purpose,
            additionalInformation: additionalInformation.with
                .multipleValues(0, 4)
                .and.maxLength(35)
                .as.tag('omega-additional-info'),
            frequency: frequency.with
                .selectionDisabledFunctions(disabledFunctions)
                .as.tag('omega-frequency'),
            selected: selected.with
                .defaultValue(true)
                .and.maxColumnWidth(25)
                .as.tag('omega-checkbox'),
        },
        wires ? wires.map(mapWireToRecord) : []
    );
};

export const mapRecordToWire = (record, removeProperties = []) => {
    const wire = {
        ...record.data,
        amount: record.wireAmount,
        frequency: record.frequency,
        purpose: record.purpose,
        additionalInformation: record.additionalInformation
            .filter(ai => ai && ai.length > 0)
            .map(info => ({ value: info })),
        referenceBeneficiary: record.referenceBeneficiary,
        selected: record.selected,
    };
    Array.forEach(removeProperties, rp => delete wire[rp]);
    return wire;
};

export const mapRecordSetToWires = (recordset, removeProperties = [], filter = null) => {
    const filteredRecordSet = filter
        ? recordset.allRecords.filter(fd => fd.values.selected)
        : recordset.allRecords;
    return filteredRecordSet.map(sd => mapRecordToWire(sd.values), removeProperties);
};

export const mapFileErrorsToRecordSet = fileErrors =>
    new Recordset(
        {
            lineNumber: lineNumber.with.maxColumnWidth(25).thatIs.readOnly(),
            errorText: errorText.thatIs.readOnly(),
        },
        () =>
            fileErrors.map(fe => ({
                lineNumber: fe.lineNumber,
                errorText: fe.errorText,
            }))
    );
export default { mapWiresToRecordSet, mapRecordSetToWires, mapFileErrorsToRecordSet };
