import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { is } from '@uirouter/core';
import { featureFlags } from '../../../shared/constants.js';

UserListController.$inject = [
    '$scope',
    '$uibModal',
    'usersService',
    'modalService',
    '$state',
    '$filter',
    '$q',
    'TmDi',
    'toaster',
];

/**
 * @param { ng.IScope } $scope
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export default function UserListController(
    $scope,
    $uibModal,
    usersService,
    modalService,
    $state,
    $filter,
    $q,
    TmDi,
    toaster
) {
    $scope.searchText = '';
    $scope.filterList = filterList;
    $scope.changeActiveStatus = changeActiveStatus;
    $scope.unlockUser = unlockUser;
    $scope.getLockedText = getLockedText;
    $scope.viewUser = viewUser;
    $scope.editUser = editUser;
    $scope.copyUser = copyUser;
    $scope.createNewUser = createNewUser;
    $scope.resetPassword = resetPassword;
    $scope.userList = [];
    $scope.filteredUserList = null;
    $scope.canApproveReject = canApproveReject;
    $scope.canEdit = canEdit;
    $scope.canCopy = canCopy;
    $scope.isUisEnabled = false;
    $scope.uisResetPassword = false;
    $scope.userIdLabel = '';
    $scope.canResetPassword = canResetPassword;
    $scope.canResetUisPassword = canResetUisPassword;
    $scope.sendResetUisPasswordEmail = sendResetUisPasswordEmail;

    function canResetPassword(user) {
        if ($scope.isUisEnabled) {
            return !!user.securityAndPasswordSettingsLink?.trim();
        }
        return user.status === 'Enrolled' && user.publishState !== 'FiPending';
    }

    function canResetUisPassword(user) {
        return $scope.isUisEnabled && $scope.uisResetPassword && !!user.userUniqueId?.trim();
    }

    function canApproveReject(user) {
        return user.publishState === 'Pending' && !user.isEditor;
    }

    function canEdit(user) {
        return user.publishState !== 'Pending' && user.publishState !== 'FI Pending';
    }

    function canCopy(user) {
        return user.isActive && user.status === 'Enrolled';
    }

    function unlockUser(user) {
        const modalOptions = {
            headerText: 'Confirm Unlock',
            bodyText: 'Are you sure you want to unlock the user?',
            actionButtonText: 'Unlock User',
            closeButtonText: 'Cancel',
            submit(result) {
                $modalInstance2.close(result);
            },
        };
        var $modalInstance2 = modalService.showModal({}, modalOptions);
        return $modalInstance2.result.then(() =>
            usersService.unlock(user.id).then(() => {
                user.isLocked = false;
            })
        );
    }

    function getLockedText(user) {
        let text = '';
        if (user.isLocked && user.isOobLocked) {
            text = 'User is locked due to failed password attempts and OOB failure.';
        } else if (!user.isLocked && user.isOobLocked) {
            text = 'User is locked due to OOB failure attempts.';
        } else if (user.isLocked && !user.isOobLocked) {
            text =
                'User is locked due to failed password attempts. Select the ‘Unlock User’ link next to the lock icon on the View User or Edit User pages.';
        } else if (user.isMfaLocked) {
            text = 'User is locked out due to multi-factor authentication failure.';
        }
        return text;
    }

    function createNewUser() {
        $state.go('admin.user-information');
    }

    function changeActiveStatus(user) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeUserStatus.html'),
            size: 'md',
            controller: 'ChangeUserStatusController',
            backdrop: 'static',
            resolve: {
                userId() {
                    return user.id;
                },
                isActive() {
                    return user.isActive;
                },
            },
        });
        return modalInstance.result.then(response => {
            user.isActive = response.isActive;
        });
    }

    function viewUser(user) {
        $state.go('admin.user-information', { id: user.id, view: true });
    }

    function editUser(user) {
        $state.go('admin.user-information', { id: user.id, view: false });
    }

    function copyUser(user) {
        $state.go('admin.user-information', { copyUser: user });
    }

    function sendResetUisPasswordEmail(user) {
        TmDi.getAsync(UsersService).then(domainUserService => {
            domainUserService
                .resetPasswordForDigitalUser(user.userUniqueId)
                .then(() => {
                    toaster.send(
                        `Reset Password email was successfully sent to user ${user.userName}.`
                    );
                })
                .catch(() =>
                    toaster.alert(
                        'Reset Password Email Failed',
                        `Reset Password email for user ${user.userName} was unable to be sent.`
                    )
                );
        });
    }

    function resetPassword(user) {
        $uibModal.open({
            template: require('../../../shared/resetPassword/views/resetPassword.html'),
            size: 'md',
            controller: 'ResetPassWordController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return user;
                },
            },
        });
    }

    function filterList(searchText) {
        $scope.searchText = searchText || '';
        $scope.filteredUserList = $filter('filter')($scope.userList, searchText);
        $scope.filteredUserList = $scope.filteredUserList.map(user => {
            switch (user.status) {
                case 'Enrolled':
                    user.title =
                        'The user set up has been completed and approved by all required approvers, if applicable. This user can login to the application and conduct business, if the Active / Inactive toggle is set to Active. Enrolled user can be Active OR Inactive.';
                    break;
                case 'Not Enrolled':
                    user.title =
                        'The user has been created but the entitlement process may not be complete, or the creator is not yet ready to allow this new user to login. This could be a new user that has not yet started in his or her position. Not Enrolled user can be Inactive only (cannot be Active).';
                    break;
            }
            return user;
        });
    }

    /**
     * @param { boolean } isUisEnabled
     */
    function loadUsers(isUisEnabled) {
        const searchPromise = isUisEnabled
            ? TmDi.getAsync(UsersService).then(domainUserService =>
                  domainUserService.searchUsersNew(false, false, false)
              )
            : // legacy user search enabled by default
              usersService.searchUsers({
                  activeUsersOnly: false,
                  enrolledUsersOnly: false,
                  includeRoles: false,
              });

        return $q.resolve(searchPromise).then(response => {
            $scope.userList = response;
            filterList('');
        });
    }

    /**
     * Initialize view model state based on the status of UIS.
     *
     * @returns A promise indicating if UIS is enabled.
     */
    function initUis() {
        const uisPromise = TmDi.getAsync(FeatureFlagService).then(async ffService => {
            return {
                isUisEnabled: await ffService.isEnabled(Feature.UisEnabled),
                uisResetPassword: await ffService.isEnabled(Feature.UisResetPassword),
            };
        });

        return $q.resolve(uisPromise).then(({ isUisEnabled, uisResetPassword }) => {
            $scope.isUisEnabled = isUisEnabled;
            $scope.uisResetPassword = uisResetPassword;
            $scope.userIdLabel = isUisEnabled ? 'TM User ID' : 'User ID';
            $scope.enrollmentStatusLabel = isUisEnabled
                ? 'TM Enrollment Status'
                : 'Enrollment Status';
            $scope.statusLabel = isUisEnabled ? 'TM Status' : 'Status';

            return isUisEnabled;
        });
    }

    initUis().then(loadUsers);
}
