/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { ErrorHttpContentDto, InitiateSsoRequestDto, InitiateSsoResponseDto, UserSsoResponseDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class SsoClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags InitiateSso
   * @name InitiateSsoPost
   * @summary Gets a InitiateSsoResponse.
   * @request POST:/Sso/InitiateSso
   */
  initiateSsoPost = (request: InitiateSsoRequestDto | null, params: RequestParams = {}) =>
    this.http.request<InitiateSsoResponseDto, ErrorHttpContentDto>({
      path: `/Sso/InitiateSso`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserSso
   * @name UserSsoGet
   * @summary Gets a UserSsoResponse.
   * @request GET:/Sso/UserSso
   */
  userSsoGet = (params: RequestParams = {}) =>
    this.http.request<UserSsoResponseDto, ErrorHttpContentDto>({
      path: `/Sso/UserSso`,
      method: "GET",
      format: "json",
      ...params,
    });
}
