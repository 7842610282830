import { getChannelAPIPath } from '@treasury/core/http';

AchRecipientsService.$inject = ['$resource'];

export function AchRecipientsService($resource) {
    const resourceUrl = `${getChannelAPIPath()}achRecipients`;

    return {
        create,
        search,
    };

    function create(recipientData, format) {
        return $resource(
            resourceUrl,
            { format },
            {
                save: {
                    method: 'POST',
                    transformRequest: tranformParseIssuedItemsRequest,
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                    isArray: true,
                },
            }
        ).save(recipientData).$promise;
    }

    function search(message) {
        return $resource(
            `${resourceUrl}/search`,
            { data: message },
            {
                query: {
                    method: 'POST',
                    isArray: false,
                },
            }
        ).query(message).$promise;
    }
    //
    // Private
    function tranformParseIssuedItemsRequest(data) {
        const fd = new FormData();
        fd.append('file', data.file);
        return fd;
    }
}
