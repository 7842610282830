/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AchFileActivityFilterModelDto,
  AchFileActivityModelDto,
  AchPaymentModelDto,
  AchSourceModelDto,
  FilterUsersDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchFileActivityClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityGetAchSourceList
   * @summary Retrieves Sources of NachaFiles
   * @request GET:/achFileActivity/getACHSourceList
   */
  achFileActivityGetAchSourceList = (params: RequestParams = {}) =>
    this.http.request<AchSourceModelDto[], any>({
      path: `/achFileActivity/getACHSourceList`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityGetProcessedByFilterUsers
   * @summary Retrieves the processed by filter users.
   * @request GET:/achFileActivity/getProcessedByFilterUsers
   */
  achFileActivityGetProcessedByFilterUsers = (params: RequestParams = {}) =>
    this.http.request<FilterUsersDto, any>({
      path: `/achFileActivity/getProcessedByFilterUsers`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityGetNachaFileContent
   * @summary Retrieves File Content
   * @request GET:/achFileActivity/getNachaFileContent/{fileArchiveId}
   */
  achFileActivityGetNachaFileContent = (fileArchiveId: number, params: RequestParams = {}) =>
    this.http.request<File, any>({
      path: `/achFileActivity/getNachaFileContent/${fileArchiveId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityDelete
   * @summary Deletes a NachaFile
   * @request DELETE:/achFileActivity/delete
   */
  achFileActivityDelete = (
    query: {
      /** @format int32 */
      fileId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<boolean, any>({
      path: `/achFileActivity/delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityPost
   * @summary Process a NachaFile
   * @request POST:/achFileActivity/process/{fileId}
   */
  achFileActivityPost = (fileId: number, params: RequestParams = {}) =>
    this.http.request<AchPaymentModelDto, any>({
      path: `/achFileActivity/process/${fileId}`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchFileActivity
   * @name AchFileActivityPostAll
   * @summary Retrieves ACH File Activity model content
   * @request POST:/achFileActivity
   */
  achFileActivityPostAll = (achFileActivityFilter: AchFileActivityFilterModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchFileActivityModelDto[], any>({
      path: `/achFileActivity`,
      method: "POST",
      body: achFileActivityFilter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
