import { GetUserProductEntitlementsModelDto, UserClaimModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class IntegratedServicesEntitlements extends DomainEntity<GetUserProductEntitlementsModelDto> {
    public get permissions() {
        return this.dto.userProductClaims?.map((claim: UserClaimModelDto) => ({
            description: claim.claimDescription,
            name: claim.claimName,
            id: claim.claimId,
            hasClaim: getDisplayValue(claim.hasUserClaim, claim.hasPendingUserClaim),
            edited: isEdited(claim.hasUserClaim, claim.hasPendingUserClaim),
        }));
    }

    public get accounts() {
        return this.dto.accounts;
    }

    public createDefault() {
        return new IntegratedServicesEntitlements({} as GetUserProductEntitlementsModelDto) as this;
    }
}
