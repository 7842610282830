import { clone, deepEquals, exists } from '@jack-henry/frontend-utils/functions';
import { Nullable, verifyPropNames } from '@treasury/utils';
import {
    AccountDto,
    AuditItemDto,
    IssuedItemDto,
    IssuedItemEntryType,
    IssuedItemLegacyDto,
    IssuedItemStatus,
    IssuedItemType,
    nullIssuedItem,
} from '../../types/arp';
import { IssuedItemLegacy } from './issued-items-legacy.entity';
import { IssuedItem } from './issued-items.entity';

/**
 * Possible statuses for issued items that have been created client side
 * and are not yet in the system.
 */
export enum IssuedItemPendingStatus {
    None = 'None',
    /**
     * Set for items that are a partial, pending void with multiple
     * possible matching checks available in the system.
     */
    HasPossibleMatches = 'Has-Possible-Matches',
    /**
     * Set for items whose void match list contains
     * to many matches to be useful and must be narrowed down.
     */
    TooManyMatches = 'Too-Many-Matches',
    /**
     * Set for items whose potential void match list consists
     * entirely of items that have been previously cleared or voided.
     */
    HasOnlyFinalizedMatches = 'HasOnlyFinalizedMatches',
    /**
     * Set if the item-to-be-voided was selected from a list of matches
     * from the `/searchIssuedItemsVoids` endpoint.
     */
    SelectedFromMatches = 'Selected-From-Matches',
    /**
     * Set if an equivalent item has been already been chosen from
     * another list of matches in a void selection list.
     */
    PreviouslySelected = 'Previously-Selected',
}

export enum IssuedItemUiState {
    None,
    Warning,
    Error,
}

/**
 * Represents the client-side domain entity for ARP issued items.
 */
export interface IIssuedItem {
    itemEntryType: IssuedItemEntryType;
    createdDate: Date | undefined;
    dateIssued: Date | undefined;
    dateFinalized: Date | undefined;
    voidedDate: Date | undefined;
    postDate: Date | undefined;
    clearedDate: Date | undefined;
    auditItems: AuditItemDto[];
    checkAmount: number;
    checkNumber: string | undefined;
    payee: string;
    itemStatus: IssuedItemStatus | '';
    type: IssuedItemType | undefined;
    readonly accountName: string;
    account?: AccountDto;
    /**
     * Whether or not this item is able to be voided.
     *
     * Items in the system may be displayed that have been previously cleared or voided
     * and cannot be voided again.
     */
    readonly voidEligible: boolean;
    /**
     * Status set for client side-created pending items or returned void matches.
     */
    pendingStatus: IssuedItemPendingStatus;
    /**
     * View model property to determine if an issued item is in a state
     * that would prevent progress in the creation UI.
     */
    readonly errorState: IssuedItemUiState;
    copyFrom(item: IIssuedItem): void;
    /**
     * Returns `true` if the `IIssuedItem` is equivalent to another.
     */
    compare(item: IIssuedItem): boolean;
}

export function createIssuedItem(dto?: Nullable<IssuedItemLegacyDto | IssuedItemDto>): IIssuedItem {
    dto = dto ?? clone(nullIssuedItem);
    return isIssuedItemDto(dto) ? new IssuedItemLegacy(dto) : new IssuedItem(dto);
}

export function isIssuedItems(arr: unknown[]): arr is IIssuedItem[] {
    return arr.every(
        element => element instanceof IssuedItemLegacy || element instanceof IssuedItem
    );
}

function isIssuedItemDto(obj: unknown): obj is Nullable<IssuedItemLegacyDto> {
    const maybeDto = obj as IssuedItemLegacyDto;

    return (
        exists(maybeDto) &&
        // use duck typing just for shape since basically any field can be null
        verifyPropNames(maybeDto, 'account', 'auditItems', 'checkAmount', 'checkNumber')
    );
}

/**
 * Copy properties from one issued item to another.
 */
export function copyIssuedItem(fromItem: IIssuedItem, toItem: IIssuedItem) {
    const {
        account,
        itemEntryType,
        type,
        itemStatus,
        checkNumber,
        payee,
        checkAmount,
        dateIssued,
        createdDate,
        dateFinalized,
    } = fromItem;

    toItem.account = account;
    toItem.type = type;
    toItem.itemStatus = itemStatus;
    toItem.itemEntryType = itemEntryType;
    toItem.checkNumber = checkNumber;
    toItem.payee = payee;
    toItem.checkAmount = checkAmount;
    toItem.dateIssued = dateIssued;
    toItem.createdDate = createdDate;
    toItem.dateFinalized = dateFinalized;
}

/**
 * Returns `true` two `IIssuedItem` instances are equivalent.
 */
export function compareIssuedItems(a: IIssuedItem, b: IIssuedItem) {
    // equivalent references
    if (a === b) {
        return true;
    }

    // list of props used in the creation UI
    const equalityProps: (keyof IIssuedItem)[] = [
        'dateIssued',
        'payee',
        'checkAmount',
        'type',
        'account',
    ];

    // check for property values that would be shown in the creation UI
    const propMismatch = equalityProps.some(propName => !deepEquals(a[propName], b[propName]));

    return !propMismatch;
}
