import { Nullable, PrimitiveValue, SyncAsyncDeferred } from '../types';

export { produce } from 'immer';

/**
 * Type guard to determine if a reference is valid.
 *
 * Serves both a runtime purpose as well a type-narrowing one.
 * See: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 *
 * @param ref Reference whose value may be `null` or `undefined`.
 * @returns A boolean value indicating the validity of the object reference.
 * Returns `true` if the reference t is valid; `false` if the value is  `undefined` or `null`.
 */
export function exists<T>(ref: T | null | undefined): ref is T {
    // eslint-disable-next-line no-restricted-syntax
    return ref !== null && typeof ref !== 'undefined';
}

/**
 * Verifies that a given list of property names all exist on the provided object.
 */
export function verifyPropNames<T extends object>(obj: T, ...propNames: (keyof T)[]) {
    return propNames.every(propName => propName in obj);
}

/**
 *
 * @returns `true` if argument is a non-null, non-primitive, non-array object
 */
export function isObject(ref: unknown): ref is object {
    return exists(ref) && typeof ref === 'object' && !Array.isArray(ref);
}

/**
 * Coerces a possibly `undefined` value to the `null` value.
 */
export function coerceToNull<T>(ref: T | undefined) {
    // eslint-disable-next-line no-restricted-syntax
    return typeof ref === 'undefined' ? null : ref;
}

type ArrayDictTransform<T, V> = (elem: T, index: number) => { key: string; value: V };
export function arrayToDict<T, V>(arr: T[], transform: ArrayDictTransform<T, V>) {
    return arr.reduce(
        (dict, val, i) => {
            const { key, value } = transform(val, i);
            dict[key] = value;

            return dict;
        },
        {} as Record<string, V>
    );
}

/**
 * Coerces an object with possibly `undefined` values to `null` values.
 */
export function coerceMembersToNull<T>(object: Partial<T>): Nullable<T> {
    const keys = Object.keys(object) as (keyof T)[];

    return keys.reduce<Nullable<T>>((copy, key) => {
        copy[key] = coerceToNull(object[key]) as T[keyof T] | null;

        return copy;
    }, {} as Nullable<T>);
}

/**
 * Coerces a possibly empty string to the `null` value.
 */
export function coerceEmptyStringToNull(str: string): string | null {
    // eslint-disable-next-line no-restricted-syntax
    return str.length === 0 ? null : str;
}

/**
 * Coerces an object with possibly empty string values to `null` values.
 */
export function coerceEmptyStringsToNull<T extends object>(object: T): Nullable<T> {
    const keys = Object.keys(object) as (keyof T)[];

    return keys.reduce<Nullable<T>>((copy, key) => {
        const val = object[key];
        // can't reuse coerceEmptyStringToNull() here; TS doesn't like the lack of type narrowing
        // eslint-disable-next-line no-restricted-syntax
        copy[key] = typeof val === 'string' && (val as string).length === 0 ? null : val;

        return copy;
    }, {} as Nullable<T>);
}

/**
 * Coerces a possibly synchronous or deferred value into an asynchronous one.
 */
export async function coerceToAsyncValue<T>(value: SyncAsyncDeferred<T>) {
    return typeof value === 'function' ? (value as () => T | Promise<T>)() : value;
}

/**
 * Strongly-typed alternative to `Object.keys()`.
 * Returns only keys for an object's [own properties](https://www.javascripttutorial.net/javascript-own-properties/).
 */
export function getKeys<T extends object>(obj: T) {
    return Object.keys(obj) as (keyof T)[];
}

/**
 * Returns the keys owned directly by an object as well as those on its prototype.
 * This includes both enumerable and non-enumerable keys.
 */
export function getKeysInstance<T extends object>(obj: T) {
    const forbiddenKeys = ['constructor', '__defineGetter__', 'toString', '__proto__'];
    let proto = Object.getPrototypeOf(obj);
    const keys = getKeys(obj);

    // don't consider keys from the top of the prototype hierarchy
    while (exists(proto) && proto !== Object.prototype) {
        const protoKeys = Object.getOwnPropertyNames(proto as T).filter(
            k => !forbiddenKeys.includes(k)
        ) as (keyof T)[];
        keys.push(...protoKeys);
        proto = Object.getPrototypeOf(proto);
    }

    return keys;
}

/**
 * Flattens objects with nested properties into a shallow object (depth of 1).
 * Property keys containing nested objects are formatted as a path
 * of property names separated by the `pathDelimiter` character.
 *
 * @param pathDelimiter Separation character used in flattened property path names.
 */
export function flattenObject<T extends object>(obj: T, pathDelimiter = '_') {
    return getKeys(obj).reduce(
        (toReturn, key) => {
            const value = obj[key];

            // eslint-disable-next-line no-restricted-syntax
            if (typeof value === 'object' && value !== null) {
                const flatObject = flattenObject(value);

                getKeys(flatObject).forEach(flatKey => {
                    toReturn[`${key.toString()}${pathDelimiter}${flatKey}`] = flatObject[flatKey];
                });
            } else {
                toReturn[key as string] = value as PrimitiveValue;
            }

            return toReturn;
        },
        {} as Record<string, PrimitiveValue>
    );
}
