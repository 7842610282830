/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountProductsMappingDetailModelDto,
    AchProductModelDto,
    AssociatedCompanyModelDto,
    CompanyAccountDetailModelDto,
    CompanyAccountModelDto,
    CompanyAccountSettingsModelDto,
    CompanyAuthenticationSettingsModelDto,
    CompanyBillPayProductModelDto,
    CompanyInfoModelDto,
    CompanyLimitModelDto,
    CompanyModelDto,
    CompanyOnboardModelDto,
    CompanySearchModelDto,
    ContactInfoModelDto,
    ElectronicDocumentProductModelDto,
    FiProductFeatureModelDto,
    InformationReportingProductFeatureModelDto,
    InternalTransferProductModelDto,
    IntuitOfxModelDto,
    LookupListModelDto,
    LookupModelDto,
    ModelStateDictionaryDto,
    NumberKeyCollectionDto,
    OkResultDto,
    ProductAccountPermissionsModelDto,
    ProductFeatureModelDto,
    QfxProductModelDto,
    RemoteDepositProductModelDto,
    SsoProductModelDto,
    SsoProductResponseModelDto,
    SuspendUserChallengeModelDto,
    UserApprovalSettingsModelDto,
    UserModelDto,
    UserSummaryModelDto,
    WausauStatementsProductModelDto,
    WireTransferDliProductFeatureModelDto,
    WireTransferProductFeatureModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class CompaniesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGet
     * @summary Returns the companies specified by the Search criteria.
     * @request GET:/Companies
     */
    companiesGet = (
        query: {
            /** Type for the search criterion. */
            searchType: string | null;
            /** Value of the search type. */
            searchValue: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanySearchModelDto[], any>({
            path: `/Companies`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetNonOnboardedCompany
     * @summary Returns the company from CORE for the specified CIF Number.
     * @request POST:/Companies/GetNonOnboardedCompany/{companyType}
     */
    companiesGetNonOnboardedCompany = (
        companyType: number,
        cifNumber: LookupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelDto, any>({
            path: `/Companies/GetNonOnboardedCompany/${companyType}`,
            method: 'POST',
            body: cifNumber,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetOnboardedCompany
     * @summary Returns a company that has been onboarded and added to Treasury.
     * @request GET:/Companies/{id}/GetOnboardedCompany/{companyType}
     */
    companiesGetOnboardedCompany = (id: number, companyType: number, params: RequestParams = {}) =>
        this.http.request<CompanyModelDto, any>({
            path: `/Companies/${id}/GetOnboardedCompany/${companyType}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUpdateCompanyUniqueId
     * @summary Update the Company Unique ID for the specified Company Id.
     * @request POST:/companies/{id}/UniqueId/{companyUniqueId}
     */
    companiesUpdateCompanyUniqueId = (
        id: number,
        companyUniqueId: string,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelDto, any>({
            path: `/companies/${id}/UniqueId/${companyUniqueId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetCompaniesByFi
     * @summary Get a list of companies by FI
     * @request GET:/companies/getcompaniesbyfi
     */
    companiesGetCompaniesByFi = (params: RequestParams = {}) =>
        this.http.request<CompanyModelDto[], any>({
            path: `/companies/getcompaniesbyfi`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesOnboard
     * @summary On-boards the Company for the specified Id (CIF Number) to Channel.
     * @request POST:/companies/onboard
     */
    companiesOnboard = (companyModel: CompanyModelDto | null, params: RequestParams = {}) =>
        this.http.request<CompanyModelDto, any>({
            path: `/companies/onboard`,
            method: 'POST',
            body: companyModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags Companies
 * @name CompaniesOnboardCompany
 * @summary On-boards the Company for the specified Id (CIF Number) to Channel.  A second route with a simplified model was
created due to json deserialization issues with the CompanyModel when member fields are empty/null that are marked
as required.
 * @request POST:/companies/onboardcompany
 */
    companiesOnboardCompany = (
        companyOnboardModel: CompanyOnboardModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyOnboardModelDto, any>({
            path: `/companies/onboardcompany`,
            method: 'POST',
            body: companyOnboardModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesStatus
     * @summary Updates the Status of the Company.
     * @request PUT:/Companies/{id}/Status
     */
    companiesStatus = (
        id: string,
        companyModel: CompanyModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelDto, ModelStateDictionaryDto>({
            path: `/Companies/${id}/Status`,
            method: 'PUT',
            body: companyModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUpdateCompanyStatus
     * @summary Updates the Status of the Company.
     * @request PUT:/Companies/UpdateCompanyStatus
     */
    companiesUpdateCompanyStatus = (
        companyInfoModel: CompanyInfoModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyInfoModelDto, ModelStateDictionaryDto>({
            path: `/Companies/UpdateCompanyStatus`,
            method: 'PUT',
            body: companyInfoModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountsAllGet
     * @summary Returns the Company accounts for the specified Company Id.
     * @request GET:/Companies/{id}/Accounts
     */
    companiesAccountsAllGet = (id: string, params: RequestParams = {}) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/Companies/${id}/Accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountsAllPost
     * @summary Add the passed Account Ids to the specified company.
     * @request POST:/Companies/{id}/Accounts
     */
    companiesAccountsAllPost = (
        id: string,
        query: {
            /** Activate accounts when added */
            activate: boolean;
        },
        accounts: LookupListModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], ModelStateDictionaryDto>({
            path: `/Companies/${id}/Accounts`,
            method: 'POST',
            query: query,
            body: accounts,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetAccountsForAnalysisTransactionType
     * @summary Gets accounts for given analysis transaction type.
     * @request GET:/companies/{companyId}/getAccountsForAnalysisTransactionType/{analysisTransactionTypeId}
     */
    companiesGetAccountsForAnalysisTransactionType = (
        companyId: number,
        analysisTransactionTypeId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/companies/${companyId}/getAccountsForAnalysisTransactionType/${analysisTransactionTypeId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesDepositAccounts
     * @summary Gets deposit accounts.
     * @request GET:/companies/{id}/depositAccounts
     */
    companiesDepositAccounts = (id: number, params: RequestParams = {}) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/companies/${id}/depositAccounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountsAllGet2
     * @summary Returns the Company accounts for the specified Company Id.
     * @request GET:/companies/{id}/accounts/{getValidCoreAccounts}
     */
    companiesAccountsAllGet2 = (
        id: number,
        getValidCoreAccounts: boolean,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/companies/${id}/accounts/${getValidCoreAccounts}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountsGet
     * @summary Returns the Accounts for the specified Account Id.
     * @request GET:/Companies/{id}/Accounts/{actionId}
     */
    companiesAccountsGet = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<CompanyAccountModelDto, any>({
            path: `/Companies/${id}/Accounts/${actionId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountsPut
     * @summary Updates the Account with the passed information.
     * @request PUT:/Companies/{id}/Accounts/{actionId}
     */
    companiesAccountsPut = (
        id: number,
        actionId: number,
        account: CompanyAccountModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, ModelStateDictionaryDto>({
            path: `/Companies/${id}/Accounts/${actionId}`,
            method: 'PUT',
            body: account,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountStatus
     * @summary Updates the Company Account status for the specified Id.
     * @request POST:/companies/{companyId}/accountstatus/{accountId}/{status}
     */
    companiesAccountStatus = (
        companyId: number,
        accountId: number,
        status: string,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto, any>({
            path: `/companies/${companyId}/accountstatus/${accountId}/${status}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesLimitsGet
     * @summary Returns the Company Limit for the specified Company Id.
     * @request GET:/Companies/{id}/Limits
     */
    companiesLimitsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<CompanyLimitModelDto, any>({
            path: `/Companies/${id}/Limits`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesLimitsPost
     * @summary Adds the Company Limit.
     * @request POST:/Companies/{id}/Limits
     */
    companiesLimitsPost = (
        id: number,
        companyLimit: CompanyLimitModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyLimitModelDto, ModelStateDictionaryDto>({
            path: `/Companies/${id}/Limits`,
            method: 'POST',
            body: companyLimit,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesLimitsPut
     * @summary Updates the Company Limit.
     * @request PUT:/Companies/{id}/Limits/{actionId}
     */
    companiesLimitsPut = (
        id: number,
        actionId: number,
        companyLimit: CompanyLimitModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyLimitModelDto, ModelStateDictionaryDto>({
            path: `/Companies/${id}/Limits/${actionId}`,
            method: 'PUT',
            body: companyLimit,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags Companies
 * @name CompaniesGetPositivePayProductFeature
 * @summary Returns Positive Pay product feature for the specified Company Id.
Note : this is separate from 'generic' product feature retrieval because PosPay needs a consistent
model for both 'add' and 'edit' scenarios.  Other products typically return an FI model for 'add'
and a company product model for 'edit'.
When adding product : productFeatureId will be 0
When editing product: productFeatureId will be greater than 0
 * @request GET:/companies/{id}/positivePayProduct/{productId}
 */
    companiesGetPositivePayProductFeature = (
        id: number,
        productId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureModelDto, any>({
            path: `/companies/${id}/positivePayProduct/${productId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesProductFeaturesAll
     * @summary Returns the Product Features for the specified Company Id.
     * @request GET:/Companies/{id}/ProductFeatures
     */
    companiesProductFeaturesAll = (id: string, params: RequestParams = {}) =>
        this.http.request<ProductFeatureModelDto[], any>({
            path: `/Companies/${id}/ProductFeatures`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesProductFeaturesPost
     * @summary Adds the Product Feature to the specified Company.
     * @request POST:/Companies/{id}/ProductFeatures
     */
    companiesProductFeaturesPost = (
        id: string,
        productFeature: ProductFeatureModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureModelDto, any>({
            path: `/Companies/${id}/ProductFeatures`,
            method: 'POST',
            body: productFeature,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAchProduct
     * @summary Adds the Ach Product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/AchProduct
     */
    companiesAchProduct = (
        companyUniqueId: string,
        achProductModel: AchProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchProductModelDto, any>({
            path: `/Companies/${companyUniqueId}/AchProduct`,
            method: 'POST',
            body: achProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesBillPayProduct
     * @summary Adds the BillPay product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/BillPayProduct
     */
    companiesBillPayProduct = (
        companyUniqueId: string,
        companyBillPayProductModel: CompanyBillPayProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyBillPayProductModelDto, any>({
            path: `/Companies/${companyUniqueId}/BillPayProduct`,
            method: 'POST',
            body: companyBillPayProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesElectronicDocumentProduct
     * @summary Adds the Electronic Document product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/ElectronicDocumentProduct
     */
    companiesElectronicDocumentProduct = (
        companyUniqueId: string,
        electronicDocumentProductModel: ElectronicDocumentProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ElectronicDocumentProductModelDto, any>({
            path: `/Companies/${companyUniqueId}/ElectronicDocumentProduct`,
            method: 'POST',
            body: electronicDocumentProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesInformationReportingProduct
     * @summary Adds the InformationReporting product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/InformationReportingProduct
     */
    companiesInformationReportingProduct = (
        companyUniqueId: string,
        informationReportingProductFeatureModel: InformationReportingProductFeatureModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InformationReportingProductFeatureModelDto, any>({
            path: `/Companies/${companyUniqueId}/InformationReportingProduct`,
            method: 'POST',
            body: informationReportingProductFeatureModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesInternalTransferProduct
     * @summary Adds the InternalTransfer product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/InternalTransferProduct
     */
    companiesInternalTransferProduct = (
        companyUniqueId: string,
        internalTransferProductModel: InternalTransferProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InternalTransferProductModelDto, any>({
            path: `/Companies/${companyUniqueId}/InternalTransferProduct`,
            method: 'POST',
            body: internalTransferProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesWausauStatementsProduct
     * @summary Adds the WausauStatements product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/WausauStatementsProduct
     */
    companiesWausauStatementsProduct = (
        companyUniqueId: string,
        wausauStatementsProductModel: WausauStatementsProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WausauStatementsProductModelDto, any>({
            path: `/Companies/${companyUniqueId}/WausauStatementsProduct`,
            method: 'POST',
            body: wausauStatementsProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesWireTransferProduct
     * @summary Adds the WireTransfer product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/WireTransferProduct
     */
    companiesWireTransferProduct = (
        companyUniqueId: string,
        wireTransferProductFeatureModel: WireTransferProductFeatureModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireTransferProductFeatureModelDto, any>({
            path: `/Companies/${companyUniqueId}/WireTransferProduct`,
            method: 'POST',
            body: wireTransferProductFeatureModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesWireTransferProduct2
     * @summary Adds the WireTransfer DLI product to the specified Company.
     * @request POST:/Companies/{companyUniqueId}/WireTransferDliProduct
     */
    companiesWireTransferProduct2 = (
        companyUniqueId: string,
        wireTransferDliProductFeatureModel: WireTransferDliProductFeatureModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireTransferDliProductFeatureModelDto, any>({
            path: `/Companies/${companyUniqueId}/WireTransferDliProduct`,
            method: 'POST',
            body: wireTransferDliProductFeatureModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesProductFeaturesGet
     * @summary Returns Product Feature for the specified Id.
     * @request GET:/Companies/{id}/ProductFeatures/{actionId}
     */
    companiesProductFeaturesGet = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<ProductFeatureModelDto, any>({
            path: `/Companies/${id}/ProductFeatures/${actionId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesProductFeaturesPut
     * @summary Updates the Product Feature with the passed information.
     * @request PUT:/Companies/{id}/ProductFeatures/{actionId}
     */
    companiesProductFeaturesPut = (
        id: number,
        actionId: number,
        productFeature: ProductFeatureModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureModelDto, ModelStateDictionaryDto>({
            path: `/Companies/${id}/ProductFeatures/${actionId}`,
            method: 'PUT',
            body: productFeature,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSsoProductFeaturesPost
     * @summary Add the Sso Product Feature with the passed information.
     * @request POST:/companies/{id}/ssoProductFeatures
     */
    companiesSsoProductFeaturesPost = (
        id: number,
        ssoProductModel: SsoProductModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<SsoProductResponseModelDto, any>({
            path: `/companies/${id}/ssoProductFeatures`,
            method: 'POST',
            body: ssoProductModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSsoProductFeaturesPut
     * @summary Updates the Sso Product Feature with the passed information.
     * @request PUT:/companies/{id}/ssoProductFeatures/{actionId}
     */
    companiesSsoProductFeaturesPut = (
        id: number,
        actionId: number,
        ssoProductModel: SsoProductModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<SsoProductResponseModelDto, any>({
            path: `/companies/${id}/ssoProductFeatures/${actionId}`,
            method: 'PUT',
            body: ssoProductModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSsoProductFeaturesGet
     * @summary Returns Sso Product Feature for the specified Id.
     * @request GET:/companies/{id}/ssoProductFeatures/{actionId}
     */
    companiesSsoProductFeaturesGet = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<SsoProductModelDto, any>({
            path: `/companies/${id}/ssoProductFeatures/${actionId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesProductStatus
     * @summary Updates the status for the specified Product Feature.
     * @request POST:/Companies/{id}/ProductStatus/{actionId}
     */
    companiesProductStatus = (
        id: string,
        actionId: string,
        query: {
            /** Product Feature status to be modified. */
            status: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureModelDto, any>({
            path: `/Companies/${id}/ProductStatus/${actionId}`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountMappingGet
     * @summary Returns the accounts and the product association for the specified company Id.
     * @request GET:/Companies/{id}/AccountMapping
     */
    companiesAccountMappingGet = (id: number, params: RequestParams = {}) =>
        this.http.request<AccountProductsMappingDetailModelDto, any>({
            path: `/Companies/${id}/AccountMapping`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAccountMappingPut
     * @summary Saves the relation between Accounts and Products for the specified company Id.
     * @request PUT:/Companies/{id}/AccountMapping/{actionId}
     */
    companiesAccountMappingPut = (
        id: number,
        actionId: number,
        model: AccountProductsMappingDetailModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/Companies/${id}/AccountMapping/${actionId}`,
            method: 'PUT',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUsersAll
     * @summary Returns the Users for the specified Company Id.
     * @request GET:/companies/{id}/{enrolledAndActive}/users
     */
    companiesUsersAll = (id: number, enrolledAndActive: boolean, params: RequestParams = {}) =>
        this.http.request<UserSummaryModelDto[], any>({
            path: `/companies/${id}/${enrolledAndActive}/users`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetUsers
     * @summary Returns the Users for the specified Company Id.
     * @request GET:/companies/{id}/users/{enrolledAndActive}
     */
    companiesGetUsers = (id: number, enrolledAndActive: boolean, params: RequestParams = {}) =>
        this.http.request<UserSummaryModelDto[], any>({
            path: `/companies/${id}/users/${enrolledAndActive}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUsers
     * @summary Returns the User for the specified Id.
     * @request GET:/Companies/{id}/Users/{actionId}
     */
    companiesUsers = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<UserModelDto, any>({
            path: `/Companies/${id}/Users/${actionId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesContactsAll
     * @summary Returns the Contact details for the specified Company Id.
     * @request GET:/Companies/{id}/Contacts
     */
    companiesContactsAll = (id: number, params: RequestParams = {}) =>
        this.http.request<ContactInfoModelDto[], any>({
            path: `/Companies/${id}/Contacts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesContactsPost
     * @summary Creates the Contact Information for the specified Company Id.
     * @request POST:/Companies/{id}/Contacts
     */
    companiesContactsPost = (
        id: number,
        contactInfo: ContactInfoModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<ContactInfoModelDto, any>({
            path: `/Companies/${id}/Contacts`,
            method: 'POST',
            body: contactInfo,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesContactsPut
     * @summary Updates the Contact Information for the specified Company Id.
     * @request PUT:/Companies/{id}/Contacts/{actionId}
     */
    companiesContactsPut = (
        id: number,
        actionId: number,
        contactInfo: ContactInfoModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<ContactInfoModelDto, any>({
            path: `/Companies/${id}/Contacts/${actionId}`,
            method: 'PUT',
            body: contactInfo,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesContactsDelete
     * @summary Deletes the passed contact for the specified Company Id.
     * @request DELETE:/Companies/{id}/Contacts/{actionId}
     */
    companiesContactsDelete = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/Companies/${id}/Contacts/${actionId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUserAccountsGet
     * @summary Returns the User Company Accounts for the specified Company Id.
     * @request GET:/Companies/{id}/UserAccounts
     */
    companiesUserAccountsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<CompanyAccountDetailModelDto, any>({
            path: `/Companies/${id}/UserAccounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUserAccountsPut
     * @summary Updates the User and associated accounts for the specified Company Id.
     * @request PUT:/Companies/{id}/UserAccounts/{actionId}
     */
    companiesUserAccountsPut = (
        id: number,
        actionId: number,
        userAccounts: CompanyAccountDetailModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ModelStateDictionaryDto>({
            path: `/Companies/${id}/UserAccounts/${actionId}`,
            method: 'PUT',
            body: userAccounts,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesEntitlementsAll
     * @summary Returns the Product, Account, User and Permission relations for the specified Company Id.
     * @request GET:/Companies/{id}/Entitlements
     */
    companiesEntitlementsAll = (id: number, params: RequestParams = {}) =>
        this.http.request<ProductAccountPermissionsModelDto[], any>({
            path: `/Companies/${id}/Entitlements`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesEntitlements
     * @summary Updates the entitlements i.e. relations between Product, Account, User and its Permissions.
     * @request POST:/Companies/{id}/Entitlements
     */
    companiesEntitlements = (
        id: number,
        model: ProductAccountPermissionsModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ModelStateDictionaryDto, any>({
            path: `/Companies/${id}/Entitlements`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesNonOnboardedCompanies
     * @summary Returns the companies specified by the Search criteria that are not onboarded.
     * @request GET:/companies/notonboardedcompanies
     */
    companiesNonOnboardedCompanies = (
        query: {
            /** Type for the search criterion. */
            searchType: string | null;
            /** Value of the search type. */
            searchValue: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelDto[], any>({
            path: `/companies/notonboardedcompanies`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAssociatedCompanies
     * @summary Associates a CORE company with the holding company for the specified CIF Number.
     * @request POST:/companies/{id}/associatedCompanies
     */
    companiesAssociatedCompanies = (
        id: number,
        cifNumber: LookupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AssociatedCompanyModelDto, any>({
            path: `/companies/${id}/associatedCompanies`,
            method: 'POST',
            body: cifNumber,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSuperUsers
     * @summary Returns super users.
     * @request GET:/companies/superusers/{id}
     */
    companiesSuperUsers = (id: number, params: RequestParams = {}) =>
        this.http.request<UserModelDto[], any>({
            path: `/companies/superusers/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAssociatedCompaniesAll
     * @summary Returns Associated Companies for the specified company id.
     * @request GET:/companies/{id}/associatedcompanies
     */
    companiesAssociatedCompaniesAll = (id: number, params: RequestParams = {}) =>
        this.http.request<AssociatedCompanyModelDto[], any>({
            path: `/companies/${id}/associatedcompanies`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesNewUserAccounts
     * @summary New User Accounts - Returns the user and account mapping that will be saved.
     * @request POST:/Companies/{id}/NewUserAccounts
     */
    companiesNewUserAccounts = (
        id: number,
        model: ProductAccountPermissionsModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<any, ModelStateDictionaryDto>({
            path: `/Companies/${id}/NewUserAccounts`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAuthenticationSettings
     * @request PUT:/companies/{id}/authenticationSettings
     */
    companiesAuthenticationSettings = (
        id: number,
        companyAuthenticationSettings: CompanyAuthenticationSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAuthenticationSettingsModelDto, any>({
            path: `/companies/${id}/authenticationSettings`,
            method: 'PUT',
            body: companyAuthenticationSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUserAccountSettingsGet
     * @request GET:/companies/{id}/userAccountSettings
     */
    companiesUserAccountSettingsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<CompanyAccountSettingsModelDto, any>({
            path: `/companies/${id}/userAccountSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesUserAccountSettingsPut
     * @request PUT:/companies/{id}/userAccountSettings
     */
    companiesUserAccountSettingsPut = (
        id: number,
        companyAccountSettings: CompanyAccountSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountSettingsModelDto, any>({
            path: `/companies/${id}/userAccountSettings`,
            method: 'PUT',
            body: companyAccountSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSuspendUserAuthentication
     * @summary Suspends OOB for a user.
     * @request PUT:/companies/{id}/users/{userId}/suspendAuthentication
     */
    companiesSuspendUserAuthentication = (
        id: number,
        userId: number,
        query: {
            /** @format int32 */
            challengeSuspensionDuration: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<SuspendUserChallengeModelDto, any>({
            path: `/companies/${id}/users/${userId}/suspendAuthentication`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesReenableUserAuthentication
     * @summary Reenable Challenge Authentication for a user.
     * @request PUT:/companies/{id}/users/{userId}/reenableUserAuthentication
     */
    companiesReenableUserAuthentication = (
        id: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<SuspendUserChallengeModelDto, any>({
            path: `/companies/${id}/users/${userId}/reenableUserAuthentication`,
            method: 'PUT',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetAvailableProductsForCompany
     * @summary Get a list of InstitutionProductTypes that have not been assigned to the given company.
     * @request GET:/companies/{companyId}/getAvailableProducts
     */
    companiesGetAvailableProductsForCompany = (companyId: number, params: RequestParams = {}) =>
        this.http.request<FiProductFeatureModelDto[], any>({
            path: `/companies/${companyId}/getAvailableProducts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetAvailableRemoteDepositProductTypes
     * @summary Gets available remote deposits.
     * @request GET:/companies/GetAvailableRemoteDepositProductTypes
     */
    companiesGetAvailableRemoteDepositProductTypes = (params: RequestParams = {}) =>
        this.http.request<NumberKeyCollectionDto, any>({
            path: `/companies/GetAvailableRemoteDepositProductTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesAddRemoteDepositProduct
     * @summary Posts deposits for a given company.
     * @request POST:/Companies/{companyId}/AddRemoteDepositProduct
     */
    companiesAddRemoteDepositProduct = (
        companyId: number,
        product: RemoteDepositProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<RemoteDepositProductModelDto, any>({
            path: `/Companies/${companyId}/AddRemoteDepositProduct`,
            method: 'POST',
            body: product,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesEditRemoteDepositProduct
     * @summary Updates remote deposits for a company.
     * @request PUT:/Companies/EditRemoteDepositProduct
     */
    companiesEditRemoteDepositProduct = (
        product: RemoteDepositProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<RemoteDepositProductModelDto, any>({
            path: `/Companies/EditRemoteDepositProduct`,
            method: 'PUT',
            body: product,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetIntuitOfxId
     * @summary Get the Intuit OFX id for a company if it exists
     * @request GET:/companies/{companyId}/getIntuitOfxId
     */
    companiesGetIntuitOfxId = (companyId: number, params: RequestParams = {}) =>
        this.http.request<IntuitOfxModelDto, any>({
            path: `/companies/${companyId}/getIntuitOfxId`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGenerateIntuitOfxIdForCompany
     * @summary Generate Intuit OFX id for a company
     * @request POST:/companies/{companyId}/generateIntuitOfxIdForCompany
     */
    companiesGenerateIntuitOfxIdForCompany = (companyId: number, params: RequestParams = {}) =>
        this.http.request<IntuitOfxModelDto, any>({
            path: `/companies/${companyId}/generateIntuitOfxIdForCompany`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetQfxEnabledFlag
     * @summary Get Qfx Enabled Flag for company
     * @request GET:/companies/{companyId}/getQfxEnabledFlag
     */
    companiesGetQfxEnabledFlag = (companyId: number, params: RequestParams = {}) =>
        this.http.request<QfxProductModelDto, any>({
            path: `/companies/${companyId}/getQfxEnabledFlag`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesSaveQfxCompanyFlag
     * @summary Saves Qfx Enabled Flag for company
     * @request POST:/companies/{companyId}/saveQfxCompanyFlag
     */
    companiesSaveQfxCompanyFlag = (
        companyId: number,
        query: {
            newFlag: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/companies/${companyId}/saveQfxCompanyFlag`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesGetCompanyName
     * @summary Gets company name from id.
     * @request GET:/companies/{companyId}/name
     */
    companiesGetCompanyName = (companyId: number, params: RequestParams = {}) =>
        this.http.request<CompanyModelDto, any>({
            path: `/companies/${companyId}/name`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesApprovalSettingsGet
     * @summary Updates the User with the passed information.
     * @request GET:/Companies/{id}/ApprovalSettings
     */
    companiesApprovalSettingsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<UserApprovalSettingsModelDto, any>({
            path: `/Companies/${id}/ApprovalSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesApprovalSettingsPut
     * @summary Updates company approval settings.
     * @request PUT:/companies/{id}/approvalSettings/{approvalSettingsId}
     */
    companiesApprovalSettingsPut = (
        id: number,
        approvalSettingsId: number,
        approvalSettings: UserApprovalSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UserApprovalSettingsModelDto, any>({
            path: `/companies/${id}/approvalSettings/${approvalSettingsId}`,
            method: 'PUT',
            body: approvalSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesPost
     * @summary Adds the new company user
     * @request POST:/companies/{id}/users
     */
    companiesPost = (id: number, userModel: UserModelDto, params: RequestParams = {}) =>
        this.http.request<UserModelDto, ModelStateDictionaryDto>({
            path: `/companies/${id}/users`,
            method: 'POST',
            body: userModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesPut
     * @summary Updated the passed FI user.
     * @request PUT:/companies/{id}/users/{actionid}
     */
    companiesPut = (
        id: number,
        actionId: number,
        actionid: string,
        userModel: UserModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<UserModelDto, ModelStateDictionaryDto>({
            path: `/companies/${id}/users/${actionid}`,
            method: 'PUT',
            body: userModel,
            format: 'json',
            ...params,
        });
}
