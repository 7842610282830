import { WireFileDto, WireTemplateModelDto } from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';
import { WireTemplateQueryDto } from '../../types/wires';

export class WireCompanyRequests {
    public static async searchTemplates(params: Partial<WireTemplateQueryDto>) {
        const http = await TmHttpClient.getInstance();
        return http.request<WireTemplateModelDto[] | null>(`wires//wireTemplateSearch`, {
            method: 'POST',
            body: params,
        });
    }

    public static async approveTemplate(id: string) {
        return (await TmHttpClient.getInstance()).request(`wires/approveTemplate/${id}`, {
            method: 'POST',
        });
    }

    public static async rejectTemplate(id: string) {
        return (await TmHttpClient.getInstance()).request(`wires/rejectTemplate/${id}`, {
            method: 'POST',
        });
    }

    public static async removeTemplate(id: string) {
        return (await TmHttpClient.getInstance()).request(`wires/template/${id}`, {
            method: 'DELETE',
        });
    }

    public static async removeFile(id: string): Promise<boolean> {
        return (await TmHttpClient.getInstance()).request(`wires/wireFile/${id}`, {
            method: 'DELETE',
        });
    }

    public static async getWireFileContents(id: string): Promise<WireFileDto> {
        return (await TmHttpClient.getInstance()).request(`wires/wireFile/${id}`, {
            method: 'POST',
        });
    }

    public static async getAllWireCompanies(nonDli: boolean) {
        return (await TmHttpClient.getInstance()).request(`wireCompanies/?nonDli=${!!nonDli}`, {
            method: 'GET',
        });
    }

    public static async fetchTransferAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/GetWithProductFeature?type=&productfeaturetype=WireTransfer`,
            {
                method: 'GET',
            }
        );
    }

    public static async getAllFileUploadUsers() {
        return (await TmHttpClient.getInstance()).request(`Wires/GetDomesticWireFileUploadUsers`, {
            method: 'GET',
        });
    }
}
