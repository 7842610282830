/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AchReturnDetailModelDto,
  AchReturnFilterModelDto,
  AchReturnReportModelDto,
  AchReturnReportRequestModelDto,
  ErrorHttpContentDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AchReturnClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags AchReturns
   * @name AchReturnsSearchAchReturn
   * @summary Searches for ACH return records.
   * @request POST:/AchReturn/Search
   */
  achReturnsSearchAchReturn = (returnFilter: AchReturnFilterModelDto | null, params: RequestParams = {}) =>
    this.http.request<AchReturnReportModelDto, ErrorHttpContentDto>({
      path: `/AchReturn/Search`,
      method: "POST",
      body: returnFilter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchReturns
   * @name AchReturnsGetReturnDetail
   * @summary Gets the details of an ACH return record.
   * @request GET:/AchReturn/{achReturnId}/Detail
   */
  achReturnsGetReturnDetail = (achReturnId: string, params: RequestParams = {}) =>
    this.http.request<AchReturnDetailModelDto, ErrorHttpContentDto>({
      path: `/AchReturn/${achReturnId}/Detail`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchReturns
   * @name AchReturnsNachaDownload
   * @summary Creates a NACHA file for an ACH return record.
   * @request POST:/AchReturn/{achReturnId}/Nacha
   */
  achReturnsNachaDownload = (achReturnId: string, params: RequestParams = {}) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/AchReturn/${achReturnId}/Nacha`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AchReturns
   * @name AchReturnsDownloadDetails
   * @summary Downloads an ACH return record as a pdf file
   * @request POST:/AchReturn/{achReturnId}/Download
   */
  achReturnsDownloadDetails = (
    achReturnId: string,
    achReturnReportRequestModel: AchReturnReportRequestModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<string, ErrorHttpContentDto>({
      path: `/AchReturn/${achReturnId}/Download`,
      method: "POST",
      body: achReturnReportRequestModel,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
