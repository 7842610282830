import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsEventPayloads, AnalyticsService } from '@treasury/core/analytics';
import { AchExceptionDetail, PayOrReturnChoice } from '../../mappings/ach';
import AchFilterRequests from '../../requests/ach/ach-filter-requests';
import { PositivePayExceptionRequests } from '../../requests/positive-pay/positive-pay-exception-requests.js';
import { AchExceptionDetailDto, AchExceptionsQueryDto } from '../../types';
import { AchExceptionDetailForApiDto } from '../../types/arp/ach-exception-detail-for-api.dto.js';

export default class AchExceptionsService {
    public static async findAchExceptions(filter: AchExceptionsQueryDto) {
        const exceptions = await AchFilterRequests.findAchExceptions(filter);
        let recordCount = 0;
        if (exceptions?.achExceptionDetails) {
            recordCount = exceptions.achExceptionDetails.length;
        } else {
            exceptions.achExceptionDetails = [];
        }
        return {
            data: (exceptions.achExceptionDetails as AchExceptionDetailDto[]).map(
                dto => new AchExceptionDetail(dto)
            ),
            totalCount: recordCount,
        };
    }

    public static saveAchExceptions(records: AchExceptionDetailForApiDto[]) {
        return Promise.all([
            PositivePayExceptionRequests.saveAchExceptions(records),
            DiContainer.getInstance().then(di => di.getAsync(AnalyticsService)),
        ]).then(([result, analytics]) => {
            trackExceptionDecisions(records, analytics);
            return result;
        });
    }
}

type AchDecisionPayload = AnalyticsEventPayloads[AnalyticsEvent.AchExceptionDecision];
type AchDecision = AchDecisionPayload['decision'];
type AchExceptionType = AchDecisionPayload['exceptionType'];
type AchSecCode = AchDecisionPayload['secCode'];
type AchAccountType = AchDecisionPayload['accountType'];

function trackExceptionDecisions(
    decisions: AchExceptionDetailForApiDto[],
    analytics: AnalyticsService
) {
    decisions.forEach(d => {
        const { amount, achExceptionDecisionStatusId, exceptionType, secCode, accountType } = d;
        let decision: AchDecision = 'none';

        switch (achExceptionDecisionStatusId) {
            case PayOrReturnChoice.Pay:
                decision = 'pay';
                break;
            case PayOrReturnChoice.Return:
                decision = 'return';
                break;
            case PayOrReturnChoice.Unselected:
            default:
                decision = 'none';
                break;
        }

        analytics.track(AnalyticsEvent.AchExceptionDecision, {
            amount: amount || 0,
            decision,
            exceptionType: exceptionType as AchExceptionType,
            secCode: secCode as AchSecCode,
            accountType: accountType as AchAccountType,
        });
    });
}
