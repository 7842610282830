/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { CompanyOptionModelCollectionDto, ErrorHttpContentDto } from "../";
import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class PositivePayCompanyConfigurationClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * @description Supported options: * PositivePay.ACHFilterManagement.AllowManageAchFilters : "0"/"1", indicates whether ACH Filters are enabled * PositivePay.ACHFilterManagement.ApproversRequired :0 (aka "None"), 1, 2, 3 * PositivePay.AllowIssuedItemActivityReview : "0"/"1" * PositivePay.ShowAchExceptions : "0"/"1" * PositivePay.SubstituteProductConfig.UseSubstituteProduct : "1"/"0".  Controls whether to use the substitute product.  Only present if have a substitute product installed (Centrix) * PositivePay.WorkAchExceptions : "1"/"0". * PositivePay.WorkCheckExceptions : "1"/"0". * PositivePay.CheckException.AllowReturnReason : "1"/"0". * PositivePay.CheckException.AllowCorrectionRequest : "1"/"0".
   *
   * @tags PositivePayCompanyConfiguration
   * @name PositivePayCompanyConfigurationGetAllOptions
   * @summary Retrieve all product options for a company Positive Pay product
   * @request GET:/positivePayCompanyConfiguration/allOptions
   */
  positivePayCompanyConfigurationGetAllOptions = (params: RequestParams = {}) =>
    this.http.request<CompanyOptionModelCollectionDto, ErrorHttpContentDto>({
      path: `/positivePayCompanyConfiguration/allOptions`,
      method: "GET",
      format: "json",
      ...params,
    });
}
