// eslint-disable-next-line import/extensions
import { JhHttpResponseType } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';

export default class AchNoticeOfChangeRequests {
    static async search(body) {
        return (await TmHttpClient.getInstance()).request(`AchNotificationOfChange/Search`, {
            body,
            method: 'POST',
        });
    }

    static async searchV2(body) {
        return (await TmHttpClient.getInstance()).request(`ach/noc/search`, {
            body,
            method: 'POST',
        });
    }

    static async getDetails(guid) {
        return (await TmHttpClient.getInstance()).request(
            `AchNotificationOfChange/${guid}/Detail`,
            {
                method: 'GET',
                maxAgeInSeconds: 1000,
            }
        );
    }

    static async downloadNotice(guid, type, notificationReceivedDate, changeCodeDescription) {
        if (type.toLowerCase() === 'nacha') {
            return (await TmHttpClient.getInstance()).request(
                `AchNotificationOfChange/${guid}/Nacha`,
                {
                    method: 'POST',
                    responseType: JhHttpResponseType.ArrayBuffer,
                }
            );
        }
        return (await TmHttpClient.getInstance()).request(
            `AchNotificationOfChange/${guid}/Download`,
            {
                method: 'POST',
                responseType: JhHttpResponseType.ArrayBuffer,
                body: { notificationReceivedDate, changeCodeDescription },
            }
        );
    }

    static async downloadNoticeV2(guid, body) {
        return (await TmHttpClient.getInstance()).request(`ach/noc/${guid}/download`, {
            method: 'POST',
            responseType: JhHttpResponseType.Raw,
            body,
        });
    }

    static async downloadNoticeReport(body) {
        return (await TmHttpClient.getInstance()).request(`ach/noc/search/download`, {
            method: 'POST',
            responseType: JhHttpResponseType.Raw,
            body,
        });
    }
}
