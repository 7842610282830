/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import {
  AddItemRequestModelDto,
  AddItemResponseModelDto,
  AuthenticateRequestModelDto,
  AuthenticatedRequestModelDto,
  AuthenticatedResponseModelDto,
  CreateBatchRequestModelDto,
  CreateBatchResponseModelDto,
  GetBatchesResponseModelDto,
  GetCustomersRequestModelDto,
  GetCustomersResponseModelDto,
  GetDataFieldsRequestModelDto,
  GetDataFieldsResponseModelDto,
  GetItemImagesRequestModelDto,
  GetItemImagesResponseModelDto,
  GetItemsRequestModelDto,
  GetItemsResponseModelDto,
  GetLocationsResponseModelDto,
  GetSettingsResponseModelDto,
  StatusModelDto,
  UpdateBatchRequestModelDto,
  UpdateBatchResponseModelDto,
  UpdateItemRequestModelDto,
} from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class RemoteDepositCaptureClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureAddItem
   * @summary Adds the item.
   * @request POST:/RemoteDepositCapture/AddItem
   */
  remoteDepositCaptureAddItem = (model: AddItemRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<AddItemResponseModelDto, any>({
      path: `/RemoteDepositCapture/AddItem`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureAuthenticate
   * @summary Authenticates the specified model.
   * @request POST:/RemoteDepositCapture/Authenticate
   */
  remoteDepositCaptureAuthenticate = (model: AuthenticateRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<AuthenticatedResponseModelDto, any>({
      path: `/RemoteDepositCapture/Authenticate`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureCloseBatch
   * @summary Closes the batch.
   * @request POST:/RemoteDepositCapture/CloseBatch
   */
  remoteDepositCaptureCloseBatch = (model: UpdateBatchRequestModelDto, params: RequestParams = {}) =>
    this.http.request<UpdateBatchResponseModelDto, any>({
      path: `/RemoteDepositCapture/CloseBatch`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureCreateBatch
   * @summary Creates the batch.
   * @request POST:/RemoteDepositCapture/CreateBatch
   */
  remoteDepositCaptureCreateBatch = (model: CreateBatchRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<CreateBatchResponseModelDto, any>({
      path: `/RemoteDepositCapture/CreateBatch`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureDeleteBatch
   * @summary Deletes the batch.
   * @request POST:/RemoteDepositCapture/DeleteBatch
   */
  remoteDepositCaptureDeleteBatch = (model: UpdateBatchRequestModelDto, params: RequestParams = {}) =>
    this.http.request<UpdateBatchResponseModelDto, any>({
      path: `/RemoteDepositCapture/DeleteBatch`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureUpdateItem
   * @summary Updates the item.
   * @request POST:/RemoteDepositCapture/DeleteItem
   */
  remoteDepositCaptureUpdateItem = (model: UpdateItemRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<UpdateBatchResponseModelDto, any>({
      path: `/RemoteDepositCapture/DeleteItem`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetBatches
   * @summary Gets the batches.
   * @request POST:/RemoteDepositCapture/GetBatches
   */
  remoteDepositCaptureGetBatches = (model: AuthenticatedRequestModelDto, params: RequestParams = {}) =>
    this.http.request<GetBatchesResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetBatches`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetCustomers
   * @summary Gets the customers.
   * @request POST:/RemoteDepositCapture/GetCustomers
   */
  remoteDepositCaptureGetCustomers = (model: GetCustomersRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<GetCustomersResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetCustomers`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetDataFields
   * @summary Gets the data fields.
   * @request POST:/RemoteDepositCapture/GetDataFields
   */
  remoteDepositCaptureGetDataFields = (model: GetDataFieldsRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<GetDataFieldsResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetDataFields`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetItemImages
   * @summary Gets the item images.
   * @request POST:/RemoteDepositCapture/GetItemImages
   */
  remoteDepositCaptureGetItemImages = (model: GetItemImagesRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<GetItemImagesResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetItemImages`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetItems
   * @summary Gets the items.
   * @request POST:/RemoteDepositCapture/GetItems
   */
  remoteDepositCaptureGetItems = (model: GetItemsRequestModelDto | null, params: RequestParams = {}) =>
    this.http.request<GetItemsResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetItems`,
      method: "POST",
      body: model,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetLocations
   * @summary Gets the locations.
   * @request POST:/RemoteDepositCapture/GetLocations
   */
  remoteDepositCaptureGetLocations = (model: AuthenticatedRequestModelDto, params: RequestParams = {}) =>
    this.http.request<GetLocationsResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetLocations`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetSettings
   * @summary Gets the settings.
   * @request POST:/RemoteDepositCapture/GetSettings
   */
  remoteDepositCaptureGetSettings = (model: AuthenticatedRequestModelDto, params: RequestParams = {}) =>
    this.http.request<GetSettingsResponseModelDto, any>({
      path: `/RemoteDepositCapture/GetSettings`,
      method: "POST",
      body: model,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RemoteDepositCapture
   * @name RemoteDepositCaptureGetStatus
   * @summary Gets the status.
   * @request GET:/RemoteDepositCapture/GetStatus
   */
  remoteDepositCaptureGetStatus = (params: RequestParams = {}) =>
    this.http.request<StatusModelDto, any>({
      path: `/RemoteDepositCapture/GetStatus`,
      method: "GET",
      format: "json",
      ...params,
    });
}
