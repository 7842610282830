import { getCreditorIsoAddress } from '@treasury/domain/wires';

/**
 * @typedef { ReturnType<import('../../../../services').WireService> } WireService
 * @typedef { import('@treasury/api/channel').WireModelDto } WireDto
 */

/**
 * @typedef { object } LocalScope
 * @property { WireDto[] } wires
 */

/**
 * @typedef { LocalScope & ng.IScope } AugmentedScope
 */

/**
 * @param { AugmentedScope } $scope
 * @param { WireService } wireService
 */
export default function CreateMultipleWireController(
    $scope,
    $state,
    $modal,
    $timeout,
    wireService,
    wireCompaniesService,
    beneficiariesService,
    companyAccountsService,
    entitlementsService,
    utilityService,
    holidaysService,
    securityService,
    modalService,
    toaster,
    downloadPageId,
    accountService,
    dateConstants,
    regexConstants,
    wireIsoService,
    countriesService
) {
    // scope
    $scope.wires = [];
    $scope.wireCompanies = [];
    $scope.beneficiaries = [];
    $scope.disableToday = false;
    $scope.currentDate = moment(new Date()).format('MM/DD/YYYY');
    $scope.defaultDate = moment(new Date()).format('MM/DD/YYYY');
    $scope.wizardController = {};
    $scope.isSaving = false;

    // scope functions
    $scope.isConfirmButtonDisabled = isConfirmButtonDisabled;
    $scope.isWireSftpEnabled = isWireSftpEnabled;
    $scope.setForm = setForm;
    $scope.setMode = setMode;
    $scope.addWire = addWire;
    $scope.removeWire = removeWire;
    $scope.wireCompanySelected = wireCompanySelected;
    $scope.showBeneficiaryModal = showBeneficiaryModal;
    $scope.showAccountModal = showAccountModal;
    $scope.onCalendarChange = onCalendarChange;
    $scope.addAdditionalInformation = addAdditionalInformation;
    $scope.getAdditionalPlaceholderText = getAdditionalPlaceholderText;
    $scope.deleteAdditionalInformation = deleteAdditionalInformation;
    $scope.review = review;
    $scope.confirm = confirm;
    $scope.cancel = cancel;
    $scope.goBack = goBack;
    $scope.createAnotherPayment = createAnotherPayment;
    $scope.viewPaymentList = viewPaymentList;
    $scope.goToWireTemplateList = goToWireTemplateList;
    $scope.isObject = isObject;
    $scope.isValidObjects = isValidObjects;
    $scope.downloadPageId = downloadPageId.BulkCreateWireConfirmation;
    $scope.filterObject = {};
    $scope.requirePurpose = false;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.weekDays = dateConstants.weekDays;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.beneficiaryReferenceRegex = regexConstants.BeneficiaryReferenceRegex;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.wirePaymentActivityButtonLabel = 'View Wire Activity';
    $scope.countries = [];
    $scope.batchBeneficiaryDisplayAddress = batchBeneficiaryDisplayAddress;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });
    $scope.wireIsoFf = false;
    wireIsoService.getEntitlementWireISO20022().then(ff => {
        $scope.wireIsoFf = ff;
    });

    let noBeneficiaries;
    let noTemplates;

    // init
    function init() {
        loadSelectedTemplates();
        setMode('edit');
        if (noTemplates) {
            getBeneficiaries();
            getWireCompanies();
        }
        loadWireConfiguration();
        getCutoff();
        getCurrencies();
        getCountries();
        loadFrequencyOptions();
        loadAccountDisplayField();
        loadSelectedBeneficiaries();
        getHolidays();
        if (noBeneficiaries && noTemplates) {
            $state.go('payables.wire.create-wire');
        }
    }

    function isConfirmButtonDisabled() {
        return $scope.isSaving;
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function isWireSftpEnabled() {
        return hasEntitlement('Feature.Wire.SFTP.DataTransmissions');
    }

    function useThreeLineAddress(beneficiary) {
        return beneficiariesService.canUseThreeLineAddress(beneficiary);
    }

    function loadAccountDisplayField() {
        accountService.getSettings().then(settings => {
            $scope.accountDisplayField = settings.accountDisplayField;
        });
    }

    function loadSelectedTemplates() {
        const templates = wireService.getSelectedTemplates();
        if (templates !== null && templates !== undefined && templates.length > 0) {
            $scope.templates = templates;
            wireService.setSelectedTemplates([]);

            angular.forEach($scope.templates, template => {
                beneficiariesService.get(template.beneficiary.id).then(response => {
                    template.beneficiary = response;
                    $scope.wires.push(initializeWireFromTemplate(template));
                });
            });
        } else {
            noTemplates = true;
        }
    }

    // scope functions imple
    function setForm(form) {
        $scope.form = form;
    }

    function setMode(mode) {
        switch (mode) {
            case 'edit':
                $scope.editMode = true;
                $scope.reviewMode = false;
                $scope.confirmMode = false;
                break;
            case 'review':
                $scope.editMode = false;
                $scope.reviewMode = true;
                $scope.confirmMode = false;
                break;
            case 'confirm':
                $scope.editMode = false;
                $scope.reviewMode = false;
                $scope.confirmMode = true;
                break;
        }
    }

    function addWire() {
        $scope.wires.unshift(initializeWire());
    }

    function removeWire(index) {
        $scope.wires.splice(index, 1);
        if ($scope.wires.length === 0) {
            $state.go('payables.wire.create-wire');
        }
    }

    function wireCompanySelected(wire) {
        // clear account
        wire.debitAccount = null;

        // load accounts based on international and wire company
        const create = wire.beneficiary.isInternational ? 'createIntl' : 'createDomestic';
        wireCompaniesService.getDebitAccounts(wire.wireCompany.id, create).then(response => {
            wire.companyAccounts = response;
        });
    }

    function showBeneficiaryModal(wire) {
        const modalInstance = $modal.open({
            template: require('../views/beneficiaryModalView.html'),
            size: 'lg',
            controller: 'BeneficiarySelectionController',
            backdrop: 'static',
            resolve: {
                type() {
                    return '';
                },
                beneficiaries() {
                    return $scope.beneficiaries;
                },
                enableFreeFormEntry() {
                    return false;
                },
            },
        });
        modalInstance.result.then(selectedBeneficiary => {
            wire.beneficiary = angular.copy(selectedBeneficiary);
            setBeneficiaryDisplayAddress(wire.beneficiary);
            if (wire.beneficiary.isInternational) {
                wire.frequency.valueDate = $scope.defaultDate;
            }
        });
    }

    function setBeneficiaryDisplayAddress(beneficiary) {
        beneficiary.displayAddress = getCreditorIsoAddress(beneficiary, $scope.countries);
        beneficiary.bank.displayAddress = getCreditorIsoAddress(beneficiary.bank, $scope.countries);
        return beneficiary;
    }

    function batchBeneficiaryDisplayAddress() {
        if (!$scope.wireIsoFf) return;
        $scope.wires.forEach(wire => {
            setBeneficiaryDisplayAddress(wire.beneficiary);
        });
    }

    function showAccountModal(wire) {
        const modalInstance = $modal.open({
            template: require('../views/accountModalView.html'),
            size: 'md',
            controller: 'AccountsController',
            backdrop: 'static',
            resolve: {
                companyAccounts() {
                    return wire.companyAccounts;
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            wire.debitAccount = angular.copy(selectedAccount);
        });
    }

    $scope.calendarOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function onCalendarChange(wire) {
        if (!isNaN(Date.parse(wire.frequency.startOn))) {
            const dt = new Date(wire.frequency.startOn);
            if (wire.frequency.type === 'Weekly') {
                wire.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if (wire.frequency.type === 'EveryTwoWeeks') {
                wire.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (wire.frequency.type === 'TwiceaMonth' || wire.frequency.type === 'Monthly') {
                wire.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if (wire.frequency.type === 'Quarterly') {
                wire.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if (wire.frequency.type === 'EverySixMonths') {
                wire.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if (wire.frequency.type === 'Yearly') {
                wire.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    }

    function addAdditionalInformation(wire) {
        if (wire.additionalInformation.length === 4) {
            return;
        }
        wire.additionalInformation.push({ value: '' });
    }

    function getAdditionalPlaceholderText(wire, index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function deleteAdditionalInformation(wire, index) {
        wire.additionalInformation.splice(index, 1);
    }

    function review() {
        $scope.errorSummary = '';
        setMode('review');
        $scope.batchBeneficiaryDisplayAddress();
        $scope.wizardController.goToNextStep();
    }

    function confirm() {
        $scope.isSaving = true;
        const payload = {
            wires: $scope.wires,
        };
        $scope.errorSummary = '';
        securityService
            .verifyUser('Create Wire', payload, () => wireService.initiateBulk(payload))
            .then(response => {
                $scope.wires = response.wires;
                $scope.batchBeneficiaryDisplayAddress();
                const created = parseResponse($scope.wires);
                if (created) {
                    setMode('confirm');
                    $scope.wizardController.goToNextStep();
                } else {
                    setMode('edit');
                    $scope.wizardController.goToPreviousStep();
                }
            })
            .finally(() => {
                $scope.isSaving = false;
            });
    }

    function goBack() {
        setMode('edit');
        $scope.wizardController.goToPreviousStep();
    }

    function parseResponse(wires) {
        let wiresCreated = false;
        $scope.filterObject.bulkWireIds = [];
        angular.forEach(wires, wire => {
            if (wire && wire.id) {
                $scope.filterObject.bulkWireIds.push(wire.id);
                wiresCreated = true;
            }
            if (wire && wire.errorSummary) {
                if (wire.errorSummary.details && wire.errorSummary.details.length > 0) {
                    let wireDetailMessage = '';
                    const wireDetails = [];
                    let accountDetailMessage = '';
                    angular.forEach(wire.errorSummary.details, detail => {
                        if (detail.attributeName === 'balance') {
                            accountDetailMessage += detail.message;
                        } else {
                            wireDetailMessage += detail.message;
                            if (detail.messageList && detail.messageList.length > 0) {
                                angular.forEach(detail.messageList, message => {
                                    wireDetails.push(message.value);
                                });
                            }
                        }
                    });
                    wire.errorWire = wireDetailMessage;
                    wire.errorWireDetails = wireDetails;
                    wire.errorAccount = accountDetailMessage;
                }
            }
        });

        if (!wiresCreated) {
            $scope.errorSummary = 'An error occurred while creating your wires.';
        }

        return wiresCreated;
    }

    function cancel() {
        if (!$scope.form.$dirty && !$scope.reviewMode) {
            if ($scope.templates) {
                $state.go('payables.wire.wire-template-list');
            } else {
                $state.go('payables.wire.wire-list');
            }
        } else {
            confirmCancel();
        }
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Cancel Edits',
            headerText: 'Confirm Cancellation',
            bodyText: 'Are you sure you want to cancel the changes you have made?',
            submit(result) {
                $modalInstance.close();
                if ($scope.templates) {
                    $state.go('payables.wire.wire-template-list');
                } else {
                    $state.go('payables.wire.wire-list');
                }
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function createAnotherPayment() {
        $state.go('payables.wire.create-wire');
    }

    function viewPaymentList() {
        $state.go('payables.wire.wire-list');
    }

    function goToWireTemplateList() {
        $state.go('payables.wire.wire-template-list');
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function isValidObjects() {
        let result = true;
        angular.forEach($scope.wires, wire => {
            if (!wire.debitAccount || !isObject(wire.debitAccount)) {
                result = false;
            }
            if (!wire.beneficiary || !isObject(wire.beneficiary)) {
                result = false;
            }
            if (!wire.amount) {
                result = false;
            }
        });
        return result;
    }

    // private functions
    function initializeWire(beneficiary) {
        return {
            beneficiary: beneficiary ? setBeneficiaryDisplayAddress(beneficiary) : null,
            frequency: {
                type: 'OneTime',
                valueDate: $scope.defaultDate,
            },
            isInternational: beneficiary ? beneficiary.isInternational : false,
            additionalInformation: [{ value: '' }],
        };
    }

    function initializeWireFromTemplate(template) {
        return {
            beneficiary: template ? template.beneficiary : null,
            frequency: {
                type: 'OneTime',
                valueDate: $scope.defaultDate,
            },
            additionalInformation:
                template.notes === null
                    ? [{ value: '' }]
                    : template.notes.map(note => ({ value: note })),
            wireCompany: template.wireCompany,
            isInternational: template.isInternational,
            purpose: template.purpose,
            debitAccount: template.debitAccount,
            templateName: template.name,
            wireTemplate: { id: template.id },
            referenceBeneficiary: template.referenceBeneficiary,
        };
    }

    function loadSelectedBeneficiaries() {
        const beneficiaries = beneficiariesService.getSelectedBeneficiaries();
        if (!beneficiaries || beneficiaries.length === 0) {
            noBeneficiaries = true;
        }
        beneficiariesService.setSelectedBeneficiaries([]);
        angular.forEach(beneficiaries, bene => {
            $scope.wires.push(initializeWire(bene));
        });
    }

    function getBeneficiaries() {
        const filter = {
            beneficiaryType: 'Domestic',
            status: 'Ready',
            requireEntitlement: false,
        };

        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            const entitledBeneficiaries = [];
            angular.forEach(response, bene => {
                if (hasAccessPermissions(bene, 'CreateWire')) {
                    entitledBeneficiaries.push(bene);
                }
            });
            $scope.beneficiaries = entitledBeneficiaries;
        });
    }

    function getWireCompanies() {
        wireCompaniesService.getAll(true).then(response => {
            $scope.wireCompanies = response;
        });
    }

    function loadWireConfiguration() {
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.requirePurpose = response.requirePurpose;
            $scope.maximumFutureDays = response.maximumFutureDays;
            if ($scope.maximumFutureDays) {
                $scope.futureDatedLimit = formatDate(
                    moment().add({ days: $scope.maximumFutureDays })
                );
            }
        });
    }

    function getCutoff() {
        companyAccountsService.getCutoffTimesByProductType('WireTransfer').then(response => {
            $scope.defaultDate = formatDate(response.currentFiTime);
            $scope.processingCutoff = response;
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;
                angular.forEach($scope.wires, wire => {
                    if (wire && wire.frequency) {
                        if (
                            moment(wire.frequency.valueDate).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    wire.frequency.valueDate = formatDate(response);
                                    wire.endOnDate = formatDate(response);
                                });
                        }
                        if (
                            moment(wire.frequency.startOn).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    wire.frequency.startOn = formatDate(response);
                                });
                        }
                        if (
                            moment(wire.frequency.endOn).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    wire.frequency.endOn = formatDate(response);
                                });
                        }
                    }
                });
                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create wire for today.');
                }
            }, timeout);
        });
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(response => {
                    $scope.currentDate = formatDate(response);
                });
            angular.forEach($scope.wires, wire => {
                utilityService
                    .getNextBusinessDay(
                        $scope.holidayDates,
                        $scope.disableToday,
                        wire.frequency.valueDate
                    )
                    .then(response => {
                        wire.endOnDate = formatDate(response);
                        wire.frequency.valueDate = formatDate(response);
                    });
            });
        });
    }

    function getCurrencies() {
        $scope.destinationCurrencies = [
            {
                cc: 'USD',
                name: 'United States dollar',
                symbol: 'US$',
            },
        ];
    }

    function getCountries() {
        countriesService.getAll().then(countries => {
            $scope.countries = countries;
        });
    }

    function loadFrequencyOptions() {
        $scope.wireFrequencies = [
            {
                name: 'One Time',
                key: 'OneTime',
            },
            {
                name: 'Weekly',
                key: 'Weekly',
            },
            {
                name: 'Every Two Weeks',
                key: 'EveryTwoWeeks',
            },
            {
                name: 'Twice a Month',
                key: 'TwiceaMonth',
            },
            {
                name: 'Monthly',
                key: 'Monthly',
            },
            {
                name: 'Quarterly',
                key: 'Quarterly',
            },
            {
                name: 'Every Six Months',
                key: 'EverySixMonths',
            },
            {
                name: 'Yearly',
                key: 'Yearly',
            },
        ];
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function hasAccessPermissions(entity, permissionType) {
        let result = false;
        angular.forEach(entity.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    init();
}

CreateMultipleWireController.$inject = [
    '$scope',
    '$state',
    '$modal',
    '$timeout',
    'wireService',
    'wireCompaniesService',
    'beneficiariesService',
    'companyAccountsService',
    'entitlementsService',
    'utilityService',
    'holidaysService',
    'securityService',
    'modalService',
    'toaster',
    'downloadPageId',
    'accountService',
    'dateConstants',
    'regexConstants',
    'wireIsoService',
    'countriesService',
];
