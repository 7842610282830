/* eslint-disable import/extensions */
import { JhHttpClientCached } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';

export default class EntitlementsRequests {
    static async getEntitlements() {
        return (await TmHttpClient.getInstance()).request(`entitlements`, {
            method: 'GET',
            maxAgeInSeconds: 3600,
        });
    }

    static async getEntitlementGroups() {
        return (await TmHttpClient.getInstance()).request(`entitlementgroups`, {
            method: 'GET',
            maxAgeInSeconds: 3600,
        });
    }

    static async resetEntitlementsCache() {
        const http = await TmHttpClient.getInstance();
        if (!(http instanceof JhHttpClientCached)) {
            throw new Error('HTTP client does not implement caching.');
        }

        http.resetCachedValue(`entitlements`, {
            method: 'GET',
        });
    }

    static async resetEntitlementGroupsCache() {
        const http = await TmHttpClient.getInstance();
        if (!(http instanceof JhHttpClientCached)) {
            throw new Error('HTTP client does not implement caching.');
        }

        http.resetCachedValue(`entitlementgroups`, {
            method: 'GET',
        });
    }
}
