import { exists } from '@jack-henry/frontend-utils/functions';
import { Primitive, WidenLiterals } from '@treasury/utils';

export const isEdited = <T extends Primitive>(
    original?: WidenLiterals<T>,
    edited?: WidenLiterals<T>
) => {
    if (!exists(original)) {
        return exists(edited);
    }

    return original !== edited;
};
