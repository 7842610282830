import { clone, exists } from '@jack-henry/frontend-utils/functions';
import { UserInformationModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../../../shared';

export class UserAccount extends DomainEntity<UserInformationModelDto> {
    constructor(dto = clone(defaultFormData) as UserInformationModelDto) {
        super(dto);
    }

    public get name() {
        return this.dto.name;
    }

    public set name(val) {
        this.dto.name = val;
    }

    public get loginId() {
        return this.dto.loginId;
    }

    public set loginId(val) {
        this.dto.loginId = val;
    }

    public get email() {
        return this.dto.email;
    }

    public set email(val) {
        this.dto.email = val;
    }

    public get extension() {
        const { extension } = this.dto;
        return exists(extension) && extension.length === 0 ? undefined : extension;
    }

    public set extension(val) {
        this.dto.extension = val || '';
    }

    public get phone() {
        return this.dto.phone;
    }

    public set phone(val) {
        this.dto.phone = val;
    }

    public get mobile() {
        const { mobile } = this.dto;
        return exists(mobile) && mobile.length === 0 ? undefined : mobile;
    }

    public set mobile(val) {
        this.dto.mobile = val;
    }

    public get fax() {
        const { fax } = this.dto;
        return exists(fax) && fax.length === 0 ? undefined : fax;
    }

    public set fax(val) {
        this.dto.fax = val || '';
    }

    public get accountNicknames() {
        return this.dto.accountNicknames ?? false;
    }

    public set accountNicknames(val) {
        this.dto.accountNicknames = val;
    }

    public get accountMasking() {
        return this.dto.accountMasking ?? false;
    }

    public set accountMasking(val) {
        this.dto.accountMasking = val;
    }

    public get verificationMethod() {
        return this.dto.verificationMethod;
    }

    public get isAccountMaskingConfigurable() {
        return this.dto.isAccountMaskingConfigurable;
    }

    public createDefault() {
        return new UserAccount() as this;
    }
}

const defaultFormData: Partial<UserInformationModelDto> = Object.freeze({
    name: '',
    loginId: '',
    email: '',
    phone: '',
    extension: '',
    mobile: '',
    fax: '',
    accountNicknames: false,
    accountMasking: false,
    verificationMethod: '',
    isAccountMaskingConfigurable: true,
});
