import { FiDate } from '@treasury/domain/dates';
import { boolean, date, list, money, number, string } from '@treasury/policy/primitives';
import { html } from 'lit';
import { returnReasonCommentAndAttachment } from '../parts/return-reason-comment-and-attachment.ts';

export default function checkExceptionFields(returnReasons, isAfterCutoff, hasReturnReason) {
    return {
        accountNumber: string.thatIs.readOnly().with.label('Account Number'),
        accountUniqueId: string.thatIs.readOnly(),
        amount: number.thatIs.readOnly().with.label('Amount'),
        arpExceptionDetailId: number.thatIs.readOnly(),
        arpExceptionDetailUniqueId: string.thatIs.readOnly(),
        checkImageNumber: number.thatIs.readOnly(),
        checkNumber: number.thatIs.readOnly().with.label('Check Number'),
        comment: string.with.rowCount(3).thatHas.maxLength(150).with.label('Comment'),
        commentToSave: string.with.rowCount(3).thatHas.maxLength(150).with.label('Comment'),
        createdDate: string.thatIs.readOnly(),
        date: string.thatIs.readOnly(),
        ddaBatchNumber: string.thatIs.readOnly().with.label('DDA Batch Number'),
        ddaSequenceNumber: string.thatIs.readOnly().with.label('DDA Sequence Number'),
        decisionHistory: boolean.thatIs.readOnly(),
        decisionStatus: string.thatIs.readOnly().with.label('Previous Decision'),
        decisionChoice: string.thatIs.readOnly(),
        decisions: string.thatIs.readOnly(),
        description: string.thatIs.readOnly(),
        exceptionReason: string.thatIs.readOnly().with.label('Exception Reason'),
        returnReasonDescription: string.thatIs
            .readOnly()
            .and.visibleWhen(() => hasReturnReason)
            .with.label('Return Reason'),
        fiId: string.thatIs.readOnly(),
        fromWorkStation: string.thatIs.readOnly(),
        id: number.thatIs.readOnly(),
        institutionCheckExceptionReturnReasonUniqueId: string.thatIs.readOnly(),
        issuedAmount: money.thatIs.readOnly().with.label('Issued Amount'),
        issuedDate: date.thatIs.readOnly().with.label('Date Issued'),
        issuedPayee: string.thatIs.readOnly().with.label('Payee'),
        lastDecisionTakenBy: string.thatIs.readOnly().with.label('Previous Decision By'),
        lastDecisionDate: string.thatIs.readOnly(),
        paidAmount: money.thatIs.readOnly().with.label('Paid Amount'),
        pay: string.thatIs.readOnly(),
        pendingDecision: string.thatIs.readOnly(),
        posPayItemId: string.thatIs.readOnly(),
        postedDate: date.thatIs.readOnly().with.label('Date Posted'),
        protected: string.thatIs.readOnly().with.label('Protected (Y/N)'),
        returnReasonComment: string,
        returnReasonWithCommentAndAttachment: string.thatIs
            .readOnly()
            .with.template(
                (value, record) => html`
                    <span class="returnReasonDescription"
                        >${record.getField('returnReasonDescription')}</span
                    >
                    ${returnReasonCommentAndAttachment(record)}
                `
            )
            .with.label('Return Reason'),
        sourceOfEntry: string.thatIs.readOnly().with.label('Source of Entry'),
        updatedBy: number.thatIs.readOnly(),
        updatedDate: string.thatIs.readOnly(),
        returnReason: string.with
            .options({
                data: returnReasons,
                value: item => item.returnReasonUniqueId,
                text: item => item.description,
            })
            .with.placeholder('Select a Reason')
            .thatIs.visibleWhen(record => record.getField('decisionChoice') === 'Return')
            .thatIs.visibleWhen(record => record.getField('protected') === 'N')
            .thatIs.readOnlyWhen(record => record.getField('step') === 1)
            .thatIs.readOnlyWhen(record => record.getField('isPastCutOffTime'))
            .thatIs.readOnlyWhen(() => isAfterCutoff)
            .with.minColumnWidth(175)
            .as.tag('omega-select'),
        audits: list.thatIs
            .readOnly()
            .with.label('Audit')
            .thatHas.template((v /* record */) => {
                const printLog = log =>
                    html`Action: ${log.action} <br />
                        Time Stamp: ${new FiDate(log.timestamp.split('T')[0])}
                        ${log.timestamp.split('T')[1].split('.')[0]} <br />
                        User Name: ${log.username}
                        <hr />`;
                return html`<style>
                        .audit-wrapper {
                            overflow-y: scroll;
                            min-width: 100px;
                            min-height: 90px;
                            height: 300px;
                            padding: 5px;
                            border: 1px solid #ccc;
                            background-color: #eee;
                        }
                    </style>
                    <div class="audit-wrapper">${v.map(log => printLog(log))}</div>`;
            }),
        isCorrectionRequestCreated: boolean.thatIs.readOnly(),
        step: number.thatIs.visibleWhen(() => false),
        isPastCutOffTime: boolean.thatIs.visibleWhen(() => false).and.defaultValue(false),
        userDecisionedToday: boolean.thatIs
            .readOnly()
            .thatHas.textAlign('center')
            .and.template(
                value =>
                    html`<omega-icon
                        style="--omega-icon-font-size: 1.4em; --omega-icon-color: ${value
                            ? '#2caa55'
                            : '#ccc'};"
                        icon="check-circle-o"
                    ></omega-icon>`
            ),
    };
}
