import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    BanksClient,
    BeneficiariesClient,
    BeneficiarySearchCriteriaModelDto,
    CountriesClient,
    CreditorsClient,
    SearchCreditorsRequestDto,
    StatesClient,
} from '@treasury/api/channel';
import { Bank } from './bank.entity';
import { WireBeneficiary } from './wire-beneficiary.entity';
import { WireCreditor } from './wire-creditor.entity';
import { WireIsoService } from './wireIso.service';

@Injectable()
export class CreditorsService {
    constructor(
        private readonly beneficiariesClient: BeneficiariesClient,
        private readonly creditorsClient: CreditorsClient,
        private readonly wireIsoService: WireIsoService,
        private readonly statesClient: StatesClient,
        private readonly countriesClient: CountriesClient,
        private readonly banksClient: BanksClient
    ) {}

    public async getAll(searchParameters: SearchCreditorsRequestDto) {
        if (await this.getWireIsoFf()) {
            // eslint-disable-next-line no-restricted-syntax
            const response = await this.creditorsClient.creditorsSearch(searchParameters);
            return response.data.map(account => new WireCreditor(account));
        } else {
            const response = await this.beneficiariesClient.beneficiariesGetAll();
            return response.data.map(account => new WireBeneficiary(account));
        }
    }

    public async getById(id: number) {
        const response = await this.beneficiariesClient.beneficiariesGet(id);
        return new WireCreditor(response.data as WireCreditor);
    }

    public async create(creditor: WireCreditor) {
        const dto = creditor.toDto();
        if (await this.getWireIsoFf()) {
            const response = await this.creditorsClient.creditorsPost(dto);
            return new WireCreditor(response.data);
        } else {
            const response = await this.beneficiariesClient.beneficiariesPost(dto);
            return new WireBeneficiary(response.data);
        }
    }

    public async delete(beneficiaryId: number) {
        return this.beneficiariesClient.beneficiariesDelete({
            beneficiaryId,
            id: 0,
            updatedDate: '',
        });
    }

    public async getBeneficiariesByType(searchParameters: SearchCreditorsRequestDto) {
        if (await this.getWireIsoFf()) {
            const response = await this.creditorsClient.creditorsSearch(searchParameters);
            return response.data.map(account => new WireCreditor(account));
        } else {
            const response = await this.beneficiariesClient.beneficiariesSearch(
                searchParameters as unknown as BeneficiarySearchCriteriaModelDto
            );
            return response.data.map(account => new WireBeneficiary(account));
        }
    }

    public async getBanks() {
        const response = await this.banksClient.banksGet();
        return response.data.map(bank => new Bank(bank));
    }

    public async getStates() {
        const response = await this.statesClient.statesGet();
        return response.data;
    }

    public async getCountries() {
        const response = await this.countriesClient.countriesGet();
        return response.data;
    }

    public validateAgentId(id: string) {
        let checksum = 0;
        for (let i = 0; i < 3; i++) {
            checksum += Number(id[i * 3]) * 3;
            checksum += Number(id[i * 3 + 1]) * 7;
            checksum += Number(id[i * 3 + 2]) * 1;
        }
        return !(checksum % 10) && checksum > 0;
    }

    private getWireIsoFf() {
        return this.wireIsoService.getEntitlementWireISO20022();
    }
}
