import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import FieldTypes from '@treasury/policy/primitives';
import { renderAuditText } from '../templates';

const ReadOnlyStringField = FieldTypes.string.thatIs.readOnly() as FieldType<string>;

// eslint-disable-next-line @treasury/no-date
const ReadonlyDateField = FieldTypes.date.thatIs.readOnly() as FieldType<Date>;

const AccountNameField = ReadOnlyStringField.with.minColumnWidth(400) as FieldType<string>;

const AuditItemsField = new FieldType<IIssuedItem['auditItems']>().with
    .sortable(false)
    .and.template(audits => renderAuditText(audits));

const CheckAmountField = FieldTypes.currency.thatIs
    .readOnly()
    .with.reducer('sum') as FieldType<number>;

export const fields: FdlFieldDefinitions<IIssuedItem> = {
    createdDate: ReadonlyDateField as FieldType<IIssuedItem['createdDate']>,
    dateIssued: ReadonlyDateField as FieldType<IIssuedItem['dateIssued']>,
    voidedDate: ReadonlyDateField as FieldType<IIssuedItem['voidedDate']>,
    postDate: ReadonlyDateField as FieldType<IIssuedItem['postDate']>,
    checkAmount: CheckAmountField,
    accountName: AccountNameField,
    auditItems: AuditItemsField,
    payee: ReadOnlyStringField,
    checkNumber: ReadOnlyStringField as FieldType<IIssuedItem['checkNumber']>,
    itemStatus: ReadOnlyStringField as FieldType<IIssuedItem['itemStatus']>,
    type: ReadOnlyStringField as FieldType<IIssuedItem['type']>,
    itemEntryType: ReadOnlyStringField as FieldType<IIssuedItem['itemEntryType']>,
};

export const columns: (
    hasIssuedItemsClearedDate: boolean
) => OmegaColumnDefinition<IIssuedItem>[] = (hasIssuedItemsClearedDate = false) => {
    let cols = [
        {
            field: 'itemEntryType' as keyof IIssuedItem,
            label: 'Item Entry Type',
        },
        {
            field: 'type' as keyof IIssuedItem,
            label: 'Item Type',
        },
        {
            field: 'itemStatus' as keyof IIssuedItem,
            label: 'Item Status',
        },
        {
            field: 'dateIssued' as keyof IIssuedItem,
            label: 'Issued Date',
        },
        {
            field: 'createdDate' as keyof IIssuedItem,
            label: 'Created Date',
        },
        {
            field: 'voidedDate' as keyof IIssuedItem,
            label: 'Void Date',
        },
        {
            field: 'postDate' as keyof IIssuedItem,
            label: 'Cleared Date',
        },
        {
            field: 'accountName' as keyof IIssuedItem,
            label: 'Account',
        },
        {
            field: 'checkAmount' as keyof IIssuedItem,
            label: 'Amount',
            summary: true,
        },
        {
            field: 'checkNumber' as keyof IIssuedItem,
            label: 'Check Number',
        },
        {
            field: 'payee' as keyof IIssuedItem,
            label: 'Payee',
        },
        {
            field: 'auditItems' as keyof IIssuedItem,
            type: OmegaColumnType.Tooltip,
            label: '',
            icon: 'list-alt',
        },
    ];
    if (hasIssuedItemsClearedDate) {
        return cols;
    } else {
        return cols.filter(col => col.field !== 'postDate');
    }
};
