CreateStpController.$inject = [
    '$scope',
    '$modal',
    '$state',
    'modalService',
    'stpService',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'TmDi',
];

/**
 * @param { ReturnType<import('../../../services/stpService.js').default> } stpService
 * @param { import('@jack-henry/frontend-utils/di').DiContainer} TmDi
 */
export default function CreateStpController(
    $scope,
    $modal,
    $state,
    modalService,
    stpService,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    TmDi
) {
    $scope.downloadPageId = downloadPageId.ConfirmationStopPay;
    $scope.addRemark = addRemark;
    $scope.canReview = canReview;
    $scope.cancel = cancel;
    $scope.findAccount = findAccount;
    $scope.confirm = confirm;
    $scope.confirmCancel = confirmCancel;
    $scope.gotoList = gotoList;
    $scope.isConfirmStep = isConfirmStep;
    $scope.isLastRemarkAllowed = isLastRemarkAllowed;
    $scope.isLastRow = isLastRow;
    $scope.isObject = isObject;
    $scope.isReviewStep = isReviewStep;
    $scope.removeRemark = removeRemark;
    $scope.reset = reset;
    $scope.restart = restart;
    $scope.review = review;
    $scope.setForm = setForm;
    $scope.confirmationMessage = 'Stop Payment successfully created.';

    $scope.form = {};
    $scope.formLimits = {
        checkAmountMin: 0.01,
        checkAmountMax: 9999999999999,
        checkNumberMaxLength: 10,
        payeeMaxLength: 100,
        remarkMaxLength: 40,
    };
    $scope.stpData = {
        accounts: [],
        singleCheck: 'true',
        remarks: [{ index: 0, text: null }],
        createdStp: null,
    };
    $scope.downloadOptions = ['CSV', 'PDF', 'Word'];
    $scope.wizardController = {};
    $scope.datePickerOptions = { format: 'MM/dd/yyyy' };

    init();

    function gotoList() {
        $state.go('payables.stp.list');
    }

    function confirm() {
        if ($scope.stpData.singleCheck === 'false') {
            // SLewis@jackhenry.com, I was enumerating the range of check numbers in anticipation of disjointed ranges of
            // check numbers, but currently they are only allowing one, continuous range. I will keep the sending the
            // start and end checknumbers as a collection and keep the getCheckNumbersFromRange function in case we
            // support disjointed ranges in the future.
            $scope.stpData.checkNumbers = [
                $scope.stpData.checkNumberRangeStart,
                $scope.stpData.checkNumberRangeEnd,
            ];
        } else {
            $scope.stpData.checkNumbers = [$scope.stpData.checkNumber];
        }
        const remarks = $scope.stpData.remarks
            .filter(remark => remark.text && remark.text.trim() !== '')
            .map(remark => remark.text);
        const stpModel = {
            account: $scope.stpData.account,
            checkNumbers: $scope.stpData.checkNumbers,
            checkAmount: $scope.stpData.checkAmount,
            checkDate: $scope.stpData.checkDate,
            payee: $scope.stpData.payee,
            remarks,
        };
        stpService.create(stpModel).then(response => {
            const { id, status, totalApprovalsRequired } = response;

            $scope.stpData.createdStp = response;
            if (totalApprovalsRequired > 0) {
                $scope.confirmationMessage = 'Your request has been submitted for approval.';
            } else {
                $scope.confirmationMessage = 'Stop Payment successfully created.';
            }

            $scope.wizardController.goToNextStep();
        });
    }

    function removeRemark(remark) {
        $scope.stpData.remarks = $scope.stpData.remarks.filter(el => el.index !== remark.index);
    }

    function isLastRow(remark) {
        return remark.index === getRemarkMaxIndex();
    }

    function getRemarkMaxIndex() {
        return Math.max.apply(
            null,
            $scope.stpData.remarks.map(o => o.index)
        );
    }

    function addRemark() {
        $scope.stpData.remarks.push({ index: getRemarkMaxIndex() + 1, text: '' });
    }

    function isLastRemarkAllowed(index) {
        return index >= 3;
    }

    function loadStpAccounts() {
        stpService.getAccounts().then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.stpData.accounts = response;
        });
    }

    function review() {
        $scope.wizardController.goToNextStep();
    }

    function canReview() {
        return !$scope.form.$invalid && isValidAccount();
    }

    function isValidAccount() {
        if (isObject($scope.stpData.account)) {
            return true;
        }

        $scope.stpData.accounts.forEach(account => {
            if (account.number === $scope.stpData.account) {
                $scope.form.$dirty = true;
                $scope.stpData.account = angular.copy(account);

                return true;
            }
        });

        return false;
    }

    function restart() {
        $scope.stpData = {
            accounts: $scope.stpData.accounts,
            singleCheck: 'true',
            remarks: [{ index: 0, text: null }],
        };
        $scope.wizardController.goToStep(0);
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function init() {
        activityService.userInteractionAudit(
            resourceType.PaymentResources,
            auditCode.AccessCreateStopPayment
        );
        loadStpAccounts();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function isReviewStep() {
        return $scope.wizardController.currentStep().name === 'Review';
    }

    function isConfirmStep() {
        return $scope.wizardController.currentStep().name === 'Confirm';
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit(result) {
                if (isReviewStep()) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelReviewStopPayment
                    );
                } else {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelConfirmStopPayment
                    );
                }
                $modalInstance.close();
                $state.go('payables.stp.list');
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancel() {
        if (
            ($scope.form && $scope.form.$dirty) ||
            $scope.wizardController.currentStep().name === 'Review'
        ) {
            $scope.confirmCancel();
        } else {
            // TODO - include Check Number if possible
            if (isReviewStep()) {
                activityService.userInteractionAudit(
                    resourceType.PaymentResources,
                    auditCode.CancelReviewStopPayment
                );
            } else {
                activityService.userInteractionAudit(
                    resourceType.PaymentResources,
                    auditCode.CancelConfirmStopPayment
                );
            }
            $state.go('payables.stp.list');
        }
    }

    function reset() {
        $scope.stpData = {
            accounts: $scope.stpData.accounts,
            singleCheck: 'true',
            remarks: [{ index: 0, text: null }],
        };
        $scope.form.$setPristine();
    }

    function findAccount() {
        const modalInstance = $modal.open({
            template: require('../views/stpAccountModalView.html'),
            size: 'md',
            controller: 'StpAccountsController',
            backdrop: 'static',
            resolve: {
                companyAccounts() {
                    return $scope.stpData.accounts;
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.form.$dirty = true;
            $scope.stpData.account = angular.copy(selectedAccount);
        });
    }

    function getCheckNumbersFromRange(checkNumberRangeStart, checkNumberRangeEnd) {
        const nums = [];

        // force to numbers
        let low = checkNumberRangeStart;
        let high = checkNumberRangeEnd;

        // XOR swap, no need for an additional variable. still 3 steps though
        // http://en.wikipedia.org/wiki/XOR_swap_algorithm
        if (high < low) {
            low ^= high;
            high = low ^ high;
            low ^= high;
        }

        // push for every number starting from low
        while (low <= high) {
            nums.push(low++);
        }

        // edit to sort list at the end
        return nums.sort((a, b) => a - b);
    }
}
