/**
 * Provides an abstract interface so that mocks or an
 * alternative implementation may be provided to `Timer`.
 */
export interface TimerControls {
    setTimeout: Window['setTimeout'];
    clearTimeout: Window['clearTimeout'];
}

const defaultControls = Object.freeze<TimerControls>({
    setTimeout(handler, timeout) {
        return window.setTimeout(handler, timeout);
    },
    clearTimeout(id) {
        return window.clearTimeout(id);
    },
});

type PromiseParams = ConstructorParameters<typeof Promise<void>>;
type Executor = PromiseParams[0];
type Resolver = Parameters<Executor>[0];
type Rejector = Parameters<Executor>[1];

/**
 * Encapsulates the functionality of `window.setTimeout()` as a promise-like interface.
 *
 * Promise subclass constructor adapted from
 * [this](https://gist.github.com/domenic/8ed6048b187ee8f2ec75?permalink_comment_id=4521175#gistcomment-4521175).
 */
export class Timer extends Promise<void> {
    /**
     * This is required or else attempting to call super-class methods will fail with
     * `"Promise resolve or reject function is not callable"`
     */
    public static get [Symbol.species]() {
        return Promise;
    }

    constructor(
        /**
         * Timer duration in milliseconds.
         */
        private duration: number,
        private controls: TimerControls = defaultControls,
        private throwOnStop = true
    ) {
        let resolve: Resolver;
        let reject: Rejector;

        const executor: Executor = (res, rej) => {
            resolve = res;
            reject = rej;
        };

        super(executor);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (resolve && reject) {
            this.resolve = resolve;
            this.reject = reject;
        }
    }

    private id?: number;

    private resolve?: () => void;

    private reject?: (e: Error) => void;

    private _completed = false;

    public get completed() {
        return this._completed;
    }

    public start() {
        if (!this.resolve) {
            throw new Error('Attempted to invoke Timer before promise construction completed it.');
        }

        this.id = this.controls.setTimeout(() => {
            this._completed = true;
            if (this.resolve) {
                this.resolve();
            }
        }, this.duration);
        return this;
    }

    public stop() {
        if (!this.id) {
            return this;
        }

        this.controls.clearTimeout(this.id);
        this.id = undefined;

        if (this.reject && this.throwOnStop) {
            this.reject(new Error('Timer stopped'));
        }

        return this;
    }
}
