import { clone } from '@jack-henry/frontend-utils/functions';
import { ToAccountPermissionModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';
import { CompanyAccount } from '../../company/entities/company-account.entity';

const nullToAccountPermission: ToAccountPermissionModelDto = {
    isAllowed: false,
    toAccount: {
        id: 0,
        name: '',
        number: '',
        status: '',
        accountUniqueId: '',
    },
};

export class ToAccountPermission extends DomainEntity<ToAccountPermissionModelDto> {
    constructor(dto = clone(nullToAccountPermission)) {
        super(dto);
    }

    public createDefault(): this {
        return new ToAccountPermission(clone(nullToAccountPermission)) as this;
    }

    public get toAccount() {
        return new CompanyAccount(this.dto.toAccount);
    }

    public get toAccountId() {
        return this.dto.toAccount.account?.id ?? 0;
    }

    public get isAllowed() {
        return this.dto.isAllowed;
    }

    public set isAllowed(value: boolean) {
        this.dto.isAllowed = value;
    }

    public get toAccountName() {
        return this.dto.toAccount.name;
    }

    public get toAccountNumber() {
        return this.dto.toAccount.number;
    }

    public get toCifNumber() {
        return this.dto.toAccount.cifNumber ?? '';
    }

    public get toAccountType() {
        return this.dto.toAccount.type ?? '';
    }

    public clone(): this {
        return new ToAccountPermission(this.dto) as this;
    }
}
