import { type RootPart } from 'lit';
import { exists } from '../../functions';

/**
 * Low-level function to dispose of a `RootPart` created with Lit's `render()` function.
 */
export function disposePart(part: RootPart) {
    part.setConnected(false);
    const { parentNode, startNode, endNode } = part;

    let node = startNode;
    while (exists(node) && node !== endNode) {
        parentNode.removeChild(node);
        node = node.nextSibling;
    }

    if (endNode) {
        parentNode.removeChild(endNode);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (parentNode as any)['_$litPart$'];
}

export function getElementForPart<Elem extends Element = Element>(part: RootPart) {
    // the start node should be a Lit comment, so get the next one
    const nextSibling = (part.startNode as Comment).nextElementSibling;
    if (!nextSibling) {
        throw new Error('Could not get element for RootPart');
    }

    return nextSibling as Elem;
}
