/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CountryModelDto {
  /** @format int32 */
  id: number;
  countryName?: string;
  countryCode?: string;
  currencyName?: string;
  currencyCode?: string;
  intradaySpot?: boolean;
}
export interface GetInstitutionAboutResponseDto {
  name?: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  emailAddress?: string;
  errorMessage?: string;
}
export interface EnableMobileUiResponseDto {
  isMobileUiEnabled: boolean;
}
export interface EnableMobileUiRequestDto {
  fiId?: string;
}
export interface AchFiltersSearchResponseModelDto {
  achFilters?: AchFilterModelCollectionDto;
  paging?: PagingResultModelDto;
}
export type AchFilterModelCollectionDto = AchFilterModelDto[];
export interface AchFilterModelDto {
  accountNickname?: string;
  accountNumber?: string;
  /** @format guid */
  accountUniqueId: string;
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format guid */
  achFilterUniqueId: string;
  allowType?: string;
  accountAllowsAllCredits: boolean;
  accountAllowsAllDebits: boolean;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  expirationDate?: string;
  filterRuleName?: string;
  /** @format decimal */
  fromAmount?: number;
  remarks?: string;
  routingNumber?: string;
  secCode?: string;
  status?: string;
  /** @format decimal */
  toAmount?: number;
  transactionType?: string;
}
export interface PagingResultModelDto {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
}
export interface ErrorResponseModelDto {
  responseDetails?: ResponseDetailModelCollectionDto;
  time?: string;
}
export type ResponseDetailModelCollectionDto = ResponseDetailModelDto[];
export interface ResponseDetailModelDto {
  /** @format int32 */
  responseCode: number;
  responseMessage?: string;
}
export interface AchFilterReportDownloadRequestApiModelDto {
  accountUniqueIds?: GuidKeyCollectionDto;
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amountStart?: number;
  downloadType: ReportDownloadTypeDto;
  /** @format date-time */
  endDate?: string;
  pagingParameters?: PagingParametersDto;
  standardEntryClassCodes?: TextKeyCollectionDto;
  /** @format date-time */
  startDate?: string;
  statuses?: TextKeyCollectionDto;
  transactionTypes?: TextKeyCollectionDto;
  userUniqueIds?: GuidKeyCollectionDto;
}
export type GuidKeyCollectionDto = string[];
export enum ReportDownloadTypeDto {
  Undefined = 0,
  Csv = 1,
  Pdf = 2,
}
export interface PagingParametersDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  pageNumber: number;
  orderBys?: OrderByCollectionDto;
  searchBys?: SearchByCollectionDto;
}
export type OrderByCollectionDto = OrderByDto[];
export interface OrderByDto {
  name?: string;
  descending: boolean;
}
export type SearchByCollectionDto = SearchByDto[];
export interface SearchByDto {
  name?: string;
  value?: string;
  secondValue?: string;
  searchType: SearchTypeDto;
  values?: TextKeyCollectionDto;
}
export enum SearchTypeDto {
  None = 0,
  Contains = 1,
  StartsWith = 2,
  EndsWidth = 3,
  Equals = 4,
  NotEquals = 5,
  GreaterThan = 6,
  GreaterThanOrEqual = 7,
  LessThan = 8,
  LessThanOrEqual = 9,
  Between = 10,
}
export type TextKeyCollectionDto = string[];
export interface NavigationDto {
  toState?: NavigationStateDto;
  fromState?: NavigationStateDto;
}
export interface NavigationStateDto {
  name?: string;
  url?: string;
}
export type UserInformationModelDto = RequestResponseDto & {
  /** @format int32 */
  userId: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  loginId: string;
  /** @minLength 1 */
  department: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phone: string;
  /** @minLength 1 */
  extension: string;
  mobile?: string;
  mobileExtension?: string;
  phoneCarrier?: PhoneCarrierModelDto;
  /** @minLength 1 */
  fax: string;
  accountNicknames?: boolean;
  accountMasking?: boolean;
  verificationMethod?: string;
  isMfaBlocked: boolean;
  isSuperUser: boolean;
  isAccountMaskingConfigurable: boolean;
  isMethodOfVerificationConfigurable: boolean;
  isAdmin: boolean;
  /** @format int32 */
  copyUserId: number;
  isEnrolled: boolean;
  isActive: boolean;
  productPermissions?: ProductPermissionModelDto[];
  securityMessage?: SecurityMessageModelDto;
};
export type PhoneCarrierModelDto = ModelBaseDto & {
  /** @format int32 */
  phoneCarrierId: number;
  phoneCarrierName?: string;
  active: boolean;
};
export interface ModelBaseDto {
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type ProductPermissionModelDto = ModelBaseDto & {
  /** @minLength 1 */
  permission: string;
};
export interface SecurityMessageModelDto {
  /** @minLength 1 */
  actionType: string;
  status?: string;
  methodUsed?: string;
  hasAlternate: boolean;
  errorCode?: string;
  message?: string;
  sendMessage?: string;
  /** @format int32 */
  challengeMethodTypeId: number;
  oneTimePassword?: string;
}
export interface RequestResponseDto {
  success: boolean;
  errorMessage?: string;
}
/**
 * The http error context for GlobalExceptionHandler.
 *
 */
export interface ErrorHttpContentDto {
  /**
   * The exception message.
   *
   */
  message?: string;
  /**
   * The exception code.
   *
   * @format int32
   */
  code: number;
  /**
   * The exception time.
   *
   */
  time?: string;
}
export interface CredentialDto {
  statusCode?: string;
  message?: string;
  data?: SecurityQuestionDto[];
  /** @format int32 */
  numberOfSecurityQuestions: number;
  /** @format int32 */
  daysUntilPasswordExpires: number;
  biometricsInfo?: UserDeviceResponseModelDto;
}
export interface SecurityQuestionDto {
  answer?: string;
  id?: string;
  text?: string;
}
export type RecordedSecurityQuestionDto = SecurityQuestionDto & {
  answerOnFile?: string;
};
export interface UserDeviceResponseModelDto {
  authenticationId?: string;
  key?: string;
}
export interface UserModelDto {
  /** @format int32 */
  id: number;
  institution?: string;
  companyID?: string;
  loginID?: string;
  code?: string;
  password?: string;
  email?: string;
  name?: string;
  useClientBiometricsAuthentication: boolean;
  biometricsInfo?: UserDeviceRequestModelDto;
}
export interface UserDeviceRequestModelDto {
  authenticationId?: string;
  hash?: string;
}
export interface LogOffModelDto {
  reason?: string;
}
/** Represents an action result that returns an empty HttpStatusCode.OK response. */
export interface OkResultDto {
  /** Gets a HTTP request message for the results. */
  request?: HttpRequestMessageDto;
}
export interface HttpRequestMessageDto {
  version?: VersionDto;
  content?: HttpContentDto;
  method?: HttpMethodDto;
  /** @format uri */
  requestUri?: string;
  headers?: HttpRequestHeadersDto;
  properties?: Record<string, any>;
}
export interface VersionDto {
  /** @format int32 */
  major: number;
  /** @format int32 */
  minor: number;
  /** @format int32 */
  build: number;
  /** @format int32 */
  revision: number;
  majorRevision: number;
  minorRevision: number;
}
export interface HttpContentDto {
  headers?: HttpContentHeadersDto;
}
export type HttpContentHeadersDto = KeyValuePairOfStringAndIEnumerableOfStringDto[] & {
  Allow?: string[];
  ContentDisposition?: ContentDispositionHeaderValueDto;
  ContentEncoding?: string[];
  ContentLanguage?: string[];
  /** @format int64 */
  ContentLength?: number;
  /** @format uri */
  ContentLocation?: string;
  /** @format byte */
  ContentMD5?: string;
  ContentRange?: ContentRangeHeaderValueDto;
  ContentType?: MediaTypeHeaderValueDto;
  /** @format date-time */
  Expires?: string;
  /** @format date-time */
  LastModified?: string;
};
export interface ContentDispositionHeaderValueDto {
  dispositionType?: string;
  parameters?: NameValueHeaderValueDto[];
  name?: string;
  fileName?: string;
  fileNameStar?: string;
  /** @format date-time */
  creationDate?: string;
  /** @format date-time */
  modificationDate?: string;
  /** @format date-time */
  readDate?: string;
  /** @format int64 */
  size?: number;
}
export interface NameValueHeaderValueDto {
  name?: string;
  value?: string;
}
export interface ContentRangeHeaderValueDto {
  unit?: string;
  /** @format int64 */
  from?: number;
  /** @format int64 */
  to?: number;
  /** @format int64 */
  length?: number;
  hasLength: boolean;
  hasRange: boolean;
}
export interface MediaTypeHeaderValueDto {
  charSet?: string;
  parameters?: NameValueHeaderValueDto[];
  mediaType?: string;
}
export interface KeyValuePairOfStringAndIEnumerableOfStringDto {
  key?: string;
  value?: string[];
}
export interface HttpMethodDto {
  method?: string;
}
export type HttpRequestHeadersDto = KeyValuePairOfStringAndIEnumerableOfStringDto[] & {
  Accept?: MediaTypeWithQualityHeaderValueDto[];
  AcceptCharset?: StringWithQualityHeaderValueDto[];
  AcceptEncoding?: StringWithQualityHeaderValueDto[];
  AcceptLanguage?: StringWithQualityHeaderValueDto[];
  Authorization?: AuthenticationHeaderValueDto;
  Expect?: NameValueWithParametersHeaderValueDto[];
  ExpectContinue?: boolean;
  From?: string;
  Host?: string;
  IfMatch?: EntityTagHeaderValueDto[];
  /** @format date-time */
  IfModifiedSince?: string;
  IfNoneMatch?: EntityTagHeaderValueDto[];
  IfRange?: RangeConditionHeaderValueDto;
  /** @format date-time */
  IfUnmodifiedSince?: string;
  /** @format int32 */
  MaxForwards?: number;
  ProxyAuthorization?: AuthenticationHeaderValueDto;
  Range?: RangeHeaderValueDto;
  /** @format uri */
  Referrer?: string;
  TE?: TransferCodingWithQualityHeaderValueDto[];
  UserAgent?: ProductInfoHeaderValueDto[];
  CacheControl?: CacheControlHeaderValueDto;
  Connection?: string[];
  ConnectionClose?: boolean;
  /** @format date-time */
  Date?: string;
  Pragma?: NameValueHeaderValueDto[];
  Trailer?: string[];
  TransferEncoding?: TransferCodingHeaderValueDto[];
  TransferEncodingChunked?: boolean;
  Upgrade?: ProductHeaderValueDto[];
  Via?: ViaHeaderValueDto[];
  Warning?: WarningHeaderValueDto[];
};
export type MediaTypeWithQualityHeaderValueDto = MediaTypeHeaderValueDto & {
  /** @format double */
  quality?: number;
};
export interface StringWithQualityHeaderValueDto {
  value?: string;
  /** @format double */
  quality?: number;
}
export interface AuthenticationHeaderValueDto {
  scheme?: string;
  parameter?: string;
}
export type NameValueWithParametersHeaderValueDto = NameValueHeaderValueDto & {
  parameters?: NameValueHeaderValueDto[];
};
export interface EntityTagHeaderValueDto {
  tag?: string;
  isWeak: boolean;
}
export interface RangeConditionHeaderValueDto {
  /** @format date-time */
  date?: string;
  entityTag?: EntityTagHeaderValueDto;
}
export interface RangeHeaderValueDto {
  unit?: string;
  ranges?: RangeItemHeaderValueDto[];
}
export interface RangeItemHeaderValueDto {
  /** @format int64 */
  from?: number;
  /** @format int64 */
  to?: number;
}
export type TransferCodingWithQualityHeaderValueDto = TransferCodingHeaderValueDto & {
  /** @format double */
  quality?: number;
};
export interface TransferCodingHeaderValueDto {
  value?: string;
  parameters?: NameValueHeaderValueDto[];
}
export interface ProductInfoHeaderValueDto {
  product?: ProductHeaderValueDto;
  comment?: string;
}
export interface ProductHeaderValueDto {
  name?: string;
  version?: string;
}
export interface CacheControlHeaderValueDto {
  noCache: boolean;
  noCacheHeaders?: string[];
  noStore: boolean;
  /** @format duration */
  maxAge?: string;
  /** @format duration */
  sharedMaxAge?: string;
  maxStale: boolean;
  /** @format duration */
  maxStaleLimit?: string;
  /** @format duration */
  minFresh?: string;
  noTransform: boolean;
  onlyIfCached: boolean;
  public: boolean;
  private: boolean;
  privateHeaders?: string[];
  mustRevalidate: boolean;
  proxyRevalidate: boolean;
  extensions?: NameValueHeaderValueDto[];
}
export interface ViaHeaderValueDto {
  protocolName?: string;
  protocolVersion?: string;
  receivedBy?: string;
  comment?: string;
}
export interface WarningHeaderValueDto {
  /** @format int32 */
  code: number;
  agent?: string;
  text?: string;
  /** @format date-time */
  date?: string;
}
export type SecurityQuestionModelDto = ModelBaseDto & {
  answer?: string;
  id?: string;
  text?: string;
};
export interface UserPasswordModelDto {
  /** @minLength 1 */
  currentPassword: string;
  /** @minLength 1 */
  newPassword: string;
  /** @minLength 1 */
  confirmPassword: string;
}
export interface ChangePasswordViewModelDto {
  statusCode?: string;
  message?: string[];
}
export interface LoginCompletionStatusModelDto {
  isComplete: boolean;
  /** @format int32 */
  challengeMethodType: number;
  action?: string;
}
export interface UserSettingsModelDto {
  /** @format int32 */
  idleTimeoutDuration?: number;
  areNotificationsEnabled: boolean;
  accountDisplayField?: string;
  /** @format int32 */
  unreadMessageCount: number;
}
export interface CheckImageModelDto {
  checkId?: string;
  /** @format byte */
  frontImage?: string;
  /** @format byte */
  backImage?: string;
}
export type CompanyAccountModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  number: string;
  /** @minLength 1 */
  status: string;
  account?: AccountModelDto;
  /** @format decimal */
  currentBalance?: number;
  /** @format decimal */
  collectedBalance?: number;
  /** @format decimal */
  availableBalance?: number;
  type?: string;
  /** @format decimal */
  perTransactionLimit?: number;
  /** @format decimal */
  dailyLimit?: number;
  cifNumber?: string;
  isDeleted: boolean;
  showAccountNickName: boolean;
  accountDisplayLabel?: string;
  /** @format decimal */
  transferAmount: number;
  transferMemo?: string;
  /** @format decimal */
  loanPaymentAmountDue?: number;
  /** @format date-time */
  loanPaymentDueDate?: string;
  allowLoanPaymentPastDue: boolean;
  /** @format guid */
  accountUniqueId: string;
};
export interface AccountModelDto {
  /** @format int32 */
  id: number;
  number?: string;
  type?: string;
  coreStatus?: string;
  accountDisplayLabel?: string;
  name?: string;
  /** @format guid */
  accountUniqueId: string;
}
export interface CompanyCutoffTimesDto {
  processingCutoffs?: ProcessingCutoffDto[];
  /** @format date-time */
  currentFiTime: string;
  timeZone?: string;
}
export interface ProcessingCutoffDto {
  /** @format duration */
  startTime?: string;
  /** @format duration */
  cutoffTime: string;
  productType?: string;
  allowSameDay?: boolean;
  /** @format date-time */
  cutOffDateTime?: string;
}
export interface CompanyProductTypeCutoffTimeDto {
  processingCutoff?: ProcessingCutoffDto;
  /** @format date-time */
  currentFiTime: string;
  fiTimeZone?: string;
  /** @format date-time */
  closestBusinessDate: string;
}
export interface AccountSummaryModelDto {
  /** @format int32 */
  id?: number;
  number?: string;
  type?: string;
  name?: string;
  status?: string;
  isNew: boolean;
  /** @format decimal */
  availableBalance?: number;
  /** @format decimal */
  currentBalance: number;
  /** @format decimal */
  collectedBalance?: number;
  category?: string;
  transactions?: AccountTransactionDetailModelDto[];
  balanceHistory?: AccountBalanceDetailDto[];
  permissions?: ProductPermissionModelDto[];
  showAccountNickName: boolean;
  accountDisplayLabel?: string;
}
export interface AccountTransactionDetailModelDto {
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @minLength 1
   */
  date: string;
  /** @minLength 1 */
  checkImageNumber: string;
  /** @minLength 1 */
  description: string;
  /** @format decimal */
  debit?: number;
  /** @format decimal */
  credit?: number;
  /** @format decimal */
  balance?: number;
  checkNumber?: string;
  isDebit: boolean;
  checkNumberSortValue?: string;
  /** @format int64 */
  postingSequence: number;
  tranCodeType?: string;
  tranCodeDescription?: string;
  /** @format decimal */
  runningBalance?: number;
}
export interface AccountBalanceDetailDto {
  /** @format date-time */
  date: string;
  /** @format decimal */
  availableBalance?: number;
  /** @format decimal */
  currentBalance?: number;
}
export interface ToAccountPermissionModelDto {
  /** @format int32 */
  fromAccountId: number;
  toAccount?: CompanyAccountModelDto;
  isAllowed: boolean;
}
export interface KeyValuePairOfIntegerAndICollectionOfToAccountPermissionModelDto {
  /** @format int32 */
  key: number;
  value?: ToAccountPermissionModelDto[];
}
export interface DownloadBaseDto {
  pageId?: string;
  outputType: OutPutTypeDto;
  includeHeader: boolean;
  /** @format int32 */
  confirmationId?: number;
  filterModel?: any;
  downloadPdfTypeSelected?: string;
  isPortrait: boolean;
}
export enum OutPutTypeDto {
  Entity = 0,
  Pdf = 1,
  Csv = 2,
  Tiff = 3,
  Bai = 4,
  Quicken = 5,
  QuickBook = 6,
  Nacha = 7,
  PdfInDetail = 8,
  CsvInDetail = 9,
  Ofx = 10,
  Qbo = 11,
  Qfx = 12,
  BaiPlusBal = 13,
  Text = 14,
}
export interface QboSettingsModelDto {
  intuitBankId?: string;
}
export interface QfxSettingsModelDto {
  enabledFlag: boolean;
}
export interface ElectronicStatementsDownloadModelDto {
  productName?: string;
  reportId?: string;
  reportName?: string;
}
export interface ReportInformationModelDto {
  name?: string;
  id?: string;
  data?: string;
}
export type HolidayCalendarModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  /**
   * @format date-time
   * @minLength 1
   */
  date: string;
  isPastDue: boolean;
};
export interface MessageCategoryModelDto {
  /** @format int32 */
  fiMessageCategoryId: number;
  messageCategory?: string;
  categoryDescription?: string;
}
export interface MessageModelDto {
  /** @format int32 */
  id: number;
  messageId?: string;
  companyName?: string;
  /** @format int32 */
  companyId?: number;
  fromToUserName?: string;
  /** @format int32 */
  fromUserId?: number;
  /** @format int32 */
  toUserId?: number;
  /** @format int32 */
  categoryId: number;
  subject?: string;
  body?: string;
  status?: string;
  assignedTo?: string;
  sentDate?: string;
  sentTime?: string;
  /** @format date-time */
  sentDateTime: string;
  sentMessage: boolean;
  hasAttachment: boolean;
  attachmentNames?: string[];
  attachments?: MessageAttachmentModelDto[];
  parentMessage?: MessageModelDto;
  isArchived: boolean;
  /** @format int32 */
  parentMessageId?: number;
  /** @format int32 */
  assignedToUserId?: number;
  isReply: boolean;
  isSent: boolean;
  messageIdSortValue?: string;
}
export interface MessageAttachmentModelDto {
  /** @format int32 */
  id: number;
  messageAttachmentId?: string;
  name?: string;
  /** @format byte */
  data?: string;
  /** @format guid */
  fileIdentifier?: string;
  /** @format int32 */
  typeId: number;
}
export interface MessageCenterSearchTermsDto {
  /** @format int32 */
  companyId?: number;
  /** @format int32 */
  statusId?: number;
  /** @format int32 */
  fromUserId?: number;
  /** @format int32 */
  assignedToUserId?: number;
  messageId?: string;
  dateFrom?: string;
  dateTo?: string;
  /** @format int32 */
  messageTypeId?: number;
  /** @format int32 */
  categoryId?: number;
}
export interface UpdateMessageModelDto {
  /** @format int32 */
  messageId: number;
  /** @format int32 */
  actionTypeId: number;
  /** @format int32 */
  statusTypeId: number;
  /** @format int32 */
  assignedUserId: number;
  updateConversation: boolean;
}
export interface NavigationModelDto {
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  name: string;
  description?: string;
  path?: string;
  subMenus?: NavigationModelDto[];
}
export type NotificationConfigurationModelDto = SecurityBaseDto & {
  /** @minLength 1 */
  email: string;
  isDuplicateEmail: boolean;
  phoneNumber?: string;
  isFirstTimeLoad: boolean;
  groups?: NotificationConfigurationGroupModelDto[];
};
export interface NotificationConfigurationGroupModelDto {
  /** @minLength 1 */
  name: string;
  features?: NotificationConfigurationFeatureModelDto[];
}
export type NotificationConfigurationFeatureModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  isDesktopRequired: boolean;
  isActive: boolean;
  isChanged: boolean;
  isEmailAllowed: boolean;
  isEmailActive: boolean;
  isEmailRequired: boolean;
  isDesktopAllowed: boolean;
  isDesktopActive: boolean;
  isTextMessageActive: boolean;
  isTextMessageAllowed: boolean;
  isTextMessageRequired: boolean;
  /** @format int32 */
  notificationTypeId: number;
  /** @format int32 */
  sequence: number;
  /** @format int32 */
  sortOrder: number;
  threshold?: ThresholdDto;
};
export interface ThresholdDto {
  /** @format guid */
  id: string;
  /** @format decimal */
  underAmount: number;
  /** @format decimal */
  overAmount: number;
  isDeleted: boolean;
  accountIds?: GuidKeyCollectionDto;
}
export type SecurityBaseDto = ModelBaseDto & {
  securityMessage?: SecurityMessageModelDto;
};
export type LookupModelDto = SecurityBaseDto & {
  value?: string;
  key?: string;
};
export type NotificationModelDto = ModelBaseDto & {
  isImportant: boolean;
  /** @minLength 1 */
  subject: string;
  functionality?: string;
  detail?: string;
  isRead: boolean;
  /**
   * @format date-time
   * @minLength 1
   */
  createdOn: string;
  showAlert: boolean;
};
export interface NotificationListModelDto {
  /** @format int32 */
  totalUnreadCount: number;
  topMessages?: NotificationModelDto[];
  webServer?: string;
}
export interface PrintAuditModelDto {
  printId?: string;
  additionalAuditInformation?: string;
}
export type AddItemResponseModelDto = AuthenticatedResponseModelDto & {
  item?: ItemModelDto;
};
export interface ItemModelDto {
  /** @format decimal */
  amount: number;
  batchReference?: string;
  events?: EventModelDto[];
  iqaStatus?: string;
  itemReference?: string;
  processingStatus?: string;
  processingStatusDescription?: string;
  status?: string;
  statusDescription?: string;
  transactionReferenceNumber?: string;
}
export interface EventModelDto {
  /** @format date-time */
  date: string;
  description?: string;
}
export type AuthenticatedResponseModelDto = ResultModelDto & {
  securityToken?: string;
};
export interface ResultModelDto {
  resultCode?: string;
  resultMessage?: string;
  /** @format int32 */
  result: number;
  validationResults?: ValidationResultModelDto[];
}
export interface ValidationResultModelDto {
  code?: string;
  message?: string;
}
export type AddItemRequestModelDto = AuthenticatedRequestModelDto & {
  /** @format decimal */
  amount: number;
  batchReference?: string;
  customerReference?: string;
  dataFields?: DataFieldModelDto[];
  frontImage?: string;
  geoLocation?: GeoLocationModelDto;
  locationReference?: string;
  rearImage?: string;
};
export interface DataFieldModelDto {
  fieldName?: string;
  /** @format int32 */
  fieldType: number;
  isRequired: boolean;
  text?: string;
  validation?: string;
  validationMessage?: string;
  value?: string;
}
export interface GeoLocationModelDto {
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
}
export interface AuthenticatedRequestModelDto {
  securityToken?: string;
}
export interface AuthenticateRequestModelDto {
  deviceTracking?: DeviceTrackingModelDto;
}
export interface DeviceTrackingModelDto {
  appBundleId?: string;
  appVersion?: string;
  deviceModel?: string;
  deviceSystemName?: string;
  deviceSystemVersion?: string;
}
export type UpdateBatchResponseModelDto = AuthenticatedResponseModelDto & {
  batch?: BatchModelDto;
};
export interface BatchModelDto {
  batchNumber?: string;
  batchReference?: string;
  events?: EventModelDto[];
  locationName?: string;
  status?: string;
  statusDescription?: string;
  /** @format decimal */
  totalAmount: number;
  /** @format int32 */
  totalCount: number;
}
export type UpdateBatchRequestModelDto = AuthenticatedRequestModelDto & {
  batchReference?: string;
};
export type CreateBatchResponseModelDto = AuthenticatedResponseModelDto & {
  batch?: BatchModelDto;
};
export type CreateBatchRequestModelDto = AuthenticatedRequestModelDto & {
  batchNumber?: string;
  locationReference?: string;
};
export type UpdateItemRequestModelDto = AuthenticatedRequestModelDto & {
  itemReference?: string;
};
export type GetBatchesResponseModelDto = AuthenticatedResponseModelDto & {
  batches?: BatchModelDto[];
};
export type GetCustomersResponseModelDto = AuthenticatedResponseModelDto & {
  customers?: CustomerModelDto[];
};
export interface CustomerModelDto {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  customerReference?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  postalCode?: string;
  state?: string;
}
export type GetCustomersRequestModelDto = AuthenticatedRequestModelDto & {
  accountNumber?: string;
  customerName?: string;
  customerNumber?: string;
};
export type GetDataFieldsResponseModelDto = AuthenticatedResponseModelDto & {
  dataFields?: DataFieldModelDto[];
};
export type GetDataFieldsRequestModelDto = AuthenticatedRequestModelDto & {
  locationReference?: string;
};
export type GetItemImagesResponseModelDto = AuthenticatedResponseModelDto & {
  images?: ItemImageModelDto;
};
export interface ItemImageModelDto {
  frontImage?: string;
  backImage?: string;
  itemReference?: string;
}
export type GetItemImagesRequestModelDto = AuthenticatedRequestModelDto & {
  itemReference?: string;
};
export type GetItemsResponseModelDto = AuthenticatedResponseModelDto & {
  items?: ItemModelDto[];
};
export type GetItemsRequestModelDto = AuthenticatedRequestModelDto & {
  batchReference?: string;
};
export type GetLocationsResponseModelDto = AuthenticatedResponseModelDto & {
  locations?: LocationModelDto[];
};
export interface LocationModelDto {
  isEnabled: boolean;
  locationNumber?: string;
  name?: string;
  locationReference?: string;
}
export type GetSettingsResponseModelDto = AuthenticatedResponseModelDto & {
  allowSaveUsername: boolean;
  enablePasswordChange: boolean;
  /** @format int32 */
  geoLocationAccess: number;
  showCustomBatchId: boolean;
  showDataEntry: boolean;
};
export interface StatusModelDto {
  isUserConfigured: boolean;
  /** @format int32 */
  remoteDepositProductType: number;
}
export interface SecurityVerificationMethodModelDto {
  /** @minLength 1 */
  method: string;
  /** @minLength 1 */
  phoneNumber: string;
  /** @minLength 1 */
  pinNumber: string;
}
export interface UserSecurityConfigModelDto {
  textVerificationMethod?: SecurityVerificationMethodModelDto;
  callVerificationMethod?: SecurityVerificationMethodModelDto;
  preferredMethod?: string;
  usePin: boolean;
  allowActionBypass: boolean;
}
export interface RecentLoginsModelDto {
  lastSuccessfulLogin?: string;
  lastSuccessfulLoginIPAddress?: string;
  lastFailedLogin?: string;
  lastFailedLoginIPAddress?: string;
}
export type CompleteSecureTokenConfigResponseDto = BaseResponseDto & {
  isUserEntryError: boolean;
};
export interface BaseResponseDto {
  baseRequest?: BaseRequestDto;
  responseDetailCollection?: ResponseDetailCollectionDto;
  success: boolean;
}
export interface BaseRequestDto {
  activityTracking?: ActivityTrackingDto;
  alias?: string;
  applicationNameType: ApplicationNameTypeDto;
  authenticationUser?: AuthenticationUserDto;
  authorizationInfo?: AuthorizationInfoDto;
  clientIpAddress?: string;
  endUser?: EndUserDto;
  institutionId?: string;
  productInformation?: ProductInformationDto;
  requestType?: string;
  secondaryId?: string;
}
export interface ActivityTrackingDto {
  activityId?: string;
  parentActivityId?: string;
}
export enum ApplicationNameTypeDto {
  Undefined = 0,
  BackOffice = 1,
  Banno = 2,
  ECS = 3,
  EPS = 4,
  Esi = 5,
  GoDough = 6,
  GoDoughClassic = 7,
  GoDough3X = 8,
  IPay = 9,
  NetTeller = 10,
  PointMobility = 11,
  Treasury = 12,
  BSLKeys = 13,
  BSL = 14,
  ESIFIM = 15,
  EStatements = 16,
  Geezeo = 17,
  BannoPeople = 18,
  CoreDirector = 19,
  Newgen = 20,
  Corserv = 21,
  BOKF = 22,
  Autobooks = 23,
  MobileWeb = 24,
  HCL = 25,
  JxChangeApp = 26,
  TreasuryMobile = 27,
  TreasuryBackOffice = 28,
  BslSubSystem = 29,
  AccountRecon = 30,
  Ach = 31,
  AlertNetCore = 32,
  BankAccount = 33,
  Company = 34,
  Event = 35,
  JxChangeProxy = 36,
  PositivePay = 37,
  Sso = 38,
  User = 39,
  Wire = 40,
  Edpp = 41,
  FxGateway = 42,
  InstitutionNetCore = 43,
  AuthorizationNetCore = 44,
  File = 45,
  Configuration = 46,
  AchReturn = 47,
  ApiCatalog = 48,
  BslSubsystemAdmin = 49,
  EsiMailProcessor = 50,
  Nacha = 51,
  Pdf = 52,
  AchNotificationOfChange = 53,
  RateLimiting = 54,
  AchCompany = 55,
  TreasuryScheduler = 56,
  Report = 57,
  AccountReconIssuedItems = 58,
  PositivePayIssuedItems = 59,
  InternalTransfer = 60,
  CheckExceptions = 61,
  AlertUpdate = 62,
  TreasuryAccount = 63,
  TreasuryAuthentication = 64,
  TreasuryOdiEesListener = 65,
  TreasuryPayment = 66,
  TreasuryReporting = 67,
  TreasuryAch = 68,
  TreasuryWire = 69,
  TreasuryBackOfficeApi = 70,
  TreasuryChannelApi = 71,
  TreasuryNotification = 72,
  NachaIngest = 73,
  AchReturnProcessor = 74,
  AchNocProcessor = 75,
  NachaProcessor = 76,
  NachaSerialize = 77,
  AchPayment = 78,
  TextMessagingNetCore = 79,
  EmailNetCore = 80,
  LoggingNetCore = 81,
  MessageCenterNetCore = 82,
  AuthenticationRules = 83,
  Credentials = 84,
  HttpProxy = 85,
  DocumentNetCore = 86,
  AuthenticationNetCore = 87,
  ChallengeManagementNetCore = 88,
}
export type AuthenticationUserDto = EndUserDto & object;
export interface EndUserDto {
  alias?: string;
  browser?: string;
  companyId?: string;
  device?: string;
  externalId?: string;
  externalSecondaryId?: string;
  internalId?: string;
  internalSecondaryId?: string;
  userType: UserTypeDto;
}
export enum UserTypeDto {
  Undefined = 0,
  Banno = 1,
  CashManagement = 2,
  Esi = 3,
  NetTeller = 4,
  Sso = 5,
  Treasury = 6,
  ESIFIM = 7,
  BackOffice = 8,
  Geezeo = 9,
  GeezeoMobile = 10,
  BannoCashManagement = 11,
  GeezeoCashManagement = 12,
  GeezeoMobileCashManagement = 13,
  Newgen = 14,
  Server = 15,
  Jha = 16,
  BannoPeople = 17,
}
export interface AuthorizationInfoDto {
  associatedClaims?: AuthorizeAssociatedClaimCollectionDto;
  entitlements?: AuthorizeEntitlementCollectionDto;
  isSuperUser: boolean;
  roles?: AuthorizeRoleCollectionDto;
}
export type AuthorizeAssociatedClaimCollectionDto = AuthorizeAssociatedClaimDto[];
export interface AuthorizeAssociatedClaimDto {
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  claimId: number;
  /** @format int32 */
  roleId: number;
}
export type AuthorizeEntitlementCollectionDto = AuthorizeEntitlementDto[];
export interface AuthorizeEntitlementDto {
  /** @format int32 */
  id: number;
  name?: string;
}
export type AuthorizeRoleCollectionDto = AuthorizeRoleDto[];
export interface AuthorizeRoleDto {
  /** @format int32 */
  id: number;
  name?: string;
}
export interface ProductInformationDto {
  featureName?: string;
  productName?: string;
  version?: string;
}
export type UserChallengeRequestDto = BaseRequestDto & {
  challengePointId: ChallengePointEnumDto;
  /** @format int32 */
  companyId: number;
  isNoThanks: boolean;
  isReminedMeLater?: boolean;
  oobValidationMode?: string;
  /** @format int32 */
  userId: number;
  userOobDetail?: UserOobDetailDto;
  validationPinRequired: boolean;
};
export enum ChallengePointEnumDto {
  CreateBatch = 1,
  EditBatch = 2,
  DeleteBatch = 3,
  ApproveBatch = 4,
  CreateOneTimeAchPayment = 5,
  CreateRecurringAchPayment = 6,
  EditAchPayment = 7,
  DeleteAchPayment = 8,
  ApproveAchPayment = 9,
  CreateBeneficiary = 11,
  EditBeneficiary = 12,
  DeleteBeneficiary = 13,
  ApproveBeneficiary = 14,
  CreateOneTimeWirePayment = 15,
  EditWirePayment = 17,
  DeleteWirePayment = 18,
  ApproveWirePayment = 19,
  CreateTransfer = 20,
  CreateRecurringTransfer = 21,
  CreateOneToManyTransfer = 22,
  CreateManyToOneTransfer = 23,
  EditTransfer = 24,
  DeleteTransfer = 25,
  ApproveTransfer = 26,
  CreatePayee = 27,
  EditPayee = 28,
  DeletePayee = 29,
  CreateOneTimePayment = 30,
  CreateRecurringPayment = 31,
  EditPayment = 32,
  DeletePayment = 33,
  CreatePayroll = 34,
  EditPayroll = 35,
  DeletePayroll = 36,
  CreateUser = 37,
  EditUser = 38,
  DeleteUser = 39,
  ApproveUser = 40,
  UpdateEmailAddress = 41,
  CreateFxBeneficiary = 42,
  EditFxBeneficiary = 43,
  DeleteFxBeneficiary = 44,
  ApproveFxBeneficiary = 45,
  CreateFxWire = 46,
  ApproveFxWire = 47,
}
export interface UserOobDetailDto {
  audioPhoneNumber?: string;
  challengePin?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isLocked: boolean;
  isOobPinCollected: boolean;
  /** @format date-time */
  localSuspendEndDate?: string;
  needCollection: boolean;
  oobAuthCount?: number;
  oobValidationMode?: OobValidationModeDto;
  /** @format int32 */
  preferredOobValidationModeId: number;
  remindMeCount?: number;
  smsPhoneNumber?: string;
  /** @format date-time */
  suspendEndDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
}
export interface OobValidationModeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type UserDto = BaseEntityDto & {
  accountLookups?: LookupDto[];
  accountProducts?: AccountProductDto[];
  achOffsetAccounts?: AchOffsetAccountDto[];
  alias?: string;
  associatedClaims?: AssociatedClaimDto[];
  backOfficeSsoEnabled: boolean;
  beneficiaryAudits?: BeneficiaryAuditDto[];
  boReports?: BoReportDto[];
  company?: CompanyDto;
  /** @format int32 */
  companyId?: number;
  contactInfo?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  department?: string;
  email?: string;
  enrolled: boolean;
  favoriteReports?: FavoriteReportDto[];
  fax?: string;
  hasLoggedIn: boolean;
  /** @format int32 */
  id: number;
  importBatchId?: string;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  isAdmin: boolean;
  isEditor: boolean;
  isSuperUser: boolean;
  locked: boolean;
  /** @format int32 */
  loginFailureCount: number;
  mfaLocked: boolean;
  name?: string;
  notificationEmail?: string;
  notificationPhoneCarrier?: TextMessagingCarrierDto;
  /** @format int32 */
  notificationPhoneCarrierId?: number;
  notificationPhoneNumber?: string;
  notificationRecipientId?: string;
  password?: string;
  /** @format date-time */
  passwordDate: string;
  pendingEditsOfUser?: PendingEditOfUserDto[];
  pendingState?: PendingEditStatusDto;
  phoneExtension?: string;
  phoneNumber?: string;
  publishState?: string;
  reportingFilterSorts?: ReportingFilterSortDto[];
  reportingFilters?: ReportingFilterDto[];
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  sessions?: SessionDto[];
  showAccountNickName?: boolean;
  showMaskedAccount?: boolean;
  tempPasswordToken?: string;
  /** @format date-time */
  tempPasswordTokenExpirationDate?: string;
  /** @format date-time */
  termsAcceptedDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccountGroups?: UserAccountGroupDto[];
  userAccounts?: UserAccountDto[];
  userAchCompanies?: UserAchCompanyDto[];
  userAudits?: UserAuditDto[];
  userDevices?: UserDeviceDto[];
  userIpAccessSetting?: UserIpAccessSettingDto;
  userIpAccesses?: UserIpAccessDto[];
  userNotificationTypes?: UserNotificationTypeDto[];
  userNotifications?: UserNotificationDto[];
  userOobDetail?: UserOobDetailDto;
  userPasswordHistories?: UserPasswordHistoryDto[];
  userProductLimits?: UserProductLimitDto[];
  userRoles?: UserRoleDto[];
  userSecureTokenSecurity?: UserSecureTokenSecurityDto;
  userSsoLogins?: UserSsoLoginDto[];
  userTimeAccessSetting?: UserTimeAccessSettingDto;
  userTimeAccesses?: UserTimeAccessDto[];
  userType?: UserType1Dto;
  /** @format int32 */
  userTypeId: number;
  /** @format guid */
  userUniqueId: string;
  userWidgets?: UserWidgetDto[];
  wireAudits?: WireAuditDto[];
};
export interface LookupDto {
  key?: string;
  value?: string;
}
export type AccountProductDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  accountProductArp?: AccountProductArpDto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  product?: ProductDto;
  productAccountPermission?: ProductAccountPermissionDto[];
  /** @format int32 */
  productId: number;
};
export type AccountDto = BaseEntityDto & {
  accountNumberTypeFormat?: string;
  accountProducts?: AccountProductDto[];
  /** @format guid */
  accountUniqueId: string;
  achOffsetAccounts?: AchOffsetAccountDto[];
  allowLoanPaymentPastDue: boolean;
  analysisTransactionCounts?: AnalysisTransactionCountDto[];
  arpExceptions?: ArpExceptionDto[];
  arpReportFiles?: ArpReportFileDto[];
  associatedClaims?: AssociatedClaimDto[];
  /** @format decimal */
  balance?: number;
  cifNumber?: string;
  /** @format decimal */
  collectedBalance?: number;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  coreAccount?: CoreAccountDto;
  coreStatus?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format decimal */
  currentBalance?: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format decimal */
  dailyUsedValue: number;
  description?: string;
  /** @format int32 */
  id: number;
  internalTransferTransactions?: InternalTransferTransactionDto[];
  internalTransferTransactions1?: InternalTransferTransactionDto[];
  isAccountLinked: boolean;
  isCompanyAccount: boolean;
  isSelected: boolean;
  linkedTMCompanies?: string[];
  /** @format decimal */
  loanPaymentAmountDue?: number;
  /** @format date-time */
  loanPaymentDueDate?: string;
  name?: string;
  nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccountDto[];
  number?: string;
  /** @format decimal */
  perTransactionLimit: number;
  reportingFilterAccounts?: ReportingFilterAccountDto[];
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  showAccountNickName: boolean;
  stopPayments?: StopPaymentDto[];
  /** @format decimal */
  templateTransferAmount: number;
  templateTransferMemo?: string;
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccountGroupAccounts?: UserAccountGroupAccountDto[];
  userAccounts?: UserAccountDto[];
  users?: UserDto[];
  wires?: WireDto[];
};
export interface AchOffsetAccountDto {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  achCompany?: AchCompanyDto;
  /** @format int32 */
  achCompanyId: number;
  /** @format int32 */
  id: number;
  isActive: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
}
export type AchCompanyDto = BaseEntityDto & {
  /** @format int32 */
  achBatchBalanceTypeId: number;
  achBatches?: AchBatchDto[];
  achCompanyId?: string;
  achCompanySecCodes?: AchCompanySecCodeDto[];
  achOffsetAccounts?: AchOffsetAccountDto[];
  achPayments?: AchPaymentDto[];
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  allowedNotOnUsTransactionTypeId: number;
  availableBalanceTable?: string;
  balanceValidationType?: string;
  batchBalanceType?: BatchBalanceTypeDto;
  company?: CompanyDto;
  coreSecCode?: string;
  /** @format decimal */
  dailyLimit: number;
  discretionaryData?: string;
  entryDescription?: string;
  /** @format int32 */
  id: number;
  isPaymentProcessingCompany: boolean;
  offsetAccountNumber?: string;
  paymentProcessingCompanyAllowed: boolean;
  /** @format int32 */
  prefundingDays?: number;
  /** @format decimal */
  requiredOnUsAmountPerBatch: number;
  /** @format int32 */
  requiredOnUsTransactionCount: number;
  sameDayAchAllowed: boolean;
  settlementAccount?: string;
  settlementAccountType?: string;
  transactionType?: TransactionTypeDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  userAchCompanies?: UserAchCompanyDto[];
};
export type AchBatchDto = BaseEntityDto & {
  achBatchAudits?: AchBatchAuditDto[];
  achBatchRecipients?: AchBatchRecipientDto[];
  achCompany?: AchCompanyDto;
  /** @format int32 */
  achCompanyId: number;
  /** @format int32 */
  achCompanyMatchType: number;
  achCompanyName?: string;
  achFederalTaxTemplate?: AchFederalTaxTemplateDto;
  achPaymentType?: AchPaymentTypeDto;
  /** @format int32 */
  achPaymentTypeId: number;
  achPayments?: AchPaymentDto[];
  achSecCode?: AchSecCodeDto;
  /** @format int32 */
  achSecCodeId: number;
  achStateTaxTemplate?: AchStateTaxTemplateDto;
  /** @format int32 */
  approvalsRequired: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  batchName?: string;
  batchStatusType?: BatchStatusTypeDto;
  /** @format int32 */
  batchStatusTypeId: number;
  /** @format int32 */
  batchUniqueId: number;
  companyIdentity?: string;
  /** @format int32 */
  completedApprovals: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  credit?: number;
  /** @format decimal */
  creditAmount: number;
  /** @format decimal */
  debit?: number;
  /** @format decimal */
  debitAmount: number;
  descretionaryData?: string;
  eligibleApprovers?: string[];
  entryDescription?: string;
  errorMessage?: string;
  fileArchive?: FileArchiveDto;
  /** @format int32 */
  fileArchiveId?: number;
  /** @format int32 */
  id: number;
  isEdited: boolean;
  /** @format int32 */
  numberOfRecipients: number;
  restricted: boolean;
  secCode?: string;
  status?: string;
  successMessage?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export type AchBatchAuditDto = BaseEntityDto & {
  achActionType?: AchActionTypeDto;
  achBatch?: AchBatchDto;
  /** @format int32 */
  achBatchId: number;
  /** @format int32 */
  actionTypeId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  userName?: string;
};
export type AchActionTypeDto = BaseEntityDto & {
  achBatchAudits?: AchBatchAuditDto[];
  achPaymentAudits?: AchPaymentAuditDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchPaymentAuditDto = BaseEntityDto & {
  achActionType?: AchActionTypeDto;
  /** @format int32 */
  achActionTypeId: number;
  achPayment?: AchPaymentDto;
  /** @format int32 */
  achPaymentId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  userName?: string;
};
export type AchPaymentDto = BaseEntityDto & {
  account?: AccountDto;
  achBatch?: AchBatchDto;
  /** @format int32 */
  achBatchId?: number;
  achCompany?: AchCompanyDto;
  /** @format int32 */
  achCompanyId: number;
  achCompanyName?: string;
  achFederalTaxPayment?: AchFederalTaxPaymentDto;
  achNachaFileCopyAudits?: AchNachaFileCopyAuditDto[];
  achPaymentAudits?: AchPaymentAuditDto[];
  /** @format int32 */
  achPaymentDraftId: number;
  achPaymentRecipients?: AchPaymentRecipientDto[];
  /** @format int32 */
  achPaymentRecipientsCount: number;
  achPaymentType?: AchPaymentTypeDto;
  /** @format int32 */
  achPaymentTypeId: number;
  achStateTaxPayment?: AchStateTaxPaymentDto;
  achStatusType?: AchStatusTypeDto;
  achTransactionId?: string;
  /** @format int32 */
  approvalsRequired: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  balanceValidationNotRequired: boolean;
  batchName?: string;
  /** @format int32 */
  batchUniqueId: number;
  chilAchPayments?: AchPaymentDto[];
  comment?: string;
  companyIdentity?: string;
  /** @format int32 */
  completedApprovals: number;
  copiedFromTransactionId?: string;
  createdBy?: string;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  credit?: number;
  /** @format decimal */
  debit?: number;
  descretionaryData?: string;
  destinationCountryCode?: string;
  destinationCurrency?: string;
  duplicatePreventionId?: string;
  /** @format date-time */
  effectiveDate: string;
  eligibleApprovers?: string[];
  /** @format date-time */
  endDate?: string;
  entryDescription?: string;
  errorSummary?: ErrorSummaryDto;
  failureReason?: string;
  fileArchive?: FileArchiveDto;
  /** @format int32 */
  fileArchiveId?: number;
  fileName?: string;
  foreignExchangeIndicator?: string;
  foreignExchangeReference?: string;
  /** @format int32 */
  foreignExchangeReferenceIndicator?: number;
  hasAccess: boolean;
  /** @format int32 */
  id: number;
  /** @format date-time */
  initiatedDate?: string;
  /** @format int32 */
  offsetAccountId?: number;
  /** @format decimal */
  onUsAmount?: number;
  /** @format int32 */
  onUsCount: number;
  originatorCurrencyCode?: string;
  parentAchPayment?: AchPaymentDto;
  /** @format int32 */
  parentAchPaymentId?: number;
  paymentFrequencyType?: PaymentFrequencyTypeDto;
  /** @format int32 */
  paymentFrequencyTypeId: number;
  paymentOnLastBusinessDay?: boolean;
  paymentProcessingBasedOn1Record?: boolean;
  /** @format int32 */
  prefundingDays?: number;
  recipientIdsforReversal?: number[];
  referenceNumber?: string;
  /** @format int32 */
  repeatOnDayOne?: number;
  /** @format int32 */
  repeatOnDayTwo?: number;
  restricted: boolean;
  /** @format int32 */
  reversalAchPaymentId?: number;
  secCode?: string;
  sentFileArchive?: FileArchiveDto;
  /** @format int32 */
  sentFileArchiveId?: number;
  /** @format date-time */
  startDate?: string;
  /** @format int32 */
  statusTypeId: number;
  successMessage?: string;
  updatedBy?: string;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
  canReverseFull: boolean;
  canReverseTransactions: boolean;
};
export interface AchFederalTaxPaymentDto {
  achFederalTaxPaymentAmounts?: AchFederalTaxPaymentAmountDto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  payFromAccount?: AccountDto;
  /** @format int32 */
  payFromAccountId?: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountTypeDto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  taxCode?: string;
  taxPayerId?: string;
  /** @format date-time */
  taxPeriodEndDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchFederalTaxPaymentAmountDto {
  achFederalAmountType?: AchFederalAmountTypeDto;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  /** @format int32 */
  achPaymentId: number;
  /** @format decimal */
  amount: number;
  amountType?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchFederalAmountTypeDto {
  amountType?: string;
  description?: string;
  /** @format int32 */
  id: number;
}
export interface AccountTypeDto {
  achBatchRecipients?: AchBatchRecipientDto[];
  achPaymentRecipients?: AchPaymentRecipientDto[];
  achTransactionCodes?: AchTransactionCodeDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  reportingType?: string;
}
export type AchBatchRecipientDto = BaseEntityDto & {
  accountNumber?: string;
  accountType?: AccountTypeDto;
  /** @format int32 */
  accountTypeId: number;
  achBatch?: AchBatchDto;
  /** @format int32 */
  achBatchId: number;
  achBatchRecipientAddendas?: AchBatchRecipientAddendaDto[];
  addenda?: string;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  hold: boolean;
  /** @format int32 */
  id: number;
  preNote: boolean;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  rowNum?: number;
  transactionType?: TransactionTypeDto;
  /** @format int32 */
  transactionTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type AchBatchRecipientAddendaDto = BaseEntityDto & {
  /** @format int32 */
  achBatchId?: number;
  achBatchRecipient?: AchBatchRecipientDto;
  /** @format int32 */
  achBatchRecipientId: number;
  addenda?: string;
  addendaType?: AddendaTypeDto;
  /** @format int32 */
  addendaTypeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  rowNum?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface AddendaTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface BaseEntityDto {
  objectState: ObjectStateDto;
}
export enum ObjectStateDto {
  Unchanged = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3,
}
export type TransactionTypeDto = BaseEntityDto & {
  achBatchRecipients?: AchBatchRecipientDto[];
  achCompanies?: AchCompanyDto[];
  achPaymentRecipients?: AchPaymentRecipientDto[];
  achTransactionCodes?: AchTransactionCodeDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchPaymentRecipientDto = BaseEntityDto & {
  accountNumber?: string;
  accountType?: AccountTypeDto;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  achMasterRecipientId?: number;
  achPayment?: AchPaymentDto;
  /** @format int32 */
  achPaymentId: number;
  achPaymentRecipientAddendas?: AchPaymentRecipientAddendaDto[];
  addenda?: string;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  hold: boolean;
  /** @format int32 */
  id: number;
  isRoutingNumberOnUs: boolean;
  isRoutingNumberValid: boolean;
  preNote: boolean;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  rowNum?: number;
  transactionType?: TransactionTypeDto;
  /** @format int32 */
  transactionTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type AchPaymentRecipientAddendaDto = BaseEntityDto & {
  /** @format int32 */
  achPaymentId: number;
  achPaymentRecipient?: AchPaymentRecipientDto;
  /** @format int32 */
  achPaymentRecipientId: number;
  addenda?: string;
  addendaType?: AddendaTypeDto;
  /** @format int32 */
  addendaTypeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  nonCustodialParentFirstName?: string;
  nonCustodialParentLastName?: string;
  /** @format int32 */
  rowNum?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface AchTransactionCodeDto {
  accountType?: AccountTypeDto;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  id: number;
  transactionCode?: TransactionCodeDto;
  /** @format int32 */
  transactionCodeId: number;
  transactionType?: TransactionTypeDto;
  /** @format int32 */
  transactionTypeId: number;
}
export type TransactionCodeDto = BaseEntityDto & {
  achTransactionCodes?: AchTransactionCodeDto[];
  /** @format int32 */
  baiCode?: number;
  baiCodeType?: BaiCodeTypeDto;
  changeStatus?: string;
  code?: string;
  description?: string;
  excluded: boolean;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  isDeleted: boolean;
  isDescriptionChanged: boolean;
  isTypeChanged: boolean;
  ofxTransactionType?: OfxTransactionTypeDto;
  /** @format int32 */
  ofxTransactionTypeId?: number;
  transactionCodeGroupAssociations?: TransactionCodeGroupAssociationDto[];
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type BaiCodeTypeDto = BaseEntityDto & {
  /** @format int32 */
  baiCode: number;
  /** @format int32 */
  baiCodeTitleId: number;
  description?: string;
  isSelected: boolean;
  level?: string;
  transactionType?: string;
};
export type InstitutionDto = BaseEntityDto & {
  accountAnalysisExceptions?: AccountAnalysisExceptionDto[];
  achExceptionReturnCode?: string;
  analysisTransactionCodes?: AnalysisTransactionCodeDto[];
  boReports?: BoReportDto[];
  brandName?: string;
  challengeGroups?: ChallengeGroupDto[];
  companies?: CompanyDto[];
  coreReleaseVersion?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  fiId?: string;
  fileArchive?: FileArchiveDto;
  /** @format int32 */
  fileArchiveId?: number;
  /** @format int32 */
  id: number;
  informationCenterConfigurations?: InformationCenterConfigurationDto[];
  institutionAccountDisplaySetting?: InstitutionAccountDisplaySettingDto;
  institutionAffiliations?: InstitutionAffiliationDto[];
  institutionFraudProviders?: InstitutionFraudProviderDto;
  institutionMessageCenter?: InstitutionMessageCenterDto;
  institutionNachaFileRecord?: InstitutionNachaFileRecordDto;
  institutionName?: string;
  institutionNotificationTypes?: InstitutionNotificationTypeDto[];
  institutionOobConfiguration?: InstitutionOobConfigurationDto;
  institutionProductTypes?: InstitutionProductTypeDto[];
  institutionTerm?: InstitutionTermDto;
  institutionUserApprovalSetting?: InstitutionUserApprovalSettingDto;
  institutionWireInternationalWorkflowOverrides?: InstitutionWireInternationalWorkflowOverridesDto;
  jxEnvironment?: string;
  jxRoutingNumber?: string;
  loginConfigurations?: LoginConfigurationDto[];
  logo?: string;
  logoutURL?: string;
  notificationEmail?: string;
  notificationEnabled?: boolean;
  /** @format duration */
  paymentProcessTime: string;
  resourceConfigurations?: ResourceConfigurationDto[];
  roles?: RoleDto[];
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  rsaInstId?: string;
  showCutOffTimeZone?: boolean;
  supportDepartmentName?: string;
  supportEmail?: string;
  supportPhone?: string;
  /** @format int32 */
  timeZoneId: number;
  transactionCodeGroups?: TransactionCodeGroupDto[];
  transactionCodes?: TransactionCodeDto[];
  treasuryProductName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  users?: UserDto[];
  welcomeEmailText?: string;
};
export interface AccountAnalysisExceptionDto {
  /** @format date-time */
  activityDate?: string;
  analysisTransactionType?: AnalysisTransactionTypeDto;
  /** @format int32 */
  analysisTransactionTypeId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  exceptionReason?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId?: number;
  transactionCode?: string;
  /** @format int32 */
  transactionCount?: number;
}
export type AnalysisTransactionTypeDto = BaseEntityDto & {
  accountAnalysisExceptions?: AccountAnalysisExceptionDto[];
  allowMultipleAccounts: boolean;
  analysisTransactionCodes?: AnalysisTransactionCodeDto[];
  analysisTransactionCounts?: AnalysisTransactionCountDto[];
  analysisTransactionTypeCategory?: AnalysisTransactionTypeCategoryDto;
  /** @format int32 */
  analysisTransactionTypeCategoryId: number;
  /** @format int32 */
  analysisTransactionTypeId: number;
  description?: string;
  /** @format int32 */
  sortOrder: number;
  transactionName?: string;
};
export type AnalysisTransactionCodeDto = BaseEntityDto & {
  active: boolean;
  /** @format int32 */
  analysisTransactionCodeId: number;
  analysisTransactionType?: AnalysisTransactionTypeDto;
  /** @format int32 */
  analysisTransactionTypeId: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  transactionCode?: string;
};
export interface AnalysisTransactionCountDto {
  account?: AccountDto;
  accountAnalysisProcessStatusType?: AccountAnalysisProcessStatusTypeDto;
  /** @format int32 */
  accountAnalysisProcessStatusTypeId?: number;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  activityDate: string;
  /** @format int32 */
  analysisTransactionCountId: number;
  analysisTransactionType?: AnalysisTransactionTypeDto;
  /** @format int32 */
  analysisTransactionTypeId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  exceptionReason?: string;
  transactionCode?: string;
  /** @format int32 */
  transactionCount: number;
  /** @format date-time */
  updatedDate?: string;
}
export interface AccountAnalysisProcessStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type AnalysisTransactionTypeCategoryDto = BaseEntityDto & {
  categoryName?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  sortOrder: number;
};
export type BoReportDto = BaseEntityDto & {
  boFavoriteReports?: BoFavoriteReportDto[];
  boReport1?: BoReportDto[];
  boReport2?: BoReportDto;
  boReportColumns?: BoReportColumnDto[];
  boReportFilters?: BoReportFilterDto[];
  customFilter?: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId?: number;
  isCustomReport: boolean;
  isFavoriteReport?: boolean;
  /** @format int32 */
  ownerId?: number;
  reportName?: string;
  reportType?: string;
  rowStatusId: number;
  runDefaultSearch: boolean;
  sequences?: BoReportUISequenceDto[];
  /** @format int32 */
  standardReportId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
};
export type BoFavoriteReportDto = BaseEntityDto & {
  boReport?: BoReportDto;
  /** @format int32 */
  boReportId: number;
  /** @format int32 */
  id: number;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type BoReportColumnDto = BaseEntityDto & {
  boReport?: BoReportDto;
  /** @format int32 */
  boReportId: number;
  columnName?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  detailsAccessible: boolean;
  displayName?: string;
  /** @format int32 */
  displayOrder: number;
  displayType?: string;
  /** @format int32 */
  id: number;
  isSortable: boolean;
};
export type BoReportFilterDto = BaseEntityDto & {
  applyCascadeFilter: boolean;
  boReport?: BoReportDto;
  /** @format int32 */
  boReportId: number;
  filterLabel?: string;
  filterModel?: string;
  filterType?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  required: boolean;
  triggerCascadeFilter: boolean;
};
export type BoReportUISequenceDto = BaseEntityDto & {
  boReport?: BoReportDto;
  /** @format int32 */
  boReportId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  selector?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type ChallengeGroupDto = BaseEntityDto & {
  challengeGroupDetails?: ChallengeGroupDetailDto[];
  companyChallengeGroups?: CompanyChallengeGroupDto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  isDefault: boolean;
  name?: string;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ChallengeGroupDetailDto = BaseEntityDto & {
  challengeGroup?: ChallengeGroupDto;
  /** @format int32 */
  challengeGroupId: number;
  challengePoint?: ChallengePointDto;
  /** @format int32 */
  challengePointId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isActivatedByThreshold: boolean;
  isActive: boolean;
  /** @format decimal */
  thresholdAmount?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ChallengePointDto = BaseEntityDto & {
  challengeGroupDetails?: ChallengeGroupDetailDto[];
  challengeLocation?: string;
  challengePointCategory?: ChallengePointCategoryDto;
  /** @format int32 */
  challengePointCategoryId: number;
  challengePointFrequencyType?: ChallengePointFrequencyTypeDto;
  /** @format int32 */
  challengePointFrequencyTypeId: number;
  description?: string;
  hasThreshold: boolean;
  hoverText?: string;
  /** @format int32 */
  id: number;
  name?: string;
  userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
};
export type ChallengePointCategoryDto = BaseEntityDto & {
  challengePoints?: ChallengePointDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  /** @format decimal */
  sort: number;
};
export type ChallengePointFrequencyTypeDto = BaseEntityDto & {
  challengePoints?: ChallengePointDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface UserSessionChallengeStatusDto {
  /** @format date-time */
  challengeDate: string;
  /** @format int32 */
  challengeMethodTypeId: number;
  challengePoint?: ChallengePointDto;
  /** @format int32 */
  challengePointId: number;
  /** @format int32 */
  challengeStatusId: number;
  challengeStatusType?: ChallengeStatusTypeDto;
  /** @format int32 */
  id: number;
  /** @format int32 */
  oobReceiptId?: number;
  oobValidationMode?: OobValidationModeDto;
  /** @format int32 */
  oobValidationModeId?: number;
  session?: SessionDto;
  /** @format guid */
  sessionId: string;
}
export type ChallengeStatusTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
};
export interface SessionDto {
  browser?: string;
  /** @format guid */
  clientConnectionId?: string;
  clientIpAddress?: string;
  device?: string;
  /** @format guid */
  id: string;
  /** @format date-time */
  latestSessionActivityDate?: string;
  /** @format date-time */
  loginDate: string;
  /** @format date-time */
  logoffDate?: string;
  mfaCompleted: boolean;
  mfaRequired: boolean;
  printMailSSoId?: string;
  printMailUrl?: string;
  secureTokenMfaCompleted: boolean;
  secureTokenMfaRequired: boolean;
  sessionActivities?: SessionActivityDto[];
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
  userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
}
export interface SessionActivityDto {
  /** @format guid */
  activityId: string;
  activityName?: string;
  /** @format date-time */
  createdDate: string;
  session?: SessionDto;
  /** @format guid */
  sessionId: string;
}
export type CompanyChallengeGroupDto = BaseEntityDto & {
  challengeGroup?: ChallengeGroupDto;
  /** @format int32 */
  challengeGroupId: number;
  /** @format int32 */
  challengeMethodTypeId: number;
  company?: CompanyDto;
  /** @format int32 */
  id: number;
  /** @format int32 */
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CompanyDto = BaseEntityDto & {
  accounts?: AccountDto[];
  achCompany?: AchCompanyDto;
  address?: AddressDto;
  beneficiaries?: BeneficiaryDto[];
  canSelect: boolean;
  childCompanies?: CompanyDto[];
  cifNumber?: string;
  comments?: string;
  companyAccountDisplaySetting?: CompanyAccountDisplaySettingDto;
  companyChallengeGroup?: CompanyChallengeGroupDto;
  /** @format guid */
  companyGuidUniqueId: string;
  companyLimits?: CompanyLimitDto[];
  companyType?: CompanyTypeDto;
  /** @format int32 */
  companyTypeId: number;
  companyUniqueId?: string;
  companyUserApprovalSetting?: CompanyUserApprovalSettingDto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  customerSince?: string;
  fileImportLayouts?: FileImportLayoutDto[];
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  internalTransferTransactions?: InternalTransferTransactionDto[];
  name?: string;
  ofxAuthenticationCode?: string;
  parentCompany?: CompanyDto;
  /** @format int32 */
  parentCompanyId?: number;
  products?: ProductDto[];
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  taxNumber?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccounts?: UserAccountDto[];
  users?: UserDto[];
  wires?: WireDto[];
};
export type AddressDto = BaseEntityDto & {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  country?: CountryDto;
  /** @format int32 */
  countryId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  postalCode?: string;
  postalCodeExtension?: string;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  rowVersionId?: number;
  state?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CountryDto = BaseEntityDto & {
  addresses?: AddressDto[];
  countryCode?: string;
  countryName?: string;
  currencyCode?: string;
  currencyName?: string;
  /** @format int32 */
  id: number;
  intradaySpot?: boolean;
};
export type RowStatusDto = BaseEntityDto & {
  accounts?: AccountDto[];
  addresses?: AddressDto[];
  beneficiaries?: BeneficiaryDto[];
  beneficiaryAudits?: BeneficiaryAuditDto[];
  companies?: CompanyDto[];
  description?: string;
  domesticBanks?: DomesticBankDto[];
  id: number;
  informationCenterConfigurations?: InformationCenterConfigurationDto[];
  institutionNotificationTypes?: InstitutionNotificationTypeDto[];
  institutionProductTypes?: InstitutionProductTypeDto[];
  institutions?: InstitutionDto[];
  name?: string;
  products?: ProductDto[];
  resourceConfigurations?: ResourceConfigurationDto[];
  roles?: RoleDto[];
  schedules?: ScheduleDto[];
  userNotificationTypes?: UserNotificationTypeDto[];
  users?: UserDto[];
};
export type BeneficiaryDto = BaseEntityDto & {
  additionalDetails?: string;
  address?: AddressDto;
  /** @format int32 */
  addressId: number;
  /** @format int32 */
  approvalCount: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  beneficiaryAccountNumber?: string;
  beneficiaryAudits?: BeneficiaryAuditDto[];
  beneficiaryName?: string;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  domesticBank?: DomesticBankDto;
  /** @format int32 */
  domesticBankId?: number;
  domesticIntermediaryAccountNumber?: string;
  domesticIntermediaryBankAddress?: AddressDto;
  /** @format int32 */
  domesticIntermediaryBankAddressId?: number;
  domesticIntermediaryNotes?: string;
  eligibleApprovers?: string[];
  foreignBankAddress?: AddressDto;
  /** @format int32 */
  foreignBankAddressId?: number;
  foreignBankId?: string;
  foreignBankIdentificationType?: BankIdentificationTypeDto;
  /** @format int32 */
  foreignBankIdentificationTypeId?: number;
  foreignBankName?: string;
  foreignIntermediaryBankAddress?: AddressDto;
  /** @format int32 */
  foreignIntermediaryBankAddressId?: number;
  foreignIntermediaryBankId?: string;
  foreignIntermediaryBankIdentificationType?: BankIdentificationTypeDto;
  /** @format int32 */
  foreignIntermediaryBankIdentificationTypeId?: number;
  foreignIntermediaryBankName?: string;
  foreignIntermediaryNotes?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId: number;
  intermediaryDomesticBank?: DomesticBankDto;
  /** @format int32 */
  intermediaryDomesticBankId?: number;
  isEdited: boolean;
  isForeignCurrency?: boolean;
  isFreeForm: boolean;
  isInternational: boolean;
  isTemplateBeneficiary: boolean;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  status?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  useDomesticIntermediary?: boolean;
  useForeignIntermediary?: boolean;
  userDefinedDomesticBankAddress?: AddressDto;
  /** @format int32 */
  userDefinedDomesticBankAddressId?: number;
  userDefinedDomesticBankId?: string;
  userDefinedDomesticBankIdentificationType?: BankIdentificationTypeDto;
  /** @format int32 */
  userDefinedDomesticBankIdentificationTypeId?: number;
  userDefinedDomesticBankName?: string;
  userPermissions?: string[];
  wires?: WireDto[];
};
export interface TupleOfstringdateTimeDto {
  m_Item1: string;
  /** @format date-time */
  m_Item2: string;
}
export type BeneficiaryAuditDto = BaseEntityDto & {
  /** @format date-time */
  actionDate: string;
  /** @format int32 */
  actionUserId: number;
  beneficiary?: BeneficiaryDto;
  beneficiaryActionType?: BeneficiaryActionTypeDto;
  /** @format int32 */
  beneficiaryActionTypeId: number;
  /** @format int32 */
  beneficiaryId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  rowVersionId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
};
export type BeneficiaryActionTypeDto = BaseEntityDto & {
  beneficiaryAudits?: BeneficiaryAuditDto[];
  /** @format int32 */
  id: number;
  name?: string;
};
export type DomesticBankDto = BaseEntityDto & {
  addressLine1?: string;
  addressLine2?: string;
  bankName?: string;
  city?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate?: string;
  domesticBankTypeId?: number;
  /** @format int32 */
  id: number;
  isActive: boolean;
  onUs: boolean;
  routingNumber?: string;
  rowStatus?: RowStatusDto;
  rowStatusId?: number;
  /** @format int32 */
  rowVersionId?: number;
  settlementType?: string;
  state?: StateDto;
  stateCode?: string;
  /** @format int32 */
  stateId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  zipCode?: string;
};
export type StateDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  stateCode?: string;
  stateName?: string;
};
export type BankIdentificationTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type WireDto = BaseWireDto & {
  approvedBy?: TupleOfstringdateTimeDto[];
  /** @format int32 */
  completedApprovals: number;
  createdByUser?: UserDto;
  /** @format decimal */
  destinationCurrencyAmount: number;
  duplicatePreventionId?: string;
  eligibleApprovers?: string[];
  errorSummary?: ErrorSummaryDto;
  /** @format decimal */
  exchangeFee: number;
  /** @format decimal */
  totalCost: number;
  updatedByUser?: UserDto;
  userPermissions?: string[];
  wireActionTypeEnum: WireActionTypeEnumDto;
  wireAudits?: WireAuditDto[];
  wireQuotes?: WireQuoteDto[];
};
export interface ErrorSummaryDto {
  details?: ErrorDetailDto[];
  summaryMessage?: string;
  summaryMessageList?: string[];
  type: ErrorTypeDto;
}
export interface ErrorDetailDto {
  attributeName?: string;
  key?: string;
  message?: string;
  messageList?: LookupDto[];
  type: ErrorTypeDto;
}
export enum ErrorTypeDto {
  Error = 0,
  Info = 1,
  Warning = 2,
}
export enum WireActionTypeEnumDto {
  Undefined = 0,
  New = 1,
  Modified = 2,
  Cancelled = 3,
  QuoteRequested = 4,
  QuoteAccepted = 5,
  QuoteRejected = 6,
  Approved = 7,
  Rejected = 8,
  Transmitted = 9,
  Expired = 10,
  Failed = 11,
  Posted = 12,
  Deleted = 13,
}
export type WireAuditDto = BaseEntityDto & {
  /** @format date-time */
  actionDate: string;
  /** @format int32 */
  actionUserId: number;
  comments?: string;
  /** @format int32 */
  id: number;
  user?: UserDto;
  wire?: WireDto;
  wireActionType?: WireActionTypeDto;
  /** @format int32 */
  wireActionTypeId: number;
  /** @format int32 */
  wireId: number;
};
export type WireActionTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  wireActionType1?: string;
  wireAudits?: WireAuditDto[];
};
export type WireQuoteDto = BaseEntityDto & {
  amexContractNumber?: string;
  /** @format int32 */
  amexQuoteId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  createdUser?: UserDto;
  /** @format int32 */
  debitAccountId?: number;
  /** @format decimal */
  destinationCurrencyAmount: number;
  destinationCurrencyCode?: string;
  /** @format decimal */
  exchangeFee: number;
  /** @format decimal */
  exchangeRate: number;
  /** @format date-time */
  expirationDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionFxWireRatesheetId?: number;
  /** @format date-time */
  quoteDate: string;
  /** @format int32 */
  quoteStatusTypeId: number;
  rateId?: string;
  /** @format decimal */
  totalWireCost: number;
  /** @format decimal */
  usdAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  wire?: WireDto;
  /** @format decimal */
  wireFee: number;
  wireFeeCurCode?: string;
  /** @format int32 */
  wireId?: number;
  wireQuoteStatusType?: WireQuoteStatusTypeDto;
};
export type WireQuoteStatusTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireQuotes?: WireQuoteDto[];
};
export type BaseWireDto = BaseEntityDto & {
  account?: AccountDto;
  additionalInformation?: string;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  approvalCount: number;
  associatedWire?: WireDto;
  /** @format int32 */
  associatedWireId?: number;
  beneficiary?: BeneficiaryDto;
  beneficiaryDetails?: string;
  /** @format int32 */
  beneficiaryId: number;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  confirmationNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  debitAccountId: number;
  destinationCurrencyCode?: string;
  /** @format date-time */
  endDate?: string;
  externallyProcessed: boolean;
  failureReason?: string;
  /** @format int32 */
  firstRepeatOnDay?: number;
  /** @format int32 */
  frequencyTypeId: number;
  /** @format int32 */
  id: number;
  isDliWire: boolean;
  isDomestic: boolean;
  parentCompany?: CompanyDto;
  paymentFrequencyType?: PaymentFrequencyTypeDto;
  paymentId?: string;
  purpose?: string;
  referenceBeneficiary?: string;
  referenceNumber?: string;
  repeatOnLastBusinessDay?: boolean;
  /** @format int32 */
  secondRepeatOnDay?: number;
  /** @format date-time */
  startDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format date-time */
  valueDate?: string;
  /** @format int32 */
  wireCompanyId: number;
  wireFile?: WireFileDto;
  /** @format int32 */
  wireFileId?: number;
  wireStatusType?: WireStatusTypeDto;
  /** @format int32 */
  wireStatusTypeId: number;
  wireTemplate?: WireTemplateDto;
  /** @format int32 */
  wireTemplateId?: number;
};
export type PaymentFrequencyTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  schedules?: ScheduleDto[];
  wires?: WireDto[];
};
export type ScheduleDto = BaseEntityDto & {
  canSpawnMultipleJobs?: boolean;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  firstRepeatOn?: number;
  /** @format int32 */
  frequencyId: number;
  /** @format int32 */
  id: number;
  importBatchId?: string;
  institutionFiId?: string;
  /** @format int32 */
  interval?: number;
  isWeekendIncluded?: boolean;
  /** @format date-time */
  lastScheduled?: string;
  pauseSchedule: boolean;
  paymentFrequencyType?: PaymentFrequencyTypeDto;
  productType?: string;
  recycleJob: boolean;
  repeatOnLastBusinessDay?: boolean;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  runningTime?: number;
  scheduleName?: string;
  scheduleSystem?: ScheduleSystemDto;
  /** @format int32 */
  scheduleSystemId: number;
  scheduledJobs?: ScheduledJobDto[];
  /** @format int32 */
  secondRepeatOn?: number;
  /** @format date-time */
  startDateTime?: string;
  /** @format int32 */
  systemUniqueId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId?: number;
  /** @format date-time */
  valueDateTime?: string;
};
export type ScheduleSystemDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  schedules?: ScheduleDto[];
};
export type ScheduledJobDto = BaseEntityDto & {
  /** @format guid */
  activityId?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  executionDateTime: string;
  /** @format date-time */
  frequencyTargetDate?: string;
  /** @format int64 */
  id: number;
  jobStatusType?: JobStatusTypeDto;
  /** @format byte */
  jobStatusTypeId: number;
  responseMessages?: string;
  schedule?: ScheduleDto;
  /** @format int32 */
  scheduleId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type JobStatusTypeDto = BaseEntityDto & {
  description?: string;
  /** @format byte */
  id: number;
  name?: string;
  scheduledJobs?: ScheduledJobDto[];
};
export interface WireFileDto {
  /** @format int32 */
  batchCount?: number;
  fileArchive?: FileArchiveDto;
  /** @format int32 */
  fileArchiveId: number;
  /** @format int32 */
  fileSize: number;
  /** @format int32 */
  id: number;
  wires?: WireDto[];
}
export type FileArchiveDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format byte */
  fileContent?: string;
  fileName?: string;
  /** @format int32 */
  fileTypeId: number;
  fileValidationFailures?: FileValidationFailureDto[];
  /** @format int32 */
  id: number;
  isValid: boolean;
  /** @format guid */
  uniqueId: string;
};
export interface FileValidationFailureDto {
  fileArchive?: FileArchiveDto;
  /** @format int32 */
  fileArchiveId: number;
  fileValidationFailureType?: FileValidationFailureTypeDto;
  /** @format int32 */
  fileValidationFailureTypeId: number;
  /** @format int32 */
  id: number;
  validationFailureJson?: string;
}
export interface FileValidationFailureTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type WireStatusTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  wires?: WireDto[];
};
export type WireTemplateDto = BaseEntityDto & {
  /** @format int32 */
  approvalCount: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  beneficiary?: BeneficiaryDto;
  /** @format int32 */
  beneficiaryId: number;
  /** @format int32 */
  completedApprovals: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  debitAccount?: AccountDto;
  /** @format int32 */
  debitAccountId: number;
  eligibleApprovers?: string[];
  errorSummary?: ErrorSummaryDto;
  /** @format int32 */
  id: number;
  initiatedRecurringWire: boolean;
  isInternational: boolean;
  notes?: string;
  purpose?: string;
  referenceBeneficiary?: string;
  templateName?: string;
  templateStatusType?: WireTemplateStatusTypeDto;
  /** @format int32 */
  templateStatusTypeId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  userPermissions?: string[];
  wireCompany?: CompanyDto;
  /** @format int32 */
  wireCompanyId: number;
  wireTemplateAudits?: WireTemplateAuditDto[];
};
export type WireTemplateStatusTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireTemplates?: WireTemplateDto[];
};
export type WireTemplateAuditDto = BaseEntityDto & {
  /** @format date-time */
  actionDate: string;
  actionUser?: UserDto;
  /** @format int32 */
  actionUserId: number;
  /** @format int32 */
  id: number;
  wireTemplateActionType?: WireTemplateActionTypeDto;
  /** @format int32 */
  wireTemplateActionTypeId: number;
  /** @format int32 */
  wireTemplateId: number;
};
export type WireTemplateActionTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireTemplateAudits?: WireTemplateAuditDto[];
};
export type InformationCenterConfigurationDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  id: number;
  informationCenterMessageType?: InformationCenterMessageTypeDto;
  /** @format int32 */
  informationCenterMessageTypeId: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  sequence?: number;
  /** @format date-time */
  startDate: string;
  title?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type InformationCenterMessageTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  informationCenterConfigurations?: InformationCenterConfigurationDto[];
  name?: string;
};
export type InstitutionNotificationTypeDto = BaseEntityDto & {
  desktopNotification: boolean;
  email: boolean;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  isNotificationEnabled: boolean;
  isRequired: boolean;
  notificationGroup?: string;
  notificationType?: NotificationTypeDto;
  /** @format int32 */
  notificationTypeId: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  sortOrder?: number;
  textMessage: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type NotificationTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionNotificationTypes?: InstitutionNotificationTypeDto[];
  isActiveByDefault: boolean;
  name?: string;
  notificationPriorityType?: NotificationPriorityTypeDto;
  /** @format int32 */
  notificationPriorityTypeId: number;
  notificationProductType?: NotificationProductTypeDto;
  /** @format int32 */
  notificationProductTypeId: number;
  notifications?: NotificationDto[];
  /** @format int32 */
  sortOrder?: number;
  userNotificationTypes?: UserNotificationTypeDto[];
};
export interface NotificationPriorityTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  notificationTypes?: NotificationTypeDto[];
}
export type NotificationProductTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  notificationTypes?: NotificationTypeDto[];
};
export interface NotificationDto {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  createdDateFiLocalTime?: string;
  /** @format int32 */
  id: number;
  notificationPayload?: string;
  notificationType?: NotificationTypeDto;
  /** @format int32 */
  notificationTypeId: number;
  /** @format guid */
  notificationUniqueIdentifier: string;
  showAlert: boolean;
  /** @format int32 */
  userNotificationId: number;
}
export type UserNotificationTypeDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  desktopNotification?: boolean;
  email?: boolean;
  /** @format int32 */
  id: number;
  isDesktopAllowed?: boolean;
  isDesktopRequired: boolean;
  isEmailAllowed?: boolean;
  isEmailRequired: boolean;
  isTextAllowed?: boolean;
  isTextRequired: boolean;
  notificationGroup?: string;
  notificationType?: NotificationTypeDto;
  /** @format int32 */
  notificationTypeId: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  sortOrder?: number;
  textMessage?: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type InstitutionProductTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  institutionAchProduct?: InstitutionAchProductDto;
  institutionArpProduct?: InstitutionArpProductDto;
  institutionBillPayProduct?: InstitutionBillPayProductDto;
  institutionElectronicDocuments?: InstitutionElectronicDocumentsDto;
  /** @format int32 */
  institutionId: number;
  institutionInternalTransferProduct?: InstitutionInternalTransferProductDto;
  institutionProductCutoffTimes?: InstitutionProductCutoffTimeDto[];
  institutionReportingConfigurations?: InstitutionReportingConfigurationDto[];
  institutionReportingProduct?: InstitutionReportingProductDto;
  institutionSsoProducts?: InstitutionSsoProductDto[];
  institutionStopPayment?: InstitutionStopPaymentDto;
  institutionWireInternationalProduct?: InstitutionWireInternationalProductDto;
  institutionWireProduct?: InstitutionWireProductDto;
  internationalUsdReadOnly: boolean;
  isCompanyProductConfigurable: boolean;
  productType?: ProductTypeDto;
  /** @format int32 */
  productTypeId: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface InstitutionAchProductDto {
  accountBalanceValidationConfiguredInCore: boolean;
  achCompanyMatchType?: AchCompanyMatchTypeDto;
  /** @format int32 */
  achCompanyMatchTypeId: number;
  allowChildSupportPayments: boolean;
  allowFederalTaxPayments: boolean;
  allowInternationalPayments: boolean;
  allowNotificationOfChange: boolean;
  allowOnUsAccessManagement: boolean;
  allowReturnReview: boolean;
  allowSameDayAch: boolean;
  allowStateTaxPayments: boolean;
  allowTaxPayments: boolean;
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  batchApprovalCount: number;
  batchAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  batchAuthenticationTypeId: number;
  copyInitiatedNachaFiles?: boolean;
  /** @format int32 */
  daysInAdvance?: number;
  /** @format int32 */
  id: number;
  institutionAchRoutings?: InstitutionAchRoutingDto[];
  institutionAchSecCodes?: InstitutionAchSecCodeDto[];
  institutionProductType?: InstitutionProductTypeDto;
  internationalEnabled: boolean;
  /** @format int32 */
  internationalTemplateApprovalCount: number;
  nachaFileUploadPath?: string;
  /** @format int32 */
  paymentApprovalCount: number;
  paymentAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  paymentProcessingCompanyConfiguredInCore?: boolean;
  /** @format int32 */
  recipientApprovalCount: number;
  remoteServerHost?: string;
  remoteServerPassword?: string;
  /** @format int32 */
  remoteServerPort?: number;
  remoteServerUserName?: string;
  /** @format decimal */
  sameDayAchLimit?: number;
  stateTaxCodesArchiveFile?: FileArchiveDto;
  /** @format int32 */
  stateTaxCodesArchiveFileId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  validateAccountBalance: boolean;
}
export type AchCompanyMatchTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionAchProducts?: InstitutionAchProductDto[];
  name?: string;
};
export type AuthenticationTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type InstitutionAchRoutingDto = BaseEntityDto & {
  bankName?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  institutionAchProduct?: InstitutionAchProductDto;
  /** @format int32 */
  institutionProductTypeId: number;
  routingNumber?: string;
  /** @format date-time */
  startDate: string;
};
export type InstitutionAchSecCodeDto = BaseEntityDto & {
  achSecCode?: AchSecCodeDto;
  /** @format int32 */
  achSecCodeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  institutionAchProduct?: InstitutionAchProductDto;
  /** @format int32 */
  institutionProductTypeId: number;
};
export interface AchSecCodeDto {
  description?: string;
  /** @format int32 */
  id: number;
  secCode?: string;
}
export interface InstitutionArpProductDto {
  allowIssuedItemActivityReview: boolean;
  allowSubstituteProduct: boolean;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  manageAchExceptions: boolean;
  showAchExceptions: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  workAchException: boolean;
  workCheckException: boolean;
}
export interface InstitutionBillPayProductDto {
  allowIPayBusinessBillPayForMobile: boolean;
  billPayServiceName?: string;
  billPayServiceUrl?: string;
  clientId?: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  landingPageUrl?: string;
  /** @format int32 */
  navigationMode?: number;
  sharedKey?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionElectronicDocumentsDto {
  clientId?: string;
  electronicDocumentServiceName?: string;
  electronicDocumentServiceUri?: string;
  electronicDocumentType?: ElectronicDocumentTypeDto;
  /** @format int32 */
  electronicDocumentTypeId?: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  /** @format double */
  monthlyFee: number;
  /** @format int32 */
  navigationMode?: number;
  sharedKey?: string;
  showSsoFields?: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface ElectronicDocumentTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  institutionElectronicDocuments?: InstitutionElectronicDocumentsDto[];
  name?: string;
}
export type InstitutionInternalTransferProductDto = BaseEntityDto & {
  allowLoanPaymentPastDue: boolean;
  /** @format int32 */
  id: number;
  institutionInternalTransferProductLinkAccounts?: InstitutionInternalTransferProductLinkAccountDto[];
  institutionProductType?: InstitutionProductTypeDto;
};
export type InstitutionInternalTransferProductLinkAccountDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  fromAccountTypeId: number;
  /** @format int32 */
  id: number;
  institutionInternalTransferProduct?: InstitutionInternalTransferProductDto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format int32 */
  toAccountTypeId: number;
};
export type InstitutionProductCutoffTimeDto = BaseEntityDto & {
  /** @format duration */
  blackoutPeriodEndTime?: string;
  /** @format duration */
  blackoutPeriodStartTime?: string;
  cutoffTimeSubType?: CutoffTimeSubTypeDto;
  /** @format int32 */
  cutoffTimeSubTypeId?: number;
  /** @format duration */
  endCutoffTime: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  /** @format int32 */
  institutionProductTypeId: number;
  processingDayType?: ProcessingDayTypeDto;
  /** @format int32 */
  processingDayTypeId?: number;
  showInChannel?: boolean;
  /** @format duration */
  startCutoffTime?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CutoffTimeSubTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type ProcessingDayTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  institutionProductCutoffTimes?: InstitutionProductCutoffTimeDto[];
  processingDay?: string;
};
export type InstitutionReportingConfigurationDto = BaseEntityDto & {
  hasBai: boolean;
  hasCsv: boolean;
  hasDownload: boolean;
  hasEmail: boolean;
  hasPdf: boolean;
  hasQuickBooks: boolean;
  hasQuicken: boolean;
  hasTiff: boolean;
  hasWord: boolean;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format int32 */
  standardReportId: number;
  supportsBai: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type InstitutionReportingProductDto = BaseEntityDto & {
  channelMobile: boolean;
  channelTablet: boolean;
  channelWeb: boolean;
  exportFormats?: ReportExportFormatDto[];
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
};
export interface ReportExportFormatDto {
  isConfigured: boolean;
  name?: string;
}
export type InstitutionSsoProductDto = BaseEntityDto & {
  clientId?: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  /** @format int32 */
  institutionProductTypeId: number;
  landingPageUrl?: string;
  rowStatusId: number;
  serviceUrl?: string;
  sharedEncryptionInitializationVector?: string;
  sharedEncryptionKey?: string;
  ssoLoginSystem?: SsoLoginSystemDto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
};
export type SsoLoginSystemDto = BaseEntityDto & {
  description?: string;
  institutionSsoProducts?: InstitutionSsoProductDto[];
  name?: string;
  /** @format int32 */
  ssoLoginSystemId: number;
  userSsoLogins?: UserSsoLoginDto[];
};
export type UserSsoLoginDto = BaseEntityDto & {
  /** @format int32 */
  rowStatusId: number;
  ssoAccessKey?: string;
  ssoLoginId?: string;
  ssoLoginSystem?: SsoLoginSystemDto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  userSsoLoginId: number;
};
export interface InstitutionStopPaymentDto {
  approvalCount: number;
  /** @format double */
  feePerRequest: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionWireInternationalProductDto = BaseEntityDto & {
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format decimal */
  dailyLimit: number;
  fxClientId?: string;
  fxPaymentNotificationEmailAddresses?: TextKeyCollection2Dto;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSettingDto[];
  internationalUsdReadOnly: boolean;
  /** @format byte */
  outgoingSettlementTypeId: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType: number;
  requirePaymentPurpose: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  validateAccountBalance?: boolean;
  wireSettlementAccount?: string;
  wireTraditionalInternationalEnabled: boolean;
  wireUsdEnabled: boolean;
};
export type TextKeyCollection2Dto = string[];
export interface InstitutionWirePaymentApprovalSettingDto {
  /** @format byte */
  approvalCount: number;
  /** @format decimal */
  endAmount?: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format decimal */
  startAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
}
export type InstitutionWireProductDto = BaseEntityDto & {
  allowWireFileUpload?: boolean;
  /** @format int32 */
  beneficiaryApprovalCount: number;
  beneficiaryAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  beneficiaryAuthenticationTypeId?: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductTypeDto;
  institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSettingDto[];
  internationalEnabled: boolean;
  limitFutureDateEnabled: boolean;
  /** @format int32 */
  maximumFutureDays?: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType?: number;
  paymentAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  paymentAuthenticationTypeId?: number;
  requirePaymentPurpose?: boolean;
  /** @format byte */
  templateApprovalCount?: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  wireDliInternationalEnabled: boolean;
};
export type ProductTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionProductTypes?: InstitutionProductTypeDto[];
  name?: string;
  productClaims?: ProductClaimDto[];
  products?: ProductDto[];
};
export type ProductClaimDto = BaseEntityDto & {
  claim?: ClaimDto;
  /** @format int32 */
  claimId: number;
  /** @format int32 */
  id: number;
  productType?: ProductTypeDto;
  /** @format int32 */
  productTypeId: number;
};
export type ClaimDto = BaseEntityDto & {
  active: boolean;
  associatedClaims?: AssociatedClaimDto[];
  claimNavigations?: ClaimNavigationDto[];
  claimPrerequisites?: ClaimPrerequisiteDto[];
  claimType?: string;
  dependentClaims?: ClaimPrerequisiteDto[];
  description?: string;
  /** @format int32 */
  id: number;
  productClaims?: ProductClaimDto[];
  uiPermission?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userType?: UserType1Dto;
  /** @format int32 */
  userTypeId: number;
};
export type AssociatedClaimDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId?: number;
  claim?: ClaimDto;
  /** @format int32 */
  claimId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  role?: RoleDto;
  /** @format int32 */
  roleId?: number;
  /** @format date-time */
  startDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId?: number;
};
export type RoleDto = BaseEntityDto & {
  associatedClaims?: AssociatedClaimDto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  name?: string;
  roleType?: RoleTypeDto;
  /** @format int32 */
  roleTypeId: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userRoles?: UserRoleDto[];
};
export type RoleTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  roles?: RoleDto[];
};
export type UserRoleDto = BaseEntityDto & {
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  role?: RoleDto;
  /** @format int32 */
  roleId: number;
  /** @format date-time */
  startDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export interface ClaimNavigationDto {
  /** @format int32 */
  "<ClaimId>k__BackingField": number;
  /** @format int32 */
  "<ClaimNavigationId>k__BackingField": number;
  "<Claim>k__BackingField": ClaimDto;
  /** @format int32 */
  "<NavigationId>k__BackingField": number;
  "<Navigation>k__BackingField": Navigation2Dto;
}
export interface Navigation2Dto {
  applicationType?: ApplicationTypeDto;
  /** @format int32 */
  applicationTypeId?: number;
  childNavigations?: Navigation2Dto[];
  key?: string;
  name?: string;
  /** @format int32 */
  navigationId: number;
  path?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type ApplicationTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  navigations?: Navigation2Dto[];
};
export interface ClaimPrerequisiteDto {
  claim?: ClaimDto;
  /** @format int32 */
  claimId?: number;
  /** @format int32 */
  id: number;
  prerequisite?: ClaimDto;
  /** @format int32 */
  prerequisiteId?: number;
}
export type UserType1Dto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  users?: UserDto[];
};
export type ProductDto = BaseEntityDto & {
  accountProducts?: AccountProductDto[];
  achProduct?: AchProductDto;
  arpProduct?: ArpProductDto;
  billPayProduct?: BillPayProductDto;
  company?: CompanyDto;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  electronicDocumentsProduct?: ElectronicDocumentsProductDto;
  /** @format int32 */
  id: number;
  internalTransferProduct?: InternalTransferProductDto;
  isConfigurable: boolean;
  productPermissions?: ProductPermissionDto[];
  productType?: ProductTypeDto;
  /** @format int32 */
  productTypeId: number;
  remoteDepositProduct?: RemoteDepositProductDto;
  reportingConfigurations?: ReportingConfigurationDto[];
  reportingProduct?: ReportingProductDto;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  stopPaymentProduct?: StopPaymentProductDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userProductLimits?: UserProductLimitDto[];
  wireInternationalProduct?: WireInternationalProductDto;
  wireProduct?: WireProductDto;
};
export interface AchProductDto {
  accountBalanceValidationConfiguredInCore: boolean;
  /** @format int32 */
  achCompanyMatchTypeId: number;
  allowChildSupportPayments: boolean;
  allowFederalTaxPayments: boolean;
  allowInternationalPayments: boolean;
  allowNotificationOfChange: boolean;
  allowOnUsAccessManagement: boolean;
  allowReturnReview: boolean;
  allowSameDayAch: boolean;
  allowStateTaxPayments: boolean;
  allowTaxPayments: boolean;
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  batchApprovalCount: number;
  batchAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  batchAuthenticationTypeId: number;
  /** @format decimal */
  dailyLimit: number;
  disableSameDay: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  internationalTemplateApprovalCount: number;
  /** @format int32 */
  paymentApprovalCount: number;
  paymentAuthenticationType?: AuthenticationTypeDto;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  product?: ProductDto;
  /** @format int32 */
  recipientApprovalCount: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  validateAccountBalance: boolean;
}
export interface ArpProductDto {
  allowIssuedItemActivityReview: boolean;
  fiAllowSubstituteProduct: boolean;
  fiManageAchExceptions: boolean;
  fiShowAchExceptions: boolean;
  fiWorkAchException: boolean;
  fiWorkCheckException: boolean;
  /** @format int32 */
  id: number;
  manageAchExceptions: boolean;
  product?: ProductDto;
  showAchExceptions: boolean;
  substituteProductClientId?: string;
  substituteProductLandingPageUrl?: string;
  substituteProductName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  useSubstituteProduct: boolean;
  workAchException: boolean;
  workAchFilter: boolean;
  workCheckException: boolean;
}
export interface BillPayProductDto {
  account?: AccountDto;
  /** @format int32 */
  accountId?: number;
  allowIPayBusinessBillPayForMobile: boolean;
  billPayServiceName?: string;
  /** @format int32 */
  id: number;
  product?: ProductDto;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface ElectronicDocumentsProductDto {
  esiEmail?: string;
  esiId?: string;
  electronicDocumentServiceName?: string;
  /** @format int32 */
  electronicDocumentTypeId?: number;
  /** @format int32 */
  id: number;
  /** @format double */
  monthlyFee: number;
  product?: ProductDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InternalTransferProductDto = BaseEntityDto & {
  allowLoanPaymentPastDue: boolean;
  /** @format int32 */
  approvalCount?: number;
  /** @format int32 */
  authenticationTypeId?: number;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  internalTransferProductLinkAccounts?: InternalTransferProductLinkAccountDto[];
  product?: ProductDto;
  /** @format byte */
  templateApprovalCount?: number;
};
export type InternalTransferProductLinkAccountDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  fromAccountType?: AccountTypeDto;
  /** @format int32 */
  fromAccountTypeId: number;
  /** @format int32 */
  id: number;
  internalTransferProduct?: InternalTransferProductDto;
  /** @format int32 */
  productId: number;
  toAccountType?: AccountTypeDto;
  /** @format int32 */
  toAccountTypeId: number;
};
export interface ProductPermissionDto {
  /** @format int32 */
  id: number;
  permission?: string;
  product?: ProductDto;
  productAccountPermissions?: ProductAccountPermissionDto[];
  /** @format int32 */
  productId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface ProductAccountPermissionDto {
  accountProduct?: AccountProductDto;
  /** @format int32 */
  accountProductId: number;
  entitlements?: EntitlementDto[];
  /** @format int32 */
  id: number;
  productPermission?: ProductPermissionDto;
  /** @format int32 */
  productPermissionId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface EntitlementDto {
  /** @format int32 */
  id: number;
  objectState: ObjectStateDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  user?: UserDto;
}
export interface RemoteDepositProductDto {
  companySsoLogin?: CompanySsoLoginDto;
  /** @format int32 */
  companySsoLoginId?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  monthlyFee?: number;
  remoteDepositProductType?: RemoteDepositProductTypeDto;
  /** @format int32 */
  remoteDepositProductTypeId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface CompanySsoLoginDto {
  clientCompanyId?: string;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  id: number;
  ssoLoginSystem?: SsoLoginSystemDto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface RemoteDepositProductTypeDto {
  description?: string;
  name?: string;
  /** @format int32 */
  remoteDepositProductTypeId: number;
}
export type ReportingConfigurationDto = BaseEntityDto & {
  hasBai: boolean;
  hasCsv: boolean;
  hasDownload: boolean;
  hasEmail: boolean;
  hasPdf: boolean;
  hasQuickBooks: boolean;
  hasQuicken: boolean;
  hasTiff: boolean;
  hasWord: boolean;
  /** @format int32 */
  id: number;
  product?: ProductDto;
  /** @format int32 */
  productId: number;
  reportingFilter?: ReportingFilterDto;
  /** @format int32 */
  standardReportId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ReportingFilterDto = BaseEntityDto & {
  accountTypes?: string;
  amountOperator?: string;
  companyUniqueId?: string;
  dateType?: string;
  favoriteReports?: FavoriteReportDto[];
  /** @format decimal */
  fromAmount?: number;
  /** @format date-time */
  fromDate?: string;
  fromPaymentId?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId?: number;
  isAscendingSort: boolean;
  isFavorite: boolean;
  isStandardReport: boolean;
  name?: string;
  /** @format int32 */
  owner?: number;
  paymentIdOperator?: string;
  paymentTypes?: FilterPaymentTypeDto[];
  postedTransactions?: boolean;
  /** @format int32 */
  priorDays?: number;
  reportType?: string;
  reportingBalanceFilter?: ReportingBalanceFilterDto;
  reportingConfigurations?: ReportingConfigurationDto[];
  reportingFilter1?: ReportingFilterDto[];
  reportingFilter2?: ReportingFilterDto;
  reportingFilterAccounts?: ReportingFilterAccountDto[];
  reportingFilterPaymentTypes?: ReportingFilterPaymentTypeDto[];
  reportingFilterSorts?: ReportingFilterSortDto[];
  reportingTransactionFilter?: ReportingTransactionFilterDto;
  /** @format int32 */
  sequence?: number;
  sortBy?: string;
  /** @format date-time */
  specificDate?: string;
  /** @format int32 */
  standardReportTypeId?: number;
  /** @format decimal */
  toAmount?: number;
  /** @format date-time */
  toDate?: string;
  toPaymentId?: string;
  transactionDirection?: string;
  transactionType?: string;
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
};
export type FavoriteReportDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilterDto;
  /** @format int32 */
  reportingFilterId: number;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export interface FilterPaymentTypeDto {
  type?: string;
}
export type ReportingBalanceFilterDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilterDto;
};
export type ReportingFilterAccountDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilterDto;
  /** @format int32 */
  reportingFilterId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ReportingFilterPaymentTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  paymentType?: string;
  reportingFilter?: ReportingFilterDto;
  /** @format int32 */
  reportingFilterId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface ReportingFilterSortDto {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilterDto;
  /** @format int32 */
  reportingFilterId: number;
  /** @format int32 */
  sequence: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
}
export type ReportingTransactionFilterDto = BaseEntityDto & {
  amountOperator?: string;
  /** @format decimal */
  fromAmount?: number;
  fromPaymentId?: string;
  /** @format int32 */
  id: number;
  paymentIdOperator?: string;
  reportingFilter?: ReportingFilterDto;
  /** @format decimal */
  toAmount?: number;
  toPaymentId?: string;
  transactionDirection?: string;
  transactionType?: string;
};
export interface ReportingProductDto {
  /** @format int32 */
  id: number;
  product?: ProductDto;
}
export interface StopPaymentProductDto {
  approvalCount: number;
  /** @format double */
  feePerRequest: number;
  /** @format int32 */
  id: number;
  product?: ProductDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type UserProductLimitDto = BaseEntityDto & {
  /** @format decimal */
  approvalLimit?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format decimal */
  initiationLimit?: number;
  product?: ProductDto;
  /** @format int32 */
  productId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type WireInternationalProductDto = BaseEntityDto & {
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  internationalUsd: boolean;
  internationalUsdReadOnly: boolean;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType: number;
  product?: ProductDto;
  requirePaymentPurpose: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  validateAccountBalance?: boolean;
  wirePaymentApprovalSettings?: WirePaymentApprovalSettingDto[];
  wireUsdEnabled: boolean;
};
export interface WirePaymentApprovalSettingDto {
  /** @format byte */
  approvalCount: number;
  /** @format decimal */
  endAmount?: number;
  /** @format int32 */
  id: number;
  product?: ProductDto;
  /** @format int32 */
  productId: number;
  /** @format decimal */
  startAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  wireProduct?: WireProductDto;
}
export type WireProductDto = BaseEntityDto & {
  allowWireFileUpload?: boolean;
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format int32 */
  beneficiaryAuthenticationTypeId: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  farthestWireExistInDays: number;
  /** @format int32 */
  id: number;
  internationalEnabled: boolean;
  internationalUsdReadOnly: boolean;
  isCompanyDliUsdEnabled: boolean;
  isCompanyWireProductEnabled: boolean;
  isDliCompanyProductEnabled: boolean;
  isFiDliProductEnabled: boolean;
  isFiDliUsdEnabled: boolean;
  isFiInternationalEnabled: boolean;
  isFiWireProductEnabled: boolean;
  isInternationalUsdEnabled: boolean;
  limitFutureDateEnabled: boolean;
  /** @format int32 */
  maximumFutureDays?: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType?: number;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  product?: ProductDto;
  requirePaymentPurpose?: boolean;
  requirePaymentPurposeDli: boolean;
  /** @format byte */
  templateApprovalCount?: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  useExternalFxWireProcessing: boolean;
  wirePaymentApprovalSettings?: WirePaymentApprovalSettingDto[];
};
export type ResourceConfigurationDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  displayExternalWarning: boolean;
  externalWarningMessage?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  link?: string;
  /** @format byte */
  logo?: string;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  sequence?: number;
  title?: string;
  tooltip?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface CompanyAccountDisplaySettingDto {
  accountMaskType?: AccountMaskTypeDto;
  /** @format int32 */
  accountMaskTypeId: number;
  allowOverrideMasking: boolean;
  company?: CompanyDto;
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  showAccountName: boolean;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
}
export interface AccountMaskTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
}
export type CompanyLimitDto = BaseEntityDto & {
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  monthlyLimit?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CompanyTypeDto = BaseEntityDto & {
  companies?: CompanyDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface CompanyUserApprovalSettingDto {
  boUserRequireBoApproval: boolean;
  chUserRequireBoApproval: boolean;
  chUserRequireChApproval: boolean;
  company?: CompanyDto;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type FileImportLayoutDto = BaseEntityDto & {
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  delimiter?: string;
  fileFormatType?: FileFormatTypeDto;
  /** @format int32 */
  fileFormatTypeId: number;
  fileImportLayoutDetails?: FileImportLayoutDetailDto[];
  /** @format int32 */
  footerRows?: number;
  /** @format int32 */
  headerRows?: number;
  /** @format int32 */
  id: number;
  productType?: ProductTypeDto;
  /** @format int32 */
  productTypeId: number;
  templateName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type FileFormatTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type FileImportLayoutDetailDto = BaseEntityDto & {
  additionalInformationFour?: string;
  additionalInformationOne?: string;
  additionalInformationThree?: string;
  additionalInformationTwo?: string;
  /** @format int32 */
  beginPosition?: number;
  /** @format int32 */
  endPosition?: number;
  fileImportField?: FileImportFieldDto;
  /** @format int32 */
  fileImportFieldId: number;
  fileImportLayout?: FileImportLayoutDto;
  /** @format int32 */
  fileImportLayoutId: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type FileImportFieldDto = BaseEntityDto & {
  description?: string;
  fieldName?: string;
  fileImportFieldGroup?: FileImportFieldGroupDto;
  /** @format int32 */
  fileImportFieldGroupId?: number;
  fileType?: FileTypeDto;
  /** @format int32 */
  fileTypeId: number;
  /** @format int32 */
  id: number;
  info?: string;
  isRequired?: boolean;
};
export type FileImportFieldGroupDto = BaseEntityDto & {
  description?: string;
  fileFormatType?: FileFormatTypeDto;
  fileImportFields?: FileImportFieldDto[];
  /** @format int32 */
  fileTypeId: number;
  groupName?: string;
  /** @format int32 */
  id: number;
  info?: string;
};
export type FileTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type InternalTransferTransactionDto = BaseEntityDto & {
  /** @format decimal */
  amount: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  batchId?: string;
  childInternalTransferTransactions?: InternalTransferTransactionDto[];
  comments?: string;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  confirmationNumber?: string;
  createdBy?: string;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  /** @format date-time */
  endDate?: string;
  errorSummary?: ErrorSummaryDto;
  /** @format int32 */
  firstRepeatOnDay?: number;
  fromAccount?: AccountDto;
  /** @format int32 */
  fromAccountId: number;
  hasNoEndDate?: boolean;
  /** @format int32 */
  id: number;
  internalTransferAudits?: InternalTransferAuditDto[];
  internalTransferStatusType?: InternalTransferStatusTypeDto;
  /** @format int32 */
  internalTransferStatusTypeId: number;
  internalTransferTemplate?: InternalTransferTemplateDto;
  /** @format int32 */
  internalTransferTemplateId?: number;
  isEdited: boolean;
  /** @format date-time */
  lastEditedDate: string;
  memo?: string;
  message?: string;
  messageType?: string;
  /** @format int32 */
  numberOfApprovalNeeded?: number;
  parentInternalTransferTransaction?: InternalTransferTransactionDto;
  /** @format int32 */
  parentInternalTransferTransactionId?: number;
  paymentFrequencyType?: PaymentFrequencyTypeDto;
  /** @format int32 */
  paymentFrequencyTypeId: number;
  paymentOptionType?: PaymentOptionTypeDto;
  /** @format int32 */
  paymentOptionTypeId?: number;
  permissions?: ProductPermissionDto[];
  repeatOnLastBusinessDay?: boolean;
  /** @format int32 */
  seconRepeatOnDay?: number;
  /** @format date-time */
  startDate?: string;
  submittedToScheduler: boolean;
  toAccount?: AccountDto;
  /** @format int32 */
  toAccountId: number;
  transactionId?: string;
  /** @format date-time */
  transferDate?: string;
  updatedBy?: string;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
};
export interface InternalTransferAuditDto {
  comments?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  internalTransferActionType?: InternalTransferActionTypeDto;
  /** @format int32 */
  internalTransferActionTypeId: number;
  /** @format int32 */
  internalTransferAuditId: number;
  internalTransferTransaction?: InternalTransferTransactionDto;
  /** @format int32 */
  internalTransferTransactionId: number;
  user?: UserDto;
}
export interface InternalTransferActionTypeDto {
  description?: string;
  /** @format int32 */
  internalTransferActionTypeId: number;
  internalTransferAudits?: InternalTransferAuditDto[];
  name?: string;
}
export interface InternalTransferStatusTypeDto {
  description?: string;
  /** @format int32 */
  internalTransferStatusTypeId: number;
  internalTransferTransactions?: InternalTransferTransactionDto[];
  name?: string;
}
export type InternalTransferTemplateDto = BaseEntityDto & {
  /** @format int32 */
  approvalCount: number;
  company?: CompanyDto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  fromAccountNumber?: string;
  /** @format int32 */
  id: number;
  internalTransferTemplateAudits?: InternalTransferTemplateAuditDto[];
  internalTransferTemplateDetails?: InternalTransferTemplateDetailDto[];
  internalTransferTemplateStatusType?: InternalTransferTemplateStatusTypeDto;
  /** @format int32 */
  internalTransferTemplateStatusTypeId: number;
  internalTransferType?: InternalTransferTypeDto;
  /** @format int32 */
  internalTransferTypeId: number;
  oneToOneMemo?: string;
  templateAccountDetails?: AccountDto[];
  templateName?: string;
  toAccountNumber?: string;
  /** @format decimal */
  totalAmount: number;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export interface InternalTransferTemplateAuditDto {
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  internalTransferTemplate?: InternalTransferTemplateDto;
  internalTransferTemplateActionType?: InternalTransferTemplateActionTypeDto;
  /** @format int32 */
  internalTransferTemplateActionTypeId: number;
  /** @format int32 */
  internalTransferTemplateId: number;
}
export interface InternalTransferTemplateActionTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InternalTransferTemplateDetailDto {
  /** @format decimal */
  amount: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  fromAccount?: AccountDto;
  /** @format int32 */
  fromAccountId: number;
  /** @format int32 */
  id: number;
  internalTransferTemplate?: InternalTransferTemplateDto;
  /** @format int32 */
  internalTransferTemplateId: number;
  memo?: string;
  toAccount?: AccountDto;
  /** @format int32 */
  toAccountId: number;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
}
export interface InternalTransferTemplateStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InternalTransferTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type PaymentOptionTypeDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type UserAccountDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  user?: UserDto;
  userAccountLimits?: UserAccountLimitDto[];
  /** @format int32 */
  userId: number;
};
export type UserAccountLimitDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  perTransactionLimit: number;
  productType?: ProductTypeDto;
  /** @format int32 */
  productTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccount?: UserAccountDto;
  /** @format int32 */
  userAccountId: number;
};
export interface InstitutionAccountDisplaySettingDto {
  accountMaskType?: AccountMaskTypeDto;
  /** @format int32 */
  accountMaskTypeId: number;
  allowOverrideMasking: boolean;
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  showAccountName: boolean;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionAffiliationDto {
  /** @format int32 */
  affiliatedInstitutionId: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionFraudProviderDto {
  institution?: InstitutionDto;
  provider?: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: UserDto;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionMessageCenterDto = BaseEntityDto & {
  archiveSetting?: ArchiveSettingDto;
  /** @format int32 */
  archiveSettingId: number;
  disclaimer?: string;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  sharedEmail?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: UserDto;
  useSharedEmail: boolean;
};
export interface ArchiveSettingDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InstitutionNachaFileRecordDto {
  addendaRecordIndicator?: string;
  blockingFactor?: string;
  checkDigit?: string;
  fileIdModifier?: string;
  formatCode?: string;
  /** @format int32 */
  id: number;
  immediateDestination?: string;
  immediateDestinationName?: string;
  immediateOrigin?: string;
  immediateOriginName?: string;
  institution?: InstitutionDto;
  originatingDfi?: string;
  originatorStatusCode?: string;
  priorityCode?: string;
  receivingDfi?: string;
  recordSize?: string;
}
export interface InstitutionOobConfigurationDto {
  allowedOobChallengeFailureCount: number;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  oobChallengePinNeeded: boolean;
  oobEnabled: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionTermDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  requireAcceptance: boolean;
  termsAndConditions?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface InstitutionUserApprovalSettingDto {
  boUserRequireBoApproval: boolean;
  chUserRequireBoApproval: boolean;
  chUserRequireChApproval: boolean;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionWireInternationalWorkflowOverridesDto = BaseEntityDto & {
  allowAccountBalanceValidation: boolean;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  forceInternationalUsdViaForeignCurrencyWorkflow: boolean;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  useExternalFxQuoteRatesheets: boolean;
  useExternalFxWireProcessing: boolean;
  useNonCoreWireCompanies: boolean;
};
export type LoginConfigurationDto = BaseEntityDto & {
  allowAlphabetsInLoginId: boolean;
  allowAlphabetsInPassword: boolean;
  allowLockedOutMessage: boolean;
  allowNumbersInLoginId: boolean;
  allowNumbersInPassword: boolean;
  allowSpecialCharactersInLoginId: boolean;
  allowSpecialCharactersInPassword: boolean;
  alphabetsInLoginIdAreRequired: boolean;
  alphabetsInPasswordAreRequired: boolean;
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  invalidLoginIdPasswordMessage?: string;
  isCompany: boolean;
  isDefault: boolean;
  lockedEmailMessage?: string;
  lockedOutMessage?: string;
  /** @format int32 */
  loginIdMaxLength: number;
  /** @format int32 */
  loginIdMinLength: number;
  /** @format int32 */
  numberOfAllowedAttemptsBeforeLock: number;
  /** @format int32 */
  numberOfLowercaseRequiredInLoginId: number;
  /** @format int32 */
  numberOfLowercaseRequiredInPassword: number;
  /** @format int32 */
  numberOfPrevUsedPasswords: number;
  /** @format int32 */
  numberOfUppercaseRequiredInLoginId: number;
  /** @format int32 */
  numberOfUppercaseRequiredInPassword: number;
  numbersInLoginIdAreRequired: boolean;
  numbersInPasswordAreRequired: boolean;
  /** @format int32 */
  passwordExpireInDays: number;
  /** @format int32 */
  passwordMaxLength: number;
  /** @format int32 */
  passwordMinLength: number;
  sendLockedEmail: boolean;
  /** @format int32 */
  sessionTimeoutMinutes: number;
  specialCharactersInLoginIdAreRequired: boolean;
  specialCharactersInPasswordAreRequired: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  warnPasswordExpireInDays: number;
  willPasswordExpire: boolean;
};
export type TransactionCodeGroupDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  institution?: InstitutionDto;
  /** @format int32 */
  institutionId: number;
  isReadOnly: boolean;
  name?: string;
  transactionCodeGroupAssociations?: TransactionCodeGroupAssociationDto[];
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type TransactionCodeGroupAssociationDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  transactionCode?: TransactionCodeDto;
  transactionCodeGroup?: TransactionCodeGroupDto;
  /** @format int32 */
  transactionCodeGroupId: number;
  /** @format int32 */
  transactionCodeId: number;
};
export type OfxTransactionTypeDto = BaseEntityDto & {
  description?: string;
  displayName?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchFederalTaxTemplateDto = BaseEntityDto & {
  achFederalTaxTemplateAmounts?: AchFederalTaxTemplateAmountDto[];
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountTypeDto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  taxCode?: string;
  taxPayerId?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchFederalTaxTemplateAmountDto = BaseEntityDto & {
  /** @format int32 */
  achBatchId: number;
  achFederalAmountType?: AchFederalAmountTypeDto;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  /** @format decimal */
  amount: number;
  amountType?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchStateTaxTemplateDto = BaseEntityDto & {
  achStateTaxTemplateAmounts?: AchStateTaxTemplateAmountDto[];
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountTypeDto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  state?: StateDto;
  /** @format int32 */
  stateId: number;
  taxCode?: string;
  taxPayerId?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchStateTaxTemplateAmountDto = BaseEntityDto & {
  /** @format int32 */
  achBatchId: number;
  achStateAmountType?: AchStateAmountTypeDto;
  /** @format int32 */
  achStateAmountTypeId: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export interface AchStateAmountTypeDto {
  amountType?: string;
  description?: string;
  /** @format int32 */
  id: number;
}
export type BatchStatusTypeDto = BaseEntityDto & {
  achBatches?: AchBatchDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchCompanySecCodeDto = BaseEntityDto & {
  achCompany?: AchCompanyDto;
  achSecCode?: AchSecCodeDto;
  /** @format int32 */
  achSecCodeId: number;
  /** @format int32 */
  companyId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
};
export type UserAchCompanyDto = BaseEntityDto & {
  achCompany?: AchCompanyDto;
  /** @format int32 */
  achCompanyId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  perTransactionLimit?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type ArpExceptionDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  activityIntentKey?: string;
  arpDecision?: ArpDecisionDto;
  /** @format int32 */
  arpDecisionId?: number;
  checkImageNumber?: string;
  checkNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  /** @format date-time */
  decisionDate?: string;
  fromworkStation?: string;
  /** @format int32 */
  id: number;
  isDebit: boolean;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  issuedDate?: string;
  /** @format decimal */
  paidAmount?: number;
  payee?: string;
  posPayItemId?: string;
  protected?: string;
  reason?: string;
  resolved?: string;
  sourceOfEntry?: string;
  transactionId?: string;
};
export interface ArpDecisionDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type NonCoreWireCompanyInternationalAccountDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  nonCoreWireCompanyInternational?: NonCoreWireCompanyInternationalDto;
  /** @format decimal */
  perTransactionLimit: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  wireCompanyId: number;
};
export type NonCoreWireCompanyInternationalDto = BaseEntityDto & {
  /** @format int32 */
  availableBalanceValidationCoreTable?: number;
  company?: CompanyDto;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccountDto[];
  /** @format decimal */
  perTransactionLimit: number;
  rowStatus?: RowStatusDto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  wireInternationalBalanceValidationType?: WireInternationalBalanceValidationTypeDto;
  /** @format int32 */
  wireInternationalBalanceValidationTypeId?: number;
};
export type WireInternationalBalanceValidationTypeDto = BaseEntityDto & {
  /** @format int32 */
  id: number;
  name?: string;
};
export type StopPaymentDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  /** @format double */
  amount?: number;
  /** @format int32 */
  approvalCount: number;
  /** @format int32 */
  approvalsCompleted: number;
  approvedBy?: TupleOfstringdateTimeDto[];
  /** @format date-time */
  checkDate?: string;
  confirmationNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  /** @format int64 */
  endCheckNumber?: number;
  enteredBy?: string;
  /** @format date-time */
  expireDate?: string;
  /** @format int32 */
  id: number;
  payee?: string;
  remarks?: string;
  /** @format int64 */
  startCheckNumber: number;
  stopPaymentAudits?: StopPaymentAuditDto[];
  stopPaymentStatusType?: StopPaymentStatusTypeDto;
  /** @format int32 */
  stopPaymentStatusTypeId: number;
  transactionId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export type StopPaymentAuditDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  remarks?: string;
  stopPayment?: StopPaymentDto;
  stopPaymentAuditAction?: StopPaymentAuditActionDto;
  /** @format int32 */
  stopPaymentAuditActionId: number;
  /** @format int32 */
  stopPaymentId: number;
};
export type StopPaymentAuditActionDto = BaseEntityDto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  stopPaymentAudits?: StopPaymentAuditDto[];
};
export interface StopPaymentStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type UserAccountGroupAccountDto = BaseEntityDto & {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  sequence: number;
  userAccountGroup?: UserAccountGroupDto;
  /** @format int32 */
  userAccountGroupId: number;
};
export type UserAccountGroupDto = BaseEntityDto & {
  accountGroupName?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format byte */
  sequency: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  userAccountGroupAccounts?: UserAccountGroupAccountDto[];
  /** @format int32 */
  userId: number;
};
export type TextMessagingCarrierDto = BaseEntityDto & {
  active: boolean;
  /** @format int32 */
  id: number;
  name?: string;
  providerId?: string;
  serviceId?: string;
  serviceTypeId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type UserAuditDto = BaseEntityDto & {
  comment?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserDeviceDto = BaseEntityDto & {
  authenticationId?: string;
  /** @format int32 */
  id: number;
  key?: string;
  /** @format date-time */
  keyCreatedDate: string;
  /** @format date-time */
  keyLastUsedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserIpAccessSettingDto = BaseEntityDto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  restricted?: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserIpAccessDto = BaseEntityDto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  ipAddress?: string;
  nickname?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserNotificationDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isEmailSent: boolean;
  isRead: boolean;
  isTextMessageSent: boolean;
  notification?: NotificationDto;
  /** @format int32 */
  notificationId: number;
  notificationStatusType?: NotificationStatusTypeDto;
  /** @format int32 */
  notificationStatusTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export interface NotificationStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type UserPasswordHistoryDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  password?: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserTimeAccessSettingDto = BaseEntityDto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  restricted?: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
};
export type UserTimeAccessDto = BaseEntityDto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  dayOfWeekId: number;
  /** @format duration */
  endTime?: string;
  /** @format int32 */
  id: number;
  /** @format duration */
  startTime?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
  /** @format int32 */
  userId: number;
  userTimeAccessTypeId: number;
};
export type UserWidgetDto = BaseEntityDto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  height: number;
  /** @format int32 */
  id: number;
  positionLeft: number;
  positionTop: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId: number;
  widget?: WidgetDto;
  /** @format int32 */
  widgetId: number;
  width: number;
};
export type WidgetDto = BaseEntityDto & {
  defaultContent?: string;
  defaultHeight?: number;
  defaultLeft?: number;
  defaultTop?: number;
  defaultWidth?: number;
  description?: string;
  hasPermission: boolean;
  /** @format int32 */
  id: number;
  imageUrl?: string;
  /** @format int32 */
  institutionId: number;
  isFullScreenAllowed: boolean;
  isPermissionRequired: boolean;
  isRequired: boolean;
  isResizable: boolean;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  name?: string;
  permissionMessage?: string;
  title?: string;
  userWidgets?: UserWidgetDto[];
};
export interface AchNachaFileCopyAuditDto {
  achPayment?: AchPaymentDto;
  /** @format int32 */
  achPaymentId: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: UserDto;
  /** @format date-time */
  createdDate: string;
  fileCopyStatus?: string;
  /** @format int32 */
  id: number;
  message?: string;
  nachaFileName?: string;
}
export interface AchPaymentTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface AchStateTaxPaymentDto {
  achStateTaxPaymentAmounts?: AchStateTaxPaymentAmountDto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  payFromAccount?: AccountDto;
  /** @format int32 */
  payFromAccountId?: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountTypeDto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  state?: StateDto;
  /** @format int32 */
  stateId?: number;
  taxCode?: string;
  taxPayerId?: string;
  /** @format date-time */
  taxPeriodEndDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchStateTaxPaymentAmountDto {
  /** @format int32 */
  achPaymentId: number;
  achStateAmountType?: AchStateAmountTypeDto;
  /** @format int32 */
  achStateAmountTypeId: number;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface BatchBalanceTypeDto {
  achCompanies?: AchCompanyDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface ArpReportFileDto {
  account?: AccountDto;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  createdDate: string;
  fileContent?: string;
  fileName?: string;
  /** @format int32 */
  id: number;
}
export interface CoreAccountDto {
  cifNumber?: string;
  coreStatus?: string;
  number?: string;
  type?: string;
}
export interface AccountProductArpDto {
  accountProduct?: AccountProductDto;
  hasAchException: boolean;
  hasAchFilter: boolean;
  hasCheckException: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface PendingEditOfUserDto {
  comment?: string;
  company?: CompanyDto;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isNew: boolean;
  pendingEditInJson?: string;
  /** @format int32 */
  pendingEditStatusId: number;
  /** @format int32 */
  pendingEditTableId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId: number;
}
export enum PendingEditStatusDto {
  Edited = 1,
  Pending = 2,
  Approved = 3,
  Discard = 4,
  Rejected = 5,
  FiPending = 6,
}
export interface UserSecureTokenSecurityDto {
  challengePin?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isLocked: boolean;
  /** @format date-time */
  localSuspendEndDate?: string;
  needCollection: boolean;
  remindMeCount: number;
  /** @format date-time */
  suspendEndDate?: string;
  tokenAuthFailureCount: number;
  tokenCredentialId?: string;
  tokenVendorUserId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: UserDto;
}
export type CheckLoginCompletionRequestDto = BaseRequestDto & object;
export type GetUserOobConfigRequestDto = BaseRequestDto & object;
export type VerifyOobMethodRequestDto = BaseRequestDto & {
  method?: string;
  phoneNumber?: string;
  pinNumber?: string;
  useForBoth: boolean;
};
export type CheckSecurityRequestDto = BaseRequestDto & {
  actionType?: string;
  errorCode?: string;
  hasAlternate: boolean;
  message?: string;
  methodUsed?: string;
  oneTimePassword?: string;
  sendMessage?: string;
  status?: string;
};
export type SetPreferredMethodRequestDto = BaseRequestDto & {
  methodType?: string;
};
export type RemoveMethodRequestDto = BaseRequestDto & {
  methodType?: string;
};
export type CompleteOobConfigRequestDto = BaseRequestDto & {
  action?: string;
};
export type CompleteSecureTokenConfigRequestDto = BaseRequestDto & {
  actionType: CompleteSecureTokenConfigActionTypeDto;
  challengePin?: string;
  credentialId?: string;
  token?: string;
};
export enum CompleteSecureTokenConfigActionTypeDto {
  Undefined = 0,
  RemindMeLater = 1,
  StopReminders = 2,
  Register = 3,
}
export type GetSecureTokenConfigRequestDto = BaseRequestDto & object;
export type SendOutOfBandOneTimePasswordRequestDto = BaseRequestDto & {
  challengePassword?: string;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  institutionName: number;
  phoneNumber?: string;
  /** @format int32 */
  userId: number;
  validationMode?: string;
};
export type DeviceRequestDto = BaseRequestDto & {
  /** @format guid */
  connectionId?: string;
};
export type LogOffRequestDto = BaseRequestDto & {
  reason?: string;
};
export type GenerateAuthenticationTokenRequestDto = BaseRequestDto & {
  authenticationTokenType: AuthenticationTokenTypeDto;
};
export enum AuthenticationTokenTypeDto {
  Undefined = 0,
  CashManagement = 1,
  NetTeller = 2,
  Esi = 3,
  SingleSignOn = 4,
}
export type ValidateOnetimePasscodeRequestDto = BaseRequestDto & {
  /** @format int32 */
  companyId: number;
  onetimePasscode?: string;
  /** @format int32 */
  userId: number;
};
export type ElectronicDocumentRequestDto = BaseRequestDto & object;
export type RdcSsoLoginRequestDto = BaseRequestDto & object;
export type GetCentrixSsoLoginRequestDto = BaseRequestDto & object;
export type GetUserIdentityRequestDto = BaseRequestDto & object;
export type LogoutUserRequestDto = BaseRequestDto & {
  reason?: string;
};
export type GetUserSecureTokenMfaLoginStatusRequestDto = BaseRequestDto & object;
export type SecureTokenMfaLoginRequestDto = BaseRequestDto & {
  oneTimePasswordAndPin?: string;
};
export type GetUserSecureTokenMfaCredentialsRequestDto = BaseRequestDto & object;
export type RegisterSecureTokenMfaRequestDto = BaseRequestDto & {
  credentialId?: string;
  pin?: string;
  token?: string;
};
export type UnlockSecureTokenRequestDto = BaseRequestDto & {
  recollectTokenCredentials: boolean;
  /** @format int32 */
  userId: number;
};
export type ResetSecureTokenRequestDto = BaseRequestDto & {
  /** @format int32 */
  userId: number;
};
export type UpdatePasswordRequestDto = BaseRequestDto & {
  isFiUser: boolean;
  password?: string;
};
export type ResetPasswordRequestDto = UpdatePasswordRequestDto & {
  isAutoReset: boolean;
  /** @format int32 */
  userId: number;
};
export type ChangePasswordRequestDto = BaseRequestDto & {
  isFiUser: boolean;
  loggingIn: boolean;
  oldPassword?: string;
  password?: string;
  userAlias?: string;
};
export type BaseMfaRequestDto = BaseRequestDto & {
  imsOrgId?: string;
  multifactorAuthenticationUserData?: MultifactorAuthenticationUserDataDto;
  serviceProviderInfo?: ServiceProviderInfoDto;
  transactionRiskReceiptId?: string;
};
export interface MultifactorAuthenticationUserDataDto {
  acceptLanguage?: string;
  browserAgent?: string;
  colorDepth?: string;
  country?: string;
  /** @format date-time */
  dateLastOnlineBankingPasswordChange: string;
  datePasswordChanged?: string;
  devicePrint?: string;
  ipAddress?: string;
  region?: string;
  screenResolution?: string;
  sessionId?: string;
  timeZone?: string;
  transactionId?: string;
  userCookie?: string;
  userLanguage?: string;
  zipCode?: string;
}
export interface ServiceProviderInfoDto {
  rsaConvVal?: string;
  rsaId?: string;
  rsaOrgId?: string;
}
export type PingRequestDto = BaseMfaRequestDto & {
  message?: string;
};
export type GetSecurityQuestionsRequestDto = BaseMfaRequestDto & {
  questionCount?: string;
  questionGroupCount?: string;
};
export type GetUserSecurityQuestionsRequestDto = BaseMfaRequestDto & {
  /** @format int32 */
  questionRequestCount: number;
};
export type SaveUserSecurityQuestionsRequestDto = BaseMfaRequestDto & {
  recordedSecurityQuestionCollection?: RecordedSecurityQuestionCollectionDto;
};
export type RecordedSecurityQuestionCollectionDto = RecordedSecurityQuestionDto[];
export type ValidateChallengeRequestDto = BaseMfaRequestDto & {
  recordedSecurityQuestionCollection?: RecordedSecurityQuestionCollectionDto;
};
export type GetMfaIdRequestDto = BaseMfaRequestDto & {
  /** @format int32 */
  companyId?: number;
  /** @format int32 */
  userId: number;
};
export type RiskRequestDto = BaseMfaRequestDto & {
  companyId?: string;
  isEditUser: boolean;
  transactionCollection?: TransactionCollectionDto;
  userId?: string;
};
export type TransactionCollectionDto = TransactionDto[];
export interface TransactionDto {
  amount?: string;
  customRiskEventDescription?: string;
  customRiskEventType: CustomRiskEventTypeDto;
  fromAccount?: MfaAccountDto;
  riskEventType: RiskEventTypeDto;
  toAccount?: MfaAccountDto;
}
export enum CustomRiskEventTypeDto {
  Undefined = 0,
  ACHBatchInitiation = 1,
  AddWire = 2,
  ARPDownload = 3,
  ARPUpload = 4,
  CMUserDownload = 5,
  DownloadACHBatch = 6,
  DownloadTransactions = 7,
  EditWire = 8,
  FailedWirePIN = 9,
  IBTAddForeignAccount = 10,
  IBTValidateForeignAccounts = 11,
  IBTSaveTransfer = 12,
  ModifyEntitlements = 13,
  StopPaymentsAdded = 14,
  StopPaymentsDeleted = 15,
  TaxPaymentInitiation = 16,
  Transfer = 17,
  UploadACHBatch = 18,
  ViewACHHistory = 19,
  ViewPowerPayHistory = 20,
  ViewPriorDay = 21,
  ViewTransactions = 22,
  ViewWireHistory = 23,
  WireTransfer = 24,
  AddNewPayment = 25,
  EditPayment = 26,
  QuickPaymentNew = 27,
  QuickPaymentEdit = 28,
}
export interface MfaAccountDto {
  accountType: AccountType1Dto;
  id?: string;
}
export enum AccountType1Dto {
  UNDEFINED = 0,
  CREDIT_CARD = 1,
  DEBIT_CARD = 2,
  CHECKING = 3,
  CHECKING_WITH_OVERDRAFT = 4,
  SAVINGS = 5,
  CD = 6,
  LINE_OF_CREDIT = 7,
  RETIREMENT = 8,
  MORTGAGE = 9,
  BILLER = 10,
  PERSONAL_ACCOUNT = 11,
}
export enum RiskEventTypeDto {
  Undefined = 0,
  ActivateCard = 1,
  AddPayee = 2,
  ChangeAddress = 3,
  ChangeAlertSettings = 4,
  ChangeChallengeQuestion = 5,
  ChangeEmail = 6,
  ChangeLogOnId = 7,
  ChangePassword = 8,
  ChangePhone = 9,
  ChangeStatementSettings = 10,
  Deposit = 11,
  EditPayee = 12,
  ExtraAuth = 13,
  FailedChangePasswordAttempt = 14,
  FailedOpenNewAccount = 15,
  FailedLogOnAttempt = 16,
  FailedOlbEnrollAttempt = 17,
  LogOn = 18,
  OlbEnroll = 19,
  OpenNewAccount = 20,
  OptionsTrade = 21,
  Payment = 22,
  ReadSecureMessage = 23,
  RequestCheckCopy = 24,
  RequestChecks = 25,
  RequestCredit = 26,
  RequestNewCard = 27,
  RequestNewPin = 28,
  RequestStatementCopy = 29,
  SendSecureMessage = 30,
  StockTrade = 31,
  UserDefined = 32,
  UserDetails = 33,
  ChangeUserAuthenticationInfo = 34,
  ViewCheck = 35,
  ViewStatement = 36,
}
export type AddRiskEventRequestDto = RiskRequestDto & {
  externalAuthStat: boolean;
  externalAuthType: ExternalAuthTypeDto;
};
export enum ExternalAuthTypeDto {
  Undefined = 0,
  OOBVoice = 1,
  OOBSMS = 2,
  CustomV11Challenge = 3,
}
export type ResponseDetailCollectionDto = ResponseDetailDto[];
export interface ResponseDetailDto {
  /** @format int32 */
  responseCode: number;
  responseMessage?: string;
}
export type UserChallengeResponseDto = BaseResponseDto & {
  errorSummary?: ErrorSummaryDto;
  isValid: boolean;
  userOobDetail?: UserOobDetailDto;
};
export type CheckLoginCompletionResponseDto = BaseResponseDto & {
  action?: string;
  challengeMethodType?: ChallengeMethodTypeEnumDto;
  isComplete: boolean;
};
export enum ChallengeMethodTypeEnumDto {
  None = 0,
  OutOfBand = 1,
  SecureToken = 2,
  OutOfBandViaEns = 3,
}
export type GetUserOobConfigResponseDto = BaseResponseDto & {
  allowActionBypass: boolean;
  usePin: boolean;
  userOobDetail?: UserOobDetailDto;
};
export type VerifyOobMethodResponseDto = BaseResponseDto & {
  errorSummary?: ErrorSummaryDto;
  isValid: boolean;
  methodUsed?: string;
  userOobDetail?: UserOobDetailDto;
};
export type CheckSecurityResponseDto = BaseResponseDto & {
  actionType?: string;
  challengeMethodType?: ChallengeMethodTypeEnumDto;
  errorCode?: string;
  hasAlternate: boolean;
  message?: string;
  methodUsed?: string;
  sendMessage?: string;
  status?: string;
};
export type SetPreferredMethodResponseDto = BaseResponseDto & object;
export type RemoveMethodResponseDto = BaseResponseDto & object;
export type CompleteOobConfigResponseDto = BaseResponseDto & object;
export type GetSecureTokenConfigResponseDto = BaseResponseDto & {
  allowActionBypass: boolean;
  cancelCollection: boolean;
  credentialId?: string;
};
export type SendOutOfBandOneTimePasswordResponseDto = BaseResponseDto & {
  isValid: boolean;
};
export type DeviceResponseDto = BaseResponseDto & object;
export type LogOffResponseDto = BaseResponseDto & object;
export type GenerateAuthenticationTokenResponseDto = BaseResponseDto & {
  /** @format int32 */
  tokenExpirationInSeconds: number;
};
export type ValidateOnetimePasscodeResponseDto = BaseResponseDto & object;
export type ElectronicDocumentResponseDto = BaseResponseDto & {
  documentType: ElectronicDocumentTypeEnumDto;
  electronicDocumentServiceName?: string;
  ssoFormPostInputs?: Record<string, string>;
  ssoUrl?: string;
};
export enum ElectronicDocumentTypeEnumDto {
  Undefined = 0,
  PrintMail = 1,
  Esi = 2,
  Wausau = 3,
}
export type RdcSsoLoginResponseDto = BaseResponseDto & {
  customerId?: string;
  hash?: string;
  hashAlgorithmName?: string;
  /** @format int32 */
  remoteDepositProductTypeId: number;
  ssoLoginUrl?: string;
  /** @format date-time */
  timestamp?: string;
  userId?: string;
};
export type GetCentrixSsoLoginResponseDto = BaseResponseDto & {
  ssoLoginUrl?: string;
};
export type GetUserIdentityResponseDto = BaseResponseDto & {
  /** @format int32 */
  companyId: number;
  fiId?: string;
  /** @format int32 */
  userId: number;
};
export type LogoutUserResponseDto = BaseResponseDto & object;
export type GetUserSecureTokenMfaLoginStatusResponseDto = BaseResponseDto & {
  status: UserSecureTokenMfaLoginStatusDto;
};
export enum UserSecureTokenMfaLoginStatusDto {
  NoChallengeRequired = 1,
  NeedCredentialRegistration = 2,
  NeedChallenge = 3,
  AlreadySuccessfullyChallenged = 4,
  Locked = 5,
}
export type SecureTokenMfaLoginResponseDto = BaseResponseDto & {
  displayableMessage?: string;
  status: SecureTokenMfaLoginResultStatusDto;
};
export enum SecureTokenMfaLoginResultStatusDto {
  Success = 1,
  Locked = 2,
  Failure = 3,
  InvalidCredentialState = 4,
}
export type GetUserSecureTokenMfaCredentialsResponseDto = BaseResponseDto & {
  credentialId?: string;
  shouldCancelCollection: boolean;
};
export type RegisterSecureTokenMfaResponseDto = BaseResponseDto & {
  wasCredentialRegistered: boolean;
};
export type UnlockSecureTokenResponseDto = BaseResponseDto & object;
export type ResetSecureTokenResponseDto = BaseResponseDto & object;
export type ResetPasswordResponseDto = BaseResponseDto & {
  isValid: boolean;
};
export type ChangePasswordResponseDto = BaseResponseDto & {
  messages?: string[];
  statusCode?: string;
};
export type BaseMfaResponseDto = BaseResponseDto & {
  transactionRiskReceiptId?: string;
};
export type PingResponseDto = BaseMfaResponseDto & {
  pong?: string;
};
export type GetSecurityQuestionsResponseDto = BaseMfaResponseDto & {
  securityQuestionCollection?: SecurityQuestionCollectionDto;
};
export type SecurityQuestionCollectionDto = SecurityQuestionDto[];
export type GetUserSecurityQuestionsResponseDto = BaseMfaResponseDto & {
  securityQuestionCollection?: SecurityQuestionCollectionDto;
};
export type SaveUserSecurityQuestionsResponseDto = BaseMfaResponseDto & object;
export type ValidateChallengeResponseDto = BaseMfaResponseDto & {
  /** @format int32 */
  questionsSuccessCount: number;
  validateChallengeFailType: ValidateChallengeFailTypeDto;
};
export enum ValidateChallengeFailTypeDto {
  Undefined = 0,
  Unknown = 1,
  Locked = 2,
}
export type RiskResponseDto = BaseMfaResponseDto & {
  multifactorAuthenticationResponseCode: MultifactorAuthenticationResponseCodeDto;
  riskScore?: string;
};
export enum MultifactorAuthenticationResponseCodeDto {
  Undefined = 0,
  Allow = 30000,
  Decline = 30100,
  Challenge = 30200,
  OutOfBandPhoneChallenge = 30300,
  QuestionChallenge = 30400,
  Collect = 30500,
  Review = 30600,
  StopAndReview = 30700,
  DelayAndReview = 30800,
  UserLocked = 30900,
}
export type GetMfaIdResponseDto = BaseMfaResponseDto & {
  mfaId?: string;
};
export type AddRiskEventResponseDto = BaseMfaResponseDto & object;
export interface CompleteSecureTokenConfigRequestModelDto {
  /** @format int32 */
  actionType: number;
  credentialId?: string;
  token?: string;
  pin?: string;
}
export interface GetSecureTokenConfigResponseModelDto {
  success: boolean;
  cancelCollection: boolean;
  credentialId?: string;
  allowActionBypass: boolean;
}
export interface ValidateOneTimePasswordResponseModelDto {
  status?: string;
}
export interface ValidateSecureTokenRequestModelDto {
  token?: string;
}
export type AccountTypeModelDto = ModelBaseDto & {
  name?: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  description: string;
  /**
   * @minLength 0
   * @maxLength 25
   */
  reportingType?: string;
};
export interface TermsAndConditionsModelDto {
  text?: string;
  /** @format date-time */
  lastAcceptedDate?: string;
  /** @format date-time */
  updatedDate: string;
  requireUsersAcceptanceAfterEdit: boolean;
  endUserLicenseAgreementText?: string;
}
/**
 * The transaction code group controller.
 *
 */
export type TransactionCodeGroupControllerDto = ApiBaseControllerDto & object;
/**
 * Base controller used by Channel API endpoints.
 *
 */
export type ApiBaseControllerDto = ApiControllerDto & object;
/** Defines properties and methods for API controller. */
export interface ApiControllerDto {
  /** Gets the HttpConfiguration of the current ApiController. */
  configuration?: HttpConfigurationDto;
  /** Gets the HttpConfiguration of the current ApiController. */
  controllerContext?: HttpControllerContextDto;
  /** Gets the action context. */
  actionContext?: HttpActionContextDto;
  /** Gets the model state after the model binding process. */
  modelState?: ModelStateDictionaryDto;
  /** Gets or sets the HttpRequestMessage of the current ApiController. */
  request?: HttpRequestMessageDto;
  /** Gets the request context. */
  requestContext?: HttpRequestContextDto;
  /** Gets an instance of a UrlHelper, which is used to generate URLs to other APIs.  */
  url?: UrlHelperDto;
  /** Returns the current principal associated with this request. */
  user?: IPrincipalDto;
}
/** Represents a configuration of HttpServer instances.  */
export interface HttpConfigurationDto {
  /** Gets or sets the action that will perform final initialization of the HttpConfiguration instance before it is used to process requests.  */
  initializer?: ActionOfHttpConfigurationDto;
  /** Gets the list of filters that apply to all requests served using this HttpConfiguration instance. */
  filters?: FilterInfoDto[];
  /** Gets an ordered list of DelegatingHandler instances to be invoked as an HttpRequestMessage travels up the stack and an HttpResponseMessage travels down in stack in return.  */
  messageHandlers?: DelegatingHandlerDto[];
  /** Gets the HttpRouteCollection associated with this HttpConfiguration instance. */
  routes?: IHttpRouteDto[];
  /** Gets the properties associated with this instance. */
  properties?: Record<string, any>;
  /** Gets the root virtual path. */
  virtualPathRoot?: string;
  /** Gets or sets the dependency resolver associated with thisinstance. */
  dependencyResolver?: IDependencyResolverDto;
  /** Gets the container of default services associated with this instance. */
  services?: ServicesContainerDto;
  /** Gets the collection of rules for how parameters should be bound. */
  parameterBindingRules?: ParameterBindingRulesCollectionDto;
  /** Gets or sets a value indicating whether error details should be included in error messages. */
  includeErrorDetailPolicy: IncludeErrorDetailPolicyDto;
  /** Gets the media-type formatters for this instance. */
  formatters?: MediaTypeFormatterCollectionDto;
}
export type ActionOfHttpConfigurationDto = MulticastDelegateDto & object;
export type MulticastDelegateDto = DelegateDto & object;
export interface DelegateDto {
  _target?: any;
  _methodBase?: any;
  _methodPtr: IntPtrDto;
  _methodPtrAux: IntPtrDto;
  Method?: MethodInfoDto;
  Target?: any;
}
export type IntPtrDto = object;
export type MethodInfoDto = MethodBaseDto & {
  memberType: MemberTypesDto;
  returnType?: string;
  returnParameter?: ParameterInfoDto;
};
export enum MemberTypesDto {
  Constructor = 1,
  Event = 2,
  Field = 4,
  Method = 8,
  Property = 16,
  TypeInfo = 32,
  Custom = 64,
  NestedType = 128,
  All = 191,
}
export interface ParameterInfoDto {
  parameterType?: string;
  name?: string;
  hasDefaultValue: boolean;
  defaultValue?: any;
  rawDefaultValue?: any;
  /** @format int32 */
  position: number;
  attributes: ParameterAttributesDto;
  member?: MemberInfoDto;
  isIn: boolean;
  isOut: boolean;
  isLcid: boolean;
  isRetval: boolean;
  isOptional: boolean;
  /** @format int32 */
  metadataToken: number;
  customAttributes?: CustomAttributeDataDto[];
}
export enum ParameterAttributesDto {
  None = 0,
  In = 1,
  Out = 2,
  Lcid = 4,
  Retval = 8,
  Optional = 16,
  HasDefault = 4096,
  HasFieldMarshal = 8192,
  Reserved3 = 16384,
  Reserved4 = 32768,
  ReservedMask = 61440,
}
export interface MemberInfoDto {
  customAttributes?: CustomAttributeDataDto[];
  /** @format int32 */
  metadataToken: number;
  module?: ModuleDto;
}
export interface CustomAttributeDataDto {
  attributeType?: string;
  constructor?: ConstructorInfoDto;
  constructorArguments?: CustomAttributeTypedArgumentDto[];
  namedArguments?: CustomAttributeNamedArgumentDto[];
}
export type ConstructorInfoDto = MethodBaseDto & {
  memberType: MemberTypesDto;
};
export type MethodBaseDto = MemberInfoDto & {
  methodImplementationFlags: MethodImplAttributesDto;
  callingConvention: CallingConventionsDto;
  isGenericMethodDefinition: boolean;
  containsGenericParameters: boolean;
  isGenericMethod: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  isPublic: boolean;
  isPrivate: boolean;
  isFamily: boolean;
  isAssembly: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isStatic: boolean;
  isFinal: boolean;
  isVirtual: boolean;
  isHideBySig: boolean;
  isAbstract: boolean;
  isSpecialName: boolean;
  isConstructor: boolean;
};
export enum MethodImplAttributesDto {
  IL = 0,
  Managed = 0,
  Native = 1,
  OPTIL = 2,
  Runtime = 3,
  CodeTypeMask = 3,
  Unmanaged = 4,
  ManagedMask = 4,
  NoInlining = 8,
  ForwardRef = 16,
  Synchronized = 32,
  NoOptimization = 64,
  PreserveSig = 128,
  AggressiveInlining = 256,
  SecurityMitigations = 1024,
  InternalCall = 4096,
  MaxMethodImplVal = 65535,
}
export enum CallingConventionsDto {
  Standard = 1,
  VarArgs = 2,
  Any = 3,
  HasThis = 32,
  ExplicitThis = 64,
}
export interface CustomAttributeTypedArgumentDto {
  argumentType?: string;
  value?: any;
}
export interface CustomAttributeNamedArgumentDto {
  memberInfo?: MemberInfoDto;
  typedValue: CustomAttributeTypedArgumentDto;
  memberName?: string;
  isField: boolean;
}
export interface ModuleDto {
  CustomAttributes?: CustomAttributeDataDto[];
  /** @format int32 */
  MDStreamVersion: number;
  FullyQualifiedName?: string;
  /** @format guid */
  ModuleVersionId: string;
  /** @format int32 */
  MetadataToken: number;
  ScopeName?: string;
  Name?: string;
  Assembly?: AssemblyDto;
  ModuleHandle: ModuleHandleDto;
}
export interface AssemblyDto {
  CodeBase?: string;
  EscapedCodeBase?: string;
  FullName?: string;
  EntryPoint?: MethodInfoDto;
  ExportedTypes?: string[];
  DefinedTypes?: string[];
  Evidence?: any[];
  PermissionSet?: any[];
  IsFullyTrusted: boolean;
  SecurityRuleSet: SecurityRuleSetDto;
  ManifestModule?: ModuleDto;
  CustomAttributes?: CustomAttributeDataDto[];
  ReflectionOnly: boolean;
  Modules?: ModuleDto[];
  Location?: string;
  ImageRuntimeVersion?: string;
  GlobalAssemblyCache: boolean;
  /** @format int64 */
  HostContext: number;
  IsDynamic: boolean;
}
export enum SecurityRuleSetDto {
  None = 0,
  Level1 = 1,
  Level2 = 2,
}
export interface ModuleHandleDto {
  /** @format int32 */
  mdStreamVersion: number;
}
/** Provides information about the available action filters. */
export interface FilterInfoDto {
  /** Gets or sets an instance of the FilterInfo. */
  instance?: IFilterDto;
  /** Gets or sets the scope FilterInfo. */
  scope: FilterScopeDto;
}
/** Defines the methods that are used in a filter. */
export interface IFilterDto {
  /** Gets or sets a value indicating whether more than one instance of the indicated attribute can be specified for a single program element. */
  allowMultiple: boolean;
}
/** Defines values that specify the order in which filters run within the same filter type and filter order. */
export enum FilterScopeDto {
  Global = 0,
  Controller = 10,
  Action = 20,
}
export type DelegatingHandlerDto = HttpMessageHandlerDto & {
  innerHandler?: HttpMessageHandlerDto;
};
export type HttpMessageHandlerDto = object;
/** IHttpRoute defines the interface for a route expressing how to map an incoming HttpRequestMessage to a particular controller and action.  */
export interface IHttpRouteDto {
  /** Gets the route template describing the URI pattern to match against.   */
  routeTemplate?: string;
  /** Gets the default values for route parameters if not provided by the incoming HttpRequestMessage.  */
  defaults?: Record<string, any>;
  /** Gets the constraints for the route parameters.  */
  constraints?: Record<string, any>;
  /** Gets any additional data tokens not used directly to determine whether a route matches an incoming HttpRequestMessage.  */
  dataTokens?: Record<string, any>;
  /** Gets the message handler that will be the recipient of the request. */
  handler?: HttpMessageHandlerDto;
}
/** Represents a dependency injection container. */
export type IDependencyResolverDto = object;
/** An abstract class that provides a container for services used by ASP.NET Web API. */
export type ServicesContainerDto = object;
/** Collection of functions that can produce a parameter binding for a given parameter.     */
export type ParameterBindingRulesCollectionDto = FuncOfHttpParameterDescriptorAndHttpParameterBindingDto[];
export type FuncOfHttpParameterDescriptorAndHttpParameterBindingDto = MulticastDelegateDto & object;
/** Specifies whether error details, such as exception messages and stack traces, should be included in error messages. */
export enum IncludeErrorDetailPolicyDto {
  Default = 0,
  LocalOnly = 1,
  Always = 2,
  Never = 3,
} /** Collection class that contains MediaTypeFormatter instances.  */
export type MediaTypeFormatterCollectionDto = MediaTypeFormatterDto[] & {
  /** Gets the MediaTypeFormatter to use for XML. */
  XmlFormatter?: XmlMediaTypeFormatterDto;
  /** Gets the MediaTypeFormatter to use for JSON. */
  JsonFormatter?: JsonMediaTypeFormatterDto;
  /** Gets the MediaTypeFormatter to use for application/x-www-form-urlencoded data. */
  FormUrlEncodedFormatter?: FormUrlEncodedMediaTypeFormatterDto;
  WritingFormatters?: MediaTypeFormatterDto[];
};
/** MediaTypeFormatter class to handle Xml.  */
export type XmlMediaTypeFormatterDto = MediaTypeFormatterDto & {
  /**
   * Gets or sets a value indicating whether the XML formatter uses the XmlSerializer as the default serializer, instead of  using the DataContractSerializer.
   * @default false
   */
  useXmlSerializer: boolean;
  /** Gets or sets a value indicating whether to indent elements when writing data. */
  indent: boolean;
  /** Gets the settings to be used while writing. */
  writerSettings?: XmlWriterSettingsDto;
  /**
   * Gets and sets the maximum nested node depth.
   * @format int32
   */
  maxDepth: number;
};
export interface XmlWriterSettingsDto {
  async: boolean;
  encoding?: EncodingDto;
  omitXmlDeclaration: boolean;
  newLineHandling: NewLineHandlingDto;
  newLineChars?: string;
  indent: boolean;
  indentChars?: string;
  newLineOnAttributes: boolean;
  closeOutput: boolean;
  conformanceLevel: ConformanceLevelDto;
  checkCharacters: boolean;
  namespaceHandling: NamespaceHandlingDto;
  writeEndDocumentOnClose: boolean;
  outputMethod: XmlOutputMethodDto;
  doNotEscapeUriAttributes: boolean;
}
export interface EncodingDto {
  bodyName?: string;
  encodingName?: string;
  headerName?: string;
  webName?: string;
  /** @format int32 */
  windowsCodePage: number;
  isBrowserDisplay: boolean;
  isBrowserSave: boolean;
  isMailNewsDisplay: boolean;
  isMailNewsSave: boolean;
  isSingleByte: boolean;
  encoderFallback?: EncoderFallbackDto;
  decoderFallback?: DecoderFallbackDto;
  isReadOnly: boolean;
  /** @format int32 */
  codePage: number;
}
export type EncoderFallbackDto = object;
export type DecoderFallbackDto = object;
export enum NewLineHandlingDto {
  Replace = 0,
  Entitize = 1,
  None = 2,
}
export enum ConformanceLevelDto {
  Auto = 0,
  Fragment = 1,
  Document = 2,
}
export enum NamespaceHandlingDto {
  Default = 0,
  OmitDuplicates = 1,
}
export enum XmlOutputMethodDto {
  Xml = 0,
  Html = 1,
  Text = 2,
  AutoDetect = 3,
} /** Base class to handle serializing and deserializing strongly-typed objects using ObjectContent.  */
export interface MediaTypeFormatterDto {
  /** Gets the mutable collection of media types supported bythis MediaTypeFormatter. */
  supportedMediaTypes?: MediaTypeHeaderValueDto[];
  /** Gets the mutable collection of character encodings supported bythis MediaTypeFormatter. */
  supportedEncodings?: EncodingDto[];
  /** Gets the mutable collection of MediaTypeMapping objects that match HTTP requests to media types. */
  mediaTypeMappings?: MediaTypeMappingDto[];
  /** Gets or sets the IRequiredMemberSelector instance used to determine required members. */
  requiredMemberSelector?: IRequiredMemberSelectorDto;
}
/** An abstract base class used to create an association between HttpRequestMessage or  HttpResponseMessage instances that have certain characteristics  and a specific MediaTypeHeaderValue.   */
export interface MediaTypeMappingDto {
  /** Gets the MediaTypeHeaderValue that is associated with HttpRequestMessage or  HttpResponseMessage instances that have the given characteristics of the  MediaTypeMapping.  */
  mediaType?: MediaTypeHeaderValueDto;
}
/** Defines method that determines whether a given member is required on deserialization. */
export type IRequiredMemberSelectorDto = object;
/** Represents the MediaTypeFormatter class to handle JSON.  */
export type JsonMediaTypeFormatterDto = BaseJsonMediaTypeFormatterDto & {
  /** Gets or sets a value indicating whether to use DataContractJsonSerializer by default.  */
  useDataContractJsonSerializer: boolean;
  /** Gets or sets a value indicating whether to indent elements when writing data.   */
  indent: boolean;
  /**
   * Gets or sets the maximum depth allowed by this formatter.
   * @format int32
   */
  maxDepth: number;
};
/** Abstract media type formatter class to support Bson and Json. */
export type BaseJsonMediaTypeFormatterDto = MediaTypeFormatterDto & {
  /** Gets or sets the JsonSerializerSettings used to configure the JsonSerializer. */
  serializerSettings?: JsonSerializerSettingsDto;
  /**
   * Gets or sets the maximum depth allowed by this formatter.
   * @format int32
   */
  maxDepth: number;
};
/** Specifies the settings on a JsonSerializer object. */
export interface JsonSerializerSettingsDto {
  /**
   * Gets or sets how reference loops (e.g. a class referencing itself) are handled.
   * The default value is Error.
   */
  referenceLoopHandling: ReferenceLoopHandlingDto;
  /**
   * Gets or sets how missing members (e.g. JSON contains a property that isn't a member on the object) are handled during deserialization.
   * The default value is Ignore.
   */
  missingMemberHandling: MissingMemberHandlingDto;
  /**
   * Gets or sets how objects are created during deserialization.
   * The default value is Auto.
   */
  objectCreationHandling: ObjectCreationHandlingDto;
  /**
   * Gets or sets how null values are handled during serialization and deserialization.
   * The default value is Include.
   */
  nullValueHandling: NullValueHandlingDto;
  /**
   * Gets or sets how default values are handled during serialization and deserialization.
   * The default value is Include.
   */
  defaultValueHandling: DefaultValueHandlingDto;
  /** Gets or sets a JsonConverter collection that will be used during serialization. */
  converters: JsonConverterDto[];
  /**
   * Gets or sets how object references are preserved by the serializer.
   * The default value is None.
   */
  preserveReferencesHandling: PreserveReferencesHandlingDto;
  /**
   * Gets or sets how type name writing and reading is handled by the serializer.
   * The default value is None.
   */
  typeNameHandling: TypeNameHandlingDto;
  /**
   * Gets or sets how metadata properties are used during deserialization.
   * The default value is Default.
   */
  metadataPropertyHandling: MetadataPropertyHandlingDto;
  /**
   * Gets or sets how a type name assembly is written and resolved by the serializer.
   * The default value is Simple.
   */
  typeNameAssemblyFormat: FormatterAssemblyStyleDto;
  /**
   * Gets or sets how a type name assembly is written and resolved by the serializer.
   * The default value is Simple.
   */
  typeNameAssemblyFormatHandling: TypeNameAssemblyFormatHandlingDto;
  /**
   * Gets or sets how constructors are used during deserialization.
   * The default value is Default.
   */
  constructorHandling: ConstructorHandlingDto;
  /**
   * Gets or sets the contract resolver used by the serializer when
   * serializing .NET objects to JSON and vice versa.
   */
  contractResolver?: IContractResolverDto;
  /** Gets or sets the equality comparer used by the serializer when comparing references. */
  equalityComparer?: IEqualityComparerDto;
  /** Gets or sets the IReferenceResolver used by the serializer when resolving references. */
  referenceResolver?: IReferenceResolverDto;
  /** Gets or sets a function that creates the IReferenceResolver used by the serializer when resolving references. */
  referenceResolverProvider?: FuncOfIReferenceResolverDto;
  /** Gets or sets the ITraceWriter used by the serializer when writing trace messages. */
  traceWriter?: ITraceWriterDto;
  /** Gets or sets the SerializationBinder used by the serializer when resolving type names. */
  binder?: SerializationBinderDto;
  /** Gets or sets the ISerializationBinder used by the serializer when resolving type names. */
  serializationBinder?: ISerializationBinderDto;
  /** Gets or sets the error handler called during serialization and deserialization. */
  error?: EventHandlerOfErrorEventArgsDto;
  /** Gets or sets the StreamingContext used by the serializer when invoking serialization callback methods. */
  context: StreamingContextDto;
  /**
   * Gets or sets how DateTime and DateTimeOffset values are formatted when writing JSON text,
   * and the expected date format when reading JSON text.
   * The default value is "yyyy'-'MM'-'dd'T'HH':'mm':'ss.FFFFFFFK".
   */
  dateFormatString: string;
  /**
   * Gets or sets the maximum depth allowed when reading JSON. Reading past this depth will throw a JsonReaderException.
   * A null value means there is no maximum.
   * The default value is 64.
   * @format int32
   */
  maxDepth?: number;
  /**
   * Indicates how JSON text output is formatted.
   * The default value is None.
   */
  formatting: FormattingDto;
  /**
   * Gets or sets how dates are written to JSON text.
   * The default value is IsoDateFormat.
   */
  dateFormatHandling: DateFormatHandlingDto;
  /**
   * Gets or sets how DateTime time zones are handled during serialization and deserialization.
   * The default value is RoundtripKind.
   */
  dateTimeZoneHandling: DateTimeZoneHandlingDto;
  /**
   * Gets or sets how date formatted strings, e.g. "\/Date(1198908717056)\/" and "2012-03-21T05:40Z", are parsed when reading JSON.
   * The default value is DateTime.
   */
  dateParseHandling: DateParseHandlingDto;
  /**
   * Gets or sets how special floating point numbers, e.g. NaN,
   * PositiveInfinity and NegativeInfinity,
   * are written as JSON.
   * The default value is String.
   */
  floatFormatHandling: FloatFormatHandlingDto;
  /**
   * Gets or sets how floating point numbers, e.g. 1.0 and 9.9, are parsed when reading JSON text.
   * The default value is Double.
   */
  floatParseHandling: FloatParseHandlingDto;
  /**
   * Gets or sets how strings are escaped when writing JSON text.
   * The default value is Default.
   */
  stringEscapeHandling: StringEscapeHandlingDto;
  /**
   * Gets or sets the culture used when reading JSON.
   * The default value is InvariantCulture.
   */
  culture: string;
  /**
   * Gets a value indicating whether there will be a check for additional content after deserializing an object.
   * The default value is false.
   */
  checkAdditionalContent: boolean;
}
/** Specifies reference loop handling options for the JsonSerializer. */
export enum ReferenceLoopHandlingDto {
  Error = 0,
  Ignore = 1,
  Serialize = 2,
} /** Specifies missing member handling options for the JsonSerializer. */
export enum MissingMemberHandlingDto {
  Ignore = 0,
  Error = 1,
} /** Specifies how object creation is handled by the JsonSerializer. */
export enum ObjectCreationHandlingDto {
  Auto = 0,
  Reuse = 1,
  Replace = 2,
} /** Specifies null value handling options for the JsonSerializer. */
export enum NullValueHandlingDto {
  Include = 0,
  Ignore = 1,
} /** Specifies default value handling options for the JsonSerializer. */
export enum DefaultValueHandlingDto {
  Include = 0,
  Ignore = 1,
  Populate = 2,
  IgnoreAndPopulate = 3,
} /** Converts an object to and from JSON. */
export interface JsonConverterDto {
  /** Gets a value indicating whether this JsonConverter can read JSON. */
  canRead: boolean;
  /** Gets a value indicating whether this JsonConverter can write JSON. */
  canWrite: boolean;
}
/**
 * Specifies reference handling options for the JsonSerializer.
 * Note that references cannot be preserved when a value is set via a non-default constructor such as types that implement ISerializable.
 */
export enum PreserveReferencesHandlingDto {
  None = 0,
  Objects = 1,
  Arrays = 2,
  All = 3,
} /** Specifies type name handling options for the JsonSerializer. */
export enum TypeNameHandlingDto {
  None = 0,
  Objects = 1,
  Arrays = 2,
  All = 3,
  Auto = 4,
} /** Specifies metadata property handling options for the JsonSerializer. */
export enum MetadataPropertyHandlingDto {
  Default = 0,
  ReadAhead = 1,
  Ignore = 2,
}
export enum FormatterAssemblyStyleDto {
  Simple = 0,
  Full = 1,
} /** Indicates the method that will be used during deserialization for locating and loading assemblies. */
export enum TypeNameAssemblyFormatHandlingDto {
  Simple = 0,
  Full = 1,
} /** Specifies how constructors are used when initializing objects during deserialization by the JsonSerializer. */
export enum ConstructorHandlingDto {
  Default = 0,
  AllowNonPublicDefaultConstructor = 1,
} /** Used by JsonSerializer to resolve a JsonContract for a given Type. */
export type IContractResolverDto = object;
export type IEqualityComparerDto = object;
/** Used to resolve references when serializing and deserializing JSON by the JsonSerializer. */
export type IReferenceResolverDto = object;
export type FuncOfIReferenceResolverDto = MulticastDelegateDto & object;
/** Represents a trace writer. */
export interface ITraceWriterDto {
  /**
   * Gets the TraceLevel that will be used to filter the trace messages passed to the writer.
   * For example a filter level of Info will exclude Verbose messages and include Info,
   * Warning and Error messages.
   */
  levelFilter: TraceLevelDto;
}
export enum TraceLevelDto {
  Off = 0,
  Error = 1,
  Warning = 2,
  Info = 3,
  Verbose = 4,
}
export type SerializationBinderDto = object;
/** Allows users to control class loading and mandate what class to load. */
export type ISerializationBinderDto = object;
export type EventHandlerOfErrorEventArgsDto = MulticastDelegateDto & object;
export interface StreamingContextDto {
  context?: any;
  state: StreamingContextStatesDto;
}
export enum StreamingContextStatesDto {
  CrossProcess = 1,
  CrossMachine = 2,
  File = 4,
  Persistence = 8,
  Remoting = 16,
  Other = 32,
  Clone = 64,
  CrossAppDomain = 128,
  All = 255,
} /** Specifies formatting options for the JsonTextWriter. */
export enum FormattingDto {
  None = 0,
  Indented = 1,
} /** Specifies how dates are formatted when writing JSON text. */
export enum DateFormatHandlingDto {
  IsoDateFormat = 0,
  MicrosoftDateFormat = 1,
} /** Specifies how to treat the time value when converting between string and DateTime. */
export enum DateTimeZoneHandlingDto {
  Local = 0,
  Utc = 1,
  Unspecified = 2,
  RoundtripKind = 3,
} /** Specifies how date formatted strings, e.g. "\/Date(1198908717056)\/" and "2012-03-21T05:40Z", are parsed when reading JSON text. */
export enum DateParseHandlingDto {
  None = 0,
  DateTime = 1,
  DateTimeOffset = 2,
} /**
 * Specifies float format handling options when writing special floating point numbers, e.g. NaN,
 * PositiveInfinity and NegativeInfinity with JsonWriter.
 */
export enum FloatFormatHandlingDto {
  String = 0,
  Symbol = 1,
  DefaultValue = 2,
} /** Specifies how floating point numbers, e.g. 1.0 and 9.9, are parsed when reading JSON text. */
export enum FloatParseHandlingDto {
  Double = 0,
  Decimal = 1,
} /** Specifies how strings are escaped when writing JSON text. */
export enum StringEscapeHandlingDto {
  Default = 0,
  EscapeNonAscii = 1,
  EscapeHtml = 2,
} /** MediaTypeFormatter class for handling HTML form URL-ended data, also known as application/x-www-form-urlencoded.   */
export type FormUrlEncodedMediaTypeFormatterDto = MediaTypeFormatterDto & {
  /**
   * Gets or sets the maximum depth allowed by this formatter.
   * @format int32
   */
  maxDepth: number;
  /**
   * Gets or sets the size of the buffer when reading the incoming stream.
   * @format int32
   */
  readBufferSize: number;
};
/** Contains information for a single HTTP operation. */
export interface HttpControllerContextDto {
  /** Gets or sets the configuration. */
  configuration?: HttpConfigurationDto;
  /** Gets or sets the controller descriptor. */
  controllerDescriptor?: HttpControllerDescriptorDto;
  /** Gets or sets the HTTP controller. */
  controller?: IHttpControllerDto;
  /** Gets or sets the request. */
  request?: HttpRequestMessageDto;
  /** Gets or sets the request context. */
  requestContext?: HttpRequestContextDto;
  /** Gets or sets the route data. */
  routeData?: IHttpRouteDataDto;
}
/** Represents information that describes the HTTP controller. */
export interface HttpControllerDescriptorDto {
  /** Gets the properties associated with this instance. */
  properties?: Record<string, any>;
  /** Gets or sets the configurations associated with the controller. */
  configuration?: HttpConfigurationDto;
  /** Gets or sets the name of the controller. */
  controllerName?: string;
  /** Gets or sets the type of the controller. */
  controllerType?: string;
}
/** Represents an HTTP controller. */
export type IHttpControllerDto = object;
/** Represents the context associated with a request. */
export interface HttpRequestContextDto {
  /** Gets or sets the client certificate. */
  clientCertificate?: X509Certificate2Dto;
  /** Gets or sets the configuration. */
  configuration?: HttpConfigurationDto;
  /** Gets or sets a value indicating whether error details, such as exception messages and stack traces, should be included in the response for this request. */
  includeErrorDetail: boolean;
  /** Gets or sets a value indicating whether the request originates from a local address. */
  isLocal: boolean;
  /** .Gets or sets the principal */
  principal?: IPrincipalDto;
  /** Gets or sets the route data. */
  routeData?: IHttpRouteDataDto;
  /** Gets or sets the factory used to generate URLs to other APIs. */
  url?: UrlHelperDto;
  /** Gets or sets the virtual path root. */
  virtualPathRoot?: string;
}
export type X509Certificate2Dto = X509CertificateDto & {
  Archived: boolean;
  Extensions?: any[];
  FriendlyName?: string;
  IssuerName?: X500DistinguishedNameDto;
  /** @format date-time */
  NotAfter: string;
  /** @format date-time */
  NotBefore: string;
  HasPrivateKey: boolean;
  PrivateKey?: AsymmetricAlgorithmDto;
  PublicKey?: PublicKeyDto;
  /** @format byte */
  RawData?: string;
  SerialNumber?: string;
  SubjectName?: X500DistinguishedNameDto;
  SignatureAlgorithm?: OidDto;
  Thumbprint?: string;
  /** @format int32 */
  Version: number;
  CertContext?: SafeCertContextHandleDto;
};
export type X500DistinguishedNameDto = AsnEncodedDataDto & {
  name?: string;
};
export interface AsnEncodedDataDto {
  oid?: OidDto;
  /** @format byte */
  rawData?: string;
}
export interface OidDto {
  value?: string;
  friendlyName?: string;
}
export interface AsymmetricAlgorithmDto {
  /** @format int32 */
  keySize: number;
  legalKeySizes?: KeySizesDto[];
  signatureAlgorithm?: string;
  keyExchangeAlgorithm?: string;
}
export interface KeySizesDto {
  /** @format int32 */
  minSize: number;
  /** @format int32 */
  maxSize: number;
  /** @format int32 */
  skipSize: number;
}
export interface PublicKeyDto {
  key?: AsymmetricAlgorithmDto;
  oid?: OidDto;
  encodedKeyValue?: AsnEncodedDataDto;
  encodedParameters?: AsnEncodedDataDto;
}
export type SafeCertContextHandleDto = SafeHandleZeroOrMinusOneIsInvalidDto & object;
export type SafeHandleZeroOrMinusOneIsInvalidDto = SafeHandleDto & {
  isInvalid: boolean;
};
export type SafeHandleDto = CriticalFinalizerObjectDto & {
  isClosed: boolean;
};
export type CriticalFinalizerObjectDto = object;
export interface X509CertificateDto {
  Handle: IntPtrDto;
  Issuer?: string;
  Subject?: string;
  CertContext?: SafeCertContextHandle2Dto;
}
export type SafeCertContextHandle2Dto = SafeHandleZeroOrMinusOneIsInvalidDto & object;
export interface IPrincipalDto {
  identity?: IIdentityDto;
}
export interface IIdentityDto {
  name?: string;
  authenticationType?: string;
  isAuthenticated: boolean;
}
/** Provides information about a route. */
export interface IHttpRouteDataDto {
  /** Gets the object that represents the route. */
  route?: IHttpRouteDto;
  /** Gets a collection of URL parameter values and default values for the route. */
  values?: Record<string, any>;
}
/** Represents a factory for creating URLs.  */
export interface UrlHelperDto {
  /** Gets or sets the HttpRequestMessage of the current UrlHelper instance. */
  request?: HttpRequestMessageDto;
}
/** Contains information for the executing action. */
export interface HttpActionContextDto {
  /** Gets or sets the controller context. */
  controllerContext?: HttpControllerContextDto;
  /** Gets or sets the action descriptor for the action context. */
  actionDescriptor?: HttpActionDescriptorDto;
  /** Gets the model state dictionary for the context. */
  modelState?: ModelStateDictionaryDto;
  /** Gets a list of action arguments. */
  actionArguments?: Record<string, any>;
  /** @format binary */
  response?: File;
  /** Gets the request message for the action context. */
  request?: HttpRequestMessageDto;
  /** Gets the current request context. */
  requestContext?: HttpRequestContextDto;
}
/** Provides information about the action methods. */
export interface HttpActionDescriptorDto {
  /** Gets or sets the action configuration. */
  configuration?: HttpConfigurationDto;
  /** Gets or sets the binding that describes the action. */
  actionBinding?: HttpActionBindingDto;
  /** Gets the information that describes the controller of the action. */
  controllerDescriptor?: HttpControllerDescriptorDto;
  /** Gets the converter for correctly transforming the result of calling ExecuteAsync(HttpControllerContext, IDictionaryString, Object)" into an instance of HttpResponseMessage. */
  resultConverter?: IActionResultConverterDto;
  /** Gets the collection of supported HTTP methods for the descriptor. */
  supportedHttpMethods?: HttpMethodDto[];
  /** Gets the properties associated with this instance. */
  properties?: Record<string, any>;
}
/** Describes *how* the binding will happen and does not actually bind. */
export interface HttpActionBindingDto {
  /** Gets or sets the back pointer to the action this binding is for.   */
  actionDescriptor?: HttpActionDescriptorDto;
  /** Gets or sets the synchronous bindings for each parameter.  */
  parameterBindings?: HttpParameterBindingDto[];
}
/** Describes how a parameter is bound. The binding should be static (based purely on the descriptor) and  can be shared across requests.   */
export interface HttpParameterBindingDto {
  /** Returns a value indicating whether this HttpParameterBinding instance will read the entity body of the HTTP message. */
  willReadBody: boolean;
  /** Gets a value that indicates whether the binding was successful. */
  isValid: boolean;
  /** If the binding is invalid, gets an error message that describes the binding error. */
  errorMessage?: string;
  /** Gets the HttpParameterDescriptor that was used to initialize this instance. */
  descriptor?: HttpParameterDescriptorDto;
}
/** Represents the HTTP parameter descriptor. */
export interface HttpParameterDescriptorDto {
  /** Gets or sets the HttpConfiguration for the HttpParameterDescriptor. */
  configuration?: HttpConfigurationDto;
  /** Gets or sets the action descriptor. */
  actionDescriptor?: HttpActionDescriptorDto;
  /** Gets the properties of this parameter. */
  properties?: Record<string, any>;
  /** Gets the default value of the parameter. */
  defaultValue?: any;
  /** Gets the prefix of this parameter. */
  prefix?: string;
  /** Gets a value that indicates whether the parameter is optional. */
  isOptional: boolean;
  /** Gets or sets the parameter binding attribute. */
  parameterBinderAttribute?: ParameterBindingAttributeDto;
}
/** Attribute on a parameter or type that produces a HttpParameterBinding.  If the attribute is on a type-declaration, then it's as if that attribute is present on all action parameters  of that type.  */
export type ParameterBindingAttributeDto = AttributeDto & object;
export interface AttributeDto {
  typeId?: any;
}
/** A contract for a conversion routine that can take the result of an action returned from <see cref="M:System.Web.Http.Controllers.HttpActionDescriptor.ExecuteAsync(System.Web.Http.Controllers.HttpControllerContext,System.Collections.Generic.IDictionary{System.String,System.Object})" /> and convert it to an instance of HttpResponseMessage.  */
export type IActionResultConverterDto = object;
/** Represents the state of an attempt to bind a posted form to an action method, which includes validation information. */
export interface ModelStateDictionaryDto {
  /**
   * Gets the number of key/value pairs in the collection.
   * @format int32
   */
  Count: number;
  /** Gets a value that indicates whether the collection is read-only. */
  IsReadOnly: boolean;
  /** Gets a value that indicates whether this instance of the model-state dictionary is valid. */
  IsValid: boolean;
  /** Gets a collection that contains the keys in the dictionary. */
  Keys?: string[];
  /** Gets a collection that contains the values in the dictionary. */
  Values?: ModelStateDto[];
  /** Encapsulates the state of model binding to a property of an action-method argument, or to the argument itself. */
  Item?: ModelStateDto;
}
/** Encapsulates the state of model binding to a property of an action-method argument, or to the argument itself. */
export interface ModelStateDto {
  /** Gets a ValueProviderResult object that encapsulates the value that was being bound during model binding. */
  value?: ValueProviderResultDto;
  /** Gets a ModelErrorCollection object that contains any errors that occurred during model binding. */
  errors?: ModelErrorCollectionDto;
}
/** Represents the result of binding a value (such as from a form post or query string) to an action-method argument property, or to the argument itself. */
export interface ValueProviderResultDto {
  /** Gets or sets the raw value that is converted to a string for display. */
  attemptedValue?: string;
  /** Gets or sets the culture. */
  culture?: string;
  /** Gets or set the raw value that is supplied by the value provider. */
  rawValue?: any;
}
/** Represents a collection of ModelError instances. */
export type ModelErrorCollectionDto = ModelErrorDto[];
/** Represents an error that occurs during model binding. */
export interface ModelErrorDto {
  /** Gets or sets the exception object. */
  exception?: ExceptionDto;
  /** Gets or sets the error message. */
  errorMessage?: string;
}
export interface ExceptionDto {
  Message?: string;
  InnerException?: ExceptionDto;
  StackTrace?: string;
  Source?: string;
}
export interface UserSummaryModelDto {
  /** @format int32 */
  id?: number;
  userId?: string;
  userName?: string;
  isLocked: boolean;
  isMfaLocked: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  status?: string;
  publishState?: string;
  isActive: boolean;
  isEditor: boolean;
  isDeleted?: boolean;
  digitalId?: string;
  securityAndPasswordSettingsLink?: string;
  userUniqueId?: string;
}
export interface SearchUserModelDto {
  activeUsersOnly: boolean;
  enrolledUsersOnly: boolean;
  includeRoles: boolean;
}
export interface UserProductFeatureSummaryModelDto {
  /** @format int32 */
  userId: number;
  changedUserInformation: boolean;
  changedAccountAccess: boolean;
  hasAch: boolean;
  changedAchEntitlements: boolean;
  changedAchUserLimits: boolean;
  hasStandardPosPay: boolean;
  hasSsoPosPay: boolean;
  changedStandardPosPayEntitlements: boolean;
  changedSsoPosPayUserSettings: boolean;
  hasInformationReporting: boolean;
  changedInformationReportingEntitlements: boolean;
  hasInternalTransfers: boolean;
  changedInternalTransfersEntitlements: boolean;
  changedInternalTransfersUserLimits: boolean;
  hasWireTransfers: boolean;
  hasWireTransfersDomestic: boolean;
  hasWireTransfersInternational: boolean;
  changedWireTransfersEntitlements: boolean;
  changedWireTransfersUserLimits: boolean;
  hasWireTransfersDli: boolean;
  changedWireTransfersDliEntitlements: boolean;
  changedWireTransfersDliUserLimits: boolean;
  hasStopPayment: boolean;
  changedStopPaymentEntitlements: boolean;
  changedIpRestrictions: boolean;
  changedTimeAccess: boolean;
  hasIntegratedServices: boolean;
  changedIntegratedServices: boolean;
  hasReceivables: boolean;
  changedReceivables: boolean;
  hasAccountReconciliationReporting: boolean;
  changedAccountReconciliationReporting: boolean;
}
export type DeleteUserModelDto = SecurityBaseDto & {
  /** @format int32 */
  userId: number;
};
export interface UserInformationResponseModelDto {
  user?: UserInformationModelDto;
  pendingUser?: UserInformationModelDto;
}
export type UserTimeAccess2Dto = RequestResponseDto & {
  timeRestricted: boolean;
  timeRestrictedPending: boolean;
  /** @format int32 */
  userId: number;
  userTimeAccessDays?: UserTimeAccessDayDto[];
  userTimeAccessDaysPending?: UserTimeAccessDayDto[];
};
export interface UserTimeAccessDayDto {
  timeAccessType: TimeAccessTypeDto;
  dayOfWeek: DayOfWeekDto;
  /** @format duration */
  beginTime?: string;
  /** @format duration */
  endTime?: string;
}
export enum TimeAccessTypeDto {
  Undefined = 0,
  AllowAllDay = 1,
  RestrictAllDay = 2,
  TimeRange = 3,
}
export enum DayOfWeekDto {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
export type ChallengesSupportedResponseModelDto = RequestResponseDto & {
  securityMessage?: SecurityMessageModelDto;
};
export type SaveUserTimeAccessRequestModelDto = RequestResponseDto & {
  securityMessage?: SecurityMessageModelDto;
  timeAccess?: UserTimeAccess2Dto;
};
export interface GetUserAccountAccessModelDto {
  accounts?: AccountAccessModelDto[];
}
export type AccountAccessModelDto = ModelBaseDto & {
  companyAccount?: CompanyAccountModelDto;
  hasUserAccess: boolean;
  hasPendingUserAccess: boolean;
};
export type SaveUserAccountAccessModelDto = RequestResponseDto & {
  addedAccounts?: AccountAccessModelDto[];
  removedAccounts?: AccountAccessModelDto[];
  securityMessage?: SecurityMessageModelDto;
};
export interface GetUserProductLimitsResponseModelDto {
  productType?: string;
  userProductLimit?: UserProductLimitsModelDto;
  pendingUserProductLimit?: UserProductLimitsModelDto;
  userAccountLimits?: AccountUserLimitModelDto[];
  /** @format decimal */
  productDailyLimit: number;
}
export interface UserProductLimitsModelDto {
  /** @format decimal */
  initiationLimit: number;
  /** @format decimal */
  approvalLimit: number;
}
export interface AccountUserLimitModelDto {
  userAccount?: UserAccountModelDto;
  userAccountLimit?: UserAccountLimitModelDto;
  pendingUserAccountLimit?: UserAccountLimitModelDto;
}
export interface UserAccountModelDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  userId: number;
  account?: CompanyAccountModelDto;
}
export interface UserAccountLimitModelDto {
  /** @format decimal */
  perTransactionLimit: number;
  /** @format decimal */
  dailyLimit: number;
}
export interface GetUserProductEntitlementsModelDto {
  productType?: string;
  userProductClaims?: UserClaimModelDto[];
  accountProductClaims?: ClaimModelDto[];
  accounts?: ProductAccountDto[];
}
export interface UserClaimModelDto {
  /** @format int32 */
  claimId: number;
  claimName?: string;
  hasUserClaim?: boolean;
  hasPendingUserClaim?: boolean;
  claimDescription?: string;
}
export interface ClaimModelDto {
  /** @format int32 */
  claimId: number;
  claimName?: string;
  claimDescription?: string;
}
export interface ProductAccountDto {
  /** @format int32 */
  id: number;
  name?: string;
  number?: string;
  userAccountClaims?: UserClaimModelDto[];
}
export interface GetUserAchEntitlementsModelDto {
  achCompanies?: AchCompanyModelDto[];
  userAchCompanies?: AchCompanyModelDto[];
  userProductClaims?: UserClaimModelDto[];
}
export type AchCompanyModelDto = ModelBaseDto & {
  /** @minLength 1 */
  companyName: string;
  /** @minLength 1 */
  companyId: string;
  secCodes?: SecCodeModelDto[];
  /** @minLength 1 */
  entryDescription: string;
  /** @minLength 1 */
  discretionaryData: string;
  /** @format decimal */
  totalDailyLimit: number;
  /** @minLength 1 */
  notOnUsTransactionTypes: string;
  /** @minLength 1 */
  batchBalanceRequirements: string;
  /** @format int32 */
  onUsTransactionsRequired: number;
  /** @format decimal */
  onUsAmountRequiredPerBatch: number;
  isActive: boolean;
  /** @format int32 */
  prefundingDays?: number;
  offsetAccountNumber?: string;
  allowUnbalancedPayments: boolean;
  /** @format guid */
  companyGuidUniqueId: string;
};
export interface SecCodeModelDto {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  description: string;
}
export interface GetUserAchLimitsModelDto {
  userProductLimit?: UserProductLimitsModelDto;
  pendingUserProductLimit?: UserProductLimitsModelDto;
  userAchCompanies?: AchCompanyLimitModelDto[];
  /** @format decimal */
  productDailyLimit: number;
}
export interface AchCompanyLimitModelDto {
  achCompany?: AchCompanyModelDto;
  userAchCompanyLimit?: CompanyLimitModelDto;
  pendingUserAchCompanyLimit?: CompanyLimitModelDto;
}
export interface CompanyLimitModelDto {
  /** @format decimal */
  perTransactionLimit: number;
  /** @format decimal */
  dailyLimit: number;
}
export interface SaveUserProductLimitsRequestModelDto {
  productType?: string;
  userProductLimit?: UserProductLimitsModelDto;
  pendingUserProductLimit?: UserProductLimitsModelDto;
  userAccountLimits?: AccountUserLimitModelDto[];
  /** @format decimal */
  productDailyLimit: number;
  securityMessage?: SecurityMessageModelDto;
}
export interface SaveUserAchLimitsRequestModelDto {
  userProductLimit?: UserProductLimitsModelDto;
  pendingUserProductLimit?: UserProductLimitsModelDto;
  userAchCompanies?: AchCompanyLimitModelDto[];
  /** @format decimal */
  productDailyLimit: number;
  securityMessage?: SecurityMessageModelDto;
}
export interface SaveUserProductEntitlementsModelDto {
  addedUserClaims?: number[];
  addedUserClaimAccounts?: Record<string, number[]>;
  removedUserClaims?: number[];
  removedUserClaimAccounts?: Record<string, number[]>;
  securityMessage?: SecurityMessageModelDto;
}
export interface SaveUserAchProductEntitlementsModelDto {
  achCompanies?: AchCompanyModelDto[];
  addedEntitlements?: UserProductEntitlementDto[];
  removedEntitlements?: UserProductEntitlementDto[];
  securityMessage?: SecurityMessageModelDto;
}
export interface UserProductEntitlementDto {
  account?: ProductAccountDto;
  claim?: ClaimModelDto;
}
export interface IpRestrictionModelDto {
  ipRestrictions?: IpRestrictionAddressModelDto[];
  ipRestrictionsPending?: IpRestrictionAddressModelDto[];
  /** @format int32 */
  userId: number;
  restricted: boolean;
  restrictedPending: boolean;
}
export interface IpRestrictionAddressModelDto {
  /** @format int32 */
  id: number;
  /** @format byte */
  octet1: number;
  /** @format byte */
  octet2: number;
  /** @format byte */
  octet3?: number;
  /** @format byte */
  octet4?: number;
  name?: string;
}
export type SaveUserIpAccessRequestModelDto = RequestResponseDto & {
  ipRestrictions?: IpRestrictionAddressModelDto[];
  securityMessage?: SecurityMessageModelDto;
};
export interface ElectronicDocumentSsoDto {
  useFormPost: boolean;
  url?: string;
  formFields?: Record<string, string>;
  /** @format int32 */
  documentTypeId: number;
  electronicDocumentServiceName?: string;
}
export interface ApprovalListModelDto {
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
}
export interface UserApprovalDto {
  name?: string;
  /** @format date-time */
  dateApproved: string;
}
export interface ApprovalListRequestModelDto {
  approvalEntity?: string;
  /** @format int32 */
  accountId?: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  createdBy: number;
  /** @format int32 */
  updatedBy?: number;
  /** @format int32 */
  productId: number;
}
export interface SsoPosPayUserSettingsModelDto {
  pendingSsoLoginId?: string;
  ssoLoginId?: string;
  userProductClaims?: UserClaimModelDto[];
}
export interface SaveSsoPosPayUserSettingsModelDto {
  settings?: SsoPosPayUserSettingsModelDto;
  securityMessage?: SecurityMessageModelDto;
}
export interface ReceivablesUserSettingsModelDto {
  pendingSsoAccessKey?: string;
  ssoAccessKey?: string;
  pendingSsoLoginId?: string;
  ssoLoginId?: string;
  userProductClaims?: UserClaimModelDto[];
  /** @format int32 */
  remoteDepositProductTypeId: number;
}
export interface SaveReceivablesUserSettingsModelDto {
  settings?: ReceivablesUserSettingsModelDto;
  securityMessage?: SecurityMessageModelDto;
}
export interface ElectronicDocumentsModelDto {
  accounts?: CompanyAccountModelDto[];
  additionalSettings?: AdditionalElectronicDocumentSettingsModelDto;
}
export interface AdditionalElectronicDocumentSettingsModelDto {
  errorMessage?: string;
  noStatementMessage?: string;
  productDisplayName?: string;
  selectAccountLabel?: string;
}
export interface AdditionalSettingsModelDto {
  displayMethod?: string;
}
export interface CurrentUserTimeAccessDto {
  timeAccess?: TimeAccessDto;
}
export interface TimeAccessDto {
  timeRestricted: boolean;
  userTimeAccessDays?: UserTimeAccessDayDto[];
}
export interface BaseResponse2Dto {
  baseRequest?: BaseRequest2Dto;
  responseDetailCollection?: ResponseDetailCollection2Dto;
  success: boolean;
}
export interface BaseRequest2Dto {
  activityTracking?: ActivityTracking2Dto;
  applicationNameType: ApplicationNameType2Dto;
  authenticationUser?: AuthenticationUser2Dto;
  clientIpAddress?: string;
  institutionId?: string;
  alias?: string;
  secondaryId?: string;
  endUser?: EndUser2Dto;
  productInformation?: ProductInformation2Dto;
  requestType?: string;
}
export interface ActivityTracking2Dto {
  activityId?: string;
  parentActivityId?: string;
}
export enum ApplicationNameType2Dto {
  Undefined = 0,
  BackOffice = 1,
  Banno = 2,
  ECS = 3,
  EPS = 4,
  Esi = 5,
  GoDough = 6,
  GoDoughClassic = 7,
  GoDough3X = 8,
  IPay = 9,
  NetTeller = 10,
  PointMobility = 11,
  Treasury = 12,
  BSLKeys = 13,
  BSL = 14,
  ESIFIM = 15,
  EStatements = 16,
  Geezeo = 17,
  BannoPeople = 18,
  CoreDirector = 19,
  Newgen = 20,
  Corserv = 21,
  BOKF = 22,
  Autobooks = 23,
  MobileWeb = 24,
  HCL = 25,
  JxChangeApp = 26,
  TreasuryMobile = 27,
  TreasuryBackOffice = 28,
  BslSubSystem = 29,
  AccountRecon = 30,
  Ach = 31,
  AlertNetCore = 32,
  BankAccount = 33,
  Company = 34,
  Event = 35,
  JxChangeProxy = 36,
  PositivePay = 37,
  Sso = 38,
  User = 39,
  Wire = 40,
  Edpp = 41,
  FxGateway = 42,
  InstitutionNetCore = 43,
  AuthorizationNetCore = 44,
  File = 45,
  Configuration = 46,
  AchReturn = 47,
  ApiCatalog = 48,
  BslSubsystemAdmin = 49,
  EsiMailProcessor = 50,
  Nacha = 51,
  Pdf = 52,
  AchNotificationOfChange = 53,
  RateLimiting = 54,
  AchCompany = 55,
  TreasuryScheduler = 56,
  Report = 57,
  AccountReconIssuedItems = 58,
  PositivePayIssuedItems = 59,
  InternalTransfer = 60,
  CheckExceptions = 61,
  AlertUpdate = 62,
  TreasuryAccount = 63,
  TreasuryAuthentication = 64,
  TreasuryOdiEesListener = 65,
  TreasuryPayment = 66,
  TreasuryReporting = 67,
  TreasuryAch = 68,
  TreasuryWire = 69,
  TreasuryBackOfficeApi = 70,
  TreasuryChannelApi = 71,
  TreasuryNotification = 72,
  NachaIngest = 73,
  AchReturnProcessor = 74,
  AchNocProcessor = 75,
  NachaProcessor = 76,
  NachaSerialize = 77,
  AchPayment = 78,
  TextMessagingNetCore = 79,
  EmailNetCore = 80,
  LoggingNetCore = 81,
  MessageCenterNetCore = 82,
  AuthenticationRules = 83,
  Credentials = 84,
  HttpProxy = 85,
  DocumentNetCore = 86,
  AuthenticationNetCore = 87,
  ChallengeManagementNetCore = 88,
  FileWatcher = 89,
  FileProcessor = 90,
  EsiApplication = 91,
  AccountReports = 92,
  AuditNetCore = 93,
  TreasuryWireNetCore = 94,
  FraudNetCore = 95,
  BslAdmin = 96,
  JxR = 97,
  HealthCheck = 98,
  EchoQueueHandlerService = 99,
  BackgroundUpload = 100,
  JxrBannoBusiness = 101,
  Disclosure = 102,
  FileMovement = 103,
  Fxgw = 104,
}
export type AuthenticationUser2Dto = EndUser2Dto & object;
export interface EndUser2Dto {
  alias?: string;
  companyId?: string;
  externalId?: string;
  externalSecondaryId?: string;
  internalId?: string;
  internalSecondaryId?: string;
  userType: UserType2Dto;
  device?: string;
  browser?: string;
}
export enum UserType2Dto {
  Undefined = 0,
  Banno = 1,
  CashManagement = 2,
  Esi = 3,
  NetTeller = 4,
  Sso = 5,
  Treasury = 6,
  ESIFIM = 7,
  BackOffice = 8,
  Geezeo = 9,
  GeezeoMobile = 10,
  BannoCashManagement = 11,
  GeezeoCashManagement = 12,
  GeezeoMobileCashManagement = 13,
  Newgen = 14,
  Server = 15,
  Jha = 16,
  BannoPeople = 17,
}
export interface ProductInformation2Dto {
  productName?: string;
  featureName?: string;
  version?: string;
}
export type ResponseDetailCollection2Dto = ResponseDetail2Dto[];
export interface ResponseDetail2Dto {
  /** @format int32 */
  responseCode: number;
  responseMessage?: string;
}
export type SaveUserActivityRequestDto = BaseRequest2Dto & {
  /** @format date-time */
  activityDate: string;
  activityMessage?: string;
  /** @format int32 */
  relatedId?: number;
  userActivityType: UserActivityTypeDto;
};
export enum UserActivityTypeDto {
  Account = 1,
  Ach = 2,
  Admin = 3,
  Dashboard = 4,
  Login = 5,
  MessageCenter = 6,
  Navigating = 7,
  PositivePay = 8,
  ProfileAndNotification = 9,
  Reporting = 10,
  StopPayment = 11,
  Transaction = 12,
  Transfer = 13,
  Wire = 14,
  AccountReconciliationReporting = 15,
  Authentication = 16,
}
export interface UisEnrollmentLinkResponseModelDto {
  uisEnrollmentLink?: string;
}
export interface UisEnrollmentLinkRequestModelDto {
  fiId?: string;
  treasuryEnrollmentId?: string;
  companyAlias?: string;
  userAlias?: string;
  returnUrl?: string;
}
export interface SaveUisUserEnrollmentDetailRequestModelDto {
  authCode?: string;
  fiId?: string;
  returnUrl?: string;
  state?: string;
}
export interface SsoViaUisResponseDto {
  statusCode?: string;
  message?: string;
  alias?: string;
  companyUniqueId?: string;
  digitalId?: string;
  userAccountSettingsLink?: string;
  userCompanyInformation?: UserCompanyInformationCollectionDto;
  errorMessage?: string;
}
export type UserCompanyInformationCollectionDto = UserCompanyInformationDto[];
export interface UserCompanyInformationDto {
  userAlias?: string;
  companyUniqueId?: string;
  companyName?: string;
  /** @format guid */
  userCompanyUniqueId: string;
  digitalId?: string;
}
export type HealthCheckActionResponseDto = BaseResponse2Dto & {
  getPackageInformationSuccessful: boolean;
  packageInformation?: PackageInformationDto;
  getRedisConnectivitySuccessful: boolean;
  redisConnectivityStatus?: RedisConnectivityStatusCollectionDto;
  redisMultiplexerAbleToConnect: boolean;
  getDatabaseConnectivitySuccessful: boolean;
  databaseConnectivityStatus?: DatabaseConnectivityStatusCollectionDto;
};
export interface PackageInformationDto {
  buildInformation?: BuildInformationDto;
  releaseInformation?: ReleaseInformationDto;
  /** @format date-time */
  createdDateTime: string;
  machineName?: string;
}
export interface BuildInformationDto {
  commitId?: string;
  repository?: string;
  azdoProject?: string;
  packageVersion?: string;
  buildUrl?: string;
}
export interface ReleaseInformationDto {
  /** @format date-time */
  deploymentDateUtc: string;
  releaseVersion?: string;
  projectId?: string;
  packageName?: string;
  packageVersion?: string;
}
export type RedisConnectivityStatusCollectionDto = RedisConnectivityStatusDto[];
export interface RedisConnectivityStatusDto {
  redisClusterName?: string;
  ableToConnect: boolean;
  ipAddress?: string;
  additionalInformation?: string;
}
export type DatabaseConnectivityStatusCollectionDto = DatabaseConnectivityStatusDto[];
export interface DatabaseConnectivityStatusDto {
  connectionStringName?: string;
  ableToConnect: boolean;
  additionalInformation?: string;
}
export interface InitiateSsoResponseDto {
  redirectTo?: string;
}
export interface InitiateSsoRequestDto {
  ssoUniqueId?: string;
}
export interface ProductTypeModelDto {
  content?: string;
  errorMessages?: any[];
}
export interface UserSsoResponseDto {
  userSsos?: UserSsoModelCollectionDto;
}
export type UserSsoModelCollectionDto = UserSsoModelDto[];
export interface UserSsoModelDto {
  ssoUniqueId?: string;
  ssoName?: string;
  parentMenuPath?: string;
}
export type UserIdentityDto = OfxBaseModelDto & {
  fiNumber?: string;
  companyUniqueId?: string;
  userId?: string;
  userPassword?: string;
};
export interface OfxBaseModelDto {
  ofxKey?: string;
  activityId?: string;
}
export interface OfxAuthenticationCodeDto {
  /** @format int32 */
  companyId: number;
  companyUniqueId?: string;
  errorMessage?: string;
}
export interface OfxContextDto {
  jxEnvironment?: string;
  jxRouting?: string;
  clientId?: string;
  bankId?: string;
  /** @format int32 */
  companyId: number;
  cifNumber?: string;
  errorMessage?: string;
}
export interface PasswordRulesDto {
  rules?: PasswordRuleDto[];
}
export interface PasswordRuleDto {
  /** @format int32 */
  ruleType: number;
  ruleValue?: string;
  ruleMessage?: string;
}
export interface OfxAccountsModelDto {
  errorMessage?: string;
  ofxAccounts?: OfxAccountDetailModelDto[];
}
export interface OfxAccountDetailModelDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  companyId: number;
  name?: string;
  type?: string;
  number?: string;
  description?: string;
  rowStatusId: number;
  cifNumber?: string;
  rowStatusDescription?: string;
  /** @format decimal */
  balance?: number;
  /** @format decimal */
  collectedBalance?: number;
  /** @format decimal */
  currentBalance?: number;
  /** @format decimal */
  perTransactionLimit: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format decimal */
  loanPaymentAmountDue?: number;
  /** @format date-time */
  loanPaymentDueDate?: string;
  allowLoanPaymentPastDue: boolean;
  suppressAccountMasking: boolean;
  showAccountNickName: boolean;
  canTransferFrom: boolean;
  /** @format decimal */
  ledgerBalance?: number;
  /** @format decimal */
  availableBalance?: number;
}
export type AccountBalanceRequestModelDto = OfxBaseModelDto & {
  /** @format int32 */
  companyId: number;
  accountId?: string;
};
export type TransferAccountRequestDto = OfxBaseModelDto & {
  /** @format int32 */
  fromAccountId: number;
};
export interface OfxTransactionsModelDto {
  errorMessage?: string;
  transactions?: OfxTransactionDetailDto[];
}
export interface OfxTransactionDetailDto {
  description?: string;
  /** @format decimal */
  debit?: number;
  /** @format decimal */
  credit?: number;
  /** @format date-time */
  date?: string;
  isDebit: boolean;
  checkNumber?: string;
}
export type TransactionDetailRequestDto = OfxBaseModelDto & {
  /** @format int32 */
  id: number;
  number?: string;
  type?: string;
  /** @format date-time */
  fromDate?: string;
  /** @format date-time */
  toDate?: string;
  requireBalanceHistory: boolean;
  memoPostOnly: boolean;
};
export interface OfxProductDto {
  errorMessage?: string;
  productTypeIds?: number[];
}
export type GetOfxUserAccountsRequestModelDto = OfxBaseModelDto & {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  companyId: number;
  isBalanceRequired: boolean;
  isTransferFromRequired: boolean;
  searchUserString?: string;
};
export interface TransferResultDto {
  errorMessage?: string;
  transactionId?: string;
}
export type OfxTransferRequestDto = OfxBaseModelDto & {
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  /** @format date-time */
  transferDate?: string;
  /** @format decimal */
  amount?: number;
  memo?: string;
  fromAccountNumber?: string;
  /** @format int32 */
  fromAccountId: number;
  fromAccountType?: string;
  toAccountNumber?: string;
  /** @format int32 */
  toAccountId: number;
  toAccountType?: string;
  /** @format int32 */
  paymentFrequencyTypeId: number;
  /** @format int32 */
  firstRepeatOnDay?: number;
  /** @format int32 */
  secondRepeatOnDay?: number;
  repeatOnLastBusinessDay?: boolean;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  hasNoEndDate?: boolean;
  transactionId?: string;
  batchId?: string;
  /** @format int32 */
  internalTransferStatusTypeId: number;
  /** @format int32 */
  internalTransferTemplateId?: number;
  /** @format int32 */
  paymentOptionTypeId?: number;
  isEdited: boolean;
  /** @format int32 */
  internalTransferIdForCancel: number;
};
export interface OfxTransfersModelDto {
  errorMessage?: string;
  transferItems?: OfxTransferItemDto[];
}
export interface OfxTransferItemDto {
  fromAccountType?: string;
  fromAccountNumber?: string;
  toAccountType?: string;
  toAccountNumber?: string;
  status?: string;
  /** @format int32 */
  id: number;
  /** @format date-time */
  transferDate?: string;
}
export type GetTransfersRequestDto = OfxBaseModelDto & {
  transferIds?: number[];
};
export type ResearchTransactionModelDto = ModelBaseDto & {
  /**
   * @format date-time
   * @minLength 1
   */
  date: string;
  /** @minLength 1 */
  accountNumber: string;
  /** @minLength 1 */
  checkImageNumber: string;
  /** @minLength 1 */
  description: string;
  /** @format decimal */
  amount?: number;
  /** @minLength 1 */
  checkNumber: string;
  /** @minLength 1 */
  accountName: string;
  /** @minLength 1 */
  accountType: string;
  /** @minLength 1 */
  transactionId: string;
  /** @minLength 1 */
  transactionType: string;
  isDebit: boolean;
  checkNumberSortValue?: string;
  transactionCodeDescription?: string;
};
export type ResearchTransactionFilterModelDto = ModelBaseDto & {
  /** @format date-time */
  transactionDateFrom?: string;
  /** @format date-time */
  transactionDateTo?: string;
  transactionId?: string;
  referenceNumberFrom?: string;
  referenceNumberTo?: string;
  /** @format decimal */
  amountFrom?: number;
  /** @format decimal */
  amountTo?: number;
  paymentDirection?: string;
  paymentTypes?: FilterPaymentTypeModelDto[];
  accounts?: CompanyAccountModelDto[];
  accountTypes?: AccountType2Dto[];
  /** @format int32 */
  maxRecordCount?: number;
};
export type FilterPaymentTypeModelDto = ModelBaseDto & {
  /** @minLength 1 */
  type: string;
};
export interface AccountType2Dto {
  name?: string;
}
export type FilterBaseModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  description?: string;
  /** @minLength 1 */
  reportType: string;
  /** @format int32 */
  standardReportTypeId?: number;
  isStandardReport: boolean;
  /** @minLength 1 */
  dateType: string;
  /** @format date-time */
  fromDate?: string;
  /** @format date-time */
  toDate?: string;
  /** @format date-time */
  specificDate?: string;
  /** @format int32 */
  priorDays?: number;
  /** @minLength 1 */
  sortBy: string;
  isAscendingSort: boolean;
  /** @format int32 */
  owner?: number;
  /** @minLength 1 */
  accountTypes: string;
  accounts?: FilterAccountModelDto[];
  /** @minLength 1 */
  type: string;
  /** @format int32 */
  sequence?: number;
  isFavorite: boolean;
};
export type FilterAccountModelDto = ModelBaseDto & {
  account: CompanyAccountModelDto;
};
export type SortRequestDto = ModelBaseDto & {
  reportingFilterIds?: number[];
};
export type ReportResultBaseModelDto = ModelBaseDto & {
  /** @minLength 1 */
  title: string;
  canEmail?: boolean;
  canPrint?: boolean;
  /** @minLength 1 */
  dateType: string;
  /** @minLength 1 */
  reportType: string;
  /** @format int32 */
  totalRecords?: number;
  downloadOptions: string[];
  /** @minLength 1 */
  sortBy: string;
  isAscending?: boolean;
};
export interface DashboardSummaryModelDto {
  /** @format decimal */
  depositAmount: number;
  /** @format decimal */
  timeDepositAmount: number;
  /** @format decimal */
  loanAmount: number;
}
export interface DashboardAccountDetailModelDto {
  transactions: DashboardTransactionDetailModelDto[];
  balanceHistory: AccountBalanceDetailDto[];
}
export interface DashboardTransactionDetailModelDto {
  /**
   * @format date-time
   * @minLength 1
   */
  date: string;
  /** @format int32 */
  id: number;
  checkImageNumber?: string;
  checkNumber?: string;
  isDebit: boolean;
  /** @minLength 1 */
  description: string;
  /** @format decimal */
  amount: number;
  /** @format decimal */
  balance: number;
  /** @format int64 */
  postingSequence: number;
  tranCodeType?: string;
  tranCodeDescription?: string;
}
export interface DashboardDepositTransactionDetailModelDto {
  /** @format decimal */
  currentBalance: number;
  /** @format decimal */
  availableBalance: number;
  /** @format decimal */
  collectedBalance: number;
}
export interface DashboardLoanTransactionDetailModelDto {
  /** @format decimal */
  originalLoanAmount: number;
  /** @format decimal */
  currentBalance: number;
  /** @minLength 1 */
  originalLoanTerm: string;
  /** @format decimal */
  interestRate: number;
  /** @format decimal */
  accruedInterest: number;
  /** @format decimal */
  totalAmountDue: number;
  /**
   * @format date-time
   * @minLength 1
   */
  lastPaymentDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  nextPaymentDate: string;
  /** @minLength 1 */
  paymentFrequency: string;
  /** @format int32 */
  remainingPayments: number;
  /** @format decimal */
  availableCredit?: number;
  /** @format decimal */
  lineOfCreditLimit?: number;
}
export interface DashboardTimeDepositTransactionDetailModelDto {
  /** @format decimal */
  currentBalance: number;
  /** @format decimal */
  originalBalance: number;
  /** @format decimal */
  interestPaidYtd: number;
  /** @format decimal */
  accruedInterest: number;
  /** @minLength 1 */
  term: string;
  /** @format decimal */
  interestRate: number;
  /**
   * @format date-time
   * @minLength 1
   */
  issuedDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  maturityDate: string;
}
export interface DashboardInformationCenterModelDto {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  type: string;
  /** @format int32 */
  sequence: number;
}
export interface DashboardResourcesModelDto {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  link: string;
  /** @minLength 1 */
  tooltip: string;
  displayExternalWarning: boolean;
  /** @minLength 1 */
  externalWarningMessage: string;
  /** @format int32 */
  sequence: number;
  /**
   * @format byte
   * @minLength 1
   */
  logo: string;
}
export type InternalTransferTransactionModelDto = SecurityBaseDto & {
  transactionId?: string;
  /** @format date-time */
  lastEditedDate?: string;
  /**
   * @format date-time
   * @minLength 1
   */
  transferDate: string;
  /** @format decimal */
  amount: number;
  memo?: string;
  /** @minLength 1 */
  status: string;
  confirmationNumber?: string;
  batchId?: string;
  message?: string;
  messageType?: string;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  comments?: string;
  isEdited: boolean;
  fromAccount: CompanyAccountModelDto;
  toAccount: CompanyAccountModelDto;
  errorSummary?: ErrorSummaryModelDto;
  /** @format date-time */
  createdDate: string;
  frequency?: string;
  frequencyDescription?: string;
  /** @format date-time */
  nextTransferDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  startDate?: string;
  audit?: InternalTransferAuditModelDto[];
  permissions?: ProductPermissionModelDto[];
  frequencyModel?: FrequencyModelDto;
  parentFrequencyModel?: FrequencyModelDto;
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  /** @format int32 */
  completedApprovalCount?: number;
  paymentOptionType?: string;
};
export type ErrorSummaryModelDto = SecurityBaseDto & {
  type: ErrorTypeModelDto;
  summaryMessage?: string;
  summaryMessageList?: string[];
  details?: ErrorDetailModelDto[];
};
export enum ErrorTypeModelDto {
  Error = 0,
  Info = 1,
  Warning = 2,
}
export interface ErrorDetailModelDto {
  attributeName?: string;
  key?: string;
  type: ErrorTypeModelDto;
  message?: string;
  messageList?: LookupModelDto[];
}
export interface InternalTransferAuditModelDto {
  /** @format date-time */
  date: string;
  text?: string;
  comments?: string;
  createdBy?: string;
}
export type FrequencyModelDto = ModelBaseDto & {
  repeatOn?: string;
  /** @format date-time */
  startOn?: string;
  /** @format date-time */
  endOn?: string;
  /** @format date-time */
  valueDate?: string;
  /** @format date-time */
  nextPaymentDate?: string;
  noEndDate?: boolean;
  /** @format int32 */
  repeatOnDay1?: number;
  /** @format int32 */
  repeatOnDay2?: number;
  repeatOnLastBusinessDay?: boolean;
  /** @minLength 1 */
  type: string;
  summary?: string;
};
export type WireModelDto = SecurityBaseDto & {
  /** @minLength 1 */
  transactionId: string;
  wireCompany: WireCompanyModelDto;
  debitAccount: CompanyAccountModelDto;
  /** @format decimal */
  amount: number;
  /** @minLength 1 */
  destinationCurrency: string;
  beneficiary: BeneficiaryModelDto;
  frequency: FrequencyModelDto;
  parentFrequency: FrequencyModelDto;
  additionalInformation?: LookupModelDto[];
  isInternational: boolean;
  isDliWire: boolean;
  /** @minLength 1 */
  status: string;
  /** @minLength 1 */
  audit: string;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  rateQuote?: RateQuoteModelDto;
  isUserApprovalLimitLess: boolean;
  permissions?: ProductPermissionModelDto[];
  errorSummary?: ErrorSummaryModelDto;
  isEdited: boolean;
  message?: string;
  messageType?: string;
  /** @minLength 1 */
  successMessage: string;
  /** @format date-time */
  createdDate: string;
  purpose?: string;
  confirmationNumber?: string;
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  wireTemplate?: WireTemplateModelDto;
  createdBy?: string;
  source?: string;
  /** @format int32 */
  completedApprovalCount?: number;
  referenceBeneficiary?: string;
  /** @format int32 */
  wireFileId?: number;
  /** @format guid */
  wireFileUniqueId?: string;
  fileName?: string;
  duplicatePreventionId?: string;
};
export type WireCompanyModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  isDliWireCompany: boolean;
};
export type BeneficiaryModelDto = SecurityBaseDto & {
  accountNumber?: string;
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  countryName?: string;
  notes?: string;
  audit?: string;
  /** @format date-time */
  createdDate?: string;
  status?: string;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  bank?: BankModelDto;
  intermediaryBanks?: IntermediaryBankModelDto[];
  isEdited?: boolean;
  isTemplateBeneficiary: boolean;
  postalCodeExtension?: string;
  permissions?: ProductPermissionModelDto[];
  isInternational: boolean;
  isUserApprovalLimitLess: boolean;
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  /** @format int32 */
  completedApprovalCount?: number;
  isForeignCurrency?: boolean;
  currencyType?: string;
  isFreeForm: boolean;
  buildingNumber?: string;
  streetName?: string;
  department?: string;
  subDepartment?: string;
  postBox?: string;
  buildingName?: string;
  buildingFloor?: string;
  buildingRoom?: string;
  townLocationName?: string;
  districtName?: string;
  isIsoCompliant: boolean;
};
export type BankModelDto = ModelBaseDto & {
  bankId?: string;
  bankIdType?: string;
  name?: string;
  /** @format int32 */
  addressId: number;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  postalCodeExtension?: string;
  country?: string;
  countryName?: string;
  isInternational: boolean;
  notes?: string;
  onUs: boolean;
  buildingNumber?: string;
  streetName?: string;
  department?: string;
  subDepartment?: string;
  postBox?: string;
  buildingName?: string;
  buildingFloor?: string;
  buildingRoom?: string;
  townLocationName?: string;
  districtName?: string;
};
export type IntermediaryBankModelDto = BankModelDto & {
  accountNumber?: string;
  isDeleted: boolean;
};
export type RateQuoteModelDto = ModelBaseDto & {
  /** @minLength 1 */
  amexContractNumber: string;
  /** @format int32 */
  amexQuoteId?: number;
  /** @format decimal */
  originAmount: number;
  /** @format decimal */
  destinationAmount: number;
  /** @minLength 1 */
  destinationCurrencyCode: string;
  /**
   * @format date-time
   * @minLength 1
   */
  valueDate: string;
  /** @format decimal */
  wireFee: number;
  /** @format decimal */
  exchangeFee: number;
  /** @format decimal */
  totalCost: number;
  /** @format int32 */
  expirationTime: number;
  /** @format decimal */
  conversionRate: number;
};
export type WireTemplateModelDto = ModelBaseDto & {
  name?: string;
  wireCompany: WireCompanyModelDto;
  debitAccount: CompanyAccountModelDto;
  beneficiary: BeneficiaryModelDto;
  purpose?: string;
  notes?: string[];
  isInternational: boolean;
  /** @minLength 1 */
  status: string;
  completedApprovals?: UserApprovalDto[];
  /** @format int32 */
  completedApprovalCount?: number;
  availableApprovers?: string[];
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  errorSummary?: ErrorSummaryModelDto;
  isEdited: boolean;
  permissions?: ProductPermissionModelDto[];
  audit?: string;
  isPendingEdit: boolean;
  /** @minLength 1 */
  successMessage: string;
  initiatedRecurringWire: boolean;
  referenceBeneficiary?: string;
  /** @format int32 */
  createdBy: number;
};
export type AchPaymentModelDto = ModelBaseDto & {
  recipients?: AchRecipientModelDto[];
  /** @minLength 1 */
  type: string;
  frequency: AchFrequencyModelDto;
  parentFrequency: AchFrequencyModelDto;
  applyUpdatesToBatch: boolean;
  /** @format int32 */
  parentId: number;
  errorSummary?: ErrorSummaryModelDto;
  /** @format int32 */
  batchId: number;
  offsetAccount: LookupModelDto;
  /** @minLength 1 */
  name: string;
  templateName?: string;
  /** @minLength 1 */
  parentFrequencyType: string;
  /** @minLength 1 */
  achCompanyName: string;
  /** @minLength 1 */
  achCompanyId: string;
  /** @minLength 1 */
  secCode: string;
  isEdited: boolean;
  /** @minLength 1 */
  discretionaryData: string;
  /** @minLength 1 */
  entryDescription: string;
  restricted: boolean;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /** @minLength 1 */
  errorMessage: string;
  /** @minLength 1 */
  transactionId: string;
  /** @minLength 1 */
  status: string;
  /** @minLength 1 */
  successMessage: string;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  achCompany: AchCompanyModelDto;
  securityMessage?: SecurityMessageModelDto;
  permissions?: ProductPermissionModelDto[];
  userPermissions?: string[];
  audit?: string;
  message?: string;
  messageType?: string;
  completedApprovals?: UserApprovalDto[];
  /** @format int32 */
  completedApprovalCount?: number;
  availableApprovers?: string[];
  /** @format int32 */
  fileArchiveId?: number;
  canReverseFull: boolean;
  canReverseTransactions: boolean;
  recipientIdsforReversal?: number[];
  /** @format int32 */
  reversalAchPaymentId?: number;
  fileName?: string;
  fileSize?: string;
  /** @format int32 */
  achPaymentDraftId: number;
  failureReason?: string;
  /** @format int32 */
  achPaymentTypeId: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  achPaymentTypeName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  achPaymentTypeDescription: string;
  paymentProcessingBasedOn1Record?: boolean;
  copiedFromTransactionId?: string;
  duplicatePreventionId?: string;
  destinationCountryCode?: string;
  foreignExchangeIndicator?: string;
  /** @format int32 */
  foreignExchangeReferenceNumber?: number;
  foreignExchangeReference?: string;
  destinationCurrencyCode?: string;
  originatorCurrencyCode?: string;
  /** @format date-time */
  createdDate: string;
};
export type AchRecipientModelDto = ModelBaseDto & {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  idNumber: string;
  /** @minLength 1 */
  accountNumber: string;
  /** @minLength 1 */
  accountType: string;
  /** @minLength 1 */
  routingNumber: string;
  /** @minLength 1 */
  transactionType: string;
  /** @format decimal */
  amount: number;
  prenote: boolean;
  hold: boolean;
  reversal: boolean;
  /** @minLength 1 */
  addendaType: string;
  /** @minLength 1 */
  addendaBody: string;
  /** @minLength 1 */
  errorMessage: string;
  isRoutingNumberValid: boolean;
  isRoutingNumberOnUs: boolean;
  addenda?: LookupModelDto[];
  /** @format int32 */
  achMasterRecipientId?: number;
};
export type AchFrequencyModelDto = ModelBaseDto & {
  repeatOn?: string;
  /** @format date-time */
  startOn?: string;
  /** @format date-time */
  endOn?: string;
  /** @format date-time */
  effectiveDate?: string;
  /** @format date-time */
  initiatedDate?: string;
  noEndDate: boolean;
  /** @format int32 */
  repeatOnDay1: number;
  /** @format int32 */
  repeatOnDay2: number;
  repeatOnLastBusinessDay?: boolean;
  /** @minLength 1 */
  type: string;
  /** @format date-time */
  nextPaymentDate?: string;
  summary?: string;
};
export interface InternalTransferTransactionModel2Dto {
  /** @format int32 */
  id: number;
  transactionId?: string;
  memo?: string;
  status?: string;
  batchId?: string;
  /** @format decimal */
  amount: number;
  /** @format date-time */
  transferDate?: string;
  /** @format date-time */
  createdDate: string;
  createdBy?: UserModel2Dto;
  /** @format date-time */
  updatedDate: string;
  updatedBy?: UserModel2Dto;
  toAccount?: AccountModel2Dto;
  fromAccount?: AccountModel2Dto;
  frequency?: FrequencyModel2Dto;
  /** @format int32 */
  numberOfApprovalsNeeded?: number;
  audit?: string;
  paymentOptionType?: string;
}
export interface UserModel2Dto {
  /** @format int32 */
  id: number;
  name?: string;
}
export interface AccountModel2Dto {
  /** @format int32 */
  id: number;
  name?: string;
  type?: string;
  accountDisplayLabel?: string;
}
export interface FrequencyModel2Dto {
  type?: string;
  summary?: string;
  /** @format date-time */
  valueDate?: string;
}
export interface WireModel2Dto {
  /** @format int32 */
  id: number;
  transactionId?: string;
  status?: string;
  destinationCurrency?: string;
  isInternational: boolean;
  /** @format decimal */
  amount: number;
  beneficiary?: BeneficiaryModel2Dto;
  /** @format date-time */
  createdDate: string;
  createdBy?: UserModel2Dto;
  /** @format date-time */
  updatedDate: string;
  updatedBy?: UserModel2Dto;
  debitAccount?: AccountModel2Dto;
  frequency?: FrequencyModel2Dto;
  /** @format int32 */
  numberOfApprovalsNeeded?: number;
  audit?: string;
  isUnsupportedRequest: boolean;
}
export interface BeneficiaryModel2Dto {
  accountNumber?: string;
  name?: string;
}
export interface AchPaymentModel2Dto {
  /** @format int32 */
  id: number;
  name?: string;
  transactionId?: string;
  status?: string;
  secCode?: string;
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  debitAmount?: number;
  /** @format date-time */
  createdDate: string;
  createdBy?: UserModel2Dto;
  /** @format date-time */
  updatedDate: string;
  updatedBy?: UserModel2Dto;
  /** @format int32 */
  numberOfApprovalsNeeded?: number;
  audit?: string;
}
export type AchExceptionModelDto = ModelBaseDto & {
  account?: AccountModelDto;
  /** @format int32 */
  achExceptionDetailId: number;
  /** @format int32 */
  achExceptionDecisionStatusId: number;
  issuingCompany?: string;
  type?: string;
  secCode?: string;
  /** @format decimal */
  amount: number;
  description?: string;
  pay?: boolean;
  itemId?: string;
  priorDecision?: string;
  protected?: string;
};
export type AchExceptionDetailDataModelDto = ModelBaseDto & {
  /** @format int32 */
  achExceptionDetailId: number;
  /** @format int32 */
  accountId: number;
  accountName?: string;
  accountNumber?: string;
  accountType?: string;
  /** @format decimal */
  amount?: number;
  batchNumber?: string;
  /** @format int32 */
  companyId: number;
  companyName?: string;
  achCompanyName?: string;
  achCompanyId?: string;
  decisionBy?: string;
  /** @format date-time */
  decisionDate?: string;
  /** @format date-time */
  postedDate?: string;
  /** @format int32 */
  achExceptionDecisionStatusId: number;
  entryDescription?: string;
  exceptionType?: string;
  exceptionItemId?: string;
  fromWorkStation?: string;
  secCode?: string;
  protected?: string;
};
export type ArpExceptionModelDto = ModelBaseDto & {
  account?: AccountModelDto;
  accountNumber?: string;
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  arpExceptionDetailId: number;
  checkNumber?: string;
  checkImageNumber?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  date?: string;
  description?: string;
  fiId?: string;
  /** @format decimal */
  issuedAmount: number;
  /** @format decimal */
  paidAmount: number;
  /** @format date-time */
  issuedDate?: string;
  /** @format date-time */
  postedDate?: string;
  payee?: string;
  reason?: string;
  pay?: boolean;
  decisionTaken?: string;
  /** @format date-time */
  decisionDate?: string;
  decisionHistory: boolean;
  fromWorkstation?: string;
  sourceOfEntry?: string;
  protected?: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  posPayItemId?: string;
  transactionId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  decisions?: ArpExceptionDetailDecisionModelDto[];
};
export interface ArpExceptionDetailDecisionModelDto {
  /** @format int32 */
  arpExceptionDetailDecisionId: number;
  /** @format int32 */
  arpExceptionDetailId: number;
  decisionBy?: string;
  decisionStatus?: string;
  /** @format date-time */
  decisionDate: string;
  /** @format date-time */
  createdDate: string;
  arpExceptionDetail?: ArpExceptionDetailModelDto;
}
export interface ArpExceptionDetailModelDto {
  /** @format int32 */
  arpExceptionDetailId: number;
  accountNumber?: string;
  checkNumber?: string;
  checkImageNumber?: string;
  decisionTaken?: string;
  /** @format date-time */
  decisionDate?: string;
  /** @format decimal */
  issuedAmount: number;
  /** @format decimal */
  paidAmount: number;
  /** @format date-time */
  issuedDate?: string;
  issuedPayee?: string;
  exceptionsReason?: string;
  pay?: boolean;
  fromWorkStation?: string;
  sourceOfEntry?: string;
  protected?: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  posPayItemId?: string;
  /** @format date-time */
  postedDate: string;
  transactionId?: string;
  decisions?: ArpExceptionDetailDecisionModelDto[];
}
export type AchExceptionDetailModelDto = ModelBaseDto & {
  accountId?: string;
  achCompanyName?: string;
  accountNumber?: string;
  accountType?: string;
  /** @format decimal */
  amount: number;
  batchNumber?: string;
  decisionBy?: string;
  /** @format int32 */
  achExceptionDecisionStatusId: number;
  /** @format date-time */
  decisionDate?: string;
  exceptionItemId?: string;
  exceptionReason?: string;
  exceptionType?: string;
  entryDescription?: string;
  fromWorkStation?: string;
  /** @format int32 */
  institutionId: number;
  /** @format date-time */
  postedDate?: string;
  secCode?: string;
  sourceOfEntry?: string;
};
export interface StpModelDto {
  /** @format int32 */
  id: number;
  account?: CompanyAccountModelDto;
  checkNumbers?: number[];
  /** @format date-time */
  checkDate?: string;
  /** @format double */
  checkAmount?: number;
  payee?: string;
  remarks?: string[];
  /** @format date-time */
  createdDate: string;
  createdBy?: string;
  enteredBy?: string;
  /** @format date-time */
  expirationDate?: string;
  sequenceNumber?: string;
  status?: string;
  permissions?: ProductPermissionModelDto[];
  /** @format int32 */
  totalApprovalsRequired: number;
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  /** @format int32 */
  completedApprovalCount?: number;
}
export interface DashboardEntitlementsModelDto {
  /** @minLength 1 */
  widget: string;
  /** @minLength 1 */
  permission: string;
}
export type DashboardAccountsGroupModelDto = ModelBaseDto & {
  accounts?: CompanyAccountModelDto[];
  /** @minLength 1 */
  name: string;
};
export type InternalTransferBatchModelDto = SecurityBaseDto & {
  internalTransfers?: InternalTransferTransactionModelDto[];
  comments?: string;
  successMessage?: string;
  errorMessage?: string;
};
export type WireListModelDto = SecurityBaseDto & {
  wires?: WireModelDto[];
  errorMessage?: string;
  successMessage?: string;
  errorSummary?: ErrorSummaryModelDto;
  bulkWireIds?: number[];
};
export type AchPaymentListModelDto = SecurityBaseDto & {
  achPayments?: AchPaymentModelDto[];
  successMessage?: string;
  errorMessage?: string;
  bulkAchIds?: number[];
};
export type PaymentBatchModelDto = SecurityBaseDto & {
  batch?: LookupModelDto[];
};
export type ArpExceptionDetailDataModelDto = ModelBaseDto & {
  accountNumber?: string;
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  arpExceptionDetailId: number;
  checkImageNumber?: string;
  checkNumber?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  date?: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  decisionHistory: boolean;
  decisionTaken?: string;
  description?: string;
  exceptionsReason?: string;
  fiId?: string;
  fromWorkStation?: string;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  issuedDate?: string;
  issuedPayee?: string;
  lastDecisionBy?: string;
  /** @format date-time */
  lastDecisionDate?: string;
  /** @format decimal */
  paidAmount?: number;
  pay?: boolean;
  posPayItemId?: string;
  protected?: string;
  /** @format date-time */
  postDate?: string;
  sourceOfEntry?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  /** @format guid */
  returnReasonUniqueId?: string;
  /** @format guid */
  arpExceptionDetailUniqueId: string;
  decisions?: ArpExceptionDetailDecisionModelDto[];
};
export interface SaveAchExceptionDetailResponseModelDto {
  /** @format int32 */
  totalDecisionsCount: number;
  /** @format int32 */
  failedDecisionsCount: number;
  failedAchExceptions?: AchExceptionDetailModelDto[];
}
export type DashboardPaymentResponseModelDto = ModelBaseDto & {
  type?: string;
  successMessage?: string;
  errorMessage?: string;
  transactions?: DashboardPaymentTransactionResponseModelDto[];
};
export type DashboardPaymentTransactionResponseModelDto = ModelBaseDto & {
  successMessage?: string;
  errorMessage?: string;
  transactionId?: string;
  /** @format int32 */
  completedApprovals: number;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
};
export interface DashboardWidgetModelDto {
  /** @format int32 */
  id: number;
  title?: string;
  description?: string;
  type?: string;
  image?: string;
  isResizable?: boolean;
  fullScreenAllowed?: boolean;
  /** @format int32 */
  minWidth?: number;
  /** @format int32 */
  maxWidth?: number;
  /** @format int32 */
  minHeight?: number;
  /** @format int32 */
  maxHeight?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  left?: number;
  /** @format int32 */
  top?: number;
  isRemoved?: boolean;
  hasPermission?: boolean;
  permissionMessage?: string;
  isRequired?: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface PendingApprovalCountsModelDto {
  /** @format int32 */
  transfers: number;
  /** @format int32 */
  loanpayments: number;
  /** @format int32 */
  wires: number;
  /** @format int32 */
  ach: number;
  /** @format int32 */
  stoppayments: number;
  /** @format int32 */
  users: number;
}
export type ProductModelDto = ModelBaseDto & {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  type: string;
  status?: string;
  name?: string;
  permissions?: ProductPermissionsModelDto;
};
export interface ProductPermissionsModelDto {
  permissions?: ProductPermissionModelDto[];
  /** @format int32 */
  productId?: number;
  productName?: string;
}
export interface ProductSettingsModelDto {
  products?: ProductModelDto[];
  billPaySettings?: BillPaySettingsModelDto;
}
export interface BillPaySettingsModelDto {
  allowIPayForMobile: boolean;
}
export type ThresholdCollectionDto = ThresholdDto[];
export interface AccountListDataModelDto {
  /** @format int32 */
  id: number;
  /** @format decimal */
  collectedBalance?: number;
  permissions?: ProductPermissionModelDto[];
}
export type GenericEntityResponseModelDto = RequestResponseDto & {
  /** @format int32 */
  id: number;
  name?: string;
};
export type GenericDownloadResponseModelDto = GenericDownloadResponseModelOfByteOfDto & object;
export type GenericDownloadResponseModelOfByteOfDto = RequestResponseDto & {
  fileName?: string;
  /** @format byte */
  fileContent?: string;
};
export interface ExternalReportFileDownloadModelDto {
  /** @format int32 */
  externalReportFileId: number;
  fileOutputType: OutPutTypeDto;
}
export type GenericEntityCollectionResponseModelDto = RequestResponseDto & {
  genericEntityResponseModels?: GenericEntityResponseModelDto[];
};
export type ExternalReportModelDto = ModelBaseDto & {
  externalReportType?: ExternalReportTypeModelDto;
  externalReportGroupIds?: number[];
  reportName?: string;
  accounts?: AccountModelDto[];
  timePeriod?: TimePeriodModelDto;
  /** @format date-time */
  activityPeriodStart?: string;
  /** @format date-time */
  activityPeriodEnd?: string;
  frequency?: FrequencyModel3Dto;
  /** @format date-time */
  createdDate: string;
  createdBy?: UserModelDto;
};
export type ExternalReportTypeModelDto = GenericEnumModelDto & {
  referenceId?: string;
  timePeriodTypesConstraint?: GenericEnumModelDto[];
};
export interface GenericEnumModelDto {
  /** @format int32 */
  id: number;
  name?: string;
  displayName?: string;
  description?: string;
}
export type TimePeriodModelDto = GenericEnumModelDto & {
  /** @format int32 */
  timePeriodInterval?: number;
};
export type FrequencyModel3Dto = GenericEnumModelDto & {
  /** @format int32 */
  repeatOnDayOne?: number;
};
export interface ExternalReportFileModelDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  externalReportId: number;
  reportName?: string;
  fileName?: string;
  externalReportType?: ExternalReportTypeModelDto;
  jobStatusType: JobStatusTypeEnumDto;
  /** @format date-time */
  executionStartTime?: string;
  /** @format date-time */
  executionCompleteTime?: string;
  user?: UserModelDto;
  account?: AccountModelDto;
  detail?: string;
  availableFileOutputTypes?: GenericEnumCollectionModelDto;
}
export enum JobStatusTypeEnumDto {
  Undefined = 0,
  Pending = 5,
  Started = 6,
  Completed = 7,
  Failed = 8,
  Cancelled = 9,
  Retry = 10,
  Hold = 11,
}
export type GenericEnumCollectionModelDto = RequestResponseDto & {
  values?: GenericEnumModelDto[];
};
export interface ExternalReportFileSearchCriteriaModelDto {
  accountIds?: number[];
  reportTypeIds?: number[];
  /** @format date-time */
  executionStartTime: string;
  /** @format date-time */
  executionEndTime: string;
  jobStatusTypeIds?: number[];
  userIds?: number[];
  pagingParameters?: PagingParametersModelDto;
}
export interface PagingParametersModelDto {
  orderBys?: OrderByModelDto[];
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  pageNumber: number;
  searchBys?: SearchByModelDto[];
}
export interface OrderByModelDto {
  descending: boolean;
  name?: string;
}
export interface SearchByModelDto {
  name?: string;
  value?: string;
  secondValue?: string;
  searchType?: string;
  values?: TextKeyCollectionDto;
}
export interface ExternalReportSearchCriteriaModelDto {
  accountIds?: number[];
  reportTypeIds?: number[];
  frequencyTypeIds?: number[];
  createdUserIds?: number[];
  pagingParameters?: PagingParametersModelDto;
}
export interface PagedListModelOfAccountModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: AccountModelDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface ReportAccountsModelDto {
  pagingParameters?: PagingParametersModelDto;
}
export type ExternalReportGroupModelDto = ModelBaseDto & {
  groupName?: string;
  /** @format int32 */
  externalReportGroupTemplateId: number;
  externalReports?: ExternalReportModelDto[];
  timePeriod?: TimePeriodModelDto;
  frequency?: FrequencyModel3Dto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
};
export type ExternalReportGroupTemplateModelDto = ModelBaseDto & {
  groupTemplateName?: string;
  timePeriod?: TimePeriodModelDto;
  frequency?: FrequencyModel3Dto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
};
export type BeneficiarySearchCriteriaModelDto = ModelBaseDto & {
  beneficiaryType?: string;
  status?: string;
  beneficiaryName?: string;
  accountNumber?: string;
  bankId?: string;
  bankName?: string;
  country?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  createdDateStart?: string;
  /** @format date-time */
  createdDateEnd?: string;
  createdDateType?: string;
  requireEntitlement: boolean;
};
export type DeleteBeneficiaryModelDto = SecurityBaseDto & {
  /** @format int32 */
  beneficiaryId: number;
};
export type ApproveBeneficiaryModelDto = SecurityBaseDto & {
  /** @format int32 */
  beneficiaryId: number;
  comments?: string;
};
export type CreditorModelDto = BeneficiaryModelDto & object;
export type SearchCreditorsRequestDto = BaseRequest2Dto & {
  beneficiaryType?: string;
  status?: string;
  beneficiaryName?: string;
  accountNumber?: string;
  bankId?: string;
  bankName?: string;
  country?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  createdDateStart?: string;
  /** @format date-time */
  createdDateEnd?: string;
  createdDateType?: string;
  requireEntitlement: boolean;
};
export interface WireConfigurationDto {
  fiDliProductEnabled: boolean;
  fiDliUsdEnabled: boolean;
  dliCompanyProductEnabled: boolean;
  dliCompanyInternationalUsdEnabled: boolean;
  requirePurposeDli: boolean;
  fiWireProductEnabled: boolean;
  fiInternationalUsdEnabled: boolean;
  companyWireProductEnabled: boolean;
  companyInternationalUsdEnabled: boolean;
  /** @format int32 */
  maximumFutureDays?: number;
  requirePurpose?: boolean;
  useExternalFxWireProcessing: boolean;
  useExternalFxQuoteRatesheets: boolean;
}
export type WireSearchCriteriaModelDto = ModelBaseDto & {
  wireType?: string;
  wireCompanies?: WireCompanyModelDto[];
  statuses?: StatusModel2Dto[];
  debitAccounts?: CompanyAccountModelDto[];
  beneficiaryName?: string;
  transactionID?: string;
  omadNumber?: string;
  currency?: string;
  wireAmountType?: string;
  /** @format decimal */
  wireAmount?: number;
  /** @format decimal */
  wireAmountMin?: number;
  /** @format decimal */
  wireAmountMax?: number;
  valueDateType?: string;
  /** @format date-time */
  valueDate?: string;
  /** @format date-time */
  valueDateStart?: string;
  /** @format date-time */
  valueDateEnd?: string;
  createdDateType?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  createdDateStart?: string;
  /** @format date-time */
  createdDateEnd?: string;
  nextPaymentDateType?: string;
  /** @format date-time */
  nextPaymentDate?: string;
  /** @format date-time */
  nextPaymentDateStart?: string;
  /** @format date-time */
  nextPaymentDateEnd?: string;
  frequency?: LookupModelDto[];
  isDownloadReport: boolean;
  /** @format int32 */
  wireFileId?: number;
  /** @format guid */
  wireFileUniqueId?: string;
  isWireTotalsRequired: boolean;
};
export type StatusModel2Dto = ModelBaseDto & {
  name?: string;
};
export type CancelWireModelDto = SecurityBaseDto & {
  /** @format int32 */
  wireId: number;
  comments?: string;
};
export type RequestQuoteModelDto = ModelBaseDto & {
  /** @minLength 1 */
  destinationCurrencyCode: string;
  /** @format decimal */
  amount: number;
  isAmountUSD: boolean;
};
export type LookupListModelDto = SecurityBaseDto & {
  lookups?: LookupModelDto[];
};
export type CurrencyModelDto = ModelBaseDto & {
  currencyCode?: string;
  name?: string;
};
export type WireTemplateSearchModelDto = WireSearchCriteriaModelDto & {
  templateName?: string;
  wireCompanies?: WireCompanyModelDto[];
};
export type FileFormatModelDto = ModelBaseDto & {
  templateName?: string;
  fileFormatType?: string;
  fieldDelimiter?: string;
  /** @format int32 */
  headerRows?: number;
  /** @format int32 */
  footerRows?: number;
  fileFormatDetails?: FileFormatDetailModelDto[];
};
export type FileFormatDetailModelDto = ModelBaseDto & {
  fileFormatField?: string;
  /** @format int32 */
  order: number;
  /** @format int32 */
  beginPosition?: number;
  /** @format int32 */
  endPosition?: number;
  additionalInformationOne?: string;
  additionalInformationTwo?: string;
  additionalInformationThree?: string;
  additionalInformationFour?: string;
};
export interface FileImportLayoutFieldModelDto {
  templateType?: string;
  fieldGroups?: FileFormatFieldGroupModelDto[];
}
export interface FileFormatFieldGroupModelDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
  info?: string;
  fields?: FileFormatFieldModelDto[];
}
export interface FileFormatFieldModelDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
  info?: string;
  required: boolean;
  /** @format int32 */
  importFieldGroupId?: number;
}
export interface WireParseResultModelDto {
  fileErrors?: WireFileErrorDto[];
  wires?: WireModelDto[];
}
export interface WireFileErrorDto {
  /** @format int32 */
  lineNumber: number;
  errorText?: string;
}
export interface PagedListModelOfWireFileModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: WireFileModelDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export type WireFileModelDto = ModelBaseDto & {
  /** @format int32 */
  wireFileId: number;
  fileName?: string;
  /** @format int32 */
  fileSize: number;
  uploadedBy?: string;
  /** @format date-time */
  uploadDate: string;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  totalWireCount: number;
  /** @format int32 */
  batchCount: number;
  /** @format int32 */
  approvedCount: number;
  /** @format int32 */
  pendingCount: number;
  /** @format int32 */
  rejectedCount: number;
  /** @format int32 */
  expiredCount: number;
  isValid: boolean;
  isExpired: boolean;
  isConfirmed: boolean;
  /** @format guid */
  wireFileUniqueId: string;
  processedBy?: string;
  /** @format date-time */
  processedDate?: string;
  contentData?: string;
  fileFormat?: string;
  isDuplicated: boolean;
  /** @format guid */
  duplicatedWireFileUniqueId?: string;
};
export type WireFileSearchCriteriaModelDto = ModelBaseDto & {
  wireFileId?: string;
  /** @format guid */
  wireFileUniqueId: string;
  fileName?: string;
  uploadedByIds?: number[];
  uploadDateType?: string;
  /** @format date-time */
  uploadDate?: string;
  /** @format date-time */
  uploadDateStart?: string;
  /** @format date-time */
  uploadDateEnd?: string;
  wireAmountType?: string;
  /** @format decimal */
  wireAmount?: number;
  /** @format decimal */
  wireAmountMin?: number;
  /** @format decimal */
  wireAmountMax?: number;
  pagingParameters?: PagingParametersModelDto;
  isDownloadReport: boolean;
};
export interface WireProcessResultModelDto {
  fileErrors?: WireFileErrorDto[];
  wires?: WireModelDto[];
}
export interface WireSearchModelDto {
  searchResults?: WireModelDto[];
  totals?: WireTotalsModelDto[];
}
export interface WireTotalsModelDto {
  status?: string;
  /** @format int32 */
  count: number;
  /** @format decimal */
  totalAmount: number;
}
export interface StpSearchModelDto {
  accounts?: CompanyAccountModelDto[];
  statuses?: StatusModel2Dto[];
  checkNumberType?: string;
  checkNumber?: string;
  checkNumberEnd?: string;
  checkNumberStart?: string;
  checkAmountType?: string;
  /** @format decimal */
  checkAmount?: number;
  /** @format decimal */
  checkAmountEnd?: number;
  /** @format decimal */
  checkAmountStart?: number;
  checkDateType?: string;
  /** @format date-time */
  checkDate?: string;
  /** @format date-time */
  checkDateStart?: string;
  /** @format date-time */
  checkDateEnd?: string;
  createdDateType?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  createdDateStart?: string;
  /** @format date-time */
  createdDateEnd?: string;
  payee?: string;
}
export type InternalTransferAddTransactionModelDto = ModelBaseDto & {
  batchId?: string;
  type?: string;
  errorSummary?: ErrorSummaryModelDto;
  securityMessage?: SecurityMessageModelDto;
  templateName?: string;
  /** @format int32 */
  templateId?: number;
  audit?: string;
  transactions?: InternalTransferAddTransactionDetailModelDto[];
};
export type InternalTransferAddTransactionDetailModelDto = ModelBaseDto & {
  /** @format date-time */
  transferDate?: string;
  /** @format decimal */
  amount?: number;
  memo?: string;
  fromAccount?: CompanyAccountModelDto;
  toAccount?: CompanyAccountModelDto;
  errorSummary?: ErrorSummaryModelDto;
  frequency?: FrequencyModelDto;
  status?: string;
  transactionId?: string;
  paymentOptionType: LoanPaymentOptionTypeEnumDto;
};
export enum LoanPaymentOptionTypeEnumDto {
  Undefined = 0,
  RegularPayment = 1,
  PrincipalOnly = 2,
}
export interface InternalTransferTransactionFilterModelDto {
  /** @format date-time */
  transferDateFrom?: string;
  /** @format date-time */
  transferDateTo?: string;
  accountOperator?: string;
  /** @format decimal */
  fromAmount?: number;
  /** @format decimal */
  toAmount?: number;
  transactionId?: string;
  /** @format date-time */
  createdDateFrom?: string;
  /** @format date-time */
  createdDateTo?: string;
  transferDateOperator?: string;
  amountOperator?: string;
  createdDateOperator?: string;
  status?: LookupModelDto[];
  fromAccounts?: CompanyAccountModelDto[];
  toAccounts?: CompanyAccountModelDto[];
  bothAccounts?: CompanyAccountModelDto[];
  batchId?: string;
  paymentOptionType?: LookupModelDto[];
}
export interface RecurringInternalTransferFilterModelDto {
  status?: LookupModelDto[];
  accountOperator?: string;
  fromAccounts?: CompanyAccountModelDto[];
  toAccounts?: CompanyAccountModelDto[];
  bothAccounts?: CompanyAccountModelDto[];
  transactionId?: string;
  frequency?: LookupModelDto[];
  amountType?: string;
  /** @format decimal */
  amountSpecific: number;
  /** @format decimal */
  amountStart: number;
  /** @format decimal */
  amountEnd: number;
  nextTransferDateType?: string;
  /** @format date-time */
  nextTransferDateSpecific?: string;
  /** @format date-time */
  nextTransferDateStart?: string;
  /** @format date-time */
  nextTransferDateEnd?: string;
  createdDateType?: string;
  /** @format date-time */
  createdDateSpecific?: string;
  /** @format date-time */
  createdDateStart?: string;
  /** @format date-time */
  createdDateEnd?: string;
}
export type CancelTransferModelDto = SecurityBaseDto & {
  /** @format int32 */
  internalTransferId: number;
  comments?: string;
};
export interface InternalTransferTemplateFilterModelDto {
  templateName?: string;
  transferType?: LookupModelDto[];
  amountOperator?: string;
  /** @format decimal */
  amountStart?: number;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amountSpecific: number;
  status?: LookupModelDto[];
  fromAccounts?: CompanyAccountModelDto[];
  toAccounts?: CompanyAccountModelDto[];
  bothAccounts?: CompanyAccountModelDto[];
  accountOperator?: string;
}
export type InternalTransferTemplateDetailModelDto = ModelBaseDto & {
  templateName?: string;
  transferFromAccount?: string;
  transferToAccount?: string;
  fromAccount?: CompanyAccountModelDto;
  toAccount?: CompanyAccountModelDto;
  memo?: string;
  audit?: string;
  transferType?: string;
  /** @format decimal */
  totalTransferAmount: number;
  status?: string;
  templateTransferInfo?: CompanyAccountModelDto[];
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  /** @format int32 */
  completedApprovalCount?: number;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  permissions?: ProductPermissionModelDto[];
};
export type InternalTransferTemplateModelDto = ModelBaseDto & {
  templateName?: string;
  transferType?: string;
  fromAccount?: CompanyAccountModelDto;
  toAccount?: CompanyAccountModelDto;
  /** @format decimal */
  amount: number;
  status?: string;
  memo?: string;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  completedApprovals?: UserApprovalDto[];
  availableApprovers?: string[];
  /** @format int32 */
  completedApprovalCount?: number;
  internalTransferTemplateDetails?: InternalTransferTemplateDetailModelDto[];
  permissions?: ProductPermissionModelDto[];
  isPendingApproval: boolean;
  /** @format int32 */
  createdBy: number;
};
export interface BillPaySsoDto {
  sessionId?: string;
  url?: string;
}
export interface GetInstitutionResponseModelDto {
  institution?: InstitutionModelDto;
}
export interface InstitutionModelDto {
  /** @format date-time */
  cutoff: string;
  nonProcessingDays?: string[];
  paymentDateModel: PaymentDateModelDto;
}
export enum PaymentDateModelDto {
  None = 0,
  ProcessDate = 1,
  DueDate = 1,
}
export interface GetPayeeResponseModelDto {
  payee?: PayeeModelDto;
}
export interface PayeeModelDto {
  account?: AccountModel3Dto;
  address?: AddressModelDto;
  defaultPayFromAccount?: AccountModel3Dto;
  email?: string;
  /** @format int32 */
  estimatedArrivalDay: number;
  /** @format date-time */
  firstAvailableEstimatedArrivalDate?: string;
  /** @format date-time */
  firstAvailableProcessDate?: string;
  id?: string;
  keyword?: string;
  name?: string;
  nickName?: string;
  paymentMethod?: PaymentMethodDto;
  phoneNumber?: string;
  status?: PayeeStatusDto;
}
export interface AccountModel3Dto {
  accountNumber?: string;
  accountType?: AccountType3Dto;
  id?: string;
  name?: string;
  routingNumber?: string;
}
export enum AccountType3Dto {
  Checking = 0,
  Savings = 1,
}
export interface AddressModelDto {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}
export enum PaymentMethodDto {
  Check = 0,
  Email = 1,
  Electronic = 2,
}
export enum PayeeStatusDto {
  Activated = 0,
  NotActivated = 1,
  Deleted = 2,
}
export interface GetPaymentsResponseModelDto {
  payments?: PaymentModelDto[];
}
export interface PaymentModelDto {
  /** @format decimal */
  amount: number;
  comment?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  estimatedArrivalDate?: string;
  fromAccount?: AccountModel3Dto;
  id?: string;
  payee?: PayeeSummaryModelDto;
  paymentMethod?: PaymentMethodDto;
  paymentType: PaymentTypeDto;
  /** @format date-time */
  processDate?: string;
  recurrence?: PaymentRecurrenceModelDto;
  status?: PaymentStatusDto;
}
export interface PayeeSummaryModelDto {
  id?: string;
  name?: string;
  nickName?: string;
  paymentMethod?: PaymentMethodDto;
}
export enum PaymentTypeDto {
  None = 0,
  Scheduled = 1,
  Past = 2,
}
export interface PaymentRecurrenceModelDto {
  dayOfWeek?: DayOfWeekDto;
  /** @format date-time */
  expirationDate?: string;
  frequency?: PaymentFrequencyDto;
  isFinite: boolean;
  /** @format int32 */
  occurrenceCount?: number;
  paymentDays?: PaymentDayModelDto[];
  /** @format date-time */
  startEstimatedArrivalDate?: string;
  /** @format date-time */
  startProcessDate?: string;
}
export enum PaymentFrequencyDto {
  None = 0,
  Once = 1,
  Weekly = 2,
  EveryOtherWeek = 3,
  Every4Weeks = 4,
  Monthly = 5,
  TwiceMonthly = 6,
  EveryOtherMonth = 7,
  Every3Months = 8,
  Every6Months = 9,
  Annual = 10,
}
export interface PaymentDayModelDto {
  /** @format int32 */
  dayOfMonth?: number;
  useLastBusinessDay?: boolean;
}
export enum PaymentStatusDto {
  Unknown = 0,
  Processed = 1,
  Paid = 2,
  Stopped = 3,
  Cancelled = 4,
  Resubmitted = 5,
  Refunded = 6,
  Skipped = 7,
  Approved = 8,
  Scheduled = 9,
  Pending = 10,
  PendingSkip = 11,
  ApprovalRequired = 12,
  Settled = 13,
  Returned = 14,
}
export interface AddPaymentResponseModelDto {
  errorSummary?: ErrorSummaryModelDto;
  payment?: PaymentModelDto;
  paymentSucceeded: boolean;
  canOverrideErrors: boolean;
}
export interface AddBillPayPaymentRequestModelDto {
  /** @format decimal */
  amount: number;
  comment?: string;
  /** @format date-time */
  estimatedArrivalDate?: string;
  payeeId?: string;
  payFromAccountId?: string;
  /** @format date-time */
  processDate?: string;
  recurrence?: PaymentRecurrenceModelDto;
  overrideErrors: boolean;
}
export interface GetPaymentResponseModelDto {
  futurePayments?: FuturePaymentModelDto[];
  payment?: PaymentModelDto;
}
export interface FuturePaymentModelDto {
  /** @format date-time */
  actualProcessDate: string;
  id?: string;
  status?: PaymentStatusDto;
}
export interface GetAccountsResponseModelDto {
  payFromAccounts?: AccountModel3Dto[];
}
export interface UpdatePaymentResponseModelDto {
  errorSummary?: ErrorSummaryModelDto;
  payment?: PaymentModelDto;
  updateSucceeded: boolean;
}
export interface UpdatePaymentRequestModelDto {
  delete?: boolean;
  deleteRecurrence?: boolean;
  futurePaymentId?: string;
  paymentId?: string;
  skipFuturePayment?: boolean;
}
export interface GetUserResponseModelDto {
  user?: UserModel3Dto;
}
export interface UserModel3Dto {
  access: BillPayAccessDto;
  accessMessage?: string;
  id?: string;
  permissions?: PermissionModelDto[];
}
export enum BillPayAccessDto {
  Unknown = 0,
  Allow = 1,
  Deny = 2,
  DenyWithMessage = 3,
}
export interface PermissionModelDto {
  code?: string;
  value?: string;
}
export type SearchAchNotificationOfChangeResponseDto = ActionStatusDto & {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  recordCountInCurrentPage: number;
  notificationOfChanges?: SearchAchNotificationOfChangeItemDto[];
};
export interface SearchAchNotificationOfChangeItemDto {
  /** @format guid */
  achNocTransactionUniqueId: string;
  /** @format date-time */
  effectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  achCompanyName?: string;
  companyEntryDescription?: string;
  secCode?: AchChangeModelDto;
  recipientName?: AchChangeModelDto;
  recipientFinancialInstitution?: AchChangeModelDto;
  accountNumber?: AchChangeModelDto;
  accountType?: AchChangeModelDto;
  identificationNumber?: AchChangeModelDto;
  traceNumber?: AchChangeModelDto;
  changeCode?: string;
}
export interface AchChangeModelDto {
  original?: string;
  updated?: string;
}
export interface ActionStatusDto {
  successLevel: ActionSuccessLevelDto;
  responseDetailCollection?: ResponseDetailCollection2Dto;
}
export enum ActionSuccessLevelDto {
  Successful = 1,
  UnSuccessful = 2,
  NotExecuted = 3,
}
export interface SearchAchNotificationOfChangeRequestDto {
  effectiveDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  effectiveDateStart?: string;
  /** @format date-time */
  effectiveDateEnd?: string;
  receivedDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  receivedDateStart?: string;
  /** @format date-time */
  receivedDateEnd?: string;
  achCompanies?: GuidKeyCollectionDto;
  recipientName?: string;
  pagingParameters?: PagingParametersDto;
}
export enum DateFilterOptionsDto {
  Undefined = 0,
  SpecificDate = 1,
  Range = 2,
}
export type GetAchNotificationOfChangeResponseDto = ActionStatusDto & {
  /** @format guid */
  achNocTransactionUniqueId: string;
  /** @format date-time */
  effectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  achCompanyName?: string;
  companyEntryDescription?: string;
  secCode?: AchChangeModelDto;
  recipientName?: AchChangeModelDto;
  recipientFinancialInstitution?: AchChangeModelDto;
  accountNumber?: AchChangeModelDto;
  accountType?: AchChangeModelDto;
  identificationNumber?: AchChangeModelDto;
  traceNumber?: AchChangeModelDto;
  changeCode?: string;
};
export type AchNotificationOfChangeReportModelDto = PagedListModelOfAchNotificationOfChangeModelDto & object;
export interface PagedListModelOfAchNotificationOfChangeModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: AchNotificationOfChangeModelDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface AchNotificationOfChangeModelDto {
  achNotificationOfChangeUniqueId?: string;
  /** @format date-time */
  effectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  achCompanyName?: string;
  returnReasonCode?: string;
  returnReasonDescription?: string;
  achCompanyId?: string;
}
export interface AchNoticeOfChangeFilterModelDto {
  receivedDate?: DateFilterModelDto;
  companyIds?: number[];
  pagingParameters?: PagingParametersModelDto;
}
export interface DateFilterModelDto {
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  searchType?: string;
}
export interface GetAchNotificationOfChangeDownloadRequestDto {
  /** @format guid */
  achNocTransactionUniqueId: string;
  outPutType: OutPutTypeDto;
}
export interface SearchAchNotificationOfChangeDownloadRequestDto {
  filter?: SearchAchNotificationOfChangeDownloadFilterDto;
  informationType?: string;
  outPutType: OutPutTypeDto;
  pagingParameters?: PagingParametersDto;
}
export interface SearchAchNotificationOfChangeDownloadFilterDto {
  effectiveDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  effectiveDateStart?: string;
  /** @format date-time */
  effectiveDateEnd?: string;
  receivedDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  receivedDateStart?: string;
  /** @format date-time */
  receivedDateEnd?: string;
  achCompanies?: GuidKeyCollectionDto;
  recipientName?: string;
}
export interface AchNotificationOfChangeDetailModelDto {
  routingNumber?: AchChangeModelDto;
  discretionaryData?: AchChangeModelDto;
  recipientName?: AchChangeModelDto;
  secCode?: AchChangeModelDto;
  accountNumber?: AchChangeModelDto;
  accountType?: AchChangeModelDto;
  identificationNumber?: AchChangeModelDto;
  traceNumber?: AchChangeModelDto;
  achCompany?: string;
  changeCode?: string;
  /** @format date-time */
  effectiveDate?: string;
  companyEntryDescription?: string;
}
export interface AchNotificationOfChangeReportRequestModelDto {
  /** @format date-time */
  notificationReceivedDate?: string;
  changeCodeDescription?: string;
  isPortrait: boolean;
}
export type SearchAchReturnResponseDto = ActionStatusDto & {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  recordCountInCurrentPage: number;
  returns?: SearchAchReturnItemDto[];
};
export interface SearchAchReturnItemDto {
  achCompanyName?: string;
  achCompanyId?: string;
  achCompanyDiscretionaryData?: string;
  achCompanyEntryDescription?: string;
  /** @format guid */
  achReturnTransactionUniqueId: string;
  accountNumber?: string;
  accountType?: string;
  /** @format decimal */
  amount: number;
  /** @format date-time */
  dateOfDeath?: string;
  identificationNumber?: string;
  /** @format date-time */
  effectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  recipientFinancialInstitution?: string;
  recipientName?: string;
  returnReason?: string;
  secCode?: string;
  traceNumber?: string;
}
export interface SearchAchReturnRequestDto {
  effectiveDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  effectiveDateStart?: string;
  /** @format date-time */
  effectiveDateEnd?: string;
  receivedDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  receivedDateStart?: string;
  /** @format date-time */
  receivedDateEnd?: string;
  achCompanies?: GuidKeyCollectionDto;
  recipientName?: string;
  pagingParameters?: PagingParametersDto;
}
export type GetAchReturnResponseDto = ActionStatusDto & {
  /** @format guid */
  achReturnTransactionUniqueId: string;
  /** @format date-time */
  originalEffectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  achCompanyName?: string;
  secCode?: string;
  recipientName?: string;
  recipientFinancialInstitution?: string;
  accountNumber?: string;
  accountType?: string;
  /** @format double */
  amount: number;
  identificationNumber?: string;
  traceNumber?: string;
  returnReason?: string;
  /** @format date-time */
  dateOfDeath?: string;
};
export type AchReturnReportModelDto = PagedListModelOfAchReturnModelDto & object;
export interface PagedListModelOfAchReturnModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: AchReturnModelDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface AchReturnModelDto {
  achReturnUniqueId?: string;
  /** @format date-time */
  effectiveDate: string;
  /** @format date-time */
  receivedDate: string;
  achCompanyName?: string;
  returnReasonCode?: string;
  returnReasonDescription?: string;
  /** @format decimal */
  amount: number;
  achCompanyId?: string;
}
export interface AchReturnFilterModelDto {
  receivedDate?: DateFilterModelDto;
  companyIds?: number[];
  pagingParameters?: PagingParametersModelDto;
}
export interface AchReturnDetailModelDto {
  routingNumber?: string;
  discretionaryData?: string;
  recipientName?: string;
  secCode?: string;
  recipientFinancialInstitution?: string;
  accountType?: string;
  identificationNumber?: string;
  traceNumber?: string;
  /** @format date-time */
  dateOfDeath?: string;
  accountNumber?: string;
  /** @format date-time */
  originalEffectiveDate: string;
  achCompany?: string;
  /** @format double */
  amount: number;
  returnReasonCode?: string;
}
export interface AchReturnReportRequestModelDto {
  /** @format date-time */
  notificationReceivedDate?: string;
  returnReasonDescription?: string;
  isPortrait: boolean;
}
export interface GetAchReturnDownloadRequestDto {
  /** @format guid */
  achReturnTransactionUniqueIdentifier: string;
  outPutType: OutPutTypeDto;
}
export interface SearchAchReturnDownloadRequestDto {
  filter?: SearchAchReturnDownloadFilterDto;
  informationType?: string;
  outPutType: OutPutTypeDto;
  pagingParameters?: PagingParametersDto;
}
export interface SearchAchReturnDownloadFilterDto {
  effectiveDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  effectiveDateStart?: string;
  /** @format date-time */
  effectiveDateEnd?: string;
  receivedDateFilterOption: DateFilterOptionsDto;
  /** @format date-time */
  receivedDateStart?: string;
  /** @format date-time */
  receivedDateEnd?: string;
  achCompanies?: GuidKeyCollectionDto;
  recipientName?: string;
}
export type GetAchChildSupportTemplateResponseDto = GetAchTemplateBaseResponseDto & {
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  recipients: AchChildSupportRecipientResponseCollectionDto;
};
export type AchChildSupportRecipientResponseCollectionDto = AchChildSupportRecipientResponseDto[];
export type AchChildSupportRecipientResponseDto = AchRecipientBaseResponseDto & {
  addenda?: AchChildSupportRecipientAddendaResponseDto;
};
export type AchChildSupportRecipientAddendaResponseDto = AchChildSupportRecipientAddendaBaseDto & object;
export type AchChildSupportRecipientAddendaBaseDto = AchRecipientAddendaBaseDto & {
  caseIdentifier?: string;
  employmentTermination: boolean;
  fipsCode?: string;
  medicalSupport: boolean;
  nonCustodialParentFirstName?: string;
  nonCustodialParentLastName?: string;
  nonCustodialParentSsn?: string;
};
export interface AchRecipientAddendaBaseDto {
  /** @format guid */
  id: string;
  /** @format guid */
  recipientId: string;
}
export type AchRecipientBaseResponseDto = AchRecipientBaseDto & {
  /** @format guid */
  id: string;
  /** @minLength 1 */
  accountTypeName: string;
  /** @minLength 1 */
  transactionTypeDescription: string;
};
export interface AchRecipientBaseDto {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  idNumber: string;
  /** @minLength 1 */
  routingNumber: string;
  /** @minLength 1 */
  accountNumber: string;
  accountType: BankAccountTypeDto;
  transactionType: AchTransactionTypeDto;
  /** @format decimal */
  amount: number;
  isPrenote: boolean;
  holdTransaction: boolean;
}
export enum BankAccountTypeDto {
  Undefined = 0,
  Checking = 1,
  Savings = 2,
  Loan = 3,
  Mortgage = 4,
  ClubAccount = 5,
  TimeDeposit = 6,
  GL = 7,
}
export enum AchTransactionTypeDto {
  Undefined = 0,
  Debit = 1,
  Credit = 2,
  DebitCredit = 3,
}
export type GetAchTemplateBaseResponseDto = AchTemplateBaseResponseDto & {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  achCompanyId: number;
  /**
   * @format guid
   * @minLength 1
   */
  achCompanyUniqueId: string;
  /** @minLength 1 */
  achCompanyName: string;
  /** @minLength 1 */
  companyIdentity: string;
  /** @minLength 1 */
  secCode: string;
  /** @minLength 1 */
  entryDescription: string;
  /** @minLength 1 */
  discretionaryData: string;
  restricted: boolean;
  audit?: string;
  /** @minLength 1 */
  statusDescription: string;
  status: AchTemplateStatusTypeDto;
  /**
   * @format date-time
   * @minLength 1
   */
  lastUpdated: string;
  userPermissions?: TextKeyCollectionDto;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  /** @format int32 */
  completedApprovalCount: number;
};
export enum AchTemplateStatusTypeDto {
  PendingApproval = 1,
  Ready = 2,
  Rejected = 3,
  Deleted = 4,
}
export type AchTemplateBaseResponseDto = ActionStatusDto & {
  /** @format guid */
  id: string;
};
export type CreateAchTemplateResponseDto = AchTemplateBaseResponseDto & {
  templateStatus?: string;
  templateAudit?: string;
  securityMessage?: SecurityMessageModelDto;
};
export type CreateAchChildSupportTemplateRequestDto = AchChildSupportTemplateBaseRequestDto & {
  /** @format guid */
  id?: string;
};
export type AchChildSupportTemplateBaseRequestDto = AchTemplateBaseRequestDto & {
  recipients: AchChildSupportRecipientRequestDto[];
};
export type AchChildSupportRecipientRequestDto = AchRecipientBaseDto & {
  /** @format guid */
  id?: string;
  addenda?: AchChildSupportRecipientAddendaRequestDto;
};
export type AchChildSupportRecipientAddendaRequestDto = AchChildSupportRecipientAddendaBaseDto & {
  /** @format guid */
  id?: string;
  /** @format guid */
  recipientId?: string;
};
export interface AchTemplateBaseRequestDto {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  achCompanyId: number;
  /**
   * @format guid
   * @minLength 1
   */
  achCompanyUniqueId: string;
  /** @minLength 1 */
  companyIdentity: string;
  secCode: AchSecCodeTypeDto;
  /** @minLength 1 */
  entryDescription: string;
  /** @minLength 1 */
  discretionaryData: string;
  restricted: boolean;
}
export enum AchSecCodeTypeDto {
  CCD = 1,
  CTX = 2,
  PPD = 3,
  TEL = 4,
  WEB = 5,
  IAT = 6,
}
export type UpdateAchTemplateResponseDto = AchTemplateBaseResponseDto & {
  templateStatus?: string;
  templateAudit?: string;
  securityMessage?: SecurityMessageModelDto;
};
export type UpdateAchChildSupportTemplateRequestDto = AchChildSupportTemplateBaseRequestDto & {
  /** @format guid */
  id: string;
};
export type DeleteAchTemplateResponseDto = AchTemplateBaseResponseDto & {
  securityMessage?: SecurityMessageModelDto;
};
export type SearchAchTemplateListResponseDto = ActionStatusDto & {
  templates?: SearchAchTemplateBaseResponseCollectionDto;
};
export type SearchAchTemplateBaseResponseCollectionDto = SearchAchTemplateBaseResponseDto[];
export interface SearchAchTemplateBaseResponseDto {
  /** @format guid */
  templateId: string;
  /** @format int32 */
  templateIntId: number;
  templateName?: string;
  achCompanyName?: string;
  secCode?: string;
  /** @format date-time */
  lastUpdated?: string;
  status?: string;
  userPermissions?: TextKeyCollectionDto;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  /** @format int32 */
  completedApprovalCount: number;
}
export type SearchAchChildSupportTemplateRequestDto = SearchAchTemplateBaseRequestDto & {
  creditAmountType: AchFilterAmountTypeDto;
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  creditAmountMax?: number;
};
export enum AchFilterAmountTypeDto {
  Undefined = 0,
  SpecificAmount = 1,
  Range = 2,
}
export interface SearchAchTemplateBaseRequestDto {
  achCompanyIdList?: NumberKeyCollectionDto;
  templateName?: string;
  lastUpdatedDateType: AchFilterDateTypeDto;
  /** @format date-time */
  lastUpdated?: string;
  /** @format date-time */
  lastUpdatedEnd?: string;
  status: AchTemplateStatusTypeDto;
  secCode: AchSecCodeTypeDto;
}
export type NumberKeyCollectionDto = number[];
export enum AchFilterDateTypeDto {
  Undefined = 0,
  SpecificDate = 1,
  Range = 2,
}
export interface AchTemplateDownloadModelDto {
  /** @format guid */
  filterModel: string;
  outputType: OutPutTypeDto;
  isPortrait: boolean;
}
export type AchChildSupportTemplateListDownloadModelDto = AchTemplateListDownloadModelDto & {
  filterModel?: SearchAchChildSupportTemplateRequestDto;
};
export interface AchTemplateListDownloadModelDto {
  outputType: OutPutTypeDto;
  isPortrait: boolean;
}
export type GetAchInternationalTemplateResponseDto = GetAchTemplateBaseResponseDto & {
  /** @minLength 1 */
  streetAddress: string;
  /** @minLength 1 */
  city: string;
  /** @format int32 */
  stateId: number;
  /** @minLength 1 */
  zipCode: string;
  country?: string;
  /** @format int32 */
  destinationCountryCodeId: number;
  /** @format int32 */
  foreignExchangeIndicatorId: number;
  /** @format int32 */
  foreignExchangeReferenceIndicatorId: number;
  foreignExchangeReference?: string;
  /** @format int32 */
  destinationCurrencyCodeId: number;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /** @format int32 */
  originatorCurrencyCodeId: number;
  recipients: AchInternationalRecipientResponseCollectionDto;
};
export type AchInternationalRecipientResponseCollectionDto = AchInternationalRecipientResponseDto[];
export type AchInternationalRecipientResponseDto = AchRecipientBaseResponseDto & {
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  /** @format int32 */
  iatTransactionTypeId: number;
  bankName?: string;
  /** @format int32 */
  bankIdTypeId: number;
  /** @format int32 */
  receivingBankCountryId: number;
  addenda?: AchInternationalRecipientAddendaCollectionDto;
};
export type AchInternationalRecipientAddendaCollectionDto = AchInternationalRecipientAddendaDto[];
export type AchInternationalRecipientAddendaDto = AchRecipientAddendaBaseDto & {
  line?: string;
};
export type CreateAchInternationalTemplateRequestDto = AchInternationalTemplateBaseRequestDto & {
  /** @format guid */
  id?: string;
};
export type AchInternationalTemplateBaseRequestDto = AchTemplateBaseRequestDto & {
  /** @minLength 1 */
  streetAddress: string;
  /** @minLength 1 */
  city: string;
  /** @format int32 */
  stateId: number;
  /** @minLength 1 */
  zipCode: string;
  country?: string;
  /** @format int32 */
  destinationCountryCodeId: number;
  /** @format int32 */
  foreignExchangeIndicatorId: number;
  /** @format int32 */
  foreignExchangeReferenceIndicatorId: number;
  foreignExchangeReference?: string;
  /** @format int32 */
  destinationCurrencyCodeId: number;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /** @format int32 */
  originatorCurrencyCodeId: number;
  recipients: AchInternationalRecipientRequestCollectionDto;
};
export type AchInternationalRecipientRequestCollectionDto = AchInternationalRecipientRequestDto[];
export type AchInternationalRecipientRequestDto = AchRecipientBaseDto & {
  /** @format guid */
  id?: string;
  /** @minLength 1 */
  streetAddress: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  state: string;
  /** @minLength 1 */
  zipCode: string;
  /** @minLength 1 */
  country: string;
  /** @format int32 */
  iatTransactionTypeId: number;
  /** @minLength 1 */
  bankName: string;
  /** @format int32 */
  bankIdTypeId: number;
  /** @format int32 */
  receivingBankCountryId: number;
  addenda?: AchInternationalRecipientAddendaCollectionDto;
};
export type UpdateAchInternationalTemplateRequestDto = AchInternationalTemplateBaseRequestDto & {
  /** @format guid */
  id: string;
};
export type SearchAchInternationalTemplateResponseDto = SearchAchTemplateBaseResponseDto & {
  /** @format decimal */
  creditAmount: number;
  /** @format decimal */
  debitAmount: number;
};
export type SearchAchInternationalTemplateRequestDto = SearchAchTemplateBaseRequestDto & {
  creditAmountType: AchFilterAmountTypeDto;
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  creditAmountMax?: number;
  debitAmountType: AchFilterAmountTypeDto;
  /** @format decimal */
  debitAmount?: number;
  /** @format decimal */
  debitAmountMax?: number;
};
export type AchInternationalTemplateListDownloadModelDto = AchTemplateListDownloadModelDto & {
  filterModel?: SearchAchInternationalTemplateRequestDto;
};
export type ApproveAchTemplateResponseDto = AchTemplateBaseResponseDto & {
  securityMessage?: SecurityMessageModelDto;
};
export interface AchTemplateApprovalModelDto {
  /** @format guid */
  id: string;
  comments?: string;
}
export type RejectAchTemplateResponseDto = AchTemplateBaseResponseDto & {
  securityMessage?: SecurityMessageModelDto;
};
export interface AchTemplateRejectModelDto {
  /** @format guid */
  id: string;
  comments?: string;
}
export type GetAchTemplateApproversResponseDto = AchTemplateBaseResponseDto & {
  completedApprovals?: ApprovalCollectionDto;
  availableApprovers?: TextKeyCollectionDto;
};
export type ApprovalCollectionDto = ApprovalDto[];
export interface ApprovalDto {
  name?: string;
  dateApproved?: string;
}
export type GetInternationalOptionValuesResponseDto = ActionStatusDto & {
  values?: InternationalOptionValuesDto;
};
export interface InternationalOptionValuesDto {
  bankIdTypes?: BankIdTypeDtoCollectionDto;
  cascadingValues?: InternationalCascadingValueCollectionDto;
  countries?: CountryDtoCollectionDto;
  currencies?: CurrencyDtoCollectionDto;
  foreignExchangeIndicators?: ForeignExchangeIndicatorsDtoCollectionDto;
  foreignExchangeReferenceIndicators?: ForeignExchangeReferenceIndicatorDtoCollectionDto;
  internationalTransactionTypes?: InternationalTransactionTypeDtoCollectionDto;
  states?: StateDtoCollectionDto;
}
export type BankIdTypeDtoCollectionDto = BankIdTypeDtoDto[];
export interface BankIdTypeDtoDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
}
export type InternationalCascadingValueCollectionDto = InternationalCascadingValueDto[];
export interface InternationalCascadingValueDto {
  /** @format int32 */
  countryId: number;
  receivingBankCountries?: NumberKeyCollectionDto;
  foreignExchangeIndicators?: ForeignExchangeIndicatorCollectionDto;
  internationalAccountTypes?: InternationalAccountTypeCollectionDto;
}
export type ForeignExchangeIndicatorCollectionDto = ForeignExchangeIndicatorDto[];
export interface ForeignExchangeIndicatorDto {
  indicator: ForeignExchangeIndicatorTypeDto;
  foreignExchangeReferenceIndicators?: ForeignExchangeReferenceIndicatorCollectionDto;
  destinationCurrencies?: CurrencyTypeCollectionDto;
}
export enum ForeignExchangeIndicatorTypeDto {
  Undefined = 0,
  FF = 1,
  FV = 2,
}
export type ForeignExchangeReferenceIndicatorCollectionDto = ForeignExchangeReferenceIndicatorDto[];
export enum ForeignExchangeReferenceIndicatorDto {
  Undefined = 0,
  ForeignExchangeRate = 1,
  ForeignExchangeReferenceNumber = 2,
  None = 3,
}
export type CurrencyTypeCollectionDto = CurrencyTypeDto[];
export enum CurrencyTypeDto {
  Undefined = 0,
  USD = 1,
  CAD = 2,
  MXN = 3,
  GBP = 4,
  CHF = 5,
  EUR = 6,
}
export type InternationalAccountTypeCollectionDto = InternationalAccountTypeDto[];
export interface InternationalAccountTypeDto {
  accountType: AchAccountTypeDto;
  transactionTypes?: TransactionTypeCollectionDto;
}
export enum AchAccountTypeDto {
  None = 0,
  Checking = 1,
  Savings = 2,
  GL = 3,
  Loan = 4,
}
export type TransactionTypeCollectionDto = TransactionType2Dto[];
export enum TransactionType2Dto {
  None = 0,
  Credit = 1,
  Debit = 2,
}
export type CountryDtoCollectionDto = CountryDtoDto[];
export interface CountryDtoDto {
  /** @format int32 */
  id: number;
  abbreviation?: string;
  name?: string;
  isActive: boolean;
}
export type CurrencyDtoCollectionDto = CurrencyDtoDto[];
export interface CurrencyDtoDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
}
export type ForeignExchangeIndicatorsDtoCollectionDto = ForeignExchangeIndicatorsDtoDto[];
export interface ForeignExchangeIndicatorsDtoDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
}
export type ForeignExchangeReferenceIndicatorDtoCollectionDto = ForeignExchangeReferenceIndicatorDtoDto[];
export interface ForeignExchangeReferenceIndicatorDtoDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
}
export type InternationalTransactionTypeDtoCollectionDto = InternationalTransactionTypeDtoDto[];
export interface InternationalTransactionTypeDtoDto {
  /** @format int32 */
  id: number;
  name?: string;
  description?: string;
}
export type StateDtoCollectionDto = StateDtoDto[];
export interface StateDtoDto {
  /** @format int32 */
  id: number;
  code?: string;
  name?: string;
}
export type AchEditableBatchModelDto = SecurityBaseDto & {
  batch: AchBatchModelDto;
  settings: AchSettingsModelDto;
  errorMessage?: string;
  successMessage?: string;
  audit?: string;
};
export type AchBatchModelDto = SecurityBaseDto & {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  achCompanyName: string;
  errorSummary?: ErrorSummaryModelDto;
  /** @minLength 1 */
  achCompanyId: string;
  /** @minLength 1 */
  secCode: string;
  /** @minLength 1 */
  discretionaryData: string;
  /** @minLength 1 */
  entryDescription: string;
  restricted: boolean;
  recipients?: AchRecipientModelDto[];
  /**
   * @format date-time
   * @minLength 1
   */
  lastUpdated: string;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /** @format int32 */
  numberOfApprovalsNeeded: number;
  /** @minLength 1 */
  status: string;
  /** @minLength 1 */
  successMessage: string;
  achCompany: AchCompanyModelDto;
  frequency: AchFrequencyModelDto;
  offsetAccount: LookupModelDto;
  permissions?: ProductPermissionModelDto[];
  transactionId?: string;
  audit?: string;
  completedApprovals?: UserApprovalDto[];
  /** @format int32 */
  completedApprovalCount?: number;
  availableApprovers?: string[];
  /** @format int32 */
  fileArchiveId?: number;
  paymentProcessingBasedOn1Record?: boolean;
  /** @format int32 */
  achPaymentTypeId: number;
};
export interface AchSettingsModelDto {
  /** @format int32 */
  achCompanyMatchType: number;
  allowStateTaxPayments: boolean;
  allowFederalTaxPayments: boolean;
  allowTaxPayments: boolean;
  allowOnUsAccessManagement: boolean;
  allowUnbalancedPayments: boolean;
  allowChildSupportPayments: boolean;
  allowInternationalPayments: boolean;
}
export type AchStateTaxTemplateModelDto = AchTaxTemplateModelDto & {
  /** @format int32 */
  stateId: number;
  state?: string;
  achStateTaxPaymentAmounts?: AchStateTaxTemplateAmountModelDto[];
};
export type AchStateTaxTemplateAmountModelDto = ModelBaseDto & {
  /** @format int32 */
  achBatchId: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  achStateAmountTypeId: number;
  achStateAmountTypeName?: string;
  achStateAmountTypeDescription?: string;
};
export type AchTaxTemplateModelDto = AchBatchModelDto & {
  taxCode?: string;
  taxPayerId?: string;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientAccountType?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  recipientAccountNumber?: string;
  recipientIdNumber?: string;
};
export type AchFederalTaxTemplateModelDto = AchTaxTemplateModelDto & {
  achFederalTaxPaymentAmounts?: AchFederalTaxTemplateAmountModelDto[];
};
export type AchFederalTaxTemplateAmountModelDto = ModelBaseDto & {
  /** @format int32 */
  achBatchId: number;
  /** @format decimal */
  amount: number;
  amountType?: string;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  achFederalAmountTypeName?: string;
  achFederalAmountTypeDescription?: string;
};
export type AchBatchSummaryModelDto = AchBatchModelDto & {
  isEdited: boolean;
  errorMessage?: string;
  /** @format int32 */
  numberOfRecipients: number;
  /** @format int32 */
  fileArchiveId?: number;
  /** @format int32 */
  batchUniqueId: number;
  hasAccess: boolean;
  /** @format int32 */
  achPaymentDraftId: number;
  isValid: boolean;
  /** @format int32 */
  createdBy: number;
  /** @format int32 */
  updatedByUserId: number;
};
export interface AchBatchFilterModelDto {
  achCompanyList: AchCompanyModelDto[];
  /** @minLength 1 */
  batchName: string;
  /** @minLength 1 */
  secCode: string;
  /**
   * @format date-time
   * @minLength 1
   */
  lastUpdatedStart: string;
  /**
   * @format date-time
   * @minLength 1
   */
  lastUpdatedEnd: string;
  /** @format decimal */
  amountMin: number;
  /** @format decimal */
  amountMax: number;
  /** @format decimal */
  debitAmountMax: number;
  /** @format decimal */
  debitAmountMin: number;
  /** @format decimal */
  creditAmountMax: number;
  /** @format decimal */
  creditAmountMin: number;
  /** @minLength 1 */
  status: string;
  /** @minLength 1 */
  amountType: string;
  /** @minLength 1 */
  creditAmountType: string;
  /** @minLength 1 */
  debitAmountType: string;
  /** @minLength 1 */
  updatedDateType: string;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /**
   * @format date-time
   * @minLength 1
   */
  updatedDate: string;
  /** @format int32 */
  taxTemplateType: number;
}
export type DeleteAchBatchModelDto = SecurityBaseDto & {
  /** @format int32 */
  achBatchId: number;
};
export type AchPaymentWithBatchesModelDto = AchPaymentModelDto & {
  achBatchSummaries?: AchBatchSummaryModelDto[];
  achPaymentIds?: number[];
  /** @format decimal */
  totalCreditAmount: number;
  /** @format decimal */
  totalDebitAmount: number;
  /** @format int32 */
  totalCreditTransactions: number;
  /** @format int32 */
  totalDebitTransactions: number;
  uploadedBy?: string;
  /** @format int32 */
  totalBalancedBatches: number;
  /** @format decimal */
  totalBalancedCreditAmount: number;
  /** @format decimal */
  totalBalancedDebitAmount: number;
  /** @format int32 */
  totalUnBalancedBatches: number;
  /** @format decimal */
  totalUnBalancedCreditAmount: number;
  /** @format decimal */
  totalUnBalancedDebitAmount: number;
  fileName?: string;
  fileSize?: string;
};
export interface AchBatchImportModelDto {
  achBatchSummaries?: AchBatchSummaryModelDto[];
  errorMessage?: string;
  /** @format int32 */
  totalDebitTransactions: number;
  /** @format decimal */
  totalCreditAmount: number;
  /** @format int32 */
  totalCreditTransactions: number;
  /** @format decimal */
  totalDebitAmount: number;
}
export type AchImportLayoutModelDto = ModelBaseDto & {
  /** @format int32 */
  delimitedId: number;
  /** @format int32 */
  fixedPositionId: number;
  delimiter?: string;
  /** @format int32 */
  nameColumn?: number;
  /** @format int32 */
  nameBegin?: number;
  /** @format int32 */
  nameEnd?: number;
  /** @format int32 */
  idNumberColumn?: number;
  /** @format int32 */
  idNumberBegin?: number;
  /** @format int32 */
  idNumberEnd?: number;
  /** @format int32 */
  routingNumberColumn?: number;
  /** @format int32 */
  routingNumberBegin?: number;
  /** @format int32 */
  routingNumberEnd?: number;
  /** @format int32 */
  accountNumberColumn?: number;
  /** @format int32 */
  accountNumberBegin?: number;
  /** @format int32 */
  accountNumberEnd?: number;
  /** @format int32 */
  amountColumn?: number;
  /** @format int32 */
  amountBegin?: number;
  /** @format int32 */
  amountEnd?: number;
  /** @format int32 */
  transactionCodeColumn?: number;
  /** @format int32 */
  transactionCodeBegin?: number;
  /** @format int32 */
  transactionCodeEnd?: number;
  /** @format int32 */
  accountTypeColumn?: number;
  /** @format int32 */
  accountTypeBegin?: number;
  /** @format int32 */
  accountTypeEnd?: number;
  accountTypeCheckingDelimited?: string;
  accountTypeCheckingFixedPosition?: string;
  accountTypeSavingsDelimited?: string;
  accountTypeSavingsFixedPosition?: string;
  accountTypeLoanDelimited?: string;
  accountTypeLoanFixedPosition?: string;
  accountTypeGlDelimited?: string;
  accountTypeGlFixedPosition?: string;
  /** @format int32 */
  transactionTypeColumn?: number;
  /** @format int32 */
  transactionTypeBegin?: number;
  /** @format int32 */
  transactionTypeEnd?: number;
  transactionTypeDebitDelimited?: string;
  transactionTypeDebitFixedPosition?: string;
  transactionTypeCreditDelimited?: string;
  transactionTypeCreditFixedPosition?: string;
  /** @minLength 1 */
  uploadFormat: string;
};
export type AchSourceModelDto = ModelBaseDto & {
  name?: string;
  key?: string;
};
export interface FilterUsersDto {
  responseDetailCollection?: ResponseDetailCollection2Dto;
  success: boolean;
  users?: FilterUserDto[];
}
export interface FilterUserDto {
  /** @format int32 */
  id: number;
  /** @format guid */
  uniqueId: string;
  name?: string;
}
export type AchFileActivityModelDto = ModelBaseDto & {
  fileName?: string;
  /** @format int32 */
  fileId: number;
  /** @format int32 */
  fileArchiveId: number;
  validationError?: string;
  isActive: boolean;
  achCompanyName?: string;
  fileSize?: string;
  source?: string;
  /** @format date-time */
  uploadDate: string;
  /** @format int32 */
  totalBatches: number;
  /** @format int32 */
  approvedCount: number;
  /** @format int32 */
  pendingApprovalCount: number;
  /** @format int32 */
  rejectedCount: number;
  /** @format int32 */
  expiredCount: number;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  status?: string;
  processedBy?: string;
  /** @format date-time */
  processedDate?: string;
  permissions?: ProductPermissionModelDto[];
};
export interface AchFileActivityFilterModelDto {
  achFileName?: string;
  /** @format int32 */
  fileArchiveId: number;
  achSourceList?: AchSourceModelDto[];
  achCompanyList?: AchCompanyModelDto[];
  uploadDateType?: string;
  /** @format date-time */
  uploadSpecificDate?: string;
  /** @format date-time */
  uploadDateStart?: string;
  /** @format date-time */
  uploadDateEnd?: string;
  processedByList?: FilterUserDto[];
  processedDateType?: string;
  /** @format date-time */
  processedSpecificDate?: string;
  /** @format date-time */
  processedDateStart?: string;
  /** @format date-time */
  processedDateEnd?: string;
}
export interface BaseResponse3Dto {
  baseRequest?: BaseRequest3Dto;
  responseDetailCollection?: ResponseDetailCollection3Dto;
  success: boolean;
}
export interface BaseRequest3Dto {
  activityTracking?: ActivityTracking3Dto;
  alias?: string;
  applicationNameType: ApplicationNameType3Dto;
  authenticationUser?: AuthenticationUser3Dto;
  authorizationInfo?: AuthorizationInfo2Dto;
  clientIpAddress?: string;
  endUser?: EndUser3Dto;
  institutionId?: string;
  productInformation?: ProductInformation3Dto;
  requestType?: string;
  secondaryId?: string;
}
export interface ActivityTracking3Dto {
  activityId?: string;
  parentActivityId?: string;
}
export enum ApplicationNameType3Dto {
  Undefined = 0,
  BackOffice = 1,
  Banno = 2,
  ECS = 3,
  EPS = 4,
  Esi = 5,
  GoDough = 6,
  GoDoughClassic = 7,
  GoDough3X = 8,
  IPay = 9,
  NetTeller = 10,
  PointMobility = 11,
  Treasury = 12,
  BSLKeys = 13,
  BSL = 14,
  ESIFIM = 15,
  EStatements = 16,
  Geezeo = 17,
  BannoPeople = 18,
  CoreDirector = 19,
  Newgen = 20,
  Corserv = 21,
  BOKF = 22,
  Autobooks = 23,
  MobileWeb = 24,
  HCL = 25,
  JxChangeApp = 26,
  TreasuryMobile = 27,
  TreasuryBackOffice = 28,
  BslSubSystem = 29,
  AccountRecon = 30,
  Ach = 31,
  AlertNetCore = 32,
  BankAccount = 33,
  Company = 34,
  Event = 35,
  JxChangeProxy = 36,
  PositivePay = 37,
  Sso = 38,
  User = 39,
  Wire = 40,
  Edpp = 41,
  FxGateway = 42,
  InstitutionNetCore = 43,
  AuthorizationNetCore = 44,
  File = 45,
  Configuration = 46,
  AchReturn = 47,
  ApiCatalog = 48,
  BslSubsystemAdmin = 49,
  EsiMailProcessor = 50,
  Nacha = 51,
  Pdf = 52,
  AchNotificationOfChange = 53,
  RateLimiting = 54,
  AchCompany = 55,
  TreasuryScheduler = 56,
  Report = 57,
  AccountReconIssuedItems = 58,
  PositivePayIssuedItems = 59,
  InternalTransfer = 60,
  CheckExceptions = 61,
  AlertUpdate = 62,
  TreasuryAccount = 63,
  TreasuryAuthentication = 64,
  TreasuryOdiEesListener = 65,
  TreasuryPayment = 66,
  TreasuryReporting = 67,
  TreasuryAch = 68,
  TreasuryWire = 69,
  TreasuryBackOfficeApi = 70,
  TreasuryChannelApi = 71,
  TreasuryNotification = 72,
  NachaIngest = 73,
  AchReturnProcessor = 74,
  AchNocProcessor = 75,
  NachaProcessor = 76,
  NachaSerialize = 77,
  AchPayment = 78,
  TextMessagingNetCore = 79,
  EmailNetCore = 80,
  LoggingNetCore = 81,
  MessageCenterNetCore = 82,
  AuthenticationRules = 83,
  Credentials = 84,
  HttpProxy = 85,
  DocumentNetCore = 86,
  AuthenticationNetCore = 87,
  ChallengeManagementNetCore = 88,
  FileWatcher = 89,
  FileProcessor = 90,
  EsiApplication = 91,
  AccountReports = 92,
  AuditNetCore = 93,
  TreasuryWireNetCore = 94,
  FraudNetCore = 95,
  BslAdmin = 96,
  JxR = 97,
  HealthCheck = 98,
  EchoQueueHandlerService = 99,
  BackgroundUpload = 100,
  JxrBannoBusiness = 101,
  Disclosure = 102,
  FileMovement = 103,
  Fxgw = 104,
}
export type AuthenticationUser3Dto = EndUser3Dto & object;
export interface EndUser3Dto {
  alias?: string;
  browser?: string;
  companyId?: string;
  device?: string;
  externalId?: string;
  externalSecondaryId?: string;
  internalId?: string;
  internalSecondaryId?: string;
  userType: UserType3Dto;
}
export enum UserType3Dto {
  Undefined = 0,
  Banno = 1,
  CashManagement = 2,
  Esi = 3,
  NetTeller = 4,
  Sso = 5,
  Treasury = 6,
  ESIFIM = 7,
  BackOffice = 8,
  Geezeo = 9,
  GeezeoMobile = 10,
  BannoCashManagement = 11,
  GeezeoCashManagement = 12,
  GeezeoMobileCashManagement = 13,
  Newgen = 14,
  Server = 15,
  Jha = 16,
  BannoPeople = 17,
}
export interface AuthorizationInfo2Dto {
  associatedClaims?: AuthorizeAssociatedClaimCollection2Dto;
  entitlements?: AuthorizeEntitlementCollection2Dto;
  isSuperUser: boolean;
  roles?: AuthorizeRoleCollection2Dto;
}
export type AuthorizeAssociatedClaimCollection2Dto = AuthorizeAssociatedClaim2Dto[];
export interface AuthorizeAssociatedClaim2Dto {
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  claimId: number;
  /** @format int32 */
  roleId: number;
}
export type AuthorizeEntitlementCollection2Dto = AuthorizeEntitlement2Dto[];
export interface AuthorizeEntitlement2Dto {
  /** @format int32 */
  id: number;
  name?: string;
}
export type AuthorizeRoleCollection2Dto = AuthorizeRole2Dto[];
export interface AuthorizeRole2Dto {
  /** @format int32 */
  id: number;
  name?: string;
}
export interface ProductInformation3Dto {
  featureName?: string;
  productName?: string;
  version?: string;
}
export type HealthCheckActionRequestDto = BaseRequest3Dto & {
  skipDatabaseConnectivity: boolean;
  skipPackageInformation: boolean;
  skipRedisConnectivity: boolean;
};
export type GetJxArpExceptionsRequestDto = BaseRequest3Dto & {
  account?: Account2Dto;
  arpExceptionSearchModel?: ArpExceptionSearchModelDto;
  /** @format int32 */
  institutionIntId: number;
};
export type Account2Dto = BaseEntity2Dto & {
  accountNumberTypeFormat?: string;
  accountProducts?: AccountProduct2Dto[];
  /** @format guid */
  accountUniqueId: string;
  achOffsetAccounts?: AchOffsetAccount2Dto[];
  allowLoanPaymentPastDue: boolean;
  analysisTransactionCounts?: AnalysisTransactionCount2Dto[];
  arpExceptions?: ArpException2Dto[];
  arpReportFiles?: ArpReportFile2Dto[];
  associatedClaims?: AssociatedClaim2Dto[];
  /** @format decimal */
  balance?: number;
  cifNumber?: string;
  /** @format decimal */
  collectedBalance?: number;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  coreAccount?: CoreAccount2Dto;
  coreStatus?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format decimal */
  currentBalance?: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format decimal */
  dailyUsedValue: number;
  description?: string;
  /** @format int32 */
  id: number;
  internalTransferTransactions?: InternalTransferTransaction2Dto[];
  internalTransferTransactions1?: InternalTransferTransaction2Dto[];
  isAccountLinked: boolean;
  isCompanyAccount: boolean;
  isSelected: boolean;
  linkedTMCompanies?: string[];
  /** @format decimal */
  loanPaymentAmountDue?: number;
  /** @format date-time */
  loanPaymentDueDate?: string;
  name?: string;
  nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccount2Dto[];
  number?: string;
  /** @format decimal */
  perTransactionLimit: number;
  reportingFilterAccounts?: ReportingFilterAccount2Dto[];
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  showAccountNickName: boolean;
  stopPayments?: StopPayment2Dto[];
  /** @format decimal */
  templateTransferAmount: number;
  templateTransferMemo?: string;
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccountGroupAccounts?: UserAccountGroupAccount2Dto[];
  userAccounts?: UserAccount2Dto[];
  users?: User2Dto[];
  wires?: Wire2Dto[];
};
export type AccountProduct2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  accountProductArp?: AccountProductArp2Dto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  product?: Product2Dto;
  productAccountPermission?: ProductAccountPermission2Dto[];
  /** @format int32 */
  productId: number;
};
export interface AccountProductArp2Dto {
  accountProduct?: AccountProduct2Dto;
  hasAchException: boolean;
  hasAchFilter: boolean;
  hasCheckException: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export type Product2Dto = BaseEntity2Dto & {
  accountProducts?: AccountProduct2Dto[];
  achProduct?: AchProduct2Dto;
  arpProduct?: ArpProduct2Dto;
  billPayProduct?: BillPayProduct2Dto;
  company?: Company2Dto;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  electronicDocumentsProduct?: ElectronicDocumentsProduct2Dto;
  /** @format int32 */
  id: number;
  internalTransferProduct?: InternalTransferProduct2Dto;
  isConfigurable: boolean;
  productPermissions?: ProductPermission2Dto[];
  productType?: ProductType2Dto;
  /** @format int32 */
  productTypeId: number;
  /** @format guid */
  productUniqueId: string;
  remoteDepositProduct?: RemoteDepositProduct2Dto;
  reportingConfigurations?: ReportingConfiguration2Dto[];
  reportingProduct?: ReportingProduct2Dto;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  stopPaymentProduct?: StopPaymentProduct2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userProductLimits?: UserProductLimit2Dto[];
  wireInternationalProduct?: WireInternationalProduct2Dto;
  wireProduct?: WireProduct2Dto;
};
export interface AchProduct2Dto {
  accountBalanceValidationConfiguredInCore: boolean;
  /** @format int32 */
  achCompanyMatchTypeId: number;
  allowChildSupportPayments: boolean;
  allowFederalTaxPayments: boolean;
  allowInternationalPayments: boolean;
  allowNotificationOfChange: boolean;
  allowOnUsAccessManagement: boolean;
  allowReturnReview: boolean;
  allowSameDayAch: boolean;
  allowStateTaxPayments: boolean;
  allowTaxPayments: boolean;
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  batchApprovalCount: number;
  batchAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  batchAuthenticationTypeId: number;
  /** @format decimal */
  dailyLimit: number;
  disableSameDay: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  internationalTemplateApprovalCount: number;
  /** @format int32 */
  paymentApprovalCount: number;
  paymentAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  product?: Product2Dto;
  /** @format int32 */
  recipientApprovalCount: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  validateAccountBalance: boolean;
}
export type AuthenticationType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface BaseEntity2Dto {
  objectState: ObjectState2Dto;
}
export enum ObjectState2Dto {
  Unchanged = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3,
}
export type AchPayment2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  achBatch?: AchBatch2Dto;
  /** @format int32 */
  achBatchId?: number;
  achCompany?: AchCompany2Dto;
  /** @format int32 */
  achCompanyId: number;
  achCompanyName?: string;
  achFederalTaxPayment?: AchFederalTaxPayment2Dto;
  achNachaFileCopyAudits?: AchNachaFileCopyAudit2Dto[];
  achPaymentAudits?: AchPaymentAudit2Dto[];
  /** @format int32 */
  achPaymentDraftId: number;
  achPaymentRecipients?: AchPaymentRecipient2Dto[];
  /** @format int32 */
  achPaymentRecipientsCount: number;
  achPaymentType?: AchPaymentType2Dto;
  /** @format int32 */
  achPaymentTypeId: number;
  achStateTaxPayment?: AchStateTaxPayment2Dto;
  achStatusType?: AchStatusType2Dto;
  achTransactionId?: string;
  /** @format int32 */
  approvalsRequired: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  balanceValidationNotRequired: boolean;
  batchName?: string;
  /** @format int32 */
  batchUniqueId: number;
  chilAchPayments?: AchPayment2Dto[];
  comment?: string;
  companyIdentity?: string;
  /** @format int32 */
  completedApprovals: number;
  copiedFromTransactionId?: string;
  createdBy?: string;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  credit?: number;
  /** @format decimal */
  debit?: number;
  descretionaryData?: string;
  destinationCountryCode?: string;
  destinationCurrency?: string;
  duplicatePreventionId?: string;
  /** @format date-time */
  effectiveDate: string;
  eligibleApprovers?: string[];
  /** @format date-time */
  endDate?: string;
  entryDescription?: string;
  errorSummary?: ErrorSummary2Dto;
  failureReason?: string;
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId?: number;
  fileName?: string;
  foreignExchangeIndicator?: string;
  foreignExchangeReference?: string;
  /** @format int32 */
  foreignExchangeReferenceIndicator?: number;
  hasAccess: boolean;
  /** @format int32 */
  id: number;
  /** @format date-time */
  initiatedDate?: string;
  /** @format int32 */
  offsetAccountId?: number;
  /** @format decimal */
  onUsAmount?: number;
  /** @format int32 */
  onUsCount: number;
  originatorCurrencyCode?: string;
  parentAchPayment?: AchPayment2Dto;
  /** @format int32 */
  parentAchPaymentId?: number;
  paymentFrequencyType?: PaymentFrequencyType2Dto;
  /** @format int32 */
  paymentFrequencyTypeId: number;
  paymentOnLastBusinessDay?: boolean;
  paymentProcessingBasedOn1Record?: boolean;
  /** @format int32 */
  prefundingDays?: number;
  recipientIdsforReversal?: number[];
  referenceNumber?: string;
  /** @format int32 */
  repeatOnDayOne?: number;
  /** @format int32 */
  repeatOnDayTwo?: number;
  restricted: boolean;
  /** @format int32 */
  reversalAchPaymentId?: number;
  secCode?: string;
  sentFileArchive?: FileArchive2Dto;
  /** @format int32 */
  sentFileArchiveId?: number;
  /** @format date-time */
  startDate?: string;
  /** @format int32 */
  statusTypeId: number;
  successMessage?: string;
  updatedBy?: string;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
  canReverseFull: boolean;
  canReverseTransactions: boolean;
};
export type AchBatch2Dto = BaseEntity2Dto & {
  achBatchAudits?: AchBatchAudit2Dto[];
  achBatchRecipients?: AchBatchRecipient2Dto[];
  achCompany?: AchCompany2Dto;
  /** @format int32 */
  achCompanyId: number;
  /** @format int32 */
  achCompanyMatchType: number;
  achCompanyName?: string;
  achFederalTaxTemplate?: AchFederalTaxTemplate2Dto;
  achPaymentType?: AchPaymentType2Dto;
  /** @format int32 */
  achPaymentTypeId: number;
  achPayments?: AchPayment2Dto[];
  achSecCode?: AchSecCode2Dto;
  /** @format int32 */
  achSecCodeId: number;
  achStateTaxTemplate?: AchStateTaxTemplate2Dto;
  /** @format int32 */
  approvalsRequired: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  batchName?: string;
  batchStatusType?: BatchStatusType2Dto;
  /** @format int32 */
  batchStatusTypeId: number;
  /** @format int32 */
  batchUniqueId: number;
  companyIdentity?: string;
  /** @format int32 */
  completedApprovals: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  credit?: number;
  /** @format decimal */
  creditAmount: number;
  /** @format decimal */
  debit?: number;
  /** @format decimal */
  debitAmount: number;
  descretionaryData?: string;
  eligibleApprovers?: string[];
  entryDescription?: string;
  errorMessage?: string;
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId?: number;
  /** @format int32 */
  id: number;
  isEdited: boolean;
  /** @format int32 */
  numberOfRecipients: number;
  restricted: boolean;
  secCode?: string;
  status?: string;
  successMessage?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export type AchBatchAudit2Dto = BaseEntity2Dto & {
  achActionType?: AchActionType2Dto;
  achBatch?: AchBatch2Dto;
  /** @format int32 */
  achBatchId: number;
  /** @format int32 */
  actionTypeId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  userName?: string;
};
export type AchActionType2Dto = BaseEntity2Dto & {
  achBatchAudits?: AchBatchAudit2Dto[];
  achPaymentAudits?: AchPaymentAudit2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchPaymentAudit2Dto = BaseEntity2Dto & {
  achActionType?: AchActionType2Dto;
  /** @format int32 */
  achActionTypeId: number;
  achPayment?: AchPayment2Dto;
  /** @format int32 */
  achPaymentId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  userName?: string;
};
export type AchBatchRecipient2Dto = BaseEntity2Dto & {
  accountNumber?: string;
  accountType?: AccountType4Dto;
  /** @format int32 */
  accountTypeId: number;
  achBatch?: AchBatch2Dto;
  /** @format int32 */
  achBatchId: number;
  achBatchRecipientAddendas?: AchBatchRecipientAddenda2Dto[];
  addenda?: string;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  hold: boolean;
  /** @format int32 */
  id: number;
  preNote: boolean;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  rowNum?: number;
  transactionType?: TransactionType3Dto;
  /** @format int32 */
  transactionTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface AccountType4Dto {
  achBatchRecipients?: AchBatchRecipient2Dto[];
  achPaymentRecipients?: AchPaymentRecipient2Dto[];
  achTransactionCodes?: AchTransactionCode2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  reportingType?: string;
}
export type AchPaymentRecipient2Dto = BaseEntity2Dto & {
  accountNumber?: string;
  accountType?: AccountType4Dto;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  achMasterRecipientId?: number;
  achPayment?: AchPayment2Dto;
  /** @format int32 */
  achPaymentId: number;
  achPaymentRecipientAddendas?: AchPaymentRecipientAddenda2Dto[];
  addenda?: string;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  hold: boolean;
  /** @format int32 */
  id: number;
  isRoutingNumberOnUs: boolean;
  isRoutingNumberValid: boolean;
  preNote: boolean;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  rowNum?: number;
  transactionType?: TransactionType3Dto;
  /** @format int32 */
  transactionTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type AchPaymentRecipientAddenda2Dto = BaseEntity2Dto & {
  /** @format int32 */
  achPaymentId: number;
  achPaymentRecipient?: AchPaymentRecipient2Dto;
  /** @format int32 */
  achPaymentRecipientId: number;
  addenda?: string;
  addendaType?: AddendaType2Dto;
  /** @format int32 */
  addendaTypeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  nonCustodialParentFirstName?: string;
  nonCustodialParentLastName?: string;
  /** @format int32 */
  rowNum?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface AddendaType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type TransactionType3Dto = BaseEntity2Dto & {
  achBatchRecipients?: AchBatchRecipient2Dto[];
  achCompanies?: AchCompany2Dto[];
  achPaymentRecipients?: AchPaymentRecipient2Dto[];
  achTransactionCodes?: AchTransactionCode2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type AchCompany2Dto = BaseEntity2Dto & {
  /** @format int32 */
  achBatchBalanceTypeId: number;
  achBatches?: AchBatch2Dto[];
  achCompanyId?: string;
  achCompanySecCodes?: AchCompanySecCode2Dto[];
  achOffsetAccounts?: AchOffsetAccount2Dto[];
  achPayments?: AchPayment2Dto[];
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  allowedNotOnUsTransactionTypeId: number;
  availableBalanceTable?: string;
  balanceValidationType?: string;
  batchBalanceType?: BatchBalanceType2Dto;
  company?: Company2Dto;
  coreSecCode?: string;
  /** @format decimal */
  dailyLimit: number;
  discretionaryData?: string;
  entryDescription?: string;
  /** @format int32 */
  id: number;
  isPaymentProcessingCompany: boolean;
  offsetAccountNumber?: string;
  paymentProcessingCompanyAllowed: boolean;
  /** @format int32 */
  prefundingDays?: number;
  /** @format decimal */
  requiredOnUsAmountPerBatch: number;
  /** @format int32 */
  requiredOnUsTransactionCount: number;
  sameDayAchAllowed: boolean;
  settlementAccount?: string;
  settlementAccountType?: string;
  transactionType?: TransactionType3Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  userAchCompanies?: UserAchCompany2Dto[];
};
export type AchCompanySecCode2Dto = BaseEntity2Dto & {
  achCompany?: AchCompany2Dto;
  achSecCode?: AchSecCode2Dto;
  /** @format int32 */
  achSecCodeId: number;
  /** @format int32 */
  companyId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
};
export interface AchSecCode2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  secCode?: string;
}
export interface AchOffsetAccount2Dto {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  achCompany?: AchCompany2Dto;
  /** @format int32 */
  achCompanyId: number;
  /** @format int32 */
  id: number;
  isActive: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
}
export type User2Dto = BaseEntity2Dto & {
  accountLookups?: Lookup2Dto[];
  accountProducts?: AccountProduct2Dto[];
  achOffsetAccounts?: AchOffsetAccount2Dto[];
  alias?: string;
  associatedClaims?: AssociatedClaim2Dto[];
  backOfficeSsoEnabled: boolean;
  beneficiaryAudits?: BeneficiaryAudit2Dto[];
  boReports?: BoReport2Dto[];
  company?: Company2Dto;
  /** @format int32 */
  companyId?: number;
  contactInfo?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  department?: string;
  email?: string;
  enrolled: boolean;
  favoriteReports?: FavoriteReport2Dto[];
  fax?: string;
  hasLoggedIn: boolean;
  /** @format int32 */
  id: number;
  importBatchId?: string;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  isAdmin: boolean;
  isEditor: boolean;
  isSuperUser: boolean;
  locked: boolean;
  /** @format int32 */
  loginFailureCount: number;
  mfaLocked: boolean;
  name?: string;
  notificationEmail?: string;
  notificationPhoneCarrier?: TextMessagingCarrier2Dto;
  /** @format int32 */
  notificationPhoneCarrierId?: number;
  notificationPhoneNumber?: string;
  notificationRecipientId?: string;
  password?: string;
  /** @format date-time */
  passwordDate: string;
  pendingEditsOfUser?: PendingEditOfUser2Dto[];
  pendingState?: PendingEditStatus2Dto;
  phoneExtension?: string;
  phoneNumber?: string;
  publishState?: string;
  reportingFilterSorts?: ReportingFilterSort2Dto[];
  reportingFilters?: ReportingFilter2Dto[];
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  sessions?: Session2Dto[];
  showAccountNickName?: boolean;
  showMaskedAccount?: boolean;
  tempPasswordToken?: string;
  /** @format date-time */
  tempPasswordTokenExpirationDate?: string;
  /** @format date-time */
  termsAcceptedDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccountGroups?: UserAccountGroup2Dto[];
  userAccounts?: UserAccount2Dto[];
  userAchCompanies?: UserAchCompany2Dto[];
  userAudits?: UserAudit2Dto[];
  userDevices?: UserDevice2Dto[];
  userIpAccessSetting?: UserIpAccessSetting2Dto;
  userIpAccesses?: UserIpAccess2Dto[];
  userNotificationTypes?: UserNotificationType2Dto[];
  userNotifications?: UserNotification2Dto[];
  userOobDetail?: UserOobDetail2Dto;
  userPasswordHistories?: UserPasswordHistory2Dto[];
  userProductLimits?: UserProductLimit2Dto[];
  userRoles?: UserRole2Dto[];
  userSecureTokenSecurity?: UserSecureTokenSecurity2Dto;
  userSsoLogins?: UserSsoLogin2Dto[];
  userTimeAccessSetting?: UserTimeAccessSetting2Dto;
  userTimeAccesses?: UserTimeAccess3Dto[];
  userType?: UserType12Dto;
  /** @format int32 */
  userTypeId: number;
  /** @format guid */
  userUniqueId: string;
  userWidgets?: UserWidget2Dto[];
  wireAudits?: WireAudit2Dto[];
};
export interface Lookup2Dto {
  key?: string;
  value?: string;
}
export type AssociatedClaim2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId?: number;
  claim?: Claim2Dto;
  /** @format int32 */
  claimId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  role?: Role2Dto;
  /** @format int32 */
  roleId?: number;
  /** @format date-time */
  startDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId?: number;
};
export type Claim2Dto = BaseEntity2Dto & {
  active: boolean;
  associatedClaims?: AssociatedClaim2Dto[];
  claimNavigations?: ClaimNavigation2Dto[];
  claimPrerequisites?: ClaimPrerequisite2Dto[];
  claimType?: string;
  dependentClaims?: ClaimPrerequisite2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  productClaims?: ProductClaim2Dto[];
  uiPermission?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userType?: UserType12Dto;
  /** @format int32 */
  userTypeId: number;
};
export interface ClaimNavigation2Dto {
  /** @format int32 */
  "<ClaimId>k__BackingField": number;
  /** @format int32 */
  "<ClaimNavigationId>k__BackingField": number;
  "<Claim>k__BackingField": Claim2Dto;
  /** @format int32 */
  "<NavigationId>k__BackingField": number;
  "<Navigation>k__BackingField": Navigation3Dto;
}
export interface Navigation3Dto {
  applicationType?: ApplicationType2Dto;
  /** @format int32 */
  applicationTypeId?: number;
  childNavigations?: Navigation3Dto[];
  key?: string;
  name?: string;
  /** @format int32 */
  navigationId: number;
  path?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type ApplicationType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  navigations?: Navigation3Dto[];
};
export interface ClaimPrerequisite2Dto {
  claim?: Claim2Dto;
  /** @format int32 */
  claimId?: number;
  /** @format int32 */
  id: number;
  prerequisite?: Claim2Dto;
  /** @format int32 */
  prerequisiteId?: number;
}
export type ProductClaim2Dto = BaseEntity2Dto & {
  claim?: Claim2Dto;
  /** @format int32 */
  claimId: number;
  /** @format int32 */
  id: number;
  productType?: ProductType2Dto;
  /** @format int32 */
  productTypeId: number;
};
export type ProductType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionProductTypes?: InstitutionProductType2Dto[];
  name?: string;
  productClaims?: ProductClaim2Dto[];
  products?: Product2Dto[];
};
export type InstitutionProductType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  institutionAchProduct?: InstitutionAchProduct2Dto;
  institutionArpProduct?: InstitutionArpProduct2Dto;
  institutionBillPayProduct?: InstitutionBillPayProduct2Dto;
  institutionElectronicDocuments?: InstitutionElectronicDocuments2Dto;
  /** @format int32 */
  institutionId: number;
  institutionInternalTransferProduct?: InstitutionInternalTransferProduct2Dto;
  institutionProductCutoffTimes?: InstitutionProductCutoffTime2Dto[];
  institutionReportingConfigurations?: InstitutionReportingConfiguration2Dto[];
  institutionReportingProduct?: InstitutionReportingProduct2Dto;
  institutionSsoProducts?: InstitutionSsoProduct2Dto[];
  institutionStopPayment?: InstitutionStopPayment2Dto;
  institutionWireInternationalProduct?: InstitutionWireInternationalProduct2Dto;
  institutionWireProduct?: InstitutionWireProduct2Dto;
  internationalUsdReadOnly: boolean;
  isCompanyProductConfigurable: boolean;
  productType?: ProductType2Dto;
  /** @format int32 */
  productTypeId: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type Institution2Dto = BaseEntity2Dto & {
  accountAnalysisExceptions?: AccountAnalysisException2Dto[];
  achExceptionReturnCode?: string;
  analysisTransactionCodes?: AnalysisTransactionCode2Dto[];
  boReports?: BoReport2Dto[];
  brandName?: string;
  challengeGroups?: ChallengeGroup2Dto[];
  companies?: Company2Dto[];
  coreReleaseVersion?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  fiId?: string;
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId?: number;
  /** @format int32 */
  id: number;
  informationCenterConfigurations?: InformationCenterConfiguration2Dto[];
  institutionAccountDisplaySetting?: InstitutionAccountDisplaySetting2Dto;
  institutionAffiliations?: InstitutionAffiliation2Dto[];
  institutionFraudProviders?: InstitutionFraudProvider2Dto;
  institutionMessageCenter?: InstitutionMessageCenter2Dto;
  institutionNachaFileRecord?: InstitutionNachaFileRecord2Dto;
  institutionName?: string;
  institutionNotificationTypes?: InstitutionNotificationType2Dto[];
  institutionOobConfiguration?: InstitutionOobConfiguration2Dto;
  institutionProductTypes?: InstitutionProductType2Dto[];
  institutionTerm?: InstitutionTerm2Dto;
  institutionUserApprovalSetting?: InstitutionUserApprovalSetting2Dto;
  institutionWireInternationalWorkflowOverrides?: InstitutionWireInternationalWorkflowOverrides2Dto;
  jxEnvironment?: string;
  jxRoutingNumber?: string;
  loginConfigurations?: LoginConfiguration2Dto[];
  logo?: string;
  logoutURL?: string;
  notificationEmail?: string;
  notificationEnabled?: boolean;
  /** @format duration */
  paymentProcessTime: string;
  resourceConfigurations?: ResourceConfiguration2Dto[];
  roles?: Role2Dto[];
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  rsaInstId?: string;
  showCutOffTimeZone?: boolean;
  supportDepartmentName?: string;
  supportEmail?: string;
  supportPhone?: string;
  /** @format int32 */
  timeZoneId: number;
  transactionCodeGroups?: TransactionCodeGroup2Dto[];
  transactionCodes?: TransactionCode2Dto[];
  treasuryProductName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  users?: User2Dto[];
  welcomeEmailText?: string;
};
export interface AccountAnalysisException2Dto {
  /** @format date-time */
  activityDate?: string;
  analysisTransactionType?: AnalysisTransactionType2Dto;
  /** @format int32 */
  analysisTransactionTypeId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  exceptionReason?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId?: number;
  transactionCode?: string;
  /** @format int32 */
  transactionCount?: number;
}
export type AnalysisTransactionType2Dto = BaseEntity2Dto & {
  accountAnalysisExceptions?: AccountAnalysisException2Dto[];
  allowMultipleAccounts: boolean;
  analysisTransactionCodes?: AnalysisTransactionCode2Dto[];
  analysisTransactionCounts?: AnalysisTransactionCount2Dto[];
  analysisTransactionTypeCategory?: AnalysisTransactionTypeCategory2Dto;
  /** @format int32 */
  analysisTransactionTypeCategoryId: number;
  /** @format int32 */
  analysisTransactionTypeId: number;
  description?: string;
  /** @format int32 */
  sortOrder: number;
  transactionName?: string;
};
export type AnalysisTransactionCode2Dto = BaseEntity2Dto & {
  active: boolean;
  /** @format int32 */
  analysisTransactionCodeId: number;
  analysisTransactionType?: AnalysisTransactionType2Dto;
  /** @format int32 */
  analysisTransactionTypeId: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  transactionCode?: string;
};
export interface AnalysisTransactionCount2Dto {
  account?: Account2Dto;
  accountAnalysisProcessStatusType?: AccountAnalysisProcessStatusType2Dto;
  /** @format int32 */
  accountAnalysisProcessStatusTypeId?: number;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  activityDate: string;
  /** @format int32 */
  analysisTransactionCountId: number;
  analysisTransactionType?: AnalysisTransactionType2Dto;
  /** @format int32 */
  analysisTransactionTypeId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  exceptionReason?: string;
  transactionCode?: string;
  /** @format int32 */
  transactionCount: number;
  /** @format date-time */
  updatedDate?: string;
}
export interface AccountAnalysisProcessStatusType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type AnalysisTransactionTypeCategory2Dto = BaseEntity2Dto & {
  categoryName?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  sortOrder: number;
};
export type BoReport2Dto = BaseEntity2Dto & {
  boFavoriteReports?: BoFavoriteReport2Dto[];
  boReport1?: BoReport2Dto[];
  boReport2?: BoReport2Dto;
  boReportColumns?: BoReportColumn2Dto[];
  boReportFilters?: BoReportFilter2Dto[];
  customFilter?: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId?: number;
  isCustomReport: boolean;
  isFavoriteReport?: boolean;
  /** @format int32 */
  ownerId?: number;
  reportName?: string;
  reportType?: string;
  rowStatusId: number;
  runDefaultSearch: boolean;
  sequences?: BoReportUISequence2Dto[];
  /** @format int32 */
  standardReportId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
};
export type BoFavoriteReport2Dto = BaseEntity2Dto & {
  boReport?: BoReport2Dto;
  /** @format int32 */
  boReportId: number;
  /** @format int32 */
  id: number;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type BoReportColumn2Dto = BaseEntity2Dto & {
  boReport?: BoReport2Dto;
  /** @format int32 */
  boReportId: number;
  columnName?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  detailsAccessible: boolean;
  displayName?: string;
  /** @format int32 */
  displayOrder: number;
  displayType?: string;
  /** @format int32 */
  id: number;
  isSortable: boolean;
};
export type BoReportFilter2Dto = BaseEntity2Dto & {
  applyCascadeFilter: boolean;
  boReport?: BoReport2Dto;
  /** @format int32 */
  boReportId: number;
  filterLabel?: string;
  filterModel?: string;
  filterType?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  required: boolean;
  triggerCascadeFilter: boolean;
};
export type BoReportUISequence2Dto = BaseEntity2Dto & {
  boReport?: BoReport2Dto;
  /** @format int32 */
  boReportId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  selector?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type ChallengeGroup2Dto = BaseEntity2Dto & {
  challengeGroupDetails?: ChallengeGroupDetail2Dto[];
  companyChallengeGroups?: CompanyChallengeGroup2Dto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  isDefault: boolean;
  name?: string;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ChallengeGroupDetail2Dto = BaseEntity2Dto & {
  challengeGroup?: ChallengeGroup2Dto;
  /** @format int32 */
  challengeGroupId: number;
  challengePoint?: ChallengePoint2Dto;
  /** @format int32 */
  challengePointId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isActivatedByThreshold: boolean;
  isActive: boolean;
  /** @format decimal */
  thresholdAmount?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ChallengePoint2Dto = BaseEntity2Dto & {
  challengeGroupDetails?: ChallengeGroupDetail2Dto[];
  challengeLocation?: string;
  challengePointCategory?: ChallengePointCategory2Dto;
  /** @format int32 */
  challengePointCategoryId: number;
  challengePointFrequencyType?: ChallengePointFrequencyType2Dto;
  /** @format int32 */
  challengePointFrequencyTypeId: number;
  description?: string;
  hasThreshold: boolean;
  hoverText?: string;
  /** @format int32 */
  id: number;
  name?: string;
  userSessionChallengeStatus?: UserSessionChallengeStatus2Dto[];
};
export type ChallengePointCategory2Dto = BaseEntity2Dto & {
  challengePoints?: ChallengePoint2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  /** @format decimal */
  sort: number;
};
export type ChallengePointFrequencyType2Dto = BaseEntity2Dto & {
  challengePoints?: ChallengePoint2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface UserSessionChallengeStatus2Dto {
  /** @format date-time */
  challengeDate: string;
  /** @format int32 */
  challengeMethodTypeId: number;
  challengePoint?: ChallengePoint2Dto;
  /** @format int32 */
  challengePointId: number;
  /** @format int32 */
  challengeStatusId: number;
  challengeStatusType?: ChallengeStatusType2Dto;
  /** @format int32 */
  id: number;
  /** @format int32 */
  oobReceiptId?: number;
  oobValidationMode?: OobValidationMode2Dto;
  /** @format int32 */
  oobValidationModeId?: number;
  session?: Session2Dto;
  /** @format guid */
  sessionId: string;
}
export type ChallengeStatusType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  userSessionChallengeStatus?: UserSessionChallengeStatus2Dto[];
};
export interface OobValidationMode2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface Session2Dto {
  browser?: string;
  /** @format guid */
  clientConnectionId?: string;
  clientIpAddress?: string;
  device?: string;
  /** @format guid */
  id: string;
  /** @format date-time */
  latestSessionActivityDate?: string;
  /** @format date-time */
  loginDate: string;
  /** @format date-time */
  logoffDate?: string;
  mfaCompleted: boolean;
  mfaRequired: boolean;
  printMailSSoId?: string;
  printMailUrl?: string;
  secureTokenMfaCompleted: boolean;
  secureTokenMfaRequired: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
  userSessionChallengeStatus?: UserSessionChallengeStatus2Dto[];
}
export type CompanyChallengeGroup2Dto = BaseEntity2Dto & {
  challengeGroup?: ChallengeGroup2Dto;
  /** @format int32 */
  challengeGroupId: number;
  /** @format int32 */
  challengeMethodTypeId: number;
  company?: Company2Dto;
  /** @format int32 */
  id: number;
  /** @format int32 */
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type Company2Dto = BaseEntity2Dto & {
  accounts?: Account2Dto[];
  achCompany?: AchCompany2Dto;
  address?: Address2Dto;
  beneficiaries?: Beneficiary2Dto[];
  canSelect: boolean;
  childCompanies?: Company2Dto[];
  cifNumber?: string;
  comments?: string;
  companyAccountDisplaySetting?: CompanyAccountDisplaySetting2Dto;
  companyChallengeGroup?: CompanyChallengeGroup2Dto;
  /** @format guid */
  companyGuidUniqueId: string;
  companyLimits?: CompanyLimit2Dto[];
  companyType?: CompanyType2Dto;
  /** @format int32 */
  companyTypeId: number;
  companyUniqueId?: string;
  companyUserApprovalSetting?: CompanyUserApprovalSetting2Dto;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  customerSince?: string;
  fileImportLayouts?: FileImportLayout2Dto[];
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  internalTransferTransactions?: InternalTransferTransaction2Dto[];
  name?: string;
  ofxAuthenticationCode?: string;
  parentCompany?: Company2Dto;
  /** @format int32 */
  parentCompanyId?: number;
  products?: Product2Dto[];
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  taxNumber?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccounts?: UserAccount2Dto[];
  users?: User2Dto[];
  wires?: Wire2Dto[];
};
export type Address2Dto = BaseEntity2Dto & {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  buildingFloor?: string;
  buildingName?: string;
  buildingNumber?: string;
  buildingRoom?: string;
  city?: string;
  country?: Country2Dto;
  /** @format int32 */
  countryId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  department?: string;
  districtName?: string;
  /** @format int32 */
  id: number;
  postBox?: string;
  postalCode?: string;
  postalCodeExtension?: string;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  rowVersionId?: number;
  state?: string;
  streetName?: string;
  subDepartment?: string;
  townLocationName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type Country2Dto = BaseEntity2Dto & {
  addresses?: Address2Dto[];
  countryCode?: string;
  countryName?: string;
  currencyCode?: string;
  currencyName?: string;
  /** @format int32 */
  id: number;
  intradaySpot?: boolean;
};
export type RowStatus2Dto = BaseEntity2Dto & {
  accounts?: Account2Dto[];
  addresses?: Address2Dto[];
  beneficiaries?: Beneficiary2Dto[];
  beneficiaryAudits?: BeneficiaryAudit2Dto[];
  companies?: Company2Dto[];
  description?: string;
  domesticBanks?: DomesticBank2Dto[];
  id: number;
  informationCenterConfigurations?: InformationCenterConfiguration2Dto[];
  institutionNotificationTypes?: InstitutionNotificationType2Dto[];
  institutionProductTypes?: InstitutionProductType2Dto[];
  institutions?: Institution2Dto[];
  name?: string;
  products?: Product2Dto[];
  resourceConfigurations?: ResourceConfiguration2Dto[];
  roles?: Role2Dto[];
  schedules?: Schedule2Dto[];
  userNotificationTypes?: UserNotificationType2Dto[];
  users?: User2Dto[];
};
export type Beneficiary2Dto = BaseEntity2Dto & {
  additionalDetails?: string;
  address?: Address2Dto;
  /** @format int32 */
  addressId: number;
  /** @format int32 */
  approvalCount: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  beneficiaryAccountNumber?: string;
  beneficiaryAudits?: BeneficiaryAudit2Dto[];
  beneficiaryName?: string;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  domesticBank?: DomesticBank2Dto;
  /** @format int32 */
  domesticBankId?: number;
  domesticIntermediaryAccountNumber?: string;
  domesticIntermediaryBankAddress?: Address2Dto;
  /** @format int32 */
  domesticIntermediaryBankAddressId?: number;
  domesticIntermediaryNotes?: string;
  eligibleApprovers?: string[];
  foreignBankAddress?: Address2Dto;
  /** @format int32 */
  foreignBankAddressId?: number;
  foreignBankId?: string;
  foreignBankIdentificationType?: BankIdentificationType2Dto;
  /** @format int32 */
  foreignBankIdentificationTypeId?: number;
  foreignBankName?: string;
  foreignIntermediaryBankAddress?: Address2Dto;
  /** @format int32 */
  foreignIntermediaryBankAddressId?: number;
  foreignIntermediaryBankId?: string;
  foreignIntermediaryBankIdentificationType?: BankIdentificationType2Dto;
  /** @format int32 */
  foreignIntermediaryBankIdentificationTypeId?: number;
  foreignIntermediaryBankName?: string;
  foreignIntermediaryNotes?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId: number;
  intermediaryDomesticBank?: DomesticBank2Dto;
  /** @format int32 */
  intermediaryDomesticBankId?: number;
  isEdited: boolean;
  isForeignCurrency?: boolean;
  isFreeForm: boolean;
  isInternational: boolean;
  isIsoCompliant?: boolean;
  isTemplateBeneficiary: boolean;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  status?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  useDomesticIntermediary?: boolean;
  useForeignIntermediary?: boolean;
  userDefinedDomesticBankAddress?: Address2Dto;
  /** @format int32 */
  userDefinedDomesticBankAddressId?: number;
  userDefinedDomesticBankId?: string;
  userDefinedDomesticBankIdentificationType?: BankIdentificationType2Dto;
  /** @format int32 */
  userDefinedDomesticBankIdentificationTypeId?: number;
  userDefinedDomesticBankName?: string;
  userPermissions?: string[];
  wires?: Wire2Dto[];
};
export interface TupleOfStringAndDateTimeDto {
  item1?: string;
  /** @format date-time */
  item2: string;
}
export type BeneficiaryAudit2Dto = BaseEntity2Dto & {
  /** @format date-time */
  actionDate: string;
  /** @format int32 */
  actionUserId: number;
  beneficiary?: Beneficiary2Dto;
  beneficiaryActionType?: BeneficiaryActionType2Dto;
  /** @format int32 */
  beneficiaryActionTypeId: number;
  /** @format int32 */
  beneficiaryId: number;
  comments?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  rowVersionId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
};
export type BeneficiaryActionType2Dto = BaseEntity2Dto & {
  beneficiaryAudits?: BeneficiaryAudit2Dto[];
  /** @format int32 */
  id: number;
  name?: string;
};
export type DomesticBank2Dto = BaseEntity2Dto & {
  addressLine1?: string;
  addressLine2?: string;
  bankName?: string;
  city?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate?: string;
  domesticBankTypeId?: number;
  /** @format int32 */
  id: number;
  isActive: boolean;
  onUs: boolean;
  routingNumber?: string;
  rowStatus?: RowStatus2Dto;
  rowStatusId?: number;
  /** @format int32 */
  rowVersionId?: number;
  settlementType?: string;
  state?: State2Dto;
  stateCode?: string;
  /** @format int32 */
  stateId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  zipCode?: string;
};
export type State2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  stateCode?: string;
  stateName?: string;
};
export type BankIdentificationType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type Wire2Dto = BaseWire2Dto & {
  approvedBy?: TupleOfStringAndDateTimeDto[];
  /** @format int32 */
  completedApprovals: number;
  createdByUser?: User2Dto;
  /** @format decimal */
  destinationCurrencyAmount: number;
  duplicatePreventionId?: string;
  eligibleApprovers?: string[];
  errorSummary?: ErrorSummary2Dto;
  /** @format decimal */
  exchangeFee: number;
  fileName?: string;
  source?: string;
  /** @format decimal */
  totalCost: number;
  updatedByUser?: User2Dto;
  userPermissions?: string[];
  wireActionTypeEnum: WireActionTypeEnum2Dto;
  wireAudits?: WireAudit2Dto[];
  wireFileUniqueId?: string;
  wireQuotes?: WireQuote2Dto[];
};
export interface ErrorSummary2Dto {
  details?: ErrorDetail2Dto[];
  summaryMessage?: string;
  summaryMessageList?: string[];
  type: ErrorType2Dto;
}
export interface ErrorDetail2Dto {
  attributeName?: string;
  key?: string;
  message?: string;
  messageList?: Lookup2Dto[];
  type: ErrorType2Dto;
}
export enum ErrorType2Dto {
  Error = 0,
  Info = 1,
  Warning = 2,
}
export enum WireActionTypeEnum2Dto {
  Undefined = 0,
  New = 1,
  Modified = 2,
  Cancelled = 3,
  QuoteRequested = 4,
  QuoteAccepted = 5,
  QuoteRejected = 6,
  Approved = 7,
  Rejected = 8,
  Transmitted = 9,
  Expired = 10,
  Failed = 11,
  Posted = 12,
  Deleted = 13,
}
export type WireAudit2Dto = BaseEntity2Dto & {
  /** @format date-time */
  actionDate: string;
  /** @format int32 */
  actionUserId: number;
  comments?: string;
  /** @format int32 */
  id: number;
  user?: User2Dto;
  wire?: Wire2Dto;
  wireActionType?: WireActionType2Dto;
  /** @format int32 */
  wireActionTypeId: number;
  /** @format int32 */
  wireId: number;
};
export type WireActionType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  wireActionType1?: string;
  wireAudits?: WireAudit2Dto[];
};
export type WireQuote2Dto = BaseEntity2Dto & {
  amexContractNumber?: string;
  /** @format int32 */
  amexQuoteId?: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  createdUser?: User2Dto;
  /** @format int32 */
  debitAccountId?: number;
  /** @format decimal */
  destinationCurrencyAmount: number;
  destinationCurrencyCode?: string;
  /** @format decimal */
  exchangeFee: number;
  /** @format decimal */
  exchangeRate: number;
  /** @format date-time */
  expirationDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionFxWireRatesheetId?: number;
  /** @format date-time */
  quoteDate: string;
  /** @format int32 */
  quoteStatusTypeId: number;
  rateId?: string;
  /** @format decimal */
  totalWireCost: number;
  /** @format decimal */
  usdAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  wire?: Wire2Dto;
  /** @format decimal */
  wireFee: number;
  wireFeeCurCode?: string;
  /** @format int32 */
  wireId?: number;
  wireQuoteStatusType?: WireQuoteStatusType2Dto;
};
export type WireQuoteStatusType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireQuotes?: WireQuote2Dto[];
};
export type BaseWire2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  additionalInformation?: string;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  approvalCount: number;
  associatedWire?: Wire2Dto;
  /** @format int32 */
  associatedWireId?: number;
  beneficiary?: Beneficiary2Dto;
  beneficiaryDetails?: string;
  /** @format int32 */
  beneficiaryId: number;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  confirmationNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  debitAccountId: number;
  destinationCurrencyCode?: string;
  /** @format date-time */
  endDate?: string;
  externallyProcessed: boolean;
  failureReason?: string;
  /** @format int32 */
  firstRepeatOnDay?: number;
  /** @format int32 */
  frequencyTypeId: number;
  /** @format int32 */
  id: number;
  isDliWire: boolean;
  isDomestic: boolean;
  parentCompany?: Company2Dto;
  paymentFrequencyType?: PaymentFrequencyType2Dto;
  paymentId?: string;
  purpose?: string;
  referenceBeneficiary?: string;
  referenceNumber?: string;
  repeatOnLastBusinessDay?: boolean;
  /** @format int32 */
  secondRepeatOnDay?: number;
  /** @format date-time */
  startDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format date-time */
  valueDate?: string;
  /** @format int32 */
  wireCompanyId: number;
  wireFile?: WireFile2Dto;
  /** @format int32 */
  wireFileId?: number;
  wireStatusType?: WireStatusType2Dto;
  /** @format int32 */
  wireStatusTypeId: number;
  wireTemplate?: WireTemplate2Dto;
  /** @format int32 */
  wireTemplateId?: number;
};
export type PaymentFrequencyType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  schedules?: Schedule2Dto[];
  wires?: Wire2Dto[];
};
export type Schedule2Dto = BaseEntity2Dto & {
  canSpawnMultipleJobs?: boolean;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  firstRepeatOn?: number;
  /** @format int32 */
  frequencyId: number;
  /** @format int32 */
  id: number;
  importBatchId?: string;
  institutionFiId?: string;
  /** @format int32 */
  interval?: number;
  isWeekendIncluded?: boolean;
  /** @format date-time */
  lastScheduled?: string;
  pauseSchedule: boolean;
  paymentFrequencyType?: PaymentFrequencyType2Dto;
  productType?: string;
  recycleJob: boolean;
  repeatOnLastBusinessDay?: boolean;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  runningTime?: number;
  scheduleName?: string;
  scheduleSystem?: ScheduleSystem2Dto;
  /** @format int32 */
  scheduleSystemId: number;
  scheduledJobs?: ScheduledJob2Dto[];
  /** @format int32 */
  secondRepeatOn?: number;
  /** @format date-time */
  startDateTime?: string;
  /** @format int32 */
  systemUniqueId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId?: number;
  /** @format date-time */
  valueDateTime?: string;
};
export type ScheduleSystem2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  schedules?: Schedule2Dto[];
};
export type ScheduledJob2Dto = BaseEntity2Dto & {
  /** @format guid */
  activityId?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  executionDateTime: string;
  /** @format date-time */
  frequencyTargetDate?: string;
  /** @format int64 */
  id: number;
  jobStatusType?: JobStatusType2Dto;
  /** @format byte */
  jobStatusTypeId: number;
  responseMessages?: string;
  schedule?: Schedule2Dto;
  /** @format int32 */
  scheduleId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type JobStatusType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format byte */
  id: number;
  name?: string;
  scheduledJobs?: ScheduledJob2Dto[];
};
export interface WireFile2Dto {
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  approvedCount?: number;
  /** @format int32 */
  batchCount?: number;
  /** @format int32 */
  companyId?: number;
  deletedBy?: string;
  /** @format date-time */
  deletedDate?: string;
  /** @format guid */
  duplicateWireFileUniqueId?: string;
  /** @format int32 */
  expiredCount?: number;
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId: number;
  /** @format int32 */
  fileSize: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId?: number;
  isDeleted: boolean;
  isDuplicated: boolean;
  isExpired?: boolean;
  /** @format int32 */
  pendingCount?: number;
  processedBy?: string;
  /** @format date-time */
  processedDate?: string;
  /** @format int32 */
  rejectedCount?: number;
  status?: string;
  /** @format int32 */
  totalWireCount?: number;
  /** @format guid */
  wireFileUniqueId: string;
  wires?: Wire2Dto[];
}
export type FileArchive2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format byte */
  fileContent?: string;
  /** @format int32 */
  fileFormatTypeId?: number;
  fileName?: string;
  /** @format int32 */
  fileTypeId: number;
  fileValidationFailures?: FileValidationFailure2Dto[];
  /** @format int32 */
  id: number;
  isValid: boolean;
  /** @format guid */
  uniqueId: string;
};
export interface FileValidationFailure2Dto {
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId: number;
  fileValidationFailureType?: FileValidationFailureType2Dto;
  /** @format int32 */
  fileValidationFailureTypeId: number;
  /** @format int32 */
  id: number;
  validationFailureJson?: string;
}
export interface FileValidationFailureType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type WireStatusType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  wires?: Wire2Dto[];
};
export type WireTemplate2Dto = BaseEntity2Dto & {
  /** @format int32 */
  approvalCount: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  beneficiary?: Beneficiary2Dto;
  /** @format int32 */
  beneficiaryId: number;
  /** @format int32 */
  completedApprovals: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  debitAccount?: Account2Dto;
  /** @format int32 */
  debitAccountId: number;
  eligibleApprovers?: string[];
  errorSummary?: ErrorSummary2Dto;
  /** @format int32 */
  id: number;
  initiatedRecurringWire: boolean;
  isInternational: boolean;
  notes?: string;
  purpose?: string;
  referenceBeneficiary?: string;
  templateName?: string;
  templateStatusType?: WireTemplateStatusType2Dto;
  /** @format int32 */
  templateStatusTypeId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  userPermissions?: string[];
  wireCompany?: Company2Dto;
  /** @format int32 */
  wireCompanyId: number;
  wireTemplateAudits?: WireTemplateAudit2Dto[];
};
export type WireTemplateStatusType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireTemplates?: WireTemplate2Dto[];
};
export type WireTemplateAudit2Dto = BaseEntity2Dto & {
  /** @format date-time */
  actionDate: string;
  actionUser?: User2Dto;
  /** @format int32 */
  actionUserId: number;
  /** @format int32 */
  id: number;
  wireTemplateActionType?: WireTemplateActionType2Dto;
  /** @format int32 */
  wireTemplateActionTypeId: number;
  /** @format int32 */
  wireTemplateId: number;
};
export type WireTemplateActionType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  name?: string;
  wireTemplateAudits?: WireTemplateAudit2Dto[];
};
export type InformationCenterConfiguration2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  id: number;
  informationCenterMessageType?: InformationCenterMessageType2Dto;
  /** @format int32 */
  informationCenterMessageTypeId: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  sequence?: number;
  /** @format date-time */
  startDate: string;
  title?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type InformationCenterMessageType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  informationCenterConfigurations?: InformationCenterConfiguration2Dto[];
  name?: string;
};
export type InstitutionNotificationType2Dto = BaseEntity2Dto & {
  desktopNotification: boolean;
  email: boolean;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  isNotificationEnabled: boolean;
  isRequired: boolean;
  notificationGroup?: string;
  notificationType?: NotificationType2Dto;
  /** @format int32 */
  notificationTypeId: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  sortOrder?: number;
  textMessage: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type NotificationType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionNotificationTypes?: InstitutionNotificationType2Dto[];
  isActiveByDefault: boolean;
  name?: string;
  notificationPriorityType?: NotificationPriorityType2Dto;
  /** @format int32 */
  notificationPriorityTypeId: number;
  notificationProductType?: NotificationProductType2Dto;
  /** @format int32 */
  notificationProductTypeId: number;
  notifications?: Notification2Dto[];
  /** @format int32 */
  sortOrder?: number;
  userNotificationTypes?: UserNotificationType2Dto[];
};
export interface NotificationPriorityType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  notificationTypes?: NotificationType2Dto[];
}
export type NotificationProductType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  notificationTypes?: NotificationType2Dto[];
};
export interface Notification2Dto {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  createdDateFiLocalTime?: string;
  /** @format int32 */
  id: number;
  notificationPayload?: string;
  notificationType?: NotificationType2Dto;
  /** @format int32 */
  notificationTypeId: number;
  /** @format guid */
  notificationUniqueIdentifier: string;
  showAlert: boolean;
  /** @format int32 */
  userNotificationId: number;
}
export type UserNotificationType2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  desktopNotification?: boolean;
  email?: boolean;
  /** @format int32 */
  id: number;
  isDesktopAllowed?: boolean;
  isDesktopRequired: boolean;
  isEmailAllowed?: boolean;
  isEmailRequired: boolean;
  isTextAllowed?: boolean;
  isTextRequired: boolean;
  notificationGroup?: string;
  notificationType?: NotificationType2Dto;
  /** @format int32 */
  notificationTypeId: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  sortOrder?: number;
  textMessage?: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type ResourceConfiguration2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  displayExternalWarning: boolean;
  externalWarningMessage?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  link?: string;
  /** @format byte */
  logo?: string;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  sequence?: number;
  title?: string;
  tooltip?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type Role2Dto = BaseEntity2Dto & {
  associatedClaims?: AssociatedClaim2Dto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  description?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  name?: string;
  roleType?: RoleType2Dto;
  /** @format int32 */
  roleTypeId: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userRoles?: UserRole2Dto[];
};
export type RoleType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  roles?: Role2Dto[];
};
export type UserRole2Dto = BaseEntity2Dto & {
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  role?: Role2Dto;
  /** @format int32 */
  roleId: number;
  /** @format date-time */
  startDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export interface CompanyAccountDisplaySetting2Dto {
  accountMaskType?: AccountMaskType2Dto;
  /** @format int32 */
  accountMaskTypeId: number;
  allowOverrideMasking: boolean;
  company?: Company2Dto;
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  showAccountName: boolean;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
}
export interface AccountMaskType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
}
export type CompanyLimit2Dto = BaseEntity2Dto & {
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  monthlyLimit?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CompanyType2Dto = BaseEntity2Dto & {
  companies?: Company2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface CompanyUserApprovalSetting2Dto {
  boUserRequireBoApproval: boolean;
  chUserRequireBoApproval: boolean;
  chUserRequireChApproval: boolean;
  company?: Company2Dto;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type FileImportLayout2Dto = BaseEntity2Dto & {
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  delimiter?: string;
  fileFormatType?: FileFormatType2Dto;
  /** @format int32 */
  fileFormatTypeId: number;
  fileImportLayoutDetails?: FileImportLayoutDetail2Dto[];
  /** @format int32 */
  footerRows?: number;
  /** @format int32 */
  headerRows?: number;
  /** @format int32 */
  id: number;
  productType?: ProductType2Dto;
  /** @format int32 */
  productTypeId: number;
  templateName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type FileFormatType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type FileImportLayoutDetail2Dto = BaseEntity2Dto & {
  additionalInformationFour?: string;
  additionalInformationOne?: string;
  additionalInformationThree?: string;
  additionalInformationTwo?: string;
  /** @format int32 */
  beginPosition?: number;
  /** @format int32 */
  endPosition?: number;
  fileImportField?: FileImportField2Dto;
  /** @format int32 */
  fileImportFieldId: number;
  fileImportLayout?: FileImportLayout2Dto;
  /** @format int32 */
  fileImportLayoutId: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type FileImportField2Dto = BaseEntity2Dto & {
  description?: string;
  fieldName?: string;
  fileImportFieldGroup?: FileImportFieldGroup2Dto;
  /** @format int32 */
  fileImportFieldGroupId?: number;
  fileType?: FileType2Dto;
  /** @format int32 */
  fileTypeId: number;
  /** @format int32 */
  id: number;
  info?: string;
  isRequired?: boolean;
};
export type FileImportFieldGroup2Dto = BaseEntity2Dto & {
  description?: string;
  fileFormatType?: FileFormatType2Dto;
  fileImportFields?: FileImportField2Dto[];
  /** @format int32 */
  fileTypeId: number;
  groupName?: string;
  /** @format int32 */
  id: number;
  info?: string;
};
export type FileType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type InternalTransferTransaction2Dto = BaseEntity2Dto & {
  /** @format decimal */
  amount: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  batchId?: string;
  childInternalTransferTransactions?: InternalTransferTransaction2Dto[];
  comments?: string;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  confirmationNumber?: string;
  createdBy?: string;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  /** @format date-time */
  endDate?: string;
  errorSummary?: ErrorSummary2Dto;
  /** @format int32 */
  firstRepeatOnDay?: number;
  fromAccount?: Account2Dto;
  /** @format int32 */
  fromAccountId: number;
  hasNoEndDate?: boolean;
  /** @format int32 */
  id: number;
  internalTransferAudits?: InternalTransferAudit2Dto[];
  internalTransferStatusType?: InternalTransferStatusType2Dto;
  /** @format int32 */
  internalTransferStatusTypeId: number;
  internalTransferTemplate?: InternalTransferTemplate2Dto;
  /** @format int32 */
  internalTransferTemplateId?: number;
  isEdited: boolean;
  /** @format date-time */
  lastEditedDate: string;
  memo?: string;
  message?: string;
  messageType?: string;
  /** @format int32 */
  numberOfApprovalNeeded?: number;
  parentInternalTransferTransaction?: InternalTransferTransaction2Dto;
  /** @format int32 */
  parentInternalTransferTransactionId?: number;
  paymentFrequencyType?: PaymentFrequencyType2Dto;
  /** @format int32 */
  paymentFrequencyTypeId: number;
  paymentOptionType?: PaymentOptionType2Dto;
  /** @format int32 */
  paymentOptionTypeId?: number;
  permissions?: ProductPermission2Dto[];
  repeatOnLastBusinessDay?: boolean;
  /** @format int32 */
  seconRepeatOnDay?: number;
  /** @format date-time */
  startDate?: string;
  submittedToScheduler: boolean;
  toAccount?: Account2Dto;
  /** @format int32 */
  toAccountId: number;
  transactionId?: string;
  /** @format date-time */
  transferDate?: string;
  updatedBy?: string;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
};
export interface InternalTransferAudit2Dto {
  comments?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  internalTransferActionType?: InternalTransferActionType2Dto;
  /** @format int32 */
  internalTransferActionTypeId: number;
  /** @format int32 */
  internalTransferAuditId: number;
  internalTransferTransaction?: InternalTransferTransaction2Dto;
  /** @format int32 */
  internalTransferTransactionId: number;
  user?: User2Dto;
}
export interface InternalTransferActionType2Dto {
  description?: string;
  /** @format int32 */
  internalTransferActionTypeId: number;
  internalTransferAudits?: InternalTransferAudit2Dto[];
  name?: string;
}
export interface InternalTransferStatusType2Dto {
  description?: string;
  /** @format int32 */
  internalTransferStatusTypeId: number;
  internalTransferTransactions?: InternalTransferTransaction2Dto[];
  name?: string;
}
export type InternalTransferTemplate2Dto = BaseEntity2Dto & {
  /** @format int32 */
  approvalCount: number;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  completedApprovals: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  fromAccountNumber?: string;
  /** @format int32 */
  id: number;
  internalTransferTemplateAudits?: InternalTransferTemplateAudit2Dto[];
  internalTransferTemplateDetails?: InternalTransferTemplateDetail2Dto[];
  internalTransferTemplateStatusType?: InternalTransferTemplateStatusType2Dto;
  /** @format int32 */
  internalTransferTemplateStatusTypeId: number;
  internalTransferType?: InternalTransferType2Dto;
  /** @format int32 */
  internalTransferTypeId: number;
  oneToOneMemo?: string;
  templateAccountDetails?: Account2Dto[];
  templateName?: string;
  toAccountNumber?: string;
  /** @format decimal */
  totalAmount: number;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export interface InternalTransferTemplateAudit2Dto {
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  internalTransferTemplate?: InternalTransferTemplate2Dto;
  internalTransferTemplateActionType?: InternalTransferTemplateActionType2Dto;
  /** @format int32 */
  internalTransferTemplateActionTypeId: number;
  /** @format int32 */
  internalTransferTemplateId: number;
}
export interface InternalTransferTemplateActionType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InternalTransferTemplateDetail2Dto {
  /** @format decimal */
  amount: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  fromAccount?: Account2Dto;
  /** @format int32 */
  fromAccountId: number;
  /** @format int32 */
  id: number;
  internalTransferTemplate?: InternalTransferTemplate2Dto;
  /** @format int32 */
  internalTransferTemplateId: number;
  memo?: string;
  toAccount?: Account2Dto;
  /** @format int32 */
  toAccountId: number;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
}
export interface InternalTransferTemplateStatusType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InternalTransferType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type PaymentOptionType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface ProductPermission2Dto {
  /** @format int32 */
  id: number;
  permission?: string;
  product?: Product2Dto;
  productAccountPermissions?: ProductAccountPermission2Dto[];
  /** @format int32 */
  productId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface ProductAccountPermission2Dto {
  accountProduct?: AccountProduct2Dto;
  /** @format int32 */
  accountProductId: number;
  entitlements?: Entitlement2Dto[];
  /** @format int32 */
  id: number;
  productPermission?: ProductPermission2Dto;
  /** @format int32 */
  productPermissionId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface Entitlement2Dto {
  /** @format int32 */
  id: number;
  objectState: ObjectState2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  user?: User2Dto;
}
export type UserAccount2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  user?: User2Dto;
  userAccountLimits?: UserAccountLimit2Dto[];
  /** @format int32 */
  userId: number;
};
export type UserAccountLimit2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  perTransactionLimit: number;
  productType?: ProductType2Dto;
  /** @format int32 */
  productTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userAccount?: UserAccount2Dto;
  /** @format int32 */
  userAccountId: number;
};
export interface InstitutionAccountDisplaySetting2Dto {
  accountMaskType?: AccountMaskType2Dto;
  /** @format int32 */
  accountMaskTypeId: number;
  allowOverrideMasking: boolean;
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  showAccountName: boolean;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionAffiliation2Dto {
  /** @format int32 */
  affiliatedInstitutionId: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionFraudProvider2Dto {
  institution?: Institution2Dto;
  provider?: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionMessageCenter2Dto = BaseEntity2Dto & {
  archiveSetting?: ArchiveSetting2Dto;
  /** @format int32 */
  archiveSettingId: number;
  disclaimer?: string;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  sharedEmail?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  useSharedEmail: boolean;
};
export interface ArchiveSetting2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface InstitutionNachaFileRecord2Dto {
  addendaRecordIndicator?: string;
  blockingFactor?: string;
  checkDigit?: string;
  fileIdModifier?: string;
  formatCode?: string;
  /** @format int32 */
  id: number;
  immediateDestination?: string;
  immediateDestinationName?: string;
  immediateOrigin?: string;
  immediateOriginName?: string;
  institution?: Institution2Dto;
  originatingDfi?: string;
  originatorStatusCode?: string;
  priorityCode?: string;
  receivingDfi?: string;
  recordSize?: string;
}
export interface InstitutionOobConfiguration2Dto {
  allowedOobChallengeFailureCount: number;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  oobChallengePinNeeded: boolean;
  oobEnabled: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionTerm2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  requireAcceptance: boolean;
  termsAndConditions?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface InstitutionUserApprovalSetting2Dto {
  boUserRequireBoApproval: boolean;
  chUserRequireBoApproval: boolean;
  chUserRequireChApproval: boolean;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionWireInternationalWorkflowOverrides2Dto = BaseEntity2Dto & {
  allowAccountBalanceValidation: boolean;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  forceInternationalUsdViaForeignCurrencyWorkflow: boolean;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  useExternalFxQuoteRatesheets: boolean;
  useExternalFxWireProcessing: boolean;
  useNonCoreWireCompanies: boolean;
};
export type LoginConfiguration2Dto = BaseEntity2Dto & {
  allowAlphabetsInLoginId: boolean;
  allowAlphabetsInPassword: boolean;
  allowLockedOutMessage: boolean;
  allowNumbersInLoginId: boolean;
  allowNumbersInPassword: boolean;
  allowSpecialCharactersInLoginId: boolean;
  allowSpecialCharactersInPassword: boolean;
  alphabetsInLoginIdAreRequired: boolean;
  alphabetsInPasswordAreRequired: boolean;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  invalidLoginIdPasswordMessage?: string;
  isCompany: boolean;
  isDefault: boolean;
  lockedEmailMessage?: string;
  lockedOutMessage?: string;
  /** @format int32 */
  loginIdMaxLength: number;
  /** @format int32 */
  loginIdMinLength: number;
  /** @format int32 */
  numberOfAllowedAttemptsBeforeLock: number;
  /** @format int32 */
  numberOfLowercaseRequiredInLoginId: number;
  /** @format int32 */
  numberOfLowercaseRequiredInPassword: number;
  /** @format int32 */
  numberOfPrevUsedPasswords: number;
  /** @format int32 */
  numberOfUppercaseRequiredInLoginId: number;
  /** @format int32 */
  numberOfUppercaseRequiredInPassword: number;
  numbersInLoginIdAreRequired: boolean;
  numbersInPasswordAreRequired: boolean;
  /** @format int32 */
  passwordExpireInDays: number;
  /** @format int32 */
  passwordMaxLength: number;
  /** @format int32 */
  passwordMinLength: number;
  sendLockedEmail: boolean;
  /** @format int32 */
  sessionTimeoutMinutes: number;
  specialCharactersInLoginIdAreRequired: boolean;
  specialCharactersInPasswordAreRequired: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  warnPasswordExpireInDays: number;
  willPasswordExpire: boolean;
};
export type TransactionCodeGroup2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  isReadOnly: boolean;
  name?: string;
  transactionCodeGroupAssociations?: TransactionCodeGroupAssociation2Dto[];
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type TransactionCodeGroupAssociation2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  transactionCode?: TransactionCode2Dto;
  transactionCodeGroup?: TransactionCodeGroup2Dto;
  /** @format int32 */
  transactionCodeGroupId: number;
  /** @format int32 */
  transactionCodeId: number;
};
export type TransactionCode2Dto = BaseEntity2Dto & {
  achTransactionCodes?: AchTransactionCode2Dto[];
  /** @format int32 */
  baiCode?: number;
  baiCodeType?: BaiCodeType2Dto;
  changeStatus?: string;
  code?: string;
  description?: string;
  excluded: boolean;
  /** @format int32 */
  id: number;
  institution?: Institution2Dto;
  /** @format int32 */
  institutionId: number;
  isDeleted: boolean;
  isDescriptionChanged: boolean;
  isTypeChanged: boolean;
  ofxTransactionType?: OfxTransactionType2Dto;
  /** @format int32 */
  ofxTransactionTypeId?: number;
  transactionCodeGroupAssociations?: TransactionCodeGroupAssociation2Dto[];
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface AchTransactionCode2Dto {
  accountType?: AccountType4Dto;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  id: number;
  transactionCode?: TransactionCode2Dto;
  /** @format int32 */
  transactionCodeId: number;
  transactionType?: TransactionType3Dto;
  /** @format int32 */
  transactionTypeId: number;
}
export type BaiCodeType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  baiCode: number;
  /** @format int32 */
  baiCodeTitleId: number;
  description?: string;
  isSelected: boolean;
  level?: string;
  transactionType?: string;
};
export type OfxTransactionType2Dto = BaseEntity2Dto & {
  description?: string;
  displayName?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface InstitutionAchProduct2Dto {
  accountBalanceValidationConfiguredInCore: boolean;
  achCompanyMatchType?: AchCompanyMatchType2Dto;
  /** @format int32 */
  achCompanyMatchTypeId: number;
  allowChildSupportPayments: boolean;
  allowFederalTaxPayments: boolean;
  allowInternationalPayments: boolean;
  allowNotificationOfChange: boolean;
  allowOnUsAccessManagement: boolean;
  allowReturnReview: boolean;
  allowSameDayAch: boolean;
  allowStateTaxPayments: boolean;
  allowTaxPayments: boolean;
  allowUnbalancedPayments: boolean;
  /** @format int32 */
  batchApprovalCount: number;
  batchAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  batchAuthenticationTypeId: number;
  copyInitiatedNachaFiles?: boolean;
  /** @format int32 */
  daysInAdvance?: number;
  /** @format int32 */
  id: number;
  institutionAchRoutings?: InstitutionAchRouting2Dto[];
  institutionAchSecCodes?: InstitutionAchSecCode2Dto[];
  institutionProductType?: InstitutionProductType2Dto;
  internationalEnabled: boolean;
  /** @format int32 */
  internationalTemplateApprovalCount: number;
  nachaFileUploadPath?: string;
  /** @format int32 */
  paymentApprovalCount: number;
  paymentAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  paymentProcessingCompanyConfiguredInCore?: boolean;
  /** @format int32 */
  recipientApprovalCount: number;
  remoteServerHost?: string;
  remoteServerPassword?: string;
  /** @format int32 */
  remoteServerPort?: number;
  remoteServerUserName?: string;
  /** @format decimal */
  sameDayAchLimit?: number;
  stateTaxCodesArchiveFile?: FileArchive2Dto;
  /** @format int32 */
  stateTaxCodesArchiveFileId?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  validateAccountBalance: boolean;
}
export type AchCompanyMatchType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  institutionAchProducts?: InstitutionAchProduct2Dto[];
  name?: string;
};
export type InstitutionAchRouting2Dto = BaseEntity2Dto & {
  bankName?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  id: number;
  institutionAchProduct?: InstitutionAchProduct2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  routingNumber?: string;
  /** @format date-time */
  startDate: string;
};
export type InstitutionAchSecCode2Dto = BaseEntity2Dto & {
  achSecCode?: AchSecCode2Dto;
  /** @format int32 */
  achSecCodeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  institutionAchProduct?: InstitutionAchProduct2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
};
export interface InstitutionArpProduct2Dto {
  allowIssuedItemActivityReview: boolean;
  allowSubstituteProduct: boolean;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  manageAchExceptions: boolean;
  showAchExceptions: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  workAchException: boolean;
  workCheckException: boolean;
}
export interface InstitutionBillPayProduct2Dto {
  allowIPayBusinessBillPayForMobile: boolean;
  billPayServiceName?: string;
  billPayServiceUrl?: string;
  clientId?: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  landingPageUrl?: string;
  /** @format int32 */
  navigationMode?: number;
  sharedKey?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface InstitutionElectronicDocuments2Dto {
  clientId?: string;
  electronicDocumentServiceName?: string;
  electronicDocumentServiceUri?: string;
  electronicDocumentType?: ElectronicDocumentType2Dto;
  /** @format int32 */
  electronicDocumentTypeId?: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  /** @format double */
  monthlyFee: number;
  /** @format int32 */
  navigationMode?: number;
  sharedKey?: string;
  showSsoFields?: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface ElectronicDocumentType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  institutionElectronicDocuments?: InstitutionElectronicDocuments2Dto[];
  name?: string;
}
export type InstitutionInternalTransferProduct2Dto = BaseEntity2Dto & {
  allowLoanPaymentPastDue: boolean;
  /** @format int32 */
  id: number;
  institutionInternalTransferProductLinkAccounts?: InstitutionInternalTransferProductLinkAccount2Dto[];
  institutionProductType?: InstitutionProductType2Dto;
};
export type InstitutionInternalTransferProductLinkAccount2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  fromAccountTypeId: number;
  /** @format int32 */
  id: number;
  institutionInternalTransferProduct?: InstitutionInternalTransferProduct2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format int32 */
  toAccountTypeId: number;
};
export type InstitutionProductCutoffTime2Dto = BaseEntity2Dto & {
  /** @format duration */
  blackoutPeriodEndTime?: string;
  /** @format duration */
  blackoutPeriodStartTime?: string;
  cutoffTimeSubType?: CutoffTimeSubType2Dto;
  /** @format int32 */
  cutoffTimeSubTypeId?: number;
  /** @format duration */
  endCutoffTime: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  processingDayType?: ProcessingDayType2Dto;
  /** @format int32 */
  processingDayTypeId?: number;
  showInChannel?: boolean;
  /** @format duration */
  startCutoffTime?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type CutoffTimeSubType2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export type ProcessingDayType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  institutionProductCutoffTimes?: InstitutionProductCutoffTime2Dto[];
  processingDay?: string;
};
export type InstitutionReportingConfiguration2Dto = BaseEntity2Dto & {
  hasBai: boolean;
  hasCsv: boolean;
  hasDownload: boolean;
  hasEmail: boolean;
  hasPdf: boolean;
  hasQuickBooks: boolean;
  hasQuicken: boolean;
  hasTiff: boolean;
  hasWord: boolean;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format int32 */
  standardReportId: number;
  supportsBai: boolean;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type InstitutionReportingProduct2Dto = BaseEntity2Dto & {
  channelMobile: boolean;
  channelTablet: boolean;
  channelWeb: boolean;
  exportFormats?: ReportExportFormat2Dto[];
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
};
export interface ReportExportFormat2Dto {
  isConfigured: boolean;
  name?: string;
}
export type InstitutionSsoProduct2Dto = BaseEntity2Dto & {
  clientId?: string;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  landingPageUrl?: string;
  rowStatusId: number;
  serviceUrl?: string;
  sharedEncryptionInitializationVector?: string;
  sharedEncryptionKey?: string;
  ssoLoginSystem?: SsoLoginSystem2Dto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
};
export type SsoLoginSystem2Dto = BaseEntity2Dto & {
  description?: string;
  institutionSsoProducts?: InstitutionSsoProduct2Dto[];
  name?: string;
  /** @format int32 */
  ssoLoginSystemId: number;
  userSsoLogins?: UserSsoLogin2Dto[];
};
export type UserSsoLogin2Dto = BaseEntity2Dto & {
  /** @format int32 */
  rowStatusId: number;
  ssoAccessKey?: string;
  ssoLoginId?: string;
  ssoLoginSystem?: SsoLoginSystem2Dto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  userSsoLoginId: number;
};
export interface InstitutionStopPayment2Dto {
  approvalCount: number;
  /** @format double */
  feePerRequest: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export type InstitutionWireInternationalProduct2Dto = BaseEntity2Dto & {
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format decimal */
  dailyLimit: number;
  fxClientId?: string;
  fxPaymentNotificationEmailAddresses?: TextKeyCollection3Dto;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSetting2Dto[];
  internationalUsdReadOnly: boolean;
  /** @format byte */
  outgoingSettlementTypeId: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType: number;
  requirePaymentPurpose: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  validateAccountBalance?: boolean;
  wireSettlementAccount?: string;
  wireTraditionalInternationalEnabled: boolean;
  wireUsdEnabled: boolean;
};
export type TextKeyCollection3Dto = string[];
export interface InstitutionWirePaymentApprovalSetting2Dto {
  /** @format byte */
  approvalCount: number;
  /** @format decimal */
  endAmount?: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  /** @format int32 */
  institutionProductTypeId: number;
  /** @format decimal */
  startAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
}
export type InstitutionWireProduct2Dto = BaseEntity2Dto & {
  allowWireFileUpload?: boolean;
  /** @format int32 */
  beneficiaryApprovalCount: number;
  beneficiaryAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  beneficiaryAuthenticationTypeId?: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  institutionProductType?: InstitutionProductType2Dto;
  institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSetting2Dto[];
  internationalEnabled: boolean;
  limitFutureDateEnabled: boolean;
  /** @format int32 */
  maximumFutureDays?: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType?: number;
  paymentAuthenticationType?: AuthenticationType2Dto;
  /** @format int32 */
  paymentAuthenticationTypeId?: number;
  requirePaymentPurpose?: boolean;
  /** @format byte */
  templateApprovalCount?: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  wireDliInternationalEnabled: boolean;
};
export type UserType12Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  users?: User2Dto[];
};
export type FavoriteReport2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilter2Dto;
  /** @format int32 */
  reportingFilterId: number;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type ReportingFilter2Dto = BaseEntity2Dto & {
  accountTypes?: string;
  amountOperator?: string;
  companyUniqueId?: string;
  dateType?: string;
  favoriteReports?: FavoriteReport2Dto[];
  /** @format decimal */
  fromAmount?: number;
  /** @format date-time */
  fromDate?: string;
  fromPaymentId?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId?: number;
  isAscendingSort: boolean;
  isFavorite: boolean;
  isStandardReport: boolean;
  name?: string;
  /** @format int32 */
  owner?: number;
  paymentIdOperator?: string;
  paymentTypes?: FilterPaymentType2Dto[];
  postedTransactions?: boolean;
  /** @format int32 */
  priorDays?: number;
  reportType?: string;
  reportingBalanceFilter?: ReportingBalanceFilter2Dto;
  reportingConfigurations?: ReportingConfiguration2Dto[];
  reportingFilter1?: ReportingFilter2Dto[];
  reportingFilter2?: ReportingFilter2Dto;
  reportingFilterAccounts?: ReportingFilterAccount2Dto[];
  reportingFilterPaymentTypes?: ReportingFilterPaymentType2Dto[];
  reportingFilterSorts?: ReportingFilterSort2Dto[];
  reportingTransactionFilter?: ReportingTransactionFilter2Dto;
  /** @format int32 */
  sequence?: number;
  sortBy?: string;
  /** @format date-time */
  specificDate?: string;
  /** @format int32 */
  standardReportTypeId?: number;
  /** @format decimal */
  toAmount?: number;
  /** @format date-time */
  toDate?: string;
  toPaymentId?: string;
  transactionDirection?: string;
  transactionType?: string;
  type?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
};
export interface FilterPaymentType2Dto {
  type?: string;
}
export type ReportingBalanceFilter2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilter2Dto;
};
export type ReportingConfiguration2Dto = BaseEntity2Dto & {
  hasBai: boolean;
  hasCsv: boolean;
  hasDownload: boolean;
  hasEmail: boolean;
  hasPdf: boolean;
  hasQuickBooks: boolean;
  hasQuicken: boolean;
  hasTiff: boolean;
  hasWord: boolean;
  /** @format int32 */
  id: number;
  product?: Product2Dto;
  /** @format int32 */
  productId: number;
  reportingFilter?: ReportingFilter2Dto;
  /** @format int32 */
  standardReportId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ReportingFilterAccount2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilter2Dto;
  /** @format int32 */
  reportingFilterId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type ReportingFilterPaymentType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  paymentType?: string;
  reportingFilter?: ReportingFilter2Dto;
  /** @format int32 */
  reportingFilterId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface ReportingFilterSort2Dto {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  reportingFilter?: ReportingFilter2Dto;
  /** @format int32 */
  reportingFilterId: number;
  /** @format int32 */
  sequence: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
}
export type ReportingTransactionFilter2Dto = BaseEntity2Dto & {
  amountOperator?: string;
  /** @format decimal */
  fromAmount?: number;
  fromPaymentId?: string;
  /** @format int32 */
  id: number;
  paymentIdOperator?: string;
  reportingFilter?: ReportingFilter2Dto;
  /** @format decimal */
  toAmount?: number;
  toPaymentId?: string;
  transactionDirection?: string;
  transactionType?: string;
};
export type TextMessagingCarrier2Dto = BaseEntity2Dto & {
  active: boolean;
  /** @format int32 */
  id: number;
  name?: string;
  providerId?: string;
  serviceId?: string;
  serviceTypeId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export interface PendingEditOfUser2Dto {
  comment?: string;
  company?: Company2Dto;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isNew: boolean;
  pendingEditInJson?: string;
  /** @format guid */
  pendingEditOfUserUniqueId: string;
  /** @format int32 */
  pendingEditStatusId: number;
  /** @format int32 */
  pendingEditTableId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId: number;
}
export enum PendingEditStatus2Dto {
  Edited = 1,
  Pending = 2,
  Approved = 3,
  Discard = 4,
  Rejected = 5,
  FiPending = 6,
}
export type UserAccountGroup2Dto = BaseEntity2Dto & {
  accountGroupName?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format byte */
  sequency: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  userAccountGroupAccounts?: UserAccountGroupAccount2Dto[];
  /** @format int32 */
  userId: number;
};
export type UserAccountGroupAccount2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  sequence: number;
  userAccountGroup?: UserAccountGroup2Dto;
  /** @format int32 */
  userAccountGroupId: number;
};
export type UserAchCompany2Dto = BaseEntity2Dto & {
  achCompany?: AchCompany2Dto;
  /** @format int32 */
  achCompanyId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  perTransactionLimit?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserAudit2Dto = BaseEntity2Dto & {
  comment?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserDevice2Dto = BaseEntity2Dto & {
  authenticationId?: string;
  /** @format int32 */
  id: number;
  key?: string;
  /** @format date-time */
  keyCreatedDate: string;
  /** @format date-time */
  keyLastUsedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserIpAccessSetting2Dto = BaseEntity2Dto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  restricted?: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserIpAccess2Dto = BaseEntity2Dto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  ipAddress?: string;
  nickname?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserNotification2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isEmailSent: boolean;
  isRead: boolean;
  isTextMessageSent: boolean;
  notification?: Notification2Dto;
  /** @format int32 */
  notificationId: number;
  notificationStatusType?: NotificationStatusType2Dto;
  /** @format int32 */
  notificationStatusTypeId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export interface NotificationStatusType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface UserOobDetail2Dto {
  audioPhoneNumber?: string;
  challengePin?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isLocked: boolean;
  isOobPinCollected: boolean;
  /** @format date-time */
  localSuspendEndDate?: string;
  needCollection: boolean;
  oobAuthCount?: number;
  oobValidationMode?: OobValidationMode2Dto;
  /** @format int32 */
  preferredOobValidationModeId: number;
  remindMeCount?: number;
  smsPhoneNumber?: string;
  /** @format date-time */
  suspendEndDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
}
export type UserPasswordHistory2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  password?: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserProductLimit2Dto = BaseEntity2Dto & {
  /** @format decimal */
  approvalLimit?: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format decimal */
  initiationLimit?: number;
  product?: Product2Dto;
  /** @format int32 */
  productId: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export interface UserSecureTokenSecurity2Dto {
  challengePin?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  isLocked: boolean;
  /** @format date-time */
  localSuspendEndDate?: string;
  needCollection: boolean;
  remindMeCount: number;
  secureChallengePin?: string;
  secureTokenCredentialId?: string;
  /** @format date-time */
  suspendEndDate?: string;
  tokenAuthFailureCount: number;
  tokenCredentialId?: string;
  tokenVendorUserId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
}
export type UserTimeAccessSetting2Dto = BaseEntity2Dto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  restricted?: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
};
export type UserTimeAccess3Dto = BaseEntity2Dto & {
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  dayOfWeekId: number;
  /** @format duration */
  endTime?: string;
  /** @format int32 */
  id: number;
  /** @format duration */
  startTime?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  /** @format int32 */
  userId: number;
  userTimeAccessTypeId: number;
};
export type UserWidget2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  height: number;
  /** @format int32 */
  id: number;
  positionLeft: number;
  positionTop: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  userId: number;
  widget?: Widget2Dto;
  /** @format int32 */
  widgetId: number;
  width: number;
};
export type Widget2Dto = BaseEntity2Dto & {
  defaultContent?: string;
  defaultHeight?: number;
  defaultLeft?: number;
  defaultTop?: number;
  defaultWidth?: number;
  description?: string;
  hasPermission: boolean;
  /** @format int32 */
  id: number;
  imageUrl?: string;
  /** @format int32 */
  institutionId: number;
  isFullScreenAllowed: boolean;
  isPermissionRequired: boolean;
  isRequired: boolean;
  isResizable: boolean;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  name?: string;
  permissionMessage?: string;
  title?: string;
  userWidgets?: UserWidget2Dto[];
};
export interface BatchBalanceType2Dto {
  achCompanies?: AchCompany2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type InternationalPaymentRecipientDto = AchPaymentRecipient2Dto & {
  city?: string;
  country?: string;
  iatTransactionType?: string;
  optionalAddendaLine1?: string;
  optionalAddendaLine2?: string;
  receivingBankCountry?: string;
  receivingBankName?: string;
  receivingBankNumber?: string;
  receivingBankType?: string;
  state?: string;
  streetAddress?: string;
  zipCode?: string;
};
export type ChildSupportPaymentRecipientDto = AchPaymentRecipient2Dto & {
  achPaymentRecipientAddendas?: ChildSupportPaymentRecipientAddendaCollectionDto;
};
export type ChildSupportPaymentRecipientAddendaCollectionDto = ChildSupportPaymentRecipientAddendaDto[];
export interface ChildSupportPaymentRecipientAddendaDto {
  caseIdentifier?: string;
  employmentTermination: boolean;
  fipsCode?: string;
  medicalSupport: boolean;
  nonCustodialParentFirstName?: string;
  nonCustodialParentLastName?: string;
  nonCustodialParentSsn?: string;
  /** @format date-time */
  payDate: string;
}
export type AchBatchRecipientAddenda2Dto = BaseEntity2Dto & {
  /** @format int32 */
  achBatchId?: number;
  achBatchRecipient?: AchBatchRecipient2Dto;
  /** @format int32 */
  achBatchRecipientId: number;
  addenda?: string;
  addendaType?: AddendaType2Dto;
  /** @format int32 */
  addendaTypeId: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  rowNum?: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
};
export type AchFederalTaxTemplate2Dto = BaseEntity2Dto & {
  achFederalTaxTemplateAmounts?: AchFederalTaxTemplateAmount2Dto[];
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountType4Dto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  taxCode?: string;
  taxPayerId?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchFederalTaxTemplateAmount2Dto = BaseEntity2Dto & {
  /** @format int32 */
  achBatchId: number;
  achFederalAmountType?: AchFederalAmountType2Dto;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  /** @format decimal */
  amount: number;
  amountType?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export interface AchFederalAmountType2Dto {
  amountType?: string;
  description?: string;
  /** @format int32 */
  id: number;
}
export interface AchPaymentType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type AchStateTaxTemplate2Dto = BaseEntity2Dto & {
  achStateTaxTemplateAmounts?: AchStateTaxTemplateAmount2Dto[];
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountType4Dto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  state?: State2Dto;
  /** @format int32 */
  stateId: number;
  taxCode?: string;
  taxPayerId?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchStateTaxTemplateAmount2Dto = BaseEntity2Dto & {
  /** @format int32 */
  achBatchId: number;
  achStateAmountType?: AchStateAmountType2Dto;
  /** @format int32 */
  achStateAmountTypeId: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export interface AchStateAmountType2Dto {
  amountType?: string;
  description?: string;
  /** @format int32 */
  id: number;
}
export type BatchStatusType2Dto = BaseEntity2Dto & {
  achBatches?: AchBatch2Dto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
};
export interface AchFederalTaxPayment2Dto {
  achFederalTaxPaymentAmounts?: AchFederalTaxPaymentAmount2Dto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  payFromAccount?: Account2Dto;
  /** @format int32 */
  payFromAccountId?: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountType4Dto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  taxCode?: string;
  taxPayerId?: string;
  /** @format date-time */
  taxPeriodEndDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchFederalTaxPaymentAmount2Dto {
  achFederalAmountType?: AchFederalAmountType2Dto;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  /** @format int32 */
  achPaymentId: number;
  /** @format decimal */
  amount: number;
  amountType?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchNachaFileCopyAudit2Dto {
  achPayment?: AchPayment2Dto;
  /** @format int32 */
  achPaymentId: number;
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  fileCopyStatus?: string;
  /** @format int32 */
  id: number;
  message?: string;
  nachaFileName?: string;
}
export interface AchStateTaxPayment2Dto {
  achStateTaxPaymentAmounts?: AchStateTaxPaymentAmount2Dto[];
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  payFromAccount?: Account2Dto;
  /** @format int32 */
  payFromAccountId?: number;
  recipientAccountNumber?: string;
  recipientAccountType?: AccountType4Dto;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientIdNumber?: string;
  recipientName?: string;
  recipientRoutingNumber?: string;
  state?: State2Dto;
  /** @format int32 */
  stateId?: number;
  taxCode?: string;
  taxPayerId?: string;
  /** @format date-time */
  taxPeriodEndDate: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchStateTaxPaymentAmount2Dto {
  /** @format int32 */
  achPaymentId: number;
  achStateAmountType?: AchStateAmountType2Dto;
  /** @format int32 */
  achStateAmountTypeId: number;
  /** @format decimal */
  amount: number;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface AchStatusType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type ChildSupportPaymentDto = AchPayment2Dto & {
  achPaymentRecipients?: ChildSupportPaymentRecipientCollectionDto;
};
export type ChildSupportPaymentRecipientCollectionDto = ChildSupportPaymentRecipientDto[];
export type InternationalPaymentDto = AchPayment2Dto & {
  achInternationalPaymentRecipients?: InternationalPaymentRecipientCollectionDto;
  city?: string;
  country?: string;
  state?: string;
  streetAddress?: string;
  zipCode?: string;
};
export type InternationalPaymentRecipientCollectionDto = InternationalPaymentRecipientDto[];
export type InternalTransferProduct2Dto = BaseEntity2Dto & {
  allowLoanPaymentPastDue: boolean;
  /** @format int32 */
  approvalCount?: number;
  /** @format int32 */
  authenticationTypeId?: number;
  /** @format decimal */
  dailyLimit?: number;
  /** @format int32 */
  id: number;
  internalTransferProductLinkAccounts?: InternalTransferProductLinkAccount2Dto[];
  product?: Product2Dto;
  /** @format byte */
  templateApprovalCount?: number;
};
export type InternalTransferProductLinkAccount2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  fromAccountType?: AccountType4Dto;
  /** @format int32 */
  fromAccountTypeId: number;
  /** @format int32 */
  id: number;
  internalTransferProduct?: InternalTransferProduct2Dto;
  /** @format int32 */
  productId: number;
  toAccountType?: AccountType4Dto;
  /** @format int32 */
  toAccountTypeId: number;
};
export type WireInternationalProduct2Dto = BaseEntity2Dto & {
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  internationalUsd: boolean;
  internationalUsdReadOnly: boolean;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType: number;
  product?: Product2Dto;
  requirePaymentPurpose: boolean;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  validateAccountBalance?: boolean;
  wirePaymentApprovalSettings?: WirePaymentApprovalSetting2Dto[];
  wireUsdEnabled: boolean;
};
export interface WirePaymentApprovalSetting2Dto {
  /** @format byte */
  approvalCount: number;
  /** @format decimal */
  endAmount?: number;
  /** @format int32 */
  id: number;
  product?: Product2Dto;
  /** @format int32 */
  productId: number;
  /** @format decimal */
  startAmount: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  user?: User2Dto;
  wireProduct?: WireProduct2Dto;
}
export type WireProduct2Dto = BaseEntity2Dto & {
  allowWireFileUpload?: boolean;
  /** @format int32 */
  beneficiaryApprovalCount: number;
  /** @format int32 */
  beneficiaryAuthenticationTypeId: number;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  farthestWireExistInDays: number;
  /** @format int32 */
  id: number;
  internationalEnabled: boolean;
  internationalUsdReadOnly: boolean;
  isCompanyDliUsdEnabled: boolean;
  isCompanyWireProductEnabled: boolean;
  isDliCompanyProductEnabled: boolean;
  isFiDliProductEnabled: boolean;
  isFiDliUsdEnabled: boolean;
  isFiInternationalEnabled: boolean;
  isFiWireProductEnabled: boolean;
  isInternationalUsdEnabled: boolean;
  limitFutureDateEnabled: boolean;
  /** @format int32 */
  maximumFutureDays?: number;
  /** @format int32 */
  paymentApprovalCount: number;
  /** @format byte */
  paymentApprovalType?: number;
  /** @format int32 */
  paymentAuthenticationTypeId: number;
  product?: Product2Dto;
  requirePaymentPurpose?: boolean;
  requirePaymentPurposeDli: boolean;
  /** @format byte */
  templateApprovalCount?: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  updatedUser?: User2Dto;
  useExternalFxWireProcessing: boolean;
  wirePaymentApprovalSettings?: WirePaymentApprovalSetting2Dto[];
};
export type ArpException2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  activityIntentKey?: string;
  arpDecision?: ArpDecision2Dto;
  /** @format int32 */
  arpDecisionId?: number;
  checkImageNumber?: string;
  checkNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  /** @format date-time */
  decisionDate?: string;
  fromworkStation?: string;
  /** @format int32 */
  id: number;
  isDebit: boolean;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  issuedDate?: string;
  /** @format decimal */
  paidAmount?: number;
  payee?: string;
  posPayItemId?: string;
  protected?: string;
  reason?: string;
  resolved?: string;
  sourceOfEntry?: string;
  transactionId?: string;
};
export interface ArpDecision2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type NonCoreWireCompanyInternationalAccount2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  nonCoreWireCompanyInternational?: NonCoreWireCompanyInternational2Dto;
  /** @format decimal */
  perTransactionLimit: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  /** @format int32 */
  wireCompanyId: number;
};
export type NonCoreWireCompanyInternational2Dto = BaseEntity2Dto & {
  /** @format int32 */
  availableBalanceValidationCoreTable?: number;
  company?: Company2Dto;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format decimal */
  dailyLimit: number;
  /** @format int32 */
  id: number;
  nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccount2Dto[];
  /** @format decimal */
  perTransactionLimit: number;
  rowStatus?: RowStatus2Dto;
  rowStatusId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  wireInternationalBalanceValidationType?: WireInternationalBalanceValidationType2Dto;
  /** @format int32 */
  wireInternationalBalanceValidationTypeId?: number;
};
export type WireInternationalBalanceValidationType2Dto = BaseEntity2Dto & {
  /** @format int32 */
  id: number;
  name?: string;
};
export type StopPayment2Dto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format double */
  amount?: number;
  /** @format int32 */
  approvalCount: number;
  /** @format int32 */
  approvalsCompleted: number;
  approvedBy?: TupleOfStringAndDateTimeDto[];
  /** @format date-time */
  checkDate?: string;
  confirmationNumber?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  eligibleApprovers?: string[];
  /** @format int64 */
  endCheckNumber?: number;
  enteredBy?: string;
  /** @format date-time */
  expireDate?: string;
  /** @format int32 */
  id: number;
  payee?: string;
  remarks?: string;
  /** @format int64 */
  startCheckNumber: number;
  stopPaymentAudits?: StopPaymentAudit2Dto[];
  stopPaymentStatusType?: StopPaymentStatusType2Dto;
  /** @format int32 */
  stopPaymentStatusTypeId: number;
  transactionId?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  userPermissions?: string[];
};
export type StopPaymentAudit2Dto = BaseEntity2Dto & {
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  remarks?: string;
  stopPayment?: StopPayment2Dto;
  stopPaymentAuditAction?: StopPaymentAuditAction2Dto;
  /** @format int32 */
  stopPaymentAuditActionId: number;
  /** @format int32 */
  stopPaymentId: number;
};
export type StopPaymentAuditAction2Dto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
  stopPaymentAudits?: StopPaymentAudit2Dto[];
};
export interface StopPaymentStatusType2Dto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export type IssuedItemDto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  accountNumber?: string;
  accountType?: string;
  actIntentKey?: string;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  batchNumber?: number;
  checkNumber?: string;
  checkType?: CheckTypeDto;
  /** @format int32 */
  checkTypeId: number;
  confirmations?: IssuedItemConfirmationDto[];
  /** @format int32 */
  createdBy: number;
  createdByUser?: User2Dto;
  /** @format date-time */
  createdDate: string;
  errorSummary?: ErrorSummary2Dto;
  /** @format int32 */
  id: number;
  /** @format date-time */
  issuedDate?: string;
  issuedItemAudits?: IssuedItemAuditDto[];
  /** @format guid */
  issuedItemBatchId?: string;
  /** @format date-time */
  issuedItemCreateDate?: string;
  issuedItemEntryType?: IssuedItemEntryTypeDto;
  /** @format int32 */
  issuedItemEntryTypeId: number;
  /** @format int32 */
  issuedItemFileId?: number;
  issuedItemStatusType?: IssuedItemStatusTypeDto;
  /** @format int32 */
  issuedItemStatusTypeId?: number;
  payee?: string;
  referenceNumber?: string;
  /** @format int64 */
  sequenceNumber?: number;
  status?: string;
  /** @format date-time */
  voidDate?: string;
  voidStatus: boolean;
};
export interface CheckTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  issuedItems?: IssuedItemDto[];
  name?: string;
}
export type IssuedItemConfirmationDto = BaseEntity2Dto & {
  confirmationNumber?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  issuedItemId: number;
};
export type IssuedItemAuditDto = BaseEntity2Dto & {
  comments?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  id: number;
  issuedItem?: IssuedItemDto;
  issuedItemActionType?: IssuedItemActionTypeDto;
  /** @format int32 */
  issuedItemActionTypeId: number;
  /** @format int32 */
  issuedItemId: number;
  user?: User2Dto;
};
export type IssuedItemActionTypeDto = BaseEntity2Dto & {
  description?: string;
  /** @format int32 */
  id: number;
  issuedItemAudits?: IssuedItemAuditDto[];
  name?: string;
};
export interface IssuedItemEntryTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface IssuedItemStatusTypeDto {
  description?: string;
  /** @format int32 */
  id: number;
  isActive: boolean;
  name?: string;
}
export type AchExceptionDetailDto = BaseEntity2Dto & {
  /** @format int32 */
  accountId: number;
  accountNumber?: string;
  accountType?: string;
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format int32 */
  achExceptionDecisionStatusId: number;
  /** @format decimal */
  amount: number;
  batchNumber?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  decisionBy?: string;
  /** @format date-time */
  decisionDate?: string;
  entryDescription?: string;
  exceptionItemId?: string;
  exceptionReason?: string;
  exceptionType?: string;
  fromWorkStation?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionId: number;
  /** @format date-time */
  postedDate?: string;
  secCode?: string;
  sourceOfEntry?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
};
export type AchPaymentDraftDto = BaseEntity2Dto & {
  accepted: boolean;
  account?: Account2Dto;
  achCompany?: AchCompany2Dto;
  /** @format int32 */
  achCompanyId?: number;
  achCompanyName?: string;
  achFile?: AchFileDto;
  /** @format int32 */
  achFileId: number;
  achPayment?: AchPayment2Dto;
  achPaymentDraftRecipients?: AchPaymentDraftRecipientDto[];
  /** @format int32 */
  achPaymentId?: number;
  allowed: boolean;
  balanced: boolean;
  batchName?: string;
  companyIdentity?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  descretionaryData?: string;
  /** @format date-time */
  effectiveDate?: string;
  entryDescription?: string;
  errorSummary?: ErrorSummary2Dto;
  /** @format int32 */
  id: number;
  /** @format int32 */
  offsetAccountId?: number;
  /** @format decimal */
  onUsAmount?: number;
  /** @format int32 */
  onUsCount: number;
  secCode?: string;
  /** @format decimal */
  totalCredit?: number;
  /** @format decimal */
  totalDebit?: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
  valid: boolean;
  validationError?: string;
};
export interface AchFileDto {
  achCompanyName?: string;
  achFileSourceType?: AchFileSourceTypeDto;
  /** @format int32 */
  achFileSourceTypeId: number;
  achPaymentDrafts?: AchPaymentDraftDto[];
  /** @format int32 */
  achPaymentRecipientsCount: number;
  /** @format int32 */
  approvedCount: number;
  /** @format int32 */
  batchCount?: number;
  /** @format int32 */
  batchUniqueId: number;
  comment?: string;
  /** @format int32 */
  companyId?: number;
  createdBy?: string;
  createdByUser?: User2Dto;
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  debitAmount?: number;
  deleted: boolean;
  /** @format int32 */
  expiredCount: number;
  fileArchive?: FileArchive2Dto;
  /** @format int32 */
  fileArchiveId: number;
  fileName?: string;
  fileSize?: string;
  hasAccess: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  pendingApprovalCount: number;
  /** @format int32 */
  rejectedCount: number;
  source?: string;
  status?: string;
  /** @format int32 */
  totalBatches: number;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: User2Dto;
  /** @format date-time */
  updatedDate?: string;
  /** @format date-time */
  uploadDate?: string;
  validationError?: string;
}
export interface AchFileSourceTypeDto {
  achFiles?: AchFileDto[];
  description?: string;
  /** @format int32 */
  id: number;
  name?: string;
}
export interface AchPaymentDraftRecipientDto {
  accountNumber?: string;
  /** @format int32 */
  achPaymentDraftId: number;
  /** @format int32 */
  id: number;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
}
export type AchExceptionDto = BaseEntity2Dto & {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format int32 */
  achDecisionId?: number;
  /** @format decimal */
  amount: number;
  batchNumber?: string;
  createdBy?: string;
  createdByUserId?: string;
  /** @format date-time */
  createdDate: string;
  decision?: string;
  decisionBy?: string;
  /** @format date-time */
  decisionDate?: string;
  description?: string;
  excActnCode?: string;
  exceptionType?: string;
  fromWorkStation?: string;
  /** @format int32 */
  id: number;
  issuingCompanyId?: string;
  issuingCompanyName?: string;
  itemId?: string;
  pay?: boolean;
  /** @format date-time */
  postedDate: string;
  return: boolean;
  secCode?: string;
};
export interface ArpProduct2Dto {
  allowIssuedItemActivityReview: boolean;
  fiAllowSubstituteProduct: boolean;
  fiManageAchExceptions: boolean;
  fiShowAchExceptions: boolean;
  fiWorkAchException: boolean;
  fiWorkCheckException: boolean;
  /** @format int32 */
  id: number;
  manageAchExceptions: boolean;
  product?: Product2Dto;
  showAchExceptions: boolean;
  substituteProductClientId?: string;
  substituteProductLandingPageUrl?: string;
  substituteProductName?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  useSubstituteProduct: boolean;
  workAchException: boolean;
  workAchFilter: boolean;
  workCheckException: boolean;
}
export interface BillPayProduct2Dto {
  account?: Account2Dto;
  /** @format int32 */
  accountId?: number;
  /** @format guid */
  accountUniqueId?: string;
  allowIPayBusinessBillPayForMobile: boolean;
  billPayServiceName?: string;
  /** @format int32 */
  id: number;
  product?: Product2Dto;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface ElectronicDocumentsProduct2Dto {
  esiEmail?: string;
  esiId?: string;
  electronicDocumentServiceName?: string;
  /** @format int32 */
  electronicDocumentTypeId?: number;
  /** @format int32 */
  id: number;
  /** @format double */
  monthlyFee: number;
  product?: Product2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface RemoteDepositProduct2Dto {
  companySsoLogin?: CompanySsoLogin2Dto;
  /** @format int32 */
  companySsoLoginId?: number;
  /** @format int32 */
  id: number;
  /** @format decimal */
  monthlyFee?: number;
  remoteDepositProductType?: RemoteDepositProductType2Dto;
  /** @format int32 */
  remoteDepositProductTypeId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface CompanySsoLogin2Dto {
  clientCompanyId?: string;
  company?: Company2Dto;
  /** @format int32 */
  companyId: number;
  /** @format int32 */
  id: number;
  ssoLoginSystem?: SsoLoginSystem2Dto;
  /** @format int32 */
  ssoLoginSystemId: number;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface RemoteDepositProductType2Dto {
  description?: string;
  name?: string;
  /** @format int32 */
  remoteDepositProductTypeId: number;
}
export interface ReportingProduct2Dto {
  /** @format int32 */
  id: number;
  product?: Product2Dto;
}
export interface StopPaymentProduct2Dto {
  approvalCount: number;
  /** @format double */
  feePerRequest: number;
  /** @format int32 */
  id: number;
  product?: Product2Dto;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
}
export interface ArpReportFile2Dto {
  account?: Account2Dto;
  /** @format int32 */
  accountId: number;
  /** @format date-time */
  createdDate: string;
  fileContent?: string;
  fileName?: string;
  /** @format int32 */
  id: number;
}
export interface CoreAccount2Dto {
  cifNumber?: string;
  coreStatus?: string;
  number?: string;
  type?: string;
}
export interface ArpExceptionSearchModelDto {
  accounts?: Account2Dto[];
  checkNumber?: string;
  decisionTaken?: string;
  /** @format decimal */
  issuedAmount?: number;
  /** @format decimal */
  issuedAmountEnd?: number;
  /** @format decimal */
  issuedAmountStart?: number;
  issuedAmountType?: string;
  /** @format date-time */
  issuedDate?: string;
  /** @format date-time */
  issuedDateEnd?: string;
  issuedDateRangeType?: string;
  /** @format date-time */
  issuedDateStart?: string;
  /** @format decimal */
  paidAmount?: number;
  /** @format decimal */
  paidAmountEnd?: number;
  /** @format decimal */
  paidAmountStart?: number;
  paidAmountType?: string;
  payee?: string;
  /** @format date-time */
  postedDate?: string;
  /** @format date-time */
  postedDateEnd?: string;
  postedDateRangeType?: string;
  /** @format date-time */
  postedDateStart?: string;
  type?: string;
}
export type SearchArpExceptionsRequestDto = BaseRequest3Dto & {
  account?: Account2Dto;
  arpExceptionSearchModel?: ArpExceptionSearchModelDto;
};
export type SaveAllArpExceptionsRequestDto = BaseRequest3Dto & {
  arpExceptionDetails?: ArpExceptionDetailDto[];
};
export interface ArpExceptionDetailDto {
  /** @format int32 */
  accountId: number;
  accountNumber?: string;
  accountType?: string;
  arpExceptionDetailDecisions?: ArpExceptionDetailDecisionDto[];
  /** @format int32 */
  arpExceptionDetailId: number;
  checkImageNumber?: string;
  checkNumber?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  exceptionsReason?: string;
  fiId?: string;
  fromWorkStation?: string;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  issuedDate?: string;
  issuedPayee?: string;
  /** @format decimal */
  paidAmount: number;
  posPayItemId?: string;
  /** @format date-time */
  postDate?: string;
  protected?: string;
  resolved?: string;
  sourceOfEntry?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface ArpExceptionDetailDecisionDto {
  /** @format int32 */
  arpExceptionDetailDecisionId: number;
  /** @format int32 */
  arpExceptionDetailId: number;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  decisionBy?: string;
  /** @format date-time */
  decisionDate: string;
  decisionStatus?: string;
  remarks?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export type CheckExceptionDto = ArpExceptionDetailDto & {
  /** @format guid */
  institutionCheckExceptionReturnReasonUniqueId?: string;
};
export type GetAchExceptionsRequestDto = BaseRequest3Dto & {
  accounts?: Account2Dto[];
  /** @format decimal */
  amount?: number;
  /** @format decimal */
  amountFrom?: number;
  /** @format decimal */
  amountTo?: number;
  amountType?: string;
  issuingCompany?: string;
  secCodes?: string[];
};
export type FindAchExceptionsRequestDto = BaseRequest3Dto & {
  achExceptionSearchModel?: AchExceptionSearchModelDto;
  downloadOutputType?: string;
};
export interface AchExceptionSearchModelDto {
  accounts?: Account2Dto[];
  achCompanies?: AchCompany2Dto[];
  achExceptionDecisionStatus?: string;
  /** @format decimal */
  amount?: number;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amountStart?: number;
  amountType?: string;
  entryDescription?: string;
  /** @format date-time */
  postedDate?: string;
  /** @format date-time */
  postedDateEnd?: string;
  postedDateRangeType?: string;
  /** @format date-time */
  postedDateStart?: string;
  secCodes?: AchSecCode2Dto[];
  type?: string;
}
export type FindAchExceptionsDecisionsActivityRequestDto = BaseRequest3Dto & {
  achExceptionSearchModel?: AchExceptionSearchModelDto;
  /** @format date-time */
  currentBusinessDayCutoff: string;
  downloadOutputType?: string;
  pagingParameters?: PagingParameters2Dto;
  /** @format date-time */
  previousBusinessDayCutoff: string;
  searchAccountNickname: boolean;
  searchText?: string;
};
export interface PagingParameters2Dto {
  orderBys?: OrderBy2Dto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
}
export interface OrderBy2Dto {
  descending: boolean;
  name?: string;
}
export type GetInstitutionPosPayAchExceptionsCutOffTimesRequestDto = BaseRequest3Dto & object;
export type UpdateAchExceptionsRequestDto = BaseRequest3Dto & {
  achExceptions?: AchExceptionDto[];
  activityIntentKey?: string;
  exceptionItemId?: string;
  institutionAchReturnCode?: string;
  pay: boolean;
  return: boolean;
};
export type SearchArpOutputFilesRequestDto = BaseRequest3Dto & {
  accounts?: Account2Dto[];
  fileName?: string;
  /** @format date-time */
  fileReceivedDate?: string;
  /** @format date-time */
  fileReceivedDateEnd?: string;
  /** @format date-time */
  fileReceivedDateStart?: string;
  fileReceivedDateType?: string;
  outPutType: OutPutType2Dto;
};
export enum OutPutType2Dto {
  Entity = 0,
  Pdf = 1,
  Csv = 2,
  Tiff = 3,
  Bai = 4,
  Quicken = 5,
  QuickBook = 6,
  Nacha = 7,
}
export type GetAchExceptionFiltersRequestDto = BaseRequest3Dto & {
  accountNumber?: string;
  accountType?: string;
  accounts?: Account2Dto[];
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format decimal */
  amount?: number;
  amountType?: string;
  exceptionFilter?: string;
  /** @format date-time */
  expirationDate?: string;
  /** @format date-time */
  expirationDateEnd?: string;
  /** @format date-time */
  expirationDateStart?: string;
  expirationDateType?: string;
  /** @format decimal */
  fromAmount?: number;
  searchAccount?: Account2Dto;
  secCodes?: AchSecCode2Dto[];
  /** @format decimal */
  toAmount?: number;
};
export type ParseArpIssuedItemsRequestDto = BaseRequest3Dto & {
  arpData?: string;
  /** @format int32 */
  fileImportLayoutId: number;
  fileName?: string;
};
export type SaveArpIssuedItemsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  accountId: number;
  issuedFileName?: string;
  issuedItems?: IssuedItemDto[];
  voidedIssuedItems?: IssuedItemDto[];
};
export type GetCompanySecCodesRequestDto = BaseRequest3Dto & object;
export type EndOfEodProcessRequestDto = BaseRequest3Dto & {
  /** @format date-time */
  eodTime: string;
  routingNumber?: string;
};
export type StartOfEodProcessRequestDto = BaseRequest3Dto & object;
export type GetArpUploadFormatsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  arpUploadFormatId?: number;
};
export type DeleteArpUploadFormatRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  arpUploadFormatId: number;
};
export type SaveArpFormatRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  accountNumberBegin?: number;
  /** @format int32 */
  accountNumberEnd?: number;
  /** @format int32 */
  accountNumberOrder?: number;
  /** @format int32 */
  accountTypeBegin?: number;
  accountTypeCheckingEquals?: string;
  /** @format int32 */
  accountTypeEnd?: number;
  /** @format int32 */
  accountTypeOrder?: number;
  accountTypeSavingsEquals?: string;
  fieldDelimiter?: string;
  /** @format int32 */
  fileImportLayoutId: number;
  /** @format int32 */
  footerRows?: number;
  /** @format int32 */
  headerRows?: number;
  /** @format int32 */
  issueDateBegin?: number;
  /** @format int32 */
  issueDateEnd?: number;
  issueDateFormat?: string;
  /** @format int32 */
  issueDateOrder?: number;
  /** @format int32 */
  itemAmountBegin?: number;
  itemAmountDecimal: boolean;
  /** @format int32 */
  itemAmountEnd?: number;
  itemAmountFormat?: string;
  /** @format int32 */
  itemAmountOrder?: number;
  /** @format int32 */
  itemNumberBegin?: number;
  /** @format int32 */
  itemNumberEnd?: number;
  /** @format int32 */
  itemNumberOrder?: number;
  name?: string;
  /** @format int32 */
  payeeBegin?: number;
  /** @format int32 */
  payeeEnd?: number;
  /** @format int32 */
  payeeOrder?: number;
  textQualifier?: string;
  type?: string;
  /** @format int32 */
  voidDateBegin?: number;
  /** @format int32 */
  voidDateEnd?: number;
  voidDateFormat?: string;
  /** @format int32 */
  voidDateOrder?: number;
  /** @format int32 */
  voidIndicatorBegin?: number;
  /** @format int32 */
  voidIndicatorEnd?: number;
  /** @format int32 */
  voidIndicatorOrder?: number;
  voidYesIndicator?: string;
};
export type UpdateArpExceptionsRequestDto = BaseRequest3Dto & {
  activityIntentKey?: string;
  arpExceptions?: ArpException2Dto[];
  exceptionItemId?: string;
  pay: boolean;
  return: boolean;
};
export type CreateCheckExceptionNotificationRequestDto = BaseRequest3Dto & {
  /** @format date-time */
  previousBusinessDate: string;
};
export type CreateAchExceptionNotificationRequestDto = BaseRequest3Dto & {
  /** @format date-time */
  previousBusinessDate: string;
};
export type RunExceptionEtlRequestDto = BaseRequest3Dto & {
  fiId?: string;
};
export type SaveAchExceptionsRequestDto = BaseRequest3Dto & {
  achExceptions?: AchExceptionDetailDto[];
};
export type TreasuryChallengeRequestDto = BaseRequest3Dto & {
  clientStatus?: string;
  oneTimePassword?: string;
};
export type CreateAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  achPayment?: AchPayment2Dto;
  achPayments?: AchPayment2Dto[];
  isUpdateAchBatch: boolean;
};
export type CreateInternationalPaymentRequestDto = CreateAchPaymentRequestDto & {
  achInternationalPayment?: InternationalPaymentDto;
  achInternationalPayments?: InternationalPaymentCollectionDto;
};
export type InternationalPaymentCollectionDto = InternationalPaymentDto[];
export type CreateChildSupportPaymentRequestDto = TreasuryChallengeRequestDto & {
  achPayment?: ChildSupportPaymentDto;
  achPayments?: ChildSupportPaymentCollectionDto;
  isUpdateAchBatch: boolean;
};
export type ChildSupportPaymentCollectionDto = ChildSupportPaymentDto[];
export type DeleteAchBatchRequestDto = TreasuryChallengeRequestDto & {
  /** @format int32 */
  achBatchId: number;
  comment?: string;
};
export type CancelAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  /** @format int32 */
  achPaymentId: number;
  comment?: string;
};
export type UninitiateAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  /** @format int32 */
  achPaymentId: number;
  comment?: string;
};
export type RejectAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  achPayments?: AchPayment2Dto[];
  lookups?: Lookup2Dto[];
};
export type RejectAchBatchRequestDto = TreasuryChallengeRequestDto & {
  /** @format int32 */
  achBatchId: number;
  comment?: string;
};
export type ApproveAchBatchRequestDto = TreasuryChallengeRequestDto & {
  /** @format int32 */
  achBatchId: number;
  comment?: string;
  isApprovalNotRequired: boolean;
};
export type ApproveAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  achPayments?: AchPayment2Dto[];
  isApproversNotRequired: boolean;
  lookups?: Lookup2Dto[];
};
export type UpdateAchPaymentRequestDto = TreasuryChallengeRequestDto & {
  achPayment?: AchPayment2Dto;
  /** @format int32 */
  achPaymentId: number;
};
export type UpdateChildSupportPaymentRequestDto = UpdateAchPaymentRequestDto & {
  childSupportPayment?: ChildSupportPaymentDto;
};
export type UpdateInternationalPaymentRequestDto = UpdateAchPaymentRequestDto & {
  internationalPayment?: InternationalPaymentDto;
};
export type CreateAchBatchRequestDto = TreasuryChallengeRequestDto & {
  achBatch?: AchBatch2Dto;
};
export type UpdateAchBatchRequestDto = TreasuryChallengeRequestDto & {
  achBatch?: AchBatch2Dto;
  /** @format int32 */
  achBatchId: number;
};
export type GetInternationalAchCountriesRequestDto = BaseRequest3Dto & object;
export type GetInternationalAchPaymentRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  internationalAchPaymentId: number;
};
export type CreateNachaFileForDownloadRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achBatchId: number;
  /** @format int32 */
  achPaymentId: number;
  outPutType: OutPutType2Dto;
};
export type GetAchPaymentRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achPaymentId: number;
};
export type GetAchPaymentDraftsRequestDto = BaseRequest3Dto & {
  achPaymentDraftIds?: number[];
};
export type GetAchBatchRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achBatchId: number;
  outPutType: OutPutType2Dto;
};
export type GetAchPaymentRecipientsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achPaymentId: number;
};
export type GetAchBatchRecipientsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achBatchId: number;
};
export type GetDomesticBankRequestDto = BaseRequest3Dto & object;
export type SearchAchPaymentRequestDto = BaseRequest3Dto & {
  achTransactionId?: string;
  achType?: string;
  batchName?: string;
  companyList?: AchCompany2Dto[];
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  creditAmountMax?: number;
  /** @format decimal */
  creditAmountMin?: number;
  /** @format decimal */
  debitAmount?: number;
  /** @format decimal */
  debitAmountMax?: number;
  /** @format decimal */
  debitAmountMin?: number;
  /** @format date-time */
  effectiveDate?: string;
  /** @format date-time */
  effectiveDateEnd?: string;
  /** @format date-time */
  effectiveDateStart?: string;
  fileArchiveIds?: number[];
  frequencyTypeIds?: number[];
  /** @format date-time */
  initiatedDate?: string;
  /** @format date-time */
  initiatedDateEnd?: string;
  /** @format date-time */
  initiatedDateStart?: string;
  isBasic: boolean;
  isDownload: boolean;
  isRecurring?: boolean;
  outPutType: OutPutType2Dto;
  paymentIds?: number[];
  secCode?: string;
  status?: string;
};
export type ValidateAchBatchNameRequestDto = BaseRequest3Dto & {
  achBatch?: AchBatch2Dto;
};
export type GetAchRecipientAddendaRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achBatchRecipientId: number;
  /** @format int32 */
  achPaymentRecipientId: number;
};
export type GetAchFileImportLayoutRequestDto = BaseRequest3Dto & {
  product: ProductTypesEnumDto;
  productType?: string;
};
export enum ProductTypesEnumDto {
  Undefined = 0,
  InternalTransfer = 1,
  InformationReporting = 2,
  WireTransfer = 3,
  WireTransferDLI = 4,
  Ach = 5,
  Arp = 6,
  StopPayment = 7,
  ElectronicDocuments = 8,
  BillPay = 9,
  RemoteDeposits = 10,
  WausauStatements = 11,
  AccountRecon = 12,
  BdiEStatements = 13,
  AgileticsSso = 14,
  DerivativePath = 15,
  IntegratedServices = -1,
}
export type SaveAchFileImportLayoutRequestDto = BaseRequest3Dto & {
  achFileImportLayout?: FileImportLayout2Dto;
  formatType: FileFormatTypeEnumDto;
  importField: AchFileImportFieldEnumDto;
};
export enum FileFormatTypeEnumDto {
  Undefined = 0,
  Delimited = 1,
  FixedPosition = 2,
}
export enum AchFileImportFieldEnumDto {
  Undefined = 0,
  Name = 1,
  IDNumber = 2,
  RoutingNumber = 3,
  AccountNumber = 4,
  Amount = 5,
  TransactionCode = 6,
  AccountType = 7,
  TransactionType = 8,
}
export type SaveAchRecipientAddendaRequestDto = BaseRequest3Dto & {
  achBatchRecipientAddenda?: AchBatchRecipientAddenda2Dto;
  /** @format int32 */
  achBatchRecipientId: number;
  achPaymentRecipientAddenda?: AchPaymentRecipientAddenda2Dto;
  /** @format int32 */
  achPaymentRecipientId: number;
  recipientAddendaActionType: AchRecipientAddendaActionTypeDto;
};
export enum AchRecipientAddendaActionTypeDto {
  Undefined = 0,
  AddAchPaymentRecipientAddenda = 1,
  UpdateAchPaymentRecipientAddenda = 2,
  AddAchBatchRecipientAddenda = 3,
  UpdateAchBatchRecipientAddenda = 4,
}
export type DownloadHtmlRequestDto = BaseRequest3Dto & {
  downloadType?: string;
};
export type NachaFileUploadRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achFileId: number;
  achPaymentType: AchPaymentTypeEnumDto;
  isAchFileCreated: boolean;
  nachaFileData?: string;
  nachaFileName?: string;
  uploadType: UploadTypeEnumDto;
};
export enum AchPaymentTypeEnumDto {
  AchPayment = 1,
  AchStateTaxPayment = 2,
  AchFederalTaxPayment = 3,
  AchChildSupportPayment = 4,
  AchInternationalPayment = 5,
}
export enum UploadTypeEnumDto {
  Undefined = 0,
  Batch = 1,
  Payment = 2,
}
export type RecipientFileUploadRequestDto = BaseRequest3Dto & {
  recipientFileData?: string;
  recipientFileName?: string;
  uploadFormat?: string;
};
export type ChangeInAchCompanyRequestDto = BaseRequest3Dto & object;
export type ChangeInAchPaymentStatusRequestDto = BaseRequest3Dto & {
  achPaymentReferenceNumber?: string;
  previousStatus?: string;
  status?: string;
};
export type VerifyRoutingNumberRequestDto = BaseRequest3Dto & {
  routingNumber?: string;
};
export type GetOffsetAccountRequestDto = BaseRequest3Dto & object;
export type SearchAchRecipientRequestDto = BaseRequest3Dto & {
  achRecipientFilter?: AchRecipientFilterDto;
};
export interface AchRecipientFilterDto {
  /** @format decimal */
  amount: number;
  /** @format decimal */
  amountMax: number;
  /** @format decimal */
  amountMin: number;
  amountType?: string;
  hold: boolean;
  prenote: boolean;
  recipientName?: string;
  recordType?: string;
  reversal: boolean;
}
export type SubmitScheduledAchPaymentRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achPaymentId: number;
  /** @format int32 */
  companyId: number;
  institutionFiId?: string;
  /** @format int64 */
  jobId: number;
  /** @format date-time */
  nextPaymentDate?: string;
  /** @format int32 */
  userId: number;
};
export type AchTransactionSearchRequestDto = BaseRequest3Dto & {
  accounts?: Account2Dto[];
  amountType: AmountTypeEnumDto;
  /** @format decimal */
  endAmount: number;
  /** @format date-time */
  endDate: string;
  searchAccount?: Account2Dto;
  /** @format decimal */
  startAmount: number;
  /** @format date-time */
  startDate: string;
  transactionType: TransactionTypeEnumDto;
};
export enum AmountTypeEnumDto {
  Debit = 0,
  Credit = 1,
  Both = 2,
}
export enum TransactionTypeEnumDto {
  Posted = 0,
  Pending = 1,
}
export type AchAddendaSearchRequestDto = BaseRequest3Dto & {
  searchAccount?: Account2Dto;
  transactionId?: string;
};
export type InquiryAchCompanyRequestDto = BaseRequest3Dto & {
  companyName?: string;
  coreSecCode?: string;
};
export type SearchAchFileActivityRequestDto = BaseRequest3Dto & {
  companyIds?: number[];
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  fileArchiveId?: number;
  fileName?: string;
  isDownload: boolean;
  processedByUserIds?: number[];
  processedDateType?: string;
  /** @format date-time */
  processedEndDate?: string;
  /** @format date-time */
  processedStartDate?: string;
  /** @format date-time */
  startDate?: string;
  uploadDateType?: string;
  userIds?: number[];
};
export type AchFileDeleteRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achFileId: number;
  activityIntentKey?: string;
  fileReferenceId?: string;
};
export type GetAchFileRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achFileArchiveId: number;
};
export type SaveTransmittedAchFilesRequestDto = BaseRequest3Dto & object;
export type GetChildSupportPaymentRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  childSupportPaymentId: number;
};
export type UploadInternationalNachaFileRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achFileId: number;
  achPaymentType: AchPaymentTypeEnumDto;
  nachaFileData?: string;
  nachaFileName?: string;
  uploadType: UploadTypeEnumDto;
};
export type GetStateTaxAmountTypesRequestDto = BaseRequest3Dto & object;
export type GetFederalTaxAmountTypesRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  federalTaxCodeId: number;
};
export type GetFederalTaxCodesRequestDto = BaseRequest3Dto & object;
export type GetFederalTaxRecipientRequestDto = BaseRequest3Dto & object;
export type GetStateTaxRecipientsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  stateId: number;
};
export type GetStateTaxCodesRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  stateId: number;
};
export type GetTaxRecipientAccountTypesRequestDto = BaseRequest3Dto & object;
export type SearchAchBatchRequestDto = BaseRequest3Dto & {
  achBatchIds?: number[];
  batchName?: string;
  companyList?: AchCompany2Dto[];
  /** @format decimal */
  creditAmount?: number;
  /** @format decimal */
  creditAmountMax?: number;
  /** @format decimal */
  creditAmountMin?: number;
  creditAmountType?: string;
  /** @format decimal */
  debitAmount?: number;
  /** @format decimal */
  debitAmountMax?: number;
  /** @format decimal */
  debitAmountMin?: number;
  debitAmountType?: string;
  /** @format date-time */
  lastUpdatedDate?: string;
  /** @format date-time */
  lastUpdatedDateEnd?: string;
  /** @format date-time */
  lastUpdatedDateStart?: string;
  lastUpdatedDateType?: string;
  outPutType: OutPutType2Dto;
  secCode?: string;
  status?: string;
  taxTemplateType: TaxTemplateSearchTypeDto;
};
export enum TaxTemplateSearchTypeDto {
  Undefined = 0,
  Federal = 1,
  State = 2,
  Both = 3,
}
export type GetMasterRecipientsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achCompanyId?: number;
  /** @format byte */
  achMasterRecipientStatusTypeId: number;
  amountFilter?: AmountFilterDto;
  downloadType?: string;
  forDownload: boolean;
  pagingParameters?: PagingParameters2Dto;
  recipientName?: string;
  searchText?: string;
};
export interface AmountFilterDto {
  /** @format decimal */
  amount: number;
  amountType: AmountFilterTypeEnumDto;
  /** @format decimal */
  maximumAmount: number;
  /** @format decimal */
  minimumAmount: number;
}
export enum AmountFilterTypeEnumDto {
  Undefined = 0,
  Specific = 1,
  Range = 2,
}
export type SaveMasterRecipientRequestDto = BaseRequest3Dto & {
  saveableMasterRecipients?: SaveableMasterRecipientDto[];
};
export interface SaveableMasterRecipientDto {
  accountNumber?: string;
  /** @format int32 */
  accountTypeId: number;
  addenda?: string;
  /** @format decimal */
  defaultAmount: number;
  /** @format int32 */
  id: number;
  recipientIdNumber?: string;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  transactionTypeId: number;
}
export type ApproveAchMasterRecipientRequestDto = BaseRequest3Dto & {
  achMasterRecipientIds?: number[];
};
export type RejectAchMasterRecipientRequestDto = BaseRequest3Dto & {
  achMasterRecipientIds?: number[];
};
export type DeleteAchMasterRecipientRequestDto = BaseRequest3Dto & {
  achMasterRecipientIds?: number[];
};
export type GetMasterRecipientAuditsRequestDto = BaseRequest3Dto & {
  /** @format int32 */
  achMasterRecipientId: number;
};
export type SearchIssuedItemsRequestDto = BaseRequest3Dto & {
  accountIds?: number[];
  /** @format decimal */
  amountRangeEnd?: number;
  /** @format decimal */
  amountRangeStart?: number;
  checkNumber?: string;
  checkType?: CheckTypeEnumDto;
  /** @format date-time */
  createdDateEnd: string;
  /** @format date-time */
  createdDateStart: string;
  entryType?: IssuedItemEntryTypeEnumDto;
  /** @format date-time */
  issuedDateEnd?: string;
  /** @format date-time */
  issuedDateStart?: string;
  outputType: OutPutType2Dto;
  payee?: string;
  statusIds?: number[];
};
export enum CheckTypeEnumDto {
  Undefined = 0,
  Check = 2,
  Void = 3,
}
export enum IssuedItemEntryTypeEnumDto {
  Undefined = 0,
  Manual = 1,
  Upload = 2,
}
export type GetIssuedItemStatusesRequestDto = BaseRequest3Dto & object;
export type UpdateIssuedItemStatusRequestDto = BaseRequest3Dto & {
  batchDetail?: UpdateIssuedItemStatusBatchDto;
  /** @format date-time */
  previousProcessingDate?: string;
  /** @format duration */
  retentionPeriod: string;
};
export interface UpdateIssuedItemStatusBatchDto {
  /** @format int32 */
  firstIssuedItemId: number;
  /** @format int32 */
  institutionId: number;
  /** @format int32 */
  lastIssuedItemId: number;
}
export type RunUpdateIssuedItemStatusBatchesRequestDto = BaseRequest3Dto & {
  failedBatches?: UpdateIssuedItemStatusBatchDto[];
  /** @format int32 */
  institutionIdToUpdate: number;
  /** @format date-time */
  previousBusinessDay?: string;
};
export type RunUpdateIssuedItemStatusRequestDto = BaseRequest3Dto & object;
export type SearchJxAchExceptionsRequestDto = BaseRequest3Dto & {
  achExceptionSearchModel?: AchExceptionSearchModelDto;
};
export type InquiryJxAchExceptionRequestDto = BaseRequest3Dto & {
  accountNumber?: string;
  accountType?: string;
  actionIntent?: string;
  itemId?: string;
};
export type ResponseDetailCollection3Dto = ResponseDetail3Dto[];
export interface ResponseDetail3Dto {
  /** @format int32 */
  responseCode: number;
  responseMessage?: string;
}
export type HealthCheckActionResponse2Dto = BaseResponse3Dto & {
  databaseConnectivityStatus?: DatabaseConnectivityStatusCollection2Dto;
  getDatabaseConnectivitySuccessful: boolean;
  getPackageInformationSuccessful: boolean;
  getRedisConnectivitySuccessful: boolean;
  packageInformation?: PackageInformation2Dto;
  redisConnectivityStatus?: RedisConnectivityStatusCollection2Dto;
  redisMultiplexerAbleToConnect: boolean;
};
export type DatabaseConnectivityStatusCollection2Dto = DatabaseConnectivityStatus2Dto[];
export interface DatabaseConnectivityStatus2Dto {
  ableToConnect: boolean;
  additionalInformation?: string;
  connectionStringName?: string;
}
export interface PackageInformation2Dto {
  buildInformation?: BuildInformation2Dto;
  /** @format date-time */
  createdDateTime: string;
  machineName?: string;
  releaseInformation?: ReleaseInformation2Dto;
}
export interface BuildInformation2Dto {
  azdoProject?: string;
  buildUrl?: string;
  commitId?: string;
  packageVersion?: string;
  repository?: string;
}
export interface ReleaseInformation2Dto {
  /** @format date-time */
  deploymentDateUtc: string;
  packageName?: string;
  packageVersion?: string;
  projectId?: string;
  releaseVersion?: string;
}
export type RedisConnectivityStatusCollection2Dto = RedisConnectivityStatus2Dto[];
export interface RedisConnectivityStatus2Dto {
  ableToConnect: boolean;
  additionalInformation?: string;
  ipAddress?: string;
  redisClusterName?: string;
}
export type GetJxArpExceptionsResponseDto = BaseResponse3Dto & {
  arpExceptions?: ArpException2Dto[];
};
export type SearchArpExceptionsResponseDto = BaseResponse3Dto & {
  arpExceptionDetails?: ArpExceptionDetailDataDto[];
  arpExceptions?: ArpException2Dto[];
  hasEtlRanSuccessfully: boolean;
};
export interface ArpExceptionDetailDataDto {
  /** @format int32 */
  accountId: number;
  accountName?: string;
  accountNumber?: string;
  accountType?: string;
  /** @format guid */
  arpExceptionAttachmentFileIdentifier?: string;
  arpExceptionDetail?: ArpExceptionDetailDto;
  /** @format int32 */
  arpExceptionDetailId: number;
  checkImageNumber?: string;
  checkNumber?: string;
  /** @format int32 */
  companyId: number;
  companyName?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  decisionHistory: boolean;
  decisionStatus?: string;
  decisions?: ArpExceptionDetailDecisionDto[];
  exceptionsReason?: string;
  fiId?: string;
  fromWorkStation?: string;
  institutionCheckExceptionReturnReasonDescription?: string;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  issuedDate?: string;
  issuedPayee?: string;
  /** @format date-time */
  lastDecisionDate?: string;
  lastDecisionTakenBy?: string;
  /** @format decimal */
  paidAmount?: number;
  posPayItemId?: string;
  /** @format date-time */
  postDate?: string;
  protected?: string;
  returnReasonComment?: string;
  sourceOfEntry?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export type SaveAllArpExceptionsResponseDto = BaseResponse3Dto & object;
export type GetAchExceptionFiltersResponseDto = BaseResponse3Dto & {
  achExceptionFilters?: AchExceptionFilterDto[];
  allowPositivePay: boolean;
};
export interface AchExceptionFilterDto {
  accountNumber?: string;
  accountType?: string;
  achCompanyName?: string;
  allowAllCredits?: string;
  allowAllDebits?: string;
  /** @format decimal */
  amountFrom: number;
  /** @format decimal */
  amountTo: number;
  companyId?: string;
  debitOrCredit?: string;
  exceptionFilter?: string;
  /** @format date-time */
  expirationDate: string;
  secCode?: string;
}
export type GetAchExceptionsResponseDto = BaseResponse3Dto & {
  achExceptions?: AchExceptionDto[];
  protected?: string;
};
export type FindAchExceptionsResponseDto = BaseResponse3Dto & {
  achExceptionDetails?: AchExceptionDetailDataDto[];
  posPayExceptionEtlFiRuntimeStats?: PosPayExceptionEtlFiRuntimeStatsDto;
};
export interface AchExceptionDetailDataDto {
  /** @format int32 */
  accountId: number;
  accountName?: string;
  accountNumber?: string;
  accountType?: string;
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format int32 */
  achExceptionDecisionStatusId: number;
  achExceptionDetail?: AchExceptionDetailDto;
  /** @format int32 */
  achExceptionDetailId: number;
  /** @format decimal */
  amount?: number;
  auditHistory: boolean;
  batchNumber?: string;
  /** @format int32 */
  companyId: number;
  companyName?: string;
  /** @format int32 */
  createdBy: number;
  /** @format date-time */
  createdDate: string;
  decisionBy?: string;
  /** @format date-time */
  decisionDate?: string;
  decisionStatus?: string;
  entryDescription?: string;
  exceptionItemId?: string;
  exceptionReason?: string;
  exceptionType?: string;
  fiId?: string;
  fromWorkStation?: string;
  /** @format int32 */
  institutionId: number;
  /** @format date-time */
  postedDate?: string;
  secCode?: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updatedDate: string;
}
export interface PosPayExceptionEtlFiRuntimeStatsDto {
  hasEndTimeOccurred: boolean;
  hasEtlRunSuccessfully: boolean;
  hasStartTimeOccurred: boolean;
  isEndOfDayRunning: boolean;
  /** @format duration */
  timeUntilEndTime?: string;
  /** @format duration */
  timeUntilStartTime?: string;
}
export type FindAchExceptionsDecisionsActivityResponseDto = BaseResponse3Dto & {
  pagedAchExceptionDetails?: PagedListOfAchExceptionDetailDataDto;
};
export interface PagedListOfAchExceptionDetailDataDto {
  items?: AchExceptionDetailDataDto[];
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  recordCountInCurrentPage: number;
  /** @format int32 */
  totalCount: number;
}
export type GetInstitutionPosPayAchExceptionsCutOffTimesResponseDto = BaseResponse3Dto & {
  institutionPosPayAchExceptionsCutOffTimes?: InstitutionPosPayAchExceptionsCutOffTimesDto;
};
export interface InstitutionPosPayAchExceptionsCutOffTimesDto {
  /** @format date-time */
  currentBusinessDayCutoff: string;
  /** @format date-time */
  currentBusinessDayStartTime: string;
  /** @format date-time */
  fiLocalTime: string;
  hasEtlRunSuccessfully: boolean;
  institutionName?: string;
  /** @format date-time */
  previousBusinessDayCutoff: string;
}
export type UpdateAchExceptionsResponseDto = BaseResponse3Dto & object;
export type SearchArpOutputFilesResponseDto = BaseResponse3Dto & {
  arpReportFiles?: ArpReportFile2Dto[];
  /** @format byte */
  downloadContent?: string;
};
export type ParseArpIssuedItemsResponseDto = BaseResponse3Dto & {
  arpFileErrors?: FileErrorDto[];
  issuedItems?: IssuedItemDto[];
};
export interface FileErrorDto {
  errorText?: string;
  /** @format int32 */
  lineNumber: number;
}
export type SaveArpIssuedItemsResponseDto = BaseResponse3Dto & {
  errorInfo?: Record<string, string>;
  issuedItems?: IssuedItemDto[];
  voidedIssuedItems?: IssuedItemDto[];
};
export type GetCompanySecCodesResponseDto = BaseResponse3Dto & {
  achCompanySecCodes?: AchCompanySecCode2Dto[];
};
export type EndOfEodProcessResponseDto = BaseResponse3Dto & object;
export type StartOfEodProcessResponseDto = BaseResponse3Dto & object;
export type GetArpUploadFormatsResponseDto = BaseResponse3Dto & {
  arpUploadFormats?: FileImportLayout2Dto[];
};
export type DeleteArpUploadFormatResponseDto = BaseResponse3Dto & object;
export type SaveArpFormatResponseDto = BaseResponse3Dto & object;
export type UpdateArpExceptionsResponseDto = BaseResponse3Dto & object;
export type CreateCheckExceptionNotificationResponseDto = BaseResponse3Dto & object;
export type CreateAchExceptionNotificationResponseDto = BaseResponse3Dto & object;
export type RunExceptionEtlResponseDto = BaseResponse3Dto & object;
export type SaveAchExceptionsResponseDto = BaseResponse3Dto & {
  failedAchExceptions?: AchExceptionDetailDto[];
  /** @format int32 */
  failedDecisionsCount: number;
  /** @format int32 */
  totalDecisionsCount: number;
};
export type TreasuryChallengeResponseDto = BaseResponse3Dto & {
  actionType?: string;
  challengeMethodType?: ChallengeMethodTypeEnum2Dto;
  errorCode?: string;
  hasAlternate: boolean;
  message?: string;
  methodUsed?: string;
  sendMessage?: string;
  status?: string;
};
export enum ChallengeMethodTypeEnum2Dto {
  None = 0,
  OutOfBand = 1,
  SecureToken = 2,
  OutOfBandViaEns = 3,
}
export type CreateAchPaymentResponseDto = TreasuryChallengeResponseDto & {
  achPayment?: AchPayment2Dto;
  achPayments?: AchPayment2Dto[];
  errorSummary?: ErrorSummary2Dto;
  successMessage?: string;
};
export type CreateChildSupportPaymentResponseDto = TreasuryChallengeResponseDto & {
  achPayment?: ChildSupportPaymentDto;
  achPayments?: ChildSupportPaymentCollectionDto;
  errorSummary?: ErrorSummary2Dto;
  successMessage?: string;
};
export type CreateInternationalPaymentResponseDto = TreasuryChallengeResponseDto & {
  achInternationalPayment?: InternationalPaymentDto;
  achInternationalPayments?: InternationalPaymentCollectionDto;
  errorSummary?: ErrorSummary2Dto;
  successMessage?: string;
};
export type RejectAchPaymentResponseDto = TreasuryChallengeResponseDto & {
  achPayments?: AchPayment2Dto[];
};
export type ApproveAchPaymentResponseDto = TreasuryChallengeResponseDto & {
  achPayments?: AchPayment2Dto[];
};
export type UpdateAchPaymentResponseDto = TreasuryChallengeResponseDto & {
  achPayment?: AchPayment2Dto;
  errorSummary?: ErrorSummary2Dto;
  successMessage?: string;
};
export type UpdateChildSupportPaymentResponseDto = TreasuryChallengeResponseDto & {
  childSupportPayment?: ChildSupportPaymentDto;
  errorSummary?: ErrorSummary2Dto;
  successMessage?: string;
};
export type UpdateInternationalPaymentResponseDto = TreasuryChallengeResponseDto & {
  errorSummary?: ErrorSummary2Dto;
  internationalPayment?: InternationalPaymentDto;
  successMessage?: string;
};
export type GetInternationalAchCountriesResponseDto = BaseResponse3Dto & {
  internationalAchCountries?: InternationalAchCountryCollectionDto;
};
export type InternationalAchCountryCollectionDto = InternationalAchCountryDto[];
export interface InternationalAchCountryDto {
  countryCode?: string;
  countryName?: string;
  foreignExchangeIndicators?: ForeignExchangeIndicatorCollection2Dto;
  internationalAchAccountTypes?: InternationalAchAccountTypeDto[];
  isActive: boolean;
  receivingBankCountry?: string;
}
export type ForeignExchangeIndicatorCollection2Dto = ForeignExchangeIndicator2Dto[];
export interface ForeignExchangeIndicator2Dto {
  indicatorCode?: string;
  indicatorName?: string;
  supportedInternationalCurrencies?: InternationalCurrencyCollectionDto;
}
export type InternationalCurrencyCollectionDto = InternationalCurrencyDto[];
export interface InternationalCurrencyDto {
  currencyCode?: string;
  currencyName?: string;
}
export interface InternationalAchAccountTypeDto {
  accountName?: string;
  allowCredit: boolean;
  allowDebit: boolean;
}
export type GetInternationalAchPaymentResponseDto = BaseResponse3Dto & {
  achInternationalPayment?: InternationalPaymentDto;
};
export type CreateNachaFileForDownloadResponseDto = BaseResponse3Dto & {
  /** @format byte */
  nachaFileContents?: string;
};
export type GetAchPaymentResponseDto = BaseResponse3Dto & {
  achPayment?: AchPayment2Dto;
};
export type GetAchPaymentDraftsResponseDto = BaseResponse3Dto & {
  achPayments?: AchPayment2Dto[];
};
export type GetAchBatchResponseDto = BaseResponse3Dto & {
  achBatch?: AchBatch2Dto;
};
export type DeleteAchBatchResponseDto = BaseResponse3Dto & object;
export type GetAchPaymentRecipientsResponseDto = BaseResponse3Dto & {
  achPaymentRecipients?: AchPaymentRecipient2Dto[];
};
export type GetAchBatchRecipientsResponseDto = BaseResponse3Dto & {
  achBatchRecipients?: AchBatchRecipient2Dto[];
};
export type SearchAchPaymentResponseDto = BaseResponse3Dto & {
  achPaymentTotals?: AchPaymentTotalDto[];
  achPayments?: AchPayment2Dto[];
  /** @format byte */
  downloadContent?: string;
  forDownload: boolean;
};
export interface AchPaymentTotalDto {
  achPaymentStatusTypeDescription?: string;
  /** @format int32 */
  achPaymentStatusTypeId: number;
  /** @format int32 */
  count: number;
  /** @format decimal */
  totalCredit: number;
  /** @format decimal */
  totalDebit: number;
}
export type CancelAchPaymentResponseDto = BaseResponse3Dto & object;
export type UninitiateAchPaymentResponseDto = BaseResponse3Dto & object;
export type RejectAchBatchResponseDto = BaseResponse3Dto & object;
export type ApproveAchBatchResponseDto = BaseResponse3Dto & object;
export type ValidateAchBatchNameResponseDto = BaseResponse3Dto & {
  validationMessage?: string;
};
export type GetAchRecipientAddendaResponseDto = BaseResponse3Dto & {
  achBatchRecipientAddendas?: AchBatchRecipientAddenda2Dto[];
  achPaymentRecipientAddendas?: AchPaymentRecipientAddenda2Dto[];
};
export type GetAchFileImportLayoutResponseDto = BaseResponse3Dto & {
  achFileImportLayout?: FileImportLayout2Dto[];
};
export type GetDomesticBankResponseDto = BaseResponse3Dto & {
  domesticBanks?: DomesticBank2Dto[];
};
export type SaveAchFileImportLayoutResponseDto = BaseResponse3Dto & {
  achFileImportLayout?: FileImportLayout2Dto[];
};
export type SaveAchRecipientAddendaResponseDto = BaseResponse3Dto & {
  achBatchRecipientAddendas?: AchBatchRecipientAddenda2Dto[];
  achPaymentRecipientAddendas?: AchPaymentRecipientAddenda2Dto[];
};
export type DownloadHtmlResponseDto = BaseResponse3Dto & {
  /** @format byte */
  filePath?: string;
};
export type ChangeInAchCompanyResponseDto = BaseResponse3Dto & object;
export type ChangeInAchPaymentStatusResponseDto = BaseResponse3Dto & object;
export type VerifyRoutingNumberResponseDto = BaseResponse3Dto & {
  isOnUs: boolean;
  isValid: boolean;
};
export type GetOffsetAccountResponseDto = BaseResponse3Dto & {
  lookups?: Lookup2Dto[];
};
export type SearchAchRecipientResponseDto = BaseResponse3Dto & {
  achRecipientRecords?: AchRecipientRecordDto[];
  limitedBatches: boolean;
  limitedPayments: boolean;
};
export interface AchRecipientRecordDto {
  accountNumber?: string;
  accountType?: string;
  /** @format decimal */
  amount: number;
  /** @format date-time */
  effectiveDate?: string;
  hold: boolean;
  idNumber?: string;
  isRoutingNumberOnUs: boolean;
  isRoutingNumberValid: boolean;
  name?: string;
  prenote: boolean;
  /** @format int32 */
  recordId: number;
  recordType?: string;
  referenceNumber?: string;
  reversal: boolean;
  routingNumber?: string;
  transactionCode?: string;
  transactionType?: string;
}
export type SubmitScheduledAchPaymentResponseDto = BaseResponse3Dto & object;
export type AchTransactionSearchResponseDto = BaseResponse3Dto & {
  transactions?: AchTransactionDto[];
};
export interface AchTransactionDto {
  /** @format int32 */
  accountId: number;
  accountName?: string;
  accountNumber?: string;
  accountType?: string;
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format decimal */
  amount: number;
  amountType?: string;
  ediType?: string;
  secCode?: string;
  segmentDescription?: string;
  segmentValue?: string;
  /** @format date-time */
  transactionDate: string;
  transactionId?: string;
}
export type AchAddendaSearchResponseDto = BaseResponse3Dto & {
  addendaList?: AchAddendaDto[];
};
export interface AchAddendaDto {
  segmentDescription?: string;
  segmentValue?: string;
  transactionId?: string;
}
export type InquiryAchCompanyResponseDto = BaseResponse3Dto & {
  coreCompanies?: AchCompany2Dto[];
};
export type GetAchFileResponseDto = BaseResponse3Dto & {
  achFile?: AchFileDto;
};
export type AchFileDeleteResponseDto = BaseResponse3Dto & object;
export type SaveTransmittedAchFilesResponseDto = BaseResponse3Dto & {
  /** @format int32 */
  fileCount: number;
};
export type GetChildSupportPaymentResponseDto = BaseResponse3Dto & {
  childSupportPayment?: ChildSupportPaymentDto;
};
export type UploadInternationalNachaFileResponseDto = BaseResponse3Dto & {
  achInternationalPayments?: InternationalPaymentCollectionDto;
  errorMessage?: ErrorSummary2Dto;
};
export type GetStateTaxAmountTypesResponseDto = BaseResponse3Dto & {
  achStateAmountTypes?: AchStateAmountType2Dto[];
};
export type GetFederalTaxAmountTypesResponseDto = BaseResponse3Dto & {
  federalTaxCodeAmountTypes?: FederalTaxCodeAmountTypeDto[];
};
export interface FederalTaxCodeAmountTypeDto {
  achFederalAmountType?: AchFederalAmountType2Dto;
  /** @format int32 */
  achFederalAmountTypeId: number;
  federalTaxCode?: FederalTaxCodeDto;
  /** @format int32 */
  federalTaxCodeId: number;
  /** @format int32 */
  id: number;
}
export interface FederalTaxCodeDto {
  description?: string;
  /** @format int32 */
  id: number;
  taxCode?: string;
}
export type GetFederalTaxCodesResponseDto = BaseResponse3Dto & {
  federalTaxCodes?: FederalTaxCodeDto[];
};
export type GetFederalTaxRecipientResponseDto = BaseResponse3Dto & {
  federalTaxRecipient?: FederalTaxRecipientDto;
};
export interface FederalTaxRecipientDto {
  accountNumber?: string;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  id: number;
  recipientName?: string;
  routingNumber?: string;
}
export type GetStateTaxRecipientsResponseDto = BaseResponse3Dto & {
  stateTaxRecipients?: InstitutionAchProductStateRecipientDto[];
};
export interface InstitutionAchProductStateRecipientDto {
  accountNumber?: string;
  /** @format int32 */
  accountTypeId: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionAchProductId: number;
  recipientName?: string;
  routingNumber?: string;
  /** @format int32 */
  stateId: number;
}
export type GetStateTaxCodesResponseDto = BaseResponse3Dto & {
  stateTaxCodes?: InstitutionAchProductStateTaxCodeDto[];
};
export interface InstitutionAchProductStateTaxCodeDto {
  description?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  institutionAchProductId: number;
  /** @format int32 */
  stateId: number;
  taxCode?: string;
}
export type GetTaxRecipientAccountTypesResponseDto = BaseResponse3Dto & {
  accountTypes?: AccountType4Dto[];
};
export type NachaFileUploadResponseDto = BaseResponse3Dto & {
  achBatches?: AchBatch2Dto[];
  achPaymentDrafts?: AchPaymentDraftDto[];
  achPayments?: AchPayment2Dto[];
  allowUnbalancedAchBatch: boolean;
  errorMessage?: ErrorSummary2Dto;
  fileName?: string;
  fileSize?: string;
  /** @format int32 */
  totalBalancedBatches: number;
  /** @format decimal */
  totalBalancedCreditAmount: number;
  /** @format decimal */
  totalBalancedDebitAmount: number;
  /** @format decimal */
  totalCreditAmount: number;
  /** @format int32 */
  totalCreditTransactions: number;
  /** @format decimal */
  totalDebitAmount: number;
  /** @format int32 */
  totalDebitTransactions: number;
  /** @format int32 */
  totalUnbalancedBatches: number;
  /** @format decimal */
  totalUnbalancedCreditAmount: number;
  /** @format decimal */
  totalUnbalancedDebitAmount: number;
};
export type RecipientFileUploadResponseDto = BaseResponse3Dto & {
  achRecipients?: AchRecipientRecordDto[];
  errorSummaries?: FileErrorDto[];
};
export type SearchAchFileActivityResponseDto = BaseResponse3Dto & {
  achFileData?: AchFileDataDto[];
  /** @format byte */
  downloadContent?: string;
  forDownload: boolean;
};
export interface AchFileDataDto {
  /** @format int32 */
  achFileId: number;
  /** @format int32 */
  approvedCount: number;
  /** @format int32 */
  expiredCount: number;
  /** @format int32 */
  fileArchiveId: number;
  fileName?: string;
  fileSize?: string;
  /** @format int32 */
  pendingCount: number;
  processedByUsername?: string;
  /** @format date-time */
  processedDate?: string;
  /** @format int32 */
  rejectedCount: number;
  source?: string;
  /** @format int32 */
  totalBatchCount: number;
  /** @format decimal */
  totalCredit: number;
  /** @format decimal */
  totalDebit: number;
  updatedBy?: string;
  /** @format date-time */
  updatedDate?: string;
  /** @format date-time */
  uploadDate: string;
  validationError?: string;
}
export type CreateAchBatchResponseDto = BaseResponse3Dto & {
  achBatch?: AchBatch2Dto;
};
export type SearchAchBatchResponseDto = BaseResponse3Dto & {
  achBatches?: AchBatch2Dto[];
  /** @format byte */
  downloadContent?: string;
};
export type UpdateAchBatchResponseDto = BaseResponse3Dto & {
  achBatch?: AchBatch2Dto;
  errorSummary?: ErrorSummary2Dto;
};
export type GetMasterRecipientsResponseDto = BaseResponse3Dto & {
  pagedRecipients?: PagedListOfAchRecipientDto;
  /** @format int32 */
  requiredApprovalCount: number;
};
export interface PagedListOfAchRecipientDto {
  items?: AchRecipientDto[];
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  recordCountInCurrentPage: number;
  /** @format int32 */
  totalCount: number;
}
export interface AchRecipientDto {
  accountNumber?: string;
  accountTypeDescription?: string;
  /** @format int32 */
  accountTypeId: number;
  achMasterRecipientStatusTypeDescription?: string;
  /** @format byte */
  achMasterRecipientStatusTypeId: number;
  addenda?: string;
  /** @format int32 */
  approvalCount: number;
  audits?: AchAuditDtoDto[];
  canApproveReject: boolean;
  canEdit: boolean;
  /** @format decimal */
  defaultAmount: number;
  /** @format int32 */
  id: number;
  recipientIdNumber?: string;
  recipientName?: string;
  /** @format int32 */
  requiredApprovals: number;
  routingNumber?: string;
  transactionTypeAbbreviation?: string;
  /** @format int32 */
  transactionTypeId: number;
  /** @format int32 */
  updatedBy: number;
}
export interface AchAuditDtoDto {
  /** @format int32 */
  achActionTypeId: number;
  /** @format date-time */
  actionDate: string;
  /** @format int32 */
  recordId: number;
  /** @format int32 */
  userId: number;
}
export type SaveMasterRecipientResponseDto = BaseResponse3Dto & {
  achMasterRecipients?: AchRecipientDto[];
};
export type ApproveAchMasterRecipientResponseDto = BaseResponse3Dto & object;
export type RejectAchMasterRecipientResponseDto = BaseResponse3Dto & object;
export type DeleteAchMasterRecipientResponseDto = BaseResponse3Dto & object;
export type GetMasterRecipientAuditsResponseDto = BaseResponse3Dto & {
  audits?: AuditDisplayDto[];
};
export interface AuditDisplayDto {
  action?: string;
  /** @format date-time */
  actionDate: string;
  userAlias?: string;
}
export type SearchIssuedItemsResponseDto = BaseResponse3Dto & {
  issuedItems?: IssuedItemReportModelDto[];
};
export interface IssuedItemReportModelDto {
  /** @format int32 */
  accountId: number;
  accountNumber?: string;
  accountType?: string;
  /** @format decimal */
  amount: number;
  audits?: IssuedItemAuditReportModelDto[];
  checkNumber?: string;
  /** @format date-time */
  createdDate: string;
  entryType?: IssuedItemEntryTypeDto;
  /** @format date-time */
  issuedDate?: string;
  itemStatus?: string;
  itemType?: string;
  payee?: string;
}
export interface IssuedItemAuditReportModelDto {
  activity?: IssuedItemActionTypeDto;
  /** @format date-time */
  createdDate: string;
  username?: string;
}
export type GetIssuedItemStatusesResponseDto = BaseResponse3Dto & {
  statuses?: IssuedItemStatusTypeDto[];
};
export type UpdateIssuedItemStatusResponseDto = BaseResponse3Dto & object;
export type RunUpdateIssuedItemStatusBatchesResponseDto = BaseResponse3Dto & {
  failedBatches?: UpdateIssuedItemStatusBatchDto[];
};
export type RunUpdateIssuedItemStatusResponseDto = BaseResponse3Dto & object;
export type SearchJxAchExceptionsResponseDto = BaseResponse3Dto & {
  achExceptions?: AchExceptionDto[];
  protected?: string;
};
export type InquiryJxAchExceptionResponseDto = BaseResponse3Dto & {
  achException?: AchExceptionDto;
};
export interface AchPaymentFilterModelDto {
  achType?: string;
  /** @minLength 1 */
  achCompanyName: string;
  achCompanyList?: AchCompanyModelDto[];
  /** @minLength 1 */
  batchName: string;
  /** @minLength 1 */
  transactionId: string;
  /** @minLength 1 */
  secCode: string;
  /** @minLength 1 */
  initiatedDateType: string;
  /**
   * @format date-time
   * @minLength 1
   */
  initiatedDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  initiatedDateStart: string;
  /**
   * @format date-time
   * @minLength 1
   */
  initiatedDateEnd: string;
  /** @minLength 1 */
  effectiveDateType: string;
  /**
   * @format date-time
   * @minLength 1
   */
  effectiveDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  effectiveDateStart: string;
  /**
   * @format date-time
   * @minLength 1
   */
  effectiveDateEnd: string;
  /** @minLength 1 */
  amountType: string;
  /** @format decimal */
  amount: number;
  /** @format decimal */
  amountMin: number;
  /** @format decimal */
  amountMax: number;
  /** @minLength 1 */
  status: string;
  /** @format decimal */
  debitAmount: number;
  /** @format decimal */
  creditAmount: number;
  /** @minLength 1 */
  creditAmountType: string;
  /** @minLength 1 */
  debitAmountType: string;
  /** @format decimal */
  debitAmountMax: number;
  /** @format decimal */
  debitAmountMin: number;
  /** @format decimal */
  creditAmountMax: number;
  /** @format decimal */
  creditAmountMin: number;
  isRecurring: boolean;
  isBasic: boolean;
  /** @minLength 1 */
  nextPaymentDateType: string;
  /**
   * @format date-time
   * @minLength 1
   */
  nextPaymentDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  nextPaymentDateStart: string;
  /**
   * @format date-time
   * @minLength 1
   */
  nextPaymentDateEnd: string;
  fileArchiveIds?: number[];
  frequencies?: LookupModelDto[];
}
export interface RoutingNumberStatusModelDto {
  isValid: boolean;
  isOnUs: boolean;
}
export interface AchEditablePaymentModelDto {
  payment: AchPaymentModelDto;
  settings: AchSettingsModelDto;
  /** @minLength 1 */
  errorMessage: string;
  /** @minLength 1 */
  successMessage: string;
  securityMessage?: SecurityMessageModelDto;
}
export type AchPaymentWithTemplatesModelDto = AchPaymentWithBatchesModelDto & {
  achBatchSummaries?: AchPaymentModelDto[];
};
export type AchStateTaxPaymentModelDto = AchPaymentModelDto & {
  /** @format date-time */
  taxPeriodEndDate: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  taxCode: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  taxPayerId: string;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientAccountType?: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientRoutingNumber: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientAccountNumber: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  recipientIdNumber?: string;
  /** @format int32 */
  stateId?: number;
  state?: string;
  /** @format int32 */
  payFromAccountId?: number;
  payFromAccountName?: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  createdBy: string;
  /** @format date-time */
  createdDate: string;
  copiedFromTransactionId?: string;
  achStateTaxPaymentAmounts?: AchStateTaxPaymentAmountModelDto[];
};
export type AchStateTaxPaymentAmountModelDto = ModelBaseDto & {
  /** @format int32 */
  achPaymentId: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  achStateAmountTypeId: number;
  /**
   * @minLength 0
   * @maxLength 1
   */
  amountTypeName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  amountTypeDescription: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  createdBy: string;
  /** @format date-time */
  createdDate: string;
};
export type AchFederalTaxPaymentModelDto = AchPaymentModelDto & {
  /** @format date-time */
  taxPeriodEndDate: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  taxCode: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  taxPayerId: string;
  /** @format int32 */
  recipientAccountTypeId: number;
  recipientAccountType?: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientRoutingNumber: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  recipientAccountNumber: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  recipientIdNumber?: string;
  /** @format int32 */
  payFromAccountId?: number;
  payFromAccountName?: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  createdBy: string;
  /** @format date-time */
  createdDate: string;
  achFederalTaxPaymentAmounts?: AchFederalTaxPaymentAmountModelDto[];
  hasAmountTypes: boolean;
};
export type AchFederalTaxPaymentAmountModelDto = ModelBaseDto & {
  /** @format int32 */
  achPaymentId: number;
  /** @format decimal */
  amount: number;
  /** @format int32 */
  achFederalAmountTypeId?: number;
  amountType?: string;
  achFederalAmountTypeName?: string;
  achFederalAmountTypeDescription?: string;
  createdBy?: string;
  /** @format date-time */
  createdDate: string;
};
export type CancelAchPaymentModelDto = SecurityBaseDto & {
  /** @format int32 */
  achPaymentId: number;
  comment?: string;
};
export type AchUninitiateModelDto = SecurityBaseDto & {
  /** @format int32 */
  achPaymentId: number;
  comment?: string;
  isUninitiated: boolean;
};
export interface AchPaymentSearchResponseDto {
  results?: AchPaymentModelDto[];
  totals?: AchPaymentStatusTotalDto[];
}
export interface AchPaymentStatusTotalDto {
  status?: string;
  /** @format int32 */
  count: number;
  /** @format decimal */
  totalCredit: number;
  /** @format decimal */
  totalDebit: number;
}
export type InternationalAchPaymentDto = AchPaymentModelDto & {
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  recipients?: InternationalAchPaymentRecipientCollectionDto;
};
export type InternationalAchPaymentRecipientCollectionDto = InternationalAchPaymentRecipientDto[];
export type InternationalAchPaymentRecipientDto = AchRecipientModelDto & {
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  receivingBankType?: string;
  receivingBankNumber?: string;
  receivingBankName?: string;
  receivingBankCountry?: string;
  optionalAddendaLine1?: string;
  optionalAddendaLine2?: string;
  iatTransactionType?: string;
};
export type ChildSupportPaymentResponseDto = AchEditablePaymentModelDto & {
  payment?: ChildSupportPayment2Dto;
};
export type ChildSupportPayment2Dto = AchPaymentModelDto & {
  recipients?: ChildSupportRecipientDto[];
};
export type ChildSupportRecipientDto = AchRecipientModelDto & {
  addenda?: ChildSupportAddendaDto[];
};
export type ChildSupportAddendaDto = ModelBaseDto & {
  caseIdentifier?: string;
  employmentTermination: boolean;
  fipsCode?: string;
  medicalSupport: boolean;
  nonCustodialParentFirstName?: string;
  nonCustodialParentLastName?: string;
  nonCustodialParentSsn?: string;
  /** @format date-time */
  payDate: string;
};
export type ChildSupportEditableAchPaymentModelDto = AchEditablePaymentModelDto & {
  childSupportAchPayment: ChildSupportPayment2Dto;
};
export type GetInternationalAchCountriesResponse2Dto = SecurityBaseDto & {
  internationalAchCountries?: InternationalAchCountryCollection2Dto;
};
export type InternationalAchCountryCollection2Dto = InternationalAchCountry2Dto[];
export interface InternationalAchCountry2Dto {
  countryCode?: string;
  countryName?: string;
  receivingBankCountry?: string;
  foreignExchangeIndicators?: ForeignExchangeIndicatorCollection3Dto;
  internationalAchAccountTypes?: InternationalAchAccountTypeCollectionDto;
  isActive: boolean;
}
export type ForeignExchangeIndicatorCollection3Dto = ForeignExchangeIndicator3Dto[];
export interface ForeignExchangeIndicator3Dto {
  indicatorCode?: string;
  indicatorName?: string;
  supportedCurrencies?: InternationalCurrencyCollection2Dto;
}
export type InternationalCurrencyCollection2Dto = InternationalCurrency2Dto[];
export interface InternationalCurrency2Dto {
  currencyCode?: string;
  currencyName?: string;
}
export type InternationalAchAccountTypeCollectionDto = InternationalAchAccountType2Dto[];
export interface InternationalAchAccountType2Dto {
  accountName?: string;
  allowDebit: boolean;
  allowCredit: boolean;
}
export interface GetInternationalAchPaymentResponse2Dto {
  payment?: InternationalAchPaymentDto;
}
export interface CreateInternationalAchPaymentResponseDto {
  payment?: InternationalAchPaymentDto;
  /** @minLength 1 */
  errorMessage: string;
  /** @minLength 1 */
  successMessage: string;
  securityMessage?: SecurityMessageModelDto;
}
export interface CreateInternationalAchPaymentRequestDto {
  payment?: InternationalAchPaymentDto;
  securityMessage?: SecurityMessageModelDto;
}
export type InternationalEditableAchPaymentModelDto = AchEditablePaymentModelDto & {
  internationalAchPayment?: InternationalAchPaymentDto;
};
export interface UpdateInternationalAchPaymentRequestDto {
  payment?: InternationalAchPaymentDto;
  securityMessage?: SecurityMessageModelDto;
}
export type AchRecipientRecordModelDto = AchRecipientModelDto & {
  /** @minLength 1 */
  recordType: string;
  /** @minLength 1 */
  referenceNumber: string;
  /**
   * @format date-time
   * @minLength 1
   */
  effectiveDate: string;
  /** @format int32 */
  recordId: number;
};
export interface SearchAchRecipientResponseModelDto {
  achRecipientRecords?: AchRecipientRecordModelDto[];
  limitedPayments: boolean;
  limitedBatches: boolean;
}
export interface AchRecipientFilterModelDto {
  /** @minLength 1 */
  recordType: string;
  /** @minLength 1 */
  recipientName: string;
  /** @minLength 1 */
  amountType: string;
  /** @format decimal */
  amount: number;
  /** @format decimal */
  amountMin: number;
  /** @format decimal */
  amountMax: number;
  prenote: boolean;
  hold: boolean;
  reversal: boolean;
}
export type GetCreatedByUsersForIssuedItemFilesResponseDto = BaseResponse2Dto & {
  createdByUsers?: IssuedItemFileCreatedByUserCollectionDto;
};
export type IssuedItemFileCreatedByUserCollectionDto = IssuedItemFileCreatedByUserDto[];
export interface IssuedItemFileCreatedByUserDto {
  /** @format guid */
  userUniqueId: string;
  name?: string;
}
export interface PagedListModelOfIssuedItemFileDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: IssuedItemFileDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface IssuedItemFileDto {
  /** @format guid */
  issuedItemFileUniqueId: string;
  fileName?: string;
  /** @format int32 */
  fileSize: number;
  /** @format guid */
  uploadedByUserUniqueId: string;
  uploadedByUserName?: string;
  /** @format date-time */
  uploadedDate: string;
  /** @format decimal */
  totalIssueAmount: number;
  /** @format int32 */
  totalIssueCount: number;
  /** @format decimal */
  totalVoidAmount: number;
  /** @format int32 */
  totalVoidCount: number;
  /** @format decimal */
  totalItemAmount: number;
  /** @format int32 */
  totalItemCount: number;
}
export type SearchIssuedItemFilesActivityRequestDto = BaseRequest2Dto & {
  pagingParameters?: PagingParametersModelDto;
};
export type SearchIssuedItemFileDetailsResponseDto = BaseResponse2Dto & {
  issuedItemFile?: IssuedItemFileDto;
  issuedItemFileDetails?: PagedListModelOfIssuedItemFileDetailDto;
};
export interface PagedListModelOfIssuedItemFileDetailDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: IssuedItemFileDetailDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface IssuedItemFileDetailDto {
  /** @format guid */
  uniqueId: string;
  actionTaken: FileDetailActionTakenTypeDto;
  /** @format date-time */
  createdDate: string;
  itemType: IssuedItemTypeDto;
  originalItemType: IssuedItemTypeDto;
  /** @format date-time */
  issuedDate: string;
  /** @format date-time */
  originalIssuedDate?: string;
  isIssuedDateModified: boolean;
  /** @format date-time */
  checkVoidDate?: string;
  accountDisplay?: string;
  originalAccountDisplay?: string;
  /** @format decimal */
  amount: number;
  /** @format decimal */
  originalAmount?: number;
  /** @format int64 */
  checkNumber: number;
  /** @format int64 */
  originalCheckNumber?: number;
  payee?: string;
  originalPayee?: string;
  isIssuedItemSaved: boolean;
}
export enum FileDetailActionTakenTypeDto {
  Undefined = 0,
  Added = 1,
  Edited = 2,
  Deleted = 3,
}
export enum IssuedItemTypeDto {
  Undefined = 0,
  None = 1,
  Check = 2,
  Void = 3,
}
export type SearchIssuedItemFileDetailsRequestDto = BaseRequest2Dto & {
  /** @format guid */
  issuedItemFileUniqueId: string;
  pagingParameters?: PagingParametersModelDto;
};
export type SearchAccountReconIssuedItemsResponseDto = BaseResponse2Dto & {
  issuedItems?: IssuedItemCollectionDto;
};
export type IssuedItemCollectionDto = IssuedItem2Dto[];
export interface IssuedItem2Dto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  accountId: number;
  accountNumber?: string;
  accountType?: string;
  companyName?: string;
  checkNumber?: string;
  /** @format date-time */
  checkDate?: string;
  /** @format decimal */
  issuedAmount: number;
  payeeName?: string;
  createdBy?: string;
  createdByAlias?: string;
  /** @format date-time */
  effectiveDate?: string;
  updatedBy?: string;
  /** @format date-time */
  updatedDate: string;
  issuedStatus?: string;
  itemType?: string;
  entryType?: string;
  batchNumber?: string;
  sequenceNumber?: string;
  /** @format int32 */
  issuedItemFileId?: number;
  fileName?: string;
  /** @format guid */
  issuedItemFileUniqueId?: string;
  bankNumber?: string;
  dataView?: string;
  /** @format int32 */
  institutionId: number;
  /** @format date-time */
  loadDate?: string;
  loadSequence?: string;
  deleteFlag?: boolean;
  deleted?: boolean;
  sourceKey?: string;
  foreignKey?: string;
  /** @format date-time */
  checkVoidDate?: string;
  transactionType?: string;
  payeeAddress1?: string;
  payeeAddress2?: string;
  payeeAddress3?: string;
  payeeAddress4?: string;
  payeeAddress5?: string;
  accountReconViolationRemark?: string;
  /** @format date-time */
  issuedItemCreatedDate?: string;
  /** @format decimal */
  transactionAmount: number;
  /** @format date-time */
  staleDate?: string;
  /** @format date-time */
  postDate?: string;
  /** @format date-time */
  dormantDate?: string;
  transactionCodeCode?: string;
  transactionCodeDescription?: string;
  /** @format date-time */
  stopCheckPlaceDate?: string;
  /** @format date-time */
  stopCheckExpirationDate?: string;
  accountReconViolationType?: string;
  exceptionResolveType?: string;
  /** @format date-time */
  systemDate?: string;
  /** @format int32 */
  returnTransactionCount: number;
  /** @format date-time */
  itemReturnDate?: string;
  accountReconAdjustedType?: string;
  accountReconPurgeCode?: string;
  accountReconPurgeDescription?: string;
  /** @format date-time */
  accountReconPurgeDate?: string;
  accountReconItemStatus?: string;
  imageNumber?: string;
  /** @format date-time */
  lastMainDate?: string;
  sourceFundsCode?: string;
  sourceFundsDescription?: string;
  /** @format date-time */
  staleExpirationDate?: string;
  /** @format date-time */
  postExpirationDate?: string;
  /** @format date-time */
  dormantExpirationDate?: string;
  /** @format date-time */
  checkVoidExpirationDate?: string;
  transactionBatchNumber?: string;
  transactionSequenceNumber?: string;
  sourceCode?: string;
  itemResolveType?: string;
  /** @format date-time */
  deleteDate?: string;
  /** @format date-time */
  previousStopCheckPlaceDate?: string;
  /** @format date-time */
  previousStopCheckExpirationDate?: string;
  /** @format date-time */
  stopCheckPurgeDate?: string;
  custom?: string;
  /** @format guid */
  accountReconItemDetailUniqueId: string;
  accountReconItemAudits?: AccountReconItemAuditCollectionDto;
}
export type AccountReconItemAuditCollectionDto = AccountReconItemAuditDto[];
export interface AccountReconItemAuditDto {
  /** @format int32 */
  accountReconItemAuditId: number;
  /** @format int32 */
  institutionId: number;
  /** @format guid */
  accountReconItemDetailUniqueId: string;
  accountReconItemActionType?: string;
  /** @format int32 */
  createdBy: number;
  createdByAlias?: string;
  /** @format date-time */
  createdDate: string;
}
export type SearchAccountReconIssuedItemsRequestDto = BaseRequest2Dto & {
  accountIds?: NumberKeyCollectionDto;
  checkNumber?: string;
  payeeName?: string;
  issuedDateStart?: string;
  issuedDateEnd?: string;
  issuedItemCreatedDateStart?: string;
  issuedItemCreatedDateEnd?: string;
  clearedDateStart?: string;
  clearedDateEnd?: string;
  issuedAmountStart?: string;
  issuedAmountEnd?: string;
  issuedItemStatusTypes?: TextKeyCollectionDto;
  itemTypes?: TextKeyCollectionDto;
  entryTypes?: TextKeyCollectionDto;
};
export type SearchIssuedItemsVoidsResponseDto = BaseResponse2Dto & {
  searchIssuedItemsVoids?: SearchIssuedItemsVoidDictionaryDto;
};
export type SearchIssuedItemsVoidDictionaryDto = Record<string, SearchIssuedItemsVoidDto>;
export interface SearchIssuedItemsVoidDto {
  issuedItems?: IssuedItemCollectionDto;
  hasMore: boolean;
}
export type SearchIssuedItemsVoidsRequestDto = BaseRequest2Dto & {
  /** @format int32 */
  maxMatches: number;
  issuedItemsVoids?: IssuedItemEntryDictionaryDto;
};
export type IssuedItemEntryDictionaryDto = Record<string, IssuedItemEntryDto>;
export interface IssuedItemEntryDto {
  /** @format int64 */
  issuedItemId: number;
  /** @format int32 */
  accountId: number;
  checkNumber?: string;
  /** @format decimal */
  issuedAmount: number;
  /** @format date-time */
  checkDate?: string;
  /** @format date-time */
  issuedItemCreatedDate?: string;
  /** @format date-time */
  checkVoidDate?: string;
  itemType?: string;
  entryType?: string;
  payeeName?: string;
  isModify: boolean;
  batchNumber?: string;
  sequenceNumber?: string;
  actionTaken?: string;
  /** @format int32 */
  originalAccountId?: number;
  originalCheckNumber?: string;
  /** @format decimal */
  originalIssuedAmount?: number;
  /** @format date-time */
  originalCheckDate?: string;
  isCheckDateModified: boolean;
  originalItemType?: string;
  originalPayee?: string;
}
export type SaveAccountReconIssuedItemsResponseDto = BaseResponse2Dto & {
  issuedItems?: IssuedItemCollectionDto;
  issuedItemErrors?: IssuedItemErrorCollectionDto;
};
export type IssuedItemErrorCollectionDto = IssuedItemErrorDto[];
export interface IssuedItemErrorDto {
  /** @format int32 */
  issuedItemId: number;
  errorCode?: string;
  errorMessage?: string;
}
export type SaveAccountReconIssuedItemsRequestDto = BaseRequest2Dto & {
  uploadFileName?: string;
  /** @format int32 */
  uploadFileSize: number;
  issuedItems?: IssuedItemEntryCollectionDto;
};
export type IssuedItemEntryCollectionDto = IssuedItemEntryDto[];
export interface ApproveAchFiltersResponseModelDto {
  success: boolean;
  securityMessage?: SecurityMessageModelDto;
}
export interface ApproveAchFiltersRequestModelDto {
  achFilterUniqueIds: string[];
  comment?: string;
  securityMessage?: SecurityMessageModelDto;
}
export interface DeleteAchFiltersResponseModelDto {
  success: boolean;
  securityMessage?: SecurityMessageModelDto;
}
export interface DeleteAchFiltersRequestModelDto {
  achFilterUniqueIds: string[];
  securityMessage?: SecurityMessageModelDto;
}
export interface RejectAchFiltersResponseModelDto {
  success: boolean;
  securityMessage?: SecurityMessageModelDto;
}
export interface RejectAchFiltersRequestModelDto {
  achFilterIds: string[];
  comment?: string;
  securityMessage?: SecurityMessageModelDto;
}
export type AccountModelCollectionDto = AccountModel4Dto[];
export interface AccountModel4Dto {
  /** @format guid */
  accountUniqueId: string;
  accountNickname?: string;
  accountNumber?: string;
  accountType?: string;
  claims?: TextKeyCollectionDto;
}
export interface AchFilterAccountMasterSettingsModelDto {
  creditDefaultAllowType?: string;
  debitDefaultAllowType?: string;
}
export type AchFilterAuditHistoryModelCollectionDto = AchFilterAuditHistoryModelDto[];
export interface AchFilterAuditHistoryModelDto {
  /** @format guid */
  achFilterUniqueId: string;
  auditEvents?: AchFilterAuditEventModelCollectionDto;
}
export type AchFilterAuditEventModelCollectionDto = AchFilterAuditEventModelDto[];
export interface AchFilterAuditEventModelDto {
  /** @format int32 */
  achFilterAuditEventId: number;
  /** @format date-time */
  actionDate?: string;
  action?: string;
  actionUsername?: string;
  editedFields?: string[];
}
export interface AchFilterAuditHistoryRequestModelDto {
  achFilterUniqueIds?: string[];
}
export interface AchFiltersSearchRequestModelDto {
  accountUniqueIds?: string[];
  achCompanyId?: string;
  achCompanyName?: string;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amountStart?: number;
  pagingParameters?: PagingParametersModelDto;
  routingNumbers?: string[];
  secCodes?: string[];
  statuses?: string[];
  transactionTypes?: string[];
}
export interface SaveAchFiltersResponseModelDto {
  /** @format guid */
  achFilterUniqueId?: string;
  securityMessage?: SecurityMessageModelDto;
}
export interface SaveAchFiltersRequestModelDto {
  /** @format guid */
  achFilterUniqueId?: string;
  /** @format guid */
  accountUniqueId: string;
  /** @minLength 1 */
  achCompanyId: string;
  /**
   * @minLength 0
   * @maxLength 16
   */
  achCompanyName?: string;
  /** @minLength 1 */
  allowType: string;
  /** @format date-time */
  expirationDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  filterRuleName?: string;
  /** @format decimal */
  fromAmount?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  remarks?: string;
  /**
   * @minLength 3
   * @maxLength 3
   */
  secCode?: string;
  /** @format decimal */
  toAmount?: number;
  /** @minLength 1 */
  transactionType: string;
  /** @minLength 1 */
  accountNumber: string;
  /** @minLength 1 */
  accountType: string;
  nickName?: string;
  routingNumber?: string;
  securityMessage?: SecurityMessageModelDto;
}
export interface ValidateSaveAchFilterRequestRequestModelDto {
  saveAchFiltersRequest: SaveAchFiltersRequestModelDto;
  securityMessage?: SecurityMessageModelDto;
}
export type AchFilterUserModelCollectionDto = AchFilterUserModelDto[];
export interface AchFilterUserModelDto {
  /** @format guid */
  userUniqueId: string;
  name?: string;
  alias?: string;
}
export type ArpExceptionViewModelDto = ModelBaseDto & {
  arpExceptions?: ArpExceptionModelDto[];
  arpExceptionDetails?: ArpExceptionDetailDataModelDto[];
  hasEtlRunSuccessfully: boolean;
};
export interface ArpExceptionSearchModel2Dto {
  type?: string;
  accounts?: AccountModelDto[];
  checkNumber?: string;
  issuedAmountType?: string;
  issuedAmountStart?: string;
  issuedAmountEnd?: string;
  issuedAmount?: string;
  paidAmountType?: string;
  paidAmountStart?: string;
  paidAmountEnd?: string;
  paidAmount?: string;
  issuedDateRangeType?: string;
  issuedDateStart?: string;
  issuedDateEnd?: string;
  issuedDate?: string;
  postedDateRangeType?: string;
  postedDateStart?: string;
  postedDateEnd?: string;
  postedDate?: string;
  payee?: string;
  decisionTaken?: string;
}
export type AchExceptionFilterModelDto = ModelBaseDto & {
  accountNumber?: string;
  allowAllDebits?: string;
  allowAllCredits?: string;
  achCompanyName?: string;
  companyId?: string;
  secCode?: string;
  /** @format decimal */
  amountFrom?: number;
  /** @format decimal */
  amountTo?: number;
  debitOrCredit?: string;
  exceptionFilter?: string;
  /** @format date-time */
  expirationDate?: string;
};
export interface AchExceptionFiltersSearchModelDto {
  accounts?: AccountModelDto[];
  achCompanyName?: string;
  achCompanyId?: string;
  secCodes?: SecCodeModelDto[];
  amountType?: string;
  /** @format decimal */
  amountStart?: number;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amount?: number;
  expirationDateType?: string;
  /** @format date-time */
  expirationDateStart?: string;
  /** @format date-time */
  expirationDateEnd?: string;
  /** @format date-time */
  expirationDate?: string;
  expirationFilter?: string;
}
export interface ArpOutputFileSearchModelDto {
  accounts?: AccountModelDto[];
  fileName?: string;
  fileReceivedDateType?: string;
  fileReceivedDateStart?: string;
  fileReceivedDateEnd?: string;
  fileReceivedDate?: string;
}
export interface AchExceptionSearchModel2Dto {
  accounts?: AccountModelDto[];
  achCompanies?: AchCompanyModelDto[];
  secCodes?: SecCodeModelDto[];
  amountType?: string;
  amountStart?: string;
  amountEnd?: string;
  amount?: string;
  type?: string;
  postedDateRangeType?: string;
  postedDateStart?: string;
  postedDateEnd?: string;
  postedDate?: string;
  decisionStatus?: string;
  entryDescription?: string;
}
export type AchExceptionViewModelDto = ModelBaseDto & {
  achExceptions?: AchExceptionModelDto[];
  achExceptionDetails?: AchExceptionDetailDataModelDto[];
  posPayExceptionEtlFiRuntimeStats?: PosPayExceptionEtlFiRuntimeStatsModelDto;
};
export type PosPayExceptionEtlFiRuntimeStatsModelDto = ModelBaseDto & {
  hasEtlRunSuccessfully: boolean;
  hasStartTimeOccurred: boolean;
  hasEndTimeOccurred: boolean;
  isEndOfDayRunning: boolean;
  /** @format duration */
  timeUntilStartTime?: string;
  /** @format duration */
  timeUntilEndTime?: string;
};
export interface AchExceptionDetailSearchModelDto {
  accounts?: AccountModelDto[];
  amountType?: string;
  /** @format decimal */
  amountStart?: number;
  /** @format decimal */
  amountEnd?: number;
  /** @format decimal */
  amount?: number;
  achExceptionDecisionStatus?: string;
  secCodes?: SecCodeModelDto[];
  description?: string;
  postedDateType?: string;
  /** @format date-time */
  postedDate?: string;
  /** @format date-time */
  postedDateStart?: string;
  /** @format date-time */
  postedDateEnd?: string;
  searchType?: string;
}
export interface PagedListModelOfAchExceptionDetailDataModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  items?: AchExceptionDetailDataModelDto[];
  /** @format int32 */
  recordCountInCurrentPage: number;
}
export interface AchExceptionsDecisionsActivityRequestModelDto {
  pagingParameters?: PagingParametersModelDto;
  achExceptionDetailSearchModel?: AchExceptionDetailSearchModelDto;
  searchText?: string;
  searchAccountNickname: boolean;
  /** @format date-time */
  previousBusinessDayCutoff: string;
  /** @format date-time */
  currentBusinessDayCutoff: string;
}
export type ArpSecCodeModelCollectionDto = ArpSecCodeModelDto[];
export interface ArpSecCodeModelDto {
  secCode?: string;
  description?: string;
}
export type ArpSavedFormatModelDto = ModelBaseDto & {
  name?: string;
  type?: string;
  /** @format int32 */
  itemNumberOrder?: number;
  /** @format int32 */
  itemNumberBegin?: number;
  /** @format int32 */
  itemNumberEnd?: number;
  /** @format int32 */
  itemAmountOrder?: number;
  /** @format int32 */
  itemAmountBegin?: number;
  /** @format int32 */
  itemAmountEnd?: number;
  itemAmountFormat?: string;
  itemAmountDecimal?: boolean;
  /** @format int32 */
  issueDateOrder?: number;
  /** @format int32 */
  issueDateBegin?: number;
  /** @format int32 */
  issueDateEnd?: number;
  issueDateFormat?: string;
  /** @format int32 */
  payeeOrder?: number;
  /** @format int32 */
  payeeBegin?: number;
  /** @format int32 */
  payeeEnd?: number;
  /** @format int32 */
  accountNumberOrder?: number;
  /** @format int32 */
  accountNumberBegin?: number;
  /** @format int32 */
  accountNumberEnd?: number;
  /** @format int32 */
  accountTypeOrder?: number;
  /** @format int32 */
  accountTypeBegin?: number;
  /** @format int32 */
  accountTypeEnd?: number;
  accountTypeCheckingEquals?: string;
  accountTypeSavingsEquals?: string;
  /** @format int32 */
  voidDateOrder?: number;
  /** @format int32 */
  voidDateBegin?: number;
  /** @format int32 */
  voidDateEnd?: number;
  voidDateFormat?: string;
  /** @format int32 */
  voidIndicatorOrder?: number;
  /** @format int32 */
  voidIndicatorBegin?: number;
  /** @format int32 */
  voidIndicatorEnd?: number;
  voidYesIndicator?: string;
  textQualifier?: string;
  fieldDelimiter?: string;
  /** @format int32 */
  headerRows?: number;
  /** @format int32 */
  footerRows?: number;
};
export interface ArpIssuedItemModelDto {
  account?: CompanyAccountModelDto;
  /** @format decimal */
  checkAmount: number;
  checkNumber?: string;
  /** @format date-time */
  dateIssued?: string;
  /** @format date-time */
  voidDate?: string;
  /** @format date-time */
  postDate?: string;
  /** @format date-time */
  createdDate?: string;
  errorSummary?: ErrorSummaryModelDto;
  /** @format int32 */
  index: number;
  itemStatus?: string;
  isUploaded: boolean;
  payee?: string;
  status?: string;
  type?: string;
  entryType?: string;
  auditItems?: ArpIssuedItemAuditModelDto[];
}
export interface ArpIssuedItemAuditModelDto {
  /** @format date-time */
  createdFiDateTime: string;
  createdByUserName?: string;
  action?: string;
}
export interface ArpParseResultsModelDto {
  fileErrors?: ArpFileErrorDto[];
  issuedItems?: ArpIssuedItemModelDto[];
}
export interface ArpFileErrorDto {
  /** @format int32 */
  lineNumber: number;
  errorText?: string;
}
export interface ArpModelDto {
  account?: CompanyAccountModelDto;
  issuedFileName?: string;
  issuedItems?: ArpIssuedItemModelDto[];
}
export interface IssuedItemSearchModelDto {
  accounts?: AccountModelDto[];
  checkNumber?: string;
  itemEntryType?: string;
  itemType?: string;
  payee?: string;
  issuedDateStart?: string;
  issuedDateEnd?: string;
  createdDateStart?: string;
  createdDateEnd?: string;
  voidDateStart?: string;
  voidDateEnd?: string;
  clearedDateStart?: string;
  clearedDateEnd?: string;
  amountStart?: string;
  amountEnd?: string;
  statusNames?: string[];
  entryTypes?: string[];
}
export interface InstitutionPosPayAchExceptionsCutOffTimesModelDto {
  /** @format date-time */
  previousBusinessDayCutoff: string;
  /** @format date-time */
  currentBusinessDayCutoff: string;
  /** @format date-time */
  currentBusinessDayStartTime: string;
  /** @format date-time */
  fiLocalTime: string;
  institutionName?: string;
  hasEtlRunSuccessfully: boolean;
}
export type CompanyOptionModelCollectionDto = CompanyOptionModelDto[];
export interface CompanyOptionModelDto {
  name?: string;
  value?: string;
}
export type CheckExceptionModelDataPageDto = DataPageOfCheckExceptionModelDto & {
  hasEtlCompletedSuccessfully: boolean;
};
export interface DataPageOfCheckExceptionModelDto {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  maxPageSize: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  recordCountInCurrentPage: number;
  items?: CheckExceptionModelDto[];
}
export interface CheckExceptionModelDto {
  /** @format guid */
  arpExceptionDetailUniqueId: string;
  /** @format int32 */
  arpExceptionDetailId: number;
  accountNumber?: string;
  checkNumber?: string;
  /** @format decimal */
  paidAmount: number;
  /** @format decimal */
  issuedAmount?: number;
  /** @format date-time */
  postedDate?: string;
  /** @format date-time */
  issuedDate?: string;
  issuedPayee?: string;
  decisionStatus?: string;
  comment?: string;
  exceptionReason?: string;
  financialInstitutionId?: string;
  companyName?: string;
  /** @format int32 */
  companyId: number;
  companyUniqueId?: string;
  fromWorkStation?: string;
  attachmentFileName?: string;
  /** @format guid */
  returnReasonUniqueId?: string;
  /** @format int32 */
  returnReasonId?: number;
  /** @format guid */
  lastDecisionByUserUniqueId?: string;
  updatedBy?: string;
  sourceOfEntry?: string;
  protected?: string;
  ddaBatchNumber?: string;
  ddaSequenceNumber?: string;
  posPayItemId?: string;
  returnReasonDescription?: string;
  lastDecisionTakenBy?: string;
  audits?: AuditModelDto[];
  isCorrectionRequestCreated: boolean;
  checkImageNumber?: string;
  /** @format guid */
  attachment?: string;
}
export interface AuditModelDto {
  username?: string;
  action?: string;
  /** @format date-time */
  timestamp: string;
}
export interface SearchCheckExceptionModelDto {
  accountUniqueIds?: string[];
  checkNumber?: string;
  decisionStatus?: string;
  decisionByUserUniqueIds?: string[];
  issuedPayee?: string;
  issuedDate?: DateFilterModelDto;
  issuedAmount?: DecimalFilterModelDto;
  postedDate?: DateFilterModelDto;
  postedAmount?: DecimalFilterModelDto;
  exceptionReasons?: string[];
  type?: string;
  isPortrait: boolean;
}
export interface DecimalFilterModelDto {
  /** @format decimal */
  startAmount: number;
  /** @format decimal */
  endAmount: number;
}
export interface SaveCheckExceptionAttachmentRequestModelDto {
  /** @format byte */
  attachment?: string;
  fileName?: string;
  comment?: string;
}
export interface ExceptionCorrectionModelDto {
  /** @format guid */
  arpExceptionDetailUniqueId: string;
  comment?: string;
  /** @format byte */
  attachment?: string;
  attachmentFileName?: string;
  exceptionCorrectionItems?: ExceptionCorrectionItemModelDto[];
}
export interface ExceptionCorrectionItemModelDto {
  exceptionCorrectionItem: ExceptionCorrectionItemDto;
  correctionValue?: string;
}
export enum ExceptionCorrectionItemDto {
  AccountNumber = 1,
  PostedDate = 2,
  CheckNumber = 3,
  Amount = 4,
  Payee = 5,
  DuplicateCheck = 6,
}
export interface InstitutionCheckExceptionReturnReasonDto {
  /** @format guid */
  returnReasonUniqueId?: string;
  description?: string;
  isDefault: boolean;
  isDeleted: boolean;
}
export interface UisLoginUrlResponseDto {
  isUisEnabled: boolean;
  uisLoginUrl?: string;
}
export interface UisLoginUrlRequestDto {
  fiId?: string;
  isMobile: boolean;
  returnUrl?: string;
}
export interface SsoViaUisRequestDto {
  authorizationCode?: string;
  fiId?: string;
  state?: string;
  isMobile: boolean;
  returnUrl?: string;
  isEnrollment: boolean;
}
export interface CompanyLoginResponseDto {
  alias?: string;
  companyUniqueId?: string;
  digitalId?: string;
  message?: string;
  statusCode?: string;
  userAccountSettingsLink?: string;
}
export interface CompanyLoginRequestDto {
  /** @format guid */
  userCompanyUniqueId: string;
}
export interface AgileticsSamlResponseDto {
  endPoint?: string;
  headers?: Record<string, string>;
  body?: Record<string, string>;
  httpVerb?: string;
  errorMessage?: string;
}
