import { Injectable } from '@jack-henry/frontend-utils/di';
import { StorageProvider } from '../types';
import { WindowService } from './window.service';

@Injectable()
export class SessionStorageService extends StorageProvider {
    constructor(window: WindowService) {
        super(window.sessionStorage);
    }
}
