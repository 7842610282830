BankDialogController.$inject = [
    '$scope',
    '$timeout',
    '$modalInstance',
    '$filter',
    'beneficiariesService',
    'bankService',
    'banks',
    'wireIsoService',
];

export default function BankDialogController(
    $scope,
    $timeout,
    $modalInstance,
    $filter,
    beneficiariesService,
    bankService,
    banks,
    wireIsoService
) {
    $scope.banks = banks;
    $scope.warnIntermediary = $modalInstance.warnIntermediary;
    $scope.selectBank = selectBank;
    $scope.close = close;
    $scope.filteredBanks = [];
    $scope.bankSearchText = '';

    $scope.$watch('bankSearchText', onBankSearchTextChange);

    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });

    init();

    function close() {
        $modalInstance.dismiss();
    }

    function selectBank(bank) {
        $modalInstance.close(bank);
    }

    function init() {}

    //
    // Events
    const filterFunction = function (item) {
        let val = $scope.bankSearchText;
        if (val) {
            val = val.toLowerCase();
        }
        return (
            (item.bankId !== null && item.bankId.toLowerCase().indexOf(val || '') !== -1) ||
            (item.name != null && item.name.toLowerCase().indexOf(val || '') !== -1) ||
            (item.addressLine1 !== null &&
                item.addressLine1.toLowerCase().indexOf(val || '') !== -1) ||
            (item.addressLine2 !== null &&
                item.addressLine2.toLowerCase().indexOf(val || '') !== -1) ||
            (item.postalCode !== null && item.postalCode.toLowerCase().indexOf(val || '') !== -1) ||
            (item.city !== null && item.city.toLowerCase().indexOf(val || '') !== -1) ||
            (item.state !== null && item.state.toLowerCase().indexOf(val || '') !== -1) ||
            (item.country !== null && item.country.toLowerCase().indexOf(val || '') !== -1)
        );
    };

    function onBankSearchTextChange(bankSearchText) {
        $scope.filteredBanks = $filter('filter')(
            $scope.banks,
            filterFunction,
            $scope.bankSearchText
        );
    }

    //
    // Private

    $scope.getCityStateZip = function (bank) {
        let cityStateZip = '';
        if (bank.city) {
            cityStateZip += bank.city;
            if (!!bank.state || !!bank.postalCode) {
                cityStateZip += ', ';
            }
        }
        if (bank.state) {
            cityStateZip += bank.state;
        }
        return cityStateZip;
    };
}
