// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

export default class AchInternationalRequests {
    /**
     *
     * @returns { Promise<import('@treasury/api/channel').GetInternationalAchCountriesResponse2Dto>}
     */
    static async getInternationalAchCountries() {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/GetInternationalAchCountries`,
            {
                method: 'GET',
                maxAgeInSeconds: 1000,
            }
        );
    }

    static async getInternationalAchPayment(paymentId) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/GetInternationalAchPayment?id=${paymentId}`,
            {
                method: 'GET',
            }
        );
    }

    /**
     *
     * @param { import('@treasury/api/channel').CreateInternationalAchPaymentRequestDto} body
     * @returns { Promise<import('@treasury/api/channel').CreateInternationalAchPaymentResponseDto>}
     */
    static async createInternationalAchPayment(body) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/CreateInternationalAchPayment`,
            {
                method: 'POST',
                body,
            }
        );
    }

    static async updateInternationalAchPayment(body, paymentId) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/InternationalAchPayment/${paymentId}`,
            {
                method: 'PUT',
                body,
            }
        );
    }

    static async uploadInternationalNachaFile(formData) {
        return (await TmHttpClient.getInstance()).request(`achPayments/uploadInternational`, {
            method: 'POST',
            body: formData,
            disableContentType: true,
        });
    }
}
