/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { ErrorHttpContentDto, NotificationConfigurationFeatureModelDto, ThresholdCollectionDto } from "../";
import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class AlertClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags SaveThresholdAlerts
   * @name SaveThresholdAlertsPost
   * @summary Saves threshold alert(s).
   * @request POST:/Alert/Thresholds/Save
   */
  saveThresholdAlertsPost = (thresholds: ThresholdCollectionDto, params: RequestParams = {}) =>
    this.http.request<ThresholdCollectionDto, ErrorHttpContentDto>({
      path: `/Alert/Thresholds/Save`,
      method: "POST",
      body: thresholds,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UpdateThresholdAlerts
   * @name UpdateThresholdAlertsPost
   * @summary Updates threshold alert(s).
   * @request POST:/Alert/Thresholds/Update
   */
  updateThresholdAlertsPost = (thresholds: ThresholdCollectionDto, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/Alert/Thresholds/Update`,
      method: "POST",
      body: thresholds,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AddThresholdAlerts
   * @name AddThresholdAlertsPost
   * @summary Adds threshold alert(s).
   * @request POST:/Alert/Thresholds/Add
   */
  addThresholdAlertsPost = (thresholds: ThresholdCollectionDto, params: RequestParams = {}) =>
    this.http.request<ThresholdCollectionDto, ErrorHttpContentDto>({
      path: `/Alert/Thresholds/Add`,
      method: "POST",
      body: thresholds,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeleteThresholdAlerts
   * @name DeleteThresholdAlertsPost
   * @summary Deletes threshold alert(s).
   * @request DELETE:/Alert/Thresholds/Delete
   */
  deleteThresholdAlertsPost = (thresholds: ThresholdCollectionDto, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorHttpContentDto>({
      path: `/Alert/Thresholds/Delete`,
      method: "DELETE",
      body: thresholds,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GetThresholdAlerts
   * @name GetThresholdAlertsPost
   * @summary Gets the user's threshold alerts.
   * @request GET:/Alert/Thresholds
   */
  getThresholdAlertsPost = (params: RequestParams = {}) =>
    this.http.request<ThresholdCollectionDto, ErrorHttpContentDto>({
      path: `/Alert/Thresholds`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GetThresholdAlert
   * @name GetThresholdAlertPost
   * @summary Gets a threshold alert.
   * @request GET:/Alert/Threshold/{thresholdId}
   */
  getThresholdAlertPost = (thresholdId: string, params: RequestParams = {}) =>
    this.http.request<NotificationConfigurationFeatureModelDto, ErrorHttpContentDto>({
      path: `/Alert/Threshold/${thresholdId}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
