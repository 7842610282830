import { html } from 'lit';

export function renderReportInfo(title: string) {
    return html`<div style="width: 335px">
        <p>
            <strong>${title}</strong> - Includes all issued items and the current item status.
            Utilize the search feature to review issued items previously submitted.
        </p>
    </div>`;
}
