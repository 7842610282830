/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-classes-per-file */
import { Recordset, RecordsetEvent, RecordsetEventCallback } from '@treasury/FDL';
import { LitPropertyVerifier } from '@treasury/utils/lit-helpers';
import { AbstractConstructor, Callback, Constructor } from '@treasury/utils/types';
import { LitElement } from 'lit';

type ListenerCallback<T> = Callback<CustomEvent<T>>;

export interface Listener<T> {
    target: EventTarget;
    event: string;
    fn: ListenerCallback<T>;
}

// eslint-disable-next-line @treasury/filename-match-export
export function ListeningElementMixin<
    BaseClass extends Constructor<LitElement> | AbstractConstructor<LitElement>,
>(Superclass: BaseClass) {
    /**
     * Provides functionality for `LitElement` instances to listen to an `EventTarget`
     * in a way that automatically cleans up subscribers on element destruction.
     */
    abstract class ListeningElement extends Superclass {
        private listeningTo: Listener<any>[] = [];

        protected listenTo<T>(target: EventTarget, event: string, fn: ListenerCallback<T>) {
            target.addEventListener(event, fn as EventListener);
            this.listeningTo.push({ target, event, fn });
        }

        protected listenToMulti<T>(
            target: EventTarget,
            eventNames: string[],
            fn: ListenerCallback<T>
        ) {
            eventNames.forEach(eventName => this.listenTo(target, eventName, fn));
        }

        /**
         * Syntax sugar method for getting strong typing on `Recordset` event names
         * and their corresponding payloads.
         */
        protected listenToRecordset<E extends RecordsetEvent, T, P>(
            target: Recordset<T, P>,
            event: E,
            fn: RecordsetEventCallback<E, T>
        ) {
            this.listenTo(target, event, fn);
        }

        public disconnectedCallback() {
            this.removeAllListeners();
            super.disconnectedCallback();
        }

        public stopListeningTo<T>(target: EventTarget, event: string, fn: ListenerCallback<T>) {
            const listeningObj = { target, event, fn };
            const listenerIndex = this.listeningTo.findIndex(listener => listener === listeningObj);
            if (listenerIndex) {
                target.removeEventListener(event, fn as EventListener);
                this.listeningTo.splice(listenerIndex, 1);
            }
        }

        public removeAllListeners() {
            this.listeningTo.forEach(({ target, event, fn }) => {
                target.removeEventListener(event, fn as EventListener);
            });

            this.listeningTo = [];
        }
    }

    return ListeningElement;
}

/**
 * Alternative to `ListeningElementMixin` where inheritance is preferred.
 *
 * Exactly duplicates the code used in the mixin class.
 */
export abstract class ListeningElement extends LitPropertyVerifier {
    private listeningTo: Listener<any>[] = [];

    protected listenTo<T>(target: EventTarget, event: string, fn: ListenerCallback<T>) {
        target.addEventListener(event, fn as EventListener);
        this.listeningTo.push({ target, event, fn });
    }

    protected listenToMulti<T>(target: EventTarget, eventNames: string[], fn: ListenerCallback<T>) {
        eventNames.forEach(eventName => this.listenTo(target, eventName, fn));
    }

    /**
     * Syntax sugar method for getting strong typing on `Recordset` event names
     * and their corresponding payloads.
     */
    protected listenToRecordset<E extends RecordsetEvent, T, P>(
        target: Recordset<T, P>,
        event: E,
        fn: RecordsetEventCallback<E, T>
    ) {
        this.listenTo(target, event, fn);
    }

    public disconnectedCallback() {
        this.removeAllListeners();
        super.disconnectedCallback();
    }

    public stopListeningTo<T>(target: EventTarget, event: string, fn: ListenerCallback<T>) {
        const listeningObj = { target, event, fn };
        const listenerIndex = this.listeningTo.findIndex(listener => listener === listeningObj);
        if (listenerIndex) {
            target.removeEventListener(event, fn as EventListener);
            this.listeningTo.splice(listenerIndex, 1);
        }
    }

    public removeAllListeners() {
        this.listeningTo.forEach(({ target, event, fn }) => {
            target.removeEventListener(event, fn as EventListener);
        });

        this.listeningTo = [];
    }
}
