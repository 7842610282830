import { TmHttpClient } from '@treasury/core/http';

interface CorpaySsoResponse {
    content: string;
    errorMessages: string[];
}

export default class CorpaySsoRequest {
    public static async getCorpaySsoUrl() {
        return (await TmHttpClient.getInstance()).request<CorpaySsoResponse>(`wires/corpay/sso`);
    }
}
