import { UserTimeAccess2Dto, UserTimeAccessDayDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { isEdited } from '../helpers/is-edited';
import { nullUserTimeAccess } from './null-user-time-access';

enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export type UserAccessDay = {
    label: string;
    value: DayOfWeek;
    startTime: { value: string; edited: boolean };
    endTime: { value: string; edited: boolean };
    timeAccessType: { value: number; edited: boolean };
};

export class UserTimeAccess extends DomainEntity<UserTimeAccess2Dto> {
    constructor(dto: UserTimeAccess2Dto) {
        super(dto);
    }

    public createDefault() {
        return new UserTimeAccess(nullUserTimeAccess as unknown as UserTimeAccess2Dto) as this;
    }

    public get isRestricted() {
        return this.dto.timeRestricted;
    }

    public get accessDays() {
        return this.dto.userTimeAccessDays?.map(day =>
            buildAccessDay(day, this.dto.userTimeAccessDaysPending)
        );
    }
}

function buildAccessDay(
    day: UserTimeAccessDayDto,
    userTimeAccessDaysPending?: UserTimeAccessDayDto[]
) {
    const { dayOfWeek } = day;
    const beginTime = userTimeAccessDaysPending
        ? userTimeAccessDaysPending[dayOfWeek].beginTime
        : undefined;
    const endTime = userTimeAccessDaysPending
        ? userTimeAccessDaysPending[dayOfWeek].endTime
        : undefined;
    const timeAccessType = userTimeAccessDaysPending
        ? userTimeAccessDaysPending[dayOfWeek].timeAccessType
        : undefined;

    return {
        label: DayOfWeek[dayOfWeek],
        value: day.dayOfWeek,
        startTime: {
            value: day.beginTime,
            edited: isEdited(day.beginTime, beginTime),
        },
        endTime: {
            value: day.endTime,
            edited: isEdited(day.endTime, endTime),
        },
        timeAccessType: {
            value: day.timeAccessType,
            edited: isEdited(day.timeAccessType, timeAccessType),
        },
    };
}
