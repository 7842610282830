CreateBatchController.$inject = [
    '$scope',
    '$rootScope',
    '$modal',
    '$state',
    '$location',
    'modalService',
    'entitlementsService',
    'achBatchService',
    'achCompaniesService',
    'achSettingsService',
    'achBankService',
    '$stateParams',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'regexConstants',
    'securityService',
    'achPaymentTypes',
    'recipientValidatorService',
    '$q',
    '$timeout',
];

export default function CreateBatchController(
    $scope,
    $rootScope,
    $modal,
    $state,
    $location,
    modalService,
    entitlementsService,
    achBatchService,
    achCompaniesService,
    achSettingsService,
    achBankService,
    $stateParams,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    regexConstants,
    securityService,
    achPaymentTypes,
    recipientValidatorService,
    $q,
    $timeout
) {
    const createFromBatchState = 'payables.ach.payments.createFromBatch';
    const createFromTaxTemplateState = 'payables.ach.payments.createFromTaxTemplate';
    const batchListState = 'payables.ach.batch-list';
    const taxTemplateListState = 'payables.ach.payments.tax-templates';
    const createBatchState = 'payables.ach.batch.create';
    const createTaxTemplateState = 'payables.ach.batch.createTaxTemplate';
    const cloneBatchState = 'payables.ach.batch.createClonedBatch';
    const cloneTaxTemplateState = 'payables.ach.batch.createClonedTaxTemplate';

    $scope.wizardController = {};
    $scope.form = {};
    $scope.achCompanies = [];
    $scope.nonIATAchCompanies = [];
    $scope.hasNonIatAchCompanies = false;
    $scope.secCodes = [];
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };

    $scope.alphanumericRegex = regexConstants.AlphaNumericRegex;
    $scope.numericRegex = /^\d+$/;

    $scope.resetErrors = resetErrors;
    $scope.confirmCancel = confirmCancel;
    $scope.uploadNacha = uploadNacha;
    $scope.findAchCompany = findAchCompany;
    $scope.findCCDAchCompany = findCCDAchCompany;
    $scope.validateAchCompany = validateAchCompany;
    $scope.validateTemplateName = validateTemplateName;
    $scope.filterRecipients = filterRecipients;
    $scope.calculateAmounts = calculateAmounts;
    $scope.showAddendaModal = showAddendaModal;
    $scope.create = createBatch;
    $scope.getRecipientErrors = getRecipientErrors;
    $scope.print = print;
    $scope.initiatePayment = initiatePayment;
    $scope.back = back;
    $scope.downloadPageId = downloadPageId.CreateAchBatchConfirmation;
    $scope.entryType = { selected: 'M' };
    $scope.recipientsTableController = {};
    $scope.review = review;
    $scope.canInitiate = true;
    $scope.canSaveRecipients = entitlementsService.hasEntitlement('Create Recipient');
    $scope.showEntryTypeOptions = true;
    $scope.counts = {
        prenote: 0,
        hold: 0,
    };
    $scope.secCodeOptions = [];
    $scope.getSecCodes = getSecCodes;
    $scope.$watch('batch.achCompany', getSecCodes);
    $scope.allowOnUsAccessManagementEntitlement = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowOnUsAccessManagement'
    );
    $scope.masterRecipients = $state.params.masterRecipients;
    $scope.createAnotherTemplate = createAnotherTemplate;

    function isBatchCreate() {
        return $state.current.name === createBatchState || $state.current.name === cloneBatchState;
    }

    function isTaxTemplateCreate() {
        return (
            $state.current.name === createTaxTemplateState ||
            $state.current.name === cloneTaxTemplateState
        );
    }

    function recipientHasValue(recipient) {
        return !(
            isNullOrEmpty(recipient.name) &&
            isNullOrEmpty(recipient.idNumber) &&
            isNullOrEmpty(recipient.accountNumber) &&
            isNullOrEmpty(recipient.routingNumber) &&
            recipient.amount === 0 &&
            (recipient.addenda == null || isNullOrEmpty(recipient.addenda[0].value)) &&
            recipient.accountType === 'Checking' &&
            recipient.transactionType === 'CR' &&
            !recipient.hold &&
            !recipient.prenote
        );
    }

    function isNullOrEmpty(stringValue) {
        return stringValue == null || stringValue.length === 0;
    }

    function init() {
        if ($state.params.template) {
            $scope.batch = $state.params.template;
            $scope.batch.isTemplate = true;
            if ($state.params.masterRecipients) {
                $scope.batch.recipients = $scope.batch.recipients.filter(recipientHasValue);
                $scope.batch.recipients = $scope.batch.recipients.concat(
                    $state.params.masterRecipients
                );
            }
            const goToManageRecipients = function () {
                if ($scope.wizardController.goToStep) {
                    $scope.wizardController.goToStep(1);
                } else {
                    $timeout(goToManageRecipients);
                }
            };
            goToManageRecipients();
        } else {
            $scope.batch = new achBatchService.Batch({
                isTemplate: true,
            });
        }
        $scope.url = $location.$$absUrl;
        $scope.createFreeFormBatch = $scope.url.indexOf('create-new-batch') > -1;
        $scope.isNewBatch =
            $state.current.name.indexOf('batch.create') !== -1 || $stateParams.cloneId;
        $scope.hasFullEdit = true; // for a batch - they can always modify header information
        $scope.name = $scope.batch.name;

        getAchBanks();
        getAchCompanies();
        getAccountTypes();
        getTransactionTypes();
        getAchSettings();

        activityService.userInteractionAudit(
            resourceType.PaymentResources,
            auditCode.AccessCreateAchBatch
        );
        $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
        $scope.$watch(
            () => $scope.batch.achCompanyName,
            data => {
                if (data && $scope.batch.achCompany.companyName !== data) {
                    if (typeof data === 'object') {
                        setAchCompanyAttributes(data);
                    } else {
                        angular.forEach($scope.achCompanies, item => {
                            if (data === item.companyName) {
                                setAchCompanyAttributes(item);
                            }
                        });
                        if (!validateAchCompany()) {
                            setAchCompanyAttributes({
                                companyName: data,
                            });
                        }
                    }
                }
            }
        );

        $scope.$watch(
            () => $scope.batch.recipients.length,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    calculateAmounts();
                }
            }
        );
    }

    function validateTemplateName() {
        if ($scope.batch.name == undefined) return;

        $scope.form.name.$setValidity('unique', true);
        if ($scope.batch.name !== $scope.name) {
            achBatchService.validateTemplateName($scope.batch.name).then(resp => {
                $scope.form.name.$setValidity('unique', !!resp.isValid);
                $scope.name = $scope.batch.name;
            });
        }
    }

    function getAchBanks() {
        achBankService.getAllBanks().then(response => {
            $rootScope.banks = response;
        });
    }

    function back() {
        $scope.batch.errorMessage = '';
        $scope.wizardController.goToPreviousStep();
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.batch,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {
            recipient.addenda = data;
        });
    }

    function resetErrors() {
        $scope.form.$setValidity('onUsTransactionsRequired', true);
    }

    function getAchSettings() {
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function uploadNacha() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelCreateAchBatch
                    );
                    $modalInstance.close();
                    $state.go('payables.ach.batch.uploadBatch');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'M';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateAchBatch
            );
            $state.go('payables.ach.batch.uploadBatch');
        }
    }

    function confirmCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelCreateAchBatch
                    );
                    $modalInstance.close();

                    if (isBatchCreate()) $state.go(batchListState);
                    if (isTaxTemplateCreate()) $state.go(taxTemplateListState);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateAchBatch
            );

            if (isBatchCreate()) $state.go(batchListState);
            if (isTaxTemplateCreate()) $state.go(taxTemplateListState);
        }
    }

    function getAchCompanies() {
        achCompaniesService.getAll().then(data => {
            $scope.achCompanies = data;
            $scope.nonIATAchCompanies = achCompaniesService.getNonIATAchCompanies(
                $scope.achCompanies
            );
            $scope.ccdAchCompanies = achCompaniesService.getCCDAchCompanies($scope.achCompanies);
            if ($stateParams.cloneId) {
                achBatchService.cloneBatch($stateParams.cloneId).then(response => {
                    $scope.batch = response.batch;
                    $scope.id = response.batch.id;
                    $scope.batch.name = ''; // batch name should to default to empty when cloning
                });
            }
        });
    }

    function getAchCompanies() {
        $q((resolve, reject) => {
            achCompaniesService.getAll().catch(reject).then(resolve);
        }).then(data => {
            $scope.achCompanies = data;
            $scope.nonIATAchCompanies = achCompaniesService.getNonIATAchCompanies(
                $scope.achCompanies
            );
            $scope.hasNonIatAchCompanies = !!$scope.nonIATAchCompanies.length;
            $scope.ccdAchCompanies = achCompaniesService.getCCDAchCompanies($scope.achCompanies);
            if ($stateParams.cloneId) {
                achBatchService.cloneBatch($stateParams.cloneId).then(response => {
                    $scope.batch = response.batch;
                    $scope.id = response.batch.id;
                    $scope.batch.name = ''; // batch name should to default to empty when cloning
                });
            }
        });
    }

    function getSecCodes() {
        $scope.secCodeOptions = $scope.batch.achCompany.secCodes.filter(
            code => code.code !== 'IAT'
        );
    }

    function getAccountTypes() {
        $scope.accountTypes = achBatchService.getAccountTypes();
    }

    function getTransactionTypes() {
        $scope.transactionTypes = achBatchService.getTransactionTypes();
    }

    function findAchCompany() {
        const modalInstance = $modal.open({
            template: require('../views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.nonIATAchCompanies;
                },
            },
        });

        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }

    function findCCDAchCompany() {
        const modalInstance = $modal.open({
            template: require('../views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.ccdAchCompanies;
                },
            },
        });

        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }

    function validateAchCompany() {
        return (
            $scope.achCompanies.filter(
                item =>
                    item.companyName === $scope.batch.achCompanyName &&
                    item.id === $scope.batch.achCompany.id
            ).length > 0
        );
    }

    function filterRecipients() {
        if ($scope.batch !== undefined && $scope.batch.recipients !== undefined) {
            let filteredArray = $scope.batch.recipients.filter(recipient => {
                if (recipient.isDeleted) {
                    return false;
                }

                return (
                    $scope.searchFilters.text === '' ||
                    (recipient.name &&
                        recipient.name
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase() !== -1)) ||
                    (recipient.idNumber &&
                        recipient.idNumber
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    (recipient.accountNumber &&
                        recipient.accountNumber
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1)
                );
            });

            if ($scope.searchFilters.prenote) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }

            if ($scope.searchFilters.hold) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.hold && recipient.hold
                );
            }
            $scope.filteredRecipients = filteredArray;
            return filteredArray;
        }
    }

    function calculateAmounts() {
        const amounts = achBatchService.calculateAmounts($scope.batch.recipients);
        $scope.batch.debitAmount = amounts.debit;
        $scope.batch.creditAmount = amounts.credit;
        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    }

    function review() {
        $scope.wizardController.goToNextStep();
        calculateAmounts();
        filterRecipients();
    }

    function createBatch() {
        securityService
            .verifyUser('', $scope.batch, () => achBatchService.create($scope.batch))
            .then(response => {
                if (response.errorMessage && response.errorMessage !== '') {
                    $scope.batch.errorMessage = response.errorMessage;
                } else {
                    $scope.batch.id = response.batch.id;
                    $scope.id = response.batch.id;
                    $scope.batch.successMessage = response.successMessage;
                    $scope.batch.numberOfApprovalsNeeded = response.batch.numberOfApprovalsNeeded;
                    $scope.batch.audit = response.batch.audit;
                    $scope.wizardController.goToNextStep();
                    $scope.canInitiate =
                        response.batch.status !== 'Pending Approval' &&
                        entitlementsService.hasEntitlement('ACH, Batch, CreatePayment');
                }
            });
    }

    function createAnotherTemplate() {
        $state.go(
            'payables.ach.batch.create',
            { masterRecipients: null, template: null },
            { reload: true }
        );
    }

    function print() {
        $window.print();
    }

    function setAchCompanyAttributes(data) {
        $scope.batch.achCompanyName = data.companyName || '';
        $scope.batch.achCompanyId = data.companyId || '';
        $scope.batch.secCode = getSecCode(data);
        $scope.secCodes = data.secCodes || [];
        $scope.batch.entryDescription = data.entryDescription || '';
        $scope.batch.discretionaryData = data.discretionaryData || '';
        $scope.batch.achCompany = data;
        setOnUsDefaultTransactionType();
    }

    function setOnUsDefaultTransactionType() {
        const notOnUsTransactionType = $scope.batch?.achCompany?.notOnUsTransactionTypes;
        const isTransactionTypeCrDrOnly =
            notOnUsTransactionType === 'Debit Only' || notOnUsTransactionType === 'Credit Only';
        if (
            $scope.allowOnUsAccessManagementEntitlement &&
            $scope.achSettings?.allowOnUsAccessManagement &&
            isTransactionTypeCrDrOnly &&
            $scope.batch.recipients[0]?.default === true
        ) {
            if (notOnUsTransactionType === 'Debit Only')
                $scope.batch.recipients[0].transactionType = 'DR';
            if (notOnUsTransactionType === 'Credit Only')
                $scope.batch.recipients[0].transactionType = 'CR';
        }
    }

    function getSecCode(company) {
        const nonIatCodes = company.secCodes.filter(code => code.code !== 'IAT');
        switch ($scope.batch.achPaymentTypeId) {
            case achPaymentTypes.AchStateTaxPayment:
            case achPaymentTypes.AchFederalTaxPayment:
                return hasCcdCode(company) ? 'CCD' : '';
            default:
                return hasSecCodes(company) ? nonIatCodes[0].code : '';
        }
    }

    function hasCcdCode(company) {
        if (!hasSecCodes(company)) return false;

        return company.secCodes.some(item => item.code === 'CCD');
    }

    function hasSecCodes(company) {
        return company.secCodes && company.secCodes.length;
    }

    function getRecipientErrors() {
        const errors = [];
        const invalidRecipientCount = recipientValidatorService(
            $scope.batch.recipients,
            $scope.batch.achCompany
        ).countErrors();

        if (invalidRecipientCount > 0) {
            if (invalidRecipientCount === 1) {
                errors.push(`${invalidRecipientCount} recipient has one or more invalid fields.`);
            } else {
                errors.push(`${invalidRecipientCount} recipients have one or more invalid fields.`);
            }
        }

        if ($scope.batch.entryDescription !== undefined && $scope.batch.entryDescription.$invalid) {
            errors.push(
                'Entry description is a required field.  If uploading a file, the entry description in the UI must match the entry description in the file being uploaded.'
            );
        }

        for (const type in $scope.form.$error) {
            switch (type) {
                case 'onUsTransactionsRequired':
                    errors.push(
                        `At least ${$scope.batch.achCompany.onUsTransactionsRequired} recipients are required to have "On Us" routing numbers.`
                    );
                    break;
                case 'batchBalanceRequirements':
                    if ($scope.batch.achCompany.batchBalanceRequirements === 'Balanced') {
                        errors.push('Debit amount total must be equal to Credit amount total.');
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Full Offset'
                    ) {
                        errors.push(
                            `Transaction type must be set to "${$scope.batch.recipients[0].transactionType}" on all recipients.`
                        );
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Partial Offset'
                    ) {
                        errors.push(
                            'Debit and Credit amounts must be greater than zero and different from one another.'
                        );
                    }
                    break;
            }
        }

        return errors;
    }

    function initiatePayment() {
        achBatchService.bulkInitiate([{ key: $scope.batch.id, value: '' }]).then(response => {
            $scope.setSelectedBatches(response);
            if (isBatchCreate()) $state.go(createFromBatchState);
            if (isTaxTemplateCreate()) $state.go(createFromTaxTemplateState);
        });
    }

    init();
}
