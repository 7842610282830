/* eslint-disable no-restricted-syntax */
import { clone, exists } from '@jack-henry/frontend-utils/functions';
import { Nullable } from '@treasury/utils';
import { DomainEntity } from '../../../shared/types';
import { DateFormatters } from '../../../shared/utilities/date-formatters';
import { IssuedItemLegacyDto, nullAccount } from '../../types/arp';
import { IIssuedItem } from './index';
import { IssuedItem } from './issued-items.entity';
import {
    compareIssuedItems,
    copyIssuedItem,
    IssuedItemPendingStatus,
    IssuedItemUiState,
} from './issued-items.mappings';

/**
 * @deprecated
 *
 * Represents an issued item entity hydrated from the original `IssuedItemLegacyDto` shape.
 */
export class IssuedItemLegacy
    extends DomainEntity<Nullable<IssuedItemLegacyDto>>
    implements IIssuedItem
{
    public pendingStatus = IssuedItemPendingStatus.None;

    public dateFinalized = undefined;

    /**
     * Instances of `IssuedItemLegacy` will only exist in the activity view,
     * so void eligibility should never `true`.
     */
    public readonly voidEligible = false;

    /**
     * Instances of `IssuedItemLegacy` will only exist in the activity view,
     * so they will never have an error state`.
     */
    public readonly errorState = IssuedItemUiState.None;

    public readonly voidedDate = this.dto.voidDate ? new Date(this.dto.voidDate) : undefined;

    private _createdDate = this.dto.createdDate ? new Date(this.dto.createdDate) : undefined;

    private _dateIssued = this.dto.dateIssued ? new Date(this.dto.dateIssued) : undefined;

    public get auditItems() {
        return exists(this.dto.auditItems) ? this.dto.auditItems : [];
    }

    public get createdDate() {
        return this._createdDate;
    }

    public set createdDate(date) {
        this._createdDate = date;
        if (!exists(date)) {
            this.dto.createdDate = null;
            return;
        }

        this.dto.createdDate = DateFormatters.apiFormattedDate(date);
    }

    public get clearedDate() {
        return this.dto.clearedDate ? new Date(this.dto.clearedDate) : undefined;
    }

    public get dateIssued() {
        return this._dateIssued || undefined;
    }

    public set dateIssued(date) {
        this._dateIssued = date;
        if (!exists(date)) {
            this.dto.dateIssued = null;
            return;
        }

        const formatted = DateFormatters.apiFormattedDates([date as Date]).value;
        this.dto.dateIssued = typeof formatted === 'string' ? formatted : formatted.start;
    }

    public get postDate() {
        return this.dto.postDate ? new Date(this.dto.postDate) : undefined;
    }

    public get checkAmount() {
        return this.dto.checkAmount || 0;
    }

    public set checkAmount(amount) {
        this.dto.checkAmount = amount;
    }

    public get payee() {
        return this.dto.payee || '';
    }

    public set payee(payeeName) {
        this.dto.payee = payeeName;
    }

    public get checkNumber() {
        return this.dto.checkNumber || undefined;
    }

    public set checkNumber(number) {
        this.dto.checkNumber = number ?? null;
    }

    public get itemStatus() {
        return this.dto.itemStatus || '';
    }

    public set itemStatus(status) {
        this.dto.itemStatus = status === '' ? null : status;
    }

    public get type() {
        return this.dto.type || undefined;
    }

    public set type(itemType) {
        this.dto.type = itemType ?? null;
    }

    public get itemEntryType() {
        return this.dto.entryType || 'Other';
    }

    public set itemEntryType(entryType) {
        this.dto.entryType = entryType || null;
    }

    public get accountName() {
        return this.account.name || this.account.accountDisplayLabel || '';
    }

    public get account() {
        if (!exists(this.dto.account)) {
            this.dto.account = clone(nullAccount);
        }

        return this.dto.account;
    }

    public set account(account) {
        this.dto.account = account;
    }

    public copyFrom(other: IIssuedItem) {
        if (other instanceof IssuedItemLegacy) {
            this.dto = other.dto;
        }

        // invoke this function to ensure setter logic is called
        copyIssuedItem(other, this);
    }

    public createDefault() {
        return new IssuedItemLegacy({} as IssuedItemLegacyDto) as this;
    }

    public compare(other: IIssuedItem): boolean {
        if (other instanceof IssuedItemLegacy && other.dto === this.dto) {
            return true;
        }

        return compareIssuedItems(this, other);
    }

    /**
     * Convert this instance of `IssuedItemLegacy` to `IssuedItem`.
     */
    public convertFromLegacy(): IssuedItem {
        const convertedItem = new IssuedItem();

        copyIssuedItem(this, convertedItem);
        return convertedItem;
    }
}
