// cSpell:ignore achbanks
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping API calls associated with ACH companies.
 */
class AchCompanyRequests {
    /**
     * @returns { Promise<import('../../types/ach').AchCompany[]>}
     */
    static async getAchCompanies() {
        return (await TmHttpClient.getInstance()).request(`achCompanies`, {
            method: 'GET',
            maxAgeInSeconds: 6000000,
        });
    }

    // TODO: use when this endpoint is available
    static async getAchCompany(id) {
        return (await TmHttpClient.getInstance()).request(`achCompanies/${id}`, {
            method: 'GET',
            ttl: 6000,
        });
    }

    /**
     * @returns { Promise<import('@treasury/api/channel').BankModelDto[]>}
     */
    static async getAchBanks() {
        return (await TmHttpClient.getInstance()).request(`achbanks`, {
            method: 'GET',
            maxAgeInSeconds: 600000,
        });
    }

    static async getAchCompanySecCodes(id) {
        return (await TmHttpClient.getInstance()).request(`achCompanies/${id}/secCodes`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async getAchCompanyOffsetAccounts(id) {
        return (await TmHttpClient.getInstance()).request(`achCompanies/${id}/offsetAccounts`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async getAchSettings() {
        return (await TmHttpClient.getInstance()).request(`achSettings`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }
}

export default AchCompanyRequests;
