import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';

interface LastLoginDto {
    lastFailedLogin: string;
    lastFailedLoginIPAddress: string;
    lastSuccessfulLogin: string;
    lastSuccessfulLoginIPAddress: string;
}

@Injectable()
export class LoginService {
    constructor(private readonly http: TmHttpClient) {}

    public async getLastLogin() {
        const response = await this.http.request<LastLoginDto>('security/recentLogins', {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });

        return response.lastSuccessfulLogin;
    }
}
