import { DiContainer, Injectable } from '@jack-henry/frontend-utils/di';
import { ObservationSource } from '@jack-henry/frontend-utils/observable';
import {
    AuthenticationClient,
    SsoViaUisResponseDto,
    UisLoginUrlResponseDto,
} from '@treasury/api/channel';
import { AppType, ConfigurationService } from '@treasury/core/config';
import { AUTH_TOKEN } from '@treasury/core/http';
import { LoggingService } from '@treasury/core/logging';
import { exists, SessionStorageService } from '@treasury/utils';
import { AccountService } from '../../channel/services/account/account.service';
import { Feature } from '../feature-flags';
import {
    legacyBoUserStorageKey,
    SetUserPayload,
    TmUser,
    tmUserStorageKey,
    UserLoginData,
} from './authentication.service.types';

export type UisStorage = UisLoginUrlResponseDto & { institutionId: string };

/**
 * Future home of all things related to authenticating the current user.
 */
@Injectable()
export class AuthenticationService {
    constructor(
        private readonly authenticationClient: AuthenticationClient,
        private readonly channelAccountService: AccountService,
        private readonly config: ConfigurationService,
        private readonly sessionStorageService: SessionStorageService,
        private readonly logger: LoggingService
    ) {
        this.authenticated$.subscribe(() => {
            this._authenticated = true;
        });
    }

    public static async getInstance() {
        const container = await DiContainer.getInstance();
        return container.get(AuthenticationService);
    }

    // Get the UIS metadata for the current institution
    // Pulls from storage first and if not available it pulls from the APO
    public readonly uisMetadataPromise =
        this.config.app !== AppType.BackOffice
            ? this.getUisMetadata()
            : Promise.resolve<void>(undefined);

    // Get the UIS metadata for the current institution
    // Forces a pull from the API and never pulls from storage
    public readonly uisForcedMetadataPromise =
        this.config.app !== AppType.BackOffice
            ? this.getUisMetadata(true)
            : Promise.resolve<void>(undefined);

    private readonly authStream = new ObservationSource<TmUser>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly authenticated$ = this.authStream.toObservable();

    private authPromise?: Promise<TmUser>;

    private _authenticated = false;

    public get authenticated() {
        return this._authenticated;
    }

    public async authenticate() {
        if (this.authPromise) {
            return this.authPromise;
        }
        if (this.hasToken()) {
            return (this.authPromise = this.authenticateUser());
        }

        return (this.authPromise = this.authenticateUser());
    }

    public invalidate() {
        this._authenticated = false;
        this.authPromise = undefined;
    }

    public async authenticateSelectedCompanyUser(userCompanyUniqueId: string) {
        const resp = await this.authenticationClient.authenticationCompanyLogin({
            userCompanyUniqueId,
        });

        return resp.data;
    }

    public async authenticationSsoViaUis(authorizationCode: string, state: string) {
        const { institutionId, uisRedirectUrl } = this.config;
        const resp = await this.authenticationClient.authenticationSsoViaUis({
            fiId: institutionId,
            authorizationCode,
            state,
            isEnrollment: false,
            isMobile: false,
            returnUrl: uisRedirectUrl,
        });
        return resp.data as SsoViaUisResponseDto;
    }

    public async setUser(user: SetUserPayload) {
        this.sessionStorageService.set<SetUserPayload>('tm-user', user);
    }

    private async authenticateUser() {
        const user = await this.getCurrentUser();
        this._authenticated = true;
        this.authStream.emit(user);
        return this.authenticated$.toPromise(true);
    }

    private hasToken() {
        const token = this.sessionStorageService.get<string>(AUTH_TOKEN);
        return !!token && token?.trim().length > 0;
    }

    private async getUisMetadata(force = false) {
        const uisStorageValue: UisStorage | boolean | undefined = this.sessionStorageService.get(
            Feature.UisEnabled
        );
        const usesDeprecatedStorageType = typeof uisStorageValue === 'boolean';

        // if the institutionId has changed, we need to fetch the metadata again
        const institutionChanged =
            (uisStorageValue as UisStorage)?.institutionId !== this.config.institutionId;

        if (
            exists(uisStorageValue) &&
            !usesDeprecatedStorageType &&
            !institutionChanged &&
            !force
        ) {
            return uisStorageValue;
        }

        // metadata is not in storage or is invalid, so we need to fetch it
        const { institutionId, uisRedirectUrl, app } = this.config;
        let uisMetadata: UisStorage;

        // FAILSAFE: If the call to check if UIS is not available, fall back to legacy auth
        try {
            const respData = (
                await this.authenticationClient.authenticationUisLoginUrl({
                    fiId: institutionId,
                    isMobile: app === AppType.Pwa,
                    returnUrl: uisRedirectUrl,
                })
            ).data;
            uisMetadata = { ...respData, institutionId: this.config.institutionId };
        } catch {
            this.logger.logError(`Error fetching UIS metadata for: ${this.config.institutionId}.`);
            uisMetadata = {
                isUisEnabled: false,
                institutionId: this.config.institutionId,
            };
        }
        this.sessionStorageService.set(Feature.UisEnabled, uisMetadata);
        return uisMetadata;
    }

    private getBoUser() {
        // First look for the user set via the AuthenticationService
        let user = this.sessionStorageService.get<SetUserPayload>(tmUserStorageKey);

        // Fall back to PagePushService key for legacy BackOffice users
        if (!user) {
            user = this.sessionStorageService.get<UserLoginData>(legacyBoUserStorageKey);
        }

        if (!user) {
            throw new Error('No current user found in memory or in storage.');
        }

        // For now there is no straight-forward way to get the userId for the current user,
        // which we need to determine if they are an admin
        // At time of writing, the isAdmin value for all backoffice users appears to be false
        // This may need to be updated if that changes in the future
        return { ...user, isAdmin: false };
    }

    private async getCurrentUser() {
        const { loginId, companyId, isAdmin } =
            this.config.app === AppType.BackOffice
                ? this.getBoUser()
                : await this.channelAccountService.getCurrentUser();

        return {
            loginId,
            companyId,
            isAdmin,
        } as TmUser;
    }
}
