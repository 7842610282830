/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { ErrorResponseModelDto, NavigationModelDto } from "../";
import { HttpClient, RequestParams } from "./http-client";

@Injectable()
export class NavigationsClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags Navigations
   * @name NavigationsGet
   * @summary Returns the navigation links.
   * @request GET:/navigations
   */
  navigationsGet = (params: RequestParams = {}) =>
    this.http.request<NavigationModelDto[], ErrorResponseModelDto>({
      path: `/navigations`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Navigations
   * @name NavigationsAuditUserActivity
   * @summary Audits the user activity.
   * @request POST:/navigations/auditUserActivity/{navigationKey}
   */
  navigationsAuditUserActivity = (navigationKey: string, params: RequestParams = {}) =>
    this.http.request<boolean, ErrorResponseModelDto>({
      path: `/navigations/auditUserActivity/${navigationKey}`,
      method: "POST",
      format: "json",
      ...params,
    });
}
