/* eslint-disable import/extensions */
import { JhHttpResponseType } from '@jack-henry/frontend-utils/http';
import { TmHttpClient } from '@treasury/core/http';

export default class AchFilterRequests {
    static async search(body) {
        return (await TmHttpClient.getInstance()).request(`achFilters/search`, {
            body,
            method: `POST`,
        });
    }

    static async download(body) {
        return (await TmHttpClient.getInstance()).request(`AchFilterReportDownload`, {
            body,
            method: 'POST',
            responseType: JhHttpResponseType.ArrayBuffer,
        });
    }

    // FIXME: Remove this call
    static async getAccounts() {
        return (await TmHttpClient.getInstance()).request(`achFilters/accounts`, {
            method: 'GET',
            maxAgeInSeconds: 30,
        });
    }

    static async getAccountsAll() {
        return (await TmHttpClient.getInstance()).request(`achFilters/all/accounts`, {
            method: 'GET',
            maxAgeInSeconds: 30,
        });
    }

    static async getAccountsCreate() {
        return (await TmHttpClient.getInstance()).request(`achFilters/create/accounts`, {
            method: 'GET',
            maxAgeInSeconds: 30,
        });
    }

    static async getAccountMasterSettings(accountUniqueId) {
        return (await TmHttpClient.getInstance()).request(
            `achFilters/account/${accountUniqueId}/masterSettings`,
            {
                method: 'GET',
                maxAgeInSeconds: 300,
            }
        );
    }

    static async getAuditLog(AchFilterUniqueId) {
        return (await TmHttpClient.getInstance()).request('achFilters/auditHistory', {
            body: {
                AchFilterUniqueIds: [AchFilterUniqueId],
            },
            method: 'POST',
        });
    }

    static async saveFilter(body) {
        return (await TmHttpClient.getInstance()).request('achFilters/save', {
            method: 'POST',
            body,
        });
    }

    static async findAchExceptions(body) {
        return (await TmHttpClient.getInstance()).request('arp/findAchExceptions', {
            method: 'POST',
            body,
        });
    }

    static async approveFilter(AchFilterUniqueIds) {
        return (await TmHttpClient.getInstance()).request('achFilters/approve', {
            body: {
                AchFilterUniqueIds: [...AchFilterUniqueIds],
            },
            method: 'POST',
        });
    }

    static async deleteFilter(AchFilterUniqueId) {
        return (await TmHttpClient.getInstance()).request(`achFilters/delete`, {
            method: 'POST',
            body: {
                AchFilterUniqueIds: [AchFilterUniqueId],
            },
        });
    }

    static async rejectFilter(AchFilterUniqueIds) {
        return (await TmHttpClient.getInstance()).request('achFilters/reject', {
            body: {
                AchFilterIds: [...AchFilterUniqueIds],
            },
            method: 'POST',
        });
    }

    static async findEligibleApprovers(AchFilterUniqueId) {
        return (await TmHttpClient.getInstance()).request(
            `achFilters/eligibleApprovers/${AchFilterUniqueId}`,
            {
                maxAgeInSeconds: 5000,
            }
        );
    }
}
