export enum StatusCode {
    Allow = 'Allow',
    Invalid = 'Invalid',
    Enrollment = 'Enrollment',
    ChallengeQuestions = 'ChallengeQuestions',
    Register = 'Register',
    Challenge = 'Challenge',
    ForgotPassword = 'ForgotPassword',
    ResetPassword = 'ResetPassword',
    ChallengeResetPassword = 'ChallengeResetPassword',
    ChallengeResetExpiredPassword = 'ChallengeResetExpiredPassword',
    Locked = 'Locked',
    Loading = 'Loading',
    ResetExpiredPassword = 'ResetExpiredPassword',
    ResetPasswordEnrollment = 'ResetPasswordEnrollment',
    UserVerification = 'UserVerification',
    RegisterOutOfBand = 'RegisterOutOfBand',
    TermsAndConditions = 'TermsAndConditions',
    PromptToResetPassword = 'PromptToResetPassword',
    ChallengePromptToResetPassword = 'ChallengePromptToResetPassword',
    PromptToResetPasswordEnrollment = 'PromptToResetPasswordEnrollment',
    OobLegacy = 'OobLegacy',
    OobOtp = 'OobOtp',
    RegisterSecureToken = 'RegisterSecureToken',
    TwoFactorChallenge = 'TwoFactorChallenge',
    ResetExpiredPasswordEnrollment = 'ResetExpiredPasswordEnrollment',
    Login = 'Login',
    Unknown = 'Unknown',
    UserSelection = 'UserSelection',
    Blocked = 'Blocked',
    ChangePassword = 'ChangePassword',
}

export interface LoginCredentials {
    companyId: string;
    loginId: string;
    password: string;
}

export interface LoginResult {
    statusCode: StatusCode;
    message?: string;
    data?: Array<unknown>;
    daysUntilPasswordExpires: number;
    numberOfSecurityQuestions?: number;
}

export interface SecureToken {
    credentialId: string;
    token: string;
    pin: string;
    confirmPin: string;
}
