import { AchBatchRecipientSummary } from '@treasury/domain/channel/types/ach';
import { addenda, secCode } from '@treasury/policy/ach';
import Primitives from '@treasury/policy/primitives';

import { FdlFieldDefinitions, number, Recordset, string } from '@treasury/FDL';
import { RecordsetData } from '@treasury/FDL/recordset';
import { html } from 'lit';

/**
 * fields for the batch payment recipients summary  dialog that can be accessed during the ach domestic batch workflow
 */
export const batchRecipientSummaryFields = (): FdlFieldDefinitions<AchBatchRecipientSummary> => ({
    batch: string.thatIs.readOnly(),
    name: string.thatIs.readOnly(),
    secCode: secCode.thatIs.readOnly(),
    accountNumber: string.thatIs.readOnly(),
    accountType: string.thatIs.readOnly(),
    routingNumber: string.thatIs.readOnly(),
    transactionType: string.thatIs.readOnly(),
    amount: Primitives.amount.thatIs.readOnly(),
    prenote: Primitives.yesNoBoolean.thatIs.readOnly(),
    addenda: addenda.thatIs
        .readOnly()
        .with.template(addenda => html`<div style="white-space: pre-line">${addenda}</div>`),
    effectiveDate: string.thatIs.readOnly(),
});

export const batchRecipientSummaryRecordset = (
    values: RecordsetData<AchBatchRecipientSummary, void>
) => new Recordset(batchRecipientSummaryFields(), values);
