import { LitElement } from 'lit';

/**
 * Logs the updated properties of a component. Intended for temporary use as a sanity check
 * while developing a component to make sure there are not superfluous updates happening.
 */
export function auditUpdates(
    changedProps: Map<PropertyKey, unknown>,
    componentThis: LitElement,
    // eslint-disable-next-line no-console
    logFn = console.log
) {
    const changedKeys = Array.from(changedProps.keys()).join(', ');
    const newValues = Array.from(changedProps).map(([k, v]) => ({
        property: k,
        oldValue: v,
        newValue: (componentThis as unknown as Record<PropertyKey, unknown>)[k],
    }));

    logFn(
        `Updated: %c%s\n%cChanges: %c%s\n%cDetails: %o`,
        'background-color: slategray; color: blue;',
        componentThis.tagName.toLocaleLowerCase(),
        'background-color: inherit; color: inherit;',
        'background-color: slategray; color: blue;',
        changedKeys,
        'background-color: inherit; color: inherit;',
        newValues
    );
}
