/* eslint-disable @treasury/no-date */
import { AchCompanyRequests } from '@treasury/domain/channel/requests/ach';
import {
    BatchDetails,
    Config,
    DatePickerValue,
    disableAchPaymentValueDates,
    isValidDate,
    PaymentEntitlements,
} from '@treasury/domain/channel/types/ach';
import { FiDate } from '@treasury/domain/dates';
import { FdlFieldDefinitions, Record as FdlRecord, FieldType, Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-icon';
import '@treasury/omega/components/omega-tooltip';
import {
    achCompany,
    achOffsetAccount,
    isAllowUnbalancedPayments,
    isBalancedTransaction,
    requirePrefundingOffsetAccount,
    secCode,
} from '@treasury/policy/ach';
import { DateModel } from '@treasury/policy/lib/utils';
import FieldTypes, { amount } from '@treasury/policy/primitives';
import { html } from 'lit';

const getDateFromVal = (value: DatePickerValue, prevValue: DatePickerValue): Date => {
    if (typeof value !== 'string') {
        if (value.dates?.length) {
            const dateVal = value.dates[0];
            return dateVal as Date;
        }
        return new Date();
    }
    return new Date(value);
};
export const getDateDisableFunctions = (config: Config) => ({
    dateDisabledFunction: (dateModel: DateModel | Date) =>
        isValidDate(dateModel, config.allowSameDayPayments, config.holidays, config.cutoffTimes),
});
const hasErrors = (record: FdlRecord<BatchDetails>) =>
    !!record.getField('errorSummary')?.summaryMessageList.length;
const paymentShouldNotBeAllowed = (record: FdlRecord<BatchDetails>): boolean => {
    const afterStep1 = record.getField('step') > 1;
    const paymentHasErrors = hasErrors(record);
    return afterStep1 || paymentHasErrors;
};

export const BatchPaymentDetailFields = (
    client: typeof AchCompanyRequests,
    config: Config
): FdlFieldDefinitions<BatchDetails> => {
    const fields: FdlFieldDefinitions<BatchDetails> = {
        selected: FieldTypes.boolean.thatIs
            .visibleWhen((record: FdlRecord<BatchDetails>) => record.getField('step') <= 1)
            .thatIs.disabledWhen(paymentShouldNotBeAllowed),
        name: FieldTypes.string.thatIs
            .readOnlyWhen(paymentShouldNotBeAllowed)
            .with.template((field, record: FdlRecord<BatchDetails>) => {
                if (hasErrors(record)) {
                    return html`<div style="display: flex;">
                        <omega-tooltip message="Please correct the errors and re-upload your file">
                            <omega-icon
                                icon="exclamation-triangle pointer-cursor danger"
                            ></omega-icon>
                        </omega-tooltip>
                        <div>${field}</div>
                    </div> `;
                }
                return html`${field}`;
            }),
        achCompany: achCompany(client).thatIs.readOnly(),
        secCode: secCode.thatIs.readOnly(),
        debitAmount: amount.thatIs.readOnly(),
        creditAmount: amount.thatIs.readOnly(),
        effectiveDate: new FieldType<DatePickerValue>().with
            .schema('datepicker')
            .and.targetColumnWidth(190)
            .thatIs.readOnlyWhen((record: FdlRecord<BatchDetails>) => record.getField('step') > 1)
            .and.disabledWhen(
                (record: FdlRecord<BatchDetails>) =>
                    record.getField('oneEffectiveDate') || paymentShouldNotBeAllowed(record)
            )
            .with.template(
                (value: string | DatePickerValue, record: FdlRecord<BatchDetails>): string =>
                    new FiDate(getDateFromVal(value, record.getField('effectiveDate'))).toString()
            )
            .thatHas.validator({
                name: 'date-selected',
                validate: (modelValue: DatePickerValue, viewValue: DatePickerValue) =>
                    !!getDateFromVal(modelValue, viewValue),
            })
            .with.selectionDisabledFunctions(
                disableAchPaymentValueDates(
                    config.allowSameDayPayments,
                    config.holidays,
                    config.cutoffTimes
                )
            )
            .and.tag('datepicker'),
        offsetAccount: achOffsetAccount(config)
            .with.template((value, record) => {
                if (
                    !requirePrefundingOffsetAccount(
                        config.entitlements as PaymentEntitlements,
                        record
                    ) ||
                    isAllowUnbalancedPayments(config.entitlements as PaymentEntitlements, record)
                ) {
                    return record.getField('achCompany').offsetAccountNumber;
                }

                if (
                    !isAllowUnbalancedPayments(
                        config.entitlements as PaymentEntitlements,
                        record
                    ) &&
                    isBalancedTransaction(record)
                ) {
                    return 'Balanced';
                }

                return (
                    record.getField('offsetAccount').accountDisplayLabel ??
                    record.getField('offsetAccount').value
                );
            })
            .with.label('Offset Account')
            .thatHas.tag('omega-select'),
        step: FieldTypes.number.thatIs.visibleWhen(() => false),
        hasAccess: FieldTypes.boolean.thatIs.visibleWhen(() => false),
        unselected: FieldTypes.boolean.thatIs.visibleWhen(() => false),
        oneEffectiveDate: FieldTypes.boolean.thatIs.visibleWhen(() => false),
        discretionaryData: FieldTypes.string.thatIs.visibleWhen(() => false),
        entryDescription: FieldTypes.string.thatIs.visibleWhen(() => false),
        fileArchiveId: FieldTypes.number.thatIs.visibleWhen(() => false),
        hasErrors: FieldTypes.boolean.thatIs.visibleWhen(() => false),
    };
    return fields;
};

export const getBatchRecordset = (
    config: Config,
    payments: Array<BatchDetails>,
    client: any
): Recordset<BatchDetails> => new Recordset(BatchPaymentDetailFields(client, config), payments);
