import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { exists, isNonEmptyString } from '@treasury/utils';

export interface IssuedItemAccountSummary {
    accountName: string;
    itemCount: number;
    amountTotal: number;
}

export function createAccountsSummary(items: IIssuedItem[], includeExistingVoids: boolean) {
    const summaries = items.reduce(
        (summaries, item) => {
            const { account, accountName } = item;
            // don't generate a summary if the item lacks an account
            const hasAccount =
                exists(account) && exists(account.id) && isNonEmptyString(accountName);
            const isExistingVoid = item.type === 'Void' && !item.voidEligible;

            if (!hasAccount) {
                return summaries;
            }

            if (!includeExistingVoids && isExistingVoid) {
                return summaries;
            }

            if (account.id in summaries) {
                const summary = summaries[account.id];
                summary.itemCount++;
                summary.amountTotal += item.checkAmount ?? 0;
            } else {
                summaries[account.id] = {
                    accountName,
                    itemCount: 1,
                    amountTotal: item.checkAmount ?? 0,
                };
            }

            return summaries;
        },
        {} as Record<string, IssuedItemAccountSummary>
    );

    return Object.keys(summaries).map(k => summaries[k]);
}
