/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from "@jack-henry/frontend-utils/di";
import { TmHttpClient } from "@treasury/core/http";
import { createSwaggerClient } from "../../utils/tm-swagger-client";

import { FilterBaseModelDto, ReportResultBaseModelDto } from "../";
import { ContentType, HttpClient, RequestParams } from "./http-client";

@Injectable()
export class GenerateReportClient {
  private readonly http: HttpClient;

  constructor(tmHttp: TmHttpClient) {
    this.http = createSwaggerClient(tmHttp, HttpClient);
  }

  /**
   * No description
   *
   * @tags GenerateReport
   * @name GenerateReportPost
   * @summary Returns the Report for the specified type.
   * @request POST:/generateReport
   */
  generateReportPost = (
    query: {
      /**
       * Type of report cdt(current day transaction)/pdt(prior day transaction)/drt(date range transaction)/cdb(current day
       * balance)/pdb(prior day balance)/drb(date range balance)
       *
       */
      type: string | null;
    },
    filter: FilterBaseModelDto | null,
    params: RequestParams = {},
  ) =>
    this.http.request<ReportResultBaseModelDto[], any>({
      path: `/generateReport`,
      method: "POST",
      query: query,
      body: filter,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
