/* eslint-disable class-methods-use-this */
import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

const KEYCODE = {
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    UP: 38,
    ENTER: 13,
    TAB: 9,
};

class OmegaTabs extends LitElement {
    static get properties() {
        return {
            activeTab: String,
            type: String,
            tabs: Array,
            label: String,
            vertical: { type: Boolean, reflect: true },
            pill: { type: Boolean, reflect: true },
        };
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/ccomponents-tabs--longhand',
        };
    }

    get tabElements() {
        return Array.from(this.shadowRoot.querySelectorAll('.omega-tab'));
    }

    firstUpdated() {
        this.tabsTransformer(this.tabs);
        this.activeTab = this.activeTab ?? this.tabs[0].id;
        this.switchTab();
    }

    isActiveTab(id) {
        return this.activeTab === id;
    }

    clickTab(e) {
        this.activeTab = e.target.id;
        this.label = e.target.innerText;
        this.switchTab();
    }

    keyDownOnTab(event) {
        const idx = this.tabs.findIndex(tab => tab.id === event.target.id);
        if (event.keyCode === KEYCODE.TAB) return;
        event.preventDefault();
        switch (event.keyCode) {
            case KEYCODE.DOWN:
            case KEYCODE.RIGHT:
                this.tabElements[(idx + 1) % this.tabElements.length].focus();
                break;
            case KEYCODE.UP:
            case KEYCODE.LEFT:
                this.tabElements[
                    (idx + this.tabElements.length - 1) % this.tabElements.length
                ].focus();
                break;
            case KEYCODE.ENTER:
                event.target.click();
                break;
            default:
                break;
        }
    }

    tabsTransformer(tabs) {
        if (tabs[0].id) return;
        this.tabs = this.tabs.map((tab, idx) => ({ label: tab, id: idx }));
    }

    switchTab() {
        this.dispatchEvent(
            new CustomEvent('switchTab', {
                bubbles: true,
                composed: true,
                detail: { activeTab: this.activeTab, label: this.label },
            })
        );
    }

    renderTabs() {
        return this.tabs.map(
            tab =>
                html` <button
                    id=${tab.id}
                    role="tab"
                    part="tab active-${this.isActiveTab(tab.id)}"
                    aria-selected=${this.isActiveTab(tab.id)}
                    .disabled=${tab.disabled}
                    class=${classMap({
                        'omega-tab': true,
                        vertical: this.vertical,
                        pill: this.pill,
                        active: this.isActiveTab(tab.id),
                        disabled: tab.disabled,
                    })}
                    @click=${this.clickTab}
                    @keydown=${this.keyDownOnTab}
                >
                    ${tab.label}
                </button>`
        );
    }

    render() {
        return html`<div class="tab-wrapper" part="wrapper">
            <div
                class=${classMap({
                    'omega-tabs': true,
                    vertical: this.vertical,
                    'pill-button-wrapper': this.pill,
                })}
                role="tablist"
                label=${this.label}
                aria-label=${this.label}
            >
                ${this.renderTabs()}
            </div>
            <slot></slot>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            path {
                fill: #fff;
            }
            .omega-tabs {
                display: flex;
                padding-inline-start: 0;
                margin: 0;
                overflow-x: var(--omega-tabs-scroll, scroll);
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            .tab-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .omega-tabs::-webkit-scrollbar {
                display: none;
            }

            .omega-tabs > button {
                display: flex;
                align-items: center;
            }

            .omega-tabs.vertical {
                display: block;
            }

            .omega-tab {
                font-family: inherit;
                border: none;
                border-bottom: 2px solid var(--transparent);
                text-align: center;
                font-weight: var(--omega-medium);
                font-size: 14px;
                padding: 5px 10px;
                margin: 1px 3px 0;
                white-space: nowrap;
                /* making all tabs (active or not) the same color to make Design happy */
                color: var(--omega-primary);
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                min-height: 30px;
                background-color: transparent;
            }

            .omega-tab.vertical {
                display: block;
                border: none;
                padding: 0;
                margin: 0;
                color: #337ab7;
            }

            .omega-tab:focus {
                outline: none;
                background: var(--omega-primary--focus);
                box-shadow: var(--omega-secondary-lighten-300) 0px 0px 10px;
            }

            .omega-tab.vertical:focus,
            .omega-tab.vertical:hover {
                color: #23527c;
                text-decoration: underline;
                background: none;
                box-shadow: none;
            }

            .omega-tab[aria-selected='true'] {
                border-bottom: 2px solid var(--omega-primary);
                font-weight: 500;
            }
            .omega-tab.vertical[aria-selected='true'] {
                border-bottom: none;
                color: #585f69;
                font-weight: bold;
            }
            .pill-button-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 35em;
            }
            .pill {
                font-family: var(--omega-font);
                font-size: 13px;
                background-color: #fff;
                border: 1px solid #dfe1e8;
                border-radius: 6px;
                flex: 1;
                padding: 6px;
                max-height: 30px;
                margin: 0;
            }
            .pill.active {
                background-color: #dceafb;
                border-color: var(--omega-primary-lighten-200);
                color: var(--omega-primary);
                font-weight: bold;
                margin-left: -6px;
                margin-right: -6px;
                height: 32px;
                max-height: 32px;
                z-index: 2;
            }
            .pill:not(.active) {
                padding: 6px;
            }
            .pill:focus {
                outline: none;
            }
            path {
                fill: var(--omega-primary);
            }
            .disabled {
                opacity: 0.5;
                background-color: #d0d2d6;
                cursor: not-allowed;
            }
        `;
    }
}
customElements.define('omega-tabs', OmegaTabs);
export default OmegaTabs;
