import {
    InternationalAchCompany,
    InternationalAchPaymentDestinationCountryCode,
    InternationalAchPaymentHeader,
    InternationalAchPaymentRecipient,
    InternationalAchTemplateGetRequest,
    InternationalAchTemplateHeader,
    InternationalAchTemplateOptions,
    InternationalAchTemplateRecipients,
    InternationalAchTemplateRequestRecipients,
} from '@treasury/domain/channel/types/ach/international';
import { allAccountTypes } from './static-data';

const errorCase = (field: string) => {
    throw new Error(`Unable to process ${field}.`);
};

const availableTransactionTypesReference = [
    {
        id: 1,
        name: 'Debit',
    },
    {
        id: 2,
        name: 'Credit',
    },
];

const convertRecipientToTemplate = (
    recipientData: InternationalAchPaymentRecipient,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    const bankIdTypeId =
        iatAchOptions.values.bankIdTypes.find(
            id =>
                id.name === recipientData.receivingBankType ||
                id.description === recipientData.receivingBankType
        )?.id || errorCase('receiving bank type');
    const iatTransactionType =
        iatAchOptions.values.internationalTransactionTypes.find(
            type => type.name === recipientData.iatTransactionType.code
        ) || errorCase('payment transaction type');

    const receivingBankCountryAbbreviation =
        typeof recipientData.receivingBankCountry === 'string'
            ? recipientData.receivingBankCountry
            : recipientData.receivingBankCountry.countryCode;
    const receivingBankCountry =
        iatAchOptions.values.countries.find(
            country => country.abbreviation === receivingBankCountryAbbreviation
        ) || errorCase('receiving bank country');
    return {
        ...recipientData,
        routingNumber: recipientData.receivingBankNumber,
        preNote: recipientData.prenote,
        bankName: recipientData.receivingBankName,
        bankIdTypeId,
        iatTransactionType,
        receivingBankCountry,
        receivingBankCountryId: receivingBankCountry.id,
        values: null,
    };
};

export const convertPaymentDataToTemplate = (
    paymentHeaderData: InternationalAchPaymentHeader,
    recipientsData: InternationalAchPaymentRecipient[],
    iatAchOptions: InternationalAchTemplateOptions
): {
    paymentHeaderValues: InternationalAchTemplateHeader;
    recipientsValues: InternationalAchTemplateRecipients[];
} => {
    const stateId =
        iatAchOptions.values.states.find(state => state.code === paymentHeaderData.state)?.id ||
        errorCase('state');
    const destinationCountryAbbreviation =
        typeof paymentHeaderData.destinationCountryCode === 'string'
            ? paymentHeaderData.destinationCountryCode
            : paymentHeaderData.destinationCountryCode.countryCode;
    const destinationCountryCode =
        iatAchOptions.values.countries.find(
            country => country.abbreviation === destinationCountryAbbreviation
        ) || errorCase('destination country');
    const destinationCurrencyCodeId =
        iatAchOptions.values.currencies.find(
            currency => currency.name === paymentHeaderData.destinationCurrencyCode
        )?.id || errorCase('destination currency');
    const OriginatorCurrencyCodeId =
        iatAchOptions.values.currencies.find(
            currency => currency.name === paymentHeaderData.originatorCurrencyCode
        )?.id || errorCase('originator currency');
    const ForeignExchangeIndicatorId =
        iatAchOptions.values.foreignExchangeIndicators.find(
            indicator => indicator.name === paymentHeaderData.foreignExchangeIndicator
        )?.id || errorCase('foreign exchange indicator');
    const foreignExchangeReferenceData =
        iatAchOptions.values.foreignExchangeReferenceIndicators.find(
            indicator => indicator.id === paymentHeaderData.foreignExchangeReferenceNumber
        );
    const templateData = {
        ...paymentHeaderData,
        achCompanyId: paymentHeaderData.achCompany.id,
        companyIdentity: paymentHeaderData.achCompany.companyId,
        achCompanyName: paymentHeaderData.achCompany.companyName,
        stateId,
        destinationCountryCodeId: destinationCountryCode?.id,
        destinationCountryCode,
        destinationCurrencyCodeId,
        OriginatorCurrencyCodeId,
        ForeignExchangeIndicatorId,
        ForeignExchangeReference: paymentHeaderData.foreignExchangeReference,
        ForeignExchangeReferenceIndicatorId: foreignExchangeReferenceData?.id,
        foreignExchangeReferenceNumber: foreignExchangeReferenceData?.name,
    };

    const formattedRecipients = recipientsData.map(recipient =>
        convertRecipientToTemplate(recipient, iatAchOptions)
    );

    return {
        paymentHeaderValues: templateData,
        recipientsValues: formattedRecipients,
    };
};

const generateCascadingValuesForPayment = (
    destinationCountryCodeId: number,
    achCompany: InternationalAchCompany,
    iatAchOptions: InternationalAchTemplateOptions
): InternationalAchPaymentDestinationCountryCode => {
    const { countries, foreignExchangeIndicators, currencies, cascadingValues } =
        iatAchOptions.values;

    const selectedCountry =
        countries.find(country => country.id === destinationCountryCodeId) ||
        errorCase('destination country code');
    const selectedCountryCascading =
        cascadingValues.find(country => country.countryId === destinationCountryCodeId) ||
        errorCase('destination country code');

    const availableIndicators = selectedCountryCascading.foreignExchangeIndicators.map(
        indicatorIdentifier => {
            const indicatorData =
                foreignExchangeIndicators.find(
                    indicator => indicator.id === indicatorIdentifier.indicator
                ) || errorCase('foreign exchange indicator');
            const supportedCurrencies = indicatorIdentifier.destinationCurrencies.map(
                currencyId => {
                    const currencyData =
                        currencies.find(({ id }) => currencyId === id) ||
                        errorCase('destination currency');

                    return {
                        currencyCode: currencyData?.name,
                        currencyName: currencyData?.description,
                    };
                }
            );
            return {
                indicatorCode: indicatorData.name,
                indicatorName: indicatorData.description,
                supportedCurrencies,
            };
        }
    );

    const internationalAchAccountTypes = selectedCountryCascading.internationalAccountTypes.map(
        type => {
            const accountName =
                allAccountTypes.find(item => item.id === type.accountType)?.accountName ||
                errorCase('bank account type');
            return {
                accountName,
                allowCredit: true,
                allowDebit: true,
            };
        }
    );

    return {
        countryCode: selectedCountry?.abbreviation,
        countryName: selectedCountry?.name,
        receivingBankCountry: selectedCountry?.name,
        foreignExchangeIndicators: availableIndicators,
        internationalAchAccountTypes,
    };
};

const convertTemplateRecipientToPayment = (
    recipient: InternationalAchTemplateRequestRecipients,
    achCompany: InternationalAchCompany,
    destinationCountry: {
        countryCode: string;
        countryName: string;
    },
    iatAchOptions: InternationalAchTemplateOptions
): InternationalAchPaymentRecipient => {
    const iatTransactionType =
        iatAchOptions.values.internationalTransactionTypes.find(
            type => type.id === recipient.iatTransactionTypeId
        ) || errorCase('international transaction type');
    const receivingBankType =
        iatAchOptions.values.bankIdTypes.find(type => type.id === recipient.bankIdTypeId) ||
        errorCase('receiving bank type');
    const accountType = typeof recipient.accountType === 'string' ? recipient.accountType : '';
    const receivingBankCountry =
        iatAchOptions.values.countries.find(
            country => country.id === recipient.receivingBankCountryId
        )?.abbreviation || errorCase('receiving bank country code');
    const transactionType = recipient.transactionTypeDescription;
    return {
        ...recipient,
        iatTransactionType: {
            code: iatTransactionType?.name,
            name: iatTransactionType?.description,
        },
        transactionType,
        accountType,
        step: 0,
        achCompany,
        destination: destinationCountry,
        hold: recipient.holdTransaction,
        prenote: recipient.isPrenote,
        nachaUpload: false,
        receivingBankCountry,
        receivingBankName: recipient.bankName,
        receivingBankNumber: recipient.routingNumber,
        receivingBankType: receivingBankType?.description,
    };
};

export const convertTemplateToPayment = (
    templateData: InternationalAchTemplateGetRequest,
    achCompanies: InternationalAchCompany[],
    iatAchOptions: InternationalAchTemplateOptions
): { payment: InternationalAchPaymentHeader; recipients: InternationalAchPaymentRecipient[] } => {
    const templateHeader = templateData;
    const { recipients } = templateData;
    const destinationCountryData =
        iatAchOptions.values.countries.find(
            country => country.id === templateData.destinationCountryCodeId
        ) || errorCase('destination country code');

    const achCompany =
        achCompanies.find(company => company.id === templateHeader.achCompanyId) ||
        errorCase('ach company');

    const destinationCountryCode = generateCascadingValuesForPayment(
        destinationCountryData?.id,
        achCompany,
        iatAchOptions
    );
    const destinationCurrencyCode =
        iatAchOptions.values.currencies.find(
            country => country.id === templateData.destinationCurrencyCodeId
        )?.name || errorCase('destination currency code');
    const foreignExchangeIndicator =
        iatAchOptions.values.foreignExchangeIndicators.find(
            item => item.id === templateData.foreignExchangeIndicatorId
        )?.name || errorCase('foreign exchange indicator');
    const originatorCurrencyCode =
        iatAchOptions.values.countries.find(
            country => country.id === templateData.originatorCurrencyCodeId
        )?.abbreviation || errorCase('originator country code');
    const state =
        iatAchOptions.values.states.find(item => item.id === templateData.stateId)?.code ||
        errorCase('state');
    const paymentHeader = {
        ...templateHeader,
        achPaymentDraftId: 0,
        destinationCountryCode,
        achCompany,
        destinationCurrencyCode,
        foreignExchangeIndicator,
        originatorCurrencyCode,
        state,
        fileArchiveId: 0,
        foreignExchangeReferenceNumber: templateData.foreignExchangeReferenceIndicatorId,
        id: 0,
        nachaUpload: false,
        offsetAccount: null,
        restricted: false,
        secCode: 'IAT',
        setToZero: false,
        templateName: templateData.name,
        name: templateData.name,
        status: null,
        step: 0,
        template: false,
        transactionId: null,
        achCompanyId: typeof templateHeader.achCompanyUniqueId,
        frequency: null,
    };

    return {
        payment: paymentHeader,
        recipients: recipients.map(recipient =>
            convertTemplateRecipientToPayment(
                recipient,
                achCompany,
                destinationCountryCode,
                iatAchOptions
            )
        ),
    };
};
